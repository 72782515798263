import { Operation, createHttpLink, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http/batchHttpLink';

import joinAPI from '../joinAPI';

const fetchParams = {
  uri: `${joinAPI.apiUrl}/graphql`,
  credentials: 'same-origin',
};

const batchHttpLink = new BatchHttpLink({
  ...fetchParams,
  batchMax: 25,
});

const normalHttpLink = createHttpLink(fetchParams);

export default split(
  ({ getContext }: Operation) => getContext().batch,
  batchHttpLink,
  normalHttpLink
);
