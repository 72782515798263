import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  getSelectedForFilterType,
} from '../../ProjectsList/ProjectsListUtils';
import { MultiSelect } from '../../scales';

type Props = {
  filterManager: ProjectFilterManager;
  filterType: string;
  setSettings: ProjectFilterSetSettings;
};

export default function InsightsFilterSelectTypes(props: Props) {
  const { clearFilterType, setFilter } = props.filterManager;
  const [selected] = getSelectedForFilterType(props.filterManager, props.filterType);

  const types = props.filterManager.filterOptions?.projectTypes;
  const selectEntries =
    types && types.map(({ id, name, parentID }) => ({ id, label: name, parentID }));

  return (
    <div className="px-2">
      <MultiSelect
        defaultValue={undefined}
        entries={selectEntries}
        isClearable
        isSearchable
        label="Project Type"
        onChange={(selectedValues: string[]) => {
          // Determine IDs that have been added
          const newlySelectedIDs = selectedValues.filter((id) => !selected.includes(id));
          // Determine IDs that have been removed (deselected)
          const removedIDs = selected.filter((id) => !selectedValues.includes(id));

          if (selectedValues.length === 0) {
            // Clear All
            clearFilterType(props.filterType, props.setSettings);
          } else if (newlySelectedIDs.length) {
            // Add Filter
            setFilter({ type: props.filterType, value: newlySelectedIDs[0] }, props.setSettings);
          } else if (removedIDs.length)
            // Remove Filter
            setFilter({ type: props.filterType, value: removedIDs[0] }, props.setSettings);
        }}
        placeholder="Find a type..."
        value={selected}
      />
    </div>
  );
}
