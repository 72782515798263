import { useCallback, useMemo } from 'react';
import { Link, To } from 'react-router-dom';

import { BusinessOutlined, ErrorOutline, LocationOnOutlined } from '@material-ui/icons';

import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import { InsightsActiveAlert, InsightsSort, InsightsSortKey } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { fromNow } from '../../../utilities/dates';
import { Thumbnail } from '../../dragon-scales';
import { SizeIcon } from '../../Nav/icons';
import { Chip, Icon, Tooltip } from '../../scales';
import ProjectTableAlertTooltip from '../ToolTips/ProjectTableAlertTooltip';
import ProjectTableChipsTooltip from '../ToolTips/ProjectTableChipsTooltip';

type Props = {
  alerts: InsightsActiveAlert[];
  onClick: () => void;
  project: InsightsProjects;
  sortState: InsightsSort;
  to: To;
};

export default function ProjectTableEntry(props: Props) {
  const sendAnalytics = useSendAnalytics();

  const { allChips, displayChip, lastUpdated } = useMemo(
    () => getChipsBySort(props.project, props.sortState.sortKey),
    [props.project, props.sortState.sortKey]
  );

  const handleTooltipOpen = useCallback(() => {
    sendAnalytics(
      insightsEvent(InsightsEvent.SUMMARY_PROJECT_TOOLTIP_VIEW, {
        projectID: props.project.id,
        projectName: props.project.name,
      })
    );
  }, [props.project.id, props.project.name, sendAnalytics]);

  return (
    <div className="flex items-center gap-2">
      <Thumbnail size={60} thumbnail={props.project.thumbnail} />

      <div className="flex flex-col flex-wrap justify-center gap-2">
        {/* Project Name */}
        <Tooltip
          content={<div className="inline w-48 type-table-text">{props.project.name}</div>}
          onOpen={handleTooltipOpen}
        >
          <Link onClick={props.onClick} to={props.to}>
            <div className="line-clamp-1 text-type-link type-heading3">{props.project.name}</div>
          </Link>
        </Tooltip>

        {/* Project Details */}
        <div className="flex items-center gap-1">
          {/* Project Alerts */}
          {props.project.activeAlerts.length > 0 && (
            <Tooltip
              content={<ProjectTableAlertTooltip triggeredAlertsByProject={props.alerts} />}
              onOpen={handleTooltipOpen}
            >
              <Chip
                icon={<ErrorOutline color="error" />}
                text={<div className="text-type-error">{props.project.activeAlerts.length}</div>}
              />
            </Tooltip>
          )}
          {/* Project Detail Chips */}
          <Tooltip
            content={<ProjectTableChipsTooltip allChips={allChips} lastUpdated={lastUpdated} />}
            onOpen={handleTooltipOpen}
          >
            <div className="flex items-center gap-2">
              <div className="flex items-center">{displayChip}</div>
              {allChips.length ? (
                <div className="flex type-table-text">+{allChips.length}</div>
              ) : null}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

type ChipDetails = {
  allChips: JSX.Element[];
  lastUpdated?: JSX.Element;
  displayChip?: JSX.Element;
};

const getChipsBySort = (project: InsightsProjects, sortKey: InsightsSortKey): ChipDetails => {
  const { chips, lastUpdated } = generateProjectChips(project);

  // Determine the chip to display
  const displayChip =
    sortKey === InsightsSortKey.LAST_UPDATED ? lastUpdated : selectDisplayChip(chips, sortKey);

  // Filter chips to exclude the displayed chip
  const allChips = chips.filter((chip) => chip.key !== displayChip?.key);

  return {
    allChips,
    lastUpdated,
    displayChip,
  };
};

type ChipData = {
  key: string;
  icon?: JSX.Element;
  text: string;
};

type ProjectChips = {
  chips: JSX.Element[];
  lastUpdated: JSX.Element;
};

const generateProjectChips = (project: InsightsProjects): ProjectChips => {
  const chips = generateChipData(project).map(({ key, icon, text }) => (
    <Chip key={key} icon={icon} text={text} />
  ));

  const lastUpdated: JSX.Element = project.lastUpdated ? (
    <div key="lastUpdated" className="line-clamp-1 type-table-text">
      Last updated {fromNow(project.lastUpdated)} by {project.lastUpdatedBy}
    </div>
  ) : (
    <div key="placeholder" className="hidden" />
  );

  return { chips, lastUpdated };
};

const generateChipData = (project: InsightsProjects): ChipData[] => {
  const chipData: ChipData[] = [];

  if (project.location) {
    chipData.push({
      key: 'location',
      icon: <LocationOnOutlined />,
      text: project.location,
    });
  }

  if (project.type && project.type !== 'Unassigned') {
    chipData.push({
      key: 'type',
      icon: <BusinessOutlined />,
      text: project.type,
    });
  }

  if (project.deliveryType) {
    chipData.push({
      key: 'deliveryType',
      text: project.deliveryType,
    });
  }

  if (project.projectLeadName) {
    chipData.push({
      key: 'lead',
      icon: <img alt="project lead icon" src="/img/GroupsOutlined.svg" width={16} />,
      text: project.projectLeadName,
    });
  }

  if (project.orgNodes) {
    project.orgNodes.forEach((node) => {
      if (node) {
        chipData.push({
          key: `orgNode-${node.id}`,
          icon: <Icon name="group_by" size="sm" />,
          text: node.name,
        });
      }
    });
  }

  if (project.status) {
    chipData.push({
      key: 'status',
      text: project.status,
    });
  }

  if (project.milestoneDesignPhase) {
    chipData.push({
      key: 'designPhase',
      text: project.milestoneDesignPhase,
    });
  }

  if (project.milestoneGSF && Number(project.milestoneGSF) !== 0) {
    chipData.push({
      key: 'size',
      icon: <SizeIcon />,
      text: `${Number(project.milestoneGSF).toLocaleString()} GSF`,
    });
  }

  return chipData;
};

const selectDisplayChip = (
  chips: JSX.Element[],
  sortKey: InsightsSortKey
): JSX.Element | undefined => {
  const chipMap: Record<InsightsSortKey, string | undefined> = {
    [InsightsSortKey.ALERTS]: 'alerts',
    [InsightsSortKey.COST]: 'location',
    [InsightsSortKey.DELIVERY_TYPE]: 'deliveryType',
    [InsightsSortKey.DESIGN_PHASE]: 'designPhase',
    [InsightsSortKey.SIZE]: 'size',
    [InsightsSortKey.STATUS]: 'status',
    [InsightsSortKey.NAME]: 'location',
    [InsightsSortKey.PROJECT_LEAD]: 'lead',
    [InsightsSortKey.LAST_UPDATED]: 'lastUpdated',
  };

  const key = chipMap[sortKey];
  return key ? chips.find((chip) => chip.key === key) : undefined;
};
