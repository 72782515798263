import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import { TimelineItemGroup } from './types';
import { BAR_GAP } from './utils';

type Props = {
  isMiniChart?: boolean;
  maxGroup: TimelineItemGroup;
  totalRange: [Date, Date];
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  yDataMax: number;
  yMax: number;
};

export default function ItemsMaxLine(props: Props) {
  return (
    <>
      {/* Max line */}
      <line
        className="max-line stroke-chart-axis stroke-1"
        strokeDasharray="4, 4"
        strokeWidth="2"
        x1={props.x(props.totalRange[0])}
        x2={props.x(props.totalRange[1])}
        y1={props.y(props.yMax - props.yDataMax)}
        y2={props.y(props.yMax - props.yDataMax)}
      />
      {/* Max text */}
      <text
        className={`fill-type-muted ${props.isMiniChart ? 'type-body3' : 'type-body2'}`}
        dominantBaseline="middle"
        textAnchor="middle"
        x={
          ((props.x(new Date(props.maxGroup?.end ?? 0)) ?? 0) +
            (props.x(new Date(props.maxGroup?.start ?? 0)) ?? 0) +
            -BAR_GAP) *
          0.5
        }
        y={props.y(props.yMax - props.yDataMax) - (props.isMiniChart ? 6 : 8)}
      >
        {props.yDataMax}
      </text>
    </>
  );
}
