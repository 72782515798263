import { FC, useEffect } from 'react';

import { Typography } from '@material-ui/core';

import { reportVariance, varianceEventTypes } from '../../../analytics/analyticsEventProperties';
import JoinAPI from '../../../api/joinAPI';
import { DISPLAY } from '../../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import { useProjectID } from '../../../utilities/routes/params';
import useGetProjectNameQuery from '../../Collaborators/hooks/UseGetProjectNameQuery';
import { GetGroupBysInput } from '../../CostReport/hooks/useMilestoneCostReportQuery';
import { useLoadUserReportComments } from '../../ReportsTab/ReportHooks';
import useVarianceReportParams from '../../VarianceReport/useVarianceReportParams';
import {
  getUserReportCommentQueryVariables,
  getVarianceColumnInputs,
  getVarianceReportCommentCount,
  useCurrentUserReportID,
  useIncludedColumnExpressions,
} from '../../VarianceReport/VarianceReportUtils';

const ExportVariance: FC = () => {
  // Read our project id from the URL to populate queries
  const projectID = useProjectID();
  if (!projectID) throw new Error('Project ID not found');
  const costMode = useCostMode();
  const reportID = useCurrentUserReportID();

  const { data: projectData, loading: projectLoading } = useGetProjectNameQuery(projectID);
  const { data: catData, loading: catLoading } = useProjectCategorizationsQuery(projectID);

  // get variance report params
  const projectName = projectData?.project?.name || '';
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const { displayGroupBy, filterManager, settings } = useVarianceReportParams(
    categorizations,
    projectID
  );

  // extract the variance report params from the queries aboves
  const { milestoneIDs, types, dates } = settings as VarianceSettings;
  const { includedExpressions } = useIncludedColumnExpressions(settings);
  const { filterQueryInput: viewFilter } = filterManager;
  const varianceColumnInputs = getVarianceColumnInputs(settings, includedExpressions);
  const groupByItems = GetGroupBysInput(displayGroupBy);

  // analytics
  const sendAnalytics = useSendAnalytics();
  const viewParams = getUserReportCommentQueryVariables(displayGroupBy, varianceColumnInputs);

  const varianceReportComments =
    useLoadUserReportComments(reportID, viewParams)?.data?.varianceReportComments || undefined;
  const numberOfComments = getVarianceReportCommentCount(varianceReportComments);
  useEffect(() => {
    // we need the project name to name the exported excel file
    const fileNameTokens = [projectName];
    // only export if everything is loaded
    if (!projectLoading && !catLoading) {
      const exportAndClose = () => {
        sendAnalytics(
          reportVariance(varianceEventTypes.VARIANCE_EXPORT_REPORT, {
            milestoneIDs,
            types,
            dates,
            numberOfComments,
          })
        );
        JoinAPI.exportCostReport(
          projectID,
          groupByItems,
          varianceColumnInputs,
          viewFilter,
          costMode,
          checkCostModeIncludesMarkups(costMode),
          true,
          fileNameTokens,
          viewParams,
          settings?.estimateLines === DISPLAY,
          checkCostModeIncludesMarkups(costMode),
          () => {
            // close this dialog when we're down exporting
            window.close();
          }
        );
      };
      setTimeout(exportAndClose, 100);
    }
  }, [
    projectID,
    groupByItems,
    varianceColumnInputs,
    viewFilter,
    costMode,
    sendAnalytics,
    projectLoading,
    catLoading,
    milestoneIDs,
    types,
    dates,
    viewParams,
    settings?.estimateLines,
    projectName,
    numberOfComments,
  ]);

  return (
    <Typography style={{ fontSize: 18, paddingTop: 16 }}>
      &nbsp;&nbsp;&nbsp;&nbsp;Exporting Variance Report...
    </Typography>
  );
};

export default ExportVariance;
