import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FileCopyOutlined, KeyboardBackspace, RotateLeft } from '@material-ui/icons';

import { projectCompsSetInputVar } from '../../api/apollo/reactiveVars';
import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { Export } from '../Icons/Export';
import { KeyboardArrowEnd } from '../Icons/KeyboardArrowEnd';
import { Icon, MenuSection } from '../scales';

import {
  ProjectCompInputUpdateFunctions,
  ProjectCompsSetInputUpdateFunctions,
} from './ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';

export const DEFAULT_REPORT_NAME = 'New Project Comparison Report';

export const getCostValue = (cost: CostRange | CostScalar): number => {
  const { value } = (cost as CostScalar) || {};
  return Number(value);
};

export function useStateLocalUpdater<T>(initialValue: T) {
  const [localValue, setLocalValue] = useState(initialValue);
  // Updater
  useEffect(() => {
    if (initialValue !== localValue) setLocalValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [initialValue]);

  return [localValue, setLocalValue] as [T, Dispatch<SetStateAction<T>>];
}

export const useProjectCompMenu = ({
  hasAccess,
  isResettable,
  updateFunctions,
  projectCompInputID,
  projectId,
}: {
  hasAccess: boolean;
  isResettable: boolean;
  updateFunctions: ProjectCompsSetInputUpdateFunctions & ProjectCompInputUpdateFunctions;
  projectCompInputID: UUID;
  projectId: UUID;
}) => {
  const projectCompsSetInput = projectCompsSetInputVar();
  const { projectCompInputs } = projectCompsSetInput;
  const index = projectCompInputs.findIndex((input) => input.id === projectCompInputID);
  const hasIndex = index > -1;
  const last = projectCompInputs.length - 1;
  const isLast = index === last;
  const isFirst = index === 0;

  const sections: MenuSection[] = [
    {
      'aria-label': 'First Section',
      entries: [
        {
          disabled: !hasIndex || isFirst,
          id: PC_MENU_OPTIONS.MOVE_LEFT,
          label: 'Move to the left',
          onClick: () => updateFunctions.reorderProjectCompInput(index - 1),
          startAdornment: <KeyboardBackspace />,
        },
        {
          disabled: !hasIndex || isLast,
          id: PC_MENU_OPTIONS.MOVE_RIGHT,
          label: 'Move to the right',
          onClick: () => updateFunctions.reorderProjectCompInput(index + 1),
          startAdornment: <KeyboardBackspace className="rotate-180" />,
        },
        {
          disabled: !hasIndex || isFirst,
          id: PC_MENU_OPTIONS.MOVE_START,
          label: 'Move to the start',
          onClick: () => updateFunctions.reorderProjectCompInput(0),
          startAdornment: <KeyboardArrowEnd />,
        },
        {
          disabled: !hasIndex || isLast,
          id: PC_MENU_OPTIONS.MOVE_END,
          label: 'Move to the end',
          onClick: () => updateFunctions.reorderProjectCompInput(last),
          startAdornment: <KeyboardArrowEnd className="rotate-180" />,
        },
      ],
    },
    {
      'aria-label': 'Second Section',
      entries: [
        {
          id: PC_MENU_OPTIONS.COPY_PROJECT,
          label: 'Copy project to new column',
          onClick: () => updateFunctions.cloneProjectCompInput(projectCompInputID),
          startAdornment: <FileCopyOutlined />,
        },
        {
          disabled: !hasAccess,
          id: PC_MENU_OPTIONS.GO_TO_PROJECT,
          label: 'Go to project source',
          onClick: () =>
            window.open(generateSharedPath(RouteKeys.PROJECT, { projectId }), '_blank'),
          startAdornment: <Export />,
          // tooltip: hasAccess ? undefined : 'This a company project you cannot access',
        },
        {
          disabled: !isResettable,
          id: PC_MENU_OPTIONS.RESET,
          label: 'Reset to default',
          onClick: updateFunctions.resetProjectCompInput,
          startAdornment: <RotateLeft />,
        },
        {
          id: PC_MENU_OPTIONS.DELETE,
          label: 'Delete',
          onClick: updateFunctions.deleteProjectCompInput,
          startAdornment: <Icon name="delete" />,
          type: 'destructive',
        },
      ],
    },
  ];
  return { sections };
};

export enum PC_SAVE_ACTION {
  SAVE = 'SAVE',
  SAVE_AS = 'SAVE_AS',
  SAVE_AS_COPY = 'SAVE_AS_COPY',
}

export enum PC_MENU_OPTIONS {
  MOVE_LEFT = 'MOVE_LEFT',
  MOVE_RIGHT = 'MOVE_RIGHT',
  MOVE_START = 'MOVE_START',
  MOVE_END = 'MOVE_END',
  COPY_PROJECT = 'COPY_PROJECT',
  GO_TO_PROJECT = 'GO_TO_PROJECT',
  RESET = 'RESET',
  DELETE = 'DELETE',
}
