import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import {
  reportTransition,
  transitionEventTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  isEstimateUploadDialogOpen,
  setReactiveLocal,
  transitionOnboardingVar,
} from '../../../api/apollo/reactiveVars';
import { EstimateType, TransitionModal } from '../../../api/gqlEnums';
import { MANUAL, TRANSITION_REACTIVE_VAR, TYPE } from '../../../constants';
import { CostReportColumnType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../../utilities/clickHandler';
import { generateSharedPath } from '../../../utilities/routes/links';
import DialogsStyles from '../../Dialogs/DialogsStyles';
import JoinDialogMulti from '../../Dialogs/JoinDialog/JoinDialogMulti';
import { JoinDialogType } from '../../Dialogs/JoinDialog/JoinDialogUtils';
import useCreateMilestoneDraftEstimateFromPreviousMilestone from '../../ImportEstimate/EstimateAddUpload/hooks/useCreateMilestoneDraftEstimateFromPreviousMilestone';
import { useGetEstimateTotalTypeConflictInfoQuery } from '../../ImportEstimate/EstimateAddUpload/hooks/useGetEstimateTotalTypeConflictInfoQuery';
import { useMilestonesQuery } from '../../Milestones/hooks';
import TransitionMilestoneSelection from '../Modals/TransitionMilestoneSelection/TransitionMilestoneSelection';
import { getCurrentEstimateType } from '../Modals/TransitionMilestoneSelection/TransitionMilestoneSelectionUtils';

import {
  TransitionManagerHeaderTexts,
  TransitionMangerTipTexts,
  headerText,
} from './TransitionManagerUtils';

type TransitionManagerProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  viewHasEstimate: boolean;
};

const TransitionManager: FC<TransitionManagerProps> = ({ classes, viewHasEstimate }) => {
  const navigate = useNavigate();
  const createMilestoneDraftEstimateFromPreviousMilestone =
    useCreateMilestoneDraftEstimateFromPreviousMilestone();

  const { projectId, milestoneId } = useParams();
  if (!projectId || !milestoneId) throw new Error('Project ID and milestone ID are required');

  const transition = useReactiveVar(transitionOnboardingVar);
  const sendAnalytics = useSendAnalytics();
  const [loading, setLoading] = useState(false);
  const projectMilestones = useMilestonesQuery(projectId, false)?.data?.milestones ?? [];
  const currentMilestone = projectMilestones.find((m: Milestone) => m.id === milestoneId);
  const milestoneName = currentMilestone?.name ?? '';
  const currentEstimateType = getCurrentEstimateType(window.location);

  const {
    modal: modalStored,
    modalIsOpen,
    milestoneID: previousMilestoneID,
    type: previousType,
    incorporateAccepted,
    numberOfAcceptedItems,
  } = transition;
  const modal: TransitionModal | undefined = modalIsOpen ? modalStored : undefined;
  const selectedMilestone = projectMilestones.find((m: Milestone) => m.id === previousMilestoneID);
  const selectedEstimateID =
    previousType === CostReportColumnType.TARGET_REPORT
      ? selectedMilestone?.budgetID
      : selectedMilestone?.activeEstimateID;
  const isRunningTotalSelection = previousType === CostReportColumnType.RUNNINGTOTAL_REPORT;
  const conflictInfo = useGetEstimateTotalTypeConflictInfoQuery({
    projectID: projectId,
    milestoneID: selectedMilestone?.id || '',
    estimateID: selectedEstimateID || '',
  })?.data?.estimateTotalTypeConflictInfo;
  const hasConflict = conflictInfo?.hasConflict ?? false;
  const formatPrevMilestoneID = previousMilestoneID ?? '';
  const showNewTip = isRunningTotalSelection && hasConflict;

  const openModal = (modal: TransitionModal) => {
    setReactiveLocal(transitionOnboardingVar, TRANSITION_REACTIVE_VAR, {
      ...transition,
      modal,
    });
    sendAnalytics(reportTransition(transitionEventTypes.TRANSITION_VISIT_MENU, { modal }));
  };

  const cancel = (isFinish?: boolean) => {
    setReactiveLocal(transitionOnboardingVar, TRANSITION_REACTIVE_VAR, {
      ...transition,
      modal: TransitionModal.MILESTONE,
      modalIsOpen: false,
    });
    if (!isFinish) sendAnalytics(reportTransition(transitionEventTypes.TRANSITION_CANCEL_MENU));
  };

  const finalize = () => {
    if (loading) return;
    setLoading(true);
    let isRunningTotal = false;
    let previousEstimateType: EstimateType | undefined;
    switch (previousType) {
      case CostReportColumnType.ESTIMATE_REPORT:
        previousEstimateType = EstimateType.ACTIVE_ESTIMATE;
        break;
      case CostReportColumnType.TARGET_REPORT:
        previousEstimateType = EstimateType.BUDGET;
        break;
      case CostReportColumnType.RUNNINGTOTAL_REPORT:
        previousEstimateType = EstimateType.ACTIVE_ESTIMATE;
        isRunningTotal = true;
        break;
      default:
    }

    if (!previousEstimateType) {
      throw new Error('Found no previous estimate type.');
    }

    createMilestoneDraftEstimateFromPreviousMilestone(
      projectId,
      milestoneId,
      currentEstimateType,
      formatPrevMilestoneID,
      previousEstimateType,
      isRunningTotal,
      incorporateAccepted,
      (estimateID: UUID) => {
        const path = generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE_IMPORT_ESTIMATE, {
          projectId,
          milestoneId,
          estimateId: estimateID,
        });
        clickLinkKeyHandler(
          navigate,
          undefined,
          path,
          `${TYPE}=${currentEstimateType}&${MANUAL}=${true}`
        );
        setLoading(false);
        cancel(true);
        sendAnalytics(
          reportTransition(transitionEventTypes.TRANSITION_FINISH_MENU, {
            incorporateAccepted,
            isRunningTotal,
            copiedFrom: previousType,
            numberOfAcceptedItems,
          })
        );
      },
      () => {
        setLoading(false);
      }
    );
  };

  const dialogs: JoinDialogType[] = [
    {
      open: modal === TransitionModal.MILESTONE,
      onOpen: () => openModal(TransitionModal.MILESTONE),
      onClose: () => cancel(),
      onNext: () => {
        finalize();
      },
      onBack: () => {
        cancel();
        isEstimateUploadDialogOpen(viewHasEstimate);
      },
      contentHeader1: TransitionManagerHeaderTexts.MilestoneModal,
      contentHeader2: '',
      contentComponent: (
        <TransitionMilestoneSelection
          currentEstimateType={currentEstimateType}
          projectMilestones={projectMilestones}
        />
      ),
      headerText: headerText(currentEstimateType, milestoneName),
      tipText: showNewTip
        ? TransitionMangerTipTexts.RunningTotalHasConflict
        : TransitionMangerTipTexts.MilestoneModal,
      disabledNext: !previousMilestoneID || !previousType, // only enable the Next button if both dropdowns have values
      dialogClass: classes.transitionManager,
    },
  ];

  return <JoinDialogMulti dialogs={dialogs} disablePageCounter dynamicHeight />;
};

export default withStyles(DialogsStyles)(TransitionManager);
