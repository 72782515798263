import { Tab, TabList, TabPanel, Tabs } from '../../scales';
import { ProjectIdea } from '../types';

import { IdeaEventType, useSendIdeaAnalytics } from './hooks/analytics';

type Props = {
  idea: ProjectIdea;
};

export default function IdeaTabs(props: Props) {
  const sendIdeaAnalytics = useSendIdeaAnalytics(props.idea);

  return (
    <Tabs
      defaultValue="characteristics"
      onChange={(detailsTab) => sendIdeaAnalytics(IdeaEventType.CHANGE_DETAILS_TAB, { detailsTab })}
    >
      <TabList>
        <Tab id="characteristics">Characteristics</Tab>
        <Tab id="advantages">Advantages</Tab>
        <Tab id="disadvantages">Disadvantages</Tab>
      </TabList>
      <TabPanel id="characteristics">
        <div className="flex min-w-full flex-row gap-2 overflow-auto px-6 pt-4">
          <IdeaContent
            content={props.idea.root.characteristics}
            isRoot
            title={props.idea.root.name}
          />
          {props.idea.alternates.map((alternate) => (
            <IdeaContent
              key={alternate.id}
              content={alternate.characteristics}
              title={alternate.name}
            />
          ))}
        </div>
      </TabPanel>
      <TabPanel id="advantages">
        <div className="flex min-w-full flex-row gap-2 overflow-auto px-6 pt-4">
          <IdeaContent content={props.idea.root.advantages} isRoot title={props.idea.root.name} />
          {props.idea.alternates.map((alternate) => (
            <IdeaContent key={alternate.id} content={alternate.advantages} title={alternate.name} />
          ))}
        </div>
      </TabPanel>
      <TabPanel id="disadvantages">
        <div className="flex min-w-full flex-row gap-2 overflow-auto px-6 pt-4">
          <IdeaContent
            content={props.idea.root.disadvantages}
            isRoot
            title={props.idea.root.name}
          />
          {props.idea.alternates.map((alternate) => (
            <IdeaContent
              key={alternate.id}
              content={alternate.disadvantages}
              title={alternate.name}
            />
          ))}
        </div>
      </TabPanel>
    </Tabs>
  );
}

function IdeaContent(props: { title: string; content: string[]; isRoot?: boolean }) {
  return (
    <div
      className={[
        'flex min-w-[300px] max-w-[500px] flex-1 flex-col gap-2 p-4',
        props.isRoot ? 'rounded bg-background-1' : '',
      ].join(' ')}
    >
      <h3 className="type-heading3">{props.title}</h3>
      {props.content.map((content) => {
        const [title, description] = content.split(':');
        if (!title || !description) return null;
        return (
          <div key={title} className="flex flex-col gap-0.5">
            <div className="type-body2">
              <b>{title}</b>
            </div>
            <div className="type-body1">{description}</div>
          </div>
        );
      })}
    </div>
  );
}
