import ProjectSelectionList from '../ProjectsList/ProjectSelection/ProjectSelectionList';
import ProjectSelectionListEntry from '../ProjectsList/ProjectSelection/ProjectSelectionListEntry';

type Props = {
  isOpen: boolean;
  isPrinting: boolean;
  projects: SearchProject[];
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
};

export default function ProgramDashboardProjectSelection({
  isOpen,
  isPrinting,
  projects,
  selectedProjectIDs,
  setSelectedProjectIDs,
}: Props) {
  if (!isPrinting && isOpen)
    return (
      <ProjectSelectionList
        key="ProjectSelectionList"
        fetchMore={() => {}}
        isLink
        onChange={setSelectedProjectIDs}
        projects={projects}
        searchTerm=""
        selectedProjectIDs={selectedProjectIDs}
        selectionMode="multiple"
      />
    );

  if (isPrinting)
    return (
      <>
        {projects.map((project) => (
          <div key={project.id} className="px-4">
            <ProjectSelectionListEntry
              key={project.id}
              project={project}
              searchTerm=""
              shouldShowTooltip
            />
          </div>
        ))}
      </>
    );

  return null;
}
