import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { DialogContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useAddCompanyCategorizations } from '../../CompanyTab/Standards/CompanyCategorizations/hooks';
import { Button } from '../../scales';
import { useRemoveCategorizations } from '../hooks/hooks';

import styles from './ConfirmDeleteCategorizationsDialogStyles';

type ConfirmDeleteCategorizationsDialogProps = {
  classes: Classes<typeof styles>;
  catIDToAdd: UUID[];
  catIDToRemove: UUID[];
  categorizationNames: string[];
  onCancel: () => void;
  onClose: () => void;
};

const ConfirmDeleteCategorizationsDialog: FC<ConfirmDeleteCategorizationsDialogProps> = ({
  classes,
  categorizationNames,
  onCancel,
  catIDToAdd,
  catIDToRemove,
  onClose,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const removeCategorizations = useRemoveCategorizations();
  const addCategorizations = useAddCompanyCategorizations();
  const sendAnalytics = useSendAnalytics();

  const deleteCategorizations = (categorizationNames || []).map((name, i) => (
    <Typography key={`${name}${i.toString()}`}>{name}</Typography>
  ));

  return (
    <>
      <div className={classes.innerPaper}>
        <DialogContent className={classes.content}>
          <Typography className={classes.margin}>
            You have selected to delete the following categorization(s):
          </Typography>
          <Typography component="div">{deleteCategorizations}</Typography>
          <Typography className={classes.margin}>
            Deleting categorization(s) is permanent and cannot be undone. If any items or milestone
            costs were tagged, their categories will be removed.
          </Typography>

          <Typography className={classes.textBold}>
            Confirm you are deleting categorization(s)?
          </Typography>
          <div className={classes.margin}>
            <Button
              label="Cancel"
              onClick={() => {
                onCancel();
                sendAnalytics(
                  categorizationEvent(CategorizationEvent.MANAGE_CONFIRM_DELETE_CANCEL, {
                    projectID: projectId,
                  })
                );
              }}
              type="secondary"
            />
          </div>
          <div className={classes.margin}>
            <Button
              label="Confirm"
              onClick={() => {
                if (catIDToAdd.length > 0) {
                  addCategorizations(projectId, catIDToAdd);
                }
                removeCategorizations(projectId, catIDToRemove, () => {
                  onCancel();
                  onClose();
                });
                sendAnalytics(
                  categorizationEvent(CategorizationEvent.MANAGE_CONFIRM_DELETE_CONFIRM, {
                    projectID: projectId,
                  })
                );
              }}
              type="primary"
            />
          </div>
        </DialogContent>
      </div>
    </>
  );
};

export default withStyles(styles)(ConfirmDeleteCategorizationsDialog);
