import { Link } from 'react-router-dom';

import { RouteKeys } from '../../../../routes/paths';
import { generateSharedPath } from '../../../../utilities/routes/links';
import ProgramThumbnail from '../../../ProgramReporting/ProgramThumbnail';

import Title from './Title';

type Props = {
  isShowingMilestoneInfo?: boolean;
  onShowMilestoneInfo?: (value: boolean) => void;
  program: {
    id: string;
    thumbnail: string;
    name: string;
    projects: { id: string; thumbnail: string }[];
  };
  isThumbnailVisible?: boolean;
};

export default function ProgramTitle(props: Props) {
  return (
    <Title
      isShowingMilestoneInfo={props.isShowingMilestoneInfo}
      onShowMilestoneInfo={props.onShowMilestoneInfo}
      subtitle={`Program of ${props.program.projects.length} Projects`}
      thumbnail={
        props.isThumbnailVisible ? (
          <Link
            className="cursor-pointer focus-visible:outline"
            to={generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, {
              programID: props.program.id,
            })}
          >
            <div className="px-2">
              <ProgramThumbnail
                projectCount={props.program.projects.length}
                projects={props.program.projects}
                projectThumbnail={props.program.thumbnail}
                size="md"
              />
            </div>
          </Link>
        ) : undefined
      }
      title={props.program.name}
    />
  );
}
