import { FC, cloneElement, isValidElement, memo } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Help as Info } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Icon, IconButton } from '../../scales';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import DialogsStyles from '../DialogsStyles';

import { JoinDialogType } from './JoinDialogUtils';

type JoinDialogProps = {
  classes: Classes<typeof DialogsStyles>;
  dialog: JoinDialogType;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  dynamicHeight?: boolean;
  hasDivider?: boolean;
  index?: number;
  pageCounter?: JSX.Element;
  hasLinearProgress?: boolean;
  numberOfDialogs?: number;
};

export const getActionButton = (
  style: string | undefined,
  dataCy: string | undefined,
  color: 'inherit' | 'primary' | 'secondary' | 'default',
  variant: 'text' | 'flat' | 'outlined' | 'contained' | 'raised' | 'fab' | 'extendedFab',
  disabled: boolean,
  onClick: (() => void) | undefined,
  text: string,
  hoverTipText?: string
) => {
  const button = (
    <Button
      key={text}
      className={style}
      color={color}
      data-cy={dataCy}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
    >
      {text}
    </Button>
  );
  if (!hoverTipText) return button;
  return (
    <NormalTooltip title={hoverTipText}>
      <div>{button}</div>
    </NormalTooltip>
  );
};

const JoinDialog: FC<JoinDialogProps> = ({
  classes,
  dialog,
  dynamicHeight,
  hasDivider = true,
  index = 0,
  pageCounter,
  hasLinearProgress,
  numberOfDialogs = 0,
}) => {
  const {
    actionButtons,
    borderColor,
    contentClass,
    contentComponent,
    contentHeader1,
    contentHeader2,
    dialogClass,
    disabledNext = false,
    headerText = 'Import Estimate',
    loading,
    onBack,
    onBackText,
    onClose,
    onNext,
    onNextText,
    open,
    subHeader,
    tipText = undefined,
    tooltip,
    hoverTipText,
  } = dialog;

  // Set up the counter with index
  let pageCounterWithIndex = pageCounter;
  if (isValidElement(pageCounter) && typeof index === 'number') {
    const props = { index };
    pageCounterWithIndex = cloneElement(pageCounter, props);
  }

  const linearProgress = ((index + 1) / numberOfDialogs) * 100;

  const header = (
    <>
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">{headerText}</Typography>
        </div>
        <IconButton
          aria-label="Close dialog"
          data-cy="join-dialog-close"
          icon={<Icon name="close" />}
          onClick={onClose}
          type="secondary"
        />
      </div>
      {hasLinearProgress && !loading && (
        <LinearProgress color="secondary" value={linearProgress} variant="determinate" />
      )}
    </>
  );

  const loadingBar = (
    <div className={classes.loading}>
      <LinearProgress hidden={!loading} />
    </div>
  );

  const content = (
    <div
      className={`${classes.content} ${classes.paddingBottom} ${classes.onboarding} ${
        dynamicHeight ? classes.dynamicHeight : ''
      } ${contentClass}`}
      data-cy="join-dialog"
    >
      {pageCounterWithIndex}
      {pageCounter && <div className={`${classes.paddingBottom}`} />}
      <Typography
        className={`${classes.leftText} ${classes.bold} ${classes.textContainer}`}
        variant="body1"
      >
        {contentHeader1 && contentHeader1}
        {tooltip && (
          <NormalTooltip title={tooltip}>
            <Info className={classes.info} data-cy="modal-tooltip" />
          </NormalTooltip>
        )}
      </Typography>
      {contentHeader2 && (
        <Typography className={`${classes.leftText} ${classes.paddingBottom}`} variant="body1">
          {contentHeader2}
        </Typography>
      )}
      {contentComponent}
      <div className={classes.spacer} />
      {tipText ? (
        <div className={classes.tipContainer}>
          <ShadedHelpTip shiftLeft tip={tipText} />
        </div>
      ) : null}
    </div>
  );

  const footer = (
    <DialogActions className={classes.action}>
      {actionButtons ? (
        actionButtons.map(
          ({ isLeftAligned, isRightAligned, dataCy, color, variant, disabled, onClick, text }) =>
            getActionButton(
              (() => {
                // Returns the correct class for the button
                if (isLeftAligned) return classes.pullLeft;
                if (isRightAligned) return classes.pullRight;
                return undefined;
              })(),
              dataCy,
              color,
              variant,
              Boolean(disabled),
              onClick,
              text
            )
        )
      ) : (
        <>
          {getActionButton(
            undefined,
            'dialog-previous-onboarding-step',
            'primary',
            'outlined',
            loading || !onBack,
            onBack,
            onBackText || 'Back',
            hoverTipText
          )}
          {hasLinearProgress && numberOfDialogs > 1 ? (
            <Typography className={classes.pageCountText}>{`${
              index + 1
            } / ${numberOfDialogs}`}</Typography>
          ) : null}
          {getActionButton(
            undefined,
            'dialog-next-onboarding-step',
            'primary',
            'contained',
            loading || !onNext || disabledNext,
            onNext,
            onNextText || 'Next',
            hoverTipText
          )}
        </>
      )}
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: `${dialogClass || classes.dialogPaper}`,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
      PaperProps={{ style: { borderColor } }}
    >
      {header}
      {subHeader}
      {hasDivider && <Divider />}
      {loadingBar}
      {content}
      <Divider />
      {footer}
    </Dialog>
  );
};

/** @deprecated in favor of design system component, please use scales/Dialog */
export default memo(withStyles(DialogsStyles)(JoinDialog));
