import { ItemSidebarLocation } from '../../../analytics/analyticsEventProperties';
import {
  DESCRIPTION,
  LUMP_SUM_DISPLAY,
  M,
  MARKUPS,
  P,
  PERCENT_DISPLAY,
  QTY,
  TOTAL,
  UNIT_PRICE,
  U_M,
  Uncategorized,
} from '../../../constants';
import {
  CostDisplay,
  ItemDrawInfo,
  MarkupMode,
  MarkupType,
  MilestoneContingencyInfo,
  PermissionResource,
  Status,
} from '../../../generated/graphql';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import { formatCommas, formatCost, getCurrencyScale } from '../../../utilities/currency';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { EMPTY_COST, formatPercent } from '../../../utilities/string';
import { isNonNullable } from '../../../utilities/types';
import { useCurrentUser } from '../../contexts/current-user';
import { getCostValue } from '../../CostReport/CostReportUtils';
import { generateSpacer } from '../../Select/SelectCategory/SelectCategoryUtils';

// TYPES
type CategoryInfo = {
  categorization?: string;
  category: string;
};

export type FormattedLine = {
  categories?: CategoryInfo[];
  cost: string;
  markupType?: string;
  markupReferences?: string;
  markupValue?: string;
  name: string;
  quantity?: string;
  unit?: string;
  unitPrice?: string;
};

type Lines = NonNullable<Item['activeEstimate']>['lines'][number][];
type Cells = NonNullable<Item['activeEstimate']>['lines'][number]['cells'][number][];
type Fields = NonNullable<Item['activeEstimate']>['fields'][number][];
type Markup =
  | NonNullable<Item['activeEstimate']>['markups'][number]
  | NonNullable<Item['activeEstimate']>['inheritedMarkups'][number];

// HELPER FUNCTIONS
export const findLinesFieldIndex = (fields: Field[], fieldName: string) =>
  fields.findIndex((f: Field) => f.name === fieldName);

export const findFieldType = (fields: Field[], fieldName: string) =>
  fields.find((f) => f.name === fieldName)?.type;

const getRegularCellValue = (cells: Cells, index: number): string => {
  if (cells.length <= index) return '';

  const { value } = cells[index] ?? { value: '' };
  if (value && 'string' in value) return value.string;
  return '';
};

const getCategoryCellValue = (cells: Cells, index: number) => {
  const categoryValue: CategoryCell | undefined | null = cells[index].value as CategoryCell;
  if (categoryValue && categoryValue.category) {
    const { name, number } = categoryValue.category;
    const display = `${number}${generateSpacer(name, number)}${name}`;
    return display;
  }
  return Uncategorized;
};
const getCategoryValue = (fields: Fields, cells: Cells, index: number): CategoryInfo => {
  const categorization = fields[index].categorization?.name;
  const category = getCategoryCellValue(cells, index);
  return { categorization, category };
};

export const getItemEstimateLines = (lines: Lines, fields: Fields): FormattedLine[] => {
  const linesCostIndex = findLinesFieldIndex(fields, TOTAL);
  const linesDescriptionIndex = findLinesFieldIndex(fields, DESCRIPTION);
  const linesQTYIndex = findLinesFieldIndex(fields, QTY);
  const linesUMIndex = findLinesFieldIndex(fields, U_M);
  const linesUnitPriceIndex = findLinesFieldIndex(fields, UNIT_PRICE);
  const unitPriceFieldType = findFieldType(fields, UNIT_PRICE);
  const unitPriceFieldCostSettings = unitPriceFieldType
    ? { scale: getCurrencyScale(unitPriceFieldType) }
    : undefined;

  const linesCostsLines = lines.map((line) => {
    const { cells } = line;
    const cost: string = getRegularCellValue(cells, linesCostIndex);
    const name: string = getRegularCellValue(cells, linesDescriptionIndex);
    const quantity: string = getRegularCellValue(cells, linesQTYIndex);
    const unit: string = getRegularCellValue(cells, linesUMIndex);
    const unitPrice: string = getRegularCellValue(cells, linesUnitPriceIndex);

    const categories: CategoryInfo[] = [];
    if (linesDescriptionIndex > 0) {
      for (let i = 0; i < linesDescriptionIndex; i += 1) {
        categories.push(getCategoryValue(fields, cells, i));
      }
    }

    const formattedLine =
      name.length !== 0 || cost !== '0'
        ? {
            categories,
            cost: formatCost(cost),
            name: name.length !== 0 ? name : EMPTY_COST,
            quantity: formatCommas(quantity),
            unit,
            unitPrice: formatCost(unitPrice, unitPriceFieldCostSettings),
          }
        : null;

    return formattedLine;
  });

  return linesCostsLines.filter(isNonNullable);
};

export const isToggleDisabled = (num: number) => num === 0;

const getReferenceText = (markups: Markup[], rowPrefix: string, reference: string) => {
  let referenceText = reference;
  if (markups.some((r) => r.id === reference)) {
    const index = markups.findIndex((r) => r.id === reference) + 1;
    referenceText = `${rowPrefix}${index}`;
  }
  return referenceText;
};

export type MarkupLine = {
  cost: string;
  markupReferences: string;
  markupType: string;
  markupValue: string;
  name: string;
};

export const getMarkupsLines = (id: string, markups: Markup[]) => {
  const markupsLines: MarkupLine[] = [];
  markups.forEach((markup: Markup) => {
    const { name, percentScale, markupReference, total, type, value } = markup;
    if (!name.length && total.toString() === '0') return;

    const rowPrefix = id === MARKUPS ? M : P;
    const referenceText = markupReference?.appliesTo.map((reference: string) =>
      getReferenceText(markups, rowPrefix, reference)
    );

    const isPercent = type === MarkupType.PERCENT;
    const markupValue = isPercent
      ? formatPercent(value, percentScale ?? undefined)
      : formatCost(value);
    markupsLines.push({
      cost: formatCost(total),
      markupReferences: isPercent && referenceText ? referenceText.join(', ') : EMPTY_COST,
      markupType: isPercent ? PERCENT_DISPLAY : LUMP_SUM_DISPLAY,
      markupValue,
      name: name.length !== 0 ? name : EMPTY_COST,
    });
  });

  return markupsLines;
};

// TODO CT-753: Remove useCalculateItemPermissions and ItemPermissions struct
export type ItemPermissions = {
  canAddItemComments: boolean;
  canDeleteItemIntegrations: boolean;
  canEditEvent: boolean;
  canEditItemAssignee: boolean;
  canEditItemCategories: boolean;
  canEditItemDetails: boolean;
  canEditItemLines: boolean;
  canEditItemMilestone: boolean;
  canEditItemNumber: boolean;
  canEditItemStatus: boolean;
  canEditScheduleImpact: boolean;
  canViewEstimateCostSubtotals: boolean;
  canViewEvent: boolean;
  canViewItemAttachments: boolean;
  canViewItemIntegrations: boolean;
  canViewItemLines: boolean;
  canViewMarkups: boolean;
  canViewScheduleImpact: boolean;
  shouldDisplayCosts: boolean;
};

/**
 * TODO CT-753: Remove useCalculateItemPermissions and ItemPermissions struct.
 * This function still serves a purpose in applying the readOnly boolean to
 * override the edit/add/delete permissions returned to false. However, we can
 * rely on usePermissions to easily access permissions closer to where they are
 * used, and doing that makes it so that we have more transparency on what
 * permission resources are used where and why. We should instead pass readOnly
 * down through the ItemSidebar components and use that to conditionally ignore
 * specific permissions.
 */
export const useCalculateItemPermissions = (
  item: ItemLike,
  projectID: UUID | undefined = undefined,
  readOnly: boolean | undefined = false
) => {
  const costMode = useCostMode();

  // This is required because of a casting issue in ItemSidebarWrapper.tsx
  // TODO: CT-481
  const categories = item?.categories || undefined;
  const userID = useCurrentUser().id;
  const { canView, canEdit, canAdd, canDelete, inPreviewMode, inTrade } = usePermissions({
    projectID,
    trades: categories,
  });
  const didCreateItem = item?.createdBy?.id === userID && !inPreviewMode;
  const isAssignedItem = item?.assignee?.id === userID && !inPreviewMode;
  const canEditItemDetails =
    canEdit(PermissionResource.ITEM_DETAILS) ||
    (canAdd(PermissionResource.ITEM_DETAILS) && (didCreateItem || isAssignedItem));
  const canEditItemNumber = canEdit(PermissionResource.ITEM_NUMBER);
  const canViewItemLines = canView(getItemLinesPermissionResource(inTrade));
  const canEditItemLines = canEdit(getItemLinesPermissionResource(inTrade));
  const canEditItemStatus = canEdit(PermissionResource.ITEM_STATUS);
  const canEditItemAssignee = canEdit(PermissionResource.ITEM_ASSIGNEES);
  const canEditItemCategories = canEdit(PermissionResource.ITEM_CATEGORIES);
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const canAddItemComments = canAdd(PermissionResource.ITEM_COMMENTS);
  const canEditItemMilestone = canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const canViewMarkups =
    canView(PermissionResource.MARKUPS) && checkCostModeIncludesMarkups(costMode);
  const canViewScheduleImpact = canView(PermissionResource.SCHEDULE_IMPACT);
  const canEditScheduleImpact = canEdit(PermissionResource.SCHEDULE_IMPACT);
  const canViewEvent = canView(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const canEditEvent = canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const canViewEstimateCostSubtotals = canView(PermissionResource.ESTIMATE_COST_SUBTOTALS);
  const canViewItemIntegrations = canView(PermissionResource.ITEM_INTEGRATIONS);
  const canDeleteItemIntegrations = canDelete(PermissionResource.ITEM_INTEGRATIONS);
  const { shouldDisplayCosts } = useShouldDisplayCosts(projectID);

  const itemPermissions: ItemPermissions = {
    canAddItemComments: readOnly ? false : canAddItemComments,
    canDeleteItemIntegrations: readOnly ? false : canDeleteItemIntegrations,
    canEditEvent: readOnly ? false : canEditEvent,
    canEditItemAssignee: readOnly ? false : canEditItemAssignee,
    canEditItemCategories: readOnly ? false : canEditItemCategories,
    canEditItemDetails: readOnly ? false : canEditItemDetails,
    canEditItemNumber: readOnly ? false : canEditItemNumber,
    canEditItemLines: readOnly ? false : canEditItemLines,
    canEditItemMilestone: readOnly ? false : canEditItemMilestone,
    canEditItemStatus: readOnly ? false : canEditItemStatus,
    canEditScheduleImpact: readOnly ? false : canEditScheduleImpact,
    canViewEstimateCostSubtotals,
    canViewEvent,
    canViewItemAttachments,
    canViewItemIntegrations,
    canViewItemLines,
    canViewMarkups,
    canViewScheduleImpact,
    shouldDisplayCosts,
  };
  return itemPermissions;
};

export const getSidebarLocation = () => {
  const { pathname } = window.location;
  const itemsList = '/items?';
  const costReport = '/milestones/';
  const benchmarking = '/benchmarking/';
  const home = '/home';
  const scenarios = '/scenarios';

  if (pathname.match(itemsList)) return ItemSidebarLocation.ITEMS_LIST;
  if (pathname.match(costReport)) return ItemSidebarLocation.MILESTONE_SUMMARY_REPORT;
  if (pathname.match(benchmarking)) return ItemSidebarLocation.BENCHMARKING;
  if (pathname.match(home)) return ItemSidebarLocation.HOME;
  if (pathname.match(scenarios)) return ItemSidebarLocation.SCENARIOS;
  return ItemSidebarLocation.ITEM_DETAILS_PAGE;
};

export const calculateCustomCostMode = (
  availableMarkupModes: MarkupMode[],
  canViewDisplayCosts: boolean,
  costMode: CostMode
) => {
  if (availableMarkupModes.includes(costMode.markupMode) || availableMarkupModes.length === 0)
    return {
      ...costMode,
      costDisplay: canViewDisplayCosts ? costMode.costDisplay : CostDisplay.HIDE_COSTS,
    };
  return {
    markupMode: availableMarkupModes[0],
    costDisplay: canViewDisplayCosts ? CostDisplay.SHOW_COSTS : CostDisplay.HIDE_COSTS,
  };
};

export const getContingencyTooltip = (shouldAllowDraws: boolean, status: Status) => {
  if (shouldAllowDraws) {
    return 'Create Contingencies and Allowances within the Milestone Estimate, and then set Items to draw from those Contingencies/Allowances here. To track the accepted and pending draws of the active milestone, view the C/A Report.';
  }
  if (!showContingenciesForItemStatus(status))
    return `Items with a status of Incorporated or Rejected are no longer editable. To make edits to the contingency or allowances, change the Item status to Pending.`;

  return 'Draws can only be added to Items that incur an additional cost to the project estimate';
};

export const shouldItemAllowDraws = (
  draws: ItemDrawInfo[] | undefined,
  available: MilestoneContingencyInfo[] | undefined,
  estimateCost: Cost,
  status: Status
) => {
  const hasActiveDraws = !!draws?.some((d) => !!d.drawFromID);
  const isItemEstimateCostNonNegative = getCostValue(estimateCost) >= 0;

  const shouldAllowDraws =
    (isItemEstimateCostNonNegative || hasActiveDraws) && showContingenciesForItemStatus(status);
  const canAddDraws = shouldAllowDraws && !!available?.length;
  return { shouldAllowDraws, canAddDraws };
};

export const showContingenciesForItemStatus = (status: Status) =>
  status !== Status.INCORPORATED && status !== Status.REJECTED;
