import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

import {
  CHART_FRAME_TITLE_HEIGHT,
  TILE_LARGE_WIDTH,
  TILE_SMALL_WIDTH,
} from './ExecutiveDashboardUtils';

const ExecutiveDashboardStyles = (theme: KomodoTheme) =>
  createStyles({
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    collapse: {
      display: 'flex',
      overflowX: 'auto',
      padding: '0 8px 8px 8px',
    },
    companySelect: {
      margin: '0.5em',
      minWidth: '12em',
      maxWidth: 350,
    },
    contents: {
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    icon: {
      height: 18,
      color: theme.palette.disabledGrey,
    },
    leftAlign: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    tileContainer: {
      display: 'flex',
      flexDirection: 'column',
      border: theme.border.line,
      margin: 5,
      backgroundColor: 'white',
    },
    tileContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
    },
    tileLarge: {
      width: TILE_LARGE_WIDTH,
    },
    tileSmall: {
      width: TILE_SMALL_WIDTH,
    },
    tileTitle: {
      ...theme.typography.h3TableHeader,
      lineHeight: theme.typography.h3TableHeader.fontSize,
      margin: 0,
      paddingLeft: 14,
      display: 'flex',
      alignSelf: 'center',
      textTransform: 'uppercase',
    },
    tileTitleContainer: {
      display: 'flex',
      borderBottom: theme.border.line,
      height: CHART_FRAME_TITLE_HEIGHT,
    },
    title: {
      padding: '17px 16px 15px 16px',
      zIndex: 101,
      borderBottom: theme.border.line,
      backgroundColor: theme.palette.chartHeaderGrey,
    },
    titleText: {
      ...theme.typography.headline,
      marginRight: 20,
      position: 'relative',
      alignSelf: 'center',
    },
  });

export default ExecutiveDashboardStyles;
