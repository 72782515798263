import { getOperationName } from 'apollo-link';
import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import { MutationHookOptions, useMutation } from '@apollo/client';

import { userReportEvent } from '../../analytics/analyticsEventProperties';
import {
  CreateReportDistributionMutation,
  CreateReportDistributionMutationVariables,
  CreateUserReportCommentMutation,
  CreateUserReportCommentMutationVariables,
  CreateUserReportMutation,
  CreateUserReportMutationVariables,
  DeleteReportDistributionMutation,
  DeleteReportDistributionMutationVariables,
  DeleteUserReportCommentMutation,
  DeleteUserReportCommentMutationVariables,
  LoadVarianceReportCommentsQuery,
  LoadVarianceReportCommentsQueryVariables,
  MentionableTextInput,
  RemoveUserReportMutation,
  RemoveUserReportMutationVariables,
  ReportDistributionHistoryQuery,
  ReportDistributionHistoryQueryVariables,
  ReportDistributionInput,
  ReportDistributionQuery,
  ReportDistributionQueryVariables,
  ReportIdByReportDistributionAssetIdQuery,
  ReportIdByReportDistributionAssetIdQueryVariables,
  UnsubscribeReportDistributionMutation,
  UnsubscribeReportDistributionMutationVariables,
  UpdateReportDistributionMutation,
  UpdateReportDistributionMutationVariables,
  UpdateUserReportCommentMutation,
  UpdateUserReportCommentMutationVariables,
  UpdateUserReportMutation,
  UpdateUserReportMutationVariables,
  UserReportCommentInput,
  UserReportCommentViewParameters,
  UserReportNotesPerMilestoneIdQuery,
  UserReportNotesPerMilestoneIdQueryVariables,
  UserReportQuery,
  UserReportQueryVariables,
  UserReportsAndProjectPropsQuery,
  UserReportsAndProjectPropsQueryVariables,
  UserReportsQuery,
  UserReportsQueryVariables,
} from '../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../hooks/useMountAwareQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import {
  createUserReportCommentMutation,
  deleteUserReportCommentMutation,
  loadVarianceReportComments,
  updateUserReportCommentMutation,
  userReportNotesPerMilestoneIDQuery,
} from '../VarianceReport/hooks/queries';

import {
  createReportDistributionMutation,
  createUserReportMutation,
  deleteReportDistributionMutation,
  loadReportDistributionsQuery,
  loadReportIDByReportDistributionAssetIDQuery,
  removeUserReportMutation,
  reportDistributionHistoryQuery,
  unsubscribeReportDistributionMutation,
  updateReportDistributionMutation,
  updateUserReportMutation,
  userReportQuery,
  userReportsAndProjectPropsQuery,
  userReportsQuery,
} from './hooks/queries';

export const useListProjectUserReports = (projectID: UUID) =>
  useQuery<UserReportsQuery, UserReportsQueryVariables>(userReportsQuery, {
    variables: { projectID },
    skip: !projectID,
  });

export const useUserReportsAndProjectPropsQuery = (projectID: UUID) =>
  useQuery<UserReportsAndProjectPropsQuery, UserReportsAndProjectPropsQueryVariables>(
    userReportsAndProjectPropsQuery,
    {
      variables: { projectID },
      skip: !projectID,
    }
  );

export const useUserReportQuery = (projectID?: UUID, reportID?: UUID) =>
  useQuery<UserReportQuery, UserReportQueryVariables>(userReportQuery, {
    variables: { projectID, reportID },
    skip: !projectID || !reportID,
  });

export function useCreateUserReport(
  options: MutationHookOptions<CreateUserReportMutation, CreateUserReportMutationVariables> = {}
) {
  const [createUserReport] = useMutation<
    CreateUserReportMutation,
    CreateUserReportMutationVariables
  >(createUserReportMutation, { ...options });

  const sendAnalytics = useSendAnalytics();
  return (userReport: UserReportInput, projectID: UUID) => {
    return createUserReport({
      variables: {
        projectID,
        userReport,
      },
      refetchQueries: [getOperationName(userReportsQuery)!],
    }).then((report) => {
      sendAnalytics(
        userReportEvent('userReportModal_create', {
          report: {
            id: userReport.id,
            name: userReport.name,
            reportType: userReport.reportType,
            isFollowingActiveMilestone: report.data?.createUserReport.isFollowingActiveMilestone,
          },
        })
      );
    });
  };
}

export function useUpdateUserReport(
  options: MutationHookOptions<UpdateUserReportMutation, UpdateUserReportMutationVariables> = {}
) {
  const [updateUserReport] = useMutation<
    UpdateUserReportMutation,
    UpdateUserReportMutationVariables
  >(updateUserReportMutation, { ...options });

  const sendAnalytics = useSendAnalytics();
  return (userReport: UserReportInput, projectID: UUID) => {
    return updateUserReport({
      variables: {
        projectID,
        userReport,
      },
      refetchQueries: [getOperationName(userReportsQuery)!],
    }).then((report) => {
      sendAnalytics(
        userReportEvent('userReportModal_edit', {
          report: {
            id: userReport.id,
            name: userReport.name,
            reportType: userReport.reportType,
            isFollowingActiveMilestone: report.data?.updateUserReport.isFollowingActiveMilestone,
          },
        })
      );
    });
  };
}

export const useDeleteUserReportMutation = () => {
  const [removeUserReport] = useMutation<
    RemoveUserReportMutation,
    RemoveUserReportMutationVariables
  >(removeUserReportMutation);
  const { projectId } = useParams();
  if (!projectId) throw new Error('projectId is required');

  const sendAnalytics = useSendAnalytics();

  const mutation = (reportID: UUID, onSuccess?: () => void, onFailure?: () => void) =>
    removeUserReport({
      variables: {
        projectID: projectId,
        reportID,
      },
      update: (cache) => {
        cache.modify({
          fields: {
            userReports(userReports, { readField }) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              return userReports.filter((c: any) => reportID !== readField('id', c));
            },
          },
        });
        cache.evict({ id: `UserReport:${reportID}` });
      },
    })
      .then(() => {
        if (onSuccess) onSuccess();
        sendAnalytics(
          userReportEvent('userReportModal_delete', {
            report: { id: reportID },
          })
        );
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return [mutation];
};

export type VarianceReportComments = LoadVarianceReportCommentsQuery['varianceReportComments'];
export type UserReportComment = VarianceReportComments['itemComments'][number];

export const useLoadUserReportComments = (
  report: UUID | undefined,
  userReportCommentViewParameters: UserReportCommentViewParameters
) => {
  // when using the default link to a report view the report ID might be the reportName
  // and not a valid id
  let reportID: UUID | undefined;
  if (isUUID(report ?? '')) reportID = report;
  return useQuery<LoadVarianceReportCommentsQuery, LoadVarianceReportCommentsQueryVariables>(
    loadVarianceReportComments,
    {
      variables: {
        reportID,
        userReportCommentViewParameters,
      },
      skip: !reportID || !userReportCommentViewParameters,
    }
  );
};

export const useCreateUserReportComment = () => {
  const [createUserReportCommentFunc] = useMutation<
    CreateUserReportCommentMutation,
    CreateUserReportCommentMutationVariables
  >(createUserReportCommentMutation);

  // const sendAnalytics = useSendAnalytics();
  return (
    projectID: UUID,
    userReportID: UUID,
    reportCommentInput: UserReportCommentInput,
    onSuccess?: (
      comment: CreateUserReportCommentMutation['createUserReportComment'] | undefined
    ) => void
  ) =>
    createUserReportCommentFunc({
      variables: {
        projectID,
        userReportID,
        reportCommentInput,
      },
      refetchQueries: [
        ...userReportCommentRefetch(userReportID, reportCommentInput.viewParameters),
      ],
    }).then((result) => {
      // TODO: CT-1010: bring back analytics.
      // const mentions = result?.data?.createUserReportComment?.comment?.text?.mentions || [];
      // sendAnalytics(
      //   userReportCommentEvent('userReportComment_create', {
      //     numberOfMentions: mentions.length,
      //   })
      // );
      if (onSuccess) onSuccess(result?.data?.createUserReportComment);
    });
};

export const useUpdateUserReportComment = () => {
  const [updateUserReportCommentFunc] = useMutation<
    UpdateUserReportCommentMutation,
    UpdateUserReportCommentMutationVariables
  >(updateUserReportCommentMutation);

  // const sendAnalytics = useSendAnalytics();

  return (
    projectID: UUID,
    commentID: UUID,
    text: MentionableTextInput,
    costs: USCents[],
    onSuccess?: (
      comment: UpdateUserReportCommentMutation['updateUserReportComment'] | undefined
    ) => void
  ) =>
    updateUserReportCommentFunc({
      variables: {
        projectID,
        commentID,
        text,
        costs,
      },
    }).then((result) => {
      // TODO: CT-1010: bring back analytics.
      // const mentions = result?.data?.updateUserReportComment?.comment?.text?.mentions || [];
      // sendAnalytics(
      //   userReportCommentEvent('userReportComment_edit', {
      //     numberOfMentions: mentions.length,
      //   })
      // );
      if (onSuccess) onSuccess(result?.data?.updateUserReportComment);
    });
};

export const useDeleteUserReportComment = () => {
  const [deleteUserReportCommentFunc] = useMutation<
    DeleteUserReportCommentMutation,
    DeleteUserReportCommentMutationVariables
  >(deleteUserReportCommentMutation);

  // const sendAnalytics = useSendAnalytics();
  return (
    projectID: UUID,
    commentID: UUID,
    userReportID: UUID,
    viewParameters: UserReportCommentViewParameters,
    onSuccess?: (
      comment: DeleteUserReportCommentMutation['deleteUserReportComment'] | undefined
    ) => void
  ) =>
    deleteUserReportCommentFunc({
      variables: {
        projectID,
        commentID,
      },
      refetchQueries: [...userReportCommentRefetch(userReportID, viewParameters)],
    }).then((result) => {
      // sendAnalytics(userReportCommentEvent('userReportComment_delete'));
      if (onSuccess) onSuccess(result?.data?.deleteUserReportComment);
    });
};

const userReportCommentRefetch = (
  userReportID: UUID,
  viewParameters: UserReportCommentViewParameters,
  costMode?: CostMode
) => {
  return [
    {
      query: loadVarianceReportComments,
      variables: {
        reportID: userReportID,
        userReportCommentViewParameters: viewParameters,
        costMode,
      },
    },
  ];
};

export const useCreateReportDistribution = (
  projectID: UUID,
  input: ReportDistributionInput,
  options?: MutationHookOptions<
    CreateReportDistributionMutation,
    CreateReportDistributionMutationVariables
  >
) =>
  useMutation<CreateReportDistributionMutation, CreateReportDistributionMutationVariables>(
    createReportDistributionMutation,
    {
      variables: {
        projectID,
        input,
      },
      ...options,
    }
  );

export const useDeleteReportDistribution = (
  projectID: UUID,
  reportDistributionID: UUID,
  options?: MutationHookOptions<
    DeleteReportDistributionMutation,
    DeleteReportDistributionMutationVariables
  >
) =>
  useMutation<DeleteReportDistributionMutation, DeleteReportDistributionMutationVariables>(
    deleteReportDistributionMutation,
    {
      variables: {
        projectID,
        reportDistributionID,
      },
      ...options,
    }
  );

export const useUpdateReportDistribution = (
  projectID: UUID,
  input: ReportDistributionInput,
  options?: MutationHookOptions<
    UpdateReportDistributionMutation,
    UpdateReportDistributionMutationVariables
  >
) =>
  useMutation<UpdateReportDistributionMutation, UpdateReportDistributionMutationVariables>(
    updateReportDistributionMutation,
    {
      variables: {
        projectID,
        input,
      },
      ...options,
    }
  );

export const useLoadReportDistribution = (
  projectID: UUID,
  reportID: UUID,
  skip: boolean,
  options: QueryHookOptions<ReportDistributionQuery, ReportDistributionQueryVariables> = {}
) =>
  useQuery<ReportDistributionQuery, ReportDistributionQueryVariables>(
    loadReportDistributionsQuery,
    {
      variables: {
        projectID,
        reportID,
      },
      skip: !projectID || !reportID || skip,
      ...options,
    }
  );

export const useReportDistributionHistory = (
  projectID: UUID,
  options: QueryHookOptions<
    ReportDistributionHistoryQuery,
    ReportDistributionHistoryQueryVariables
  > = {}
) =>
  useQuery<ReportDistributionHistoryQuery, ReportDistributionHistoryQueryVariables>(
    reportDistributionHistoryQuery,
    {
      variables: {
        projectID,
      },
      ...options,
    }
  );

export const useUnsubscribeReportDistribution = (
  options?: MutationHookOptions<
    UnsubscribeReportDistributionMutation,
    UnsubscribeReportDistributionMutationVariables
  >
) => {
  const [submitFunc] = useMutation<
    UnsubscribeReportDistributionMutation,
    UnsubscribeReportDistributionMutationVariables
  >(unsubscribeReportDistributionMutation, options);
  return (reportDistributionID: UUID) => submitFunc({ variables: { reportDistributionID } });
};

export const useLoadReportIDByReportDistributionAssetIDQuery = (
  assetID?: UUID,
  options: QueryHookOptions<
    ReportIdByReportDistributionAssetIdQuery,
    ReportIdByReportDistributionAssetIdQueryVariables
  > = {}
) =>
  useQuery<
    ReportIdByReportDistributionAssetIdQuery,
    ReportIdByReportDistributionAssetIdQueryVariables
  >(loadReportIDByReportDistributionAssetIDQuery, {
    variables: {
      assetID,
    },
    skip: !assetID,
    ...options,
  });

export const useUserReportNotesPerMilestoneIDQuery = (
  projectID?: UUID,
  reportID?: UUID,
  options: QueryHookOptions<
    UserReportNotesPerMilestoneIdQuery,
    UserReportNotesPerMilestoneIdQueryVariables
  > = {}
) =>
  useQuery<UserReportNotesPerMilestoneIdQuery, UserReportNotesPerMilestoneIdQueryVariables>(
    userReportNotesPerMilestoneIDQuery,
    {
      variables: {
        projectID,
        reportID,
      },
      skip: !reportID || !projectID,
      ...options,
    }
  );
