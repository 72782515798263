import { FC, useCallback, useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';

import {
  ForecastingReportShareEvent,
  getForecastingReportShareEvent,
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { DD_BENCHMARKING } from '../../../features';
import { ResourceType } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import SharedResourceBadge from '../../dragon-scales/Share/SharedResourceIcon/SharedResourceBadge';
import ShareForecastingReportDialog from '../../ForecastingRoute/Share/ShareForecastingReportDialog';
import { isPrintKeys } from '../../Print/PrintUtils';
import { Button, Icon, Switch } from '../../scales';
import TeamSummary from '../../TeamSummary/TeamSummary';
import ProjectCompsInputsExportPanel from '../ProjectCompsInputs/ProjectCompsInputsExportPanel';
import ProjectCompsInputsSavePanel from '../ProjectCompsInputs/ProjectCompsInputsSavePanel';
import {
  toggleProjectCompSectionCollapsed,
  useIsProjectCompSectionCollapsed,
} from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';
import { DEFAULT_REPORT_NAME, PC_SAVE_ACTION } from '../ProjectCompUtils';

import { ProjectCompsPrintDialog } from './ProjectCompsPrintDialog';

type ProjectCompsHeaderProps = {
  classes: Classes<typeof styles>;
  onSaveAction: (action: PC_SAVE_ACTION) => void;
  exportProjectCompsReport: () => void;
  projectComparisonReport?: ProjectComparisonReport;
  parentProjectID?: UUID;
  projectCompsSet: ProjectCompsSet;
};

const ProjectCompsHeader: FC<ProjectCompsHeaderProps> = ({
  classes,
  exportProjectCompsReport,
  onSaveAction,
  parentProjectID,
  projectComparisonReport,
  projectCompsSet,
}) => {
  // Hooks
  const sendAnalytics = useSendAnalytics();
  const hasBenchmarkingFeature = useHasFeature(DD_BENCHMARKING);

  // State
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const onChartClick = () => {
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_CHARTS_TOGGLE, {
        chartCollapsed,
      })
    );
    toggleProjectCompSectionCollapsed(ProjectCompSectionType.SECTION_GRAPHS);
  };
  const chartCollapsed = useIsProjectCompSectionCollapsed(ProjectCompSectionType.SECTION_GRAPHS);

  // Print
  const reportID = projectComparisonReport?.id;
  const hasExistingReport = !!projectComparisonReport?.name;
  const reportName = projectComparisonReport?.name || DEFAULT_REPORT_NAME;
  const route = reportID
    ? RouteKeys.BENCHMARKING_PROJECT_COMPS_SAVED_PRINT
    : RouteKeys.BENCHMARKING_PROJECT_COMPS_PRINT;
  const printPath = generateSharedPath(route, { reportID });

  const onPrint = useCallback(() => {
    window.open(printPath, '_blank');
  }, [printPath]);

  const onTriggerPrint = useCallback(() => {
    setPrintDialogOpen(true);
  }, [setPrintDialogOpen]);

  const onClickPrintDialogButton = () => {
    setPrintDialogOpen(false);
    onPrint();
  };

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (isPrintKeys(event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        onTriggerPrint();
      }
    };

    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [onTriggerPrint]);

  return (
    <>
      <div className={classes.header}>
        <div className="flex flex-shrink-0 flex-col">
          <div className={classes.flexRow}>
            <Breadcrumbs
              links={[
                {
                  display: hasBenchmarkingFeature ? 'Benchmarking' : 'Forecasting',
                  destination: generateSharedPath(RouteKeys.BENCHMARKING),
                  tooltip: hasBenchmarkingFeature
                    ? 'Back to Benchmarking home'
                    : 'Back to Forecasting home',
                },
                {
                  display: 'Reports',
                  destination: generateSharedPath(RouteKeys.BENCHMARKING_REPORTS),
                  tooltip: hasBenchmarkingFeature
                    ? 'Back to Benchmarking reports list'
                    : 'Back to Forecasting reports list',
                },
                {
                  display: 'Project Comparisons',
                  tooltip: '',
                },
              ]}
            />
          </div>
          <Typography
            className={classes.titleHeading}
            data-cy="CostReport-title"
            variant="headline"
          >
            {reportName || DEFAULT_REPORT_NAME}
          </Typography>
        </div>
        <div className={classes.teamLogosContainer}>
          {parentProjectID && <TeamSummary projectID={parentProjectID} />}
        </div>
        <div className="flex flex-shrink-0 gap-2">
          <Switch
            aria-label="toggle chart"
            data-cy="charts-toggle"
            isChecked={!chartCollapsed}
            isFullWidth
            label="Show Charts"
            onChange={onChartClick}
          />
          {reportID && (
            <div className="flex gap-2">
              <SharedResourceBadge
                resourceID={reportID}
                resourceType={ResourceType.FORECASTING_REPORT}
                tooltipAnalyticsEvent={getForecastingReportShareEvent(
                  ForecastingReportShareEvent.TOOLTIP
                )}
              />
              <Button
                data-cy="share-report-button"
                label="Share"
                onClick={() => {
                  setShareDialogOpen(!shareDialogOpen);
                  const event = shareDialogOpen
                    ? ForecastingReportShareEvent.CLOSE_SHARE_DIALOG
                    : ForecastingReportShareEvent.OPEN_SHARE_DIALOG;
                  sendAnalytics(
                    getForecastingReportShareEvent(event, {
                      location: 'report-ProjectCompsHeader',
                    })
                  );
                }}
                startIcon={<Icon name="shareGroupAdd" />}
                type="secondary"
              />
              {shareDialogOpen && (
                <ShareForecastingReportDialog
                  onClose={() => {
                    sendAnalytics(
                      getForecastingReportShareEvent(
                        ForecastingReportShareEvent.CLOSE_SHARE_DIALOG,
                        { location: 'report-ProjectCompsHeader' }
                      )
                    );
                    setShareDialogOpen(false);
                  }}
                  reportID={reportID}
                  reportName={reportName}
                />
              )}
            </div>
          )}
          <ProjectCompsInputsSavePanel
            hasExistingReport={hasExistingReport}
            onSaveAction={onSaveAction}
          />
          <ProjectCompsInputsExportPanel
            exportProjectCompsReport={exportProjectCompsReport}
            printOnClick={onTriggerPrint}
          />
        </div>
        {printDialogOpen && ( // This allows us to initialize local state at time of open
          <ProjectCompsPrintDialog
            isOpen
            onClickPrint={onClickPrintDialogButton}
            projectComparisonReport={projectComparisonReport}
            projectCompsSet={projectCompsSet}
            setIsOpen={setPrintDialogOpen}
          />
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(ProjectCompsHeader);
