import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../../constants';
import { formatCost } from '../../../../utilities/currency';
import { isNonNullable } from '../../../../utilities/types';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import { NARROW_WIDTH } from '../../../ExecutiveDashboardV2/Charts/XAxis';
import { DETAILS_MARGIN } from '../../../ExecutiveDashboardV2/ProjectDetailsCharts/utils';
import ProjectCostTable from '../../../ExecutiveDashboardV2/Tables/ProjectCostTable';
import useMemoWrapper from '../../../useMemoWrapper';
import CostFillPattern from '../ChartsCostTrendline/CostFillPattern';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import CostYAxis from '../ChartsCostTrendline/CostYAxis';
import TimelineArea from '../TimelineArea';
import TimelineChartTooltip from '../TimelineChartTooltip';
import TimelineCircle from '../TimelineCircle';
import TimelineHoverSections from '../TimelineHoverSections';
import TimelineMultiTooltipContent from '../TimelineMultiTooltip/TimelineMultiTooltipContent';
import TimelinePath from '../TimelinePath';
import TimelineXAxis from '../TimelineXAxis';

import InsightsEmptyChart from './InsightsEmptyChart';
import { CostTimeSeries } from './types';
import { filterCostRange, getYDomain, getYDomainPadded, isNonNullUSCents } from './utils';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  costData: CostTimeSeries[];
  height: number;
  hoverDate?: Date | undefined;
  isMiniChart?: boolean;
  isPrint?: boolean;
  setHoverDate: (date: Date | undefined) => void;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsCostTrendline(props: Props) {
  const { height, hoverDate, isPrint, setHoverDate, today, totalRange } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = props.isMiniChart ? DEFAULT_MARGIN : DETAILS_MARGIN;
  const costData = useMemoWrapper(filterCostRange, props.costData, props.totalRange);
  // Last data index, the first point for horizontal lines after today
  const lastIndex = costData.length - 1;

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, costData);
  // Add vertical padding
  const { yDomain, yMin, yMax } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  // Hover index for tooltips
  const hoverIndex = costData.findIndex(({ date }) => date.getTime() === hoverDate?.getTime());
  const hoverCost = costData[hoverIndex];
  const hasEstimateValue = isNonNullUSCents(hoverCost?.estimate);
  const hasRunningTotalValue = isNonNullUSCents(hoverCost?.runningTotal);
  const hasBudgetValue = isNonNullUSCents(hoverCost?.budget);
  const hasAtLeastOne = hasEstimateValue || hasRunningTotalValue || hasBudgetValue;

  if (!costData.length) {
    return (
      <InsightsEmptyChart height={height} margin={margin} today={today} totalRange={totalRange} />
    );
  }

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {!props.isMiniChart && (
        <>
          <TimelineXAxis
            range={xRange}
            tickTotal={width < NARROW_WIDTH ? 10 : 18}
            x={x}
            y={y}
            yPosition={yMin}
          />
          <CostYAxis
            range={yRange}
            tickFormat={(v: number) => formatCost(v, costFormat)}
            tickTotal={4}
            x={x}
            xPosition={xMin}
            y={y}
          />
        </>
      )}
      {/* Pending Area */}
      <TimelineArea<{ pendingMin: number; pendingMax: number }, { date: Date }>
        data={costData}
        fieldDate="date"
        fieldMax="pendingMax"
        fieldMin="pendingMin"
        fill="url(#stripes)"
        shouldFilterNulls
        stroke="var(--colors-chart-pending-cost-area)"
        x={x}
        y={y}
      />
      {/* Estimate */}
      <TimelinePath<{ estimate: number }>
        // Before today
        className="stroke-entities-estimate"
        data={costData}
        field="estimate"
        hasSteps
        shouldFilterNulls
        strokeDasharray="1, 2"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath<{ estimate: number }>
        // After today
        className="stroke-chart-axis"
        data={[costData[lastIndex], { date: xMax, estimate: costData[lastIndex].estimate }]}
        field="estimate"
        shouldFilterNulls
        strokeDasharray="1, 2"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Running Total */}
      <TimelinePath<{ runningTotal: number }>
        // Before today
        className="stroke-entities-estimate"
        data={costData}
        field="runningTotal"
        shouldFilterNulls
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath<{ runningTotal: number }>
        // After today
        className="stroke-chart-axis"
        data={[costData[lastIndex], { date: xMax, runningTotal: costData[lastIndex].runningTotal }]}
        field="runningTotal"
        shouldFilterNulls
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Budget */}
      <TimelinePath<{ budget: number }>
        // Before today
        className="stroke-entities-milestone"
        data={costData}
        field="budget"
        hasSteps
        shouldFilterNulls
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      <TimelinePath<{ budget: number }>
        // After today
        className="stroke-chart-axis"
        data={[costData[lastIndex], { date: xMax, budget: costData[lastIndex].budget }]}
        field="budget"
        shouldFilterNulls
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Line */}
      {!isPrint && hoverDate && (
        <>
          <TimelinePath<{ value: number }>
            // current hover date
            className="stroke-chart-axis"
            data={[
              { date: hoverDate, value: yMin },
              { date: hoverDate, value: yMax },
            ]}
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelineCircle<{ value: number }>
            data={{ date: hoverDate, value: hoverCost.estimate }}
            fill="fill-button-primary-hover"
            x={x}
            y={y}
          />
          <TimelineCircle<{ value: number }>
            data={{ date: hoverDate, value: hoverCost.runningTotal }}
            fill="fill-entities-estimate"
            x={x}
            y={y}
          />
          <TimelineCircle<{ value: number }>
            data={{ date: hoverDate, value: hoverCost.budget }}
            fill="fill-entities-milestone"
            x={x}
            y={y}
          />
        </>
      )}
      {/* Today */}
      <TimelinePath<{ value: number }>
        className="stroke-selection-focus-fill"
        data={todayData}
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Tooltip */}
      {!isPrint && hoverDate && hasAtLeastOne && (
        <TimelineChartTooltip
          key={`tooltip-${hoverIndex}-${hoverDate}`}
          content={
            props.isMiniChart ? (
              <TimelineMultiTooltipContent
                backgroundColor={[
                  hasEstimateValue ? 'var(--colors-button-primary-hover)' : null,
                  hasRunningTotalValue ? 'var(--colors-button-primary)' : null,
                  hasBudgetValue ? 'var(--colors-entities-milestone)' : null,
                ].filter(isNonNullable)}
                content={[
                  hasEstimateValue ? formatCost(hoverCost.estimate, costFormat) : null,
                  hasRunningTotalValue ? formatCost(hoverCost.runningTotal, costFormat) : null,
                  hasBudgetValue ? formatCost(hoverCost.budget, costFormat) : null,
                ].filter(isNonNullable)}
                data={[
                  // Data set
                  hasEstimateValue ? { ...hoverCost, value: hoverCost.estimate } : null,
                  hasRunningTotalValue ? { ...hoverCost, value: hoverCost.runningTotal } : null,
                  hasBudgetValue ? { ...hoverCost, value: hoverCost.budget } : null,
                ].filter(isNonNullable)}
                yDomain={yDomain}
              />
            ) : (
              <div className="flex flex-col gap-2">
                <b className="type-body2">Trends on {hoverDate.toLocaleDateString()}</b>
                <ProjectCostTable costTimeSeriesData={hoverCost} isChartTooltip />
              </div>
            )
          }
          data={{
            value: (yMin + yMax) / 2,
            date: hoverDate,
          }}
          isMiniChart={!!props.isMiniChart}
          isOpen
          offsetY={height / 2 - 10}
          x={x}
          y={y}
        />
      )}
      {/* Hover Sections */}
      <TimelineHoverSections
        bounds={bounds}
        data={costData}
        onHoverIndex={(index) =>
          index === -1 ? setHoverDate(undefined) : setHoverDate(costData[index]?.date)
        }
        x={x}
      />
      <CostFillPattern />
      <CostLabelBlur />
    </SVGWithDimensions>
  );
}
