import { useCallback, useMemo, useState } from 'react';

import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
  searchEventTypes,
} from '../../../../../analytics/analyticsEventProperties';
import {
  ForecastingProjectsFilters,
  ForecastingProjectsSortBy,
  ForecastingProjectsSortKey,
  SearchResultType,
  SortDirection,
} from '../../../../../generated/graphql';
import useSendAnalytics from '../../../../../hooks/useSendAnalytics';
import { isEnumValue } from '../../../../../utilities/types';
import { getSortManager } from '../../../../ForecastingRoute/ForecastingExplore/projects/hooks/useForecastingProjectsQueryParams';
import { useSavedQuery } from '../../../../ForecastingRoute/hooks/useSavedQuery';
import useProgramsCountsQuery from '../../../../ProjectsList/hooks/useProgramsCountsQuery';
import useProjectsCountsQuery from '../../../../ProjectsList/hooks/useProjectsCountsQuery';
import useCoreSearchPermissions from '../../hooks/useCoreSearchPermissions';
import useSendSearchAnalytics from '../../hooks/useSendSearchAnalytics';
import { SearchToggleParams } from '../../SearchToggle';
import { SearchToggleValue } from '../../types';

export enum ProgramToggleValue {
  PROGRAMS = 'PROGRAMS',
  PROJECTS = 'PROJECTS',
}

type SearchProjectsVariables = {
  filters: ForecastingProjectsFilters;
  search: string;
  sortBy: ForecastingProjectsSortBy;
  toggleValue: SearchToggleValue;
  programToggleValue: ProgramToggleValue;
};

const LOCAL_STORAGE_KEY = 'SEARCH_PROJECTS_QUERY';
const ALL_PROJECTS_LABEL = 'All Projects';
const ALL_PROGRAMS_LABEL = 'All Programs';
const MY_PROJECTS_LABEL = 'My Projects';
const MY_PROGRAMS_LABEL = 'My Programs';

export const DEFAULT_QUERY: SearchProjectsVariables = {
  filters: {
    companies: [],
    estimateCostRange: {},
    gsfRange: {},
    locations: [],
    milestoneDateRange: {},
    milestoneDesignPhases: [],
    projectTypes: [],
    statuses: [],
    types: [],
    programIDs: [],
  },
  search: '',
  sortBy: {
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingProjectsSortKey.UPDATED_AT,
  },
  toggleValue: SearchToggleValue.ALL,
  programToggleValue: ProgramToggleValue.PROJECTS,
};

export default function useSearchProjectsQueryParams() {
  const { hasAllProjectsAccess } = useCoreSearchPermissions();
  const sendSearchAnalytics = useSendSearchAnalytics();
  const sendAnalytics = useSendAnalytics();

  const [savedQuery, setSavedQuery] = useSavedQuery<SearchProjectsVariables>(
    LOCAL_STORAGE_KEY,
    DEFAULT_QUERY
  );

  const { data: projectsCountData } = useProjectsCountsQuery();
  const projectsCounts = projectsCountData?.projectsCounts ?? {
    allProjectsCount: 0,
    myProjectsCount: 0,
  };

  const [programToggleValue, setProgramToggleValue] = useState(
    hasAllProjectsAccess ? savedQuery.programToggleValue : ProgramToggleValue.PROJECTS
  );

  const { data: programsCountData } = useProgramsCountsQuery({
    onCompleted: (counts) => {
      // if the user doesn't have access to any programs
      // then we hide the program / project toggle.  Therefore we need to set
      // the toggle to show projects
      if (counts.programsCounts.allProjectsCount === 0)
        setProgramToggleValue(ProgramToggleValue.PROJECTS);
    },
  });
  const programsCounts = programsCountData?.programsCounts ?? {
    allProjectsCount: 0,
    myProjectsCount: 0,
  };

  const [filters, setFilters] = useState<ForecastingProjectsFilters>(savedQuery.filters);
  const [toggleValue, setToggleValue] = useState(
    hasAllProjectsAccess ? savedQuery.toggleValue : SearchToggleValue.MY
  );

  const [search, setSearch] = useState(savedQuery.search);
  const [sortBy, setSortBy] = useState<ForecastingProjectsSortBy>(savedQuery.sortBy);
  const [unitID, setUnitID] = useState<UUID>();
  const analyticEventTypeName = 'Projects';

  const onChangeFilters = useCallback(
    (
      filterName: keyof ForecastingProjectsFilters,
      filterValue: ForecastingProjectsFilters[keyof ForecastingProjectsFilters],
      isSendAnalytics = true
    ) => {
      setFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      setSavedQuery({
        filters: { [filterName]: filterValue },
      });
      if (isSendAnalytics) {
        sendSearchAnalytics(searchEventTypes.SEARCH_FILTERS, {
          filterSelected: filterValue,
          filterType: filterName,
          type: analyticEventTypeName,
          filterSection: 'projectsFilter',
        });
      }
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onResetFilters = useCallback(() => {
    setFilters(DEFAULT_QUERY.filters);
    setSavedQuery({
      filters: DEFAULT_QUERY.filters,
    });
  }, [setSavedQuery]);

  const onChangeSearch = useCallback(
    (search: string) => {
      setSearch(search);
      setSavedQuery({ search });
      sendSearchAnalytics(searchEventTypes.SEARCH_TEXT_ENTERED, {
        search,
        type: analyticEventTypeName,
        location: 'Home',
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeSortBy = useCallback(
    (sortBy: ForecastingProjectsSortBy) => {
      setSortBy(sortBy);
      setSavedQuery({ sortBy });
      sendSearchAnalytics(searchEventTypes.SEARCH_SORT, {
        sortDirection: sortBy.sortDirection,
        sortKey: sortBy.sortKey,
        type: analyticEventTypeName,
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeToggleValue = useCallback(
    (toggleValue: SearchToggleValue) => {
      setToggleValue(toggleValue);
      setSavedQuery({ toggleValue });
      sendSearchAnalytics(searchEventTypes.SEARCH_TOGGLE, {
        toggleValue,
        type: analyticEventTypeName,
      });
    },
    [sendSearchAnalytics, setSavedQuery]
  );
  const onChangeProgramToggleValue = useCallback(
    (ptv: ProgramToggleValue) => {
      setProgramToggleValue(ptv);
      setSavedQuery({ programToggleValue: ptv });
      sendAnalytics(
        ProgramReportingAnalyticsEvent(ProgramReportingTypes.PROJECT_PROGRAM_TOGGLE, {
          programToggleValue: ptv,
        })
      );
    },
    [sendAnalytics, setSavedQuery]
  );

  const sortManager = useMemo(
    () => getSortManager(sortBy, onChangeSortBy),
    [sortBy, onChangeSortBy]
  );
  const toggleCounts = useMemo(() => {
    if (programToggleValue === ProgramToggleValue.PROJECTS)
      return {
        all: projectsCounts.allProjectsCount,
        my: projectsCounts.myProjectsCount,
      };
    return {
      all: programsCounts.allProjectsCount,
      my: programsCounts.myProjectsCount,
    };
  }, [
    programToggleValue,
    programsCounts.allProjectsCount,
    programsCounts.myProjectsCount,
    projectsCounts.allProjectsCount,
    projectsCounts.myProjectsCount,
  ]);
  const canViewAProgram = programsCounts.allProjectsCount > 0;
  const programToggleCounts = useMemo(
    () => ({
      project:
        toggleValue === SearchToggleValue.ALL
          ? projectsCounts.allProjectsCount
          : projectsCounts.myProjectsCount,
      program:
        toggleValue === SearchToggleValue.ALL
          ? programsCounts.allProjectsCount
          : programsCounts.myProjectsCount,
    }),
    [
      programsCounts.allProjectsCount,
      programsCounts.myProjectsCount,
      projectsCounts.allProjectsCount,
      projectsCounts.myProjectsCount,
      toggleValue,
    ]
  );
  const toggleParams = useMemo(() => {
    if (!hasAllProjectsAccess) return undefined;
    return {
      options: [
        {
          value: SearchToggleValue.ALL,
          label: `${programToggleValue === ProgramToggleValue.PROJECTS ? ALL_PROJECTS_LABEL : ALL_PROGRAMS_LABEL}`,
          count: toggleCounts.all,
        },
        {
          value: SearchToggleValue.MY,
          label: `${programToggleValue === ProgramToggleValue.PROJECTS ? MY_PROJECTS_LABEL : MY_PROGRAMS_LABEL}`,
          count: toggleCounts.my,
        },
      ],
      onChange: (value: string) => {
        if (isEnumValue(SearchToggleValue, value)) onChangeToggleValue(value);
      },
      value: toggleValue,
    };
  }, [
    hasAllProjectsAccess,
    onChangeToggleValue,
    programToggleValue,
    toggleCounts.all,
    toggleCounts.my,
    toggleValue,
  ]);

  const programToggleParams: SearchToggleParams | undefined = useMemo(() => {
    if (!hasAllProjectsAccess) return undefined;
    return {
      options: [
        {
          value: ProgramToggleValue.PROJECTS,
          label: `Projects`,
          count: programToggleCounts.project,
        },
        {
          value: ProgramToggleValue.PROGRAMS,
          label: `Programs`,
          count: programToggleCounts.program,
        },
      ],
      onChange: (value: string) => {
        if (isEnumValue(ProgramToggleValue, value)) onChangeProgramToggleValue(value);
      },
      value: programToggleValue,
    };
  }, [hasAllProjectsAccess, onChangeProgramToggleValue, programToggleCounts, programToggleValue]);

  return useMemo(
    () => ({
      canViewAProgram,
      onChangeFilters,
      onChangeSearch,
      onChangeUnitID: setUnitID,
      onResetFilters,
      sortManager,
      toggleCounts,
      toggleParams,
      programToggleParams,
      variables: {
        filters,
        searchResultType:
          toggleValue === SearchToggleValue.ALL ? SearchResultType.ALL : SearchResultType.MY,
        search,
        sortBy,
        unitID,
        pagination: {
          offset: 0,
          limit: 25,
        },
      },
    }),
    [
      canViewAProgram,
      onChangeFilters,
      onChangeSearch,
      onResetFilters,
      sortManager,
      toggleCounts,
      toggleParams,
      programToggleParams,
      filters,
      toggleValue,
      search,
      sortBy,
      unitID,
    ]
  );
}
