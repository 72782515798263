import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ScenarioShareEvent,
  ScenarioShareKey,
  scenarioShareEvent,
} from '../../../analytics/analyticsEventProperties';
import { PermissionResource } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useCurrentCollaborator } from '../../contexts/current-collaborator';
import { Button, Icon } from '../../scales';

import ShareScenarioDialog from './ShareScenarioDialog';

export type Props = {
  isDisabled?: boolean;
  scenarioColor?: string;
  scenarioCreatorID: UUID;
  scenarioID: UUID;
  scenarioName: string;
};

export default function ShareScenarioButton(props: Props) {
  const analyticsKey = ScenarioShareKey.SCENARIOS;
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');
  const sendAnalytics = useSendAnalytics();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const collaborator = useCurrentCollaborator();
  const isScenarioCreator = collaborator?.user.id === props.scenarioCreatorID;
  const { canEdit } = usePermissions();
  const canShareScenario = canEdit(PermissionResource.SHARE_ITEMS_SCENARIOS) || isScenarioCreator;

  if (!canShareScenario) return null;

  const button = (
    <div>
      <Button
        data-cy="share-scenario-button"
        isDisabled={props.isDisabled}
        label="Share"
        onClick={() => {
          setIsDialogOpen(!isDialogOpen);
          sendAnalytics(scenarioShareEvent(analyticsKey, ScenarioShareEvent.CTA));
        }}
        startIcon={<Icon name="shareGroupAdd" />}
        type="secondary"
      />
    </div>
  );

  const dialog = (
    <>
      {collaborator?.id && (
        <ShareScenarioDialog
          accentColor={props.scenarioColor}
          collaboratorID={collaborator?.id}
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          projectID={projectId}
          scenarioID={props.scenarioID}
          scenarioName={props.scenarioName}
        />
      )}
    </>
  );

  return (
    <>
      {button}
      {isDialogOpen && dialog}
    </>
  );
}
