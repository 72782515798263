import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import useApolloClient from '../api/apollo/useApolloClient';
import { AUTH_REDIRECT } from '../constants';
import { useIsJoinAccessDeactivated } from '../hooks/useIsJoinAccessDeactivated';
import { RouteKeys } from '../routes/paths';
import AppThemeProvider from '../theme/AppThemeProvider';
import { generateSharedPath } from '../utilities/routes/links';

import AppContexts from './contexts/AppContexts';
import useInitHubspotToken from './login/hooks/useInitHubspotToken';
import SignUpLayer from './login/SignUpLayer';
import VerificationLayers from './login/VerificationLayers';

export default function RootRoute() {
  const apolloClient = useApolloClient();

  usePostLoginRedirect();

  return (
    <ApolloProvider client={apolloClient}>
      <AppThemeProvider>
        <VerificationLayers>
          <AppContexts>
            <Inner />
          </AppContexts>
        </VerificationLayers>
      </AppThemeProvider>
    </ApolloProvider>
  );
}

function Inner() {
  useInitHubspotToken();

  const { pathname } = useLocation();
  const isAccessDeactivated = useIsJoinAccessDeactivated();
  if (isAccessDeactivated && pathname !== generateSharedPath(RouteKeys.DEACTIVATED)) {
    return <Navigate to="/deactivated" />;
  }

  return <SignUpLayer />;
}

/**
 * Navigate to the last page the user was on before logging in.
 * We do this because url params are lost after authenticating
 */
function usePostLoginRedirect() {
  const navigate = useNavigate();

  const redirectTo = sessionStorage.getItem(AUTH_REDIRECT);
  useEffect(() => {
    if (redirectTo) {
      sessionStorage.removeItem(AUTH_REDIRECT);
      navigate(redirectTo, { replace: true });
    }
  }, [redirectTo, navigate]);
}
