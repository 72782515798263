import { extent } from 'd3-array';
import { ScaleTime, scaleTime } from 'd3-scale';

import { isNonNullable } from '../../../utilities/types';

type TimeDate = {
  date: Date;
};

type StackValueItem = { name: string; value: number };
export type StackDataItem = { category: string; values: StackValueItem[] };

export function getRange<T extends TimeDate>(data: T[]) {
  const set1 = data.map((d) => new Date(d.date));
  const ext = set1.filter((date) => date);
  const dateRange = extent(ext);
  return dateRange;
}

export function getRangeToday<T extends TimeDate>(data: T[], today: string | Date | undefined) {
  const dataRange = getRange(data);
  const todayDate = new Date(today || data[0]?.date || 0);
  const ext = [...dataRange, todayDate].filter(isNonNullable);
  const dateRange = extent(ext);
  return dateRange;
}

export function getRangeTodayCentered<T extends TimeDate>(
  data: T[],
  today: string | Date | undefined
) {
  const dataRange = getRangeToday(data, today) as Date[];
  const todayDate = new Date(today || data[0]?.date || 0);
  // eslint-disable-next-line no-unsafe-optional-chaining -- TODO CT-1151: Fix this pls :)
  const dStart = todayDate.getTime() - dataRange[0]?.getTime();
  // eslint-disable-next-line no-unsafe-optional-chaining -- TODO CT-1151: Fix this pls :)
  const dEnd = dataRange[1]?.getTime() - todayDate.getTime();
  const delta = Math.abs(dStart > dEnd ? dStart : dEnd);
  const dateRange = [new Date(todayDate.getTime() - delta), new Date(todayDate.getTime() + delta)];
  return dateRange;
}

/**
 * Calculates the [min, max] date range in ISO format.
 *
 * @param data timeline data set for range calculation or single [td] value
 * @param today date to include in range calculation
 *
 */
/** Moves an array of time series to today's date */
export function getTodayRangeStr<T extends TimeDate>(data: T[], today: string | Date | undefined) {
  const [minExt, maxExt] = getRangeTodayCentered(data, today);
  if (!minExt || !maxExt) return ['0', '0'];
  const newMin = minExt.toISOString();
  const newMax = maxExt.toISOString();
  return [newMin, newMax];
}

export const createXScale = (
  range: [string, string] | [Date, Date],
  width: number
): ScaleTime<number, number> => {
  const rangeDates = [new Date(range?.[0] ?? '0'), new Date(range?.[1] ?? '0')];
  return scaleTime()
    .domain(rangeDates as Iterable<Date>)
    .range([0, width - 0]);
};

export const calculatePercentage = (count: number, total: number): number => {
  if (Number(count) === 0 || Number(total) === 0) {
    return 0;
  }
  return Math.floor((100 * count) / total);
};
