import { useParams } from 'react-router-dom';

import { useSortedUnits } from '../../Milestone/hooks/UnitHooks';
import useMilestoneQuantitiesQuery, {
  emptyQuantities,
} from '../../Milestone/hooks/useMilestoneQuantitiesQuery';
import { MetricGroup, MetricGroupRow } from '../CostReportMetricGroup/CostReportMetricGroupUtils';

export const useEnabledUnitQuantities = (milestoneID: UUID) => {
  const { projectId: projectID } = useParams();
  if (!projectID) throw new Error('Project ID not found');
  const { data: { quantities = emptyQuantities } = {} } = useMilestoneQuantitiesQuery(milestoneID);
  const { units } = useSortedUnits(true);
  return { quantities, units, projectID };
};

export const getInitialSelection = (setting: string[], groups: MetricGroup[]) => {
  const initialSelection: string[] = [];
  groups.forEach((group) => {
    const includedRows = group.rows.filter((row: MetricGroupRow) => setting.includes(row.key));
    includedRows.forEach((row: MetricGroupRow) => initialSelection.push(row.key));
  });
  return initialSelection;
};
