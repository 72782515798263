import { FC } from 'react';

import { Dialog, DialogActions, Divider, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import styles from '../../Dialogs/DialogsStyles';
import { Button } from '../../scales';

type ChangeProjectCurrencyModalProps = {
  classes: Classes<typeof styles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

const ScheduleImpactDisableModal: FC<ChangeProjectCurrencyModalProps> = ({
  classes,
  open = false,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <div>
        <div className={classes.titleContainer}>
          <Typography variant="title">Disabling Schedule Impact?</Typography>
        </div>
        <Divider />
        <div className={classes.content}>
          <Typography>
            You just disabled the feature to include Schedule impact data on items. By disabling
            this, the project will not display the information in the following areas:
          </Typography>
          <br />
          <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
            <li>Item Details</li>
            <li>Items List</li>
          </ul>
          <br />
          <Typography>You can change this at any time, by re-enabling it here.</Typography>
        </div>
      </div>
      <Divider />
      <div>
        <DialogActions>
          <Button label="Cancel" onClick={onClose} type="secondary" />
          <Button
            data-cy="schedule-impact-settings-disable-button"
            label="Disable"
            onClick={() => {
              onSubmit();
              onClose();
            }}
            type="primary"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ScheduleImpactDisableModal);
