import { FC } from 'react';

import { Dialog, DialogContent, Divider, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import dialogsStyles from '../../Dialogs/DialogsStyles';
import { Icon, IconButton } from '../../scales';

type MilestoneSettingsReplaceDialogProps = {
  classes: Classes<typeof dialogsStyles>;
  children: JSX.Element;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  term: string;
  onClose: () => void;
};

const MilestoneSettingsReplaceDialog: FC<MilestoneSettingsReplaceDialogProps> = ({
  classes,
  children,
  term,
  open,
  onClose,
}) => {
  return (
    <Dialog
      classes={{ paper: classes.replaceEstimateContainer }}
      disableBackdropClick
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <div style={{ display: 'block' }}>
          <Typography className={classes.newTitleText}>Replace this {term}</Typography>
          <Typography>
            Replace the existing {term.toLowerCase()} by importing or starting a new draft.
          </Typography>
        </div>
        <IconButton
          aria-label="Close dialog"
          icon={<Icon name="close" />}
          onClick={onClose}
          type="secondary"
        />
      </div>
      <Divider />
      <DialogContent className={classes.noPadding}>{children}</DialogContent>
    </Dialog>
  );
};

export default withStyles(dialogsStyles)(MilestoneSettingsReplaceDialog);
