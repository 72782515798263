import { useMemo, useState } from 'react';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { DialogContent, Divider, LinearProgress } from '@material-ui/core';

import { searchOptionsDialogAnalytics } from '../../../analytics/analyticsEventProperties';
import { SORT_NUMBER } from '../../../constants';
import { ResourceType, VisibilityView } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getDraftItemIDs, getSharedUsersMap } from '../../../utilities/items';
import { shouldFilterItem, sortItemsBy } from '../../../utilities/sorting';
import { ItemsOptionsListAddExisting } from '../../Items/ItemsOptionsList/ItemsOptionsListAddExisting';
import { isPrivateVisibility } from '../../Items/ItemsUtils';
import { useGetSharedResources } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { useItemsListQuery } from '../../ItemsList/useItemsListQuery';
import SearchBar from '../../Search/SearchBar/SearchBar';
import useMemoWrapper from '../../useMemoWrapper';

import DialogsNewItemSearchStyles from './DialogsNewItemSearchStyles';

type Event = {
  target: { value: string };
};

type Props = {
  classes: Classes<typeof DialogsNewItemSearchStyles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  convertItem: () => Promise<any>;
  parent: Item;
};

const DialogsNewItemSearch: React.FC<Props> = ({ classes, convertItem, parent }) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const itemsListQueryResult = useItemsListQuery(
    null,
    null,
    projectId,
    true,
    {},
    VisibilityView.HYBRID_VIEW,
    [],
    true
  );
  const items = itemsListQueryResult.data?.itemsList.items ?? [];
  const loading = itemsListQueryResult.loading;

  const draftItemIDs = useMemoWrapper(getDraftItemIDs, items);
  const sharedResourcesResult = useGetSharedResources(draftItemIDs, ResourceType.ITEM);
  const sharedUsersMap = useMemoWrapper(getSharedUsersMap, items, sharedResourcesResult.data);

  const sendAnalytics = useSendAnalytics();

  const [search, setSearch] = useState('');
  const onChange = (evt: Event) => {
    setSearch(evt.target.value);
  };
  // this calculates the filtered items as the search terms are input
  // its similar to using a selector, but using hooks
  const filtered = useMemo(() => {
    if (!items) return [];
    return items
      .slice()
      .sort(sortItemsBy[SORT_NUMBER])
      .filter(
        (i: ItemsListItem) =>
          parent &&
          i.id !== parent.id &&
          shouldFilterItem(i, search, isPrivateVisibility(parent.visibility))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [items.length, parent.id, search]);

  return (
    <div className={classes.root}>
      <Divider />
      <div className={classes.containerSearchBar}>
        <SearchBar
          onChange={onChange}
          onClear={() => setSearch('')}
          onClick={() => sendAnalytics(searchOptionsDialogAnalytics(parent ? parent.id : ''))}
          placeholder="Search for an item..."
          searchTerm={search}
        />
      </div>
      <DialogContent className={classes.dialogContent}>
        {loading && (
          <div className={classes.loading}>
            <LinearProgress hidden={!loading} />
          </div>
        )}
        <ItemsOptionsListAddExisting
          convertItem={convertItem}
          items={filtered}
          parent={parent}
          projectID={projectId}
          projectItems={items}
          sharedUsersMap={sharedUsersMap}
        />
      </DialogContent>
    </div>
  );
};

export default withStyles(DialogsNewItemSearchStyles)(DialogsNewItemSearch);
