import { FC, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import { Add, Bookmark } from '@material-ui/icons';

import { userReportEvent } from '../../../analytics/analyticsEventProperties';
import { currentUserReportVar, setReactiveLocal } from '../../../api/apollo/reactiveVars';
import { USER_REPORT_VAR } from '../../../constants';
import { PermissionResource, UserReportType } from '../../../generated/graphql';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { makeDefaultLevelNames } from '../../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../../utilities/categorization/categorizationUtils';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import DialogsReportsData from '../../Dialogs/DialogsReports/DialogsReportsData';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Chip, IconMenuButton, MenuEntry, MenuSection } from '../../scales';
import { DefaultReports, reportForAnalytics } from '../config';
import { useListProjectUserReports } from '../ReportHooks';

import CurrentReportBar from './CurrentReportBar';
import styles from './ReportManagerMenuStyles';
import { MapReportManagerPagesToValidReports, ReportManagerPages } from './utils';

type ReportManagerMenu = {
  classes: Classes<typeof styles>;
  parentInputReport?: UserReportInput;
  setParentInputReport?: (newInput: UserReportInput) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  setSettings: SetSettingsFunctionType;
  variant: ReportManagerPages;
  isViewOnly?: boolean;
  setShowReportDistributionDialog?: (value: boolean) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  reportModalOpenOverride?: boolean;
  setReportModalOpenOverride?: (value: boolean) => void;
};

// used to set or find the anchor element for the report bar
export const ReportManagerAnchors = (key: 'breakdowns' | 'app'): string => `${key}-anchor`;

const ReportsManagerMenu: FC<ReportManagerMenu> = ({
  classes,
  parentInputReport,
  setParentInputReport,
  settings,
  setSettings,
  variant,
  isViewOnly,
  setShowReportDistributionDialog,
  reportModalOpenOverride,
  setReportModalOpenOverride,
}) => {
  const t = useContext(ProjectTermStore);
  const { projectId, milestoneId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const sendAnalytics = useSendAnalytics();
  const currentReport = useReactiveVar(currentUserReportVar);

  const [reportModalOpen, setReportModalOpen] = useState(false);

  // Used due to the report distribution experiment
  // if we have a value passed in we use that, otherwise we use the local state
  const setReportModalOpenHelper = (value: boolean) => {
    if (setReportModalOpenOverride) {
      setReportModalOpenOverride(value);
    } else {
      setReportModalOpen(value);
    }
  };
  const reportModalOpenValue = reportModalOpenOverride || reportModalOpen;

  let validReportTypes = MapReportManagerPagesToValidReports[variant];
  if (
    currentReport &&
    validReportTypes.some((reportType) => reportType === currentReport.reportType)
  ) {
    validReportTypes = [currentReport.reportType];
  }
  const getNewReport = () => {
    const newReport: UserReportInput = {
      reportType: validReportTypes[0],
      shared: false,
      name: '',
      description: '',
      settings: JSON.stringify(settings),
    };
    if (milestoneId) newReport.milestoneID = milestoneId;
    if (variant === 'msr') newReport.milestoneID = undefined;
    return newReport;
  };
  const [inputReport, setInputReport] = useState<UserReportInput>(getNewReport());
  const handleSetReportInput = (newInput: UserReportInput) => {
    if (setParentInputReport) {
      setParentInputReport(newInput);
    } else {
      setInputReport(newInput);
    }
  };

  const {
    data: { project },
    loading: loadingProjectProps,
  } = useProjectPropsQuery(projectId, MountPolicy.SKIP_ON_MOUNT);
  const name = project?.activeMilestone.name ?? '';

  const {
    data: { userReports } = {
      userReports: [],
    },
    loading: loadingUserReports,
  } = useListProjectUserReports(projectId);
  const loading = loadingProjectProps || loadingUserReports;

  const filteredCustomReports: UserReport[] = useMemo(() => {
    const isValidMSR = (r: UserReport) => {
      if (r.reportType !== UserReportType.USER_REPORT_MSR) return true;
      if (r.milestoneID && milestoneId && r.milestoneID === milestoneId) return true;
      return false;
    };
    if (!loading) {
      return userReports.filter((r) => validReportTypes.includes(r.reportType) && isValidMSR(r));
    }
    return [];
  }, [loading, userReports, validReportTypes, milestoneId]);

  const { canView } = usePermissions();
  const canViewMarkups = canView(PermissionResource.MARKUPS);

  const { data: catData, loading: catzLoading } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const defaultCategorizations = getDefaultReportCategorizations(categorizations);
  const defaultGroupBy = makeDefaultLevelNames(defaultCategorizations);

  const defaultReports = useMemo(
    () =>
      DefaultReports(
        name,
        t.titleCase,
        {
          showContingencyReport: canViewMarkups,
          showMarkupsInTooltip: canViewMarkups,
        },
        defaultGroupBy
      ),
    [name, t, canViewMarkups, defaultGroupBy]
  );

  if (loading || catzLoading) return <LinearProgress hidden={!loading} />;

  const reportEntries: MenuEntry[] = [];

  const switchReport = (r: UserReport): void => {
    setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, r);
    setSettings(JSON.parse(r.settings));
  };

  const sendSwitchEvent = (r: UserReport) =>
    sendAnalytics(
      userReportEvent('userReportMenu_applySwitchReport', {
        page: variant,
        report: reportForAnalytics(r),
      })
    );

  defaultReports
    .filter(
      (r) =>
        validReportTypes.includes(r.reportType) &&
        r.reportType !== UserReportType.USER_REPORT_ITEMS_LIST_DETAILS
    )
    .forEach((r) => {
      reportEntries.push({
        id: r.id,
        label: r.name,
        endAdornment: <Chip text="Default" />,
        onClick: () => {
          sendSwitchEvent(r);
          switchReport(r);
        },
      });
    });
  filteredCustomReports.forEach((r) =>
    reportEntries.push({
      id: r.id,
      label: r.name,
      onClick: () => {
        sendSwitchEvent(r);
        switchReport(r);
      },
    })
  );

  const sections: MenuSection[] = [];
  if (!isViewOnly)
    sections.push({
      'aria-label': 'Create new report',
      entries: [
        {
          startAdornment: <Add />,
          label: 'Create new report',
          onClick: () => {
            sendAnalytics(userReportEvent('userReportMenu_saveReport', { page: variant }));
            handleSetReportInput(getNewReport());
            setReportModalOpenHelper(true);
          },
          id: 'button-saveReport',
        },
      ],
    });
  if (reportEntries.length > 0)
    sections.push({
      label: 'Saved reports',
      entries: reportEntries,
    });

  return (
    <div className={classes.containerOuter}>
      {sections.length > 0 && (
        <IconMenuButton
          aria-label="Reports manager"
          data-cy="button-reportsManager"
          icon={<Bookmark />}
          onOpen={() => sendAnalytics(userReportEvent('userReportMenu_open', { page: variant }))}
          sections={sections}
          type="secondary"
        />
      )}
      <DialogsReportsData
        allowableReportTypes={validReportTypes}
        inputReport={parentInputReport || inputReport}
        isFollowingActiveMilestone={currentReport?.isFollowingActiveMilestone ?? false}
        onClose={() => {
          setReportModalOpenHelper(false);
        }}
        onSuccess={(r) => setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, r)}
        open={reportModalOpenValue}
        setInputReport={handleSetReportInput}
        setShowReportDistributionDialog={setShowReportDistributionDialog}
        variant="reports-manager"
      />
      <CurrentReportBar
        currentMilestoneID={parentInputReport ? parentInputReport.milestoneID : milestoneId}
        isViewOnly={isViewOnly}
        onCreate={() => setReportModalOpenHelper(true)}
        setSettings={setSettings}
        settings={settings}
        variant={variant}
      />
    </div>
  );
};

export default withStyles(styles)(ReportsManagerMenu);
