import { FC, useState } from 'react';

import { Divider, Typography } from '@material-ui/core';
import {
  Assignment,
  Edit,
  LayersOutlined,
  Restore,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { CategorizationDialogType } from '../../../../api/gqlEnums';
import { CompanyCategorization } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import theme, { withStyles } from '../../../../theme/komodo-mui-theme';
import CategorizationsListDialogs from '../../../Categorizations/CategorizationsListDialogs/CategorizationsListDialogs';
import DialogsDeleteCompanyCategorization from '../../../Dialogs/DialogsCompanyCategorizations/DialogsDeleteCompanyCategorization';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import { Icon } from '../../../scales';
import SearchText from '../../../Search/SearchText/SearchText';
import IconMenu from '../../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../../Select/SelectMenu/SelectOption';
import CompanyTabStyles from '../../CompanyTabStyles';
import { formatModifiedAtString } from '../../CompanyTabUtils';

import { useToggleBuiltInCategorization } from './hooks';

type ListEntryProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyCategorization: CompanyCategorization;
  searchTerm: string;
  companyID: UUID;
};

const ListEntry: FC<ListEntryProps> = ({
  classes,
  companyCategorization,
  searchTerm,
  companyID,
}) => {
  const [categorizationDialogType, setCategorizationDialogType] =
    useState<CategorizationDialogType>(CategorizationDialogType.NONE);
  const [showDeleteCategorizationModal, setShowDeleteCategorizationModal] = useState(false);
  const toggleBuiltInCategorization = useToggleBuiltInCategorization();
  const sendAnalytics = useSendAnalytics();

  const builtInMenuOptions: MenuOption[] = [
    {
      callback: () => {
        setCategorizationDialogType(CategorizationDialogType.VIEW);
      },
      name: 'View',
      icon: <Assignment />,
    },
  ];

  // NOTE: We will no longer be supporting built-ins at the company standards level
  // disable/enable would be removed
  const enableMenuOptions: MenuOption[] = [
    {
      callback: () => {
        toggleBuiltInCategorization(companyCategorization.categorization.id, true);
      },
      name: 'Disable',
      icon: <VisibilityOff />,
    },
    {
      callback: () => {
        toggleBuiltInCategorization(companyCategorization.categorization.id, false);
      },
      name: 'Enable',
      icon: <Visibility />,
      color: theme.palette.chartMediumGreen,
    },
  ];

  const isDisabled = companyCategorization.disabled;

  if (!isDisabled) {
    builtInMenuOptions.push(enableMenuOptions[0]);
  } else {
    builtInMenuOptions.push(enableMenuOptions[1]);
  }

  const multilevelMenuOptions: MenuOption[] = [
    {
      callback: () => {
        setCategorizationDialogType(CategorizationDialogType.MULTILEVEL_EDIT);
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_EDIT_CTA, {
            companyID,
            targetID: companyCategorization.categorization.id,
          })
        );
      },
      name: 'Edit',
      icon: <Edit />,
    },
    {
      callback: () => {
        setShowDeleteCategorizationModal(true);
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_DELETE_CTA, {
            companyID,
            targetID: companyCategorization.categorization.id,
          })
        );
      },
      name: 'Delete',
      icon: <Icon name="delete" />,
      color: 'red',
    },
  ];

  const singlelevelMenuOptions: MenuOption[] = [
    {
      callback: () => {
        setCategorizationDialogType(CategorizationDialogType.EDIT);
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_EDIT_CTA, {
            targetID: companyCategorization.categorization.id,
          })
        );
      },
      name: 'Edit',
      icon: <Edit />,
    },
    {
      callback: () => {
        setShowDeleteCategorizationModal(true);
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_DELETE_CTA, {
            companyID,
            targetID: companyCategorization.categorization.id,
          })
        );
      },
      name: 'Delete',
      icon: <Icon name="delete" />,
      color: 'red',
    },
  ];

  const levelMenuOptions =
    companyCategorization.categorization.levels > 1
      ? multilevelMenuOptions
      : singlelevelMenuOptions;

  const isBuiltIn = companyCategorization.disabled !== null;

  const menuOptions = isBuiltIn ? builtInMenuOptions : levelMenuOptions;

  const titleStyle = isDisabled ? classes.textDisabled : classes.textRegular;
  const textStyle = isDisabled ? `${classes.textDisabled} ` : '';

  const { categorization } = companyCategorization;
  const isMultilevel = categorization.levels > 1;

  return (
    <>
      <div className={classes.row} data-cy="categorization-list-entry">
        <div className={`${classes.containerLeft}`}>
          <div className={`${classes.titleContainer}`}>
            <NormalTooltip title={categorization.name}>
              <Typography className={`${titleStyle} ${classes.categorizationTitle}`}>
                <SearchText searchTerm={searchTerm} text={categorization.name} />
              </Typography>
            </NormalTooltip>
          </div>
          {categorization.modifiedAt && (
            <div className={classes.modifiedBy}>
              <Restore className={classes.icon} />
              <Typography variant="body2">
                {formatModifiedAtString(categorization.modifiedAt)} by{' '}
                {categorization.modifiedBy?.name}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.containerMiddle}>
          <div className={classes.flex}>
            {isMultilevel ? (
              <NormalTooltip title="Multi-level categorization">
                <LayersOutlined className={classes.iconPaddingRight} />
              </NormalTooltip>
            ) : (
              ''
            )}
            <NormalTooltip title={categorization.description}>
              <Typography className={`${textStyle}${classes.description}`}>
                <SearchText searchTerm={searchTerm} text={categorization.description} />
              </Typography>
            </NormalTooltip>
          </div>
        </div>
        <div className={classes.containerRight}>
          <div className={`${classes.iconMenu} ${classes.alignItemsCenter}`}>
            {isDisabled && (
              <Typography className={`${classes.paddingRight} ${classes.textDisabled}`}>
                Disabled
              </Typography>
            )}
            <IconMenu options={menuOptions} />
          </div>
        </div>
        {/* Edit Dialog */}
        {categorizationDialogType !== CategorizationDialogType.NONE && (
          <CategorizationsListDialogs
            categorization={companyCategorization.categorization}
            companyID={companyID}
            setType={setCategorizationDialogType}
            type={categorizationDialogType}
          />
        )}

        {/* TO-DO: may need to re-do modal to match CategorizationDialogType, but separate designs for now */}
        <DialogsDeleteCompanyCategorization
          companyCategorization={companyCategorization}
          onClose={() => {
            setShowDeleteCategorizationModal(false);
          }}
          open={showDeleteCategorizationModal}
        />
      </div>
      <Divider />
    </>
  );
};

export default withStyles(CompanyTabStyles)(ListEntry);
