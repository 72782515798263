import { ReactNode, forwardRef, useRef } from 'react';
import { mergeProps, useButton, useFocusRing } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import Badge from '../Badge/Badge';
import { TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';

type Props = TooltipTriggerProps & {
  'aria-label': string;
  badge?: string | number;
  'data-cy'?: string;
  icon: ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
  type?: 'default' | 'destructive';
};

export default forwardRef<HTMLButtonElement, Props>(function BabyButton(props, forwardedRef) {
  const ref = useRef<HTMLButtonElement>(null);

  const { isFocusVisible, focusProps } = useFocusRing();

  const { buttonProps } = useButton(
    {
      'aria-label': props['aria-label'],
      isDisabled: Boolean(props.isDisabled),
      onPress: props.onClick,
    },
    ref
  );

  const classes = [
    'relative flex w-max shrink-0 items-center rounded-full p-1 justify-center transition [&>*]:!icon-sm',
  ];

  if (props.type === 'destructive') {
    classes.push(
      'bg-icon-bg text-button-destructive',
      'hover:enabled:bg-button-destructive-hover',
      isFocusVisible ? 'outline outline-button-destructive' : 'outline-none',
      'disabled:text-type-inactive disabled:border-button-inactive'
    );
  } else {
    classes.push(
      'bg-icon-bg text-type-primary',
      'hover:enabled:bg-button-icon-hover',
      isFocusVisible ? 'outline' : 'outline-none',
      'disabled:text-type-inactive disabled:border-button-inactive'
    );
  }

  // TODO: Re-enable data-cy validation, after MUI IconButton migration is merged
  // validateDataCy(props['data-cy'], 'button');

  return (
    <button
      {...mergeProps(pickTooltipTriggerProps(props), buttonProps, focusProps)}
      ref={composeRefs(ref, forwardedRef)}
      className={[...classes].join(' ')}
      data-cy={props['data-cy']}
    >
      {props.icon}
      {props.badge !== undefined && props.badge !== '' ? (
        <Badge inset={{ right: 12 }}>{props.badge}</Badge>
      ) : null}
    </button>
  );
});
