import { useState } from 'react';
import { Link } from 'react-router-dom';

import { DialogContent } from '@material-ui/core';

import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { pluralizeCountString, pluralizeString, pluralizeWord } from '../../utilities/string';
import { Thumbnail } from '../dragon-scales';
import { Button, Dialog, TextLink } from '../scales';

type Props = {
  selectedCategorization: string;
  missingProjects: ForecastingProject[];
};

export default function ProgramEstimateChartBannerContent(props: Props) {
  const [showDialog, setShowDialog] = useState(false);
  const titleText = `${pluralizeCountString('project', props.missingProjects.length)} ${pluralizeWord('is', props.missingProjects.length)} missing '${props.selectedCategorization}'`;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex flex-col">
          <div className="type-body2">{titleText}</div>
          <div className="type-body3">
            {`The cost from ${pluralizeWord('this', props.missingProjects.length)} ${pluralizeString('project', props.missingProjects.length)} will appear in 'Uncategorized'`}
          </div>
        </div>
        <div className="flex items-center gap-6">
          <TextLink
            className="type-link"
            label="About Categorizations"
            to="https://success.join.build/en/knowledge/getting-started-with-custom-categories"
          />

          <div className="w-40">
            <Button label="View Projects" onClick={() => setShowDialog(true)} type="secondary" />
          </div>
        </div>
      </div>
      <Dialog
        footerRight={<Button label="Close" onClick={() => setShowDialog(false)} type="primary" />}
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        title={titleText}
      >
        <DialogContent>
          <div className="flex flex-col gap-6">
            {props.missingProjects.map((project) => (
              <div key={project.name} className="flex items-center gap-6">
                <Thumbnail size={48} thumbnail={project.thumbnail} />
                <Link
                  className="!type-heading2 type-link"
                  to={generateSharedPath(RouteKeys.PROJECT, {
                    projectId: project.id,
                  })}
                >
                  {project.name}
                </Link>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
