export type ItemTimeSeries = {
  date: Date;
  dueDates: {
    past: number;
    upcoming: number;
    decided: number;
    without: number;
  };
};

export type InsightsMilestone = {
  date: Date;
  id: UUID;
  active: boolean;
  designPhase?: string | null;
  name: string;
};

export enum ItemDueState {
  Upcoming,
  Decided,
  PastDue,
  Missing,
}

export interface TimelineItemData {
  id: UUID;
  name: string;
  dueDate: string;
  dueState: ItemDueState;
}

export interface TimelineItemGroup {
  counter: { [key in ItemDueState]: number };
  data: TimelineItemData[];
  end: string;
  start: string;
}

export enum GroupByType {
  Day,
  Week,
  Month,
}
