import { ProgramEstimateChartOptionsQuery } from '../../generated/graphql';
import { StatusBanner } from '../scales';

import ProgramEstimateChartBannerContent from './ProgramEstimateChartBannerContent';
import { getAllMissingProjectNames } from './ProgramUsersDialog/utils';

type Props = {
  programEstimateChartInfo: ProgramEstimateChartOptionsQuery['programEstimateChartOptions'];
  selectedCategorization: string;
  projects: ForecastingProject[];
};

export default function ProgramEstimateChartBanner(props: Props) {
  const missingProjects = getAllMissingProjectNames(
    props.selectedCategorization,
    props.programEstimateChartInfo.categorizations,
    props.projects
  );
  if (!missingProjects.length) return null;

  return (
    <StatusBanner type="warning">
      <ProgramEstimateChartBannerContent
        missingProjects={missingProjects}
        selectedCategorization={props.selectedCategorization}
      />
    </StatusBanner>
  );
}
