import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode } from 'react';

import { Tooltip } from '../../scales';

type Data = { date: Date; value: number };

const DEFAULT_HOVER_SIZE = 1;
const DEFAULT_OFFSET = 0;

export type Props = {
  content: ReactNode;
  data: Data;
  offsetY?: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
};

export default function TimelineBottomTooltip(props: Props) {
  if (!props.data) return null;

  const hoverAttrs = {
    cx: props.x(props.data.date),
    cy: props.y(props.data.value),
    opacity: 0,
    r: DEFAULT_HOVER_SIZE,
  };

  return (
    <Tooltip
      content={props.content}
      isOpen
      offset={props.offsetY ?? DEFAULT_OFFSET}
      placement="bottom"
    >
      <circle {...hoverAttrs} />
    </Tooltip>
  );
}
