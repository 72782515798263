import { FC, ReactNode, useRef } from 'react';
import { useClickAway, useKey } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { Popper } from '@material-ui/core';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { isSecondPopupVar } from '../../../api/apollo/reactiveVars';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Button, IconButton } from '../../scales';

import styles from './InputsSettingsPanelStyles';

type InputsSettingsProps = {
  children: ReactNode;
  classes: Classes<typeof styles>;
  cy?: string;
  header?: JSX.Element;
  icon: JSX.Element;
  page?: CompanyAdminView;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  show: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showAsButton?: boolean;
  setShow: (show: boolean) => void;
};

const InputsSettingsPanel: FC<InputsSettingsProps> = ({
  children,
  classes,
  cy,
  header,
  icon,
  page,
  show,
  showAsButton,
  setShow,
}) => {
  const isSecondPopup = useReactiveVar(isSecondPopupVar);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const clickawayRef = useRef<HTMLDivElement | null>(null);

  const sendAnalytics = useSendAnalytics();

  const hideIfNoSelects = () => {
    // Don't close the whole panel unless there are no selects in progress
    if (
      document.getElementById('SelectCategory') === null &&
      document.getElementById('InputsFilterSelectOption') === null &&
      document.getElementById('InputsMultiSelectOption') === null
    ) {
      if (isSecondPopup) return;
      setShow(false);
    }
  };

  // Handle interactions
  useClickAway(clickawayRef, () => hideIfNoSelects());
  useKey('Escape', () => hideIfNoSelects());

  const onClick = () => {
    setShow(true);
    if (page === CompanyAdminView.PROJECT_STATS_LIST)
      sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_STATS_FILTER_CLICK));
  };

  return (
    <>
      <div ref={anchorRef} className={classes.containerOuter}>
        {showAsButton ? (
          <Button label="Filter" onClick={onClick} startIcon={icon} type="secondary" />
        ) : (
          <IconButton
            aria-label="Menu button"
            data-cy={cy || 'menu-button'}
            icon={icon}
            onClick={onClick}
            type="secondary"
          />
        )}
      </div>
      <Popper
        anchorEl={anchorRef.current}
        className={`${classes.popper} flex flex-col overflow-hidden`}
        open={show}
        placement="bottom-end"
      >
        <div ref={clickawayRef} className="flex flex-col overflow-y-auto">
          {header && <div className={classes.header}>{header}</div>}
          <div className="flex flex-col gap-2 p-2">{children}</div>
        </div>
      </Popper>
    </>
  );
};
export default withStyles(styles)(InputsSettingsPanel);
