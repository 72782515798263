import { FC, useState } from 'react';

import { Card, CardHeader } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';
import { getCurrencySymbol } from '../../utilities/currency';
import DialogUnits from '../Dialogs/DialogsUnits/DialogsUnits';
import { Button } from '../scales';

import styles from './ProjectUnitsStyles';

type UnitsProps = {
  classes: Classes<typeof styles>;
};

const ProjectUnits: FC<UnitsProps> = ({ classes }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const currencySymbol = getCurrencySymbol();

  return (
    <>
      <Card className={classes.card} elevation={0} square>
        <CardHeader
          action={
            <Button
              data-cy="manage-units-of-measure-button"
              label="Manage Units of Measure"
              onClick={() => setOpen(true)}
              type="primary"
            />
          }
          classes={{
            action: classes.action,
          }}
          id="units" // anchor
          subheader={`Choose which units of measure to track and use to display unit costs (e.g. ${currencySymbol}/GSF, ${currencySymbol}/key)`}
          title="Units of Measure"
        >
          Categorizations
        </CardHeader>
      </Card>
      {isOpen && <DialogUnits onClose={() => setOpen(false)} open={isOpen} />}
    </>
  );
};

export default withStyles(styles)(ProjectUnits);
