import { FC } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import 'react-vis/dist/style.css';

import { Typography } from '@material-ui/core';

import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';

import styles from './MilestoneQuickLinkStyles';

type Props = {
  classes: Classes<typeof styles>;
  milestone: Milestone;
};

const MilestoneQuickLink: FC<Props> = ({ classes, milestone }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const onClick = (navigate: NavigateFunction) => {
    if (milestone.id && projectId) {
      const pathname = generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
        projectId,
        milestoneId: milestone.id,
      });
      const search = ''; // we clear any search terms since we don't need them on the Milestone Page
      navigate({ pathname, search });
    }
  };

  return (
    <span className={classes.root}>
      <span
        className={milestone.id ? classes.milestoneLink : classes.milestoneLinkDisabled}
        onClick={() => onClick(navigate)}
        onKeyPress={() => onClick(navigate)}
        role="link"
        tabIndex={0}
      >
        <Typography className={classes.bold}>{milestone.name}</Typography>
      </span>
    </span>
  );
};

const MilestoneQuickLinkStyled = withStyles(styles)(MilestoneQuickLink);

export default MilestoneQuickLinkStyled;
