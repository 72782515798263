import _ from 'lodash';
import { FC } from 'react';

import { Card, CardHeader, Table, TableBody, TableCell, TableRow } from '@material-ui/core';

import {
  settingNotificationEventTypes,
  settingsNotificationsAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import {
  CommentNotificationSetting,
  LoadUserInAppNotificationSettingQuery,
  LoadUserNotificationSettingQuery,
  NotificationChannel,
} from '../../generated/graphql';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { withStyles } from '../../theme/komodo-mui-theme';
import { useScrollToHashOnce } from '../../utilities/scrolling';
import { Switch } from '../scales';
import OnOffSelect from '../Select/OnOffSelect';

import CommentSettingSelect from './CommentSettingSelect';
import styles from './NotificationSettingsStyles';

const EmailSettingsOff: LoadUserNotificationSettingQuery['userNotificationSetting'] = {
  isGlobalSubscribed: true,
  assignments: false,
  comments: CommentNotificationSetting.NONE,
  mentions: false,
  newItems: false,
  shareScenarios: false,
  __typename: 'UserNotificationSetting',
  channel: NotificationChannel.EMAIL,
};

const IanSettingsOff: LoadUserInAppNotificationSettingQuery['userInAppNotificationSetting'] = {
  ...EmailSettingsOff,
  channel: NotificationChannel.INAPP,
};

type NotificationSettingsProps = {
  classes: Classes<typeof styles>;
  setSetting: (newSetting: LoadUserNotificationSettingQuery['userNotificationSetting']) => void;
  setIanSetting: (
    newSetting: LoadUserInAppNotificationSettingQuery['userInAppNotificationSetting']
  ) => void;
  userNotificationSetting: LoadUserNotificationSettingQuery['userNotificationSetting'];
  userInAppNotificationSetting: LoadUserInAppNotificationSettingQuery['userInAppNotificationSetting'];
  isViewOnly?: boolean;
  isEditTemplate?: boolean;
};
// Assignee
const stringAssigned = 'Assigned items and options';
const stringAssignedDescription =
  'Notify me when someone assigns an item or option in this project to me.';
// Comments
const stringComments = 'Comments';
const stringCommentsDescription = `Notify me when someone comments on an item/option. This notification can be set for all items/options or just relevant ones to me.`;
// Mentions
const stringMentions = 'Mentions';
const stringMentionsDescription =
  'Notify me when someone mentions me in a comment for an item, or a note from a report.';
// New And Share Draft Item
const stringNewAndShareDraftItem = 'New/Draft items and options';
const stringNewAndAddShareDraftItem =
  'Notify me when someone (1) adds a new item or option to this project or (2) shares a draft item or option.';
// New And Share Draft Item
const stringShareScenario = 'Scenarios';
const stringShareScenarioDescription = 'Notify me when someone shares a scenario with me.';

const NotificationSettingsPanel: FC<NotificationSettingsProps> = ({
  classes,
  userNotificationSetting,
  userInAppNotificationSetting,
  setSetting,
  setIanSetting,
  isViewOnly,
  isEditTemplate,
}) => {
  const { isGlobalSubscribed, assignments, comments, mentions, newItems, shareScenarios } =
    userNotificationSetting;
  const {
    isGlobalSubscribed: browserNotification,
    assignments: browserAssignments,
    comments: browserComments,
    mentions: browserMentions,
    newItems: browserNewItems,
    shareScenarios: browserShareScenarios,
  } = userInAppNotificationSetting || {};

  const sendAnalytics = useSendAnalytics();
  const sendSettingsAnalytics = (
    analyticsEventName: settingNotificationEventTypes,
    channel: 'email' | 'browser',
    settingType: string,
    settingResult: string | boolean
  ) => {
    let updatedSettingResult = settingResult;
    if (settingResult === false) {
      updatedSettingResult = 'None';
    } else if (settingResult === true) {
      updatedSettingResult = 'All';
    }
    sendAnalytics(
      settingsNotificationsAnalyticsEvent(analyticsEventName, {
        channel,
        settingType,
        settingResult: updatedSettingResult,
      })
    );
  };

  useScrollToHashOnce(window.location.hash, -40);

  const onChange = (
    updatedSetting: Partial<LoadUserNotificationSettingQuery['userNotificationSetting']>
  ) => {
    const settings = { ...userNotificationSetting, ...updatedSetting };
    if (_.isEqual(EmailSettingsOff, settings)) {
      settings.isGlobalSubscribed = false;
    }
    setSetting(settings);
    // Analytics
    sendSettingsAnalytics(
      settingNotificationEventTypes.SETTINGS_NOTIFICATIONS_CHANGE,
      'email',
      Object.keys(updatedSetting)[0],
      Object.values(updatedSetting)[0]
    );
  };

  const onIanChange = (
    updatedSetting: Partial<LoadUserInAppNotificationSettingQuery['userInAppNotificationSetting']>
  ) => {
    const settings = { ...userInAppNotificationSetting, ...updatedSetting };
    if (_.isEqual(IanSettingsOff, settings)) {
      settings.isGlobalSubscribed = false;
    }
    setIanSetting(settings);
    // Analytics
    sendSettingsAnalytics(
      settingNotificationEventTypes.SETTINGS_NOTIFICATIONS_TOGGLE,
      'browser',
      Object.keys(updatedSetting)[0],
      Object.values(updatedSetting)[0]
    );
  };
  const emailDisabled = isGlobalSubscribed ? '' : classes.disabled;
  const emailCell = `${classes.cell} ${classes.selectorCell} ${emailDisabled}`;

  const browserDisabled = browserNotification ? '' : classes.disabled;
  const browserCell = `${classes.cell} ${classes.selectorCell} ${browserDisabled}`;

  const rowNewAndShareDraftItem = (
    <TableRow className={classes.row}>
      <TableCell className={`${classes.cell} ${classes.leftCell}`}>
        {stringNewAndShareDraftItem}
      </TableCell>
      <TableCell className={emailCell}>
        <OnOffSelect
          cySelect="select-notify-newItems"
          disabled={!isGlobalSubscribed || isEditTemplate}
          onChange={(newItems) => onChange({ newItems })}
          value={newItems}
        />
      </TableCell>
      <TableCell className={browserCell}>
        <OnOffSelect
          cySelect="select-notify-newItems"
          disabled={!browserNotification || isEditTemplate}
          onChange={(newItems) => onIanChange({ newItems })}
          value={browserNewItems}
        />
      </TableCell>
      <TableCell className={classes.cell}>{stringNewAndAddShareDraftItem}</TableCell>
    </TableRow>
  );

  const rowScenarioShare = (
    <TableRow className={classes.row}>
      <TableCell className={`${classes.cell} ${classes.leftCell}`}>{stringShareScenario}</TableCell>
      <TableCell className={emailCell}>
        <OnOffSelect
          cySelect="select-notify-shareScenarios"
          disabled={!isGlobalSubscribed || isEditTemplate}
          onChange={(shareScenarios) => onChange({ shareScenarios })}
          value={shareScenarios}
        />
      </TableCell>
      <TableCell className={browserCell}>
        <OnOffSelect
          cySelect="select-notify-shareScenarios"
          disabled={!browserNotification || isEditTemplate}
          onChange={(shareScenarios) => onIanChange({ shareScenarios })}
          value={browserShareScenarios}
        />
      </TableCell>
      <TableCell className={classes.cell}>{stringShareScenarioDescription}</TableCell>
    </TableRow>
  );

  return (
    <Card className={classes.card} elevation={0} square>
      <CardHeader
        id="Notifications"
        subheader="Stay informed on activity in Join by customizing your own email and browser notifications"
        title="Notifications"
      />
      <Table>
        <TableBody>
          <TableRow className={classes.row}>
            <TableCell className={`${classes.header} ${classes.leftCell} `}>
              Activity Type
            </TableCell>
            <TableCell className={classes.header}>Notification Type</TableCell>
            <TableCell className={classes.header} />
            <TableCell className={classes.header}>Description</TableCell>
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={`${classes.header} ${classes.leftCell} `} />
            <TableCell className={`${classes.header} ${classes.notificationSwitch} `}>
              <div className={classes.subHeaderCell}>
                <Switch
                  id="email-notify-switch"
                  isChecked={isGlobalSubscribed}
                  isDisabled={isViewOnly || isEditTemplate}
                  isFullWidth
                  label="Email"
                  onChange={(checked) => {
                    setSetting({
                      ...userNotificationSetting,
                      isGlobalSubscribed: checked,
                    });
                    // Analytics
                    sendSettingsAnalytics(
                      settingNotificationEventTypes.SETTINGS_NOTIFICATIONS_TOGGLE,
                      'email',
                      'isGlobalSubscribed',
                      checked ? 'On' : 'Off'
                    );
                  }}
                />
              </div>
            </TableCell>
            <TableCell className={`${classes.header} ${classes.notificationSwitch} `}>
              <div className={classes.subHeaderCell}>
                <Switch
                  id="browser-notify-switch"
                  isChecked={browserNotification}
                  isDisabled={isViewOnly || isEditTemplate}
                  isFullWidth
                  label="Browser"
                  onChange={(checked) => {
                    setIanSetting({
                      ...userInAppNotificationSetting,
                      isGlobalSubscribed: checked,
                    });
                    // Analytics
                    sendSettingsAnalytics(
                      settingNotificationEventTypes.SETTINGS_NOTIFICATIONS_TOGGLE,
                      'browser',
                      'isGlobalSubscribed',
                      checked ? 'On' : 'Off'
                    );
                  }}
                />
              </div>
            </TableCell>
            <TableCell className={classes.header} />
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={`${classes.cell} ${classes.leftCell}`}>
              {stringAssigned}
            </TableCell>
            <TableCell className={emailCell}>
              <OnOffSelect
                cySelect="select-notify-assignment"
                disabled={!isGlobalSubscribed || isEditTemplate}
                onChange={(assignments) => onChange({ assignments })}
                value={assignments}
              />
            </TableCell>
            <TableCell className={browserCell}>
              <OnOffSelect
                cySelect="select-notify-assignment"
                disabled={!browserNotification || isEditTemplate}
                onChange={(assignments) => onIanChange({ assignments })}
                value={browserAssignments}
              />
            </TableCell>
            <TableCell className={classes.cell}>{stringAssignedDescription}</TableCell>
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={`${classes.cell} ${classes.leftCell}`}>
              {stringComments}
            </TableCell>
            <TableCell className={emailCell}>
              <CommentSettingSelect
                disabled={!isGlobalSubscribed || isEditTemplate}
                onChange={(comments: CommentNotificationSetting) => onChange({ comments })}
                value={comments}
              />
            </TableCell>
            <TableCell className={browserCell}>
              <CommentSettingSelect
                disabled={!browserNotification || isEditTemplate}
                onChange={(comments: CommentNotificationSetting) => onIanChange({ comments })}
                value={browserComments}
              />
            </TableCell>
            <TableCell className={classes.cell}>{stringCommentsDescription}</TableCell>
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={`${classes.cell} ${classes.leftCell}`}>
              {stringMentions}
            </TableCell>
            <TableCell className={emailCell}>
              <OnOffSelect
                cySelect="select-notify-mentions"
                disabled={!isGlobalSubscribed || isEditTemplate}
                onChange={(mentions) => onChange({ mentions })}
                value={mentions}
              />
            </TableCell>
            <TableCell className={browserCell}>
              <OnOffSelect
                cySelect="select-notify-mentions"
                disabled={!browserNotification || isEditTemplate}
                onChange={(mentions) => onIanChange({ mentions })}
                value={browserMentions}
              />
            </TableCell>
            <TableCell className={classes.cell}>{stringMentionsDescription}</TableCell>
          </TableRow>
          {rowNewAndShareDraftItem}
          {rowScenarioShare}
        </TableBody>
      </Table>
    </Card>
  );
};

export default withStyles(styles)(NotificationSettingsPanel);
