import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { MilestoneEvent, milestoneEvent } from '../../../analytics/analyticsEventProperties';
import {
  importEstimateHelpDialogVar,
  transitionOnboardingVar,
} from '../../../api/apollo/reactiveVars';
import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import { MILESTONES } from '../../../constants';
import { DesignPhaseType, GetMilestoneQuery, PermissionResource } from '../../../generated/graphql';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useCostMode } from '../../../utilities/costMode';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { usePersistentStates } from '../../../utilities/urlState';
import AssetsWrapperMilestoneData from '../../assets/AssetsWrapper/AssetsWrapperMilestoneData';
import { useCurrentUser } from '../../contexts/current-user';
import useCostReportParams from '../../CostReport/CostReport/useCostReportParams';
import useMilestoneCostReportQuery from '../../CostReport/hooks/useMilestoneCostReportQuery';
import MilestoneDesignPhaseSelect from '../../dragon-scales/MilestoneDesignPhaseSelect/MilestoneDesignPhaseSelect';
import { computeCategorizations } from '../../estimate/EstimateAccordion/EstimateAccordion';
import DialogsHelpImportEstimate from '../../ImportEstimate/Modals/DialogsHelpImportEstimate/DialogsHelpImportEstimate';
import { useGridEstimateArmatureQuery } from '../../JoinGrid/hooks/estimateQuery';
import TransitionManager from '../../MilestoneTransition/TransitionManager/TransitionManager';
import { useTimelineQuery } from '../../Timeline/hooks/TimelineHook';
import useMemoWrapper from '../../useMemoWrapper';
import {
  useUpdateMilestoneDate,
  useUpdateMilestoneDesignPhase,
  useUpdateMilestoneName,
} from '../hooks';
import useUpdateMilestoneTimelinePhase from '../hooks/UpdateMilestoneTimelinePhaseHook';
import { useDesignPhaseTypes } from '../hooks/useDesignPhaseTypesQuery';
import MilestoneDatePicker from '../MilestoneDatePicker/MilestoneDatePicker';
import MilestoneDescription from '../MilestoneDescription/MilestoneDescription';
import MilestoneTimelinePhaseSelector from '../MilestoneDesignPhaseSelector/MilestoneTimelinePhaseSelector';
import MilestoneTitle from '../MilestoneTitle/MilestoneTitle';
import { MilestoneDetailsTabs, getTotalMilestoneAttachmentCount } from '../utils';

import MilestoneDetailsEstimate from './MilestoneDetailsEstimate';
import MilestoneDetailsNav from './MilestoneDetailsNav/MilestoneDetailsNav';
import { milestonePageStorageParam } from './MilestoneDetailsNav/MilestoneDetailsNavUtils';
import MilestoneDetailsQuantities from './MilestoneDetailsQuantities/MilestoneDetailsQuantities';
import MilestoneDetailsStyles from './MilestoneDetailsStyles';

type MilestoneDetailsProps = {
  activeMilestoneId: UUID;
  classes: Classes<typeof MilestoneDetailsStyles>;
  enabledUnits: Unit[];
  milestone: NonNullable<GetMilestoneQuery['milestone']>;
  projectName: string;
  refetchMilestone: () => void;
};

const MilestoneDetails: FC<MilestoneDetailsProps> = ({
  activeMilestoneId,
  classes,
  enabledUnits,
  milestone,
  projectName,
  refetchMilestone,
}) => {
  const { milestoneId, projectId } = useParams();
  if (!milestoneId || !projectId) throw new Error('Milestone ID and project ID are required');

  const transitionManagerIsOpen = useReactiveVar(transitionOnboardingVar)?.modalIsOpen;

  const defaults = { view: MilestoneDetailsTabs.BASICS.toString() };
  const [settings, setSettings] = usePersistentStates(
    window.location,
    MILESTONES,
    defaults,
    `${projectId} - Milestone - ${milestoneId} - `
  );
  const { view } = settings;
  const sendAnalytics = useSendAnalytics();
  const setView = (view: string) => {
    sendAnalytics({ type: 'milestone_detailsTab', eventProperties: { view } });
    setSettings({ view });
  };

  const { id, name, activeEstimateID, budgetID, designPhase } = milestone;
  const numMilestoneAttachments = getTotalMilestoneAttachmentCount(milestone);

  const designPhaseTypes = useDesignPhaseTypes();

  const viewHasEstimate =
    (view === 'TARGET' && !!budgetID) || (view === 'ESTIMATE' && !!activeEstimateID);

  const isEstimate = view !== MilestoneDetailsTabs.TARGET.toString();
  const estimateID = isEstimate ? activeEstimateID : budgetID;

  const costMode = useCostMode();
  const [previousCostMode, setPreviousCostMode] = useState(costMode);
  const estimateArmature = useGridEstimateArmatureQuery(
    estimateID ?? undefined,
    undefined,
    costMode
  );

  const fields = estimateArmature.data?.estimate?.fields;
  const categorizations = useMemoWrapper(computeCategorizations, fields);
  const page = 'milestone';
  const milestoneStorageParam = useMemo(() => milestonePageStorageParam(id), [id]);
  const costParams = useCostReportParams(
    categorizations,
    categorizations,
    name,
    page,
    enabledUnits,
    milestoneStorageParam
  );
  const { filterManager } = costParams;
  const { filterQueryInput: viewFilter } = filterManager;

  const { data: costReportData, refetch: refetchReports } = useMilestoneCostReportQuery(
    milestoneId,
    [],
    undefined,
    projectId,
    viewFilter,
    false,
    false,
    true,
    false,
    // We need to refetch when removing view filters.
    // Otherwise, if the user edits the estimate while filtered we show the old
    // (incorrect) cost.
    // Note: useMountAwareQuery does not respect the input cache policy so
    //  we need to set the mount policy instead
    MountPolicy.FETCH_ON_VARS
  );
  const milestoneCostReports = costReportData?.milestoneCostReports ?? [];

  // This isn't ideal, but we need to get the cost reports both with
  // and without owner costs.  The cost displayed in the header
  // excludes owner costs, but the subtotals in the estimate include them
  const {
    data: { milestoneCostReports: reportExcludingOwnerCosts = [] } = {},
    refetch: refetchReportsExcludingOwnerCosts,
  } = useMilestoneCostReportQuery(
    milestoneId,
    [],
    undefined,
    projectId,
    viewFilter,
    false,
    false,
    false,
    false
  );

  // Refetch if cost mode is controlled...
  if (
    previousCostMode.markupMode !== costMode.markupMode ||
    previousCostMode.costDisplay !== costMode.costDisplay
  ) {
    refetchReports();
    refetchReportsExcludingOwnerCosts();
    setPreviousCostMode(costMode);
  }

  // Permissions
  const userID = useCurrentUser().id;
  const { canView, canEdit, canDelete } = usePermissions();
  const didCreateMilestone = milestone.createdBy?.id === userID;
  const canEditDraftMilestones = canEdit(PermissionResource.DRAFT_MILESTONE) || didCreateMilestone;
  const canDeleteDraftMilestones =
    canDelete(PermissionResource.DRAFT_MILESTONE) || didCreateMilestone;
  const canEditMilestones = canEdit(PermissionResource.MILESTONES);
  const canViewMilestoneCosts = canView(PermissionResource.MILESTONE_LINES);
  const canViewAttachments = canView(PermissionResource.MILESTONE_ATTACHMENTS);
  const canViewTimeline = canView(PermissionResource.TIMELINE);
  const canEditTimeline = canEdit(PermissionResource.TIMELINE);

  const shouldBeDisabled = !canEditMilestones || (milestone.isDraft && !canEditDraftMilestones);

  const helpDialog = useReactiveVar(importEstimateHelpDialogVar);

  const isActive = activeMilestoneId === milestoneId; // Active - true, Not Active - false

  const [updateMilestoneName] = useUpdateMilestoneName() as ((
    projectID: string,
    milestoneId: string,
    date: string
  ) => Promise<void>)[];
  const onUpdateMilestoneNameApi = (date: string) => {
    updateMilestoneName(projectId, milestoneId, date);
  };

  const [updateMilestoneDate] = useUpdateMilestoneDate();
  const onUpdateMilestoneDate = (projectId: UUID, milestoneID: UUID, date: string | null) => {
    updateMilestoneDate(projectId, milestoneID, date);
    sendAnalytics(milestoneEvent(MilestoneEvent.BASIC_START_DATE));
  };

  const [selectedDesignPhase, setSelectedDesignPhase] = useState<DesignPhaseType | undefined>(
    designPhase || undefined
  );
  // Design Phase Types
  const [updateMilestoneDesignPhase] = useUpdateMilestoneDesignPhase();
  const onUpdateMilestoneDesignPhaseApi = (designPhase: DesignPhaseType) => {
    setSelectedDesignPhase(designPhase);
    updateMilestoneDesignPhase(projectId, milestoneId, designPhase);
  };

  // Timeline Phase
  const { data } = useTimelineQuery({ projectID: projectId, types: [TimelineActivityType.PHASE] });
  const phases = data?.timeline?.activities ?? [];
  const [updateMilestoneTimelinePhase] = useUpdateMilestoneTimelinePhase();
  const [timelinePhase, setSelectedTimelinePhase] = useState<UUID>(
    milestone?.timelinePhase?.id ?? 'none'
  );
  const [maxDate, setMaxDate] = useState<Date | undefined>(
    milestone.timelinePhase?.endDate ? new Date(milestone.timelinePhase?.endDate) : undefined
  );
  const upsertMilestonePhase = (phaseID: UUID) => {
    if (phaseID === 'none') {
      setSelectedTimelinePhase('none');
      setMaxDate(undefined);
      updateMilestoneTimelinePhase(projectId, {
        milestoneID: milestoneId,
      });
      return;
    }
    setSelectedTimelinePhase(phaseID);
    const phase = phases.find((p) => p.id === phaseID);
    if (phase && phase.endDate) {
      setMaxDate(new Date(phase.endDate));
    }
    updateMilestoneTimelinePhase(projectId, {
      milestoneID: milestoneId,
      phaseID,
    });
    sendAnalytics(milestoneEvent(MilestoneEvent.BASIC_BELONGS_TO));
  };

  const basicsContent = () => (
    <div className={classes.tabContent}>
      <div className="flex flex-col gap-4">
        <div className="flex gap-2">
          <MilestoneTitle
            disabled={shouldBeDisabled}
            name={milestone.name}
            onUpdate={onUpdateMilestoneNameApi}
          />
          <MilestoneDatePicker
            date={milestone.date}
            disabled={shouldBeDisabled}
            maxDate={maxDate}
            minDate={
              milestone.timelinePhase?.startDate
                ? new Date(milestone.timelinePhase?.startDate)
                : undefined
            }
            onUpdate={(date) =>
              onUpdateMilestoneDate(
                projectId,
                milestoneId,
                date ? new Date(date).toISOString() : null
              )
            }
          />
        </div>
        <div className="flex gap-2">
          <div className="min-w-[320px] gap-0.5">
            <div className="type-label">Milestone Design Phase *</div>
            <MilestoneDesignPhaseSelect
              entries={designPhaseTypes}
              isDisabled={shouldBeDisabled}
              onChange={(designPhase: DesignPhaseType) =>
                onUpdateMilestoneDesignPhaseApi(designPhase)
              }
              value={selectedDesignPhase?.id}
            />
          </div>
          {canViewTimeline && (
            <div className="min-w-[320px] gap-0.5">
              <div className="type-label">Belongs to Timeline Phase *</div>
              <MilestoneTimelinePhaseSelector
                disabled={!canEditTimeline}
                milestoneID={milestone.id}
                phases={phases}
                selectedValue={timelinePhase}
                setSelection={upsertMilestonePhase}
                startDate={milestone.date}
              />
            </div>
          )}
        </div>
        <MilestoneDescription
          isDisabled={canEditDraftMilestones === false || shouldBeDisabled}
          milestone={milestone}
          sendAnalytics={sendAnalytics}
        />
      </div>
    </div>
  );

  const assetsContent = () => (
    <div className={classes.tabContent}>
      <div className="mb-6">
        <AssetsWrapperMilestoneData milestoneID={milestoneId} projectID={projectId} />
      </div>
    </div>
  );

  const estimateContent = () => (
    <MilestoneDetailsEstimate
      costParams={costParams}
      milestone={milestone}
      milestoneCostReports={milestoneCostReports}
      refetchMilestone={refetchMilestone}
      refetchReports={() => {
        refetchReports();
        refetchReportsExcludingOwnerCosts();
      }}
      view={view}
    />
  );

  const content: Record<string, JSX.Element> = {
    [MilestoneDetailsTabs.BASICS.toString()]: basicsContent(),
    [MilestoneDetailsTabs.METRICS.toString()]: (
      <MilestoneDetailsQuantities milestoneID={milestoneId} projectID={projectId} />
    ),
    [MilestoneDetailsTabs.ATTACHMENTS.toString()]: assetsContent(),
    [MilestoneDetailsTabs.ESTIMATE.toString()]: estimateContent(),
    [MilestoneDetailsTabs.TARGET.toString()]: estimateContent(),
  };

  const tabContent = content[view];

  return (
    <div className="flex h-full min-w-[1000px] flex-col">
      <MilestoneDetailsNav
        canDeleteDraftMilestones={canDeleteDraftMilestones}
        canEditDraftMilestones={canEditDraftMilestones}
        canEditMilestones={canEditMilestones}
        canViewAttachments={canViewAttachments}
        canViewMilestoneCosts={canViewMilestoneCosts}
        costParams={costParams}
        hasEstimate={viewHasEstimate}
        isActive={isActive}
        milestone={milestone}
        milestoneCostReports={reportExcludingOwnerCosts}
        numMilestoneAttachments={numMilestoneAttachments}
        projectName={projectName}
        setView={setView}
        view={view}
      />
      {tabContent}
      {helpDialog.isOpen && (
        <DialogsHelpImportEstimate
          defaultFilterKey={importEstimateHelpDialogVar().platform}
          onClose={() =>
            importEstimateHelpDialogVar({ isOpen: false, platform: helpDialog.platform })
          }
          open={helpDialog.isOpen}
          subHeaderText="Follow these steps to import an existing estimate file into Join"
        />
      )}
      {transitionManagerIsOpen && <TransitionManager viewHasEstimate={viewHasEstimate} />}
    </div>
  );
};

export default withStyles(MilestoneDetailsStyles)(MilestoneDetails);
