import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import routes from '../routes';

const router = createBrowserRouter(routes);

function App() {
  return <RouterProvider router={router} />;
}

export default Sentry.withProfiler(App);
