import { FC, useRef } from 'react';

import { CloudUpload } from '@material-ui/icons';

import { FilesDialogView } from '../../../../api/apollo/reactiveVars';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { Button } from '../../../scales';

import styles from './FilesDialogUploadButtonStyles';

type FilesDialogUploadButtonProps = {
  classes: Classes<typeof styles>;
  onDropFile: (file: File) => void;
};

const FilesDialogUploadButton: FC<FilesDialogUploadButtonProps> = ({ classes, onDropFile }) => {
  // Handle file upload window
  const fileUpload = useRef<HTMLInputElement | null>(null);
  const addFiles = (files?: FileList | null) => {
    if (files) Array.from(files).forEach((file: File) => onDropFile(file));
  };
  const buttonText = FilesDialogView.DROPZONE;
  const onClick = () => {
    fileUpload?.current?.click();
  };

  return (
    <div>
      <Button
        data-cy="upload-button"
        label={buttonText}
        onClick={onClick}
        startIcon={<CloudUpload className={classes.icon} />}
        type="secondary"
      />
      <input
        ref={fileUpload}
        className={classes.hidden}
        id="upload-files"
        onChange={(evt) => {
          if (evt.target.files) {
            addFiles(evt.target.files);
          }
        }}
        type="file"
      />
    </div>
  );
};

export default withStyles(styles)(FilesDialogUploadButton);
