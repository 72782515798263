import { FC, useContext, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  ChartLabel,
  FlexibleXYPlot,
  Hint,
  HorizontalGridLines,
  LabelSeries,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
  // @ts-ignore JIRA: CT-224
} from 'react-vis/dist';
import 'react-vis/dist/style.css';

import { TermKey } from '../../../api/gqlEnums';
import { VARIANCE_KEY } from '../../../constants';
import { CostReportColumnKey } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import theme from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import truncateLabel, {
  cleanCategoryForChart,
  fontSize,
  tickFormatFn,
} from '../../../utilities/dashboard';
import { generateSharedPath } from '../../../utilities/routes/links';
import { EMPTY_COST, categoryTitle, removeYear } from '../../../utilities/string';
import {
  VarianceColumnDescription,
  getColumnTermFromColumnKey,
} from '../../CostReport/CostReportColumns/CostReportColumns';
import DashboardChartPlaceholder from '../../dashboard/DashboardCharts/DashboardChartsPlaceholder';
import ChartLegend from '../../dragon-scales/TimelineCharts/ChartLegend';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import CTALink from '../../shared-widgets/CTALink';
import {
  CHART_BOTTOM_PADDING,
  CHART_RIGHT_PADDING,
} from '../ChartsAllMilestones/ChartsAllMilestonesUtils';
import { getVarianceDisplayValues } from '../ChartsEstimate/ChartsEstimateUtils';
import { CHART_HEIGHT, allDataFilteredMessage } from '../ChartsUtils';

import styles, {
  CHART_BAR_GAP,
  CHART_LEFT_MARGIN,
  VARIANCE_BAR_WIDTH,
} from './ChartsVarianceStyles';

const NUM_GROUPED_BARS = 2;
const MIN_VARIANCE_BAR_RATIO = 76;
const MAX_PRINT_BARS = 12;

const simpleCost = (v: number) => v / 100;

const getXLabel = (viewBy: string) =>
  removeYear(viewBy).replace('MF ', 'MasterFormat ').replace('UF ', 'UniFormat ');

const getColumnType = (t: TermStore, description: VarianceColumnDescription) => {
  if (!description) return '';
  return description && description.columnKey === CostReportColumnKey.TARGET_KEY
    ? t.lowerCase(TermKey.TARGET)
    : t.lowerCase(TermKey.ESTIMATE);
};

type CategoryHover = Category & Record<string, string>;

type ChartsVarianceProps = {
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
  displayColumnDescriptions: VarianceColumnDescription[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  groupedCategories: any;
  isPrint?: boolean;
  isFiltered?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  legend: any;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  projectId: UUID;
  viewByName?: string;
};

const STARTING_WIDTH = 960;

const ChartsVariance: FC<ChartsVarianceProps> = ({
  classes,
  clearFilters = undefined,
  displayColumnDescriptions,
  groupedCategories,
  isPrint = false,
  isFiltered = false,
  legend,
  loading = false,
  projectId,
  viewByName,
}) => {
  const navigate = useNavigate();
  const t = useContext(ProjectTermStore);
  // only hover if we are not in print
  const [hover, setHover] = useState<CategoryHover | null>(null);

  const setValue = !isPrint ? setHover : () => {};
  const [width, setWidth] = useState(STARTING_WIDTH);
  const xLabel = getXLabel(viewByName || 'Category');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const tickData: (i: number) => any = tickFormatFn(groupedCategories, width);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const barValue = (category: any, y: any) => ({
    x: categoryTitle(category),
    y,
    ...cleanCategoryForChart(category, t),
  });
  const numCats = groupedCategories && groupedCategories.length;
  const shouldRotateXLabels =
    groupedCategories &&
    groupedCategories.some((_c: Category, i: number) => !tickData(i) || !tickData(i).name);
  const shouldRotateVarianceLabels = isPrint
    ? numCats > MAX_PRINT_BARS
    : width / numCats < MIN_VARIANCE_BAR_RATIO;
  const hint = useMemo(() => {
    if (!hover) return null;
    const { name, number } = hover;

    const hoverValues = displayColumnDescriptions
      .filter((column) => column.milestoneName)
      .map((column) => {
        const { chartKey, milestoneName, date, columnKey } = getVarianceDisplayValues(column);
        const cost = hover[chartKey] || 0;
        const costText = cost ? formatCost(cost, { rounded: true }) : EMPTY_COST;
        return { costText, milestoneName, date, columnKey };
      });
    const variance = hover[VARIANCE_KEY] || 0;
    const varianceText = variance ? formatCost(variance, { rounded: true }) : EMPTY_COST;

    return (
      <Hint
        align={{
          horizontal: Hint.ALIGN.AUTO,
          vertical: Hint.ALIGN.AUTO,
        }}
        value={hover}
      >
        <div className={`${classes.hint} rv-hint__content`}>
          <div className={`${classes.bold} ${classes.category}`}>{number}</div>
          <div className={classes.category}>{name}</div>
          {hoverValues.map((value, i) => {
            const key = `${value.milestoneName}-${value.columnKey}${i}-hover`;
            return (
              <div key={key} className={classes.milestoneContainer}>
                <div className={classes.inline}>
                  <div className={classes.overflow}>{`${value.milestoneName}`}</div>
                </div>
                <div className={classes.inline}>
                  <div className={classes.overflow}>
                    {getColumnTermFromColumnKey(t, value.columnKey)}
                  </div>
                  <div className={classes.spacer} />
                  <div className={classes.number}>{value.costText}</div>
                </div>
              </div>
            );
          })}
          <Divider className={classes.divider} />
          <div className={classes.inline}>
            <div className={classes.budget}>Variance</div>
            <div className={classes.spacer} />
            <div className={`${classes.number} ${classes.budget}`}>{varianceText}</div>
          </div>
        </div>
      </Hint>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [classes, displayColumnDescriptions, hover]);

  const description1 = displayColumnDescriptions[0];
  const description2 = displayColumnDescriptions[1];
  const milestonesMatch =
    (description1 && description1.milestoneID) === (description2 && description2.milestoneID);
  const types = displayColumnDescriptions.map((description) => getColumnType(t, description));
  const typesMatch = types[0] === types[1];

  const emptyMilestoneMessage = (
    navigate: NavigateFunction,
    description: VarianceColumnDescription
  ) => {
    const typeText = typesMatch
      ? `milestone ${getColumnType(t, description)}`
      : `${types[0]} or ${types[1]}`;
    const milestoneText = `${milestonesMatch ? 'this milestone' : description.milestoneName}`;
    return (
      <div className={classes.emptyMessageText}>
        <CTALink
          linkText={`${typeText} in ${milestoneText}`}
          onClick={() => {
            navigate(
              generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
                projectId,
                milestoneId: description.milestoneID,
              })
            );
          }}
        />
      </div>
    );
  };

  const emptyChartMessage = (navigate: NavigateFunction) => (
    <Typography className={classes.emptyMessageContainer} variant="subheading">
      <span className={classes.emptyMessageText}>Enter a</span>
      {emptyMilestoneMessage(navigate, description1)}
      {!milestonesMatch && (
        <>
          <span className={classes.emptyMessageText}>or a</span>
          {emptyMilestoneMessage(navigate, description2)}
        </>
      )}
      <span className={classes.emptyMessageText}>to see a chart.</span>
    </Typography>
  );

  const emptyState =
    isFiltered || loading ? (
      <DashboardChartPlaceholder
        clearFilters={clearFilters}
        emptyMessage={allDataFilteredMessage}
        loading={loading}
      />
    ) : (
      <>{!isPrint && emptyChartMessage(navigate)}</>
    );

  // ZOOM RANGE FOR CATEGORIES, ROTATED X LABEL TEXT
  const RANGE_MIN = 20;
  const RANGE_MAX = 60;
  const MIN_ZOOM = 6 / fontSize.name;
  let rotatedFontZoom = 1;
  if (numCats > RANGE_MAX) {
    rotatedFontZoom = MIN_ZOOM;
  } else if (numCats > RANGE_MIN) {
    rotatedFontZoom = 1 - ((numCats - RANGE_MIN) / (RANGE_MAX - RANGE_MIN)) * (1 - MIN_ZOOM);
  }

  const horizontalGrid = (
    <HorizontalGridLines
      style={{ strokeWidth: 2, stroke: theme.palette.primaryGrey }}
      tickTotal={1}
      tickValues={[0]}
    />
  );

  const xAxis = (
    <XAxis
      style={{ fill: theme.palette.primaryGrey }}
      tick
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      tickFormat={(_: any, i: any) => {
        const { tooltip, name, description, cost } = tickData(i);
        // if no name, and in print,
        if (shouldRotateXLabels) {
          return (
            <tspan
              style={{
                fontSize: fontSize.name * rotatedFontZoom,
                fontWeight: 400,
              }}
              x="24"
              y="2"
            >
              {truncateLabel(categoryTitle(groupedCategories[i]), 6, true)}
            </tspan>
          );
        }
        return (
          <tspan>
            <title id={tooltip}>{tooltip}</title>
            <tspan
              dy="1em"
              style={{
                fontSize: fontSize.name,
                fontWeight: 400,
              }}
              x="0"
              y="-8"
            >
              {name}
              &nbsp;
            </tspan>
            <tspan
              dy="1em"
              style={{
                fontSize: fontSize.description,
              }}
              x="0"
            >
              {description}
              &nbsp;
            </tspan>
            <tspan
              dy="1em"
              style={{
                fontSize: fontSize.cost,
                ...theme.typography.number,
              }}
              x="0"
            >
              {cost}
            </tspan>
          </tspan>
        );
      }}
      tickLabelAngle={shouldRotateXLabels ? 45 : 0}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      tickValues={groupedCategories && groupedCategories.map((c: any) => categoryTitle(c))}
    />
  );

  const yAxis = (
    <YAxis
      style={{
        fill: theme.palette.primaryGrey,
      }}
      tickTotal={0}
    />
  );

  const xAxisChartLabel = (
    <ChartLabel
      className={classes.axisLabel}
      includeMargin={false}
      style={{
        y: 80,
        textAnchor: 'middle',
      }}
      text={xLabel}
      xPercent={0.5}
      yPercent={1}
    />
  );

  const varianceBarClasses = [classes.milestone1, classes.milestone2];
  const barData = (index: number) =>
    groupedCategories
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      .map((category: any) => {
        const { chartKey } = getVarianceDisplayValues(displayColumnDescriptions[index]);
        const reportValue = category[chartKey] || 0;
        if (reportValue !== 0) {
          return {
            ...barValue(category, simpleCost(reportValue)),
          };
        }
        return null;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      .filter((c: any) => !!c);

  const varianceBarSeries = (index: number) => (
    <VerticalBarSeries
      barWidth={VARIANCE_BAR_WIDTH}
      className={varianceBarClasses[index]}
      data={barData(index)}
      onValueMouseOut={() => setValue(null)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      onValueMouseOver={(v: any) => setValue(v)}
    />
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const varianceLabelSeries = (category: any, index: number, negativeFactor: number) => {
    const { chartKey } = getVarianceDisplayValues(displayColumnDescriptions[index]);
    const reportValue = category[chartKey] || 0;
    const xOffset =
      (negativeFactor * (width * VARIANCE_BAR_WIDTH)) / (numCats * NUM_GROUPED_BARS) / 2 +
      negativeFactor * CHART_BAR_GAP;
    return (
      <LabelSeries
        key={category.number}
        className={classes.subtotal}
        data={[
          {
            x: categoryTitle(category),
            y: reportValue / 100,
            xOffset,
            yOffset: shouldRotateVarianceLabels ? -20 : -10,
            rotation: shouldRotateVarianceLabels ? -90 : 0,
          },
        ]}
        getLabel={() => `${formatCost(reportValue, { short: true })}`}
        labelAnchorX="middle"
        labelAnchorY="middle"
        stack={false}
      />
    );
  };

  return (
    <div className={classes.root}>
      {groupedCategories && groupedCategories.length > 0 && !loading ? (
        <div
          ref={(r) => {
            if (r && r.getBoundingClientRect()) {
              const newWidth = Math.round(r.getBoundingClientRect().width);
              if (!width || width !== newWidth) {
                setWidth(newWidth);
              }
            }
          }}
          data-cy="ChartsEstimate"
        >
          <div className={classes.legendContainer}>
            <div className={classes.spacer} />
            <ChartLegend legendLabels={legend} />
          </div>
          <FlexibleXYPlot
            className={classes.graph}
            height={CHART_HEIGHT}
            margin={{
              left: CHART_LEFT_MARGIN,
              bottom: CHART_BOTTOM_PADDING,
              right: CHART_RIGHT_PADDING,
            }}
            onMouseLeave={() => setValue(null)}
            width={isPrint ? STARTING_WIDTH : width}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
            xDomain={groupedCategories.map((x: any) => categoryTitle(x))}
            xPadding={60}
            xType="ordinal"
            yBaseValue={0}
            yRange={[290, 20]}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            {horizontalGrid}
            {xAxis}
            {yAxis}
            {xAxisChartLabel}
            {varianceBarSeries(0)}
            {varianceBarSeries(1)}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :) */}
            {groupedCategories.map((category: any) => varianceLabelSeries(category, 0, -1))}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :) */}
            {groupedCategories.map((category: any) => varianceLabelSeries(category, 1, 1))}
            {hint}
          </FlexibleXYPlot>
        </div>
      ) : (
        emptyState
      )}
    </div>
  );
};

export default withStyles(styles)(ChartsVariance);
