import { gql } from '../../../../api/graphqlFragments';
import { ideaFields } from '../../queries';

export const ideaQuery = gql`
  query idea($projectID: UUID!, $ideaID: UUID!) {
    idea(projectID: $projectID, ideaID: $ideaID) {
      ...ideaFields
    }
  }
  ${ideaFields}
`;

export const createItemFromAlternateMutation = gql`
  mutation createItemFromAlternate($ideaID: UUID!, $alternateID: UUID!, $projectID: UUID!) {
    createItemFromAlternate(ideaID: $ideaID, alternateID: $alternateID, projectID: $projectID)
  }
`;

export const createItemFromIdeaMutation = gql`
  mutation createItemFromIdea($ideaID: UUID!, $projectID: UUID!, $hiddenAlternates: [UUID!]!) {
    createItemFromIdea(ideaID: $ideaID, projectID: $projectID, hiddenAlternates: $hiddenAlternates)
  }
`;
