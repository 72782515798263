import { useMemo } from 'react';

import { Thumbnail } from '../dragon-scales';

type Props = {
  projectCount: number;
  projectThumbnail: string;
  projects: { id: UUID; thumbnail: string }[];
  size?: 'sm' | 'md' | 'lg';
};

export default function ProgramThumbnail({
  projectThumbnail,
  projects,
  projectCount,
  size = 'md',
}: Props) {
  let rootStyle = '';
  let thumbnailSize = 0;
  let bottomOffsetStyle = '';
  let countOffset = '';
  let rotatedThumbnailStyle = '';
  switch (size) {
    case 'sm':
      rootStyle = 'h-[42px] w-[42px]';
      bottomOffsetStyle = 'bottom-[32px] ';
      rotatedThumbnailStyle = 'right-2';
      thumbnailSize = 32;
      break;
    case 'md':
      rootStyle = 'h-[62px] w-[62px]';
      bottomOffsetStyle = 'bottom-[45px] left-[5px]';
      countOffset = 'bottom-[105px] left-[45px]';
      rotatedThumbnailStyle = 'top-[5px] right-1';
      thumbnailSize = 50;
      break;
    default:
      rootStyle = 'h-[72px] w-[72px]';
      bottomOffsetStyle = 'bottom-[70px] ';
      countOffset = 'bottom-[150px] left-[65px]';
      rotatedThumbnailStyle = 'right-2';
      thumbnailSize = 70;
      break;
  }

  // pick a thumbnail to the the secondary thumbnal
  const secondThumbnail = useMemo(() => {
    // using a randomly generated number might result in the image changing
    // between renders even when memoizing.  Also, if we have the program thumbnail
    // displayed twice on the same page we want the two instances to match.
    // So instead we'll sort the projects by id, and pick the first
    const sortedList = projects.filter((p) => p.thumbnail !== projectThumbnail).sort(sortByID);
    // If multiple projects share the same image then the length of the sorted
    // list might be zero
    if (!sortedList.length) return projectThumbnail;
    return sortedList[0].thumbnail;
  }, [projectThumbnail, projects]);

  return (
    <div className={rootStyle}>
      <div
        className={`relative z-0 w-min -rotate-12 border border-background-primary ${rotatedThumbnailStyle}`}
      >
        <Thumbnail size={thumbnailSize} thumbnail={secondThumbnail} />
      </div>
      <div
        className={`relative left-1 z-[1] w-min border border-background-primary ${bottomOffsetStyle}`}
      >
        <Thumbnail size={thumbnailSize} thumbnail={projectThumbnail} />
      </div>
      {size !== 'sm' && (
        <div
          className={`relative z-[2] flex min-h-[20px] w-5 flex-col justify-center rounded-[10px] bg-background-1 text-center type-body3 ${countOffset}`}
        >
          {projectCount}
        </div>
      )}
    </div>
  );
}

function sortByID<T extends { id: UUID }>(a: T, b: T) {
  if (a.id > b.id) return 1;
  return -1;
}
