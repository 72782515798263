import { useCallback } from 'react';

import useSendAnalytics from '../../../../hooks/useSendAnalytics';

export enum IdeaEventType {
  CHANGE_DETAILS_TAB = 'changeDetailsTab',
  CREATE_ITEM_FROM_ALTERNATE = 'createItemFromAlternate',
  CREATE_ITEM_FROM_IDEA = 'createItemFromIdea',
  HIDE_ALTERNATE = 'hideAlternate',
  HIDE_RELEVANT_LINES = 'hideRelevantLines',
  RESET_HIDDEN_ALTERNATES = 'resetHiddenAlternates',
  SHOW_RELEVANT_LINES = 'showRelevantLines',
  STAR_IDEA = 'starIdea',
}

type IdeaEventProperties = {
  alternateID?: UUID;
  detailsTab?: string;
  isStarred?: boolean;
};

export function useSendIdeaAnalytics(idea: { id: UUID; name?: string }) {
  const sendAnalytics = useSendAnalytics();

  return useCallback(
    (type: IdeaEventType, eventProperties?: IdeaEventProperties) => {
      sendAnalytics({
        type: `idea_${type}`,
        eventProperties: {
          itemID: idea.id,
          itemName: idea.name,
          ...eventProperties,
        },
      });
    },
    [idea.id, idea.name, sendAnalytics]
  );
}
