import { HasDisplayCosts } from '../conditionals/HasDisplayCosts';

import ScenariosTop from './ScenariosTop';

export function ScenariosRoute() {
  return (
    <HasDisplayCosts>
      <ScenariosTop />
    </HasDisplayCosts>
  );
}
