import 'react-vis/dist/style.css';

import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { DASHBOARD } from '../../../constants';
import ClearFiltersPanelHeader from '../../FilterPanel/ClearFilters/ClearFiltersPanelHeader';
import {
  FilterManager,
  formatFilterSummary,
  getCategorizationsNameMap,
  getHeaderFilterString,
} from '../../FilterPanel/filterUtils';
import useMemoWrapper from '../../useMemoWrapper';

import styles from './DashboardFilterDescriptionStyles';

type DashboardFilterDescriptionProps = {
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  filterManager: FilterManager | undefined;
  isFiltered: boolean;
};
const DashboardFilterDescription: FC<DashboardFilterDescriptionProps> = ({
  categorizations,
  classes,
  filterManager,
  isFiltered,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const categorizationsNameMap = useMemoWrapper(getCategorizationsNameMap, categorizations);

  const filterSummaryLines = useMemoWrapper(
    formatFilterSummary,
    filterManager?.filters,
    categorizationsNameMap,
    400,
    10
  ).map(getHeaderFilterString);

  if (!isFiltered || !filterManager) return null;
  return (
    <div className={classes.filterSummary}>
      {filterSummaryLines.map((line) => (
        <Typography key={line} data-cy={`filter-${line}`} gutterBottom>
          {line}
        </Typography>
      ))}
      {filterManager && (
        <div className={classes.filter}>
          <div className={classes.clear}>
            <ClearFiltersPanelHeader
              filterManager={filterManager}
              projectID={projectId}
              text="clear filters"
              variant={DASHBOARD}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(DashboardFilterDescription);
