import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import { useReactiveVar } from '@apollo/client';

import { currentUserReportVar } from '../../api/apollo/reactiveVars';
import { DISPLAY, HIDE, TOTAL, UNGROUPED, VIEW_FILTER, VIEW_OPTIONS } from '../../constants';
import { CostReportColumnType, UserReportCommentViewParameters } from '../../generated/graphql';
import { categoryDefaultLevelNames } from '../../utilities/categorization';
import { isDateValid } from '../../utilities/dates';
import {
  VarianceColumnDescription,
  getExpressionsFromUnits,
  getMatchingVarianceDescriptions,
  getVarianceReportDescriptions,
} from '../CostReport/CostReportColumns/CostReportColumns';
import { DEPRECATED_DEFAULTS } from '../CostReport/CostReportList/CostReportList/CostReportListUtils';
import { useGetProjectUnitsQuery } from '../Milestone/hooks/UnitHooks';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import { VarianceReportComments } from '../ReportsTab/ReportHooks';

export const displaySettings = [DISPLAY, HIDE];

export const VARIANCE_DEFAULTS: VarianceSettings = {
  groupBy: categoryDefaultLevelNames,
  zeroVariance: DISPLAY,
  estimateLines: DISPLAY,
  milestoneIDs: [],
  types: [CostReportColumnType.ESTIMATE_REPORT, CostReportColumnType.TARGET_REPORT],
  dates: ['', ''],
  varianceColumns: [TOTAL],
  [VIEW_FILTER]: JSON.stringify({}), // we do not currently have view filters enabled on the variance report
  viewMode: VIEW_OPTIONS.CHART_AND_LIST,
  collapse: [UNGROUPED],
  expand: [],
};

export const transformVarianceDefaults = {
  ...VARIANCE_DEFAULTS,
  ...DEPRECATED_DEFAULTS,
};

// our display assumption: show today if no date is in settings
export const LATEST = 'Latest Data';
export const getDate = (date?: string) => date || LATEST;

export const makeSettingDescription = (verb: string, noun: string) => `${verb} ${noun}`;

export const getIsTotalIncluded = (settings: CostReportSettings, enabledUnits: Unit[]): boolean => {
  const columnExpressions: Expression[] = getExpressionsFromUnits(settings, enabledUnits);
  const expressionTotal = columnExpressions.find((exp) => exp.text === TOTAL);
  return !!expressionTotal?.include;
};

export const useIncludedColumnExpressions = (settings: VarianceSettings) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const { data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] } } =
    useGetProjectUnitsQuery(projectId, true);
  // list of units "enabled with "included" settings

  // if any units are not within enabled units, we won't query them...
  // but we will hold onto the setting "just in case"
  const columnExpressions = getExpressionsFromUnits(settings, enabledUnits);
  const includedExpressions = columnExpressions.filter((exp) => exp.include);

  const expressionCount = includedExpressions.length || 0;
  return { includedExpressions, expressionCount };
};

// We need to interpret date inputs as the time at end of day
// for our backend beforeDate to give users
// the data thru that date
export const parseDateToEndOfDay = (date?: string) => {
  if (date && isDateValid(date)) {
    const endOfDay = new Date(date);
    endOfDay.setDate(endOfDay.getDate() + 1);
    endOfDay.setMinutes(endOfDay.getMinutes() - 1);
    // We want to the end of that day returned
    return endOfDay.toISOString();
  }
  // otherwise, act like no date is input (latest)
  return undefined;
};

export const getVarianceColumnInputs = (
  settings: VarianceSettings,
  includedExpressions: Expression[]
) => {
  const { dates, milestoneIDs, types } = settings;
  const varianceColumnInputs: VarianceColumnSet[] = milestoneIDs?.map((milestoneID, i) => {
    let date: string | undefined;
    if (dates) {
      date = parseDateToEndOfDay(dates[i]);
    }
    const columns: CostReportColumnInput[] = [{ type: types[i] }];
    includedExpressions
      .filter((exp) => exp.text !== TOTAL)
      .forEach((columnExpression) => {
        const { id, isCategorized, isMetric } = columnExpression;
        let type = types[i];
        if (isMetric) {
          type = isCategorized
            ? CostReportColumnType.CATEGORIZEDMETRIC_REPORT
            : CostReportColumnType.METRIC_REPORT;
        }
        columns.push({ type, unitID: id, isCategorized });
      });

    return {
      milestoneID,
      columns,
      date,
    };
  });
  return varianceColumnInputs;
};

export const useVarianceDescriptions = (
  varianceReports: VarianceReports,
  settings: VarianceSettings,
  includedExpressions: Expression[],
  expressionCount: number
) => {
  const varianceDescriptions = useVarianceColumnDescriptions(
    varianceReports,
    settings,
    includedExpressions,
    expressionCount
  );

  const headerDescriptions = useVarianceColumnDescriptions(varianceReports, settings, [], 1, true);
  return { varianceDescriptions, headerDescriptions };
};

const useVarianceColumnDescriptions = (
  varianceReports: VarianceReports,
  settings: VarianceSettings,
  includedExpressions: Expression[],
  expressionCount: number,
  isHeader = false
) => {
  const { dates, types } = settings;

  const t = useContext(ProjectTermStore);
  const varianceDescriptions: VarianceColumnDescription[] = [];
  varianceReports.forEach((report, i) => {
    const { milestoneID } = report;
    const milestoneName = report.name ?? '';
    const descriptions = getVarianceReportDescriptions(
      t,
      types,
      milestoneName,
      includedExpressions,
      isHeader
    );
    //  we want to match descriptions only for each milestone
    const matchingDescriptions = getMatchingVarianceDescriptions(
      descriptions as VarianceColumnDescription[],
      i
    );
    matchingDescriptions.forEach((description) => {
      varianceDescriptions.push({
        ...description,
        milestoneID: milestoneID ?? '',
        milestoneName,
        date: dates ? dates[i] : undefined,
        isWide: expressionCount === 1, // Important, this is Total and/or Units
      });
    });
  });

  return varianceDescriptions;
};

export const reverseSetting = (settingArray: string[] | UUID[] | CostReportColumnType[]) => [
  settingArray[1],
  settingArray[0],
];

export const getUserReportCommentQueryVariables = (
  displayGroupBy: DisplayGroupBy[],
  varianceColumnInputs: VarianceColumnSet[]
) => {
  const groupBys = displayGroupBy.map((g) => g.categorization.id);
  const milestoneEstimates = varianceColumnInputs?.map((v) => {
    return {
      milestoneID: v.milestoneID,
      estimateType: v.columns.filter((c) => c.type === 'TARGET_REPORT').length
        ? 'BUDGET'
        : 'ACTIVE_ESTIMATE',
    };
  });
  const viewParams = { groupBys, milestoneEstimates } as UserReportCommentViewParameters;
  return viewParams;
};

export const getVarianceReportCommentCount = (
  varianceReportComments: VarianceReportComments | undefined
) =>
  (varianceReportComments?.itemComments?.length || 0) +
  (varianceReportComments?.itemLineComments?.length || 0) +
  (varianceReportComments?.markupComments?.length || 0) +
  (varianceReportComments?.subtotalComments?.length || 0);

export const useCurrentUserReportID = () => {
  const currentUserReportID = useReactiveVar(currentUserReportVar)?.id;
  // for any built-in reports the ID is going to be the report type
  // ie USER_REPORT_ITEMS_LIST_DETAILS, USER_REPORT_MSR, etc
  return isUUID(currentUserReportID || '') ? currentUserReportID : undefined;
};
