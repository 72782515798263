import { ProgramsCountsQuery, ProgramsCountsQueryVariables } from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';

import { programsCountsQuery } from './queries';

export default function useProgramsCountsQuery(
  options: QueryHookOptions<ProgramsCountsQuery, ProgramsCountsQueryVariables> = {}
) {
  return useQuery<ProgramsCountsQuery, ProgramsCountsQueryVariables>(programsCountsQuery, options);
}
