import { useParams } from 'react-router-dom';

import { Edit } from '@material-ui/icons';

import ItemsButtonAddEstimate from '../../Items/ItemsButtonAddEstimate/ItemsButtonAddEstimate';

type Props = {
  itemID: UUID;
  onItemMutated?: () => void;
  canEditEstimate: boolean;
};

const ItemSidebarNoEstimate = (props: Props) => {
  const { canEditEstimate, itemID } = props;
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  return canEditEstimate ? (
    <div className="bg-background-primary px-5 py-2">
      <ItemsButtonAddEstimate
        icon={<Edit />}
        itemId={itemID}
        onItemMutated={props.onItemMutated}
        projectId={projectId}
        setIsAllExpanded={() => {}}
      />
    </div>
  ) : null;
};

export default ItemSidebarNoEstimate;
