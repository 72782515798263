import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode, useRef } from 'react';

import MultiTooltipOverlay from './TransparentOverlay';

const OFFSET = 8;

type Data = { date: Date; value: number };

export type Props = {
  content: ReactNode;
  data: Data;
  isOpen?: boolean;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
};

export default function TimelineMultiTooltip(props: Props) {
  const ref = useRef<SVGCircleElement>(null);
  if (!props.data) return null;

  const hostAttrs = {
    cx: (props.x(props.data?.date) ?? 0) + OFFSET,
    cy: 0,
    opacity: 0,
    r: 1,
  };

  return (
    <>
      <circle ref={ref} {...hostAttrs} />
      {props.isOpen && (
        <MultiTooltipOverlay isOpen offset={0} triggerRef={ref}>
          {props.content}
        </MultiTooltipOverlay>
      )}
    </>
  );
}
