import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ResourceType, Visibility } from '../../../generated/graphql';
import { hasSharedResourceAccess } from '../../../utilities/utilities';
import { useCurrentUser } from '../../contexts/current-user';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import useMemoWrapper from '../../useMemoWrapper';
import { useSetItemPublished } from '../../ve-item-details/ItemDetailsHooks';
import { isOptionOfPrivateItem, isPrivateVisibility } from '../ItemsUtils';

import ItemVisibilityToggle from './ItemVisibilityToggle';
import { hasPrivateItemEditPermission } from './ItemVisibilityToggleUtils';

type ItemVisibilityToggleEditWrapperProps = {
  createdByID?: UUID;
  isOption: boolean;
  parentVisibility?: Visibility;
  setShowPublishItemDialog: (setShowPublishItemDialog: boolean) => void;
  visibility: Visibility;
};

const ItemVisibilityToggleEditWrapper: FC<ItemVisibilityToggleEditWrapperProps> = ({
  createdByID,
  isOption,
  parentVisibility,
  setShowPublishItemDialog,
  visibility,
}) => {
  // HOOKS
  const { itemId, projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  if (!itemId) throw new Error('Item ID not found');

  const currentUserID = useCurrentUser().id;

  const { data: sharedResourceData } = useGetSharedResource(itemId, ResourceType.ITEM);
  const currentUserHasSharedResourceAccess = useMemoWrapper(
    hasSharedResourceAccess,
    sharedResourceData,
    currentUserID
  );

  const updateVisibility = useSetItemPublished();
  const setUpdateVisibility = () => updateVisibility(projectId, itemId, createdByID);

  // STATE
  const [visibilitySetting, setVisibilitySetting] = useState(visibility);

  // CONSTANTS
  const hasPrivateParent = isOptionOfPrivateItem(isOption, parentVisibility);
  const canViewEditPrivateItem =
    currentUserID &&
    createdByID &&
    (hasPrivateItemEditPermission(createdByID, currentUserID) ||
      currentUserHasSharedResourceAccess);
  const showVisibilityToggle = !!canViewEditPrivateItem && isPrivateVisibility(visibility);

  return showVisibilityToggle ? (
    <ItemVisibilityToggle
      isDisabled={hasPrivateParent}
      itemID={itemId}
      onChange={setUpdateVisibility}
      setShowPublishItemDialog={setShowPublishItemDialog}
      setVisibilitySetting={setVisibilitySetting}
      visibilitySetting={visibilitySetting}
    />
  ) : null;
};

export default ItemVisibilityToggleEditWrapper;
