import { useEffect, useState } from 'react';

import { getTodayUtcNoon } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import InsightsBaseLine from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import InsightsItemsChart from '../../dragon-scales/TimelineCharts/InsightsItems/InsightsItemsChart';
import {
  ItemDueState,
  ItemTimeSeries,
  TimelineItemData,
} from '../../dragon-scales/TimelineCharts/InsightsItems/types';
import { filterItemsRange } from '../../dragon-scales/TimelineCharts/InsightsItems/utils';
import useMemoWrapper from '../../useMemoWrapper';
import TableChartPlaceholder from '../Charts/TableChartPlaceholder';
import { InsightsProject } from '../types';
import { getRange } from '../utils';

import { useItems } from './hooks/useItems';
import { BASE_HEIGHT, CHART_HEIGHT, PADDING, TOTAL_HEIGHT } from './utils';

export default function ProjectItems(props: {
  project: InsightsProject;
  startStr: string;
  endStr: string;
  today?: Date;
  onClick?: () => void;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const projectID = props.project.id;
  const milestones = props.project?.milestones || [];
  const activeMilestoneID = milestones.find((m) => m.active)?.id;

  const { items: realItems, loading } = useItems(projectID, activeMilestoneID || '');
  const demoTimeline = useMemoWrapper(seriesToTimeline, props.project?.itemsDueDateSeriesMockData);
  const items = demoTimeline || realItems;

  // Min and Max date range values for zoom domain and pickers
  const [startStr, setStartStr] = useState<string>(props.startStr);
  const [endStr, setEndStr] = useState<string>(props.endStr);

  useEffect(() => {
    if (startStr !== props.startStr) setStartStr(props.startStr);
    if (endStr !== props.endStr) setEndStr(props.endStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startStr, props.endStr]);

  // Draft milestone may have no date a.date.getTime() > 0
  const baseRange = getRange(milestones.filter(isNonNullable).filter((a) => a.date.getTime() > 0));
  const chartItems = useMemoWrapper(filterItemsRange, items, [
    new Date(startStr),
    new Date(endStr),
  ]);

  if (loading) return <TableChartPlaceholder height={TOTAL_HEIGHT - PADDING * 2} />;

  const chart = (
    <InsightsItemsChart
      hasLinkAccess={props.project.hasAccess}
      height={CHART_HEIGHT}
      isMiniChart
      items={chartItems}
      today={today}
      totalRange={[new Date(startStr), new Date(endStr)]}
    />
  );

  const base = (
    <InsightsBaseLine
      baseRange={baseRange}
      data={milestones}
      height={BASE_HEIGHT}
      projectStatus={props.project.status}
      today={today}
      totalRange={[new Date(startStr ?? '0'), new Date(endStr ?? '0')]}
    />
  );

  return (
    <div
      key={props.project.id}
      className={`flex h-[${TOTAL_HEIGHT}px] min-w-full cursor-pointer flex-col bg-background-primary`}
      onClick={props.onClick}
      onKeyPress={props.onClick}
    >
      {chart}
      {base}
    </div>
  );
}

export const seriesToTimeline = (
  items: ItemTimeSeries[] | undefined
): TimelineItemData[] | undefined => {
  if (!items) return undefined;
  const series: TimelineItemData[] = [];
  items.forEach((point) => {
    const {
      date,
      dueDates: { past, upcoming, decided, without },
    } = point;
    const dueDate = date.toISOString();
    // Past
    const pastArr: TimelineItemData[] = Array(past).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.PastDue,
    });
    series.push(...pastArr);
    // Upcoming
    const upcomingArr: TimelineItemData[] = Array(upcoming).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.Upcoming,
    });
    series.push(...upcomingArr);
    // Decided
    const decidedArr: TimelineItemData[] = Array(decided).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.Decided,
    });
    series.push(...decidedArr);
    //  Without
    const withoutArr: TimelineItemData[] = Array(without).fill({
      id: '',
      name: 'Item',
      dueDate,
      dueState: ItemDueState.Decided,
    });
    series.push(...withoutArr);
  });
  return series;
};
