import { useEffect } from 'react';

import { usePersistentStates } from '../../utilities/urlState';
import { useFilterProjects } from '../ProjectsList/ProjectsListUtils';

import InsightsProjectsList from './InsightsProjectsList';
import { INSIGHTS_DEFAULTS } from './utils';

export default function InsightsProjects(props: { companyID: UUID }) {
  // Filtering
  const [settings, setSettings] = usePersistentStates(
    window.location,
    '',
    INSIGHTS_DEFAULTS,
    'Insights Filters - ',
    (settings) => {
      const newSettings = settings;
      if (!settings.estimateCostRange.min && !settings.estimateCostRange.max) {
        newSettings.estimateCostRange = {
          max: null,
          min: null,
        };
      }
      if (!settings.gsfRange.min && !settings.gsfRange.max) {
        newSettings.gsfRange = {
          max: null,
          min: null,
        };
      }
      return newSettings;
    }
  );
  // Filters
  const filterManager = useFilterProjects(settings);
  const { setFilter } = filterManager;

  useEffect(() => {
    filterManager.clearFilters(setSettings, INSIGHTS_DEFAULTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.companyID]);

  return (
    <InsightsProjectsList
      companyID={props.companyID}
      filterManager={filterManager}
      setFilter={setFilter}
      setSettings={setSettings}
    />
  );
}
