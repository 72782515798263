import { FC, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';

import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import {
  FILTER_DELIVERY_METHOD,
  FILTER_DESIGN_PHASE,
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_COSTS,
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_SIZE,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
  PROJECTS,
} from '../../../constants';
import { CostRangeInput } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import ClearFiltersPanelHeader from '../../FilterPanel/ClearFilters/ClearFiltersPanelHeader';
import FilterIcon from '../../Icons/FilterIcon';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  projectFilterStateHasFilters,
  projectFilterStateNumFilters,
} from '../../ProjectsList/ProjectsListUtils';
import { Button, CostInput, Popover, TextInput, TextLink } from '../../scales';

import InsightsFilterSelect from './InsightsFilterSelect';
import InsightsFilterSelectOrgs from './InsightsFilterSelectOrgs';
import InsightsFilterSelectTypes from './InsightsFilterSelectTypes';

type InsightsFilterPanelProps = {
  filterManager: ProjectFilterManager;
  setSettings: ProjectFilterSetSettings;
};

const InsightsFilterPanel: FC<InsightsFilterPanelProps> = ({ filterManager, setSettings }) => {
  const sendAnalytics = useSendAnalytics();
  const hasFilters = projectFilterStateHasFilters(filterManager.filterState);
  const filterOverlayState = useOverlayTriggerState({
    onOpenChange: (open) => open,
  });
  const ref = useRef<HTMLButtonElement>(null);

  const filterCounts = projectFilterStateNumFilters(filterManager.filterState, '');

  return (
    <div>
      <Button
        ref={ref}
        badge={filterCounts || undefined}
        label="Filter"
        onClick={() => {
          filterOverlayState.open();
          sendAnalytics(insightsEvent(InsightsEvent.FILTER_CTA_CLICK));
        }}
        startIcon={<FilterIcon isFilled={hasFilters} />}
        type="secondary"
      />
      {filterOverlayState.isOpen && (
        <Popover
          className="w-100"
          placement="bottom end"
          state={filterOverlayState}
          triggerRef={ref}
        >
          <div className="flex flex-col">
            <div className="bg-background-2">
              <ClearFiltersPanelHeader
                filterManager={filterManager}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(insightsEvent(InsightsEvent.FILTER_CLEAR_ALL));
                }}
                variant={PROJECTS}
              />
            </div>
            <div className="flex flex-col gap-2 p-2">
              <InsightsFilterSelectOrgs
                filterManager={filterManager}
                filterType={FILTER_PROJECT_ORGANIZATION}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(
                    insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_ORGANIZATION })
                  );
                }}
              />
              <InsightsFilterSelect
                filterManager={filterManager}
                filterType={FILTER_PROJECT_LEAD}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_LEAD }));
                }}
              />
              <InsightsFilterSelect
                filterManager={filterManager}
                filterType={FILTER_PROJECT_LOCATION}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(
                    insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_LOCATION })
                  );
                }}
              />
              <InsightsFilterSelectTypes
                filterManager={filterManager}
                filterType={FILTER_PROJECT_TYPE}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_TYPE }));
                }}
              />
              <InsightsFilterSelect
                filterManager={filterManager}
                filterType={FILTER_PROJECT_STATUS}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(
                    insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_STATUS })
                  );
                }}
              />
              <InsightsFilterSelect
                filterManager={filterManager}
                filterType={FILTER_DESIGN_PHASE}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(insightsEvent(InsightsEvent.FILTER, { name: FILTER_DESIGN_PHASE }));
                }}
              />
              <InsightsFilterInput
                filterManager={filterManager}
                filterType={FILTER_PROJECT_COSTS}
                isCost
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(
                    insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_COSTS })
                  );
                }}
                title="Costs"
                value={{
                  max: filterManager.filterState.estimateCostRange.max,
                  min: filterManager.filterState.estimateCostRange.min,
                }}
              />
              <InsightsFilterInput
                filterManager={filterManager}
                filterType={FILTER_PROJECT_SIZE}
                isCost={false}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_SIZE }));
                }}
                title="Project Size (GSF)"
                value={{
                  max: filterManager.filterState.gsfRange.max,
                  min: filterManager.filterState.gsfRange.min,
                }}
              />
              <InsightsFilterSelect
                filterManager={filterManager}
                filterType={FILTER_DELIVERY_METHOD}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(
                    insightsEvent(InsightsEvent.FILTER, { name: FILTER_DELIVERY_METHOD })
                  );
                }}
              />
              <InsightsFilterSelect
                filterManager={filterManager}
                filterType={FILTER_PROJECT_COMPANIES}
                setSettings={(set) => {
                  setSettings(set);
                  sendAnalytics(
                    insightsEvent(InsightsEvent.FILTER, { name: FILTER_PROJECT_COMPANIES })
                  );
                }}
              />
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default InsightsFilterPanel;

type Props = {
  filterManager: ProjectFilterManager;
  filterType: string;
  isCost: boolean;
  setSettings: ProjectFilterSetSettings;
  title: string;
  value: CostRangeInput;
};
const InsightsFilterInput = (props: Props) => {
  const { clearFilterType, setFilter } = props.filterManager;

  const clearFilter = () => {
    clearFilterType(props.filterType, props.setSettings);
  };

  return (
    <div className="flex flex-col gap-1 px-2">
      <div className="flex justify-between">
        <div className="flex type-label">{props.title}</div>
        <TextLink
          label="Clear"
          onClick={() => {
            clearFilter();
          }}
          size="sm"
          to=""
        />
      </div>
      <div className="flex flex-row gap-2">
        <div className="min-w-0">
          {props.isCost ? (
            <CostInput
              label="Min"
              onChange={(value) => {
                const results = {
                  min: value,
                  max: props.value.max || null,
                };
                setFilter({ type: props.filterType, value: results }, props.setSettings);
              }}
              placeholder="$0"
              value={props.value.min ?? null}
            />
          ) : (
            <TextInput
              aria-label="Minimum Input"
              onChange={(value) => {
                // Allow empty values or valid numbers
                if (value === '' || !Number.isNaN(Number(value))) {
                  const results = {
                    min: value === '' ? null : Number(value), // Update max only if value is valid
                    max: props.value?.max || null,
                  };
                  setFilter({ type: props.filterType, value: results }, props.setSettings);
                }
              }}
              placeholder="0"
              value={
                props.value?.min !== undefined && props.value.min !== null
                  ? props.value.min.toString()
                  : ''
              }
            />
          )}
        </div>
        <div className="min-w-0">
          {props.isCost ? (
            <CostInput
              label="Max"
              onChange={(value) => {
                const results = {
                  min: props.value.min || null,
                  max: value,
                };
                setFilter({ type: props.filterType, value: results }, props.setSettings);
              }}
              placeholder="$9999999999"
              value={props.value.max ?? null}
            />
          ) : (
            <TextInput
              aria-label="Maximum Input"
              onChange={(value) => {
                // Allow empty values or valid numbers
                if (value === '' || !Number.isNaN(Number(value))) {
                  const results = {
                    min: props.value?.min || null,
                    max: value === '' ? null : Number(value), // Update max only if value is valid
                  };
                  setFilter({ type: props.filterType, value: results }, props.setSettings);
                }
              }}
              placeholder="9999999999"
              value={
                props.value?.max !== undefined && props.value.max !== null
                  ? props.value.max.toString()
                  : ''
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
