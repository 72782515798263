import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
} from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { ScrollContainer } from '../scales';

import ProgramDashboardContent from './ProgramDashboardContent';
import ProgramDashboardMenu from './ProgramDashboardMenu';
import { ProgramDashboardData } from './ProgramDialog/hooks/useProgramDashboardHooks';

type Props = {
  isPrinting: boolean;
  data: ProgramDashboardData;
};

export default function ProgramDashboard({ data, isPrinting }: Props) {
  const sendAnalytics = useSendAnalytics();
  useEffectOnce(() => {
    sendAnalytics(
      ProgramReportingAnalyticsEvent(ProgramReportingTypes.VIEW_PROGRAM_DASHBOARD, {
        programName: data.info.program?.name,
        updatedBy: data.info.program?.updatedBy,
      })
    );
  });

  const [isOpen, setIsOpen] = useState(isPrinting);

  const projects = data.info.program?.projects ?? [];
  if (!projects || projects.length === 0) return null;

  const content = (
    <ProgramDashboardContent
      data={data}
      isOpen={isOpen}
      isPrinting={isPrinting}
      setIsOpen={setIsOpen}
    />
  );

  return (
    <div className="flex h-full flex-col">
      <ProgramDashboardMenu isPrinting={isPrinting} program={data.info.program} />
      {isPrinting || !data.state.selectedProjectIDs.length ? (
        content
      ) : (
        <ScrollContainer direction="vertical">{content}</ScrollContainer>
      )}
    </div>
  );
}
