import { useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { PageViews } from '../../analytics/analyticsEventProperties';
import { DASHBOARD, FALSE, TRUE, VIEW_FILTER } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { RouteKeys } from '../../routes/paths';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { usePersistentStates } from '../../utilities/urlState';
import FilterPanelWrapper from '../FilterPanel/FilterPanelWrapper';
import { hasActiveFilter, useFilterManager } from '../FilterPanel/filterUtils';
import LeadsBanner from '../HomeTab/Search/projects/LeadsBanner';
import { useLoadTimer } from '../PerfMonitor/utils';
import { isPrintKeys } from '../Print/PrintUtils';
import ReportsManagerMenu from '../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import { ScrollContainer } from '../scales';
import TeamSummary from '../TeamSummary/TeamSummary';
import useMemoWrapper from '../useMemoWrapper';

import DashboardChartsEstimate from './DashboardCharts/DashboardChartsEstimate/DashboardChartsEstimate';
import DashboardChartsItems from './DashboardCharts/DashboardChartsItems/DashboardChartsItems';
import DashboardChartsTrend from './DashboardCharts/DashboardChartsTrend/DashboardChartsTrend';
import DashboardExportPanel from './DashboardExportPanel/DashboardExportPanel';
import { DASHBOARD_DEFAULTS } from './DashboardUtils';

export const getDashboardStorageParam = (projectId: string) => `Dashboard Project ${projectId} `;

export default function Dashboard() {
  // TODO - if we like this, we will need to elevate this hash load effect globally
  const { pathname } = window.location;
  useEffect(() => {
    const { hash } = window.location;
    const anchor = decodeURI(hash).toLowerCase();
    let tries = 0;
    const tryHash = () => {
      setTimeout(() => {
        const el = document.querySelector(anchor);
        if (el) {
          el.scrollIntoView();
        } else if (tries < 10) {
          tries += 1;
          tryHash();
        }
      }, 500);
    };
    if (anchor) {
      tryHash();
    }
  }, [pathname]);

  // CONSTANTS
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  const { search } = window.location;

  const {
    data: { project },
    loading: projectLoading,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name ?? '';
  const activeMilestoneId = project?.activeMilestone.id;
  const { data, loading: projectCategorizationsLoading } =
    useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const { canView, isViewOnly } = usePermissions();
  const canViewProjectCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);

  const [settings, setSettings] = usePersistentStates(
    window.location,
    DASHBOARD,
    DASHBOARD_DEFAULTS,
    getDashboardStorageParam(projectId)
  );

  // store settings changes without immediately persisting
  const [settingsToUpdate, setSettingsToUpdate] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const setSetting = (param: any, value: any) => {
    setSettingsToUpdate({ ...settingsToUpdate, [param]: value });
  };
  // rely on filterPanel open status to determine when to persist settings updates
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  useEffect(() => {
    if (Object.keys(settingsToUpdate).length !== 0 && !showFilterPanel) {
      setSettings(settingsToUpdate); // persist settings change
      setSettingsToUpdate({}); // reset intermediate state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilterPanel, settingsToUpdate]);

  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    setSetting(VIEW_FILTER, newValue)
  );

  const { clearFilters, filterQueryInput: viewFilter } = filterManager;
  const isFiltered = useMemoWrapper(hasActiveFilter, viewFilter);

  // Print click and key press
  const printOnClick = useCallback(
    (route: RouteKeys) => {
      const params = new URLSearchParams(window.location.search); // modifies the URL of the current page
      params.append('showTable', FALSE);
      const search = `?${params.toString()}`;
      // To-Do: see if we can remove showTable instead of appending False
      // Workaround for now - however, don't want showTable to end up controlling the Dashboard view
      // Later - change the name to something more standalone than showTable
      window.open(generateSharedPath(route, { projectId, search }), '_blank');
    },
    [projectId]
  );

  const onEstimateTableClick = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.append('showTable', TRUE);
    const search = `?${params.toString()}`;
    window.open(
      generateSharedPath(RouteKeys.PRINT_PROJECT_DASHBOARD, { projectId, search }),
      '_blank'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [projectId, search]);

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (isPrintKeys(event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        printOnClick(RouteKeys.PRINT_PROJECT_DASHBOARD);
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [printOnClick]);

  const [trendlineIsLoading, setTrendlineIsLoading] = useState(true);
  const [estimateIsLoading, setEstimateIsLoading] = useState(true);
  const [itemsIsLoading, setItemsIsLoading] = useState(true);
  const chartsLoading =
    projectLoading ||
    projectCategorizationsLoading ||
    trendlineIsLoading ||
    estimateIsLoading ||
    itemsIsLoading;
  useLoadTimer('Dashboard', chartsLoading);

  useEffect(() => {
    if (projectName) document.title = `${projectName} - Dashboard`;
  }, [projectName]);

  if (!projectId) return <Navigate to="/404" />;

  return (
    <div className="flex h-full flex-col overflow-auto bg-background-primary">
      <div
        className="flex h-20 w-full shrink-0 items-center justify-between self-center bg-background-primary px-6"
        id="dashboardPageHeader"
      >
        <header className="mr-auto type-heading1">Dashboard</header>
        <TeamSummary projectID={projectId} />
        <div className="ml-auto flex items-center">
          {canViewProjectCategories && (
            <FilterPanelWrapper
              filterManager={filterManager}
              page="dashboard"
              setShow={setShowFilterPanel}
              show={showFilterPanel}
              showFilterSummary={false}
            />
          )}
          <ReportsManagerMenu
            isViewOnly={isViewOnly}
            setSettings={setSettings}
            settings={settings}
            variant="dashboard"
          />
          <DashboardExportPanel
            onEstimateTableClick={onEstimateTableClick}
            printOnClick={printOnClick}
            projectId={projectId}
          />
        </div>
      </div>
      <LeadsBanner
        description="Update the Lead for this project in Settings."
        page={PageViews.DASHBOARD}
        projectID={projectId}
      />
      <ScrollContainer direction="vertical">
        <div className="p-6">
          {activeMilestoneId && (
            <DashboardChartsTrend
              activeMilestoneId={activeMilestoneId}
              categorizations={categorizations}
              clearFilters={clearFilters}
              filterManager={filterManager}
              isFiltered={isFiltered}
              setIsLoading={setTrendlineIsLoading}
              setSetting={setSetting}
              settings={settings}
              viewFilter={viewFilter}
            />
          )}
          {activeMilestoneId && (
            <DashboardChartsEstimate
              activeMilestoneId={activeMilestoneId}
              categorizations={categorizations}
              clearFilters={clearFilters}
              filterManager={filterManager}
              isFiltered={isFiltered}
              setIsLoading={setEstimateIsLoading}
              setSetting={setSetting}
              settings={settings}
              viewFilter={viewFilter}
            />
          )}
          {activeMilestoneId && (
            <DashboardChartsItems
              activeMilestoneId={activeMilestoneId}
              categorizations={categorizations}
              clearFilters={clearFilters}
              filterManager={filterManager}
              isFiltered={isFiltered}
              setIsLoading={setItemsIsLoading}
              setSetting={setSetting}
              settings={settings}
              viewFilter={viewFilter}
            />
          )}
        </div>
      </ScrollContainer>
    </div>
  );
}
