import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { PROJECT_COST_BREAKDOWN_REPORT } from '../../constants';
import { EstimateType } from '../../generated/graphql';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { useCostMode } from '../../utilities/costMode';
import { usePersistentStates } from '../../utilities/urlState';
import { useMilestoneQuery } from '../Milestone/hooks';

import ExportProjectCostBreakdownReport from './ExportProjectCostBreakdownReport';
import { generateProjectCostBreakdownReportStorageParam } from './utils';

const ExportProjectCostBreakdownReportData: FC = () => {
  const { projectId: projectID, milestoneId: milestoneID } = useParams();
  if (!projectID || !milestoneID) throw new Error('Project ID or Milestone ID not found');
  const costMode = useCostMode();

  const [settings] = usePersistentStates<
    ProjectCostBreakdownSettings,
    ProjectCostBreakdownSettings
  >(
    window.location,
    PROJECT_COST_BREAKDOWN_REPORT,
    { estimateType: EstimateType.ACTIVE_ESTIMATE },
    generateProjectCostBreakdownReportStorageParam(milestoneID)
  );

  const { data: milestoneData, loading: loadingMilestone } = useMilestoneQuery(milestoneID);
  const { data: projectData, loading: projectLoading } = useProjectPropsQuery(projectID);

  const loading = loadingMilestone || projectLoading;

  return (
    <>
      <Typography style={{ fontSize: 18, paddingTop: 16 }}>
        &nbsp;&nbsp;&nbsp;&nbsp;Exporting Project Cost Breakdown Report...
      </Typography>
      {!loading && (
        <ExportProjectCostBreakdownReport
          costMode={costMode}
          estimateType={settings.estimateType}
          milestoneID={milestoneID}
          milestoneName={milestoneData?.milestone?.name ?? ''}
          projectID={projectID}
          projectName={projectData?.project?.name ?? ''}
        />
      )}
    </>
  );
};

export default ExportProjectCostBreakdownReportData;
