import { useState } from 'react';

import { getTodayUtcNoon } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import InsightsBaseLine from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsBaseLine';
import InsightsCostTrendline from '../../dragon-scales/TimelineCharts/InsightsCost/InsightsCostTrendline';
import TimelineCostLabels from '../Charts/TimelineCostLabels';
import { InsightsProject } from '../types';
import { getRange } from '../utils';

import { BASE_HEIGHT, CHART_HEIGHT, TOTAL_HEIGHT } from './utils';

export default function ProjectCost(props: {
  project: InsightsProject;
  start: Date;
  end: Date;
  today?: Date;
  onClick?: () => void;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const [hoverDate, setHoverDate] = useState<Date | undefined>(undefined);

  const costData = props.project?.costTimeseries || [];
  const milestones = props.project?.milestones || [];

  const totalRange: [Date, Date] = [props.start, props.end];

  // Draft milestone may have no date a.date.getTime() > 0
  const baseRange = getRange(milestones.filter(isNonNullable).filter((a) => a.date.getTime() > 0));

  const chart = (
    <InsightsCostTrendline
      costData={costData}
      height={CHART_HEIGHT}
      hoverDate={hoverDate}
      isMiniChart
      setHoverDate={setHoverDate}
      today={today}
      totalRange={totalRange}
    />
  );
  const base = (
    <InsightsBaseLine
      baseRange={baseRange}
      data={milestones}
      height={BASE_HEIGHT}
      hoverDate={hoverDate}
      projectStatus={props.project.status}
      today={today}
      totalRange={totalRange}
    />
  );
  const firstLabels = <TimelineCostLabels costs={costData} index={0} />;
  const lastLabels = (
    <TimelineCostLabels className="left-[50%] pl-1" costs={costData} index={costData.length - 1} />
  );

  return (
    <div className="relative w-full" style={{ height: 120 }}>
      <div
        key={props.project.id}
        className={`flex h-[${TOTAL_HEIGHT}px] absolute w-full cursor-pointer flex-col bg-background-primary`}
        onClick={props.onClick}
        onKeyPress={props.onClick}
      >
        {chart}
        {base}
      </div>
      {firstLabels}
      {lastLabels}
    </div>
  );
}
