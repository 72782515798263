import { FC, useEffect, useState } from 'react';

import { ALL_MILESTONES, VIEW_FILTER } from '../../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import {
  computeDistinctCreators,
  createItemsAssigneeMap,
  getAssigneeIDs,
} from '../../../utilities/items';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { useProjectID } from '../../../utilities/routes/params';
import useUsersByIDs from '../../Collaborators/hooks/useUsersByIDs';
import { useFilterManager } from '../../FilterPanel/filterUtils';
import { useItemsListSettings } from '../../ItemsList/ItemsListSettingsUtils';
import { useItemsList, useItemsTree } from '../../ItemsList/ItemsListUtils';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { useLoadTimer } from '../../PerfMonitor/utils';
import useMemoWrapper from '../../useMemoWrapper';
import usePrintWindow from '../PrintHooks/usePrintWindow';

import PrintItemsList from './PrintItemsList';

// TODO: Theres an issue between display Milestone and filtered Milestone... figure it out
const PrintViewItemsList: FC = () => {
  // STATE
  const settingsManager = useItemsListSettings();
  const { settings, addSettingsChange } = settingsManager;

  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    addSettingsChange(VIEW_FILTER, newValue)
  );

  const { currentMilestone } = settings;

  // DATA
  // Fetch relevant project data: categorizations list, project name
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');
  const {
    data: { project },
    loading: projectPropsLoading,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name;
  const activeMilestoneId = project?.activeMilestone.id;
  const { data, loading: projectCategorizationsLoading } =
    useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const { data: { milestones = [] } = {}, loading: milestonesLoading } = useMilestonesQuery(
    projectId,
    false
  );
  const projectLoading = projectPropsLoading || projectCategorizationsLoading || milestonesLoading;

  // TIME LINE
  const filteredMilestoneId =
    (currentMilestone && currentMilestone[0]) || activeMilestoneId || ALL_MILESTONES;

  const milestoneId = (filteredMilestoneId !== ALL_MILESTONES && filteredMilestoneId) || null;

  const { itemsTree, loading: loadingItemsTree } = useItemsTree(filterManager, settings);
  // preview mode
  const { inPreviewMode } = usePermissions();
  const {
    itemsList,
    itemsListMap,
    loading: loadingItemsList,
  } = useItemsList(filterManager, settings, inPreviewMode, true);

  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const assigneeIDs = useMemoWrapper(getAssigneeIDs, itemsList);
  const { data: assignees, loading: loadingAssignees } = useUsersByIDs(assigneeIDs);
  const itemsAssigneeMap = useMemoWrapper(createItemsAssigneeMap, itemsList, assignees?.usersByIDs);

  const creators = useMemoWrapper(computeDistinctCreators, itemsList);

  const dataLoading = projectLoading || loadingItemsTree || loadingItemsList || loadingAssignees;

  const milestoneText =
    milestones.find((milestone) => milestone.id === milestoneId)?.name || ALL_MILESTONES;

  const [imagesAreReady, setImagesAreReady] = useState(false);
  const triggerOnComplete = () => setImagesAreReady(true);

  const hooksLoading = dataLoading || !imagesAreReady;
  useLoadTimer('PrintViewItemsList', hooksLoading);

  usePrintWindow(!hooksLoading, imagesAreReady);

  // Title the page for better print headers
  useEffect(() => {
    if (projectName) document.title = `${projectName} - Items List`;
  }, [projectName]);

  return (
    <div
      className="print-root"
      // add a test ID so automated report distribution PDF generation (pdf/lib.go) knows the report has loaded
      data-testid={!hooksLoading ? 'report-distribution-print-hooks-loaded' : undefined}
    >
      <PrintItemsList
        categorizations={categorizations}
        creators={creators}
        itemsAssigneeMap={itemsAssigneeMap}
        itemsListItemsMap={itemsListMap}
        itemsTree={itemsTree}
        loading={dataLoading}
        milestoneText={milestoneText}
        settings={settings}
        shouldDisplayCosts={shouldDisplayCosts}
        triggerOnComplete={triggerOnComplete}
      />
    </div>
  );
};

export default PrintViewItemsList;
