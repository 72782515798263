import {
  PreviewProjectCollaboratorQuery,
  PreviewProjectCollaboratorQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

import { previewProjectCollaborator } from './queries';

export default function usePreviewProjectCollaboratorQuery(
  projectID: UUID | undefined,
  userID: UUID | undefined,
  options: QueryHookOptions<
    PreviewProjectCollaboratorQuery,
    PreviewProjectCollaboratorQueryVariables
  > = {},
  mountPolicy?: MountPolicy
) {
  return useQuery<PreviewProjectCollaboratorQuery, PreviewProjectCollaboratorQueryVariables>(
    previewProjectCollaborator,
    {
      variables: { projectID, userID },
      skip: !projectID || !userID,
      ...options,
    },
    mountPolicy
  );
}
