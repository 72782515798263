import { KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorOutline } from '@material-ui/icons';

import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { InsightsProject as InsightsProjectType } from '../types';
import { DescriptionCount, getAlertSectionID, getUniqueAlertDescriptionCounts } from '../utils';

export default function InsightsProjectAlerts(props: {
  alerts: InsightsProjectType['activeAlerts'];
  direction: 'vertical' | 'horizontal';
}) {
  const uniqueAlerts = getUniqueAlertDescriptionCounts(props.alerts);
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>, alert: DescriptionCount) => {
    if (event.key === 'Enter' || event.key === ' ') {
      sendAnalytics(insightsEvent(InsightsEvent.DETAILS_ALERT_CLICK, { type: alert.alertType }));
      navigate({ hash: getAlertSectionID(alert) });
    }
  };

  return (
    <div className={`flex ${props.direction === 'vertical' ? 'flex-col' : 'flex-row'} gap-2`}>
      {uniqueAlerts.map((a) => (
        <div
          key={a.description}
          className={`flex ${props.direction === 'horizontal' ? 'cursor-default' : ''} gap-2`}
          onClick={() => {
            sendAnalytics(insightsEvent(InsightsEvent.DETAILS_ALERT_CLICK, { type: a.alertType }));
            navigate({ hash: getAlertSectionID(a) });
          }}
          onKeyPress={(event) => handleKeyPress(event, a)}
          role="button"
          tabIndex={0}
        >
          <div className="flex items-center text-type-error [&_*]:!icon-sm">
            <ErrorOutline />
          </div>
          <div className="whitespace-nowrap text-type-error type-body3">{a.description}</div>
        </div>
      ))}
    </div>
  );
}
