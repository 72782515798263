import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import { RouteKeys } from '../../../../routes/paths';
import { generateSignupPath } from '../../SignUpLayerUtils';

import { useCreateCompanyUserMutation } from './ConfirmCompanyHooks';

const ConfirmCompanyData: FC = () => {
  const { projectId } = useParams();
  const createCompanyUser = useCreateCompanyUserMutation();
  const navigate = useNavigate();

  useCompanyUserQuery(undefined, {
    onCompleted: (data) => {
      const companyID = data?.companyUser?.matchedCompany?.id;
      const path = generateSignupPath(RouteKeys.PROJECT_SIGNUP_SETUP_USER, projectId);
      if (companyID && path) {
        createCompanyUser(companyID, false, null);
        navigate(path);
      }
    },
  });

  return null;
};

export default ConfirmCompanyData;
