import { FC, useEffect, useState } from 'react';

import { Check, Close } from '@material-ui/icons';

import {
  autoPopulateQuantityRowsAnalytics,
  removeQuantityBreakdownAnalytics,
} from '../../../../../analytics/analyticsEventProperties';
import { CategoryCombinationsQuery } from '../../../../../generated/graphql';
import useSendAnalytics from '../../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../../utilities/permissions/usePermissions';
import DialogsConfirm from '../../../../Dialogs/DialogsConfirm/DialogsConfirm';
import { GridController } from '../../../../JoinGrid/types';
import { Button } from '../../../../scales';
import { formatUnitText } from '../../../../VarianceReport/VarianceModals/VarianceModalUnits/VarianceModalUnitsUtils';
import useRemoveQuantityBreakdown from '../../../hooks/RemoveQuantityBreakdownHook';
import {
  AUTOFILL,
  MAX_NUM_COMBINATIONS,
  formulateCategoryCombinations,
  generateAutoFillModeStorageLocation,
  useDisableButtonsTilPopulated,
} from '../MilestoneDetailsQuantitiesUtils';

import MilestoneDetailsQuantitiesGridStyles from './MilestoneDetailsQuantitiesGridHeaderStyles';

type MilestoneDetailsQuantitiesGridHeaderProps = {
  canEditMetrics: boolean;
  classes: Classes<typeof MilestoneDetailsQuantitiesGridStyles>;
  categoryCombinations: CategoryCombinationsQuery['categoryCombinations'];
  categorizationIDs: UUID[];
  grid: GridController;
};

const BUTTON_START_OVER = 'Start Over';

const MilestoneDetailsQuantitiesGridHeader: FC<MilestoneDetailsQuantitiesGridHeaderProps> = ({
  canEditMetrics,
  categoryCombinations,
  categorizationIDs,
  classes,
  grid,
}) => {
  // Data
  const sendAnalytics = useSendAnalytics();

  const { estimateID, projectID, quantity } = grid;
  const { magnitude, milestoneID, quantityID, unitInfo } = quantity ?? {};
  const { unitName, unitID, abbreviationSingular } = unitInfo ?? {};

  const autoFillStorageLocation = generateAutoFillModeStorageLocation(
    milestoneID ?? '',
    unitID ?? ''
  );
  const isAutoFill = [AUTOFILL, null].includes(localStorage.getItem(autoFillStorageLocation));

  const { isViewOnly } = usePermissions();

  // Content
  const numCombinations: number = categoryCombinations.length;
  const confirmButtonText = `Auto-fill ${numCombinations} Category Combos`;
  const canPopulate = numCombinations <= MAX_NUM_COMBINATIONS && numCombinations > 0;
  const categoryCombinationCellInputs: GridCategoryCellInputs[][] | null = canPopulate
    ? formulateCategoryCombinations(
        categorizationIDs,
        categoryCombinations as CategoryCombination[]
      )
    : null;

  // Actions
  const populateMetricCategorizations = () => {
    grid.populateMetricCategorizations(categoryCombinationCellInputs ?? []);
    setDisableButtons(true);
    sendAnalytics(autoPopulateQuantityRowsAnalytics(quantityID, unitName, milestoneID));
  };

  // If the state switches to autofill, populate
  useEffect(() => {
    if (isAutoFill && !!categoryCombinationCellInputs) {
      populateMetricCategorizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [isAutoFill]);

  // State: After Populate, disable until done
  const isPopulateComplete = numCombinations === 0 || numCombinations > MAX_NUM_COMBINATIONS;
  const { disableButtons, setDisableButtons } = useDisableButtonsTilPopulated(isPopulateComplete);
  const [openConfirm, setOpenConfirm] = useState(false);

  const removeQuantityBreakdown = useRemoveQuantityBreakdown();
  // Can only remove with estimate value...
  const removeCategorizations = () => {
    if (
      projectID !== undefined &&
      milestoneID !== undefined &&
      estimateID !== undefined &&
      quantityID !== undefined &&
      unitID !== undefined &&
      magnitude !== undefined &&
      unitName !== undefined
    ) {
      removeQuantityBreakdown(
        projectID,
        milestoneID,
        quantityID,
        estimateID ?? '',
        unitID,
        magnitude
      );
      sendAnalytics(removeQuantityBreakdownAnalytics(quantityID, unitName, milestoneID));
    }
  };

  const onConfirm = () => {
    removeCategorizations();
    setOpenConfirm(false);
  };

  // Components
  const confirmTitle =
    unitName && abbreviationSingular
      ? `Start over with ${formatUnitText(unitName, abbreviationSingular)}`
      : 'Start over';
  const confirmBody =
    'Are you sure? Doing this will remove all your current categorization rows and assigned values.';
  const confirmDialog = (
    <DialogsConfirm
      acceptCtaCopy={BUTTON_START_OVER}
      body={confirmBody}
      onClose={() => setOpenConfirm(false)}
      onConfirm={onConfirm}
      open={openConfirm}
      title={confirmTitle}
    />
  );

  const buttonYes = (
    <div className={classes.buttonContainer}>
      <Button
        isDisabled={disableButtons}
        label={confirmButtonText}
        onClick={populateMetricCategorizations}
        startIcon={<Check />}
        type="primary"
      />
    </div>
  );

  const buttonNo = (
    <div className={classes.buttonContainer}>
      <Button
        isDisabled={disableButtons || isViewOnly}
        label={BUTTON_START_OVER}
        onClick={() => setOpenConfirm(true)}
        startIcon={<Close />}
        type="secondary"
      />
    </div>
  );

  return (
    <div className={classes.header}>
      {canEditMetrics && (
        <div className={classes.populateContainer}>
          <div className={classes.spacer} />
          {canPopulate && buttonYes}
          {buttonNo}
          {confirmDialog}
        </div>
      )}
    </div>
  );
};

export default withStyles(MilestoneDetailsQuantitiesGridStyles)(
  MilestoneDetailsQuantitiesGridHeader
);
