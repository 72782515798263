import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Add, Close, History, Save } from '@material-ui/icons';

import { currentUserReportVar, setReactiveLocal } from '../../../api/apollo/reactiveVars';
import { USER_REPORT_VAR } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { SetSettingsFunctionType, removeNullKeys } from '../../../utilities/urlState';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import { BabyButton, Tooltip } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import { useUpdateUserReport } from '../ReportHooks';

import { MapReportManagerPagesToValidReports, ReportManagerPages, areSettingsDirty } from './utils';

type Props = {
  currentMilestoneID?: UUID; // Used to update the milestone ID associated with this report since milestoneID is not part of settings
  onCreate: () => void;
  settings: Record<string, unknown>;
  setSettings: SetSettingsFunctionType;
  variant: ReportManagerPages;
  isViewOnly?: boolean;
};

export default function CurrentReportBar(props: Props) {
  const { projectId } = useParams();
  if (!projectId) throw new Error('projectId is required');

  const { canEdit } = usePermissions();
  const canEditSharedReport = canEdit(PermissionResource.SHARED_USER_REPORTS);
  const updateReport = useUpdateUserReport({
    onCompleted: (data) => {
      setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, data.updateUserReport);
      setToast({ message: 'Successfully saved report' });
    },
  });
  const updateReportWithoutReactiveVarChange = useUpdateUserReport({
    onCompleted: () => setToast({ message: 'Successfully saved report' }),
  });
  const validReportTypes = MapReportManagerPagesToValidReports[props.variant];
  const currentReport = useReactiveVar(currentUserReportVar);

  const milestoneID = props.currentMilestoneID || undefined;

  const settingsDirty = useMemoWrapper(
    areSettingsDirty,
    currentReport,
    props.settings,
    milestoneID
  );
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);

  // when this component unmounts we want to clear the current report to indicate
  // that the user is no longer in a saved report
  useEffect(() => {
    return () => {
      setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
    };
  }, []);

  if (!currentReport || !validReportTypes.includes(currentReport.reportType)) return null;

  return (
    <div
      className="min-h-10 fixed left-[calc(50%_-_200px)] top-1 z-50 flex min-w-[400px] max-w-[600px] items-center gap-4 rounded bg-background-1 px-4 py-2 text-type-primary shadow-downwards"
      data-cy="popper-ReportBar"
    >
      <div className="flex gap-2 type-body2">
        <div>{currentReport.name}</div>
        {currentReport.shared ? <div className="text-type-muted">(Shared)</div> : null}
      </div>
      <div className="ml-auto flex gap-2">
        {settingsDirty && (
          <Tooltip content="Revert to saved report">
            <BabyButton
              aria-label="Revert to saved report"
              data-cy="revert-to-saved-report-button"
              icon={<History />}
              onClick={() => {
                props.setSettings(removeNullKeys(JSON.parse(currentReport.settings)));
              }}
            />
          </Tooltip>
        )}
        {settingsDirty &&
          (currentReport.owner || (currentReport.shared && canEditSharedReport) ? (
            <Tooltip content="Save changes to report">
              <BabyButton
                aria-label="Save changes to report"
                data-cy="save-changes-to-report-button"
                icon={<Save />}
                onClick={() => {
                  updateReport(
                    {
                      ...currentReport,
                      milestoneID,
                      settings: JSON.stringify(removeNullKeys(props.settings)),
                    },
                    projectId
                  );
                  setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, {
                    ...currentReport,
                    milestoneID,
                  });
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip content="Create new report">
              <BabyButton
                aria-label="Create new report"
                icon={<Add />}
                isDisabled={props.isViewOnly}
                onClick={props.onCreate}
              />
            </Tooltip>
          ))}
        <Tooltip content="Exit report">
          <BabyButton
            aria-label="Exit report"
            icon={<Close />}
            onClick={() => {
              if (settingsDirty && currentReport.owner) {
                setShowCloseConfirm(true);
              } else {
                setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
              }
            }}
          />
        </Tooltip>
      </div>
      <DialogsConfirm
        acceptCtaCopy="Save"
        body={`Do you want to save changes made to the report "${currentReport.name}"? Your changes will be lost if you don't save them.`}
        cancelCtaCopy="Don't Save"
        onClose={() => {
          setShowCloseConfirm(false);
          setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
        }}
        onConfirm={() => {
          updateReportWithoutReactiveVarChange(
            {
              ...currentReport,
              milestoneID,
              settings: JSON.stringify(removeNullKeys(props.settings)),
            },
            projectId
          );
          setShowCloseConfirm(false);
          setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
        }}
        open={showCloseConfirm}
        title="Save?"
      />
    </div>
  );
}
