import { FC, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { currentUserReportVar } from '../../../api/apollo/reactiveVars';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useProjectID } from '../../../utilities/routes/params';
import { HasDisplayCosts } from '../../conditionals/HasDisplayCosts';
import ContingencyReportData from '../../ContingencyReport/ContingencyReportData/ContingencyReportData';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import { LandscapeOrientation } from '../PrintUtils';

const PrintContingencyReport: FC = () => {
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');
  const project = useProjectPropsQuery(projectId)?.data?.project;

  const currentReport = useReactiveVar(currentUserReportVar);
  const defaultName = 'Contingency & Allowance Draw Report';
  const name = currentReport?.name ?? defaultName;
  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - ${name}`;
  }, [name, project]);

  const milestoneName = project?.activeMilestone?.name;

  return (
    <HasDisplayCosts>
      <LandscapeOrientation />
      <div>
        <PrintPageHeaderWrapper
          milestoneName={milestoneName}
          projectId={projectId}
          reportName={name}
        />
        <ContingencyReportData isPrint />
      </div>
    </HasDisplayCosts>
  );
};

export default PrintContingencyReport;
