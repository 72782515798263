import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { handleErrors } from './errors';
import { typeDefs } from './extendTypeDefs';
import httpLink from './HttpLink';
import introspectionFragmentMatcher from './introspection-fragment-matcher.json';
import typePolicies from './typePolicies';

const headerLink = (customHeaders) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...customHeaders,
      },
    }));
    return forward(operation);
  });

// we need to check localStorage for the token every request. See https://www.apollographql.com/docs/react/networking/authentication/#header

const authLink = (auth) =>
  setContext(async (_, { headers }) => {
    let accessToken;
    // If we are in an integration testing environment, we do this to skip auth0 and use a token from localStorage
    if (localStorage.getItem('skip_user_profile_fetch') === 'true') {
      accessToken = localStorage.getItem('access_token');
    } else {
      accessToken = await auth.getAccessTokenSilently();
      if (window.location.hostname === 'localhost') {
        localStorage.setItem('voyager_access_token', accessToken);
      }
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  });

const apolloClient = (auth, headers) =>
  new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: introspectionFragmentMatcher.possibleTypes,
      // provide our client-side schema
      typeDefs,
      // client custom key, date, pagination and some other fields
      typePolicies,
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
    link: ApolloLink.from([
      // IMPORTANT: This is Join's Error-handling
      onError(({ graphQLErrors, networkError }) => handleErrors(graphQLErrors, networkError, auth)),
      headerLink(headers),
      authLink(auth),
      httpLink,
    ]),
  });

export default apolloClient;
