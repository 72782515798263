import { Link, To } from 'react-router-dom';

import { KeyboardArrowRight } from '@material-ui/icons';

type Props = {
  isLinkVisible: boolean;
  to: To;
  title?: string;
  linkText: string;
  entityColor?: string;
  count?: number;
  onLink?: () => void;
};

export default function SectionTitle(props: Props) {
  return (
    <div className="flex w-full items-center gap-2">
      {props.entityColor && <div className={`h-3 w-3 self-center ${props.entityColor}`} />}
      {props.title && <div className="type-heading3">{props.title}</div>}
      {props.count !== undefined && (
        <div className="text-type-muted type-number">{props.count} total</div>
      )}
      {props.isLinkVisible && (
        <div className="ml-auto flex">
          <Link onClick={props.onLink} target="_blank" to={props.to}>
            <div className="text-type-link type-button">{props.linkText}</div>
          </Link>
          <KeyboardArrowRight />
        </div>
      )}
    </div>
  );
}
