import { ReactNode, useCallback, useRef } from 'react';
import { Placement, useFocusRing, useTooltipTrigger } from 'react-aria';
import { NavLink } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { YC_INSIGHTS_V2 } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { replaceUUIDs } from '../../../utilities/uuid';
import { Chip } from '../../scales';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';

import NavPopover from './NavPopover';

type Props = {
  icon: ReactNode;
  isBeta?: boolean;
  label?: string;
  path: string;
  tooltip?: ReactNode;
  /** @default right */
  tooltipPlacement?: Placement;
};

export default function NavItem(props: Props) {
  const hasInsightsV2Feature = useHasFeature(YC_INSIGHTS_V2);
  const sendNavAnalytics = useSendNavAnalytics();

  const ref = useRef<HTMLDivElement>(null);

  const { focusProps, isFocusVisible } = useFocusRing();

  const popoverState = useOverlayTriggerState({});

  const isDisabled = !props.tooltip;
  const { tooltipProps } = useTooltipTrigger(
    {
      isDisabled,
    },
    popoverState,
    ref
  );

  const onOpenTooltip = useCallback(() => popoverState.open(), [popoverState]);
  const onCloseTooltip = useCallback(() => popoverState.close(), [popoverState]);

  const popoverRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={`px-2 ${props.label ? 'w-full' : ''}`}
      onBlur={onCloseTooltip}
      onFocus={onOpenTooltip}
      onPointerEnter={onOpenTooltip}
      onPointerLeave={onCloseTooltip}
    >
      <NavLink
        className={({ isActive }) => {
          let textColor = isActive ? 'text-radio-selected' : 'text-type-primary';
          if (isFocusVisible) textColor = 'text-selection-focus-fill';

          let backgroundColor = '';
          if (popoverState.isOpen) backgroundColor = 'bg-selection-hover';
          else if (isActive) backgroundColor = 'bg-selection-selected';

          return [
            'relative flex items-center gap-2 rounded px-2 py-1 type-body2 hover:bg-selection-hover',
            textColor,
            backgroundColor,
            isFocusVisible ? 'outline-1 !-outline-offset-1 outline' : 'outline-none',
            props.label ? '' : 'justify-center',
          ].join(' ');
        }}
        onClick={() => {
          const routePath = props.path.split('/').map(replaceUUIDs).join('/');
          let eventPath = routePath;
          if (hasInsightsV2Feature && routePath.match('/insights')) {
            eventPath = `/insightsv2`;
          }

          sendNavAnalytics(navEventTypes.LINK_CTA, { path: eventPath });
        }}
        to={props.path}
        {...focusProps}
      >
        {({ isActive }) => (
          <>
            <div className="icon-md">{props.icon}</div>
            <Label isActive={isActive} isBeta={props.isBeta} label={props.label} />
            {props.tooltip && popoverState.isOpen && (
              <NavPopover
                {...tooltipProps}
                ref={popoverRef}
                placement={props.tooltipPlacement ?? 'right'}
                state={popoverState}
                triggerRef={ref}
              >
                {props.tooltip}
              </NavPopover>
            )}
          </>
        )}
      </NavLink>
    </div>
  );
}

function Label(props: { isActive: boolean; label?: string; isBeta?: boolean }) {
  if (!props.label) return null;

  const label = props.isActive ? <strong>{props.label}</strong> : <div>{props.label}</div>;
  if (!props.isBeta) return label;
  return (
    <div className="flex w-full items-center justify-between gap-2">
      {label}
      <Chip
        backgroundColor="transparent"
        borderColor="var(--colors-type-muted)"
        text="Beta"
        textColor="var(--colors-type-muted)"
      />
    </div>
  );
}
