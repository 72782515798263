import { ProgramQuery, ProgramQueryVariables } from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { programQuery } from '../queries';

export const useProgramQuery = (
  programID?: UUID,
  options: QueryHookOptions<ProgramQuery, ProgramQueryVariables> = {}
) =>
  useQuery<ProgramQuery, ProgramQueryVariables>(
    programQuery,
    {
      variables: { programID: programID ?? '' },
      ...options,
      skip: !programID,
    },
    MountPolicy.FETCH_ON_VARS
  );
