import { SyntheticEvent, useRef, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { UPLOAD_PROJECT_THUMBNAIL } from '../../actions/actionTypes';
import { getAssetAnalytics } from '../../analytics/analyticsEventProperties';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import useUpdateProject from '../../hooks/useUpdateProject';
import { useUploadImage } from '../assets/hooks/AssetsMutationHook';
import { Thumbnail } from '../dragon-scales';
import { BabyButton, Icon, TextLink, Tooltip } from '../scales';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 5 MB

type Props = {
  isEditable: boolean;
  projectID: UUID;
};

export default function ProjectThumbnail(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data, refetch } = useProjectPropsQuery(props.projectID);
  const thumbnail = data?.project?.thumbnail;

  const [uploading, setUploading] = useState(false);

  const updateProjectProperties = useUpdateProject();
  const uploadImage = useUploadImage();
  const onChangeLogo = async (file: File) => {
    setUploading(true);
    setError(null);

    if (file.size > MAX_FILE_SIZE) {
      setUploading(false);
      setError('File size exceeds 10MB limit');
      return;
    }

    try {
      const assetID = await uploadImage(file, props.projectID);
      if (!assetID) throw new Error('Failed to upload asset');

      await updateProjectProperties({
        id: props.projectID,
        thumbnail: assetID,
      });
      await refetch();
      sendAnalytics(getAssetAnalytics(UPLOAD_PROJECT_THUMBNAIL));
    } catch (e) {
      console.error(e);
      setError('Failed to upload asset');
    } finally {
      setUploading(false);
    }
  };

  let image = thumbnail ? (
    <Thumbnail size={200} thumbnail={thumbnail} />
  ) : (
    <Icon name="add" size="lg" />
  );

  if (uploading) image = <CircularProgress size={200} />;

  return (
    <div
      className="flex flex-col gap-0.5"
      onDragEnter={noop}
      onDragLeave={noop}
      onDragOver={noop}
      onDrop={
        props.isEditable
          ? (evt) => {
              noop(evt);
              onChangeLogo(evt.dataTransfer.files[0]);
            }
          : undefined
      }
    >
      <button
        aria-haspopup="true"
        aria-label="Thumbnail"
        aria-owns="thumbnail"
        className="h-50 w-50 border border-dashed target:outline focus-visible:outline"
        disabled={!props.isEditable}
        onClick={() => inputRef.current?.click()}
      >
        {image}
      </button>
      {props.isEditable && (
        <>
          <input
            ref={inputRef}
            accept=".png, .jpg, .webp, .svg"
            className="hidden"
            onChange={(evt) => {
              if (evt.target && evt.target.files) {
                onChangeLogo(evt.target.files[0]);
              }
            }}
            type="file"
          />
          <div className="type-body3">
            <div className="flex items-center gap-2">
              <div>Drop image or click to choose file.</div>
              <Tooltip content="Images can be PNG, JPG, SVG, or WEBP.">
                <BabyButton
                  aria-label="supports png, jpg, svg, or webp"
                  icon={<Icon name="info" />}
                />
              </Tooltip>
            </div>
            <div className="type-body3">
              {'Need help? '}
              <TextLink label="Contact Join Support" size="sm" to="mailto:support@join.build" />
            </div>
            {error && <div className="text-type-error type-body3">{error}</div>}
          </div>
        </>
      )}
    </div>
  );
}

const noop = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};
