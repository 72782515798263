import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useLoadTimer } from '../../PerfMonitor/utils';
import VarianceReport from '../VarianceReport/VarianceReport';

const VarianceReportData = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const { data: projectData, loading: projectLoading, error } = useProjectPropsQuery(projectId);
  const { data: catData, loading: catLoading } = useProjectCategorizationsQuery(projectId);

  const milestoneID = projectData?.project?.activeMilestone?.id ?? '';
  const categorizations = getCategorizationsForProjectFromQueryData(catData);

  const hooksLoading = projectLoading || catLoading;

  const [varianceReportIsLoading, setIsLoading] = useState(true);
  useLoadTimer('VarianceReport', varianceReportIsLoading);

  return !hooksLoading ? (
    <>
      <VarianceReport
        categorizations={categorizations}
        error={error}
        milestoneID={milestoneID}
        setIsLoading={setIsLoading}
      />
    </>
  ) : null;
};

export default VarianceReportData;
