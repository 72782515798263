import { ScaleBand, ScaleLinear, scaleBand, scaleLinear } from 'd3-scale';
import { useLayoutEffect, useRef, useState } from 'react';

import SVGWithDimensions from '../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../Charts/ChartsD3/useChartDimensions';

const COST_RANGE_GAP = 12;
const COST_RANGE_HEIGHT = 16;
const LABEL_PADDING = 8;
const CHART_PADDING_X = 16;
const CHART_PADDING_Y = 16;

type CostRange = {
  name: string;
  min: Numeric;
  max: Numeric;
  variant?: 'primary' | 'default';
};

export default function CostComparison(props: { data: CostRange[] }) {
  const { ref, dimensions } = useChartDimensions({
    height:
      props.data.length * COST_RANGE_HEIGHT +
      (props.data.length - 1) * COST_RANGE_GAP +
      CHART_PADDING_Y * 2,
    marginBottom: CHART_PADDING_Y,
    marginLeft: CHART_PADDING_X,
    marginRight: CHART_PADDING_X,
    marginTop: CHART_PADDING_Y,
  });

  const x = scaleLinear<number, number>().domain([0, 100]).range([0, dimensions.boundedWidth]);
  const y = scaleBand<string>()
    .domain(props.data.map((alternate) => alternate.name))
    .range([0, dimensions.boundedHeight])
    .padding(0.4);

  return (
    <SVGWithDimensions ref={ref} dimensions={dimensions}>
      {/* Gradient Background */}
      <linearGradient id="gradient">
        <stop
          offset="0%"
          style={{ stopColor: 'var(--colors-entities-comparison-better)', stopOpacity: 1 }}
        />
        <stop
          offset="40%"
          style={{ stopColor: 'var(--colors-entities-comparison-better)', stopOpacity: 0 }}
        />
        <stop
          offset="60%"
          style={{ stopColor: 'var(--colors-entities-comparison-worse)', stopOpacity: 0 }}
        />
        <stop
          offset="100%"
          style={{ stopColor: 'var(--colors-entities-comparison-worse)', stopOpacity: 1 }}
        />
      </linearGradient>
      <rect
        fill="url(#gradient)"
        height={dimensions.height}
        width={dimensions.width}
        x={0 - dimensions.marginLeft}
        y={0 - dimensions.marginTop}
      />
      {/* Labels */}
      <text
        className="text-center type-label"
        textAnchor="start"
        x={x(0) - LABEL_PADDING}
        y={dimensions.height - CHART_PADDING_Y - LABEL_PADDING}
      >
        Typically lower cost
      </text>
      <text
        className="text-center type-label"
        textAnchor="end"
        x={x(100) + LABEL_PADDING}
        y={dimensions.height - CHART_PADDING_Y - LABEL_PADDING}
      >
        Typically higher cost
      </text>
      {/* Cost Ranges */}
      {dimensions.boundedWidth > 0 &&
        props.data.map((alternate) => (
          <CostRange key={alternate.name} data={alternate} x={x} y={y} />
        ))}
    </SVGWithDimensions>
  );
}

function CostRange(props: {
  data: CostRange;
  x: ScaleLinear<number, number>;
  y: ScaleBand<string>;
}) {
  const ref = useRef<SVGTextElement>(null);
  const [labelPlacement, setLabelPlacement] = useState<'left' | 'right'>('right');

  const availableSpaceToRight =
    props.x(100) - props.x(props.data.max) + CHART_PADDING_X - 2 * LABEL_PADDING;
  const availableSpaceToLeft =
    props.x(props.data.min) - props.x(0) + CHART_PADDING_X - 2 * LABEL_PADDING;
  const yStart = props.y(props.data.name) ?? 0;
  const yMid = yStart + (3 * props.y.bandwidth()) / 4;
  const isPrimary = props.data.variant === 'primary';
  const isLabelOnRight = labelPlacement === 'right';

  useLayoutEffect(() => {
    const labelWidth = ref.current?.getBoundingClientRect().width ?? 0;
    if (labelWidth <= availableSpaceToRight) setLabelPlacement('right');
    else if (labelWidth <= availableSpaceToLeft) setLabelPlacement('left');
    else setLabelPlacement(availableSpaceToRight > availableSpaceToLeft ? 'right' : 'left');
  }, [availableSpaceToLeft, availableSpaceToRight, ref]);

  if (!props.data.min && !props.data.max) return null;
  return (
    <>
      <rect
        className={isPrimary ? 'fill-type-primary' : 'fill-type-muted'}
        height={props.y.bandwidth()}
        rx={props.y.bandwidth() / 2}
        width={props.x(props.data.max) - props.x(props.data.min)}
        x={props.x(props.data.min)}
        y={yStart}
      />
      <text
        ref={ref}
        className={['text-center text-type-primary type-body2', isPrimary ? '!font-bold' : ''].join(
          ' '
        )}
        textAnchor={isLabelOnRight ? 'start' : 'end'}
        x={
          isLabelOnRight
            ? props.x(props.data.max) + LABEL_PADDING
            : props.x(props.data.min) - LABEL_PADDING
        }
        y={yMid}
      >
        {props.data.name}
      </text>
    </>
  );
}
