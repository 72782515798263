import { useCallback } from 'react';

import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { StarredToggle } from '../../types';

export enum IdeasEventType {
  CHANGE_CATEGORY_FILTERS = 'changeCategoryFilters',
  CHANGE_MILESTONE = 'changeMilestone',
  CHANGE_SEARCH = 'changeSearch',
  CHANGE_STAR_TOGGLE = 'changeStarToggle',
  CLICK_IDEA_TILE = 'clickIdeaTile',
  MILESTONE_ESTIMATE_LINK = 'milestoneEstimateLink',
  OPT_OUT_BANNER_DISMISS = 'optOutBannerDismiss',
  OPT_OUT_BANNER_EXTERNAL_LINK = 'optOutBannerExternalLink',
  OPT_OUT_DIALOG_CLOSE = 'optOutDialogClose',
  OPT_OUT_DIALOG_CTA = 'optOutDialogCTA',
  OPT_OUT_DIALOG_OPEN = 'optOutDialogOpen',
  RESET_ALL_FILTERS = 'resetAllFilters',
  RESET_CATEGORY_FILTERS = 'resetCategoryFilters',
  RESET_SEARCH = 'resetSearch',
  SCROLL_IDEA_GROUP = 'scrollIdeaGroup',
  STAR_IDEA = 'starIdea',
}

type IdeasEventProperties = {
  categorizationID?: UUID;
  categoryIDs?: UUID[];
  ideaGroup?: string;
  ideaID?: UUID;
  ideaName?: string;
  isStarred?: boolean;
  location?: 'IdeaGroup' | 'SearchResults';
  milestoneID?: UUID;
  search?: string;
  toggleValue?: StarredToggle;
};

export function useSendIdeasAnalytics() {
  const sendAnalytics = useSendAnalytics();

  return useCallback(
    (type: IdeasEventType, eventProperties?: IdeasEventProperties) => {
      sendAnalytics({
        type: `ideas_${type}`,
        eventProperties,
      });
    },
    [sendAnalytics]
  );
}
