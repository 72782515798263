import { ComponentProps, ReactNode } from 'react';

import { Search } from '@material-ui/icons';

import { SearchResultType } from '../../../generated/graphql';
import { pluralizeString } from '../../../utilities/string';
import { isEnumValue } from '../../../utilities/types';
import { useSearchProjectsForProjectSelectionQuery } from '../../HomeTab/Search/projects/hooks/useSearchProjectsQuery';
import { useFilterOptions } from '../../HomeTab/Search/projects/utils';
import SearchToggle from '../../HomeTab/Search/SearchToggle';
import { SearchToggleValue } from '../../HomeTab/Search/types';
import { DialogContent, TextInput } from '../../scales';
import useSearchProjectsQueryParams from '../hooks/useSearchProjectsQueryParams';

import ProjectSelectionFilterPanel from './ProjectSelectionFilterPanel';
import ProjectSelectionList from './ProjectSelectionList';
import ProjectSelectionSortSelect from './ProjectSelectionSortSelect';

type Props = {
  /** callout is used for the CreateEstimateFlow, but is not DS-approved and should not be used otherwise */
  callout?: ReactNode;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disableIfNotAdmin?: boolean;
  /** includeCompanyProjects will allow Company Projects to be access via the result type toggle */
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  includeCompanyProjects?: boolean;
  onChange?: (selectedProjectIDs: UUID[]) => void;
  selectedProjectIDs?: UUID[];
  selectionMode: ComponentProps<typeof ProjectSelectionList>['selectionMode'];
};

export default function ProjectSelection(props: Props) {
  const params = useSearchProjectsQueryParams(!!props.includeCompanyProjects);

  const { data, fetchMore, loading, previousData } = useSearchProjectsForProjectSelectionQuery(
    params.variables
  );
  const { searchResultType, filters } = params.variables;
  const filterOptions = useFilterOptions(searchResultType !== SearchResultType.MY, filters);
  const projects = data?.searchProjects?.data ?? previousData?.searchProjects?.data ?? [];
  const counts = {
    current: data?.searchProjects?.total ?? previousData?.searchProjects?.total ?? 0,
    total:
      searchResultType !== SearchResultType.MY ? params.toggleCounts.all : params.toggleCounts.my,
  };

  return (
    <DialogContent className="flex h-full flex-col gap-2 pb-0">
      <div className="flex flex-col gap-2">
        {props.callout}
        <div className="flex items-end gap-2">
          <TextInput
            // TODO CT-1036: make/use a SearchInput scales component, use a more specific data-cy
            data-cy="search-input"
            label="Search"
            onChange={params.onChangeSearch}
            onClear={() => params.onChangeSearch('')}
            placeholder="Search for projects..."
            startAdornment={<Search />}
            value={params.variables.search}
          />
          {params.toggleParams && (
            <div className="flex shrink-0 flex-col gap-0.5">
              <div className="type-label">Show</div>
              <SearchToggle
                onChange={(value: string) => {
                  if (isEnumValue(SearchToggleValue, value)) params.toggleParams?.onChange(value);
                }}
                options={params.toggleParams.options}
                value={params.toggleParams.value}
              />
            </div>
          )}
          <div className="w-40 shrink-0">
            <ProjectSelectionSortSelect sortManager={params.sortManager} />
          </div>
          <ProjectSelectionFilterPanel filterOptions={filterOptions} params={params} />
        </div>
        <div className="flex items-end gap-2">
          <div className="ml-auto text-type-muted type-body1">
            Showing {counts.current} of {counts.total}&nbsp;
            {pluralizeString('project', counts.current)}
          </div>
        </div>
      </div>
      {!loading && (
        <ProjectSelectionList
          disableIfNotAdmin={props.disableIfNotAdmin}
          fetchMore={fetchMore}
          hasHover
          hasScrollContainer
          onChange={props.onChange}
          projects={projects}
          searchTerm={params.variables.search}
          selectedProjectIDs={props.selectedProjectIDs}
          selectionMode={props.selectionMode}
        />
      )}
      {!loading && !counts.current && (
        <div className="h-full w-full p-8 text-center text-type-muted type-heading3">
          No results found
        </div>
      )}
    </DialogContent>
  );
}
