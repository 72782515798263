import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { ButtonBar } from '../scales';

import { InsightsTabId } from './types';

type Props = {
  onTabChange?: (tabId: InsightsTabId) => void;
  selectedTabID: InsightsTabId;
};

export default function InsightsTabs({ onTabChange, selectedTabID }: Props) {
  const navigate = useNavigate();
  const [tabId, setTabId] = useState(selectedTabID);
  const updateHash = (id: InsightsTabId) => {
    const idAnchor = `#${id}`;
    const hasHash = window.location.href.includes(idAnchor);
    if (!hasHash) navigate({ hash: idAnchor });
  };
  useDebounce(() => updateHash(tabId), 100, [tabId]);
  const handleTabChange = (id: InsightsTabId) => {
    setTabId(id);
    if (onTabChange) {
      onTabChange(id);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="type-body3">View</div>
      <ButtonBar
        isFullWidth={false}
        onChange={(tabId) => handleTabChange(tabId as InsightsTabId)}
        options={[
          {
            label: 'Cost Trends',
            value: InsightsTabId.Costs,
          },
          {
            label: 'Items',
            value: InsightsTabId.Items,
          },
          {
            label: 'Contingencies & Allowances',
            value: InsightsTabId.Contingencies,
          },
        ]}
        value={tabId}
      />
    </div>
  );
}
