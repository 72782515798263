import { FC, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { itemSidebarOpenVar } from '../../../api/apollo/reactiveVars';
import {
  ACCEPTED,
  ASSIGNEE,
  BUCKET,
  CREATOR,
  ITEM,
  PENDING,
  REJECTED,
  RUNNING,
  STATUS,
  TARGET,
} from '../../../constants';
import { ItemsTreeBranchNodeCosts } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { PermissionFunc } from '../../../utilities/permissions/types';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../../utilities/routes/links';
import { categoryLabel } from '../../../utilities/string';
import { COMPACT_LIST } from '../../ItemsList/ItemsListViewToggle';
import MilestoneMeetingChip, {
  SECTIONHEADER,
} from '../../Milestone/MilestoneMeetingChip/MilestoneMeetingChip';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import CollapseIcon from '../../shared-widgets/CollapseIcon';
import ItemsListHeadersCost from '../ItemsListHeaders/ItemsListHeadersCost';
import ItemsListItemData from '../ItemsListItem/ItemsListItemData';

import ItemsNestingCollapseStyles from './ItemsNestingCollapseStyles';

// TODO: We will put all attached cost reports in an array
// (we only have the ones returned in the report)
type Node = {
  categorization?: Categorization;
  display?: string;
  email?: string;
  id: UUID;
  item?: ItemsListItem;
  name?: string;
  nodeCosts?: ItemsTreeBranchNodeCosts;
  number?: string;
  options?: ItemsTreeOption[];
  ACCEPTED_REPORT?: CostReport;
  PENDING_REPORT?: CostReport;
  REJECTED_REPORT?: CostReport;
  RUNNINGTOTAL_REPORT?: CostReport;
  TARGET_REPORT?: CostReport;
};

type ItemsNestingCollapseProps = {
  canEdit: PermissionFunc;
  canView: PermissionFunc;
  children: ReactNode;
  classes: Classes<typeof ItemsNestingCollapseStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  collapsed: boolean;
  displayName?: string;
  filteredMilestoneID?: UUID;
  isSelected: boolean;
  node: Node;
  refetchItemsTree: () => void;
  selectItemLike?: (id: UUID, isSelected: boolean, isItem: boolean, milestoneID: UUID) => void;
  setCollapse: (Collapsed: boolean, nodes: UUID[], node: string) => void;
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCategories?: boolean;
  spacerCount: number;
  nodeId: string;
  topLevelItemCount?: number;
  variant?: string;
  view: string;
  mainCostImpact?: Cost;
  totalCostImpact?: Cost;
};

const ItemsNestingCollapse: FC<ItemsNestingCollapseProps> = ({
  canEdit,
  canView,
  children,
  classes,
  collapsed,
  displayName,
  filteredMilestoneID,
  isSelected,
  node,
  nodeId,
  refetchItemsTree,
  selectItemLike,
  setCollapse,
  sharedUsersMap,
  showCategories = false,
  spacerCount,
  topLevelItemCount,
  variant,
  view,
  mainCostImpact,
  totalCostImpact,
}) => {
  // CONSTANTS

  const t = useContext(ProjectTermStore);
  const isItemSidebarOpen = useReactiveVar(itemSidebarOpenVar);
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  const { categorization, nodeCosts, display, email, item, name, number, options } = node;
  const showCostReports = !!categorization || !!nodeCosts;
  const isItemHighlighted = (item || {}).id === isItemSidebarOpen;
  const isCompact = view === COMPACT_LIST;
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const renderTitle = () => {
    if (displayName) {
      return <Typography className={classes.title}>{displayName}</Typography>;
    }
    switch (variant) {
      case BUCKET:
        return <MilestoneMeetingChip {...node} variant={SECTIONHEADER} />;
      case ASSIGNEE:
        return (
          <Typography className={classes.title}>
            {`${name || ''} (${email || 'No Assignee'})`}
          </Typography>
        );
      case CREATOR:
        return (
          // We expect non empty creator
          <Typography className={classes.title}>{`${name || ''} (${email || ''})`}</Typography>
        );
      case STATUS:
        return <Typography className={classes.title}>{display}</Typography>;
      case ITEM:
        if (item) {
          return (
            <ItemsListItemData
              canEdit={canEdit}
              canView={canView}
              filteredMilestoneID={filteredMilestoneID}
              isCollapseHeader
              isSelected={isSelected}
              item={item}
              link={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                projectId,
                itemId: item.id,
              })}
              mainCostImpact={mainCostImpact}
              options={options}
              selectItemLike={selectItemLike}
              sharedUsers={sharedUsersMap.get(item.id) ?? []}
              showCategories={showCategories}
              showNoEstimate
              totalCostImpact={totalCostImpact}
              view={view}
            />
          );
        }
        return null;
      default:
        return (
          <Typography className={classes.title}>
            {categoryLabel(name, number, categorization, t)}
          </Typography>
        );
    }
  };

  return (
    <div className={classes.container}>
      {spacerCount > 0
        ? Array(spacerCount)
            .fill(null)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, i) => <div key={i} className={classes.spacer} />)
        : null}
      <div
        className={`${classes.root} ${
          variant === ITEM ? classes.itemHeader : classes.categoryHeader
        } ${isItemHighlighted ? classes.sidebarItem : classes.simpleItem}`}
      >
        <div
          className={`gap-1 ${classes.header}`}
          data-cy="nestingListContainer-header"
          onClick={() => {
            setCollapse(!collapsed, [nodeId], node.id);
            refetchItemsTree();
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={-1}
        >
          <div className="flex w-full items-center">
            {children && <CollapseIcon isCollapsed={collapsed} />}
            <div className={classes.rowTitleInner}>
              {renderTitle()}
              {topLevelItemCount ? (
                <Typography
                  className={`${classes.containedItems}`}
                >{`(${topLevelItemCount})`}</Typography>
              ) : null}
            </div>
          </div>
          {showCostReports && shouldDisplayCosts && (
            <>
              <div className="mr-auto" />
              <ItemsListHeadersCost compress node={node} status={PENDING} />
              <ItemsListHeadersCost compress node={node} status={REJECTED} />
              <ItemsListHeadersCost compress node={node} status={ACCEPTED} />
              <ItemsListHeadersCost node={node} status={RUNNING} />
              <ItemsListHeadersCost node={node} status={TARGET} />
              <div className={`flex-shrink-0 ${isCompact ? 'basis-14' : 'basis-36'}`} />
            </>
          )}
        </div>
        {!collapsed && children}
      </div>
    </div>
  );
};

export default withStyles(ItemsNestingCollapseStyles)(ItemsNestingCollapse);
