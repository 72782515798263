import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { itemSidebarOpenVar, paginationHasMoreVar } from '../../../api/apollo/reactiveVars';
import {
  ITEM_ACTIVITY_FEED_REPORT_TYPE,
  NULL_ID,
  PROJECT_ACTIVITY_PAGINATION_LIMIT,
} from '../../../constants';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { computeItemHistoryEventsFormat } from '../../../utilities/itemHistoryEvents';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { logErrorToSentry } from '../../../utilities/sentry';
import { useCurrentUser } from '../../contexts/current-user';
import { useMilestonesQuery } from '../../Milestones/hooks';
import useProjectActivityQuery from '../hooks/useProjectActivityQuery';
import useItemHistoryLinksQuery from '../ItemReferences/hooks/useItemHistoryLinksQuery';

import ItemActivityFeed from './ItemActivityFeed';
import { ItemActivityFeedReportType } from './ItemActivityFeedUtils';

const ItemActivityFeedData: FC = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const [activityLoadingInfo, setActivityLoadingInfo] = useState({
    isFetching: false,
    pagination: {
      offsetID: NULL_ID,
      limit: PROJECT_ACTIVITY_PAGINATION_LIMIT,
    },
  });
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const [reportType, setReportType] = useLocalStorage<ItemActivityFeedReportType>(
    `${projectId}-${ITEM_ACTIVITY_FEED_REPORT_TYPE}`,
    ItemActivityFeedReportType.ALL_ACTIVITY
  );

  const currentUserID = useCurrentUser().id;
  const { pagination } = activityLoadingInfo;
  const filterByUserID =
    reportType === ItemActivityFeedReportType.MY_ACTIVITY ? currentUserID : undefined;

  // DATA
  const {
    data: projectActivityData,
    loading: projectActivityLoading,
    fetchMore,
    refetch,
  } = useProjectActivityQuery({
    projectID: projectId,
    filterByUserID,
    pagination,
  });

  // close the sidebar if it was left open on a previous page
  useEffect(() => {
    itemSidebarOpenVar(null);
    paginationHasMoreVar(true);
  }, []);

  // refetch the top of the feed when item changes happen in the sidebar
  const onItemMutated = useCallback(() => {
    refetch({
      pagination: {
        offsetID: NULL_ID,
        limit: PROJECT_ACTIVITY_PAGINATION_LIMIT,
      },
    });
  }, [refetch]);

  // refetch the top of the feed when switching report types
  useEffect(() => {
    onItemMutated();
  }, [reportType, onItemMutated]);

  // wrapper for the fetchMore function that will update the activityLoadingInfo state to togggle isFetching
  const fetchMoreWrapper = async () => {
    const newOffsetID = projectActivityData?.projectActivity?.paginationData?.nextOffsetID;
    const currentOffsetID = activityLoadingInfo.pagination.offsetID;

    if (paginationHasMoreVar() && newOffsetID !== currentOffsetID) {
      // update isFetching to true
      setActivityLoadingInfo((prev) => {
        return {
          ...prev,
          pagination: {
            offsetID: newOffsetID ?? NULL_ID,
            limit: PROJECT_ACTIVITY_PAGINATION_LIMIT,
          },
          isFetching: true,
        };
      });
      try {
        await fetchMore(newOffsetID);
      } catch (err) {
        logErrorToSentry(err);
      }
      // update isFetching to false
      setActivityLoadingInfo((prev) => {
        return {
          ...prev,
          isFetching: false,
        };
      });
    }
  };

  const { loading: loadingItems, data: itemLinksData } = useItemHistoryLinksQuery(projectId);
  const itemLinks = itemLinksData?.itemHistoryLinks ?? [];

  const { data: milestoneData, loading: loadingMilestones } = useMilestonesQuery(projectId, false);
  const milestones = milestoneData?.milestones ?? [];
  const cleanEvents = computeItemHistoryEventsFormat(
    projectActivityData?.projectActivity?.data ?? [],
    milestones,
    shouldDisplayCosts,
    undefined,
    itemLinks
  );
  return (
    <ItemActivityFeed
      events={cleanEvents}
      fetchMoreEvents={() => fetchMoreWrapper()}
      isDoneFetching={!activityLoadingInfo.isFetching}
      isLoading={projectActivityLoading || loadingItems || loadingMilestones}
      milestones={milestones}
      onItemMutated={onItemMutated}
      projectID={projectId}
      selectedReportType={reportType}
      setReportType={setReportType}
    />
  );
};

export default ItemActivityFeedData;
