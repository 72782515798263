import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { useProjectID } from '../../../utilities/routes/params';
import {
  Button,
  Dialog,
  DialogContent,
  StatusBanner,
  TextLink,
  useBannerVisibility,
} from '../../scales';

import { IdeasEventType, useSendIdeasAnalytics } from './hooks/analytics';
import useRemoveAccessToIdeasBetaMutation from './hooks/useRemoveAccessToIdeaBetaMutation';

export default function OptOutBanner() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const projectID = useProjectID();
  const navigate = useNavigate();
  const sendIdeasAnalytics = useSendIdeasAnalytics();
  const onOptOut = useRemoveAccessToIdeasBetaMutation({
    onCompleted: () => navigate(generateSharedPath(RouteKeys.PROJECT, { projectId: projectID })),
  });

  const { onDismiss, isHidden } = useBannerVisibility({
    localStorageKey: 'IDEAS_BETA_BANNER',
    isGlobal: true,
  });

  if (isHidden) return null;
  return (
    <>
      <StatusBanner type="info">
        <div className="flex w-full items-center justify-between">
          <div>
            <div className="type-body1">Join Ideas is a beta feature.</div>
            <div className="type-body3">
              Check back as new ideas get added over time, and reach out to our support team with
              any feedback.
            </div>
          </div>
          <div className="flex items-center gap-2">
            <TextLink
              label="Learn more"
              onClick={() => sendIdeasAnalytics(IdeasEventType.OPT_OUT_BANNER_EXTERNAL_LINK)}
              to="https://success.join.build/en/knowledge/frequently-asked-questions-about-join-ideas"
            />
            <Button
              label="Opt out of Beta"
              onClick={() => {
                sendIdeasAnalytics(IdeasEventType.OPT_OUT_DIALOG_OPEN);
                setIsDialogOpen(true);
              }}
              type="secondary"
            />
            <Button
              label="Got it"
              onClick={() => {
                sendIdeasAnalytics(IdeasEventType.OPT_OUT_BANNER_DISMISS);
                onDismiss();
              }}
              type="primary"
            />
          </div>
        </div>
      </StatusBanner>
      <OptOutDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          sendIdeasAnalytics(IdeasEventType.OPT_OUT_DIALOG_CLOSE);
        }}
        onOptOut={() => {
          sendIdeasAnalytics(IdeasEventType.OPT_OUT_DIALOG_CTA);
          onOptOut();
        }}
      />
    </>
  );
}

function OptOutDialog(props: { isOpen: boolean; onClose: () => void; onOptOut: () => void }) {
  return (
    <Dialog
      footerRight={
        <div className="flex items-center gap-2">
          <Button label="Cancel" onClick={props.onClose} type="secondary" />
          <Button label="Opt out of Beta" onClick={props.onOptOut} type="destructive" />
        </div>
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Opt out of the Join Ideas Beta?"
    >
      <DialogContent>
        <div className="flex flex-col gap-4 type-body1">
          <p>Are you sure you want to opt out of the Join Ideas Beta program?</p>
          <p>You can ask for access again by reaching out to our support team.</p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
