import { DesignPhaseEnum, TimelineGroups } from '../../api/gqlEnums';
import { TimelineActivityType } from '../../api/gqlEnumsBe';
import { TIMELINE } from '../../constants';
import { TimelineData } from '../shared-widgets/TimelineChart/timeline/timeline.types';

import { Activity, ActivityNode, TimelineExpandedMap } from './Timeline.types';

export type TimelineSettings = {
  collapse: TimelineGroups[];
  expand: TimelineGroups[];
};

export const TimelineDefaults: TimelineSettings = {
  collapse: [],
  expand: [TimelineGroups.TIMELINE, TimelineGroups.ITEMS],
};

export const getTimelineUrlConfig = (
  projectID: UUID
): [Location, string, TimelineSettings, string] => [
  window.location,
  TIMELINE,
  TimelineDefaults,
  `${projectID} - Timeline - `,
];

export const filterChildren = (children: UUID[], activities: Activity[]) => {
  const filtered = [...children].filter((id: UUID) =>
    activities.includes(activities.find((a) => a.id === id) as Activity)
  );
  return filtered;
};

export const tableNodeToTimelineData = (a: ActivityNode): TimelineData => {
  const { id, name, type, startDate, endDate } = a;
  const d: TimelineData = { id, name, type, start: startDate, end: endDate ?? undefined };
  return d;
};

export const computeExpandable = (list: TimelineActivity[]): string[] =>
  list.filter(({ children }) => children?.length).map(({ id }) => id);

export const computeExpandableMap = (collapse: string[], expand: string[]) => {
  const map: TimelineExpandedMap = {};
  expand.forEach((id) => {
    map[id] = true;
  });
  collapse.forEach((id) => {
    map[id] = false;
  });
  return map;
};

export const getExpandSettings = (map: TimelineExpandedMap) => {
  const expand: string[] = [];
  const collapse: string[] = [];
  Object.entries(map).forEach(([key, value]) => (value ? expand : collapse).push(key));
  return [collapse, expand];
};

export const ACTIVITIES_DEFAULT = [
  TimelineActivityType.EVENT,
  TimelineActivityType.PHASE,
  TimelineActivityType.MILESTONE,
  TimelineActivityType.ACTIVE_MILESTONE,
];

const mapChildren = (a: ActivityNode): TimelineData => ({
  id: a.id,
  isGuaranteedMaximumPrice:
    a.milestone?.designPhase?.name === DesignPhaseEnum['Guaranteed Maximum Price'],
  name: a.name,
  type: a.type,
  start: a.startDate,
  end: a.endDate ?? undefined,
  children: a.children.length === 0 ? undefined : a.children.map(mapChildren),
});

export const getTimelineData = (tree: ActivityNode[]) => tree.map(mapChildren);
