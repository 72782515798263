import { ReactNode, useRef } from 'react';
import { useButton } from 'react-aria';
import { MenuTriggerProps, useMenuTriggerState } from 'react-stately';

import { pluralizeCountString } from '../../utilities/string';
import FilterIcon from '../Icons/FilterIcon';
import { Button, IconButton, Popover } from '../scales';

type Props = MenuTriggerProps & {
  children: ReactNode;
  'data-cy'?: string;
  isDisabled?: boolean;
  numFiltersApplied: number;
  onResetFilters: () => void;
  type?: 'icon' | 'button';
};

export function FilterPopover(props: Props) {
  // Create state based on the incoming props
  const state = useMenuTriggerState(props);

  // Get props for the menu trigger and menu elements
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <div className="relative z-0">
      {props.type === 'button' ? (
        <Button
          ref={ref}
          badge={props.numFiltersApplied > 0 ? props.numFiltersApplied : undefined}
          data-cy={props['data-cy']}
          isDisabled={props.isDisabled}
          label="Filter"
          onClick={() => state.toggle()}
          startIcon={<FilterIcon isFilled={props.numFiltersApplied > 0} />}
          type="secondary"
        />
      ) : (
        <IconButton
          ref={ref}
          aria-label="show filter popover"
          badge={props.numFiltersApplied > 0 ? props.numFiltersApplied : undefined}
          data-cy={props['data-cy']}
          icon={<FilterIcon isFilled={props.numFiltersApplied > 0} />}
          isDisabled={props.isDisabled}
          onClick={() => state.toggle()}
          type="secondary"
        />
      )}
      {state.isOpen && (
        <Popover
          className="flex w-80 flex-col overflow-auto"
          offset={8}
          placement="bottom start"
          state={state}
          triggerRef={ref}
        >
          <div className="flex w-full items-center bg-background-2 p-2 type-body1">
            <strong className="flex-grow" data-cy="filter-count">
              {pluralizeCountString('filter', props.numFiltersApplied)}
            </strong>
            {props.numFiltersApplied > 0 && <ClearButton onClick={props.onResetFilters} />}
          </div>
          <div className="flex flex-col gap-2 p-2">{props.children}</div>
        </Popover>
      )}
    </div>
  );
}

function ClearButton(props: { onClick: () => void }) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      'aria-label': 'clear',
      onPress: props.onClick,
    },
    ref
  );

  return (
    <button {...buttonProps} ref={ref} className="outline-none type-small-link">
      Clear
    </button>
  );
}
