import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { PermissionResource } from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { removeYear } from '../../../utilities/string';
import {
  SelectCategoryValue,
  UNCATEGORIZED,
  isSelectCategoryInteraction,
} from '../../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputSingle from '../../Select/SelectCategoryChipInput/SelectCategoryChipInputSingle';

import DialogsNewItemCategoriesStyles from './DialogsNewItemCategoriesStyles';

type DialogsNewItemCategoriesProps = {
  classes: Classes<typeof DialogsNewItemCategoriesStyles>;
  categories: Category[] | undefined;
  onChange: (patch: { categories: Category[] }) => void;
};

const DialogsNewItemCategories: FC<DialogsNewItemCategoriesProps> = ({
  classes,
  categories = [],
  onChange,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  // PERMISSIONS
  const { canEdit } = usePermissions({ trades: categories });
  // Users will never be able to see the categorization selectors if they have
  // *any* trade restriction, as categories will start as an empty array.
  // TODO: CT-745
  const canEditItemCategories = canEdit(PermissionResource.ITEM_CATEGORIES);

  const addCategory = (categorization: Categorization, category: SelectCategoryValue) => {
    const updatedCategories =
      categories.filter((c) => c.categorization && c.categorization.id !== categorization.id) || [];
    if (category.id === UNCATEGORIZED) {
      onChange({
        categories: updatedCategories,
      });
      return;
    }
    // If category is of SelectCategoryInteraction type then don't add it
    if (isSelectCategoryInteraction(category)) {
      return;
    }
    updatedCategories.push(category);
    onChange({ categories: updatedCategories });
  };

  const categorizationSelectors = categorizations.map((categorization: Categorization) => {
    const selectedCategory: Category | undefined =
      categories &&
      categories.find((c) => c.categorization && c.categorization.id === categorization.id);
    return (
      <div
        key={categorization.id}
        className={classes.dialogColumn}
        id={`NewItemCategories-${categorization.id}`}
      >
        <Typography className={classes.caption} variant="caption">
          {removeYear(categorization.name, (categorization as CategorizationFeDefined).builtin)}
        </Typography>
        <div className={classes.categories}>
          <SelectCategoryChipInputSingle
            key={categorization.name}
            categorizations={[categorization]}
            id={`NewItemCategories-${categorization.id}`}
            selectedCategory={selectedCategory}
            setCategory={(selected: SelectCategoryValue) => addCategory(categorization, selected)}
          />
        </div>
      </div>
    );
  });
  const emptyColumnCount = 3 - (categorizations.length % 3);
  const emptyColumns = [...Array(emptyColumnCount)].map((e, i) => (
    <div key={`empty column ${1 + i}`} className={classes.dialogColumn} />
  ));

  const hasCategorizations = categorizations && categorizations.length > 0;
  return canEditItemCategories && hasCategorizations ? (
    <div>
      <div className={classes.cardHeader}>
        <Typography className={classes.cardHeaderTypography}>Categorizations</Typography>
      </div>
      <div className={classes.content}>
        {categorizationSelectors}
        {emptyColumns}
      </div>
    </div>
  ) : null;
};

export const StyledDialogsNewItemCategories = withStyles(DialogsNewItemCategoriesStyles)(
  DialogsNewItemCategories
);

export default StyledDialogsNewItemCategories;
