import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { UNTITLED_PROJECT } from '../../../constants';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getTextWidth } from '../../../utilities/string';
import { getProjectUnitDisplay } from '../../Inputs/UnitToggle/UnitToggleUtils';
import useMemoWrapper from '../../useMemoWrapper';

import styles from './ProjectSummaryStyles';

type ProjectSummaryProps = {
  classes: Classes<typeof styles>;
  displayName?: string;
  project: ProjectProps;
  quantities: Quantity[];
  thumbnailURL?: string;
};

const ProjectSummary: FC<ProjectSummaryProps> = ({
  classes,
  displayName,
  project,
  quantities,
  thumbnailURL,
}) => {
  const navigate = useNavigate();
  // Extract relevant data
  const { activeMilestone, name: projectName, id: projectId } = project;
  const { id: activeMilestoneId, name: activeMilestoneName } = activeMilestone || {};

  // Project Size: display value if the value exists
  const unitQuantities: string[] = useMemoWrapper(getProjectUnitDisplay, quantities);

  // Name display
  const name = displayName || projectName || UNTITLED_PROJECT;

  const isCostReportSummary = !!displayName;
  const milestoneName = !isCostReportSummary && (
    <Typography className={classes.milestoneText} variant="caption">
      {activeMilestoneName}
    </Typography>
  );

  return (
    <div className={classes.top}>
      <div className={classes.thumbnail}>
        {thumbnailURL ? (
          <img alt="thumb" className={classes.avatar} src={thumbnailURL} />
        ) : (
          <div className={`${classes.avatar} ${classes.loading}`} />
        )}
      </div>
      <div className={classes.projectSummary}>
        <Typography
          className={classes.projectName}
          data-cy="project-title--project-name"
          style={{ fontSize: getNameFontSize(name) }}
          variant="headline"
        >
          {name}
        </Typography>
        {activeMilestoneId && (
          <div
            className={`${classes.milestoneButton} ${classes.clickable}`}
            onClick={() => {
              if (navigate)
                navigate(
                  generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
                    projectId,
                    milestoneId: activeMilestoneId,
                  })
                );
            }}
            onKeyPress={() => {
              if (navigate)
                navigate(
                  generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
                    projectId,
                    milestoneId: activeMilestoneId,
                  })
                );
            }}
            role="button"
            tabIndex={-1}
          >
            {milestoneName}
            {unitQuantities.map((display) => (
              <Typography
                key={display}
                className={classes.milestoneText}
                data-cy="UnitQuantity"
                variant="caption"
              >
                {display}
              </Typography>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ProjectSummary);

const getNameFontSize = (name: string) => {
  const MAX_FONT_SIZE = 21;
  const MIN_FONT_SIZE = 16;
  const MAX_WIDTH = 370;

  const fullWidth = getTextWidth(name, MAX_FONT_SIZE);
  const scale = MAX_WIDTH / fullWidth;
  let size = MAX_FONT_SIZE * scale;
  if (size > MAX_FONT_SIZE) size = MAX_FONT_SIZE;
  if (size < MIN_FONT_SIZE) size = MIN_FONT_SIZE;
  return size;
};
