import { useState } from 'react';

import { Edit, LinkOutlined, OpenInNew } from '@material-ui/icons';

import {
  ForecastingReportShareEvent,
  forecastingEventTypes,
  getForecastingReportShareEvent,
} from '../../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { FORECASTING_REPORTS_ICON_MENU } from '../../../../tagConstants';
import { Icon } from '../../../scales';
import IconMenu from '../../../Select/SelectMenu/IconMenu';
import { SelectVariants } from '../../../Select/SelectMenu/SelectStyles';
import useSendForecastingAnalytics from '../../hooks/useSendForecastingAnalytics';
import ShareForecastingReportDialog from '../../Share/ShareForecastingReportDialog';

type Props = {
  onAddToProject: (report: ForecastingReport) => void;
  onDelete: (report: ForecastingReport) => void;
  onEdit: (report: ForecastingReport) => void;
  onLink: (report: ForecastingReport) => void;
  report: ForecastingReport;
};

const IconMenuCell = (props: Props) => {
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const sendAnalytics = useSendAnalytics();
  const sendForecastingAnalytics = useSendForecastingAnalytics();
  const handleMenuEvent = (select: string) => {
    sendForecastingAnalytics(forecastingEventTypes.MENU, {
      location: 'reports',
      reportID: props.report.id,
      reportName: props.report.name,
      select,
    });
  };

  const menuOptions = [
    {
      icon: <OpenInNew />,
      name: 'Go to report',
      callback: () => {
        props.onLink(props.report);
        handleMenuEvent('view report');
      },
    },
    {
      icon: <LinkOutlined />,
      name: 'Add to a project',
      disabled: Boolean(props.report.attachedProject),
      callback: () => {
        props.onAddToProject(props.report);
        handleMenuEvent('add to project');
      },
    },
    {
      icon: <Edit />,
      name: 'Edit report',
      callback: () => {
        props.onEdit(props.report);
        handleMenuEvent('edit');
      },
    },
    {
      icon: <Icon name="shareGroupAdd" />,
      name: 'Share report',
      callback: () => {
        setShareDialogOpen(true);
        sendAnalytics(
          getForecastingReportShareEvent(ForecastingReportShareEvent.OPEN_SHARE_DIALOG, {
            location: 'reports-IconMenuCell',
          })
        );
      },
    },
    {
      icon: <Icon name="delete" />,
      name: 'Delete report',
      color: 'red',
      callback: () => {
        props.onDelete(props.report);
        handleMenuEvent('delete');
      },
    },
  ];

  return (
    <>
      <IconMenu
        cy={`${FORECASTING_REPORTS_ICON_MENU}-${props.report.name}`}
        options={menuOptions}
        variant={SelectVariants.UNSTYLED}
      />
      {shareDialogOpen && (
        <ShareForecastingReportDialog
          onClose={() => {
            sendAnalytics(
              getForecastingReportShareEvent(ForecastingReportShareEvent.CLOSE_SHARE_DIALOG, {
                location: 'reports-IconMenuCell',
              })
            );
            setShareDialogOpen(false);
          }}
          reportID={props.report.id}
          reportName={props.report.name}
        />
      )}
    </>
  );
};

export default IconMenuCell;
