import { useEffect } from 'react';

import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { isPrintKeys } from '../Print/PrintUtils';

import ProgramDashboard from './ProgramDashboard';
import useProgramDashboardHooks from './ProgramDialog/hooks/useProgramDashboardHooks';

type Props = {
  programID: UUID;
};

export default function ProgramDashboardData({ programID }: Props) {
  const data = useProgramDashboardHooks(programID);

  // open up the print view when holding down ctrl+p
  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (isPrintKeys(event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        window.open(
          generateSharedPath(RouteKeys.PROGRAM_DASHBOARD_PRINT, {
            programID,
          }),
          '_blank'
        );
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [programID]);

  return <ProgramDashboard data={data} isPrinting={false} />;
}
