import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { currentUserReportVar } from '../../../api/apollo/reactiveVars';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { useUserReportNotesPerMilestoneIDQuery } from '../../ReportsTab/ReportHooks';
import { isDefaultReport } from '../../ReportsTab/ReportsManagerMenu/utils';

import FollowingActiveMilestoneSelect, {
  defaultMilestoneEntry,
} from './FollowingActiveMilestoneSelect';

type Props = {
  defaultSelectedMilestoneID?: string;
  shouldNavigateOnChange: boolean;
  shouldNavigateOnClose: boolean;
  onChange: (milestoneID?: string) => void;
};

export default function FollowingActiveMilestoneSelectData(props: Props) {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const currentReport = useReactiveVar(currentUserReportVar);

  // fetch milestones for the project
  const milestones = useMilestonesQuery(projectId, false)?.data?.milestones ?? [];

  // we only want to fetch notesPerMilestoneID on saved reports that are not the default report
  const isViewingSavedReport = !!currentReport && !isDefaultReport(currentReport.id);
  // fetch the number of notes per milestoneID for the currentReport.ID
  const notesPerMilestoneID = useUserReportNotesPerMilestoneIDQuery(
    projectId,
    isViewingSavedReport ? currentReport?.id : undefined
  )?.data?.userReportNotesPerMilestoneID;

  // fetch active milestone for the project
  const activeMilestoneID = useProjectPropsQuery(projectId)?.data?.project?.activeMilestone.id;

  const defaultSelectedMilestoneID =
    !props.defaultSelectedMilestoneID && currentReport?.isFollowingActiveMilestone
      ? defaultMilestoneEntry.id
      : props.defaultSelectedMilestoneID;

  if (!activeMilestoneID || !milestones) {
    return null;
  }

  return (
    <FollowingActiveMilestoneSelect
      activeMilestoneID={activeMilestoneID}
      defaultSelectedMilestoneID={defaultSelectedMilestoneID}
      milestones={milestones.map((milestone) => ({
        ...milestone,
        noteCount:
          notesPerMilestoneID?.find((note) => note.milestoneID === milestone.id)?.noteCount ?? 0,
      }))}
      onChange={props.onChange}
      projectID={projectId}
      shouldNavigateOnChange={props.shouldNavigateOnChange}
      shouldNavigateOnClose={props.shouldNavigateOnClose}
    />
  );
}
