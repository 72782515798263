import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../../constants';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import TimelinePath from '../TimelinePath';

type Props = {
  height: number;
  today: Date;
  totalRange: [Date, Date];
  margin?: typeof DEFAULT_MARGIN;
};

export default function InsightsEmptyChart(props: Props) {
  const { height, today, totalRange } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = props.margin || DEFAULT_MARGIN;

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const yDomain: [number, number] = [height, 0];
  const yRange: [number, number] = [height, 0];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {/* Today */}
      <TimelinePath<{ value: number }>
        className="stroke-selection-focus-fill"
        data={[
          { date: today, value: 0 },
          { date: today, value: height },
        ]}
        strokeWidth={1.5}
        x={x}
        y={y}
      />
    </SVGWithDimensions>
  );
}
