import { useOutlet } from 'react-router-dom';

import MilestoneData from './MilestoneData/MilestoneData';

export default function MilestoneRoute() {
  const outlet = useOutlet();
  if (outlet) return outlet;

  // If we were writing this from scratch, all the tabs would probably be
  // outlets and we'd render the frame here yadda yadda kinda like the
  // TeamRoute. We're not writing this from scratch, so I'm taking the easy way
  // out here. When we rethink the Milestones page, let's take the time to do
  // this right.
  return <MilestoneData />;
}
