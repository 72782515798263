import * as d3 from 'd3';

import { renderCostString } from '../CostReport/CostReportUtils';

import { CategoryTotals } from './hooks/useProgramCategorizedTotalsQuery';

type Props = {
  xScale: d3.ScaleBand<string>;
  xWidth: number;
  yOffset: number;
  categoryCostData: CategoryTotals[];
  categorizationName: string;
};

export default function EstimateChartXAxis({
  xScale,
  xWidth,
  yOffset,
  categoryCostData,
  categorizationName,
}: Props) {
  return (
    <g transform={`translate(0, ${yOffset})`}>
      {xScale.domain().map((category) => {
        const cost = categoryCostData.find((c) => c.categoryName === category)?.estimate;
        return (
          <foreignObject
            key={category}
            height={70}
            width={xScale.bandwidth()}
            x={xScale(category)}
            y={0}
          >
            <div className="mt-1 flex h-full flex-col gap-0">
              <div className="line-clamp-2 text-center text-sm">{category}</div>
              <div className="text-center text-sm">{renderCostString({ cost, short: true })}</div>
            </div>
          </foreignObject>
        );
      })}
      <text className="type-body1" fontSize="14px" textAnchor="middle" x={xWidth / 2} y={80}>
        {categorizationName}
      </text>
    </g>
  );
}
