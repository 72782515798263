import { useNavigate } from 'react-router-dom';

import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
} from '../../analytics/analyticsEventProperties';
import { ProgramFieldsFragment } from '../../generated/graphql';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { useRecentlyViewedProjects } from '../contexts/recently-viewed-entries';
import { Button, Dialog, DialogContent } from '../scales';

import { useDeleteProgramMutation } from './ProgramDialog/hooks/useDeleteProgramMutation';

type Props = {
  program: ProgramFieldsFragment;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function ProgramDeleteDialog({ program, isOpen, setIsOpen }: Props) {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();
  const { removeLastViewedEntry } = useRecentlyViewedProjects();
  const [deleteProgram] = useDeleteProgramMutation(program.id, {
    onCompleted: () => {
      setIsOpen(false);
      navigate(generateSharedPath(RouteKeys.SEARCH_PROJECTS));
      sendAnalytics(
        ProgramReportingAnalyticsEvent(ProgramReportingTypes.DELETE_PROGRAM, {
          programName: program?.name,
        })
      );
    },
  });

  return (
    <Dialog
      footerRight={
        <div className="flex gap-2">
          <Button label="Cancel" onClick={() => setIsOpen(false)} type="secondary" />
          <Button
            label="Delete"
            onClick={() => {
              deleteProgram();
              removeLastViewedEntry({ id: program.id, type: 'program' });
            }}
            type="destructive"
          />
        </div>
      }
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={`Delete ${program.name} Program`}
    >
      <DialogContent>
        <p className="type-body2">
          Are you sure you want to delete this Program? The Program Dashboard and grouping will be
          removed, but the Projects won&apos;t be impacted. You can always recreate this from the
          Projects page.
        </p>
      </DialogContent>
    </Dialog>
  );
}
