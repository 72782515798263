import {
  ProgramCostTrendlineQuery,
  QueryProgramCostTrendlineArgs,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';
import { programCostTrendlineQuery } from '../../queries';

export const useProgramCostTrendlineQuery = (
  programID: UUID | undefined,
  includedProjectIDs: UUID[]
) => {
  return useQuery<ProgramCostTrendlineQuery, QueryProgramCostTrendlineArgs>(
    programCostTrendlineQuery,
    {
      variables: { programID, includedProjectIDs },
      skip: !programID || !includedProjectIDs.length,
    },
    MountPolicy.FETCH_ON_VARS
  );
};
