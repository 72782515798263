import { AnalyticsEvent } from '../../../analytics/analyticsEventProperties';
import { useProjectID } from '../../../utilities/routes/params';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import { useUpdateMilestoneDescription } from '../hooks';

type Props = {
  isDisabled: boolean;
  milestone: Milestone;
  sendAnalytics: (event: AnalyticsEvent) => void;
};

export default function MilestoneDescription(props: Props) {
  const projectID = useProjectID();
  if (!projectID) throw Error('Failed to find Project ID');

  const [updateDescription] = useUpdateMilestoneDescription(projectID);

  return (
    <div className="flex flex-col gap-0.5">
      <InputsTextAreaStyled
        data-cy="milestone-description-input"
        isDisabled={props.isDisabled}
        isEditable
        label="Description"
        onChange={(description, descriptionStyled) => {
          updateDescription({ ...props.milestone, description, descriptionStyled });
        }}
        placeholder="Describe this Milestone..."
        sendAnalytics={props.sendAnalytics}
        value={props.milestone.descriptionStyled}
      />
    </div>
  );
}
