import { FC, MouseEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-vis/dist/style.css';

import { Typography } from '@material-ui/core';

import { ItemShareKey } from '../../../analytics/analyticsEventProperties';
import { REFETCH_ITEM, refetchItem } from '../../../api/refetchSets';
import { OPTIONS } from '../../../constants';
import { useRefetch } from '../../../hooks/useRefetch';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { clickLinkKeyHandler, clickQuicklinkHandler } from '../../../utilities/clickHandler';
import truncateLabel from '../../../utilities/dashboard';
import { getHoverDisplay } from '../../../utilities/item-status';
import { generateSharedPath } from '../../../utilities/routes/links';
import { renderCostString } from '../../CostReport/CostReportUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import ItemsListItemPrivateBadge from '../ItemsListItem/ItemsListItemPrivateBadge/ItemsListItemPrivateBadge';
import { isPrivateVisibility, showPrivateBadge } from '../ItemsUtils';

import styles from './ItemsQuickLinkStyles';

export const COST_REPORT = 'cost report';

export function formatItemDescriptor(
  item: Partial<Pick<Item, 'id' | 'name' | 'number' | 'visibility'>> & {
    currentNumber?: string;
  },
  ellipses: boolean,
  showNumberSign: boolean,
  charNumberLimit?: number,
  charNameLimit?: number
) {
  // if the user feeds an item into the chip from an event
  // then the current number may differ from the number
  // of the item in the event, and we also want to display
  // the current number to avoid confusion
  if (item) {
    const { currentNumber, number, name, visibility } = item;
    const formatNumber = (num?: string) =>
      `${
        showNumberSign && !showPrivateBadge(isPrivateVisibility(visibility), number) ? '#' : ''
      }${truncateLabel(num, charNumberLimit, ellipses)}`;
    const formattedNumber = formatNumber(number);
    const formattedName = `${truncateLabel(name, charNameLimit, ellipses)}`;
    const delimeter = formattedNumber && formattedName ? ': ' : '';
    const formattedCurrentNumber =
      currentNumber && number !== currentNumber ? ` (now ${formatNumber(currentNumber)})` : '';
    return `${formattedNumber}${formattedCurrentNumber}${delimeter}${formattedName}`;
  }
  return '';
}

type ItemQuickLinkItem = Pick<ItemLike, 'id' | 'name' | 'number' | 'status' | 'cost'> & {
  parent?: Option['parent'];
};

export const getItemTitle = (item: ItemQuickLinkItem, chosen?: boolean) => {
  const { name, number, status, cost } = item;

  return `${item.parent ? 'Option' : 'Item'} ${number} (${getHoverDisplay(
    !!chosen,
    status
  )}): ${name}${cost ? ` (${renderCostString({ cost, isSigned: true, isWide: true })})` : ''}`;
};

type ItemsQuickLinkProps = {
  classes: Classes<typeof styles>;
  charNumberLimit?: number;
  charNameLimit?: number;
  cost?: Cost;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  ellipses?: boolean;
  hasDefaultWidth?: boolean;
  isDraftItem?: boolean;
  isPrint?: boolean;
  isVariance?: boolean;
  item: ItemQuickLinkItem;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  parentIsChosen?: boolean;
  sharedUsers?: Pick<User, 'id' | 'name'>[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showNumberSign?: boolean;
  variant?: string;
  textVariant?: string;
  handleClick?: (id?: UUID) => void;
};

const ItemsQuickLink: FC<ItemsQuickLinkProps> = ({
  classes,
  cost,
  hasDefaultWidth = true,
  isDraftItem = false,
  isPrint = false,
  isVariance = false,
  item,
  textVariant = '',
  ellipses = false,
  charNumberLimit,
  charNameLimit,
  variant = '',
  parentIsChosen = false,
  sharedUsers = [],
  showNumberSign = false,
  handleClick = () => {},
}) => {
  const navigate = useNavigate();

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const itemTitle = getItemTitle(item as ItemLike, parentIsChosen);
  const refetch = useRefetch(REFETCH_ITEM);

  // Class variables
  const widthClass = hasDefaultWidth ? classes.navLinkWidthDefault : '';
  const getVariantClass = () => {
    switch (variant) {
      case 'next': {
        return classes.next;
      }
      case 'previous': {
        return classes.previous;
      }
      case COST_REPORT: {
        return classes.list;
      }
      default: {
        return '';
      }
    }
  };
  const getTextClass = () => {
    switch (textVariant) {
      case 'body1': {
        return classes.body1;
      }
      case 'body2': {
        return classes.body2;
      }
      case 'bodySidebar': {
        return classes.bodySidebar;
      }
      default: {
        return '';
      }
    }
  };
  const itemId = item.id;

  // TODO: CT-1010: bring back analytics.
  const itemNewWindowAnalytics = () => {
    // if (item.id) sendAnalytics(itemNewWindowOpen(item.id));
  };

  return (
    <div
      className={`${classes.root} ${getVariantClass()} ${widthClass} gap-1`}
      data-cy={`items-quick-link-${variant}`}
      onClick={(e: MouseEvent) => {
        if (!isPrint) {
          const path = generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
            projectId,
            itemId,
          });
          if (variant === COST_REPORT) {
            clickQuicklinkHandler(
              e,
              navigate,
              generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                projectId,
                itemId,
              }),
              itemNewWindowAnalytics,
              undefined
            );
          } else if (variant === OPTIONS) {
            handleClick(item.id);
          } else {
            const emptySearch = '';
            clickLinkKeyHandler(navigate, e, path, emptySearch);
            if (itemId) refetch([refetchItem(itemId)]);
          }
        }
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex={-1}
    >
      {!isVariance && (
        <ItemsIcons
          notChosen={parentIsChosen}
          status={item.status}
          variant={variant === COST_REPORT ? undefined : SMALL}
        />
      )}
      {isDraftItem && (
        <ItemsListItemPrivateBadge
          analyticsKey={ItemShareKey.SIDEBAR}
          sharedPrivateItemUsers={sharedUsers}
        />
      )}
      <NormalTooltip title={variant === COST_REPORT && !isPrint ? itemTitle : ''}>
        <Typography
          className={`${classes.item} ${getTextClass()} ${!isPrint ? classes.hover : ''}`}
          data-cy={`itemsQuickLink-${item.number}`}
        >
          {formatItemDescriptor(item, ellipses, showNumberSign, charNumberLimit, charNameLimit)}
          {cost && (
            <span className={classes.cost}>
              {` (${renderCostString({ cost, isSigned: true, isWide: true })})`}
            </span>
          )}
        </Typography>
      </NormalTooltip>
    </div>
  );
};

const ItemsQuickLinkStyled = withStyles(styles)(ItemsQuickLink);

export default ItemsQuickLinkStyled;
