import { FC, RefObject, useContext } from 'react';

import { Divider, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { TermKey } from '../../../api/gqlEnums';
import { TARGET } from '../../../constants';
import { CostReportColumnType } from '../../../generated/graphql';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { parseDate } from '../../../utilities/dates';
import { pluralizeCountString } from '../../../utilities/string';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { IconButton, Tooltip } from '../../scales';

import DialogsHideListItem from './DialogsHideListItem';
import styles from './DialogsHideListStyles';

export type HideDate = {
  milestone: Pick<Milestone, 'id' | 'name' | 'description' | 'date'>;
  point: Point;
  isLast?: boolean;
};

type DialogsHideListProps = {
  classes: Classes<typeof styles>;
  listItemRef: RefObject<HTMLDivElement>;
  onClickToggleAll: (visible: boolean) => void;
  onClickToggleVisibility: () => void;
  onMouseLeave: () => void;
  onMouseOver: (trendlineEvent: HideDate | null) => void;
  selected: HideDate | null;
  listItems: HideDate[];
  unit?: string;
};

// TODO: Create a map from ColumnTerm to CostReportColumnType for all columns
const reports: CostReportColumnType[] = [
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.TARGET_REPORT,
];
const keys: TermKey[] = [TermKey.ESTIMATE, TermKey.RUNNING_TOTAL, TermKey.TARGET];

export const matchPoints = (hide: HideDate, selected: HideDate) =>
  hide.milestone.id === selected.milestone.id && hide.point.dateStart === selected.point.dateStart;

const DialogsHideList: FC<DialogsHideListProps> = ({
  classes,
  listItemRef,
  onClickToggleAll,
  onClickToggleVisibility,
  onMouseLeave,
  onMouseOver,
  selected,
  listItems,
  unit = 'event',
}) => {
  const hidden = listItems.filter((t) => !t.point.isVisible).length;
  const hideText = hidden ? 'Show all' : 'Hide all';
  const terms = useContext(ProjectTermStore);

  return (
    <div className={classes.root}>
      <Divider />
      <Typography className={classes.countText} data-cy="editTrendline-header">
        {pluralizeCountString(unit, listItems.length)} ({hidden} hidden)
      </Typography>
      <div className={classes.header}>
        <Typography className={classes.text}>Milestone</Typography>
        {
          // These are the report column headers in order
          keys.map((key) => {
            const color = key === TARGET ? theme.palette.budget : undefined;
            return (
              <Typography key={key} className={classes.value} style={{ color }}>
                {terms.titleCase(key)}
              </Typography>
            );
          })
        }
        <Typography className={classes.date}>Date</Typography>

        <Tooltip content={hideText}>
          <IconButton
            aria-label="Hide"
            data-cy="toggle-vis-all"
            icon={hidden ? <Visibility /> : <VisibilityOff />}
            onClick={() => onClickToggleAll(hidden > 0)}
            type="secondary"
          />
        </Tooltip>
      </div>
      <div className={classes.scroll} onMouseLeave={onMouseLeave}>
        {listItems &&
          listItems.map((listItem: HideDate) => {
            // On hover, we pass the ref back for the list item
            const isSelected = !!selected && matchPoints(listItem, selected);
            const { point, isLast, milestone } = listItem;
            const { isVisible, values } = listItem.point;
            const key = JSON.stringify(listItem);
            const content = (
              <div key={key} className={classes.item}>
                <Typography className={classes.text}>{milestone.name}</Typography>
                {
                  // These are the report column values in order
                  reports.map((report) => {
                    const value = values.find((v) => v.type === report);
                    const text = value ? formatCost(value.y, { short: true }) : '';
                    const color =
                      report === CostReportColumnType.TARGET_REPORT
                        ? theme.palette.budget
                        : undefined;
                    return (
                      <Typography key={report} className={classes.value} style={{ color }}>
                        {text}
                      </Typography>
                    );
                  })
                }
                <Typography className={classes.date}>
                  {isLast ? 'Current Data' : parseDate(point.dateStart)}
                </Typography>
              </div>
            );

            return (
              <div key={key} ref={isSelected ? listItemRef : null}>
                <DialogsHideListItem
                  content={content}
                  isSelected={isSelected}
                  isVisible={isVisible}
                  listItem={listItem}
                  onClickToggleVisibility={onClickToggleVisibility}
                  onMouseLeave={onMouseLeave}
                  onMouseOver={onMouseOver}
                />
                <Divider />
              </div>
            );
          })}
        {listItems && listItems.length < 8 && <Divider />}
      </div>
    </div>
  );
};

export default withStyles(styles)(DialogsHideList);
