import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconButton, TextField, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { UnitType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useCreateOrUpdateUnit, useRemoveCustomUnit } from '../../Milestone/hooks/UnitHooks';
import { Button, Dialog, DialogContent, Icon } from '../../scales';
import JoinSelect, { getEntry } from '../../Select/JoinSelect/JoinSelect';
import useMemoWrapper from '../../useMemoWrapper';
import DialogsConfirm from '../DialogsConfirm/DialogsConfirm';

import styles from './DialogCreateOrEditUnitStyles';

type CreateOrEditUnitProps = {
  classes: Classes<typeof styles>;
  onBack: () => void;
  onClose: () => void;
  isOpen: boolean;
  unit: Unit | undefined;
};

const emptyUnitInput = (): UnitInput => ({
  abbreviationSingular: '',
  abbreviationPlural: '',
  name: '',
  description: '',
  type: UnitType.COUNT,
});

const readyForAbbreviation = (input: UnitInput): boolean =>
  input.name.length > 0 && input.description.length > 0;

const validUnitInput = (input: UnitInput): boolean =>
  readyForAbbreviation(input) &&
  input.abbreviationSingular.length > 0 &&
  input.abbreviationPlural.length > 0;

const unitTypeOptions = [
  { key: UnitType.COUNT, value: 'Count' },
  { key: UnitType.LINEARFEET, value: 'Linear Feet' },
  { key: UnitType.LINEARMETER, value: 'Linear Meters' },
  { key: UnitType.SQUAREFEET, value: 'Square Feet' },
  { key: UnitType.SQUAREMETER, value: 'Square Meters' },
  { key: UnitType.CUBICFEET, value: 'Cubic Feet' },
  { key: UnitType.CUBICYARDS, value: 'Cubic Yards' },
  { key: UnitType.CUBICMETER, value: 'Cubic Meters' },
];

const DialogsCreateOrEditUnit: FC<CreateOrEditUnitProps> = ({
  classes,
  onBack,
  onClose,
  isOpen,
  unit,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const createOrUpdateUnit = useCreateOrUpdateUnit();
  const removeUnit = useRemoveCustomUnit();
  const [localUnit, setLocalUnit] = useState<UnitInput>(emptyUnitInput());
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (unit) {
      setLocalUnit({ ...unit, description: unit.description || '' });
    }
  }, [unit]);

  const isValidUnit = useMemoWrapper(validUnitInput, localUnit);
  const isReadyForAbbreviation = useMemoWrapper(readyForAbbreviation, localUnit);

  return (
    <Dialog
      footerLeft={
        localUnit.id ? (
          <>
            <Button
              data-cy="delete-unit-button"
              label="Delete"
              onClick={() => setShowDeleteConfirm(true)}
              startIcon={<Icon name="delete" />}
              type="destructive"
            />
            <DialogsConfirm
              acceptCtaCopy="Delete"
              body={`Are you sure? Deleting ${localUnit.name} will also delete any associated quantities`}
              cancelCtaCopy="Cancel"
              onClose={() => {
                setShowDeleteConfirm(false);
              }}
              onConfirm={() => {
                if (localUnit.id) {
                  removeUnit(projectId, localUnit.id, localUnit.name);
                  onBack();
                }
              }}
              open={showDeleteConfirm}
              title={`Delete ${localUnit.name} from project`}
            />
          </>
        ) : (
          <div />
        )
      }
      footerRight={
        <div className="flex gap-3">
          <Button label="Cancel" onClick={onBack} type="secondary" />
          <Button
            data-cy="save-or-create-unit-button"
            isDisabled={!isValidUnit}
            label={localUnit.id ? 'Save' : 'Create'}
            onClick={() => {
              createOrUpdateUnit(localUnit, projectId);
              onBack();
            }}
            type="primary"
          />
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex items-center gap-3">
          <IconButton className={classes.backButton} onClick={onBack} title="Close create">
            <ArrowForwardIos />
          </IconButton>
          <div>{localUnit.id ? `Edit ${localUnit.name}` : 'Create new unit of measure'}</div>
        </div>
      }
    >
      <DialogContent>
        <div className={classes.rowContainer}>
          <div className={classes.nameInput}>
            <Typography className={classes.caption} variant="caption">
              Name
            </Typography>
            <TextField
              data-cy="input-UoM-Name"
              fullWidth
              InputProps={{
                disableUnderline: true,
                inputProps: { maxLength: 35 },
              }}
              onChange={(evt) => {
                setLocalUnit({ ...localUnit, name: evt.target.value });
              }}
              placeholder="e.g. Beds"
              value={localUnit.name}
            />
          </div>
          <div className={classes.typeInput}>
            <Typography className={classes.caption} variant="caption">
              Type
            </Typography>
            <JoinSelect
              entries={unitTypeOptions.map(({ key, value }) => getEntry(key, value))}
              hidePrint={false}
              onChange={(newValue: string | null) =>
                setLocalUnit({ ...localUnit, type: (newValue || '') as UnitType })
              }
              value={localUnit.type}
            />
          </div>
        </div>
        <div className={`${classes.rowContainer} ${classes.paddingTop}`}>
          <div className={classes.descriptionInput}>
            <Typography className={classes.caption} variant="caption">
              Description
            </Typography>
            <TextField
              data-cy="input-UoM-Description"
              fullWidth
              InputProps={{
                disableUnderline: true,
                inputProps: { maxLength: 70 },
              }}
              onChange={(evt) => {
                setLocalUnit({ ...localUnit, description: evt.target.value });
              }}
              placeholder="Describe this unit"
              value={localUnit.description}
            />
          </div>
        </div>
        {isReadyForAbbreviation && (
          <div className={classes.abbreviation}>
            <Typography className={classes.cardHeaderTypography}>
              Now let&apos;s make sure this unit displays correctly across Join:
            </Typography>
            <div className={classes.abbreviationRow}>
              <Typography className={classes.cardHeaderTypography}>Estimate is $500 /</Typography>
              <TextField
                className={classes.abbreviationInput}
                data-cy="input-UoM-AbbreviationSingular"
                InputProps={{
                  disableUnderline: true,
                  inputProps: { maxLength: 10 },
                }}
                onChange={(evt) => {
                  setLocalUnit({ ...localUnit, abbreviationSingular: evt.target.value });
                }}
                placeholder="e.g. BED"
                value={localUnit.abbreviationSingular}
              />
            </div>
            <div className={classes.abbreviationRow}>
              <Typography className={classes.cardHeaderTypography}>
                Project size is 100,000
              </Typography>
              <TextField
                className={classes.abbreviationInput}
                data-cy="input-UoM-AbbreviationPlural"
                InputProps={{
                  disableUnderline: true,
                  inputProps: { maxLength: 10 },
                }}
                onChange={(evt) => {
                  setLocalUnit({ ...localUnit, abbreviationPlural: evt.target.value });
                }}
                placeholder="e.g. BEDS"
                value={localUnit.abbreviationPlural}
              />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const StyledDialogsCreateOrEditUnit = withStyles(styles)(DialogsCreateOrEditUnit);

export default StyledDialogsCreateOrEditUnit;
