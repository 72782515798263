import { useState } from 'react';

import {
  ProgramCostBreakdownFieldsFragment,
  ProgramCostFieldsFragment,
  ProgramCostTrendline,
  ProgramEstimateChartOptionsQuery,
  ProgramFieldsFragment,
} from '../../../../generated/graphql';
import { useProgramBreakdownQuery } from '../../hooks/useProgramBreakdownQuery';
import {
  CategoryTotals,
  useProgramCategorizedTotalsQuery,
} from '../../hooks/useProgramCategorizedTotalsQuery';
import { useProgramCostsQuery } from '../../hooks/useProgramCostsQuery';
import { useProgramEstimateChartOptionsQuery } from '../../hooks/useProgramEstimateChartOptionsQuery';
import { useProgramQuery } from '../../hooks/useProgramQuery';
import { BY_PROJECT_ID, TOTAL_UNIT_ID } from '../../ProgramCombinedEstimateChart';

import { useProgramCostTrendlineQuery } from './useProgramCostTrendlineQuery';

const defaultGroupingOption = { id: BY_PROJECT_ID, label: 'Projects in Program' };
const defaultUnitOption = { value: TOTAL_UNIT_ID, label: 'Total' };

export type ProgramDashboardData = {
  info: {
    breakdowns?: ProgramCostBreakdownFieldsFragment;
    program?: ProgramFieldsFragment;
    programCosts?: ProgramCostFieldsFragment;
    programCategorizedTotals?: CategoryTotals[];
    programEstimateChartInfo?: ProgramEstimateChartOptionsQuery['programEstimateChartOptions'];
    trendline?: ProgramCostTrendline;
  };
  isLoading: boolean;
  state: {
    selectedGroupingID: string;
    selectedProjectIDs: UUID[];
    selectedUnitAbbreviation: string;
    setSelectedGroupingID: (id: UUID) => void;
    setSelectedProjectIDs: (ids: UUID[]) => void;
    setSelectedUnitAbbreviation: (abb: string) => void;
  };
};

export default function useProgramDashboardHooks(programID: UUID): ProgramDashboardData {
  const [selectedProjectIDs, setSelectedProjectIDs] = useState<UUID[]>([]);
  const [selectedGroupingID, setSelectedGroupingID] = useState<string>(defaultGroupingOption.id);
  const [selectedUnitAbbreviation, setSelectedUnitAbbreviation] = useState<string>(
    defaultUnitOption.value
  );

  const {
    data: programData,
    previousData: prevProgramData,
    loading: loadingProgram,
  } = useProgramQuery(programID, {
    onCompleted: () => {
      setSelectedProjectIDs(programData?.program?.projects?.map((p) => p.id) ?? []);
    },
  });
  const program = programData?.program ?? prevProgramData?.program;

  // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
  const {
    data: breakdownData,
    previousData: prevBreakdownData,
    loading: loadingBreakdownData,
  } = useProgramBreakdownQuery(program?.id, selectedProjectIDs);
  const breakdowns = breakdownData?.programBreakdown ?? prevBreakdownData?.programBreakdown;

  // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
  const {
    data: programCostTrendlineData,
    previousData: prevProgramCostTrendlineData,
    loading: loadingProgramCostTrendlineData,
  } = useProgramCostTrendlineQuery(program?.id, selectedProjectIDs);
  const trendline =
    programCostTrendlineData?.programCostTrendline ??
    prevProgramCostTrendlineData?.programCostTrendline;

  // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
  const {
    data: programCostData,
    previousData: prevProgramCostData,
    loading: loadingProgramCostData,
  } = useProgramCostsQuery(selectedProjectIDs, program?.id);
  const programCosts = programCostData?.programCosts ?? prevProgramCostData?.programCosts;

  const programCategorizationsQueryResult = useProgramEstimateChartOptionsQuery(
    program?.id, // wait for program / selectedProjectIDs to be loaded to avoid duplicate queries with empty list of selectedProjectIDs
    selectedProjectIDs
  );
  const programEstimateChartInfo = (
    programCategorizationsQueryResult.data ?? programCategorizationsQueryResult.previousData
  )?.programEstimateChartOptions;

  // Convert the selected ID to the categorization name (or null for project grouping)
  const categorizationName = selectedGroupingID === BY_PROJECT_ID ? null : selectedGroupingID;

  // Fetch costs for selected grouping
  const {
    data: categorizedTotalsData,
    previousData: prevCategorizedTotalsData,
    loading: loadingCategorizedTotalsData,
  } = useProgramCategorizedTotalsQuery(
    program?.id,
    selectedProjectIDs,
    categorizationName ?? undefined,
    selectedUnitAbbreviation === TOTAL_UNIT_ID ? undefined : selectedUnitAbbreviation
  );
  const programCategorizedTotals =
    categorizedTotalsData?.programCategorizedTotals ??
    prevCategorizedTotalsData?.programCategorizedTotals;

  const isLoading =
    loadingProgram ||
    loadingBreakdownData ||
    loadingProgramCostTrendlineData ||
    loadingProgramCostData ||
    programCategorizationsQueryResult.loading ||
    loadingCategorizedTotalsData;

  return {
    info: {
      breakdowns,
      program,
      programCategorizedTotals,
      programCosts,
      programEstimateChartInfo,
      trendline,
    },
    isLoading,
    state: {
      selectedGroupingID,
      selectedProjectIDs,
      selectedUnitAbbreviation,
      setSelectedGroupingID,
      setSelectedProjectIDs,
      setSelectedUnitAbbreviation,
    },
  };
}
