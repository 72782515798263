import { FC, useRef, useState } from 'react';

import { Popover } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { reportItemsListEditItemsCTA } from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { withStyles } from '../../theme/komodo-mui-theme';
import ItemsListClearBulkEditPanelHeader from '../ItemsList/BulkEditingPanel/BulkEditingPanelHeader/ItemsListBulkEditingPanelHeader';
import { Button } from '../scales';

import styles from './BulkEditPanelStyles';

interface BulkEditPanelProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  categorySelectors: any;
  classes: Classes<typeof styles>;
  clearAllSelectedFields: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  itemsLikeIDsSelected: UUID[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  nonCategorySelectors: any;
  onSubmit: () => void;
}

const BulkEditPanel: FC<BulkEditPanelProps> = ({
  classes,
  categorySelectors,
  clearAllSelectedFields,
  disabled,
  itemsLikeIDsSelected,
  nonCategorySelectors,
  onSubmit,
}) => {
  const sendAnalytics = useSendAnalytics();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const handleClick = () => {
    setAnchorEl(buttonRef.current);
    sendAnalytics(reportItemsListEditItemsCTA(itemsLikeIDsSelected.length));
  };

  return (
    <>
      <Button
        ref={buttonRef}
        data-cy="bulk-editing-edit-button"
        label="Edit Items"
        onClick={handleClick}
        startIcon={<Edit />}
        type="primary"
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.paper,
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ItemsListClearBulkEditPanelHeader clearAllSelectedFields={clearAllSelectedFields} />
        <div className={classes.scroll}>
          {nonCategorySelectors && nonCategorySelectors}
          {categorySelectors && categorySelectors}
        </div>
        <div className={classes.applyChangesButtonContainer}>
          <Button
            data-cy="bulk-editing-submit-button"
            isDisabled={disabled}
            label="Apply Changes"
            onClick={() => {
              onSubmit();
              setAnchorEl(null);
            }}
            type="primary"
          />
        </div>
      </Popover>
    </>
  );
};

export default withStyles(styles)(BulkEditPanel);
