import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { isUUID } from 'validator';

import { useCurrentUser } from './current-user';

const RECENTLY_VIEWED_PROJECTS_KEY = 'NAV_LATEST_ENTRIES';
const MAX_RECENTLY_VIEWED_PROJECTS = 5;
const IGNORED_URLS = ['/insights']; // Define URLs to ignore

export type LastViewedProject = {
  id: string;
  type: 'project' | 'program';
};

type RecentlyViewedProjectsContextType = {
  lastViewedProjects: LastViewedProject[];
  addLastViewedEntry: (entry: LastViewedProject) => void;
  removeLastViewedEntry: (entry: LastViewedProject) => void;
};

const RecentlyViewedProjectsContext = createContext<RecentlyViewedProjectsContextType | undefined>(
  undefined
);

export const RecentlyViewedProjectsContextProvider = ({ children }: { children: ReactNode }) => {
  const userID = useCurrentUser().id;
  const { projectId, programID } = useParams();

  const userStorageKey = `${RECENTLY_VIEWED_PROJECTS_KEY}:${userID}`;
  const [localStorageProjects, setLocalStorageProjects] = useLocalStorage<LastViewedProject[]>(
    userStorageKey,
    []
  );
  const [lastViewedProjects, setLastViewedProjects] = useState<LastViewedProject[]>(
    localStorageProjects || []
  );

  // Check if URL should be ignored
  const shouldIgnoreUrl = useCallback((): boolean => {
    return IGNORED_URLS.some((path) => window.location.pathname.startsWith(path));
  }, []);

  // Update last viewed projects
  const updateLastViewedProjects = useCallback(
    (id: string, type: 'project' | 'program') => {
      setLastViewedProjects((prevEntries) => {
        const updatedEntries = [
          { id, type },
          ...prevEntries.filter((entry) => entry.id !== id),
        ].slice(0, MAX_RECENTLY_VIEWED_PROJECTS);
        setLocalStorageProjects(updatedEntries);
        return updatedEntries;
      });
    },
    [setLocalStorageProjects]
  );

  // Add projects or programs to last viewed
  useEffect(() => {
    if (shouldIgnoreUrl()) return;

    if (projectId && isUUID(projectId)) {
      updateLastViewedProjects(projectId, 'project');
    } else if (programID && isUUID(programID)) {
      updateLastViewedProjects(programID, 'program');
    }
  }, [projectId, programID, shouldIgnoreUrl, updateLastViewedProjects]);

  const addLastViewedEntry = useCallback(
    (entry: LastViewedProject) => {
      updateLastViewedProjects(entry.id, entry.type);
    },
    [updateLastViewedProjects]
  );

  const removeLastViewedEntry = useCallback(
    (entry: LastViewedProject) => {
      setLastViewedProjects((prevEntries) => {
        const updatedEntries = prevEntries.filter((e) => e.id !== entry.id);
        setLocalStorageProjects(updatedEntries);
        return updatedEntries;
      });
    },
    [setLocalStorageProjects]
  );

  return (
    <RecentlyViewedProjectsContext.Provider
      value={{ lastViewedProjects, addLastViewedEntry, removeLastViewedEntry }}
    >
      {children}
    </RecentlyViewedProjectsContext.Provider>
  );
};

export function useRecentlyViewedProjects() {
  const context = useContext(RecentlyViewedProjectsContext);
  if (!context) {
    throw new Error(
      'useRecentlyViewedProjects must be used within a RecentlyViewedProjectsContextProvider'
    );
  }
  return context;
}
