import { FC, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Typography, withStyles } from '@material-ui/core';

import {
  ItemEvent,
  itemSidebarEvent,
  openItemMilestoneTransitionDialog,
  removeItemMilestoneAnalytics,
  updateItemMilestoneAnalytics,
} from '../../../../analytics/analyticsEventProperties';
import {
  milestoneTransitionOpenVar,
  reloadGridVersionVar,
} from '../../../../api/apollo/reactiveVars';
import { OPTION } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import AddToMilestoneModal from '../../../AddToMilestone/AddToMilestoneModal';
import SelectEventItemData from '../../../Select/SelectEvent/SelectEventItemData';
import SelectMilestoneButton from '../../../Select/SelectMilestoneButton/SelectMilestoneButton';
import {
  useItemMilestoneUpdate,
  useRemoveItemMilestoneHistory,
} from '../../../ve-item-details/ItemDetailsHooks';
import ItemMilestoneMap from '../../../ve-item-details/ItemMilestoneMap';
import ItemSidebarTextField from '../ItemSidebarTextField';
import { ItemPermissions, getSidebarLocation } from '../ItemSidebarUtils';

import styles from './ItemMilestoneContainerStyles';

type ItemMilestoneStatus = {
  milestone: string | undefined;
  status: Status | undefined;
};

type ItemMilestoneContainerProps = {
  classes: Classes<typeof styles>;
  item: ItemLike;
  milestones: Milestone[];
  permissions: ItemPermissions;
  projectID: UUID;
  onItemMutated?: (isCostMutated?: boolean) => void;
};

const ItemMilestoneContainer: FC<ItemMilestoneContainerProps> = ({
  classes,
  item,
  milestones,
  permissions,
  projectID,
  onItemMutated,
}) => {
  // HOOKS
  const sendAnalytics = useSendAnalytics();
  const showMilestoneTransition = useReactiveVar(milestoneTransitionOpenVar);

  // STATE
  const [itemMilestoneStatus, setItemMilestoneStatus] = useState<ItemMilestoneStatus>({
    milestone: undefined,
    status: undefined,
  });

  // VARIABLES
  const { id, milestone = null, itemType } = item || {};
  const { name } = milestone || {};
  const isOption = itemType === OPTION;
  const { canEditItemMilestone, canEditEvent, canViewEvent } = permissions;
  const disabled = !canEditItemMilestone || isOption;

  // UPDATES
  // Milestones
  const updateItemMilestone = useItemMilestoneUpdate({
    onCompleted: () => {
      onItemMutated?.();
      reloadGridVersionVar(reloadGridVersionVar() + 1);
    },
  });
  const setItemMilestone = (updateItem: ItemLike, milestoneId: string, status: Status) => {
    sendAnalytics(
      updateItemMilestoneAnalytics({
        id: updateItem.id,
        itemType: updateItem.itemType,
        milestoneId,
        status,
        location: getSidebarLocation(),
      })
    );
    updateItemMilestone(projectID, updateItem, milestoneId, status);
    milestoneTransitionOpenVar(false);
  };

  const removePreviousMilestoneHistory = useRemoveItemMilestoneHistory({
    onCompleted: () => {
      onItemMutated?.(false);
    },
  });
  const removeItemMilestones = (milestoneToDelete: Milestone | null) => {
    if (milestoneToDelete) {
      removePreviousMilestoneHistory(item, milestoneToDelete.id);
      sendAnalytics(
        removeItemMilestoneAnalytics({
          itemId: item.id,
          milestoneId: milestoneToDelete.id,
          location: getSidebarLocation(),
        })
      );
    }
  };

  // Milestone transition dialog
  const closeDialog = () => {
    sendAnalytics(
      openItemMilestoneTransitionDialog({
        id,
        milestoneId: item ? milestone?.id : undefined,
        isOpen: 'CLOSE',
      })
    );
    milestoneTransitionOpenVar(false);
  };

  const renderModal = () => {
    const selectedMilestone = itemMilestoneStatus.milestone || item.milestone?.id;
    const selectedStatus = itemMilestoneStatus.status || item.status;
    return (
      <AddToMilestoneModal
        isOpen={showMilestoneTransition}
        item={item}
        milestones={milestones.filter((m: Milestone) => !m.isDraft)}
        onCancel={closeDialog}
        onChangeMilestone={(milestone) => {
          setItemMilestoneStatus({ milestone, status: selectedStatus });
        }}
        onChangeStatus={(status: Status) => {
          setItemMilestoneStatus({ milestone: selectedMilestone, status });
        }}
        onClose={closeDialog}
        onSubmit={() => {
          const { milestone, status } = itemMilestoneStatus;
          if (milestone && status && item) setItemMilestone(item, milestone, status);
        }}
        selectedMilestone={selectedMilestone}
        selectedStatus={selectedStatus}
      />
    );
  };

  const eventsElement = canViewEvent && (
    <div className={`${classes.field}`}>
      <Typography className={classes.captionColor} variant="caption">
        Events
      </Typography>
      <div className={classes.noPrint}>
        <SelectEventItemData
          analyticsEvent={itemSidebarEvent(ItemEvent.EVENT_SELECT)}
          disabled={!canEditEvent}
          item={item}
          maxChips={3}
          onItemMutated={onItemMutated}
          projectID={projectID}
        />
      </div>
    </div>
  );

  return (
    <>
      {showMilestoneTransition && !isOption && renderModal()}
      <div className={`${classes.field} ${classes.noPrint}`}>
        <Typography className={classes.captionColor} variant="caption">
          Milestone
        </Typography>
        <div className={classes.contentContainer}>
          {canEditItemMilestone ? (
            <SelectMilestoneButton
              canEditItemMilestone={canEditItemMilestone}
              disabled={disabled}
              name={name ?? ''}
              onClick={() => {
                milestoneTransitionOpenVar(true);
                sendAnalytics(
                  openItemMilestoneTransitionDialog({
                    id,
                    milestoneId: item ? milestone?.id : '',
                    isOpen: 'OPEN',
                  })
                );
              }}
            />
          ) : (
            <ItemSidebarTextField value={name ?? ''} />
          )}
          <ItemMilestoneMap
            canEditItemMilestone={canEditItemMilestone}
            currentItem={item}
            onChange={removeItemMilestones}
            projectID={projectID}
            shouldDisplayCosts={permissions.shouldDisplayCosts}
          />
        </div>
      </div>

      {eventsElement}
    </>
  );
};

export default withStyles(styles)(ItemMilestoneContainer);
