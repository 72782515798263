import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import {
  CreateItemFromIdeaMutation,
  CreateItemFromIdeaMutationVariables,
} from '../../../../generated/graphql';
import { RouteKeys } from '../../../../routes/paths';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { useProjectID } from '../../../../utilities/routes/params';

import { createItemFromIdeaMutation } from './queries';

export default function useCreateItemFromIdeaMutation(ideaID: UUID, hiddenAlternates: UUID[]) {
  const projectID = useProjectID() || '';
  const navigate = useNavigate();

  const [createItem] = useMutation<CreateItemFromIdeaMutation, CreateItemFromIdeaMutationVariables>(
    createItemFromIdeaMutation
  );

  const submitFunc = useCallback(async () => {
    const mutationResult = await createItem({
      variables: { projectID, ideaID, hiddenAlternates },
    });

    const itemID = mutationResult.data?.createItemFromIdea;
    if (itemID)
      navigate(
        generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, { projectId: projectID, itemId: itemID })
      );
  }, [createItem, hiddenAlternates, ideaID, navigate, projectID]);

  return [submitFunc];
}
