import { useParams } from 'react-router-dom';

import { PortraitOrientation } from '../Print/PrintUtils';

import PrintProgramDashboardData from './PrintProgramDashboardData';

export default function PrintProgramDashboardRoute() {
  const { programID } = useParams();

  if (!programID) return null;
  // Note: we need to set the width of the page so it prints correctly
  return (
    <div className="h-full w-[960px] overflow-visible">
      <PortraitOrientation />
      <PrintProgramDashboardData programID={programID} />;
    </div>
  );
}
