import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { RouteKeys } from '../../routes/paths';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../utilities/routes/links';
import { useProjectID } from '../../utilities/routes/params';

type HasDisplayCostsProps = {
  children: ReactNode;
};

export function HasDisplayCosts(props: HasDisplayCostsProps) {
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const projectId = useProjectID();

  return shouldDisplayCosts ? (
    <>{props.children}</>
  ) : (
    <Navigate to={generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId })} />
  );
}
