import { FC, useCallback, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { BREAKDOWNS, OVERALL_PROJECT } from '../../../constants';
import { IntervalType } from '../../../generated/graphql';
import useListCategoriesQuery from '../../../hooks/useListCategoriesQuery';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useProjectID } from '../../../utilities/routes/params';
import { removeYear } from '../../../utilities/string';
import { usePersistentStates } from '../../../utilities/urlState';
import { HasDisplayCosts } from '../../conditionals/HasDisplayCosts';
import { dashboardColumnTypes } from '../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrend';
import { useMilestoneQuery } from '../../Milestone/hooks';

import { useMilestonesCostTrendsBreakdownsQuery } from './hooks/useMilestonesCostTrendsBreakdownsQuery';
import PrintBreakdowns from './PrintBreakdowns';
import PrintBreakdownsStyles from './PrintBreakdownsStyles';
import { BREAKDOWNS_DEFAULTS } from './utils';

const PrintBreakdownsData: FC = () => {
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');
  const { location } = window;
  const [settings, setSettings] = usePersistentStates(
    location,
    BREAKDOWNS,
    BREAKDOWNS_DEFAULTS,
    `${projectId} - Breakdowns - `
  );
  const setSelected = useCallback((selected: string) => setSettings({ selected }), [setSettings]);
  const { selected } = settings; // this will removeYear(c.name), for legibility

  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const categorization =
    categorizations && categorizations.find((c) => removeYear(c.name) === selected);
  const activeMilestoneId = project?.activeMilestone?.id || '';
  // resetter
  useEffect(() => {
    if (!selected || !categorization) {
      const name = categorizations && categorizations[0] && categorizations[0].name;
      if (name) setSelected(removeYear(name));
    }
  }, [categorization, categorizations, selected, setSelected]);

  const { data: { category: categories } = { category: [] } } = useListCategoriesQuery(
    categorization && categorization.id,
    '',
    1
  );
  // Milestone Info from active
  const { data: { milestone: activeMilestone } = { milestone: undefined } } =
    useMilestoneQuery(activeMilestoneId);

  const milestoneName = (activeMilestone && activeMilestone.name) || '';

  const menuOptions = categorizations.map((c: Categorization) => ({
    key: removeYear(c.name),
    text: removeYear(c.name) || '',
    subText: '',
  }));

  const onChange = (categorizationName: string) => {
    setSelected(categorizationName);
  };

  useEffect(() => {
    if (project && project.name)
      document.title = `${project.name} - Trendline - Breakdown by Category`;
  }, [project]);

  const chartCategoriesData =
    categories &&
    categories.concat({
      name: OVERALL_PROJECT,
      id: OVERALL_PROJECT,
      categorization: undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    } as any);

  const viewFilters: ViewFilterInput[] = chartCategoriesData.map((category) => {
    const { id, categorization } = category;
    const categories = categorization ? [{ id, categorizationID: categorization.id }] : [];
    return { categories };
  });

  const intervalType = IntervalType.BI_WEEKLY;
  const columns: CostReportColumnInput[] = dashboardColumnTypes.map((type) => ({
    type,
  }));

  const {
    data: { milestonesCostTrendsBreakdowns } = { milestonesCostTrendsBreakdowns: [] },
    loading,
  } = useMilestonesCostTrendsBreakdownsQuery(
    projectId,
    columns,
    intervalType,
    viewFilters,
    true,
    activeMilestoneId
  );

  return categorization ? (
    <PrintBreakdowns
      categorization={categorization}
      chartData={chartCategoriesData as Category[]}
      loading={loading}
      menuOptions={menuOptions}
      milestoneName={milestoneName}
      milestonesCostTrendsBreakdowns={milestonesCostTrendsBreakdowns}
      onChange={onChange}
      selected={selected}
      setSettings={setSettings}
      settings={settings}
    />
  ) : null;
};

const Styled = withStyles(PrintBreakdownsStyles)(PrintBreakdownsData);

export default () => (
  <HasDisplayCosts>
    <Styled />
  </HasDisplayCosts>
);
