import {
  JoinCompanyOrganizationsRoutes,
  JoinCompanyRoutes,
  JoinCompanyStandardsRoutes,
  JoinRoutes,
} from '../api/gqlEnums';
import {
  COMPANY_CATEGORIZATIONS_PATH,
  COMPANY_COLLABORATORS_PATH,
  COMPANY_INSIGHTS_ALERTS_PATH,
  COMPANY_MEMBERS_PATH,
  COMPANY_ORGANIZATIONS_PATH,
  COMPANY_PROJECT_STATS_PATH,
  COMPANY_PROJECT_TEMPLATES_PATH,
  COMPANY_PROJECT_TYPES_PATH,
  COMPANY_STANDARDS_PATH,
  COMPANY_TAB_PATH,
} from '../constants';
import { YC_INSIGHTS_V2 } from '../features';
import { PermissionResource } from '../generated/graphql';
import { INSIGHTS_V2 } from '../moduleEntitlementFlagsList';

/**
 * ================== 🚀🚀🚀 NEW STUFF! 🚀🚀🚀 ==================
 * You should probably consider doing any significant new work in
 * `routes/index.tsx` instead of in here. This file is our legacy way of adding
 * routes and anything added here is gonna need to be ported over. If you have
 * any questions, ask Mark or in #ask-engineering.
 * ================== 🚀🚀🚀 NEW STUFF! 🚀🚀🚀 ==================
 * */

const CompanyOrganizationsRoutes: {
  [key in JoinCompanyOrganizationsRoutes]: SharedRoute;
} = {
  [JoinCompanyOrganizationsRoutes.COMPANY_ORGANIZATION]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_ORGANIZATIONS_PATH}/:organizationId`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
};

const CompanyStandardsRoutes: {
  [key in JoinCompanyStandardsRoutes]: SharedRoute;
} = {
  [JoinCompanyStandardsRoutes.COMPANY_CATEGORIZATIONS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}/${COMPANY_CATEGORIZATIONS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyStandardsRoutes.COMPANY_PROJECT_TEMPLATES]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}/${COMPANY_PROJECT_TEMPLATES_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyStandardsRoutes.COMPANY_PROJECT_TYPES]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}/${COMPANY_PROJECT_TYPES_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
};

const CompanyRoutes: { [key in JoinCompanyRoutes]: SharedRoute } = {
  [JoinCompanyRoutes.COMPANY_MEMBERS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_MEMBERS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_COLLABORATORS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_COLLABORATORS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_INSIGHTS_ALERTS]: {
    featureFlag: YC_INSIGHTS_V2,
    moduleEntitlementFlag: INSIGHTS_V2,
    isEveryCheckRequired: true,
    path: `/${COMPANY_TAB_PATH}/${COMPANY_INSIGHTS_ALERTS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_MEMBERS_PROFILE]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_MEMBERS_PATH}/:userId`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_COLLABORATORS_PROFILE]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_COLLABORATORS_PATH}/:userId`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_PROJECT_STATS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_PROJECT_STATS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
  },
  [JoinCompanyRoutes.COMPANY_ORGANIZATIONS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_ORGANIZATIONS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
    routes: CompanyOrganizationsRoutes,
  },
  [JoinCompanyRoutes.COMPANY_STANDARDS]: {
    path: `/${COMPANY_TAB_PATH}/${COMPANY_STANDARDS_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
    routes: CompanyStandardsRoutes,
  },
};

export const RootRoutes: { [key in JoinRoutes]: SharedRoute } = {
  [JoinRoutes.COMPANY_TAB]: {
    path: `/${COMPANY_TAB_PATH}`,
    permissionResource: PermissionResource.COMPANY_DETAILS,
    routes: CompanyRoutes,
  },
};

export const AllRoutes = {
  ...CompanyRoutes,
  ...CompanyOrganizationsRoutes,
  ...CompanyStandardsRoutes,
  ...RootRoutes,
};

export type GenericJoinRoutes = {
  [key in string]: SharedRoute;
};

export type SharedRoute = {
  path: string;
  featureFlag?: string;
  moduleEntitlementFlag?: string;
  permissionResource?: PermissionResource;
  isEveryCheckRequired?: boolean;
  routes?: GenericJoinRoutes;
};
