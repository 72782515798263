import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { itemSidebarOpenVar } from '../../../api/apollo/reactiveVars';
import { refetchItem } from '../../../api/refetchSets';
import { GENERAL_INFO, OPTIONS } from '../../../constants';
import { ResourceType } from '../../../generated/graphql';
import { useCostMode } from '../../../utilities/costMode';
import { toSharedResourceUsers } from '../../../utilities/utilities';
import { ItemTypes } from '../../CostReport/CostReportList/CostReportListRow/CostReportListRowUtils';
import DialogsNewItem from '../../Dialogs/DialogsNewItem/DialogsNewItem';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';

import ItemSidebar from './ItemSidebar';
import ItemSidebarFooterNav from './ItemSidebarFooterNav';
import { useCalculateItemPermissions } from './ItemSidebarUtils';

type ItemSidebarWrapperProps = {
  items?: ItemTypes[];
  milestones: Milestone[];
  onItemMutated?: (isCostMutated?: boolean) => void;
  defaultExpanded?: string;
  onClose?: () => void;
};

const ItemSidebarWrapper: FC<ItemSidebarWrapperProps> = ({
  items,
  milestones,
  onItemMutated,
  onClose,
  defaultExpanded = GENERAL_INFO,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const costMode = useCostMode();
  const itemIdReactiveVar = useReactiveVar(itemSidebarOpenVar);

  const [openDialogAddOption, setOpenDialogAddOption] = useState(false);
  const [expanded, setExpanded] = useState<string[]>(defaultExpanded ? [defaultExpanded] : []);

  // QUERIES
  const { data, loading, previousData, refetch } = useItemQuery(itemIdReactiveVar ?? undefined);

  // TODO: Remove this cast - CT-481
  const item = (data?.item ?? previousData?.item ?? null) as ItemLike;
  const { id, milestone = null } = item ?? {};

  // TODO CT-753: Remove useCalculateItemPermissions and ItemPermissions struct
  const itemSidebarPermissions = useCalculateItemPermissions(item);
  const { data: sharedResourceData, loading: sharedResourceLoading } = useGetSharedResource(
    item?.id,
    ResourceType.ITEM
  );
  const sharedUsers = toSharedResourceUsers(sharedResourceData);

  // REFETCH
  const refetchNewItem = () => id && refetchItem(id);

  const openSidebar = (id?: string | undefined) => {
    if (id) itemSidebarOpenVar(id);
  };

  const closeSidebar = () => {
    itemSidebarOpenVar(null);
    onClose?.();
  };

  return (
    <>
      {openDialogAddOption && milestone && (
        <DialogsNewItem
          key={id}
          milestoneID={milestone?.id}
          onClose={() => {
            refetchNewItem();
            setOpenDialogAddOption(false);
            if (item?.activeEstimate || ('options' in item && item.options.length > 0)) {
              setExpanded([OPTIONS]);
            }
          }}
          onCreateAnother={refetchNewItem}
          open={openDialogAddOption}
          parentItem={item as Item}
        />
      )}
      {itemIdReactiveVar && (
        <ItemSidebar
          closeSidebar={closeSidebar}
          costMode={costMode}
          expanded={expanded}
          footer={
            items ? (
              <ItemSidebarFooterNav item={item} items={items} openSidebar={openSidebar} />
            ) : null
          }
          item={item}
          items={items}
          loading={loading || sharedResourceLoading}
          milestones={milestones}
          onItemMutated={(isCostMutated?: boolean) => {
            onItemMutated?.(isCostMutated);
            refetch();
          }}
          openSidebar={openSidebar}
          permissions={itemSidebarPermissions}
          projectId={projectId}
          setExpanded={setExpanded}
          setOpenDialogAddOption={setOpenDialogAddOption}
          sharedUsers={sharedUsers}
        />
      )}
    </>
  );
};

export default ItemSidebarWrapper;
