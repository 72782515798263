import { Context, FieldFunctionOptions, TypePolicies } from '@apollo/client';

import { isImage, isModel, isPdf } from '../../components/assets/utils';
import { IMAGE, MODEL, PDF } from '../../constants';
import {
  CompanyCategorization,
  Line,
  UserNotificationSetting,
  UserReportComment,
} from '../../generated/graphql';

import { projectCompsSetInputVar } from './reactiveVars';
import { offsetLimitArrayPagination, offsetLimitObjectPagination } from './utilities';

const Query = {
  fields: {
    projectCompSettingsReactive: {
      read() {
        return projectCompsSetInputVar();
      },
    },
    projects: offsetLimitArrayPagination([
      'projectsFilterBy',
      'projectsSortBy',
      'projectsSearch',
      'includeCompanyProjects',
    ]),
    forecastingProjects: offsetLimitArrayPagination(['filters', 'search', 'sortBy', 'unitID']),
    forecastingItems: offsetLimitObjectPagination([
      'filters',
      'projectsFilters',
      'search',
      'sortBy',
      'unitID',
    ]),
    insights: offsetLimitArrayPagination(['input']),
    searchProjects: offsetLimitObjectPagination([
      'filters',
      'searchResultType',
      'search',
      'sortBy',
      'unitID',
    ]),
    searchItems: offsetLimitObjectPagination([
      'filters',
      'projectsFilters',
      'searchResultType',
      'search',
      'sortBy',
      'unitID',
    ]),
    projectActivity: offsetLimitObjectPagination([
      'projectID',
      'filterByUserID',
      'costMode',
      'dateFilter',
    ]),
  },
};

const typePoliciesCustom = {
  // Assets can return different thumbnailURL for different requested sizes
  Asset: {
    keyFields: (object: Asset, context: Context) =>
      // @ts-ignore TODO: CT-896 check for thumbnailURL, more probably can be dropped
      `${context.typename}:${object.id} ${object.thumbnailURL}`,
    fields: {
      type: (_type: string, { readField }: FieldFunctionOptions) => {
        const name: string | undefined = readField('name');
        if (!name) return null;
        if (isImage(name)) {
          return IMAGE;
        }
        if (isModel(name)) {
          return MODEL;
        }
        if (isPdf(name)) {
          return PDF;
        }
        return null;
      },
    },
  },
  // We don't have IDs on cells, so we construct one from the field and line ID.
  Cell: {
    keyFields: (object: Cell, context: Context) =>
      `${context.typename}:${object.field}:${object.line}:${object.estimateId || ''}`,
  },
  CompanyCategorization: {
    keyFields: (object: CompanyCategorization, context: Context) =>
      `${context.typename}:${object.categorization.id}`,
  },
  CompanyUser: {
    keyFields: (object: CompanyUser, context: Context) => `${context.typename}:${object?.user?.id}`,
  },
  // Categorized item costs need to use both item ids and ids for all the categories to avoid conflicts.
  CategorizedItemCost: {
    keyFields: (object: CategorizedItemCost, context: Context) =>
      `${context.typename}:${object.id}:${object.categories
        .map((c) => `${c.id}-level${c.level}`)
        .join(':')}:${JSON.stringify(object.range)}:${object.itemContributionLines.map(
        (icl) => `${icl.lineID}:${JSON.stringify(icl.range)}`
      )}`,
  },
  // Inherited milestone markups on items need to be treated as different from the parent on the milestone estimate.
  Category: {
    keyFields: (object: Category, context: Context) =>
      `${context.typename}:${object.id}:${object.number}:${
        object.categorization && object.categorization.id
      }:${object.levels && object.levels.map((l) => l && l.number).join(':')}`,
  },
  Item: {
    keyFields: (object: Item, context: Context) =>
      `${context.typename}:${object.id}:${
        object.filteredMilestone ? object.filteredMilestone : 'all-milestones'
      }:${object.filteredCategories ? object.filteredCategories.toString() : ''}`,
  },
  // Event items share ids across events so we need to disable caching
  EventItem: {
    keyFields: false,
  },
  // the same ItemHistoryEvent can have different eventContent coming from the
  // itemHistory and projectActivity queries, so we don't want to cache them together
  ItemHistoryEvent: {
    keyFields: false,
  },
  // ItemsListItem vary by costmode, so important to include cost as part of key
  ItemsListItem: {
    // TODO: ItemsListItem should use itemLikeID for the Item's ID and use a new truly unique ID
    keyFields: (object: ItemsListItem, context: Context) =>
      `${context.typename}:${object.id}:${object.status}:${object.milestone?.id}:${JSON.stringify(
        object.cost
      )}:${object.currentStatus}:${object.currentMilestone?.id}:${JSON.stringify(
        object.currentCost
      )}`,
  },
  // Inherited milestone markups on items need to be treated as different from the parent on the milestone estimate.
  Markup: {
    keyFields: (object: Markup, context: Context) =>
      `${context.typename}:${object.id}:${object.estimateId || ''}`,
  },
  Line: {
    keyFields: (object: Line, context: Context) =>
      `${context.typename}:${object.id}:${object.estimateId || ''}`,
  },
  Option: {
    keyFields: (object: Option, context: Context) =>
      `${context.typename}:${object.id}:${
        object.filteredMilestone ? object.filteredMilestone : 'all-milestones'
      }:${
        object.cost && (object.cost as CostScalar).value ? (object.cost as CostScalar).value : 0
      }`,
  },
  // Don't cache roles. A single role's permissionGroups can change depending on
  // the way it was fetched. Eg, the roles returned by a ProjectRoles are
  // different from the roles returned as part of the currentCollaborator
  // userRoleSetting query, due to the addition of company permissions in the
  // latter.
  // TODO DD-914: take company permisions out of project roles, so project roles can have a true id
  Role: {
    keyFields: false,
  },
  ProjectCategorization: {
    keyFields: (object: ProjectCategorization, context: Context) =>
      `${context.typename}:${object.projectID}:${object.categorization.id}`,
  },
  // Project Company
  ProjectCompany: {
    keyFields: (object: ProjectCompany) => `${object.company.id}`,
  },
  UserNotificationSetting: {
    keyFields: (object: UserNotificationSetting, context: Context) =>
      `${context.typename}:${object.channel}`,
  },
  UserReportComment: {
    keyFields: (object: UserReportComment, context: Context) =>
      `${context.typename}:${object.commentLineID}`,
  },
  ...dateUpdates([
    // [typename, fieldName],
    ['InsightsMilestone', 'date'],
    ['CostTimeseries', 'date'],
    ['ContingenciesTimeseries', 'date'],
    ['ProgramSeparatedCostTimeSeries', 'date'],
  ]),
};

function dateUpdates(pairs: [string, string][]) {
  const datePolicies: TypePolicies = {};
  pairs.forEach((pair) => {
    datePolicies[pair[0]] = {
      fields: {
        [pair[1]]: (dateStr: string) => {
          // string ISO to Date
          return new Date(dateStr);
        },
      },
    };
  });
  return datePolicies;
}

const typePolicies = {
  Query,
  // Type policies definitions:
  ...typePoliciesCustom,
};

export default typePolicies;
