import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import { getProjectIdFromUrl } from '../url';

/** @deprecated use useParams() instead */
export const useProjectID = (): UUID | undefined => {
  const { projectId } = useParams<{ projectId: UUID }>();
  if (projectId && isUUID(projectId)) return projectId;

  // We're stuck failing back to the URL if we don't have a projectId param.
  // Main reason for this is that we're currently calling this in
  // usePermissions. That's fine generally...the only place it's /not/ fine is
  // if usePermissions is called outside of a router. This is the case when
  // we're calling it via useIsRouteAccessible. When we're done migrating routes
  // over to the new router, we can remove this fallback.
  return getProjectIdFromUrl() || undefined;
};
