import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import useLocalStorage from '../../../hooks/useLocalStorage';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { IdeaFilters, StarredToggle } from '../types';

import { IdeasEventType, useSendIdeasAnalytics } from './hooks/analytics';
import useIdeaGroupsQuery from './hooks/useIdeaGroupsQuery';
import useIdeasQuery from './hooks/useIdeasQuery';
import Ideas from './Ideas';

export default function IdeasIndexRoute() {
  const { projectId } = useParams();
  if (!projectId || !isUUID(projectId)) {
    throw new Error('Failed to get projectId param');
  }

  const [filters, setFilters] = useLocalStorage<IdeaFilters>('IDEA_FILTERS', {
    categories: [],
    search: '',
    toggleValue: StarredToggle.ALL_IDEAS,
  });
  const [milestoneID, setMilestoneID] = useState<UUID>();
  const [starredIdeas, setStarredIdeas] = useLocalStorage<UUID[]>('STARRED_IDEAS', []);

  const activeMilestoneID = useProjectPropsQuery(projectId).data.project?.activeMilestone.id;

  const ideaGroupsQuery = useIdeaGroupsQuery(projectId, milestoneID, filters);
  const ideaGroups = (
    ideaGroupsQuery?.data?.ideaGroups ??
    ideaGroupsQuery.previousData?.ideaGroups ??
    []
  )
    .map((ideaGroup) => ({
      ...ideaGroup,
      ideas: ideaGroup.ideas.filter(
        ({ id }) => filters.toggleValue === StarredToggle.ALL_IDEAS || starredIdeas.includes(id)
      ),
    }))
    .filter(({ ideas }) => ideas.length > 0);
  const ideasQuery = useIdeasQuery(projectId, milestoneID, filters);
  const ideas = (ideasQuery?.data?.ideas ?? ideasQuery.previousData?.ideas ?? []).filter(
    ({ id }) => filters.toggleValue === StarredToggle.ALL_IDEAS || starredIdeas.includes(id)
  );

  // We use the first idea group when no results are able to be shown
  const unfilteredIdeaGroupsQuery = useIdeaGroupsQuery(projectId, milestoneID, {
    categories: [],
    search: '',
  });
  const ideasForNoResults =
    unfilteredIdeaGroupsQuery.data?.ideaGroups[0].ideas ??
    unfilteredIdeaGroupsQuery.previousData?.ideaGroups[0].ideas ??
    [];

  const sendIdeasAnalytics = useSendIdeasAnalytics();

  return (
    <Ideas
      filters={filters}
      ideaGroups={ideaGroups}
      ideas={ideas}
      ideasForNoResults={ideasForNoResults}
      milestoneID={milestoneID ?? activeMilestoneID}
      onChangeMilestone={(milestoneID) => {
        sendIdeasAnalytics(IdeasEventType.CHANGE_MILESTONE, { milestoneID });
        setMilestoneID(milestoneID);
      }}
      onResetFilters={() => {
        sendIdeasAnalytics(IdeasEventType.RESET_ALL_FILTERS);
        setFilters({ categories: [], search: '', toggleValue: StarredToggle.ALL_IDEAS });
      }}
      onStar={(ideaID, isStarred) =>
        setStarredIdeas(
          isStarred
            ? [...starredIdeas.filter((id) => id !== ideaID), ideaID]
            : starredIdeas.filter((id) => id !== ideaID)
        )
      }
      onUpdateFilters={(updatedFilters) => setFilters({ ...filters, ...updatedFilters })}
      projectID={projectId}
      starredIdeas={starredIdeas}
    />
  );
}
