import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import { InsightsProject } from '../types';

export default function PendingItemDonutChart(props: {
  isProjectsList?: boolean;
  project: InsightsProject;
}) {
  const total =
    props.project.pendingItemsDueInFuture +
    props.project.pendingItemsMissingDueDates +
    props.project.pendingItemsPastDue;
  const chartSizeDetails = {
    diameter: 170,
    insideDiameter: 110,
  };
  const chartSizeList = {
    diameter: 60,
    insideDiameter: 35,
  };
  const chartSize = props.isProjectsList ? chartSizeList : chartSizeDetails;
  const sublabel = props.isProjectsList ? '' : 'Pending';

  const pieChartData = [
    {
      name: 'Past due',
      share: props.project.pendingItemsPastDue,
      color: 'var(--colors-entities-item-pastdue)',
    },
    {
      name: 'Due in future',
      share: props.project.pendingItemsDueInFuture,
      color: 'var(--colors-item-status-pending)',
    },
    {
      name: 'Missing due dates',
      share: props.project.pendingItemsMissingDueDates,
      color: 'var(--colors-item-status-pending-tint)',
    },
  ].filter((item) => item.share > 0);
  const pieColors: string[] = pieChartData.map((d) => d.color);

  return (
    <ChartsPieGraph
      centerLabel={{
        label: String(total),
        sublabel,
      }}
      chartSize={chartSize}
      colors={pieColors}
      data={pieChartData}
      hasSectionTooltip={!props.isProjectsList}
      labelStyle="type-label"
    />
  );
}
