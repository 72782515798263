import { FC } from 'react';

import { DRAG_DROP_ASSET_ITEM_DETAILS } from '../../../actions/actionTypes';
import { getItemAssetAnalytics } from '../../../analytics/analyticsEventProperties';
import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import { useItemUploadAttachAssets } from '../hooks/AssetsMutationHook';
import useOptimisticItemUploadAssets from '../hooks/useOptimisticItemUploadAssets';
import useOptimisticProjectUploadAssets from '../hooks/useOptimisticProjectUploadAssets';

import AssetsWrapper from './AssetsWrapper';
import { useItemAttachments } from './AssetsWrapperUtils';

type AssetsWrapperItemDataProps = {
  itemID?: UUID;
  onItemMutated?: () => void;
  projectID: UUID;
};

const AssetsWrapperItemData: FC<AssetsWrapperItemDataProps> = (props) => {
  const item = useItemQuery(props.itemID).data?.item;

  const { canView, canAdd, canDelete } = usePermissions({
    trades: item?.categories,
  });

  // Permissions
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const canAddItemAttachments = canAdd(PermissionResource.ITEM_ATTACHMENTS);
  const canDeleteItemAttachments = canDelete(PermissionResource.ITEM_ATTACHMENTS);

  const analyticsEvent = getItemAssetAnalytics(DRAG_DROP_ASSET_ITEM_DETAILS, props.itemID);
  const { isAttach, onAttachAsset, onDropFile, uploadingAssets } = useItemUploadAttachAssets(
    item?.id || '',
    analyticsEvent,
    { onCompleted: props.onItemMutated }
  );

  const queriedAssetItemID = canViewItemAttachments ? props.itemID : undefined;
  const { itemAssets, loading } = useItemAttachments(queriedAssetItemID);

  useOptimisticItemUploadAssets(queriedAssetItemID || '', uploadingAssets, isAttach);
  useOptimisticProjectUploadAssets(props.projectID, uploadingAssets);

  return (
    <AssetsWrapper
      assets={itemAssets}
      assetsLoading={loading}
      hasAssetAddPermission={canAddItemAttachments}
      hasAssetDeletePermission={canDeleteItemAttachments}
      itemID={props.itemID}
      onAttachAsset={onAttachAsset}
      onDropFile={onDropFile}
      onItemMutated={props.onItemMutated}
      projectID={props.projectID}
    />
  );
};

export default AssetsWrapperItemData;
