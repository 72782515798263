import { useParams } from 'react-router-dom';

import { Print } from '@material-ui/icons';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Export } from '../../Icons/Export';
import { IconMenuButton, MenuEntry } from '../../scales';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from '../hooks/useSendScenariosAnalytics';

type Props = {
  milestoneID: UUID;
};

const SandboxExportPanel = ({ milestoneID }: Props) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const sendScenariosAnalytics = useSendScenariosAnalytics();

  const iconMenuOptions: MenuEntry[] = [
    {
      id: 'print-scenarios-presentation',
      label: 'Print Scenarios Presentation',
      onClick: () => {
        sendScenariosAnalytics(ScenariosAnalyticsEvents.PRINT_SANDBOX_EXPORT_PANEL, {
          milestoneID,
        });
        window.open(generateSharedPath(RouteKeys.PRINT_PROJECT_SCENARIOS, { projectId }), '_blank');
      },
      startAdornment: <Print />,
    },
  ];

  return (
    <div>
      <IconMenuButton
        aria-label="Open export options"
        data-cy="scenarios-export-menu-button"
        entries={iconMenuOptions}
        icon={<Export />}
        type="secondary"
      />
    </div>
  );
};

export default SandboxExportPanel;
