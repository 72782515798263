import { FC } from 'react';

import { GetApp, Print } from '@material-ui/icons';

import { Export } from '../../Icons/Export';
import { IconMenuButton, MenuEntry } from '../../scales';

type VarianceReportExportPanelProps = {
  exportCostReport: () => void;
  printOnClick: () => void;
};

const VarianceReportExportPanel: FC<VarianceReportExportPanelProps> = ({
  exportCostReport,
  printOnClick,
}) => {
  const options: MenuEntry[] = [
    {
      id: 'print',
      onClick: () => printOnClick(),
      startAdornment: <Print />,
      label: 'Print',
    },
    {
      id: 'download-xlsx',
      onClick: () => exportCostReport(),
      startAdornment: <GetApp />,
      label: 'Download .xlsx',
    },
  ];

  return (
    <IconMenuButton
      aria-label="Variance report export menu"
      entries={options}
      icon={<Export />}
      type="secondary"
    />
  );
};

export default VarianceReportExportPanel;
