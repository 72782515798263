import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { Icon, Select, SelectEntry } from '../../scales';

type Props = {
  onChange: (milestone: UUID) => void;
  projectID: UUID;
  value?: UUID;
};

export default function MilestoneSelect(props: Props) {
  const activeMilestoneID = useProjectPropsQuery(props.projectID).data.project?.activeMilestone.id;
  const milestones = useMilestonesQuery(props.projectID, true).data?.milestones ?? [];

  const entries: SelectEntry[] = milestones.map((milestone) => {
    const isActiveMilestone = milestone.id === activeMilestoneID;
    return {
      id: milestone.id,
      label: milestone.name,
      startAdornment: activeMilestoneID ? (
        <Icon
          className="text-entities-milestone"
          name="flag"
          type={isActiveMilestone ? 'filled' : 'outlined'}
        />
      ) : undefined,
    };
  });
  return <Select entries={entries} onChange={props.onChange} value={props.value} />;
}
