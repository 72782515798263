import { useState } from 'react';

import { MoreVert, OpenInNew } from '@material-ui/icons';

import { analyticsEvent } from '../../../../analytics/analyticsEventProperties';
import {
  AutodeskPotentialChangeOrder,
  ProcoreChangeEvent,
  SourceSystem,
  SourceType,
} from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import useUnlinkItemIntegrationObjectMutation from '../../../../hooks/useUnlinkItemIntegrationObjectMutation';
import { formatCost } from '../../../../utilities/currency';
import { useProjectID } from '../../../../utilities/routes/params';
import { capitalizeString } from '../../../../utilities/string';
import { Chip, IconMenuButton } from '../../../scales';

import {
  getItemIntegrationObjectAnalyticsTypeBySourceSystem,
  getItemIntegrationObjectLink,
  getItemIntegrationObjectTypeBySourceSystem,
} from './IntegrationsUtils';
import ItemIntegrationObjectLastUpdated from './ItemIntegrationObjectLastUpdated';
import UnlinkItemIntegrationObjectDialog from './UnlinkItemIntegrationObjectDialog';

type Props = {
  canDeleteItemIntegration: boolean;
  itemIntegrationObject: Omit<ProcoreChangeEvent, 'id'> | Omit<AutodeskPotentialChangeOrder, 'id'>;
  itemID: UUID;
  linkedProjectID?: string;
  pageLoadTime?: Date;
  refetchChangeEvents: () => void;
  setSubmitted: (value: boolean) => void;
  sourceID: UUID;
  sourceSystem: SourceSystem;
  procoreZone?: string;
};

export default function ItemIntegrationObjectContent(props: Props) {
  const projectID = useProjectID();

  const sendAnalytics = useSendAnalytics();
  const itemIntegrationObjectAnalytics = getItemIntegrationObjectAnalyticsTypeBySourceSystem(
    props.sourceSystem
  );
  const onCompleted = () => {
    sendAnalytics(analyticsEvent(itemIntegrationObjectAnalytics.unlink));
    props.setSubmitted(false);
  };

  const [unlinkItemIntegrationObject] = useUnlinkItemIntegrationObjectMutation({ onCompleted });

  const [isOpen, setIsOpen] = useState(false);

  const numberString =
    props.itemIntegrationObject.number > 0 ? `${props.itemIntegrationObject.number} - ` : '';

  const changeEventName = `${numberString} ${props.itemIntegrationObject.title}${
    'isDeleted' in props.itemIntegrationObject && props.itemIntegrationObject?.isDeleted
      ? ' (Deleted)'
      : ''
  }`;

  const url = getItemIntegrationObjectLink(
    props.sourceSystem,
    props.itemIntegrationObject.integrationObjectID,
    props.linkedProjectID,
    props.procoreZone
  );

  const header = (
    <div className="flex justify-center gap-1 pb-1">
      <div className="flex items-center justify-center text-type-muted type-body3">
        {getItemIntegrationObjectTypeBySourceSystem(props.sourceSystem)}
      </div>
    </div>
  );

  const title = (
    <div className="flex w-full">
      <a
        className="ml-auto flex flex-grow type-link"
        href={url}
        onClick={() => sendAnalytics(analyticsEvent(itemIntegrationObjectAnalytics.create))}
        rel="noreferrer"
        target="_blank"
      >
        {changeEventName}
        <div className="text-base type-link">
          <OpenInNew className="ml-1" fontSize="inherit" />
        </div>
      </a>
    </div>
  );

  const chips = (
    <div className="flex gap-1">
      {props.itemIntegrationObject.cost && (
        <Chip text={formatCost(props.itemIntegrationObject.cost)} />
      )}
      {'status' in props.itemIntegrationObject && props.itemIntegrationObject.status && (
        <Chip text={capitalizeString(props.itemIntegrationObject.status)} />
      )}
      {props.itemIntegrationObject.eventType && (
        <Chip text={`Type: ${props.itemIntegrationObject.eventType}`} />
      )}
      {'changeReason' in props.itemIntegrationObject && (
        <Chip text={`Change Reason: ${props.itemIntegrationObject.changeReason}`} />
      )}
      <ItemIntegrationObjectLastUpdated
        onClick={props.refetchChangeEvents}
        pageLoadTime={props.pageLoadTime}
        sourceSystem={props.sourceSystem}
      />
    </div>
  );

  return (
    <div className="flex w-full flex-col items-start justify-center p-2">
      <UnlinkItemIntegrationObjectDialog
        isOpen={isOpen}
        name={changeEventName}
        onClose={() => {
          unlinkItemIntegrationObject(
            projectID || '',
            props.itemID,
            props.sourceSystem,
            SourceType.CHANGE_EVENT,
            props.sourceID,
            {
              number: props.itemIntegrationObject.number,
              title: props.itemIntegrationObject.title,
            }
          );
          props.setSubmitted(true);
        }}
        setIsOpen={setIsOpen}
        sourceSystem={props.sourceSystem}
      />

      {header}
      <div className="flex w-full items-start">
        <div className="flex w-full flex-col items-start justify-center">
          {title}

          <div className="mb-2 mt-2 line-clamp-3 w-full self-center whitespace-pre-line type-body3">
            {props.itemIntegrationObject.description}
          </div>
          {chips}
        </div>
        {props.canDeleteItemIntegration && (
          <IconMenuButton
            aria-label="Edit"
            entries={[{ label: 'Unlink from Item', id: 'unlink', onClick: () => setIsOpen(true) }]}
            icon={<MoreVert />}
            type="secondary"
          />
        )}
      </div>
    </div>
  );
}
