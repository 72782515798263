import { FC, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

import {
  IMPORT_ESTIMATE_DEFAULT,
  getImportEstimateVar,
  setReactiveLocal,
} from '../../../api/apollo/reactiveVars';
import { EstimateType } from '../../../api/gqlEnums';
import { IMPORT_ESTIMATE_ACTIVE, IMPORT_ESTIMATE_BUDGET } from '../../../constants';
import { GetDetailedMilestonesQuery } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Button, IconButton, Tooltip } from '../../scales';
import EstimateImportLink from '../EstimateImportLink';
import { Status } from '../types';
import { isDraftEstimate, isImportInProgress } from '../utils';

import styles from './EstimateUploadContainerStyles';

type Asset = GetDetailedMilestonesQuery['milestones'][number]['importedEstimates'][number];

type EstimateUploadContainerProps = {
  classes: Classes<typeof styles>;
  estimateType: EstimateType;
  fileStatus: Status;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideCurrentUpload: boolean;
  lastUploadedEstimate?: Asset;
};

const EstimateUploadContainer: FC<EstimateUploadContainerProps> = ({
  classes,
  estimateType,
  fileStatus,
  hideCurrentUpload,
  lastUploadedEstimate,
}) => {
  const importEstimateVar = getImportEstimateVar(estimateType);
  const importEstimateKey =
    estimateType === EstimateType.ACTIVE_ESTIMATE ? IMPORT_ESTIMATE_ACTIVE : IMPORT_ESTIMATE_BUDGET;
  const importingEstimate = useReactiveVar(importEstimateVar);
  const isImportProgress = !!importingEstimate.id;

  const { isUploading, file: { name } = { name: null }, estimateId, error }: Status = fileStatus;

  const importText = useMemo(() => {
    let nameText = '';
    if (name && estimateId && !hideCurrentUpload) {
      nameText = `View Draft: ${name}`;
    } else if (!name && estimateId && !hideCurrentUpload) {
      nameText = 'View Draft';
    } else if (error) {
      nameText = `Import Error. Please try again.`;
    } else if (lastUploadedEstimate && isImportProgress) {
      nameText = `Your import progress: ${lastUploadedEstimate.name}`;
    } else {
      nameText = '';
    }
    return isUploading ? `Uploading: ${name}; this may take a few minutes.` : nameText;
  }, [
    isUploading,
    name,
    estimateId,
    error,
    hideCurrentUpload,
    lastUploadedEstimate,
    isImportProgress,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const continueImport = (event: any) => {
    if (event.preventDefault) event.preventDefault();
    if (event.stopPropagation) event.stopPropagation();
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importingEstimate,
      modalIsOpen: true,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const closeProgress = (event: any) => {
    if (event.preventDefault) event.preventDefault();
    if (event.stopPropagation) event.stopPropagation();
    setReactiveLocal(importEstimateVar, importEstimateKey, IMPORT_ESTIMATE_DEFAULT);
  };

  if (isImportInProgress(isUploading, error, !!lastUploadedEstimate, isImportProgress)) {
    return (
      <div className="flex gap-2">
        <Button
          data-cy="continue-import-progress-button"
          label={importText}
          onClick={continueImport}
          startIcon={<CachedIcon />}
          type="warning"
        />
        <Tooltip content="Cancel import progress">
          <IconButton
            aria-label="cancel import progress"
            data-cy="cancel-import-progress-button"
            icon={<CloseIcon />}
            onClick={closeProgress}
            type="secondary"
          />
        </Tooltip>
      </div>
    );
  }
  if (isDraftEstimate(isUploading, error, estimateId, hideCurrentUpload))
    return (
      <EstimateImportLink estimateId={estimateId} estimateType={estimateType}>
        <NewReleasesIcon classes={{ root: classes.statusIcon }} />
        <Typography className={classes.text} variant="body1">
          {importText}
        </Typography>
      </EstimateImportLink>
    );
  return null;
};

export default withStyles(styles)(EstimateUploadContainer);
