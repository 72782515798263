import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { ITEMS_GROUP_BY, ITEMS_SORT_BY } from '../../actions/actionTypes';
import { analyticsEvent, reportItemsList } from '../../analytics/analyticsEventProperties';
import { SORT_NUMBER, UNGROUPED } from '../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { returnGroupByOptions } from '../../utilities/grouping';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { SetSettingsFunctionType } from '../../utilities/urlState';
import ItemsListGroupBySelect from '../Items/ItemsListGroupBySelect/ItemsListGroupBySelect';
import { isScheduleSettingsDisabled } from '../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import SearchBar from '../Search/SearchBar/SearchBar';
import JoinSelect from '../Select/JoinSelect/JoinSelect';
import { MultiGroupOrderOption } from '../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';
import useMemoWrapper from '../useMemoWrapper';

import { computeGroupByOptions, computeSortBy } from './ItemsListSortBarUtils';
import { parseFilterText } from './ItemsListUtils';

type ItemsListSortBarProps = {
  milestone?: Milestone;
  setSettings: SetSettingsFunctionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
};

const ItemsListSortBar: FC<ItemsListSortBarProps> = ({
  milestone = null,
  setSettings,
  settings,
}) => {
  const location = useLocation();
  const sendAnalytics = useSendAnalytics();
  const { filterText: filterTextOuter, groupBy, sort } = settings;

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  useEffect(() => {
    const notItemsList = !window.location.pathname.includes('/items');
    if (notItemsList) {
      setSettings(parseFilterText(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [location]);

  // we should be able to extract all the search info and setting from one function and one setting
  // FUNCTIONS
  const updateSortBy = (sort: string) => {
    sendAnalytics(reportItemsList(sort, 'sort', settings));
    sendAnalytics(analyticsEvent(ITEMS_SORT_BY)); // the previous analytics is triggered in a lot of places.  This is just for changing sort bys
    setSettings({ sort });
  };

  const setGroupBy = (groupBys: MultiGroupOrderOption[]) => {
    const groupBy = groupBys.flatMap((g) => (g.name ? [g.name] : []));
    sendAnalytics(reportItemsList(groupBy, 'group-set', settings));
    sendAnalytics(analyticsEvent(ITEMS_GROUP_BY)); // the previous analytics is triggered in a lot of places.  This is just for changing group bys
    setSettings({ groupBy, expand: [], collapse: [UNGROUPED] });
  };

  const [filterText, setFilterText] = useState(filterTextOuter);
  useDebounce(() => updateSearchTerms(filterText), 500, [filterText]);

  const updateSearchTerms = (text: string) => {
    if (text.length === 0) {
      sendAnalytics(reportItemsList('clear', 'filterText', settings));
      setSettings(parseFilterText(''));
    } else {
      sendAnalytics(reportItemsList(text, 'filterText', settings));
      setSettings(parseFilterText(text));
    }
  };

  // groupBy options are single or multiple level. We use names or levelnames as identifiers.
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const groupByOptions = useMemoWrapper(
    computeGroupByOptions,
    categorizations,
    isScheduleImpactEnabled,
    milestone?.id
  );

  // CONSTANTS
  const displayGroupBy = returnGroupByOptions(groupBy, groupByOptions);
  const sortByData = useMemoWrapper(
    computeSortBy,
    categorizations,
    isScheduleImpactEnabled,
    shouldDisplayCosts
  );

  return (
    <div className="flex gap-1 border-x bg-background-primary px-4">
      <div className="flex-grow gap-2">
        <div className="type-label">Search</div>
        <SearchBar
          onChange={(evt) => {
            setFilterText(evt.target.value);
          }}
          onClear={() => setFilterText('')}
          placeholder="Search items, assignee: name, or due: 1/1 to 11/14"
          searchTerm={filterText}
        />
      </div>
      <div className="print:hidden">
        <ItemsListGroupBySelect
          handleClose={setGroupBy}
          options={groupByOptions}
          selected={displayGroupBy}
        />
      </div>
      <div className="gap-[2px] print:hidden">
        <div className="type-label">Sort By</div>
        <JoinSelect
          cySelect="itemsListSortSelect"
          entries={sortByData}
          onChange={(key) => {
            updateSortBy(key || SORT_NUMBER);
          }}
          value={sort || SORT_NUMBER}
        />
      </div>
    </div>
  );
};

export default ItemsListSortBar;
