import { ErrorOutline } from '@material-ui/icons';

import { InsightsActiveAlert } from '../../../generated/graphql';
import { getUniqueAlertDescriptionCounts } from '../utils';

type Props = {
  triggeredAlertsByProject: InsightsActiveAlert[];
};

const ProjectTableAlertTooltip = (props: Props) => {
  const uniqueAlerts = getUniqueAlertDescriptionCounts(props.triggeredAlertsByProject);

  return (
    <div className="flex w-75 flex-col gap-1 p-1">
      <strong className="type-body2">Alerts</strong>
      <div className="flex flex-col gap-1">
        {/* Display the unique alerts with counts */}
        {uniqueAlerts.map((alert) => (
          <div key={alert.description} className="flex items-center gap-1">
            <ErrorOutline color="error" style={{ width: 20, height: 20 }} />
            <div className="text-type-error type-table-text">{alert.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectTableAlertTooltip;
