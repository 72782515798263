import {
  CostDisplay,
  CostReportColumnType,
  ItemsTimelineWithEventsQuery,
  ItemsTimelineWithEventsQueryVariables,
  MarkupMode,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';

import { itemsTimelineWithEventsQuery } from './queries';

const dashboardColumnTypes: CostReportColumnType[] = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.REMAINING_REPORT,
];
export const columnInputs: CostReportColumnInput[] = dashboardColumnTypes.map((type) => ({
  type,
}));

export const useItemsTimelineWithEventsQuery = (
  milestoneID: UUID | null,
  projectID: UUID | undefined,
  skipQuery = false
) =>
  useQuery<ItemsTimelineWithEventsQuery, ItemsTimelineWithEventsQueryVariables>(
    itemsTimelineWithEventsQuery,
    {
      variables: {
        // # events
        projectID,
        viewFilter: {},
        // Set cost mode temporarily, can be removed in the future. There is no cost data in this request.
        costMode: {
          markupMode: MarkupMode.ALLOCATED_MARKUPS,
          costDisplay: CostDisplay.SHOW_COSTS,
        },
        milestoneID,
        columnInputs,
        quantityMagnitude: undefined,
        // # items
        iMilestoneID: null,
        iProjectID: projectID,
        // Set cost mode temporarily, can be removed in the future. There is no cost data in this request.
        iCostMode: {
          markupMode: MarkupMode.ALLOCATED_MARKUPS,
          costDisplay: CostDisplay.SHOW_COSTS,
        },
      },
      skip: !milestoneID || !projectID || skipQuery,
    },
    MountPolicy.FETCH_ON_VARS
  );
