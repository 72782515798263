import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      [theme.breakpoints.up('md')]: {
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingLeft: '12px',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
      minWidth: 112,
      '@media print': {
        display: 'none',
      },
    },
    applyChangesButtonContainer: {
      float: 'right',
      marginTop: 8,
      paddingRight: 8,
    },
    fullWidth: {
      width: '100%',
    },
    paper: {
      background: theme.palette.backgroundWhite,
      paddingBottom: 8,
      position: 'absolute',
      width: 284,
    },
    scroll: {
      height: 380,
      overflowY: 'scroll',
    },
  });
