import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { MilestoneDetailsTabs } from '../../Milestone/utils';

import { styles } from './ContingencyReportStyles';

type AllMilestoneContingencyReportEmptyStateProps = {
  classes: Classes<typeof styles>;
  projectID: string;
  milestoneID: string;
  clearFilters: () => void;
  hasNoSelectedContingenciesOrAllowances: boolean;
};

const AllMilestoneContingencyReportEmptyState: FC<AllMilestoneContingencyReportEmptyStateProps> = ({
  classes,
  projectID,
  milestoneID,
  clearFilters,
  hasNoSelectedContingenciesOrAllowances,
}) => {
  const noContingenciesSelectedText = (
    <Typography className={classes.text}>
      No data matching your filters <br />{' '}
      <button className={classes.link} onClick={clearFilters}>
        Clear Filters
      </button>
    </Typography>
  );

  const milestoneDetailsLink = generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
    projectId: projectID,
    milestoneId: milestoneID,
  });

  const noContingenciesExistText = (
    <Typography className={classes.text}>
      No Contingencies or Allowances to show, <br /> create them in your Active Milestone Estimate{' '}
      <Link
        className={classes.link}
        data-cy="milestone-estimate"
        tabIndex={-1}
        to={`${milestoneDetailsLink}?view=${MilestoneDetailsTabs.ESTIMATE}`}
      >
        here
      </Link>
    </Typography>
  );

  return (
    <div className={classes.emptyStateParent}>
      <div className={classes.emptyRowInfoContainer} />
      <div className={classes.emptyState}>
        {hasNoSelectedContingenciesOrAllowances
          ? noContingenciesSelectedText
          : noContingenciesExistText}
      </div>
    </div>
  );
};

export default withStyles(styles)(AllMilestoneContingencyReportEmptyState);
