import { DD_BENCHMARKING } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { Button } from '../../scales';

export default function CompanyOrganizationsPlaceholder(props: {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  onAdd: () => void;
}) {
  const hasBenchmarkingFeature = useHasFeature(DD_BENCHMARKING);
  return (
    <div className="flex h-full w-full flex-col gap-4 p-4">
      <div className="flex pt-4">
        Add your organizational structure -- regions, offices, business units, or all of the above!
      </div>
      <div className="flex">
        {`Employees will be able to add this information when they start new projects and use it as a
        filter in the Projects list as well as ${hasBenchmarkingFeature ? 'Benchmarking' : 'Forecasting'} and Insights.`}
      </div>
      <div className="flex justify-center p-4">
        <img
          alt="No scenarios add new static"
          src="/img/Company_Settings/Organizations_Placeholder.gif"
          width={800}
        />
      </div>
      <div className="flex justify-center">
        <Button
          isDisabled={props.disabled}
          label="Add an organization"
          onClick={props.onAdd}
          type="primary"
        />
      </div>
    </div>
  );
}
