import { Button, Dialog, DialogContent, Icon } from '../../scales';

type props = {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

export default function DeleteDialog(props: props) {
  return (
    <Dialog
      footerRight={
        <div className="flex gap-3">
          <Button label="Cancel" onClick={props.onCancel} type="secondary" />
          <Button
            label="Delete"
            onClick={props.onDelete}
            startIcon={<Icon name="delete" />}
            type="destructive"
          />
        </div>
      }
      isOpen={props.isOpen}
      onClose={props.onCancel}
      title="Delete Scheduled Distribution"
    >
      <DialogContent>
        <div className="p-6">
          Are you sure you want to delete the Scheduled Distribution from this report?
        </div>
      </DialogContent>
    </Dialog>
  );
}
