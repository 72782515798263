import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { setItemEstimateExpanded } from '../../../analytics/analyticsEventProperties';
import { ESTIMATE } from '../../../constants';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useCostMode } from '../../../utilities/costMode';
import { getContingencyEstimateCosts } from '../../../utilities/items';
import { PermissionFunc } from '../../../utilities/permissions/types';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { getItemIdFromUrl } from '../../../utilities/url';
import { LIST } from '../../ItemsList/ItemsListViewToggle';
import useMemoWrapper from '../../useMemoWrapper';
import { useItemQuery } from '../hooks/useItemQuery';
import ItemsButtonDetachOption from '../ItemsListItem/ItemsListItemCta/ItemsButtonDetachOption';
import ItemsOptionsList from '../ItemsOptionsList/ItemsOptionsList';

import EstimateContentHeader from './EstimateContentHeader';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ItemsCollapseStyles, { SCROLL_MARGIN } from './ItemsCollapseStyles';

type Props = {
  canAdd: PermissionFunc;
  canEdit: PermissionFunc;
  canView: PermissionFunc;
  classes: Classes<typeof ItemsCollapseStyles>;
  isExpanded: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  inPreviewMode: boolean;
  projectID: UUID;
  setOpenDialogAddOption: (option: boolean) => void;
  setIsExpanded: (isExpanded: boolean) => void;
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
};

const OptionCollapse: FC<Props> = ({
  canAdd,
  canEdit,
  canView,
  classes,
  isExpanded,
  inPreviewMode,
  projectID,
  setIsExpanded,
  setOpenDialogAddOption,
  sharedUsersMap,
}) => {
  const costMode = useCostMode();
  const [previousCostMode, setPreviousCostMode] = useState(costMode);

  const itemID = getItemIdFromUrl();
  const sendAnalytics = useSendAnalytics();
  const { data, refetch } = useItemQuery(itemID, MountPolicy.SKIP_ON_MOUNT);
  const item = data?.item || undefined;

  if (
    previousCostMode.markupMode !== costMode.markupMode ||
    previousCostMode.costDisplay !== costMode.costDisplay
  ) {
    refetch({ id: itemID, costMode });
    setPreviousCostMode(costMode);
  }

  // PERMISSIONS
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  // Options
  const options: DetailedOption[] = useMemo(() => {
    if (item && 'options' in item) return item.options.filter((o) => !!o);
    return [];
  }, [item]);

  // Costs
  const estimateCost: Cost = useMemoWrapper(getContingencyEstimateCosts, item);

  // HELPER FUNCTIONS
  const activeEstimate = useMemo(() => (item && item.activeEstimate) || null, [item]); // Is this useMemo necessary?

  const scrollRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const id = window.location.hash.split('?')[0].slice(1);
    if (id === ESTIMATE || id === 'schedule-impact') {
      setIsExpanded(true);
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  if (!item) return null;

  return (
    <div ref={scrollRef} className={classes.card}>
      <ItemDetailsCollapse
        analyticsEvent={setItemEstimateExpanded(!isExpanded)} // update?
        canExpand={options?.length > 0}
        hasGrayBackground
        headerContent={
          <EstimateContentHeader
            canViewContent
            estimateCost={estimateCost}
            hasActiveEstimate={!!activeEstimate}
            hasOptions
            isReadOnly={false}
            itemID={itemID}
            options={options}
            projectID={projectID}
            scheduleImpact={undefined}
            sendAnalytics={sendAnalytics}
            setOpenDialogAddOption={setOpenDialogAddOption}
            shouldDisplayCosts={shouldDisplayCosts}
          />
        }
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        style={{ scrollMarginTop: SCROLL_MARGIN }}
      >
        <ItemsOptionsList
          canAdd={canAdd}
          canEdit={canEdit}
          canView={canView}
          getCtaButton={(option) => <ItemsButtonDetachOption option={option} />}
          inPreviewMode={inPreviewMode}
          isNewItemsList={false}
          item={item}
          itemsLikeSelected={[]}
          options={options}
          projectId={projectID}
          sharedUsersMap={sharedUsersMap}
          showCheckbox={false}
          view={LIST}
        />
      </ItemDetailsCollapse>
    </div>
  );
};

export default withStyles(ItemsCollapseStyles)(OptionCollapse);
