import { ReactNode, forwardRef } from 'react';

import { getTextColor } from '../../../utilities/colors';
import { TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';

import CloseButton from './CloseButton';

type Props = TooltipTriggerProps & {
  backgroundColor?: string;
  borderColor?: string;
  'data-cy'?: string;
  icon?: ReactNode;
  onRemove?: () => void;
  text: ReactNode;
  textColor?: string;
};

export default forwardRef<HTMLDivElement, Props>(function Chip(props, forwardedRef) {
  let bgClassName: string | undefined = 'bg-background-2';
  let textClassName: string | undefined = 'text-type-primary';
  if (props.backgroundColor) {
    bgClassName = undefined;
    textClassName = getTextColor(props.backgroundColor);
  }
  if (props.textColor) {
    textClassName = undefined;
  }

  const Icon = props.icon;

  return (
    <div
      {...pickTooltipTriggerProps(props)}
      ref={forwardedRef}
      className={[
        'flex h-6 w-min max-w-full shrink-0 items-center gap-0.5 truncate whitespace-nowrap rounded px-1.5 type-label',
        bgClassName,
        textClassName,
      ].join(' ')}
      data-cy={props['data-cy']}
      style={{
        backgroundColor: props.backgroundColor,
        borderColor: props.borderColor,
        border: props.borderColor ? '1px solid' : 'none',
        color: props.textColor,
      }}
    >
      {Icon && (
        /*
          We're going to override the size of the icon that's been passed in.
          Currently, the icons passed in are almost always guaranteed to be MUI icons which
          set their own size via the font-size property. This allows us to override it.
          
          We could've also taken the icon component type as the prop and rendered it with a
          `fontSize="inherit"` prop but that'll require too many changes from users of this
          component if we ever start supporting non-MUI icons.
          */
        <div className="flex items-center [&_*]:!icon-sm">{Icon}</div>
      )}
      <div className="truncate">{props.text}</div>
      {props.onRemove && (
        <CloseButton
          backgroundColor={props.backgroundColor}
          data-cy={`remove-${props['data-cy']}`}
          onPress={props.onRemove}
        />
      )}
    </div>
  );
});
