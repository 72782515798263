import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import useLocalStorage from '../../../hooks/useLocalStorage';

import { IdeaEventType, useSendIdeaAnalytics } from './hooks/analytics';
import useCreateItemFromAlternateMutation from './hooks/useCreateItemFromAlternateMutation';
import useCreateItemFromIdeaMutation from './hooks/useCreateItemFromIdeaMutation';
import useIdeaQuery from './hooks/useIdeaQuery';
import Idea from './Idea';

export default function IdeaRoute() {
  const { projectId, ideaID } = useParams();
  if (!projectId || !isUUID(projectId)) {
    throw new Error('Failed to get projectId param');
  } else if (!ideaID || !isUUID(ideaID)) {
    throw new Error('Failed to get ideaID param');
  }

  const idea = useIdeaQuery(projectId, ideaID).data?.idea;
  const [hiddenAlternatesByIdea, setHiddenAlternatesByIdea] = useLocalStorage<Record<UUID, UUID[]>>(
    `IDEA_TO_HIDDEN_ALTERNATES`,
    {}
  );
  const hiddenAlternates = hiddenAlternatesByIdea[ideaID] ?? [];
  const hasHiddenAlternates = hiddenAlternates.length > 0;
  const [starredIdeas, setStarredIdeas] = useLocalStorage<UUID[]>('STARRED_IDEAS', []);
  const isStarred = starredIdeas.includes(ideaID);

  const [createItemFromAlternate] = useCreateItemFromAlternateMutation(ideaID);
  const [createItemFromIdea] = useCreateItemFromIdeaMutation(ideaID, hiddenAlternates);

  const sendIdeaAnalytics = useSendIdeaAnalytics({ id: ideaID, name: idea?.name });

  if (!idea) return null;
  return (
    <Idea
      canResetHiddenAlternates={hasHiddenAlternates}
      idea={{
        ...idea,
        alternates: idea.alternates.filter(({ id }) => !hiddenAlternates.includes(id)),
      }}
      isStarred={isStarred}
      onCreateItemFromAlternate={(alternateID) => {
        sendIdeaAnalytics(IdeaEventType.CREATE_ITEM_FROM_ALTERNATE, {
          alternateID,
        });
        createItemFromAlternate(alternateID);
      }}
      onCreateItemFromIdea={() => {
        sendIdeaAnalytics(IdeaEventType.CREATE_ITEM_FROM_IDEA);
        createItemFromIdea();
      }}
      onHideAlternate={(alternateID) => {
        sendIdeaAnalytics(IdeaEventType.HIDE_ALTERNATE, { alternateID });
        setHiddenAlternatesByIdea({
          ...hiddenAlternatesByIdea,
          [ideaID]: [...hiddenAlternates, alternateID],
        });
      }}
      onResetHiddenAlternates={() => {
        sendIdeaAnalytics(IdeaEventType.RESET_HIDDEN_ALTERNATES);
        setHiddenAlternatesByIdea({ ...hiddenAlternatesByIdea, [ideaID]: [] });
      }}
      onStar={(isStarred) => {
        sendIdeaAnalytics(IdeaEventType.STAR_IDEA, { isStarred });
        setStarredIdeas(
          isStarred
            ? [...starredIdeas.filter((id) => id !== ideaID), ideaID]
            : starredIdeas.filter((id) => id !== ideaID)
        );
      }}
      projectID={projectId}
    />
  );
}
