import { FC } from 'react';

import { UPLOAD_ATTACH_ITEM_ASSET } from '../../../../actions/actionTypes';
import { getItemAssetAnalytics } from '../../../../analytics/analyticsEventProperties';
import { UserSource } from '../../../../generated/graphql';
import { useItemUploadAttachAssets } from '../../../assets/hooks/AssetsMutationHook';
import useOptimisticProjectUploadAssets from '../../../assets/hooks/useOptimisticProjectUploadAssets';

import FilesDialogSidebar from './FilesDialogSidebar';

type FilesDialogSidebarItemWrapperProps = {
  itemID: UUID;
  userSources?: UserSource[];
  onItemMutated?: () => void;
  projectID: UUID;
};

const FilesDialogSidebarItemWrapper: FC<FilesDialogSidebarItemWrapperProps> = (props) => {
  const analyticsEvent = getItemAssetAnalytics(UPLOAD_ATTACH_ITEM_ASSET, props.itemID);
  const { onDropFile, uploadingAssets } = useItemUploadAttachAssets(props.itemID, analyticsEvent, {
    onCompleted: props.onItemMutated,
  });

  useOptimisticProjectUploadAssets(props.projectID, uploadingAssets);

  return <FilesDialogSidebar onDropFile={onDropFile} userSources={props.userSources} />;
};

export default FilesDialogSidebarItemWrapper;
