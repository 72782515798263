import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ITEM_WITH_OPTIONS } from '../../../constants';
import useItemsIntegrationObjectsCountsQuery, {
  getIntegrationObjectCountForItemID,
} from '../../../hooks/useItemsIntegrationObjectsCountsQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import CommentsHistoryPrint from '../../Comments/CommentsHistory/CommentsHistoryPrint';
import { usePrintItemHistory } from '../../Events/EventsItemHistoryList/EvenItemsHistoryListUtils';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { PrintItemDetailsBody } from '../PrintItemDetails/PrintItemDetailsBody';
import PrintItemDetailsEstimate from '../PrintItemDetails/PrintItemDetailsEstimate';
import PrintItemDetailsScheduleImpact from '../PrintItemDetails/PrintItemDetailsScheduleImpact';
import { PrintItemDetailsSubheader } from '../PrintItemDetails/PrintItemDetailsSubheader';
import { useTriggerOnHeaderHisoricalComplete } from '../PrintItemDetails/PrintItemDetailsUtils';
import PrintItemDetailsIntegrations from '../PrintItemDetails/PrintItemIntegrations';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';

type PrintItemDetailsListProps = {
  costMode: CostMode;
  items: ItemDataQueryItem[];
  permissions: ReturnType<typeof usePermissions>;
  triggerOnComplete?: () => void;
};

const PrintItemDetailsList: FC<PrintItemDetailsListProps> = ({
  costMode,
  items,
  triggerOnComplete,
  permissions,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const { data: { milestones = [] } = {} } = useMilestonesQuery(projectId, false);
  const withHistory = usePrintItemHistory();
  const { setHeaderComplete, setHistoryComplete, setIntegrationsComplete } =
    useTriggerOnHeaderHisoricalComplete(triggerOnComplete, withHistory);
  const [historyCount, setHistoryCount] = useState(0);
  const incrementHistoryCount = () => {
    const newCount = historyCount + 1;
    setHistoryCount(newCount);
    if (newCount === items.length) {
      setHistoryComplete(true);
    }
  };

  const pageHeader = (itemMilestone: string) => (
    <PrintPageHeaderWrapper
      milestoneName={itemMilestone}
      projectId={projectId}
      reportName="Item Details"
      triggerOnComplete={() => setHeaderComplete(true)}
    />
  );

  const itemIDs = items.map((item) => item.id);
  const itemsIntegrationObjectsCounts = useItemsIntegrationObjectsCountsQuery({ itemIDs });

  return (
    <>
      {items.map((item) => {
        const itemMilestoneId = item && item.milestone && item.milestone.id;
        const itemMilestone = milestones.find((m: Milestone) => m.id === itemMilestoneId);
        const headerText = (itemMilestone && itemMilestone.name) || '';
        return (
          <div key={item.id} className="print-item-detail-page">
            {pageHeader(headerText)}
            <PrintItemDetailsSubheader item={item} shouldDisplayCosts={shouldDisplayCosts} />
            <PrintItemDetailsBody costMode={costMode} itemLike={item} permissions={permissions} />
            {item.activeEstimate && (
              <PrintItemDetailsEstimate
                costMode={costMode}
                estimate={item.activeEstimate}
                item={item}
                permissions={permissions}
              />
            )}
            {isScheduleImpactEnabled && item.itemType !== ITEM_WITH_OPTIONS && (
              <PrintItemDetailsScheduleImpact item={item} permissions={permissions} />
            )}
            <PrintItemDetailsIntegrations
              item={item}
              itemIntegrationObjectsCount={getIntegrationObjectCountForItemID(
                item.id,
                itemsIntegrationObjectsCounts.data
              )}
              permissions={permissions}
              triggerOnComplete={() => setIntegrationsComplete(true)}
            />
            {withHistory && (
              <CommentsHistoryPrint
                costMode={costMode}
                item={item}
                permissions={permissions}
                triggerOnComplete={incrementHistoryCount}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default PrintItemDetailsList;
