import { PieChartData } from './ChartsPieGraphUtils';

export const getColor = (colors: string[], data: PieChartData, index: number) => {
  if (data.name.includes('Other ')) {
    return chartColorOther;
  }
  if (data.name === 'Unassigned') {
    return chartColorUnassigned;
  }
  if (data.name.startsWith('No ')) {
    return chartColorUnassigned;
  }
  return colors[index];
};

export const chartColorOther = 'var(--colors-button-primary-hover)';
export const chartColorUnassigned = 'var(--colors-item-status-not-chosen-tint)';
