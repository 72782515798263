import { NS_PROGRAM_REPORTING } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { formatNumber } from '../../../utilities/string';
import { Radio, RadioGroup } from '../../scales';

type Props = {
  onChange: (value: string) => void;
  value: string;
  options: { label: string; count: number; value: string }[];
};

export default function SearchToggleRadioGroup(props: Props) {
  const hasProgramReporting = useHasFeature(NS_PROGRAM_REPORTING);

  if (!hasProgramReporting) return null;

  return (
    <div className="flex gap-3 rounded-2xl bg-background-2 p-3">
      <RadioGroup isFullWidth onChange={(value) => props.onChange(value)} value={props.value}>
        {props.options.map((e) => (
          <Radio key={e.value} value={e.value}>
            <div className="flex justify-between type-number">
              <div>{e.label}</div>
              <div>{formatNumber(e.count)}</div>
            </div>
          </Radio>
        ))}
      </RadioGroup>
    </div>
  );
}
