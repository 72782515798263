import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ItemSidebarLocation } from '../../../analytics/analyticsEventProperties';
import { GENERAL_INFO } from '../../../constants';
import { useCostMode } from '../../../utilities/costMode';
import ItemSidebar from '../../frame/ItemSidebar/ItemSidebar';
import { useCalculateItemPermissions } from '../../frame/ItemSidebar/ItemSidebarUtils';

type ScenariosItemSidebarWrapperProps = {
  item: ItemLike;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  refetchItem: () => void;
  setItemID: (id: string, color: string) => void;
};

export default function ScenariosItemSidebarWrapper({
  item,
  loading,
  refetchItem,
  setItemID,
}: ScenariosItemSidebarWrapperProps) {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const costMode = useCostMode();
  const isReadOnly = false;

  // TODO CT-753: Remove useCalculateItemPermissions and ItemPermissions struct
  const itemSidebarPermissions = useCalculateItemPermissions(item, projectId, isReadOnly);

  // State
  const [expanded, setExpanded] = useState<string[]>([GENERAL_INFO]);

  // Functions
  const openSidebar = (id?: string | undefined) => {
    setItemID(id ?? '', '');
  };

  const closeSidebar = () => {
    setItemID('', '');
  };

  return (
    <ItemSidebar
      closeSidebar={closeSidebar}
      costMode={costMode}
      expanded={expanded}
      footer={null}
      item={item}
      items={[]}
      loading={loading}
      milestones={[]}
      onItemMutated={refetchItem}
      openSidebar={openSidebar}
      permissions={itemSidebarPermissions}
      projectId={item?.project?.id}
      setExpanded={setExpanded}
      setOpenDialogAddOption={() => {}}
      sidebarLocation={ItemSidebarLocation.SCENARIOS}
    />
  );
}
