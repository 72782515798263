import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { isEmpty, trim } from 'validator';

import { ApolloError } from '@apollo/client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close, Edit } from '@material-ui/icons';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import {
  CategorizationImportMetadataInput,
  CreateCategorizationMutation,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useCreateCompanyCategorization } from '../../CompanyTab/Standards/CompanyCategorizations/hooks';
import { Button } from '../../scales';
import CardButton from '../../shared-widgets/CardButton';
import { useReplaceCategories } from '../hooks/hooks';
import useCreateCategoriesFromImport from '../tableUtils/CreateCategoriesFromImport';
import { useCreateCategorization } from '../tableUtils/CreateCategorizationHook';
import usePreviewCategoriesQuery from '../tableUtils/PreviewCategories';
import useUpdateCategorization from '../tableUtils/UpdateCategorizationHook';

import CreateCategorization from './CreateCategorization';
import CreateCategorizationDialogStyles from './CreateCategorizationDialogStyles';
import ErrorMessage, { processError } from './ErrorMessage';
import ImportCategoriesData from './ImportCategoriesData';
import MultilevelCategorizations from './MultilevelCategorizations';
import ReplaceCategorizationConfirm from './ReplaceCategorizationConfirm';
import { CategoriesImportState, CategoryMapInput } from './types';

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
type Props = {
  buttonText: string;
  categorization?: CategorizationMetadata;
  classes: Classes<typeof CreateCategorizationDialogStyles>;
  onBack?: () => void;
  onBackMultilevel?: () => void;
  onClose: () => void;
  onManualEntry: (
    name?: string,
    description?: string,
    categoryMap?: CategoryMapInput[],
    companyID?: UUID,
    projectID?: UUID,
    metadata?: CategorizationImportMetadataInput
  ) => void;
  onSuccess?: (c: CreateCategorizationMutation['createCategorization']) => void;
  projectId?: UUID;
  companyID?: UUID;
  title: string;
};

const CreateCategorizationDialog: FC<Props> = ({
  buttonText,
  categorization,
  classes,
  onBack,
  onBackMultilevel,
  onClose,
  onManualEntry,
  onSuccess,
  projectId,
  companyID,
  title,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [importState, setImportState] = useState<CategoriesImportState>(CategoriesImportState.none);
  const [categorizationName, setCategorizationName] = useState<string | undefined>(
    categorization?.name
  );
  const [categorizationDescription, setCategorizationDescription] = useState<string | undefined>(
    categorization?.description
  );
  const [viewReplaceConfirmation, setViewReplaceConfirmation] = useState(false);

  const [importedCategoriesAssetID, setImportedCategoriesAssetID] = useState('');
  const { categoryMap, metadata, errors } = usePreviewCategoriesQuery(importedCategoriesAssetID);
  const [createCategoriesFromImport] = useCreateCategoriesFromImport();
  const sendAnalytics = useSendAnalytics();

  const onCreateSuccess = (created: CreateCategorizationMutation['createCategorization']) => {
    if (created && 'id' in created && importedCategoriesAssetID && categoryMap && metadata) {
      createCategoriesFromImport(
        created.id,
        importedCategoriesAssetID,
        categoryMap,
        metadata,
        projectId
      );
    }
    onSuccess?.(created);
    onClose();
  };

  const onReplaceSuccess = () => {
    onClose();
  };

  const [createProjectCategorization, createCategorizationResult] = useCreateCategorization(
    projectId ?? '',
    setErrorMessage,
    onCreateSuccess
  );

  const created = createCategorizationResult.data?.createCategorization;

  const createCompanyCategorization = useCreateCompanyCategorization();

  const handleCategorizationInputChange = (e: ChangeEvent, inputType: 'name' | 'description') => {
    if (inputType === 'name') {
      setCategorizationName(e.target.value);
    } else if (inputType === 'description') {
      setCategorizationDescription(e.target.value);
    }
  };

  useEffect(() => {
    // Updates the categorization name and description if there are values in the uploaded file
    if (metadata && importState === CategoriesImportState.loading) {
      setCategorizationName(metadata.name);
      if (metadata.description) {
        setCategorizationDescription(metadata.description);
      }
      setImportState(CategoriesImportState.preview);
    }

    // Switches dialog to the right view for previewing categories
    if (categoryMap && importState === CategoriesImportState.preview) {
      let isMulti = false;
      categoryMap.forEach((category: CategoryMapInput) => {
        if (category.subcategories.length > 0) {
          isMulti = true;
        }
      });
      if (isMulti) {
        setImportState(CategoriesImportState.multi);
      } else {
        onManualEntry(
          categorizationName,
          categorizationDescription,
          categoryMap,
          companyID,
          projectId,
          metadata || undefined
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [categoryMap, metadata, projectId]);

  const updateCategorization = useUpdateCategorization(
    categorization?.id ?? '',
    setErrorMessage,
    (e: ApolloError) => setErrorMessage(processError(e)),
    projectId
  );
  const [replaceCategories] = useReplaceCategories();

  const isMultilevel = categorization && categorization.levels && categorization.levels > 1;

  const isMultilevelPreview = isMultilevel || importState === CategoriesImportState.multi;

  const columns: string[] = [];
  if (metadata) {
    const { columnLevel1: c1, columnLevel2: c2, columnLevel3: c3, columnLevel4: c4 } = metadata;
    if (c1) columns.push(c1);
    if (c2) columns.push(c2);
    if (c3) columns.push(c3);
    if (c4) columns.push(c4);
  }

  return (
    <Dialog disableBackdropClick onClose={onClose} open>
      <Formik
        enableReinitialize // This allows the form to be updated with the categorization name and description when the file is uploaded
        initialValues={{
          name:
            created && 'name' in created
              ? created.name
              : (categorizationName ?? categorization?.name ?? ''),
          description:
            created && 'description' in created
              ? created.description
              : (categorizationDescription ?? categorization?.description ?? ''),
        }}
        onSubmit={(values) => {
          if (!isEmpty(values.name, { ignore_whitespace: true })) {
            const name = trim(values.name);
            const description = trim(values.description);
            if (categorization) {
              if (categorization.name !== name || categorization.description !== description) {
                updateCategorization({ name, description });
              }
              if (categoryMap && metadata) {
                replaceCategories(
                  {
                    categorizationID: categorization.id,
                    categories: categoryMap,
                    assetID: importedCategoriesAssetID,
                    metadata,
                    projectID: projectId,
                  },
                  onReplaceSuccess
                );
              } else {
                onClose();
              }
            } else if (projectId) {
              createProjectCategorization(name, description);
            } else if (companyID) {
              createCompanyCategorization(
                companyID,
                name,
                description,
                onCreateSuccess,
                setErrorMessage
              );
            }
            sendAnalytics(
              categorizationEvent(
                isMultilevel
                  ? CategorizationEvent.CREATE_NEW_ML_CREATE
                  : CategorizationEvent.CREATE_NEW_SL_CREATE
              )
            );
          }
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form>
            <div className={classes.titleContainer}>
              <div>
                <Typography variant="title">{title}</Typography>
              </div>
              <IconButton className={classes.close} title="Close dialog">
                <Close
                  onClick={() => {
                    onClose();
                    sendAnalytics(categorizationEvent(CategorizationEvent.CREATE_NEW_CLOSE));
                  }}
                />
              </IconButton>
            </div>
            <Divider />
            <DialogContent className={classes.content}>
              <CreateCategorization
                disableOnReplace={viewReplaceConfirmation}
                handleChange={(e: ChangeEvent, type: 'name' | 'description') => {
                  handleChange(e);
                  handleCategorizationInputChange(e, type);
                }}
                handleSubmit={handleSubmit}
                setErrorMessage={setErrorMessage}
                values={values}
              />
              <ErrorMessage message={errorMessage} />
              {categoryMap && isMultilevelPreview && !viewReplaceConfirmation && (
                <>
                  <MultilevelCategorizations
                    categories={categoryMap}
                    onClickReplace={() => {
                      setImportState(CategoriesImportState.none);
                      setImportedCategoriesAssetID('');
                      setCategorizationName('');
                      setCategorizationDescription('');
                      sendAnalytics(
                        categorizationEvent(CategorizationEvent.CREATE_NEW_ML_REPLACE, {
                          projectID: projectId,
                          companyID,
                        })
                      );
                    }}
                    onExpandCollapse={() => {
                      sendAnalytics(
                        categorizationEvent(CategorizationEvent.CREATE_NEW_ML_EXPAND_COLLAPSE, {
                          projectID: projectId,
                          companyID,
                        })
                      );
                    }}
                  />
                  {columns.length > 0 && (
                    <div className={classes.metadataContainer}>
                      <Typography variant="caption">Estimate Import Mapping</Typography>
                      <div className={classes.metadataContent}>{columns.join(' > ')}</div>
                    </div>
                  )}
                </>
              )}
              {!categoryMap && (
                <>
                  <ImportCategoriesData
                    handleImportState={setImportState}
                    previewErrors={errors}
                    projectID={projectId}
                    setImportAssetID={setImportedCategoriesAssetID}
                  />
                  <div className="h-2" />
                  {/* We only render this component if the import component isn't being used. */}
                  {importState === CategoriesImportState.none && (
                    <CardButton
                      buttonText="Create categories manually"
                      dataCy="button-create-categories"
                      fullWidth
                      icon={<Edit />}
                      onClick={() =>
                        onManualEntry(
                          values.name,
                          values.description,
                          categoryMap,
                          companyID,
                          projectId
                        )
                      }
                    />
                  )}
                </>
              )}
              {viewReplaceConfirmation && (
                <ReplaceCategorizationConfirm
                  onBack={isMultilevel ? onBackMultilevel : onBack}
                  onSubmit={handleSubmit}
                />
              )}
            </DialogContent>
            {!viewReplaceConfirmation && (
              <DialogActions className={classes.action}>
                {onBack && (
                  <Button
                    data-cy="create-categorizations-dialog-back-button"
                    label="Back"
                    onClick={isMultilevel ? onBackMultilevel : onBack}
                    type="secondary"
                  />
                )}
                <Button
                  data-cy="create-categorization-dialog-create-button"
                  isDisabled={(!categoryMap && !values.name) || !metadata}
                  label={buttonText}
                  onClick={
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                    categorization ? () => setViewReplaceConfirmation(true) : (handleSubmit as any)
                  }
                  type="primary"
                />
              </DialogActions>
            )}
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default withStyles(CreateCategorizationDialogStyles)(CreateCategorizationDialog);
