import { setMarkupModeAnalytics } from '../../../../analytics/analyticsEventProperties';
import { REFETCH_NEW_COST_MODE } from '../../../../api/refetchSets';
import { useRefetch } from '../../../../hooks/useRefetch';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { updateCostMode, useAvailableCostModes, useCostMode } from '../../../../utilities/costMode';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { MarkupModeSelect } from '../../../dragon-scales';
import { Switch } from '../../../scales';

type Props = {
  projectID: UUID;
};

export default function Settings(props: Props) {
  const { shouldDisplayCosts, setDisplayCostsToggle } = useShouldDisplayCosts(props.projectID);
  const markupMode = useCostMode(props.projectID).markupMode;
  const { availableMarkupModes } = useAvailableCostModes(props.projectID);

  const sendAnalytics = useSendAnalytics();
  // I'm not confident enough to remove this, but I don't think we need to manually refetch cost queries
  const refetchCostQueries = useRefetch(REFETCH_NEW_COST_MODE);

  return (
    <div className="flex h-full flex-col gap-2" data-cy="settings">
      {setDisplayCostsToggle && (
        <Switch
          data-cy="display-costs-toggle"
          isChecked={shouldDisplayCosts}
          isFullWidth
          label="Show Costs"
          onChange={setDisplayCostsToggle}
        />
      )}
      {availableMarkupModes.length > 1 && (
        <MarkupModeSelect
          availableMarkupModes={availableMarkupModes}
          isDisabled={!shouldDisplayCosts}
          label="Markups Display"
          onChange={(markupMode) => {
            updateCostMode(props.projectID, { markupMode });
            sendAnalytics(setMarkupModeAnalytics(markupMode));
            setTimeout(() => refetchCostQueries(), 0);
          }}
          value={markupMode}
        />
      )}
    </div>
  );
}
