import { Outlet, useLocation } from 'react-router-dom';

import { RecentlyViewedProjectsContextProvider } from '../contexts/recently-viewed-entries';

import ModeBars from './ModeBars/ModeBars';
import Sidebar from './Sidebar/Sidebar';

/**
 * For some routes we don't want to render the nav/sidebar. Generally, we should accomplish
 * this by having those routes outside of the layout route that renders the <Nav />.
 * However, some routes are currently nested deep within, such as in a project route.
 * Rather than disentangling all those routes, check whether we're in one of them.
 *
 * NB! If you're adding more of these varieties of routes and about to add an entry to this
 *     array, think hard about whether that's the best approach.
 */
const NAV_FREE_PATH_FRAGMENTS = ['/print', '/export', '/sign-up'];

export default function Nav() {
  let hasNav = true;
  const pathname = useLocation().pathname;
  NAV_FREE_PATH_FRAGMENTS.forEach((fragment) => {
    if (pathname.includes(fragment)) {
      hasNav = false;
    }
  });

  if (hasNav) {
    return (
      // nav chrome is rendered in dark theme for constrast
      <div className="flex h-screen w-screen scrollbar-theme" data-theme="dark">
        <RecentlyViewedProjectsContextProvider>
          <Sidebar />
          <div className="flex grow flex-col overflow-auto">
            <ModeBars />
            {/* // the main content of the page is rendered in our standard light theme */}
            <main
              className="grow overflow-auto bg-background-primary scrollbar-theme"
              data-theme="light"
            >
              <Outlet />
            </main>
          </div>
        </RecentlyViewedProjectsContextProvider>
      </div>
    );
  }

  return <Outlet />;
}
