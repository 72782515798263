import { useRef } from 'react';

import { BryntumGantt } from '@bryntum/gantt-react';

import '@bryntum/gantt/gantt.material.css';
import './overrides.css';
import { Button } from '../../scales';

export default function TimelineEditView() {
  const ganttRef = useRef<BryntumGantt>(null);

  return (
    <div className="flex flex-col gap-5 py-5">
      <div className="px-4 type-heading1">Timeline</div>
      <BryntumGantt
        ref={ganttRef}
        bbar={{
          items: [
            {
              type: 'widget',
              html: <Button label="do nothing" onClick={() => {}} type="primary" />,
            },
          ],
        }}
        cellEditFeature
        projectLinesFeature
        rowHeight={40}
        store={{
          sorters: [{ field: 'name', ascending: false }],
        }}
        taskTooltipFeature={{
          anchor: false,
          template: (data) => (
            <div className="flex w-50 flex-col gap-2 rounded-md bg-background-1 p-2">
              <div className="text-type-primary type-heading3">Task Name</div>
              <div className="flex flex-col gap-1">
                <div className="text-type-primary type-body1">{`Name: ${data.taskRecord.getData('name')}`}</div>
                <div className="text-type-primary type-body1">{`Duration: ${data.taskRecord.getData('duration')}`}</div>
              </div>
            </div>
          ),
        }}
        timeRangesFeature={{
          showCurrentTimeLine: {
            name: 'today',
          },
        }}
      />
    </div>
  );
}
