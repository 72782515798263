import { ReactNode } from 'react';

import FilterIcon from '../../Icons/FilterIcon';
import { Button, Icon } from '../../scales';
import { IdeaFilters, StarredToggle } from '../types';

export default function NoResults(props: {
  filters: IdeaFilters;
  hasNoStarredIdeas: boolean;
  onResetFilters: () => void;
}) {
  const isSearchFilterApplied = props.filters.search.length > 0;
  const isCategoryFilterApplied = props.filters.categories.length > 0;
  const isStarredFilterApplied = props.filters.toggleValue === StarredToggle.STARRED_IDEAS;

  if (isStarredFilterApplied && props.hasNoStarredIdeas)
    return (
      <Content
        body="Star some Ideas to keep them handy. Reset the filter to explore all available options."
        heading="No starred Ideas here (yet)."
      >
        <Button
          label="See all Ideas"
          onClick={props.onResetFilters}
          startIcon={<Icon name="bulb" />}
          type="secondary"
        />
      </Content>
    );

  if (isSearchFilterApplied && !isCategoryFilterApplied && !isStarredFilterApplied)
    return (
      <Content
        body="Try using a different keyword, or browse some of our suggestions below."
        heading={`No results found for "${props.filters.search}"`}
      />
    );

  return (
    <Content
      body="Don't worry; you can reset your filters/search or try tweaking them to explore more
          Ideas."
      heading="Oops, it looks like nothing fits your criteria"
    >
      <Button
        label="Clear filters"
        onClick={props.onResetFilters}
        startIcon={<FilterIcon isFilled />}
        type="secondary"
      />
    </Content>
  );
}

function Content(props: { body: string; children?: ReactNode; heading: string }) {
  return (
    <div className="flex w-full justify-center">
      <div className="flex w-max flex-col items-center gap-4 rounded border p-6">
        <div className="type-heading2">{props.heading}</div>
        <NoResultsSVG />
        <div className="type-body1">{props.body}</div>
        {props.children}
      </div>
    </div>
  );
}

function NoResultsSVG() {
  return (
    <svg fill="none" height="72" viewBox="0 0 75 72" width="75" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1782_14887)">
        <path
          d="M32.6074 45.5778C32.2014 42.2264 31.5288 38.7557 28.2417 35.0184C26.8757 33.5325 26.0157 31.6881 25.7548 29.6842C25.0603 24.3503 28.8338 19.4471 34.1677 18.7526C34.2063 18.7476 34.2364 18.7246 34.2741 18.7165C34.3123 18.7147 34.3473 18.7293 34.3859 18.7242C35.6924 18.5541 36.9924 18.6449 38.2497 18.9932C38.4269 19.0425 38.6069 19.0532 38.7785 19.0309C39.2936 18.9638 39.7437 18.5978 39.8909 18.0653C40.0873 17.3556 39.6726 16.6203 38.9629 16.4239C37.3597 15.9786 35.7046 15.8634 34.0416 16.0799C34.003 16.0849 33.9729 16.1079 33.9352 16.1161C33.897 16.1178 33.8623 16.1032 33.8234 16.1083C27.0317 16.9926 22.2262 23.237 23.1105 30.0285C23.4428 32.5811 24.538 34.9308 26.2587 36.8011C29.332 40.2968 29.6822 43.3894 30.0857 46.9716L30.121 47.2843L30.1215 47.288L30.1305 47.3673C30.1308 47.3696 30.1324 47.3716 30.1327 47.374L30.6002 50.9642C31.0168 54.1638 34.0788 56.4121 37.4268 55.9762L40.7375 55.5452C44.0855 55.1092 46.4711 52.1514 46.0545 48.952L45.5754 45.2722C45.53 44.9236 45.3483 44.6058 45.0706 44.3899C44.5493 43.988 44.194 43.7484 32.6074 45.5778ZM43.41 49.2969C43.6366 51.0374 42.2835 52.6552 40.393 52.9014L37.0823 53.3324C35.1932 53.5784 33.4709 52.361 33.2443 50.6205L32.9326 48.2265C37.0038 47.5862 41.2016 46.9896 43.0846 46.7996L43.41 49.2969Z"
          fill="black"
        />
        <path
          d="M51.757 22.5255C47.4444 22.3604 47.0546 22.0597 45.7918 17.9354C45.6769 17.561 45.3103 17.3252 44.9229 17.3757C44.5355 17.4261 44.2416 17.7481 44.2263 18.1392C44.0617 22.4493 43.7619 22.8398 39.6345 24.1038C39.2609 24.2189 39.0249 24.5852 39.0754 24.9726C39.1258 25.36 39.4486 25.6539 39.8384 25.6693C44.1508 25.8328 44.5414 26.1334 45.8044 30.2593C45.9193 30.6337 46.2858 30.8694 46.6732 30.819C47.0606 30.7686 47.3546 30.4466 47.3698 30.0555C47.5342 25.7435 47.8349 25.3532 51.9608 24.0909C52.3336 23.976 52.5704 23.6095 52.52 23.2221C52.4696 22.8347 52.1476 22.5411 51.757 22.5255Z"
          fill="#DE7C1D"
        />
        <path
          d="M47.224 34.6832C44.5062 34.5791 44.2605 34.3895 43.4646 31.7905C43.3923 31.5546 43.1612 31.4059 42.9172 31.4377C42.6731 31.4694 42.4879 31.6724 42.4783 31.9189C42.3745 34.635 42.1855 34.8812 39.5845 35.6778C39.3492 35.7502 39.2004 35.9812 39.2322 36.2252C39.264 36.4693 39.4674 36.6545 39.7129 36.6642C42.4305 36.7672 42.6768 36.9568 43.4727 39.5566C43.5451 39.7924 43.7761 39.9411 44.0202 39.9094C44.2643 39.8776 44.4495 39.6746 44.4591 39.4281C44.5627 36.7109 44.752 36.465 47.3524 35.6695C47.5872 35.5973 47.7365 35.3662 47.7047 35.1221C47.6729 34.878 47.47 34.6928 47.224 34.6832Z"
          fill="#DE7C1D"
        />
      </g>
      <path
        d="M14.7367 31.79H1.13672"
        stroke="#F9CE4D"
        strokeDasharray="4.35 4.35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.1763"
      />
      <path
        d="M15.0668 25.02L2.25684 19"
        stroke="#F9CE4D"
        strokeDasharray="4.35 4.35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.1763"
      />
      <path
        d="M15.0668 38.5703L2.25684 44.5903"
        stroke="#F9CE4D"
        strokeDasharray="4.35 4.35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.1763"
      />
      <path
        d="M61.419 31.7998L73.0664 31.7998"
        stroke="#F9CE4D"
        strokeDasharray="4.35 4.35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.1763"
      />
      <path
        d="M61.1366 38.5698L72.1074 44.5898"
        stroke="#F9CE4D"
        strokeDasharray="4.35 4.35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.1763"
      />
      <path
        d="M61.1366 25.0195L72.1074 18.9995"
        stroke="#F9CE4D"
        strokeDasharray="4.35 4.35"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.1763"
      />
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="75.7266"
          id="filter0_d_1782_14887"
          width="75.7275"
          x="1.13672"
          y="0"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="4" dy="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.819608 0 0 0 0 0.839216 0 0 0 0 0.839216 0 0 0 1 0"
          />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_1782_14887" />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1782_14887"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
