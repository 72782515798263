import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import { CurrentUserQuery } from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { CurrentUserContextProvider } from '../contexts/current-user';
import { currentUserQuery } from '../contexts/queries';

import { useAcceptedTOS } from './hooks/useTOSHooks';
import { useVerifyEmailMutation } from './Signup/SignupPages/EmailVerification/EmailVerificationHooks';
import EmailVerificationLayer from './Signup/SignupPages/EmailVerification/EmailVerificationLayer';
import TOSCheckLayer from './TOSPage';

export default function VerificationLayers(props: { children: JSX.Element }) {
  const currentUser = useFetchCurrentUser();
  const isEmailVerified = useIsEmailVerified();
  const acceptedTOS = useAcceptedTOS();

  if (
    !currentUser ||
    isEmailVerified === undefined ||
    acceptedTOS.accepted === undefined ||
    acceptedTOS.dateEffective === undefined
  ) {
    return <LinearProgress />;
  }

  return (
    <CurrentUserContextProvider value={currentUser}>
      <EmailVerificationLayer isEmailVerified={isEmailVerified}>
        <TOSCheckLayer dateEffective={acceptedTOS.dateEffective} isAccepted={acceptedTOS.accepted}>
          {props.children}
        </TOSCheckLayer>
      </EmailVerificationLayer>
    </CurrentUserContextProvider>
  );
}

function useFetchCurrentUser() {
  const result = useQuery<CurrentUserQuery>(currentUserQuery);
  return result.data?.currentUser ?? result.previousData?.currentUser ?? undefined;
}

function useIsEmailVerified() {
  const location = useLocation(); // use a hook to be refreshed on changes
  const emailVerificationToken =
    new URLSearchParams(decodeURIComponent(location.search)).get('emailVerify') ?? '';

  const [verifyEmail, verifyEmailResult] = useVerifyEmailMutation();
  useEffect(() => {
    verifyEmail({ variables: { emailVerify: emailVerificationToken } });
  }, [emailVerificationToken, verifyEmail]);

  return verifyEmailResult.data?.verifyEmail;
}
