import { EditorView } from '@tiptap/pm/view';

import joinAPI, { apiRoute } from '../../../../api/joinAPI';

const MAX_FILE_MB = 10;

export type UploadImage = (
  file: File,
  projectID: string | null,
  onSuccess: (asset: Asset) => void,
  onFailure: (message: string) => void
) => Promise<unknown>;

export type OnAttachAsset = (asset: Asset) => void;

export const addFile = (
  file: File,
  uploadImage: UploadImage,
  onSuccessOuter?: (assetWithBlobUrl: Asset) => void,
  onFailureOuter?: () => void
) => {
  const onSuccess = async (a: Asset) => {
    const assetWithBlobUrl = a;
    await joinAPI.requestAssetBlobURL(a.location, a.name);
    if (onSuccessOuter) onSuccessOuter(assetWithBlobUrl);
  };
  const onFailure = async () => {
    if (onFailureOuter) onFailureOuter();
  };
  uploadImage(file, null, onSuccess, onFailure);
};

export const isAllowedSize = (file: File) => {
  const filesize = Number(file.size) / 1024 / 1024; // get the filesize in MB
  return filesize < MAX_FILE_MB;
};

export const isAllowedType = (file: File) =>
  file.type === 'image/jpeg' || file.type === 'image/png';

export const addImage = (
  view: EditorView,
  file: File,
  x: number,
  y: number,
  uploadImage: UploadImage,
  onSuccessOuter?: OnAttachAsset,
  onFailureOuter?: () => void
): boolean => {
  if (!isAllowedType(file)) return false;
  if (!isAllowedSize(file)) return false;
  // Focus input
  view.dom?.focus();
  const _URL = window.URL || window.webkitURL;
  const img = new Image();
  img.src = _URL.createObjectURL(file);
  img.onload = () => {
    const onSuccess = (assetWithBlobUrl: Asset) => {
      if (onSuccessOuter) onSuccessOuter(assetWithBlobUrl);
      // place the now uploaded image in the editor where it was dropped
      const { schema } = view.state;
      const coordinates = view.posAtCoords({ left: x, top: y });
      const src = apiRoute(assetWithBlobUrl?.location);
      // creates the image element
      const node = schema.nodes.image.create({ src });
      // places it in the correct position
      const transaction = view.state.tr.insert(coordinates?.pos || 0, node);
      return view.dispatch(transaction);
    };
    addFile(file, uploadImage, onSuccess, onFailureOuter);
  };
  return true; // handled
};
