import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import FourOhFour from '../FourOhFour';
import useProjectStatusesQuery from '../ProjectsList/hooks/useProjectStatusesQuery';

import ProjectProperties from './ProjectProperties';

const ProjectPropertiesData: FC = () => {
  const { projectId } = useParams();

  const project = useProjectPropsQuery(projectId)?.data?.project;
  // pre-load the project statuses so we don't need to wait for it later
  useProjectStatusesQuery();

  if (!projectId) {
    return <FourOhFour message="Project not found" />;
  }

  if (!project?.id) {
    return <LinearProgress hidden={!project?.id} />;
  }

  return <ProjectProperties project={project} />;
};

export default ProjectPropertiesData;
