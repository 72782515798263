import { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';

import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Thumbnail } from '../../dragon-scales';

import ProjectDetails from './Details/ProjectDetails';
import NavPopover from './NavPopover';

type Props = {
  projectID: UUID;
};

export default function ProjectThumbnail(props: Props) {
  const projectQueryResult = useProjectPropsQuery(props.projectID);
  const project =
    projectQueryResult.data.project ?? projectQueryResult.previousData?.project ?? undefined;

  const overlayState = useOverlayTriggerState({});
  const ref = useRef<HTMLDivElement>(null);

  // Manually control the trigger callbacks instead of using RA's triggerProps
  // We need to do this here because we're about to create an overlay that can open
  // a popover when the user clicks the edit-cost-mode button. Clicking inside that
  // second popover will blur this component, closing the overlay before you ever
  // change the cost mode.
  const onOpenOverlay = useCallback(() => overlayState.open(), [overlayState]);
  const onCloseOverlay = useCallback(() => overlayState.close(), [overlayState]);

  return (
    <div
      ref={ref}
      className="flex w-full items-center justify-center p-2"
      data-cy="project-thumbnail"
      onPointerEnter={onOpenOverlay}
      onPointerLeave={onCloseOverlay}
    >
      <Link
        className="cursor-pointer border focus-visible:outline"
        to={generateSharedPath(RouteKeys.PROJECT, {
          projectId: props.projectID,
        })}
      >
        <Thumbnail size={40} thumbnail={project?.thumbnail} />
      </Link>
      {overlayState.isOpen && (
        <NavPopover placement="right top" state={overlayState} triggerRef={ref}>
          <div className="w-[250px] py-2">
            <ProjectDetails isThumbnailVisible={false} projectID={props.projectID} />
          </div>
        </NavPopover>
      )}
    </div>
  );
}
