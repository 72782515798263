import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { Fragment } from 'react';

import SVGWithDimensions from '../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../Charts/ChartsD3/useChartDimensions';

import EstimateChartVerticalBar from './EstimateChartVerticalBar';
import EstimateChartXAxis from './EstimateChartXAxis';
import EstimateChartYAxis from './EstimateChartYaxis';
import { CategoryTotals } from './hooks/useProgramCategorizedTotalsQuery';

type EstimateChartProps = {
  categoryCostData: CategoryTotals[];
  selectedCategorization: string;
};

export default function EstimateChart({
  categoryCostData,
  selectedCategorization,
}: EstimateChartProps) {
  const LABEL_HEIGHT = 150; // Includes category name, cost, and categorization name

  const { ref, dimensions } = useChartDimensions({
    height: 500,
    marginTop: 20,
    marginRight: 100,
    marginBottom: LABEL_HEIGHT,
    marginLeft: 100,
  });

  // Create scales using full available height
  const xDomain = categoryCostData.map((c) => c.categoryName);
  const xScale = scaleBand().domain(xDomain).padding(0.1).range([0, dimensions.boundedWidth]);

  const yDomain = [
    min(categoryCostData, (d) => Math.min(d.estimate, d.budget, d.runningTotal, 0)) || 0,
    max(categoryCostData, (d) => Math.max(d.estimate, d.budget, d.runningTotal, 0)) || 0,
  ];
  const yScale = scaleLinear()
    .domain(yDomain)
    .nice()
    .range([dimensions.height - LABEL_HEIGHT, 0]);

  return (
    <SVGWithDimensions ref={ref} dimensions={dimensions}>
      <EstimateChartXAxis
        categorizationName={selectedCategorization}
        categoryCostData={categoryCostData}
        xScale={xScale}
        xWidth={dimensions.boundedWidth}
        yOffset={dimensions.height - LABEL_HEIGHT}
      />
      <EstimateChartYAxis xWidth={dimensions.boundedWidth} yScale={yScale} />
      {categoryCostData.map((costData) => (
        <Fragment key={`chart-group-${costData.categoryName}`}>
          <line stroke="black" x1={0} x2={dimensions.boundedWidth} y1={yScale(0)} y2={yScale(0)} />
          <EstimateChartVerticalBar
            key={`estimate-${costData.categoryName}`}
            data={costData}
            x={xScale}
            y={yScale}
          />
        </Fragment>
      ))}
    </SVGWithDimensions>
  );
}
