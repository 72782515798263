import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { importEstimateIsPublishedVar } from '../../../api/apollo/reactiveVars';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import FourOhFour from '../../FourOhFour';
import { useMilestoneQuery } from '../hooks';
import { useGetProjectUnitsQuery } from '../hooks/UnitHooks';
import MilestoneDetails from '../MilestoneDetails/MilestoneDetails';

const MilestoneData: FC = () => {
  const { milestoneId, projectId } = useParams();
  if (!milestoneId || !projectId) throw new Error('Project ID and Milestone ID are required');
  const isPublishedDraftEstimate = useReactiveVar(importEstimateIsPublishedVar);

  const {
    data: { project },
    loading: loadingProject,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name ?? '';
  const activeMilestoneId = project?.activeMilestone.id ?? '';

  const { loading: loadingMilestone, error, data, refetch } = useMilestoneQuery(milestoneId);
  const milestone = data?.milestone;

  // load project enabled units
  const {
    data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] },
    loading: unitsLoading,
  } = useGetProjectUnitsQuery(projectId, true);

  const isLoading: boolean =
    loadingMilestone || loadingProject || isPublishedDraftEstimate || unitsLoading;

  useEffect(() => {
    // when publishing an estimate we are reloading the milestone
    // due to how apollo works the loading flag will not be true
    // but the milestone will be false to avoid a 404 error page
    // we need to check if we just published an estimate
    if (isPublishedDraftEstimate && !loadingMilestone && milestone)
      importEstimateIsPublishedVar(false);
  }, [isPublishedDraftEstimate, loadingMilestone, milestone]);

  if (isLoading) return null;
  if (error || !milestone) {
    return <FourOhFour message="Error loading milestone" />;
  }

  return (
    <MilestoneDetails
      activeMilestoneId={activeMilestoneId}
      enabledUnits={enabledUnits}
      milestone={milestone}
      projectName={projectName}
      refetchMilestone={refetch}
    />
  );
};

export default MilestoneData;
