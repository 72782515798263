import { useReactiveVar } from '@apollo/client';

import {
  FilesDialogView,
  filesDialogViewVar,
  selectedAssetsVar,
} from '../../../../api/apollo/reactiveVars';
import useBrowserTabUpdate from '../../../../hooks/useBrowserTabUpdate';
import { matchStringWithSourceEnum } from '../../../../utilities/url';
import { useItemQuery } from '../../../Items/hooks/useItemQuery';
import { useMilestoneQuery } from '../../../Milestone/hooks';
import { Dialog } from '../../../scales';
import { useUserSourcesQuery } from '../../hooks';
import {
  getAssetIDsforDisabling,
  useEntityAttachments,
  useEntityID,
} from '../FilesAssets/FilesAssetsUtils';
import FilesAttach from '../FilesAttach/FilesAttach';

import FilesDialogContent from './FilesDialogContent';
import { getCurrentUserSource, getHeaderText } from './FilesDialogUtils';

export const FILES_DIALOG_TAB_KEY = 'FilesDialog_UserSources';

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onDropFile: (file: File) => void;
  onClose: () => void;
  onAttachItemCopy?: (assetIDs: UUID[]) => void;
  onItemMutated?: () => void;
  projectID: UUID;
};

export default function FilesDialog({
  open,
  onDropFile,
  onClose,
  onAttachItemCopy,
  onItemMutated,
  projectID,
}: Props) {
  const { data, refetch } = useUserSourcesQuery();
  const { itemID, milestoneID } = useEntityID();
  const itemResult = useItemQuery(itemID);
  const item = itemResult.data?.item;
  const milestone = useMilestoneQuery(milestoneID);
  const view = useReactiveVar(filesDialogViewVar);

  const entityAssets = useEntityAttachments();
  const disabledIDs = getAssetIDsforDisabling(entityAssets);

  // Refresh hooks
  const onTabEvent = (sourceString?: string | null) => {
    refetch();
    if (sourceString) {
      const source = matchStringWithSourceEnum(sourceString);
      if (source) {
        filesDialogViewVar(source);
      }
    }
  };
  useBrowserTabUpdate(FILES_DIALOG_TAB_KEY, onTabEvent);

  // Actions
  const closeDialog = () => {
    onClose();
    filesDialogViewVar(FilesDialogView.ALL_FILES);
    selectedAssetsVar({ selectedMap: {} });
  };

  const selectedUserSource = getCurrentUserSource(data?.userSources.sources, view);

  return (
    <Dialog
      footerRight={
        <FilesAttach
          onAttachItemCopy={onAttachItemCopy}
          onClick={closeDialog}
          onItemMutated={onItemMutated}
          sourceSystem={selectedUserSource?.sourceSystem}
        />
      }
      isOpen={open}
      onClose={closeDialog}
      size="lg"
      title={getHeaderText(item, milestone.data?.milestone)}
    >
      <FilesDialogContent
        disabledIDs={disabledIDs}
        onDropFile={onDropFile}
        onItemMutated={onItemMutated}
        projectID={projectID}
      />
    </Dialog>
  );
}
