import { FC } from 'react';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { IconButton } from '../../scales';

import styles from './DialogsHideListItemStyles';

type DialogsHideListItemProps = {
  classes: Classes<typeof styles>;
  content: JSX.Element;
  onClickToggleVisibility: () => void;
  onMouseLeave: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseOver: (trendlineEvent: any) => void;
  isSelected: boolean;
  isVisible: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  listItem: any;
};

const DialogsHideListItem: FC<DialogsHideListItemProps> = ({
  classes,
  content,
  listItem,
  onClickToggleVisibility,
  onMouseLeave,
  onMouseOver,
  isSelected,
  isVisible,
}) => (
  <div
    className={isSelected ? `${classes.root} ${classes.selected}` : `${classes.root}`}
    data-cy="hide-list-item"
    onFocus={() => onMouseOver(listItem)}
    onMouseLeave={() => onMouseLeave()}
    onMouseOver={() => onMouseOver(listItem)}
  >
    <div className={classes.description}>{content}</div>
    <IconButton
      aria-label="Hide"
      data-cy="vis-icon"
      icon={isVisible ? <Visibility /> : <VisibilityOff />}
      onClick={onClickToggleVisibility}
      type="secondary"
    />
  </div>
);

export default withStyles(styles)(DialogsHideListItem);
