import { useProjectCategorizationsQuery } from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useProjectID } from '../../../utilities/routes/params';
import { HasDisplayCosts } from '../../conditionals/HasDisplayCosts';
import { useMilestoneQuery } from '../../Milestone/hooks';

import PrintDashboard from './PrintDashboard';

export default () => (
  <HasDisplayCosts>
    <PrintDashboardData />
  </HasDisplayCosts>
);

function PrintDashboardData() {
  // CONSTANTS
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');

  // DATA
  const projectPropsQueryResult = useProjectPropsQuery(projectId);
  const project = projectPropsQueryResult.data.project;
  const projectCategorizationsQueryResult = useProjectCategorizationsQuery(projectId);
  const activeMilestoneId = project?.activeMilestone.id;
  const milestoneQueryResult = useMilestoneQuery(activeMilestoneId);

  const loading =
    projectPropsQueryResult.loading ||
    projectCategorizationsQueryResult.loading ||
    milestoneQueryResult.loading;

  if (loading) return null;

  return (
    <PrintDashboard
      milestoneData={milestoneQueryResult.data}
      projectCategorizationsData={projectCategorizationsQueryResult.data}
      projectPropsData={projectPropsQueryResult.data}
    />
  );
}
