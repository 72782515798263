import { useEffect } from 'react';

import usePrintWindow from '../Print/PrintHooks/usePrintWindow';

import ProgramDashboard from './ProgramDashboard';
import useProgramDashboardHooks from './ProgramDialog/hooks/useProgramDashboardHooks';

type Props = {
  programID: UUID;
};

export default function PrintProgramDashboardData({ programID }: Props) {
  const data = useProgramDashboardHooks(programID);

  usePrintWindow(!data.isLoading);

  // This will set the name of the PDF that is generated
  useEffect(() => {
    if (data?.info.program) document.title = data?.info.program.name;
  }, [data?.info.program]);

  return <ProgramDashboard data={data} isPrinting />;
}
