import { ComponentProps, RefObject, forwardRef, useRef } from 'react';
import { useMenuTrigger } from 'react-aria';
import { MenuTriggerProps, useMenuTriggerState } from 'react-stately';

import composeRefs from '@seznam/compose-react-refs';

import BabyButton from '../BabyButton/BabyButton';
import Menu from '../Menu/Menu';
import Popover from '../Popover/Popover';
import { TooltipTriggerProps } from '../Tooltip';

type MenuProps = ComponentProps<typeof Menu> & MenuTriggerProps;

type Props = ComponentProps<typeof BabyButton> &
  MenuProps &
  TooltipTriggerProps & {
    buttonRef?: RefObject<HTMLButtonElement>;
    onClick?: never;
  };

export default forwardRef<HTMLButtonElement, Props>(function BabyMenuButton(props, forwardedRef) {
  const { buttonRef, ...buttonProps } = props;

  // Create state based on the incoming props
  const state = useMenuTriggerState(props);

  // Get props for the menu trigger and menu elements
  const ref = useRef<HTMLButtonElement>(null);
  const { menuProps } = useMenuTrigger({}, state, ref);

  // TODO: Re-enable data-cy validation, after MUI IconMenuButton migration is merged
  // validateDataCy(props['data-cy'], 'menu-button');

  return (
    <div className="relative">
      <BabyButton
        {...buttonProps}
        ref={composeRefs(ref, buttonRef, forwardedRef)}
        onClick={() => {
          state.toggle();
        }}
      />
      {state.isOpen && (
        <Popover offset={8} placement="bottom start" state={state} triggerRef={ref}>
          <Menu
            {...menuProps}
            {...props}
            autoFocus={state.focusStrategy || true}
            data-cy={props['data-cy'] ? `${props['data-cy']}-menu` : undefined}
            onClose={state.close}
          />
        </Popover>
      )}
    </div>
  );
});
