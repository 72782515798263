import { CircularProgress } from '@material-ui/core';

import { CategoryTotals, ProgramEstimateChartOptionsQuery } from '../../generated/graphql';
import DashboardAnchor from '../dashboard/DashboardAnchor';
import { ButtonBar, Select } from '../scales';

import EstimateChart from './EstimateChart';
import EstimateChartLegend from './EstimateChartLegend';
import ProgramEstimateChartBanner from './ProgramEstimateChartBanner';

type Props = {
  selectedUnitAbbreviation: string;
  selectedGroupingID: UUID;
  setSelectedGroupingID: (id: UUID) => void;
  programCategorizedTotals: CategoryTotals[];
  setSelectedUnitAbbreviation: (unitAbb: string) => void;
  programEstimateChartInfo: ProgramEstimateChartOptionsQuery['programEstimateChartOptions'];
  projects: ForecastingProject[];
};

export const BY_PROJECT_ID = 'by-project';
export const TOTAL_UNIT_ID = 'total';

export default function ProgramCombinedEstimateChart(props: Props) {
  // Track selected grouping option
  // Create grouping options including "By Project" and categorizations
  const categorizations = props.programEstimateChartInfo?.categorizations ?? [];
  const groupingOptions = [
    { id: BY_PROJECT_ID, label: 'Projects in Program' },
    ...categorizations.map((cat) => ({
      id: cat.name,
      label: cat.name,
    })),
  ];

  // create options for the select dropdown from
  // programEstimateChartInfo.sharedUnits
  const abbreviations = props.programEstimateChartInfo?.sharedUnitAbbreviations ?? [];
  const sharedUnitsOptions = [
    { value: TOTAL_UNIT_ID, label: 'Total' },
    ...abbreviations.map((unit) => ({
      value: unit,
      label: `$/${unit}`,
    })),
  ];

  return (
    <div>
      <DashboardAnchor anchor="Combined Estimate" />
      <div className="flex w-full flex-col gap-4">
        <div className="flex justify-end gap-2">
          <div className="flex w-52">
            <ButtonBar
              label="Shared Units"
              onChange={(v) => props.setSelectedUnitAbbreviation(v)}
              options={sharedUnitsOptions}
              value={props.selectedUnitAbbreviation}
            />
          </div>
          <div className="flex w-52">
            <Select
              entries={groupingOptions}
              label="Group By"
              onChange={props.setSelectedGroupingID}
              value={props.selectedGroupingID}
            />
          </div>
        </div>
        <ProgramEstimateChartBanner
          programEstimateChartInfo={props.programEstimateChartInfo}
          projects={props.projects}
          selectedCategorization={props.selectedGroupingID}
        />
        <EstimateChartLegend />
        <div className="relative">
          {!props.programCategorizedTotals && (
            <div className="flex h-[600px] w-full items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {props.programCategorizedTotals && (
            <EstimateChart
              categoryCostData={props.programCategorizedTotals}
              selectedCategorization={
                props.selectedGroupingID === BY_PROJECT_ID ? 'By Project' : props.selectedGroupingID
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
