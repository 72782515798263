import { FC, useMemo } from 'react';

import { CategoryLink, PermissionResource } from '../../../generated/graphql';
import { PermissionFunc } from '../../../utilities/permissions/types';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import { AddCategoriesToFilter } from '../../FilterPanel/filterUtils';
import { COMPACT_LIST, LIST } from '../../ItemsList/ItemsListViewToggle';
import { useIsHighlightedItem } from '../ItemsHooks';
import { getItemSettingsFromURL, getItemTotalValue } from '../ItemsUtils';

import ItemsListItem from './ItemsListItem';
import ItemsListItemCompact from './ItemsListItemCompact';
import { canBulkEditFunc } from './ItemsListItemUtils';

type ItemFlag = {
  parentIsChosen?: boolean;
};

type CategorizedItem = ItemsListItem & CategorizedLine & ItemFlag;

type ItemsListItemDataProps = {
  addCategoriesToFilter?: AddCategoriesToFilter;
  canEdit: PermissionFunc;
  canView: PermissionFunc;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  children?: any;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  filteredMilestoneID?: UUID;
  hasCtaButton?: boolean;
  isListWithOptions?: boolean;
  isCollapseHeader?: boolean;
  isNested?: boolean;
  isSelected?: boolean;
  item: CategorizedItem;
  link: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseEnter?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseLeave?: (event: any) => void;
  options?: ItemsTreeOption[]; // if the display options differ than the listed Items due to filtering...
  selectItemLike?: (id: UUID, isSelected: boolean, isItem: boolean, milestoneID: UUID) => void;
  sharedUsers: Pick<User, 'id' | 'name'>[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCategories?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCheckbox?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showParent?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showPartialCost?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showSimpleSchedule?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showNoEstimate?: boolean;
  view?: string;
  mainCostImpact?: Cost;
  totalCostImpact?: Cost;
};

const ItemsListItemData: FC<ItemsListItemDataProps> = ({
  addCategoriesToFilter,
  canEdit,
  canView,
  children, // this is the third column content
  disabled = false,
  filteredMilestoneID,
  hasCtaButton = false,
  isCollapseHeader = false,
  isListWithOptions = false,
  isNested = false, // this is a nested option in the items list
  isSelected,
  item,
  link = '',
  onMouseEnter,
  onMouseLeave,
  options,
  selectItemLike,
  sharedUsers,
  showCategories = true,
  showCheckbox = true,
  showParent = false,
  showPartialCost = true,
  showSimpleSchedule = false,
  showNoEstimate = false,
  view = LIST,
  mainCostImpact,
  totalCostImpact,
}) => {
  const { categories, costImpact, filteredMilestoneState, id, milestone, parentID } = item;

  const isHighlightedItem = useIsHighlightedItem(id);

  // PERMISSIONS
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const { canViewAttachments, canViewComments, canBulkEdit, canViewEstimateCostSubtotals } =
    useMemo(() => getItemPermissions(canView, canEdit, categories), [canEdit, canView, categories]);

  const itemMilestoneID = milestone?.id || '';

  // FUNCTIONS
  const toggleCheckbox = (select: boolean) => {
    if (selectItemLike) selectItemLike(id, select, !parentID, itemMilestoneID);
  };

  const { groupBy, show, hasGroupings } = getItemSettingsFromURL();

  const totalValue = getItemTotalValue(costImpact, mainCostImpact, showPartialCost);

  const isCompact = view === COMPACT_LIST;

  if (isCompact) {
    return (
      <ItemsListItemCompact
        // eslint-disable-next-line react/no-children-prop
        children={children}
        canEdit={canEdit}
        canViewCheckbox={canBulkEdit}
        costImpact={mainCostImpact}
        disabled={disabled}
        filteredMilestoneID={filteredMilestoneID}
        filteredMilestoneState={filteredMilestoneState}
        groupBy={groupBy}
        hasCtaButton={hasCtaButton}
        hasGroupings={hasGroupings}
        isCollapseHeader={isCollapseHeader}
        isHighlightedItem={isHighlightedItem}
        isListWithOptions={isListWithOptions}
        isNested={isNested}
        isSelected={isSelected}
        item={item}
        link={link}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        sharedUsers={sharedUsers}
        shouldDisplayCosts={shouldDisplayCosts}
        show={show}
        showCheckbox={showCheckbox}
        toggleCheckbox={toggleCheckbox}
        totalValue={totalCostImpact || totalValue}
      />
    );
  }

  return (
    <ItemsListItem
      // eslint-disable-next-line react/no-children-prop
      children={children}
      addCategoriesToFilter={addCategoriesToFilter}
      canEdit={canEdit}
      canViewAttachments={canViewAttachments}
      canViewCheckbox={canBulkEdit}
      canViewComments={canViewComments}
      canViewEstimateCostSubtotals={canViewEstimateCostSubtotals}
      costImpact={mainCostImpact}
      disabled={disabled}
      filteredMilestoneID={filteredMilestoneID}
      filteredMilestoneState={filteredMilestoneState}
      groupBy={groupBy}
      hasCtaButton={hasCtaButton}
      hasGroupings={hasGroupings}
      isCollapseHeader={isCollapseHeader}
      isHighlightedItem={isHighlightedItem}
      isListWithOptions={isListWithOptions}
      isNested={isNested}
      isSelected={isSelected}
      item={item}
      link={link}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      options={options}
      sharedUsers={sharedUsers}
      shouldDisplayCosts={shouldDisplayCosts}
      show={show}
      showCategories={showCategories}
      showCheckbox={showCheckbox}
      showNoEstimate={showNoEstimate}
      showParent={showParent}
      showSimpleSchedule={showSimpleSchedule}
      toggleCheckbox={toggleCheckbox}
      totalValue={totalCostImpact || totalValue}
    />
  );
};

export default ItemsListItemData;

const getItemPermissions = (
  canView: PermissionFunc,
  canEdit: PermissionFunc,
  categories: CategoryLink[]
) => {
  const trades = { trades: categories };
  const canViewAttachments = canView(PermissionResource.ITEM_ATTACHMENTS, trades);
  const canViewComments = canView(PermissionResource.ITEM_COMMENTS, trades);
  const canBulkEdit = canBulkEditFunc(canEdit, trades);
  const canViewEstimateCostSubtotals = canView(PermissionResource.ESTIMATE_COST_SUBTOTALS, trades);
  return { canViewAttachments, canViewComments, canBulkEdit, canViewEstimateCostSubtotals };
};
