import { FC, useState } from 'react';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { DD_BENCHMARKING } from '../../../features';
import { CompanyEmployeeInput } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { isValidEmail } from '../../../utilities/string';
import { useAddCompanyEmployeeMutation } from '../../CompanyTab/CompanyHooks';
import { useModuleEntitlements } from '../../contexts/module-entitlements';
import { Button, Dialog, DialogContent, Icon, IconButton, Select, TextInput } from '../../scales';
import { allRoles } from '../DialogsCompanyRoles/DialogsCompanyRolesUtils';

type DialogsAddCompanyEmployeeProps = {
  company: Company;
  companyProjectRoles: CompanyProjectRoles[];
  companyRoles: PartialRole[];
  companyUsers: CompanyUser[];
  onCreateEmployee?: (user: User, projectNames: string[]) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  selectedProjectID?: UUID;
  setDialogOpen: (open: boolean) => void;
};

type ProjectRoleInput = {
  id: string;
  name: string;
  key: number;
};

const DialogsAddCompanyEmployee: FC<DialogsAddCompanyEmployeeProps> = ({
  company,
  companyProjectRoles,
  companyRoles,
  companyUsers,
  onCreateEmployee,
  open = false,
  selectedProjectID,
  setDialogOpen,
}) => {
  const moduleEntitlementFlags = useModuleEntitlements();
  const hasBenchmarkingFeature = useHasFeature(DD_BENCHMARKING);

  const defaultCompanyRoleName = companyRoles?.[0].name ?? '';
  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState<CompanyEmployeeInput>({
    email: '',
    companyRoleName: defaultCompanyRoleName,
    projectIDs: [],
    projectRoleNames: [],
  });
  const preSelectedProject = companyProjectRoles.find((c) => c.id === selectedProjectID);
  const preSelectedProjectInput = preSelectedProject
    ? { id: preSelectedProject?.id, name: '', key: 1 }
    : { id: '', name: '', key: 0 };
  const { email, name = '', jobTitle = '', companyRoleName } = input;
  const [projectInputs, setProjectInputs] = useState<ProjectRoleInput[]>([preSelectedProjectInput]);
  const [showEmailError, setShowEmailError] = useState(false);
  const addCompanyEmployee = useAddCompanyEmployeeMutation();
  const [nextKey, setNextKey] = useState(1);
  const sendAnalytics = useSendAnalytics();

  // Disable the add permission button if there are empty fields, or all projects have been assigned.
  const disableAddPermission =
    !companyProjectRoles || projectInputs.length >= companyProjectRoles.length;
  const hasPartialPermission = projectInputs.some((p) => !!p.id !== !!p.name);
  const isCompanyEmail = isValidEmail(email) && company.domain && email.endsWith(company.domain);
  const isExistingUser = companyUsers.some((companyUser) => companyUser?.user?.email === email);
  const isValidInput =
    email && isCompanyEmail && !isExistingUser && companyRoleName && !hasPartialPermission;
  const onClose = () => {
    setDialogOpen(false);
    setInput({
      email: '',
      companyRoleName: defaultCompanyRoleName,
      projectIDs: [],
      projectRoleNames: [],
    });
    setProjectInputs([{ id: '', name: '', key: 0 }]);
    setShowEmailError(false);
    setSubmitted(false);
  };

  const onSubmit = () => {
    if (!submitted && isValidInput) {
      setSubmitted(true);
      const projectIDs: UUID[] = [];
      const projectRoleNames: string[] = [];
      const projectNames: string[] = [];
      projectInputs.forEach((p) => {
        if (!p.id || !p.name) return;
        projectIDs.push(p.id);
        projectRoleNames.push(p.name);
        const project = companyProjectRoles.find((c) => c.id === p.id);
        if (project?.name) projectNames.push(project?.name);
      });

      const onSuccess = (value: CompanyUser) => {
        if (value.user && onCreateEmployee) onCreateEmployee(value.user, projectNames);
      };

      addCompanyEmployee({ ...input, projectIDs, projectRoleNames }, onSuccess);
      onClose();
    }
  };

  const companyRoleOptions = companyRoles
    ?.filter((r) =>
      allRoles(hasBenchmarkingFeature).some(
        (a) =>
          a.companyRole === r.name &&
          (!a.entitlement || moduleEntitlementFlags.find((m) => m === a.entitlement))
      )
    )
    .map((r) => {
      return {
        id: r.name,
        name: r.name,
        label: r.name,
      };
    });

  const emailError = isExistingUser
    ? 'User with this email already exists.'
    : `Please provide a ${company ? company.domain : 'valid'} email address.`;

  return (
    <Dialog
      footerLeft={
        <Button
          data-cy="cancel-button"
          label="Cancel"
          onClick={() => {
            onClose();
            sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_CANCEL));
          }}
          type="secondary"
        />
      }
      footerRight={
        <Button
          data-cy="add-button"
          isDisabled={!isValidInput}
          label="Add Employee"
          onClick={() => {
            onSubmit();
            sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_ADD_CTA));
          }}
          type="primary"
        />
      }
      isOpen={open}
      onClose={() => {
        onClose();
        sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.NEW_MEMBER_CLOSE));
      }}
      title="Add New Employee"
    >
      <DialogContent>
        <div className="flex flex-col">
          <div className="flex flex-col gap-3">
            <TextInput
              autoFocus
              errorMessage={showEmailError ? emailError : ''}
              id="email"
              label="Email *"
              onBlur={() => {
                setShowEmailError(!!email && (!isCompanyEmail || isExistingUser));
              }}
              onChange={(value) => {
                setInput({ ...input, email: value });
              }}
              placeholder="Email"
              value={email}
            />
            <TextInput
              id="full-name"
              label="Full Name"
              onChange={(value) => {
                setInput({ ...input, name: value });
              }}
              placeholder="Full Name"
              value={name || ''}
            />
            <TextInput
              id="job-title"
              label="Job Title"
              onChange={(value) => {
                setInput({ ...input, jobTitle: value });
              }}
              placeholder="Job Title"
              value={jobTitle ?? undefined}
            />
          </div>
          <div className="pb-2 pt-4 type-heading3">Company Permissions</div>
          <Select
            aria-label="company role"
            data-cy="company-role-select"
            entries={companyRoleOptions}
            label="Company Role"
            onChange={(roleName: string) => {
              setInput({ ...input, companyRoleName: roleName });
            }}
            value={input.companyRoleName}
          />
          <div className="pb-2 pt-4 type-heading3">Project Permissions</div>
          <div className="flex pb-0.5 pr-10 type-label">
            <div className="w-1/2 pr-10">Project Name</div>
            <div className="w-1/2">
              {projectInputs.find((p) => p.id !== '') ? 'Project Role *' : 'Project Role'}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {projectInputs.map(({ id: projectID, name: roleName, key }, index) => {
              // Filter out projects that have already been selected.
              const projectOptions = companyProjectRoles
                ?.filter(
                  (option) =>
                    !projectInputs?.filter((_x, i) => index !== i).find((p) => p.id === option.id)
                )
                // Truncate name length
                .map((option) => ({ ...option, label: option.name.slice(0, 80) }));
              // Find the project roles for the selected project.
              const projectRoleOptions = companyProjectRoles
                ?.find((p) => p.id === projectID)
                ?.roles.map((r) => {
                  return {
                    id: r.name,
                    name: r.name,
                    label: r.name,
                  };
                });
              const isDeleteProjectRowDisabled = index === 0 && selectedProjectID !== undefined;

              return (
                <div key={key} className="flex items-end gap-1">
                  <Select
                    data-cy="project-select"
                    entries={projectOptions}
                    isDisabled={index === 0 && selectedProjectID !== undefined}
                    isSearchable
                    onChange={(newProjectID: string) => {
                      setProjectInputs((prev) => {
                        const result = prev.slice();
                        result[index].id = newProjectID;
                        return result;
                      });
                    }}
                    placeholder="Select Project"
                    value={projectID}
                  />
                  <Select
                    data-cy="project-role-select"
                    entries={projectRoleOptions}
                    onChange={(newRoleName: string) => {
                      setProjectInputs((prev) => {
                        const result = prev.slice();
                        result[index].name = newRoleName;
                        return result;
                      });
                    }}
                    placeholder="Select Role"
                    value={roleName}
                  />

                  <IconButton
                    aria-label="delete project row"
                    data-cy="delete-button"
                    icon={<Icon name="delete" />}
                    isDisabled={isDeleteProjectRowDisabled}
                    onClick={() => {
                      if (projectInputs.length === 1) {
                        setProjectInputs([{ id: '', name: '', key: 0 }]);
                      } else {
                        setProjectInputs((prev) => {
                          const result = prev.slice();
                          result.splice(index, 1);
                          return result;
                        });
                      }
                      sendAnalytics(
                        companyAdminAnalyticsEvent(
                          CompanyAdminEventType.NEW_MEMBER_DELETE_PROJECT_PERMISSION
                        )
                      );
                    }}
                    type="destructive"
                  />
                </div>
              );
            })}
          </div>
          <div className="pt-2">
            <Button
              data-cy="add-project-permission-button"
              isDisabled={disableAddPermission}
              label="Add Project"
              onClick={() => {
                setProjectInputs([...projectInputs, { id: '', name: '', key: nextKey }]);
                setNextKey(nextKey + 1);
                sendAnalytics(
                  companyAdminAnalyticsEvent(
                    CompanyAdminEventType.NEW_MEMBER_ADD_PROJECT_PERMISSION
                  )
                );
              }}
              type="secondary"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DialogsAddCompanyEmployee;
