import { Outlet } from 'react-router-dom';

import { DD_TIMELINE_V2 } from '../../features';
import HasFeatureFlag from '../HasFeatureFlag';
import Timeline from '../Timeline/Timeline';

export default function TimelineRoute() {
  return (
    <HasFeatureFlag fallback={<Timeline />} flag={DD_TIMELINE_V2}>
      <Outlet />
    </HasFeatureFlag>
  );
}
