import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import { KeyboardBackspace } from '@material-ui/icons';

import { PROJECT_ITEM_TEMPLATE } from '../../../constants';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';

import styles from './ItemsTemplateLinkStyles';

type ItemsTemplateLinkProps = {
  classes: Classes<typeof styles>;
};

const ItemsTemplateLink: FC<ItemsTemplateLinkProps> = ({ classes }) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  return (
    <Link
      className={classes.templateLink}
      to={`${generateSharedPath(RouteKeys.PROJECT_SETTINGS, {
        projectId,
      })}#${PROJECT_ITEM_TEMPLATE}`}
    >
      <div data-cy="linkToDefaultItemTemplate">Default Item Template</div>
      <KeyboardBackspace className={classes.templateLinkIcon} />
    </Link>
  );
};

export default withStyles(styles)(ItemsTemplateLink);
