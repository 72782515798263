import FileSaver from 'file-saver';
import mime from 'mime';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MutationHookOptions } from '@apollo/client';

import { UPLOAD_COMPLETED } from '../../../actions/actionTypes';
import { AnalyticsEvent } from '../../../analytics/analyticsEventProperties';
import { EstimateType } from '../../../api/gqlEnums';
import JoinAPI from '../../../api/joinAPI';
import { REFETCH_PROJECT_ASSETS } from '../../../api/refetchSets';
import { AttachToItemMutation, AttachToItemMutationVariables } from '../../../generated/graphql';
import { useRefetch } from '../../../hooks/useRefetch';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useProjectID } from '../../../utilities/routes/params';
import { logErrorToSentry } from '../../../utilities/sentry';
import { updateSelectedMap } from '../../DocumentMagic/Files/FilesAssets/FilesAssetsUtils';
import { completeUpload, getActiveUploads, getAssetTempID, startUpload } from '../utils';

import {
  ItemAttachmentMutationArgs,
  formatItemAttachmentMutationInput,
  useAttachToItem,
} from './mutations/useAttachToItem';
import {
  MilestoneAttachmentMutationArgs,
  formatMilestoneAttachmentMutationInput,
  useAttachToMilestone,
} from './mutations/useAttachToMilestone';

// map of tempId to a promise for the asset upload
export const uploadPromises: Record<string, Promise<{ id: string }>> = {};

// ATTACHMENT UPLOADS

// upload and attach assets to item
export const useItemUploadAttachAssets = (
  itemID: UUID,
  analyticsEvent: AnalyticsEvent,
  options: MutationHookOptions<AttachToItemMutation, AttachToItemMutationVariables> = {}
) =>
  useUploadAttachAssetsHooks(
    analyticsEvent,
    { itemID },
    { attachAssetToItemFunc: useAttachToItem(options) }
  );

// upload and attach assets to milestone
export const useMilestoneUploadAttachAssets = (milestoneID: UUID, analyticsEvent: AnalyticsEvent) =>
  useUploadAttachAssetsHooks(
    analyticsEvent,
    { milestoneID },
    { attachAssetToMilestoneFunc: useAttachToMilestone() }
  );

// upload assets to project, do not attach
export const useProjectUploadAssets = (analyticsEvent?: AnalyticsEvent) =>
  useUploadAttachAssetsHooks(analyticsEvent);

type AttachToEntity = {
  itemID?: UUID;
  milestoneID?: UUID;
  project?: ProjectAssetInfo;
};

type AttachToEntityFunc = {
  attachAssetToItemFunc?: (args: ItemAttachmentMutationArgs) => void;
  attachAssetToMilestoneFunc?: (args: MilestoneAttachmentMutationArgs) => void;
};

// return isAttach, onAttachAsset, onDropFile, uploadingAssets functions to be used in components
const useUploadAttachAssetsHooks = (
  analyticsEvent?: AnalyticsEvent,
  attachEntity?: AttachToEntity,
  attachEntityFunc?: AttachToEntityFunc,
  onSuccess?: () => void
) => {
  const sendAnalytics = useSendAnalytics();
  const refetchProjectAssets = useRefetch(REFETCH_PROJECT_ASSETS);

  const [isAttach, setIsAttach] = useState<boolean>(false);
  const [uploadingAssets, setUploadingAssets] = useState<UploadingAsset[]>([]);
  const [currentUpload, setCurrentUpload] = useState<AssetActionType | null>(null);
  const onSuccessLocal = (id: UUID) => {
    updateSelectedMap({ [id]: true });
    refetchProjectAssets();
    setIsAttach(false);
    if (onSuccess) onSuccess();
  };

  useEffect(() => {
    if (currentUpload?.type === UPLOAD_COMPLETED) {
      const assets = uploadingAssets.filter(({ id }) => id !== currentUpload.data?.tempID);
      setUploadingAssets(assets);
      return;
    }
    setUploadingAssets(getActiveUploads(uploadingAssets, currentUpload));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [currentUpload]);

  const uploadAssets = useUploadAssets();

  // Upload without attaching
  const onDropFile = (file: File) => {
    const currentUpload: AssetActionDataWithRequiredFile = { file };
    const promise = uploadAssets(currentUpload, setCurrentUpload, undefined, onSuccessLocal);
    if (analyticsEvent) sendAnalytics(analyticsEvent);
    return promise;
  };

  // Upload and attach
  const onAttachAsset = (file: File) => {
    setIsAttach(true);
    const currentUpload: AssetActionDataWithRequiredFile = { ...attachEntity, file };
    const promise = uploadAssets(currentUpload, setCurrentUpload, attachEntityFunc, onSuccessLocal);
    if (analyticsEvent) sendAnalytics(analyticsEvent);
    return promise;
  };

  return { isAttach, onAttachAsset, onDropFile, uploadingAssets };
};

// handle upload assets, pending and completed state
// attach uploaded assets to item, milestone, or project in relevant cases
function useUploadAssets() {
  const defaultProjectId = useProjectID();

  return useCallback(
    async (
      currentUpload: AssetActionDataWithRequiredFile, // { file }
      setCurrentUpload?: (action: AssetActionType) => void,
      attachEntityFunc?: AttachToEntityFunc,
      onSuccess?: (result: UUID) => void
    ): Promise<UUID | null> => {
      // How we flag when we are in a project
      const projectId = defaultProjectId || currentUpload?.project?.id;
      try {
        const tempID = getAssetTempID(currentUpload.file.name, currentUpload.file.size);
        const tempUpload: AssetActionDataWithRequiredFile = { ...currentUpload, tempID };
        if (setCurrentUpload) setCurrentUpload(startUpload(tempUpload));

        const uploadPromise = JoinAPI.uploadAsset(projectId ?? null, currentUpload.file);
        uploadPromises[tempID] = uploadPromise;
        const uploaded = await uploadPromise;

        const onSuccessLocal = () => {
          if (setCurrentUpload) setCurrentUpload(completeUpload(tempUpload));
          if (onSuccess) onSuccess(uploaded.id);
        };

        if (
          attachEntityFunc &&
          (attachEntityFunc.attachAssetToItemFunc || attachEntityFunc.attachAssetToMilestoneFunc)
        ) {
          // attach to item or milestone
          attachAssetsToEntity(currentUpload, attachEntityFunc, uploaded.id, onSuccessLocal);
        } else {
          // complete project thumbnail upload
          if (currentUpload.project) {
            completeProjectThumbnailUpload(currentUpload, uploaded.id);
          }
          // complete upload to project
          onSuccessLocal();
        }

        return uploaded.id;
      } catch (e) {
        // handle errors
        logErrorToSentry(e);
        return null;
      }
    },
    [defaultProjectId]
  );
}

const completeProjectThumbnailUpload = (
  currentAssetData: AssetActionDataWithRequiredFile,
  assetID?: UUID
) => {
  const projectAssetUploadData = currentAssetData;
  if (assetID && projectAssetUploadData.project) {
    projectAssetUploadData.project.thumbnail = assetID;
  }
};

const attachAssetsToEntity = (
  currentUpload: AssetActionDataWithRequiredFile,
  attachEntityFunc: AttachToEntityFunc,
  assetID?: UUID,
  onSuccess?: () => void
) => {
  if (!assetID) return;
  // attach assets to item
  if (attachEntityFunc.attachAssetToItemFunc && currentUpload?.itemID) {
    attachEntityFunc.attachAssetToItemFunc({
      input: formatItemAttachmentMutationInput(assetID, currentUpload),
      onSuccess,
    });
  }
  // attach assets to milestone
  if (attachEntityFunc.attachAssetToMilestoneFunc && currentUpload?.milestoneID) {
    attachEntityFunc.attachAssetToMilestoneFunc({
      input: formatMilestoneAttachmentMutationInput(assetID, currentUpload),
      onSuccess,
    });
  }
};

// IMAGE UPLOADS

export const useUploadImage =
  (blockedTypes: string[] = []) =>
  async (
    file: File,
    projectID: UUID | null,
    onSuccess?: (asset: Asset) => void,
    onFailure?: (message: string) => void
  ): Promise<UUID | null> => {
    try {
      const type = mime.getType(file?.name);

      const validImageFile = String(type).includes('image');
      // Check that the file type is allowed
      let blockedFileType = false;
      blockedTypes.forEach((blockedType) => {
        if (String(type).includes(blockedType)) blockedFileType = true;
      });

      if (!validImageFile) {
        onFailure?.('Sorry, not a valid image.');
      } else if (blockedFileType) {
        onFailure?.('Sorry, not an accepted type.');
      } else {
        const uploadPromise = JoinAPI.uploadAsset(projectID, file);
        const uploaded = await uploadPromise;
        onSuccess?.(uploaded); // we write the location to user.picture
        return uploaded.id;
      }
    } catch (e) {
      const response = 'Something went wrong uploading the image';
      // handle errors
      logErrorToSentry(e);
      onFailure?.(response);
    }

    return null;
  };

// CATEGORY IMPORTS

export const useImportCategories = () => {
  const uploadCategoriesFunc = async (
    file: File,
    categorizationID?: UUID,
    projectID?: UUID,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess?: (result: any) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onFailure?: (e: any) => any
  ) => {
    try {
      const uploadPromise = JoinAPI.importCategories(file, categorizationID, projectID);
      const res = await uploadPromise;
      if (res) {
        if (onSuccess) onSuccess(res);
      }
    } catch (err) {
      logErrorToSentry(err);
      if (onFailure) onFailure(err);
    }
  };

  return uploadCategoriesFunc;
};

// ITEM IMPORTS

export const useImportItems = () => {
  return async (
    file: File,
    projectID?: UUID,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess?: (result: any) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onFailure?: (e: any) => any
  ) => {
    try {
      const uploadPromise = JoinAPI.importItems(file, projectID);
      const res = await uploadPromise;
      if (res) {
        if (onSuccess) onSuccess(res);
      }
    } catch (err) {
      logErrorToSentry(err);
      if (onFailure) onFailure(err);
    }
  };
};

// ESTIMATE IMPORTS

type AssetImportEstimateActionType = Omit<AssetActionType, 'data'> & {
  data?: AssetActionData & { estimateType: EstimateType };
};

export function useImportEstimate() {
  const defaultProjectId = useProjectID();
  return useMemo(
    () => [
      async (
        action: AssetImportEstimateActionType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        onSuccess: (result: any) => void,
        onFailure: (e: { response: string }) => void
      ) => {
        const projectId = defaultProjectId || action.data?.project?.id;
        if (action.data?.file && action.data?.milestoneID && projectId) {
          try {
            const tempId = `${action.data.file.name}/${action.data.file.size}`;
            const {
              data: { file, milestoneID, estimateType },
            } = action;
            const uploadPromise = JoinAPI.importEstimate(
              projectId,
              file,
              milestoneID,
              estimateType
            );
            uploadPromises[tempId] = uploadPromise;
            const uploaded = await uploadPromise;
            // if there was an error then run on Failure intead
            if (uploaded.error) {
              // convert this reponse into the normal error format
              const result = { response: uploaded.error, status: 400 };
              logErrorToSentry(result);
              if (onFailure) {
                onFailure(result);
              }
            } else {
              const onSuccessLocal = () => {
                if (onSuccess) onSuccess(uploaded);
              };
              // milestone estimate
              onSuccessLocal();
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          } catch (e: any) {
            logErrorToSentry(e);
            // handle errors
            if (onFailure) {
              onFailure(e);
            }
          }
        }
      },
    ],
    [defaultProjectId]
  );
}

// ASSET DOWNLOADS

export function useDownloadAsset() {
  const submitFunc = async (
    location: string,
    name: string,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    try {
      const url = await JoinAPI.requestAssetBlobURL(location, name);
      FileSaver.saveAs(url, name);
      if (onSuccess) onSuccess();
    } catch (e) {
      // handle errors
      logErrorToSentry(e);
      if (onFailure) onFailure();
    }
  };

  return [submitFunc];
}
