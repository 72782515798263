import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { CardHeader, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import GetApp from '@material-ui/icons/GetApp';

import {
  EDIT_ESTIMATE_CTA,
  IMPORT_ESTIMATE_DOWNLOAD_CTA,
  PREVIEW_ESTIMATE_CTA,
} from '../../../actions/actionTypes';
import {
  analyticsEvent,
  deleteEstimateCTA,
  downloadAsset,
  publishEstimateCTA,
} from '../../../analytics/analyticsEventProperties';
import { helpTipTrackerVar, importEstimateIsPublishedVar } from '../../../api/apollo/reactiveVars';
import { EstimateType } from '../../../api/gqlEnums';
import { REFETCH_CHANGE_MILESTONE_EVENTS_LIST } from '../../../api/refetchSets';
import { DRAFT, EDIT_ESTIMATE, PREVIEW_MSR } from '../../../constants';
import { EstimateArmatureQuery, GetMilestoneQuery } from '../../../generated/graphql';
import { useRefetch } from '../../../hooks/useRefetch';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import '../../../theme/content-list-layout.scss';
import { RouteKeys } from '../../../routes/paths';
import theme from '../../../theme/design-system-mui-theme';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { useDownloadAsset } from '../../assets/hooks/AssetsMutationHook';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button } from '../../scales';
import SelectFilterMenu from '../../Select/SelectFilterMenu/SelectFilterMenu';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import { useApplyMilestoneDraftEstimate, useDeleteMilestoneDraftEstimate } from '../hooks';

import styles from './ImportEstimateHeaderStyles';

type ImportEstimateHeaderProps = {
  classes: Classes<typeof styles>;
  canPublishDraftEstimate: boolean;
  draftEstimate: NonNullable<EstimateArmatureQuery['estimate']>;
  editTitle: string;
  estimateAssetID: UUID;
  estimateHandle: string;
  milestone: GetMilestoneQuery['milestone'];
  filterKey: string;
  setFilterKey: (filter: string) => void;
  issuesComponent: JSX.Element;
  settingsComponent: JSX.Element;
  refetchMilestone: () => void;
};

type SelectFilterMenuData = { title: string };

const ImportEstimateHeader: FC<ImportEstimateHeaderProps> = ({
  classes,
  canPublishDraftEstimate,
  draftEstimate,
  editTitle,
  estimateAssetID,
  estimateHandle,
  milestone,
  filterKey,
  setFilterKey,
  issuesComponent,
  settingsComponent,
  refetchMilestone,
}) => {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();

  // SELECT FILTER MENU
  const selectFilterMenuData = [
    { title: editTitle },
    { title: PREVIEW_MSR },
  ] as SelectFilterMenuData[];

  const { milestoneId, projectId } = useParams();
  if (!projectId || !milestoneId) {
    throw new Error('Project ID or Milestone ID not found');
  }
  const milestoneName = milestone?.name;

  const estimateID = draftEstimate && draftEstimate.id;
  const helpTipTrackerInfo = useReactiveVar(helpTipTrackerVar);
  const estimateWasImported = helpTipTrackerInfo.importedEstimateIDs.find(
    (id) => id === estimateID
  );
  const showHelpTip = estimateWasImported && helpTipTrackerInfo.estimateImportCount <= 3;

  const [isApplyEstimateDialog, setIsApplyEstimateDialog] = useState(false);
  const [isDeleteEstimateDialog, setIsDeleteEstimateDialog] = useState(false);

  const onDeleteMilestoneDraftQuery = () => {
    setIsDeleteEstimateDialog(false);
    refetchMilestone();
    navigate(
      generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, { projectId, milestoneId })
    );
  };

  const [deleteMilestoneDraftEstimate] = useDeleteMilestoneDraftEstimate({
    onCompleted: onDeleteMilestoneDraftQuery,
    onError: onDeleteMilestoneDraftQuery,
  });

  const onDeleteMilestoneDraftEstimateApi = (estimateId: UUID) => {
    deleteMilestoneDraftEstimate(projectId, estimateId);
    sendAnalytics(deleteEstimateCTA(estimateId));
  };

  const refetchSidebar = useRefetch(REFETCH_CHANGE_MILESTONE_EVENTS_LIST);

  const [applyMilestoneDraftEstimate] = useApplyMilestoneDraftEstimate();
  const onApplyMilestoneDraftEstimateApi = (estimateId: UUID, estimateType: EstimateType) => {
    applyMilestoneDraftEstimate(
      projectId,
      estimateId,
      () => {
        // SUCCESS
        setIsApplyEstimateDialog(false);
        refetchSidebar();
        navigate(
          generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, { projectId, milestoneId })
        );
        importEstimateIsPublishedVar(true);
      },
      () => {
        // FAILURE
        setIsApplyEstimateDialog(false);
        navigate(
          generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, { projectId, milestoneId })
        );
      }
    );
    sendAnalytics(publishEstimateCTA(estimateId, estimateType, !!draftEstimate.isRunningTotal));
  };

  const asset = milestone?.importedEstimates.find((a) => a.id === estimateAssetID);
  const [downloadAssetFunc] = useDownloadAsset();
  const downloadAssetLocal = (assetForDownload: { location: string; name: string }) => {
    downloadAssetFunc(
      assetForDownload.location,
      assetForDownload.name,
      () => {},
      () => {}
    );
    sendAnalytics(downloadAsset(IMPORT_ESTIMATE_DOWNLOAD_CTA));
  };

  let replacementWarning = '';
  if (
    (estimateHandle === 'Estimate' && milestone && milestone.activeEstimateID) ||
    (estimateHandle === 'Budget' && milestone && milestone.budgetID)
  ) {
    replacementWarning = `, and replace the current ${estimateHandle.toLowerCase()} for the milestone`;
  }
  const menuOptions = [];
  if (asset?.name) {
    menuOptions.push({
      name: 'Download',
      callback: () => downloadAssetLocal(asset),
      icon: <GetApp />,
    });
  }
  menuOptions.push({
    color: theme.palette.ds.type.delete,
    name: 'Delete',
    callback: () => setIsDeleteEstimateDialog(true),
  });

  const getEstimateName = () => (asset?.name ? `(${asset.name})` : '');

  return (
    <>
      <CardHeader
        title={
          <div className={classes.header}>
            <div className={classes.title}>
              <Breadcrumbs
                links={[
                  {
                    display: 'Milestones',
                    destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES, { projectId }),
                    tooltip: 'Back to milestones list',
                  },
                  {
                    display: milestoneName || 'Loading...',
                    destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
                      projectId,
                      milestoneId,
                    }),
                    tooltip: 'Back to milestone details',
                  },
                ]}
              />
              <div className={classes.wrapper}>
                <Typography variant="headline">{`Draft ${estimateHandle} ${getEstimateName()}`}</Typography>
                <NormalTooltip title="Until you publish this estimate, it is only visible to you and is not used in any other views.">
                  <p className={classes.draftIcon}>{DRAFT}</p>
                </NormalTooltip>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {showHelpTip ? (
                <ShadedHelpTip
                  shiftLeft
                  tip={`${estimateHandle}s are only viewable to others after they are Published`}
                />
              ) : null}
              <div className={classes.headerOptions}>
                <Button
                  data-cy="publish-button"
                  isDisabled={!canPublishDraftEstimate}
                  label="Publish"
                  onClick={() => {
                    setIsApplyEstimateDialog(true);
                  }}
                  startIcon={<DoneIcon className={classes.actionIcon} />}
                  type="primary"
                />
              </div>
            </div>
          </div>
        }
      />
      <Typography className={classes.printHeader} variant="subheading">
        {`Draft ${estimateHandle} ${getEstimateName()}`}
      </Typography>
      <div className={classes.subHeader}>
        <div className={classes.selectFilterMenu}>
          <SelectFilterMenu
            data={selectFilterMenuData}
            filterKey={filterKey}
            label="View"
            onClick={(filter: string) => {
              if (filter === EDIT_ESTIMATE) {
                sendAnalytics(analyticsEvent(EDIT_ESTIMATE_CTA));
              } else {
                sendAnalytics(analyticsEvent(PREVIEW_ESTIMATE_CTA));
              }
              setFilterKey(filter);
            }}
          />
        </div>
        {issuesComponent}
        <div className={classes.controlHeader}>
          {showHelpTip ? (
            <ShadedHelpTip
              tip={
                <div className={classes.wrapper}>
                  <Typography className={classes.shadedTipText}>
                    <b>Tip:&nbsp;</b>
                  </Typography>
                  <Typography className={classes.shadedTipText}>
                    Markup calculations are not included in the import. Don&apos;t forget to{' '}
                    <a
                      className={classes.link}
                      href="https://success.join.build/en/knowledge/markups-overview"
                      target="blank"
                    >
                      allocate your markups
                    </a>{' '}
                    in the &apos;Applies to...&apos; column below!
                  </Typography>
                </div>
              }
            />
          ) : null}
          {settingsComponent}
          <IconMenu
            className={filterKey === PREVIEW_MSR ? classes.iconMenu : undefined}
            id={classes.menu}
            options={menuOptions}
          />
        </div>
      </div>
      <DialogsConfirm
        body={`Use this ${estimateHandle.toLowerCase()} for ${
          milestone ? milestone.name : 'this milestone'
        }. This new ${estimateHandle.toLowerCase()} will be visible to anyone who can see cost details for milestones${replacementWarning}.`}
        onClose={() => setIsApplyEstimateDialog(false)}
        onConfirm={() => {
          if (estimateID) {
            onApplyMilestoneDraftEstimateApi(
              estimateID,
              estimateHandle === 'Estimate' ? EstimateType.ACTIVE_ESTIMATE : EstimateType.BUDGET
            );
          }
        }}
        open={isApplyEstimateDialog}
        title={`Publish new ${estimateHandle.toLowerCase()}`}
      />
      <DialogsConfirm
        acceptCtaCopy="Delete"
        body={`Your draft ${estimateHandle.toLowerCase()} will be deleted`}
        destructiveConfirm
        onClose={() => setIsDeleteEstimateDialog(false)}
        onConfirm={() => {
          if (estimateID) {
            onDeleteMilestoneDraftEstimateApi(estimateID);
          }
        }}
        open={isDeleteEstimateDialog}
        title={`Draft ${estimateHandle} ${getEstimateName()}`}
      />
    </>
  );
};

export default withStyles(styles)(ImportEstimateHeader);
