import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Cached } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { capitalizeString } from '../../../utilities/string';
import { GridType } from '../../JoinGrid/types';
import { BabyButton, Icon, Tooltip } from '../../scales';

import AddRemoveRowButtonsStyles from './AddRemoveRowButtonsStyles';
import MiniEstimateButton from './MiniEstimateButton';

type AddRemoveRowButtonsProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabledDelete: boolean;
  gridType?: GridType;
  classes: Classes<typeof AddRemoveRowButtonsStyles>;
  onClick?: () => void;
  onClickDelete?: () => void;
  onClickReplace?: () => void;
  tooltip?: string;
  variant?: string;
};

const AddRemoveRowButtons: FC<AddRemoveRowButtonsProps> = ({
  disabled,
  disabledDelete,
  gridType,
  classes,
  onClick,
  onClickDelete,
  onClickReplace,
  tooltip,
  variant = 'row',
}) => {
  const variantName = capitalizeString(variant);

  return (
    <div className="flex items-center justify-start gap-2 px-1 py-2 print:hidden">
      {onClickDelete && (
        <MiniEstimateButton
          data-cy={`${gridType}-delete-rows-button`}
          isDisabled={disabledDelete}
          label={`Delete Selected ${variantName}s`}
          onClick={onClickDelete}
          startIcon={<Icon name="delete" size="sm" />}
          type="destructive"
        />
      )}
      {onClick && (
        <>
          <Tooltip content={tooltip || ''}>
            <MiniEstimateButton
              data-cy={`${gridType}-add-row-button`}
              isDisabled={disabled}
              label={`Add a ${variantName}`}
              onClick={onClick}
              startIcon={<Icon name="add" size="sm" />}
              type="primary"
            />
          </Tooltip>
          <Typography variant="body2">
            or hit
            <span className={classes.key}>Enter</span>
            in the last row to add another.
          </Typography>
        </>
      )}
      {onClickReplace && (
        <Tooltip content="Replace categories">
          <BabyButton aria-label="Replace categories" icon={<Cached />} onClick={onClickReplace} />
        </Tooltip>
      )}
    </div>
  );
};

export default withStyles(AddRemoveRowButtonsStyles)(AddRemoveRowButtons);
