import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Check } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../../utilities/routes/links';
import { useProjectID } from '../../../utilities/routes/params';
import {
  useProjectTemplateQuery,
  useSetProjectTemplatePublicationMutation,
  useUpdateProjectTemplateNameMutation,
} from '../../CompanyTab/Standards/ProjectTemplates/hooks';
import { Button, Chip, TextInput } from '../../scales';

import ModeBar from './ModeBar';

export default function EditProjectTemplateModeBar() {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();

  const projectID = useProjectID();
  if (!projectID) {
    throw new Error('Failed to get projectID param');
  }

  const [templateName, setTemplateName] = useState('');
  // Call
  const template = useProjectTemplateQuery(projectID, {
    onCompleted: (data) => setTemplateName(data.projectTemplate.name),
  }).data?.projectTemplate;

  const onUpdateTemplateName = useUpdateProjectTemplateNameMutation();
  const onSetProjectTemplatePublication = useSetProjectTemplatePublicationMutation(projectID);

  return (
    <ModeBar data-cy="template-bar" title="Edit project template">
      <div className="w-75">
        <TextInput
          aria-label="template name"
          data-cy="project-template-name-text-input"
          endAdornment={template?.isDraft && <Chip text="DRAFT" />}
          onBlur={() => {
            if (template) {
              onUpdateTemplateName(template.id, templateName);
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_EDIT_NAME)
              );
            }
          }}
          onChange={setTemplateName}
          value={templateName}
        />
      </div>
      <div className="ml-auto flex gap-2">
        {template?.isDraft && (
          <Button
            data-cy="template-publish-button"
            label="Publish"
            onClick={() => {
              sendAnalytics(
                companyAdminAnalyticsEvent(
                  CompanyAdminEventType.PROJECT_TEMPLATES_EDIT_PUBLISH_DRAFT
                )
              );
              onSetProjectTemplatePublication(true);
            }}
            startIcon={<Check />}
            type="secondary"
          />
        )}
        <Button
          data-cy="template-done-button"
          label="Done"
          onClick={() => {
            sendAnalytics(
              companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_EDIT_DONE)
            );
            navigate(generateSharedPath(JoinCompanyRoutes.COMPANY_STANDARDS, {}));
          }}
          type="primary"
        />
      </div>
    </ModeBar>
  );
}
