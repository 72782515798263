import { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useIsRouteAccessible } from '../../hooks/useIsRouteAccessible';
import { RootRoutes } from '../../routes/routes';
import { useProjectID } from '../../utilities/routes/params';
import { getSubRoutes } from '../../utilities/routes/routes';
import FourOhFour from '../FourOhFour';
import { useHasProjectAccess } from '../ProjectRoute/hooks/useHasProjectAccess';
import { routeToAppComponent } from '../RouteToAppComponent';

export default function Router() {
  const { isAccessible, loading: routeLoading } = useIsRouteAccessible();

  const routes = useMemo(
    () => getSubRoutes(RootRoutes, routeToAppComponent, isAccessible),
    [isAccessible]
  );
  const projectID = useProjectID();
  const { loading, hasAccess } = useHasProjectAccess(projectID);
  if (loading || routeLoading) return null;
  if (!hasAccess) return <Navigate replace to="/404" />;
  return (
    <Routes>
      {routes}
      <Route element={<FourOhFour />} path="*" />
    </Routes>
  );
}
