/* eslint-disable react/no-array-index-key */
import { FC, Fragment } from 'react';
import 'react-vis/dist/style.css';
import { Link, useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import {
  ITEM_NUMBER_CHARLIMIT,
  KIND_COPIED_ITEM,
  KIND_EVENT_DESCRIPTION_NEW_LINE,
  KIND_EVENT_DESCRIPTION_STATUS,
  KIND_EVENT_DESCRIPTION_TEXT,
  KIND_EVENT_ITEM_LIKE_SUMMARY,
  KIND_EVENT_PRIVATE_ITEM_LIKE_SUMMARY,
  KIND_ITEM,
  KIND_MEETING,
  KIND_MILESTONE,
  KIND_OPTION,
  VISIBILITY_PRIVATE_ITEM,
} from '../../../constants';
import { Status } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeString } from '../../../utilities/string';
import ItemsQuickLink from '../../Items/ItemsQuickLink/ItemsQuickLink';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { HIDDEN, SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import MeetingQuickLink from '../MeetingQuickLink/MeetingQuickLink';
import MilestoneQuickLink from '../MilestoneQuickLink/MilestoneQuickLink';

import styles from './EventsDescriptionStyles';

type Props = {
  classes: Classes<typeof styles>;
  charNameLimit: number;
  description: EventDescription[];
  isItemHistory?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  variant: any;
};
const EventsDescription: FC<Props> = ({
  classes,
  description,
  charNameLimit,
  isItemHistory = false,
  variant,
}) => {
  const { projectId } = useParams();

  return (
    <>
      {description &&
        description.length > 0 &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        description.map((d: any, i) => {
          switch (d.kind) {
            case KIND_EVENT_DESCRIPTION_TEXT:
              return (
                <Typography
                  key={d.text + i}
                  className={classes.inline}
                  data-cy="text-eventDescription"
                  variant={variant}
                >
                  {d.text}
                </Typography>
              );
            case KIND_EVENT_DESCRIPTION_NEW_LINE:
              return <div key={`newLine ${i}`} className={classes.overflow} />;
            case KIND_EVENT_DESCRIPTION_STATUS:
              return !isItemHistory ? (
                <Fragment key={`${d.text + i}`}>
                  <ItemsIcons key={`d.text ${i}`} status={d.status} variant={SMALL} />
                  <Typography key={d.text + i} className={classes.statusText} variant={variant}>
                    {d.text}
                  </Typography>
                </Fragment>
              ) : (
                <Fragment key={`${d.text + i}`}>
                  <Typography key={d.text + i} className={classes.inline} variant={variant}>
                    {d.text}
                  </Typography>
                </Fragment>
              );
            case KIND_MEETING:
              return (
                <MeetingQuickLink
                  key={`${d.meeting.id}${d.meeting.name}`}
                  isExpanded={false}
                  meeting={d.meeting}
                />
              );
            case KIND_MILESTONE:
              // If we have project id, then we are looking at the editable item sidebar
              // otherwise we are outside of the project, observing forecasting or home page sidebars
              return projectId ? (
                <MilestoneQuickLink key={JSON.stringify(d)} milestone={d} />
              ) : (
                <div key={d.id}>
                  <Typography key={d.text + i} variant={variant}>
                    {d.name}
                  </Typography>
                </div>
              );
            case KIND_COPIED_ITEM:
              // this link is styled to look similar to the MilestoneQuickLink and ItemQuickLink
              if (!d.copiedItemLike.hasAccess)
                return (
                  <div
                    key={`${JSON.stringify(d)}${i}`}
                    className="inline-flex items-end type-body2"
                  >
                    {d.copiedItemLike.name}
                  </div>
                );
              return (
                <Link
                  key={`${JSON.stringify(d)}${i}`}
                  className="inline-flex items-end underline type-body2 hover:text-type-link"
                  to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                    projectId: d.copiedItemLike.projectID,
                    itemId: d.copiedItemLike.id,
                  })}
                >
                  {d.copiedItemLike.name}
                </Link>
              );
            case KIND_ITEM:
            case KIND_OPTION:
              return (
                <div key={`${JSON.stringify(d)}${i}`} className={classes.item}>
                  <ItemsQuickLink
                    key={`${JSON.stringify(d)} ${i}`}
                    charNameLimit={charNameLimit}
                    charNumberLimit={ITEM_NUMBER_CHARLIMIT}
                    ellipses
                    hasDefaultWidth={false}
                    isVariance={isItemHistory} // reusing existing boolean: do not show status
                    item={d as ItemLike}
                    showNumberSign
                    textVariant={variant}
                    variant={d ? SMALL : HIDDEN}
                  />
                </div>
              );
            case KIND_EVENT_ITEM_LIKE_SUMMARY:
              return (
                <div key={`${JSON.stringify(d)}${i}`} className={classes.item}>
                  <ItemsQuickLink
                    key={`${JSON.stringify(d)} ${i}`}
                    charNameLimit={charNameLimit}
                    charNumberLimit={ITEM_NUMBER_CHARLIMIT}
                    ellipses
                    hasDefaultWidth={false}
                    isVariance={isItemHistory}
                    item={{
                      // set some defaults for required properties if they don't exist
                      name: '',
                      number: '',
                      status: Status.PENDING,
                      ...(d as EventDescriptionItemLikeSummary).itemLike,
                    }}
                    showNumberSign
                    textVariant={variant}
                    variant={d ? SMALL : HIDDEN}
                  />
                </div>
              );
            case KIND_EVENT_PRIVATE_ITEM_LIKE_SUMMARY: {
              const { count } = d as EventDescriptionPrivateItemLikeSummary;
              const countString = count === 1 ? 'a' : `${count}`;
              return (
                <div key={`${JSON.stringify(d)}${i}`} className={classes.item}>
                  <Typography
                    key={`${JSON.stringify(d)} ${i}`}
                  >{` ${countString} ${VISIBILITY_PRIVATE_ITEM.toLowerCase()} ${pluralizeString(
                    'option',
                    count
                  )}`}</Typography>
                </div>
              );
            }
            default:
              return null;
          }
        })}
    </>
  );
};
export default withStyles(styles)(EventsDescription);
