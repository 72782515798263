import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

export const TIMELINE_BOTTOM_TOOLTIP_ID = 'timeline-bottom-tooltip';

type Data = { date: Date; value: number };

const DEFAULT_HOVER_SIZE = 1;

export type Props = {
  content: ReactNode;
  data: Data;
  offsetY?: number;
  onMouseLeave: () => void;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
};

export default function TimelineBottomPortal(props: Props) {
  const elementRef = useRef<SVGCircleElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });
    if (tooltipRef.current) ro.observe(tooltipRef.current);
    return () => ro.disconnect();
  }, []);

  useEffect(() => {
    if (elementRef.current && width !== 0) {
      const rect = elementRef.current.getBoundingClientRect();
      setX(rect.left + window.scrollX - width / 2);
      setY(rect.top + window.scrollY);
    }
  }, [width]);

  const hoverAttrs = {
    cx: props.x(props.data.date) ?? 0,
    cy: props.y(props.data.value) ?? 0,
    opacity: 0,
    r: DEFAULT_HOVER_SIZE,
  };

  const portal = ReactDOM.createPortal(
    <div
      ref={tooltipRef}
      className="absolute z-50"
      id={TIMELINE_BOTTOM_TOOLTIP_ID}
      onMouseLeave={(e) => {
        // We use id to keep tooltip on the screen
        if (
          e.relatedTarget instanceof Element &&
          e.relatedTarget?.id === TIMELINE_BOTTOM_TOOLTIP_ID
        )
          return;
        props.onMouseLeave();
      }}
      style={{
        top: `${y}px`,
        left: `${x}px`,
        paddingTop: props.offsetY,
        opacity: width === 0 ? 0 : 1,
      }}
    >
      <div className="rounded-md border-border-muted bg-background-1 px-3 py-2 shadow">
        {props.content}
      </div>
    </div>,
    document.body
  );

  return (
    <>
      <circle ref={elementRef} {...hoverAttrs} />
      {portal}
    </>
  );
}
