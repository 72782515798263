import { Navigate, useLocation, useOutlet } from 'react-router-dom';

import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';

import ForecastingHeader from './ForecastingHeader';

export default function ForecastingTab() {
  const { pathname } = useLocation();

  const outlet = useOutlet();
  if (!outlet) {
    return <Navigate replace to={generateSharedPath(RouteKeys.BENCHMARKING_REPORTS)} />;
  }

  const hideHeader = shouldHideForecastingHeader(pathname);
  if (hideHeader) return outlet;

  return (
    <div className="flex h-full flex-col overflow-hidden bg-background-primary">
      <ForecastingHeader />
      {outlet}
    </div>
  );
}

const shouldHideForecastingHeader = (pathname: string = window.location.pathname) => {
  return pathname.split('/').includes('project-comparison');
};
