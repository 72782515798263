import TimelineBottomPortal, { Props as TimelineBottomPortalProps } from '../TimelineBottomPortal';
import TimelineBottomTooltip, {
  Props as TimelineBottomTooltipProps,
} from '../TimelineBottomTooltip';

type Props = TimelineBottomTooltipProps & TimelineBottomPortalProps & { isMiniChart: boolean };

export default function ItemsBarTooltip(props: Props) {
  return (
    <>
      {props.isMiniChart ? (
        <TimelineBottomTooltip {...props} />
      ) : (
        <TimelineBottomPortal {...props} />
      )}
    </>
  );
}
