import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { REFETCH_ITEM, refetchItem } from '../../../api/refetchSets';
import { useRefetch } from '../../../hooks/useRefetch';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../../utilities/clickHandler';
import { generateSharedPath } from '../../../utilities/routes/links';
import { useProjectID } from '../../../utilities/routes/params';
import CommentHoverWrapper from '../CommentHoverWrapper/CommentHoverWrapper';
import ItemReferencePreview from '../ItemReferencePreview/ItemReferencePreview';

import ItemReferenceDisplayStyles from './ItemReferenceDisplayStyles';

const DEPRECATED_ITEM_TEXT = 'This item is no longer available';
const HOVER_EXIT_DELAY = 600;

type ItemReferenceDisplayProps = {
  item?: ItemsListItem;
  displayText: string;
  classes: Classes<typeof ItemReferenceDisplayStyles>;
  isPrint?: boolean;
  referenceID: string;
  hoveredID?: string | null;
  setHoveredID?: (id: string | null) => void;
  curHoverTimeout?: NodeJS.Timeout | null;
  setCurHoverTimeout?: (timeout: NodeJS.Timeout | null) => void;
};

const ItemReferenceDisplay = ({
  item,
  displayText,
  classes,
  isPrint,
  referenceID,
  hoveredID,
  setHoveredID,
  curHoverTimeout,
  setCurHoverTimeout,
}: ItemReferenceDisplayProps) => {
  const navigate = useNavigate();
  const projectID = useProjectID();
  const refetch = useRefetch(REFETCH_ITEM);

  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  // have a delay before removing the hover state
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const mouseEnter = (event: any) => {
    if (setHoveredID) setHoveredID(referenceID);
    setAnchorEl(event.currentTarget);
    if (setCurHoverTimeout && curHoverTimeout) {
      setCurHoverTimeout(null);
      clearTimeout(curHoverTimeout);
    }
  };

  const mouseLeave = () => {
    if (setCurHoverTimeout)
      setCurHoverTimeout(
        setTimeout(() => {
          if (setHoveredID) {
            setHoveredID(null);
          }
          setAnchorEl(null);
        }, HOVER_EXIT_DELAY)
      );
  };

  const navigateToItem = (e: MouseEvent) => {
    if (item) {
      const path = generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
        projectId: projectID,
        itemId: item.id,
      });
      if (path) {
        clickLinkKeyHandler(navigate, e, path, '');
        if (item.id) refetch([refetchItem(item.id)]);
      }
    }
  };

  const displayPreview = !isPrint && referenceID === hoveredID;
  return (
    <span
      key={displayText}
      className={`${classes.itemReferenceBadge} cursor-pointer ${
        displayPreview && item ? classes.itemReferenceTextHovered : ''
      }`}
      data-cy="item-reference-span"
      onClick={navigateToItem}
      onKeyPress={() => {}}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {displayPreview &&
        (item ? (
          <CommentHoverWrapper
            anchorEl={anchorEl}
            containsPreviewContent
            content={<ItemReferencePreview item={item} />}
            onClick={navigateToItem}
          />
        ) : (
          <CommentHoverWrapper
            anchorEl={anchorEl}
            containsPreviewContent={false}
            content={<span>{DEPRECATED_ITEM_TEXT}</span>}
          />
        ))}
      {displayText}
    </span>
  );
};

export default withStyles(ItemReferenceDisplayStyles)(ItemReferenceDisplay);
