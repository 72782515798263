import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AppFrame from '../AppFrame';
import { useCurrentCompanyUser } from '../contexts/current-company-user';

import { useWaitForSignupRedirect } from './SignUpLayerUtils';

export default function SignUpLayer() {
  const companyUser = useCurrentCompanyUser();

  // SKIP SIGN UP FLOW (CYPRESS TESTS)
  const skipSignupFlow = localStorage.getItem('skip_signup_flow');
  const pathname = useLocation().pathname;
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { wait, url } = useWaitForSignupRedirect(companyUser, !!skipSignupFlow, projectId);

  useEffect(() => {
    if (window.location.pathname !== url && url) {
      // this will load signup
      navigate(url);
    }
  }, [url, wait, pathname, navigate]);

  if (wait || !companyUser) return null;

  // the regularly scheduled Join Application
  return <AppFrame />;
}
