import { FC } from 'react';

import { DRAG_DROP_ASSET_MILESTONE_DETAILS } from '../../../actions/actionTypes';
import { getMilestoneAssetAnalytics } from '../../../analytics/analyticsEventProperties';
import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useMilestoneUploadAttachAssets } from '../hooks/AssetsMutationHook';
import useMilestoneAttachmentsQuery from '../hooks/useMilestoneAttachmentsQuery';
import useOptimisticMilestoneUploadAssets from '../hooks/useOptimisticMilestoneUploadAssets';
import useOptimisticProjectUploadAssets from '../hooks/useOptimisticProjectUploadAssets';
import { getNonNullEntityAttachments } from '../utils';

import AssetsWrapper from './AssetsWrapper';

type AssetsWrapperMilestoneDataProps = {
  milestoneID: UUID;
  projectID: UUID;
};

const AssetsWrapperMilestoneData: FC<AssetsWrapperMilestoneDataProps> = (props) => {
  // Permissions
  const { canView, canAdd, canDelete } = usePermissions();
  const canViewMilestoneAttachements = canView(PermissionResource.MILESTONE_ATTACHMENTS);
  const canAddMilestoneAttachements = canAdd(PermissionResource.MILESTONE_ATTACHMENTS);
  const canDeleteMilestoneAttachements = canDelete(PermissionResource.MILESTONE_ATTACHMENTS);

  const analyticsEvent = getMilestoneAssetAnalytics(
    DRAG_DROP_ASSET_MILESTONE_DETAILS,
    props.milestoneID
  );
  const { isAttach, onAttachAsset, onDropFile, uploadingAssets } = useMilestoneUploadAttachAssets(
    props.milestoneID,
    analyticsEvent
  );

  const { data, loading } = useMilestoneAttachmentsQuery(
    props.milestoneID,
    canViewMilestoneAttachements
  );
  const milestoneAttachments = getNonNullEntityAttachments(data?.milestoneAttachments);
  const assets = milestoneAttachments;

  useOptimisticMilestoneUploadAssets(props.milestoneID, uploadingAssets, isAttach);
  useOptimisticProjectUploadAssets(props.projectID, uploadingAssets);

  return (
    <AssetsWrapper
      assets={assets}
      assetsLoading={loading}
      hasAssetAddPermission={canAddMilestoneAttachements}
      hasAssetDeletePermission={canDeleteMilestoneAttachements}
      onAttachAsset={onAttachAsset}
      onDropFile={onDropFile}
      projectID={props.projectID}
    />
  );
};

export default AssetsWrapperMilestoneData;
