import { ReactNode, forwardRef, useRef } from 'react';
import { AriaButtonProps, mergeProps, useButton, useFocusRing } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import { TooltipTriggerProps, pickTooltipTriggerProps } from '../../scales';

type Props = TooltipTriggerProps & {
  'data-cy'?: string;
  isDisabled?: boolean;
  label: string;
  onClick?: AriaButtonProps['onPress'];
  startIcon?: ReactNode;
  type: 'primary' | 'secondary' | 'destructive';
};

export default forwardRef<HTMLButtonElement, Props>(
  function MiniEstimateButton(props, forwardedRef) {
    const ref = useRef(null);

    // Manage focus via useFocusRing since focus has a habit of appearing when clicking via
    // the mouse when we rely solely on useButton.
    const { isFocusVisible, focusProps } = useFocusRing();

    const { buttonProps } = useButton(
      {
        isDisabled: Boolean(props.isDisabled),
        onPress: props.onClick,
      },
      ref
    );

    const classes = [
      'flex relative shrink-0 grow-0 type-label justify-center items-center gap-1 border h-[20px] transition rounded-3xl w-min whitespace-nowrap',
    ];

    if (props.type === 'primary') {
      classes.push(
        'bg-button-primary text-type-secondary border-transparent',
        'hover:enabled:bg-button-primary-hover',
        isFocusVisible ? 'outline border-button-focus-outline' : 'outline-none',
        'disabled:text-type-inactive disabled:bg-button-inactive disabled:border-button-inactive'
      );
    } else if (props.type === 'secondary') {
      classes.push(
        'bg-button-secondary text-type-primary border-button-primary',
        'hover:enabled:bg-button-secondary-hover',
        isFocusVisible ? 'outline !border-transparent' : 'outline-none',
        'disabled:text-type-inactive disabled:border-button-inactive'
      );
    } else if (props.type === 'destructive') {
      classes.push(
        'bg-button-secondary text-button-destructive border-button-destructive',
        'hover:enabled:bg-button-destructive-hover',
        isFocusVisible
          ? 'outline outline-[3px] outline-button-destructive !border-transparent'
          : 'outline-none',
        'disabled:text-type-inactive disabled:border-button-inactive'
      );
    }

    let paddingClass = 'px-4';
    if (props.startIcon) {
      paddingClass = 'pl-2 pr-4';
    }

    return (
      <button
        {...mergeProps(pickTooltipTriggerProps(props), buttonProps, focusProps)}
        ref={composeRefs(ref, forwardedRef)}
        className={[...classes, paddingClass].join(' ')}
        data-cy={props['data-cy']}
      >
        {props.startIcon}
        {props.label}
      </button>
    );
  }
);
