import * as d3 from 'd3';
import { useState } from 'react';

import { ProgramSeparatedCostTrendlines } from '../../../../generated/graphql';
import { formatCost } from '../../../../utilities/currency';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import DashboardAnchor from '../../../dashboard/DashboardAnchor';
import { ButtonBar } from '../../../scales';
import CostFillPattern from '../ChartsCostTrendline/CostFillPattern';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import { CostTimeSeries } from '../InsightsCost/types';
import TimelineArea from '../TimelineArea';
import TimelineHoverSections from '../TimelineHoverSections';
import TimelinePath from '../TimelinePath';
import TimelinePointTooltip from '../TimelinePointTooltip';

import CombinedTimelineMultiTooltip from './CombinedHoverOverlay';
import LineLabelManager from './LineLabelManager';
import ProgramCostTrendlineLegend from './ProgramCostTrendlineLegend';
import ProgramXAxis from './ProgramXAxis';
import ProgramYAxis from './ProgramYAxis';
import SeparatedHoverContent from './SeparatedHoverContent';

type Props = {
  combined?: CostTimeSeries[];
  separated?: ProgramSeparatedCostTrendlines[];
  separatedProjectColors: Map<string, string>;
  isPrint?: boolean;
  dataRange: [number, number];
  timeRange: [Date, Date];
  height: number;
};

export default function ProgramCostTrendlineChart(props: Props) {
  const [chartType, setChartType] = useState<'combined' | 'separated'>('combined');
  const [hoverInfo, setHoverInfo] = useState<{ date: Date; value: number } | null>(null);
  const [combinedHoverInfo, setCombinedHoverInfo] = useState<{ costData: CostTimeSeries } | null>(
    null
  );

  const separatedDataRange: [number, number] = [
    d3.min(props.separated?.map((project) => Number(project.minCost)) ?? []) ?? 0,
    d3.max(props.separated?.map((project) => Number(project.maxCost)) ?? []) ?? 0,
  ];

  const { ref, dimensions } = useChartDimensions({
    height: props.height,
    marginTop: 5,
    marginRight: 50,
    marginBottom: 50,
    marginLeft: 50,
  });

  if (!props.combined || !props.separated) return null;
  // x domain
  const xMin = props.timeRange[0];
  const xMax = props.timeRange[1];
  const xDomain = [xMin, xMax];
  const xRange: [number, number] = [dimensions.marginLeft, dimensions.boundedWidth];

  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const yDataMin = chartType === 'combined' ? props.dataRange[0] : separatedDataRange[0];
  const yDataMax = chartType === 'combined' ? props.dataRange[1] : separatedDataRange[1];
  const yDelta = yDataMax - yDataMin;

  // Add vertical padding
  const yMin = Number(yDataMin) - (6 * Number(yDelta)) / 100;
  const yMax = Number(yDataMax) + (18 * Number(yDelta)) / 100;
  const yDomain: [number, number] = [yMin, yMax];
  const yRange: [number, number] = [
    dimensions.height - dimensions.marginBottom,
    dimensions.marginTop,
  ];

  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <DashboardAnchor anchor="Combined Cost Trendline" />
        <div>
          <ButtonBar
            onChange={(value) => {
              setChartType(value as 'combined' | 'separated');
            }}
            options={[
              {
                label: 'Combined',
                value: 'combined',
              },
              {
                label: 'Separated',
                value: 'separated',
              },
            ]}
            value={chartType}
          />
        </div>
      </div>
      <ProgramCostTrendlineLegend
        chartType={chartType}
        separatedProjectColors={props.separatedProjectColors}
      />
      <SVGWithDimensions ref={ref} dimensions={dimensions}>
        <ProgramXAxis
          domain={d3.timeDays(xDomain[0], xDomain[1])}
          range={xRange}
          tickTotal={5}
          x={x}
          y={y}
          yPosition={yMin}
        />
        <ProgramYAxis
          showHorizontalLines
          tickFormat={(v) => formatCost(v, { short: true })}
          tickTotal={5}
          x={x}
          y={y}
        />

        {chartType === 'combined' && !!props.combined.length && (
          <>
            <TimelineArea<{ pendingMin: number; pendingMax: number }, { date: Date }>
              data={props.combined}
              fieldDate="date"
              fieldMax="pendingMax"
              fieldMin="pendingMin"
              fill="url(#stripes)"
              stroke="var(--colors-chart-pending-cost-area)"
              x={x}
              y={y}
            />
            <TimelinePath<{ estimate: number }>
              className="stroke-entities-estimate"
              data={props.combined}
              field="estimate"
              strokeDasharray="4, 3"
              strokeWidth={2}
              x={x}
              y={y}
            />
            <TimelinePath<{ budget: number }>
              className="stroke-entities-budget"
              data={props.combined}
              field="budget"
              strokeWidth={2}
              x={x}
              y={y}
            />
            <TimelinePath<{ runningTotal: number }>
              className="stroke-entities-estimate"
              data={props.combined}
              field="runningTotal"
              strokeWidth={2}
              x={x}
              y={y}
            />
            {!combinedHoverInfo && <LineLabelManager data={props.combined} x={x} y={y} />}
          </>
        )}

        {chartType === 'separated' &&
          props.separated.map((projectInfo) => (
            <>
              <TimelinePath<{ runningTotal: number }>
                key={`series-${projectInfo.projectID}-${projectInfo.projectName}`}
                data={projectInfo.separatedCostTimeSeries}
                field="runningTotal"
                stroke={
                  props.separatedProjectColors.has(projectInfo.projectName)
                    ? props.separatedProjectColors.get(projectInfo.projectName)
                    : 'stroke-entities-estimate'
                }
                strokeWidth={1.5}
                x={x}
                y={y}
              />

              {projectInfo.separatedCostTimeSeries.map((point) => (
                <TimelinePointTooltip
                  key={`point-${projectInfo.projectID}-${point.date}`}
                  content={<SeparatedHoverContent {...projectInfo} costData={point} />}
                  data={{
                    date: point.date,
                    value: point.runningTotal,
                  }}
                  fill="fill-entities-estimate"
                  isOpen={hoverInfo?.date === point.date && hoverInfo?.value === point.runningTotal}
                  onHover={() => setHoverInfo({ date: point.date, value: point.runningTotal })}
                  onLeave={() => setHoverInfo(null)}
                  x={x}
                  y={y}
                />
              ))}
            </>
          ))}

        {combinedHoverInfo && chartType === 'combined' && (
          <CombinedTimelineMultiTooltip
            key={`hover-point-${combinedHoverInfo.costData.date}`}
            backgroundColor={['black', 'red', 'blue']}
            data={combinedHoverInfo.costData}
            fill={[
              'fill-entities-estimate',
              'fill-entities-budget',
              'fill-entities-estimate',
              'var(--colors-chart-pending-cost-area)',
              'var(--colors-chart-pending-cost-area)',
            ]}
            isOpen
            pointSize={4}
            x={x}
            y={y}
            yDomain={yDomain}
          />
        )}

        {chartType === 'combined' && (
          <TimelineHoverSections
            bounds={bounds}
            data={props.combined}
            onHoverIndex={(index) => {
              if (index >= 0 && props.combined && props.combined[index]) {
                setCombinedHoverInfo({ costData: props.combined[index] });
              } else {
                setCombinedHoverInfo(null);
              }
            }}
            x={x}
          />
        )}

        <CostFillPattern />
        <CostLabelBlur />
      </SVGWithDimensions>
    </div>
  );
}
