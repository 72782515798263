import { MenuItem } from '@material-ui/core';
import { ErrorOutline as Error, Info } from '@material-ui/icons';

import {
  CLEAR_REFERENCES,
  COST_OF_CONSTRUCTION_REF,
  COST_TYPES,
  COST_TYPES_DISPLAY,
  RESOLVE_ERRORS,
  S1,
  S2,
  SELECT_ALL,
  TOTAL_REF,
} from '../../../constants';
import { CostType, EstimateTotalType } from '../../../generated/graphql';
import { CELL_REFERENCE_SELECTION } from '../../../tagConstants';
import { Switch, Tooltip } from '../../scales';

import { ClearLink } from './InputsSelectReferenceCategoryFilters';
import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';
import { getReferencesMenuSubtotal } from './ReferencesMenuSubtotal';
import { getReferencesSubMenuItem } from './ReferencesSubMenuItem';
import {
  MarkupSelectors,
  checkValidMarkupReferences,
  costTypeColors,
  getDirectCostSubtotalText,
  getMarkupSubtotalText,
  getReferenceValue,
  isMarkupChecked,
  markupText,
  markupTitle,
  updateReferencesAndCostFilters,
} from './utils';

type Props = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  isFixedMarkup: boolean;
  isOwnerCost: boolean;
  hasFilters: boolean;
  markups: SelectableMarkup[];
  canViewDirectCosts: boolean;
  s1RefShouldIncludeS2: boolean;
  selectors: MarkupSelectors;
  setSelectors: (newSelectors: MarkupSelectors) => void;
  setNewErrorState: (err?: string) => void;
  termStore: TermStore;
  totalType?: EstimateTotalType;
  isItem?: boolean;
  newError?: string;
};
// Note:
// Material-ui doesn't let us use a functional component to wrap
// a MenuItem component.  If we wrap it then the MenuItems aren't interactive
export const generateReferencesMenuItems = ({
  classes,
  isFixedMarkup,
  isOwnerCost,
  hasFilters,
  markups,
  canViewDirectCosts,
  s1RefShouldIncludeS2,
  selectors,
  setSelectors,
  setNewErrorState,
  termStore,
  totalType,
  isItem,
  newError,
}: Props) => {
  // VARIABLES
  const { references, costTypes, applyFilterToMarkups } = selectors;
  const hasMarkups = markups.length > 0;
  const hasReferences = references.length > 0;

  // checkbox Logic
  const validMarkupRefs = references.filter((r) => markups.find((m) => m.id === r));
  const isReferencingTotal = references.includes(TOTAL_REF);
  const isReferencingCostOfConstruction = references.includes(COST_OF_CONSTRUCTION_REF);

  const allCostTypesSelected = costTypes.length === COST_TYPES.length;
  const noCostTypesSelected = !costTypes.length;
  const isS1Checked = references.includes(S1);
  const isEveryS1CostTypeChecked =
    isMarkupChecked(S1, references, COST_TYPES.length) &&
    (allCostTypesSelected || noCostTypesSelected);

  const isS1FullyChecked =
    isFixedMarkup ||
    (!isOwnerCost && isReferencingTotal) ||
    (isEveryS1CostTypeChecked && isS1Checked);

  const isS2Checked = validMarkupRefs.length === markups.length || isReferencingTotal;
  const totalChecked = isReferencingTotal || (isS1FullyChecked && isS2Checked);

  const costTypeIsChecked = (costType: CostType) => costTypes.includes(costType);

  const directCostSubtotalText = getDirectCostSubtotalText(termStore, s1RefShouldIncludeS2);
  const markupSubtotalText = getMarkupSubtotalText(termStore, s1RefShouldIncludeS2);

  const onClick = (newRef: string) => {
    // To make this easier to manage we added a prefix `ADDED_` or `REMOVED_` to the newRef
    // value the user clicked on to know when a user is adding or removing a reference clicking.
    updateReferencesAndCostFilters(
      newRef,
      markups,
      selectors,
      setSelectors,
      totalType,
      isOwnerCost
    );
  };

  // Clear & Subtotal option
  const selectAllOrClearAllKey = references.length ? CLEAR_REFERENCES : SELECT_ALL;
  const menuOptions = [
    <div key="header" className="flex h-9 items-center !justify-between border-b px-3 type-body2">
      <div className="truncate">Apply to: </div>
      {!isFixedMarkup && !isOwnerCost && (
        <ClearLink
          onClick={() => onClick(selectAllOrClearAllKey)}
          text={hasReferences ? 'Clear' : 'Select All'}
        />
      )}
    </div>,
  ];

  if (!isOwnerCost || canViewDirectCosts) {
    // only show S1 (direct cost) Subtotal for owner costs if the user has direct cost view access
    menuOptions.push(
      getReferencesMenuSubtotal({
        classes,
        key: S1,
        text: directCostSubtotalText,
        isChecked: isS1FullyChecked,
        isUnchecked: !costTypes.length,
        value: getReferenceValue(S1, isS1FullyChecked),
        onClick,
      })
    );
  }

  menuOptions.push(
    <li key="filter-toggle" className="flex items-center gap-2 px-2">
      <Tooltip
        key="applyFilterTooltip"
        content={
          <div className="flex flex-col gap-2 type-body2">
            <div>
              <div className="type-heading3">Filter markups by category</div>
              <div>
                Uses categories to filter the cost of selected percent markups when calculating.
              </div>
            </div>
            <div>
              <div className="type-heading3">Do not filter markups by category</div>
              <div>
                Use the total cost of selected percent markups when calculating. WinEst, Sage &
                DESTINI calculate this way.
              </div>
            </div>
          </div>
        }
      >
        <div>
          <Info className="text-type-muted" />
        </div>
      </Tooltip>
      <div className={`flex-grow type-body2 ${hasFilters ? '' : 'text-type-muted'}`}>
        {hasFilters ? 'Filter markups by category' : 'No category filters to apply'}
      </div>
      <Switch
        aria-label="Apply Category Filter to Markups"
        isChecked={applyFilterToMarkups}
        isDisabled={!hasFilters}
        onChange={(newApplyFilterToMarkups) =>
          setSelectors({ ...selectors, applyFilterToMarkups: newApplyFilterToMarkups })
        }
      />
    </li>
  );

  // costType / LEMSOU references
  if (totalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES && !isFixedMarkup && !isOwnerCost) {
    menuOptions.push(
      ...COST_TYPES.map((costType) => {
        const backgroundColor = costTypeColors.get(costType);
        const text = COST_TYPES_DISPLAY.get(costType) || '';
        const isChecked = costTypeIsChecked(costType) || isReferencingTotal;
        const value = getReferenceValue(costType, isChecked);
        return getReferencesSubMenuItem({
          classes,
          backgroundColor,
          id: costType,
          key: costType,
          isChecked: costTypeIsChecked(costType) || isReferencingTotal || isEveryS1CostTypeChecked,
          text,
          title: text,
          value,
          onClick,
        });
      })
    );
  }

  // S2 (markup) subtotal
  if (!isFixedMarkup && hasMarkups && !isOwnerCost) {
    const value = getReferenceValue(S2, isS2Checked);
    menuOptions.push(
      getReferencesMenuSubtotal({
        classes,
        key: S2,
        isChecked: isS2Checked,
        isUnchecked: !validMarkupRefs.length,
        text: markupSubtotalText,
        value,
        onClick,
      })
    );
  }

  // when pasting in markups between estimates we might fail to find a reference
  // if that references is to another markup (like M1) This MenuItem clears
  // out those invalid references, because they are otherwise hard for users to remove

  const isReferenceError = newError && newError.includes('reference to missing markup');
  if (hasMarkups && isReferenceError)
    menuOptions.push(
      <MenuItem
        key="errorLine"
        className="pl-1"
        disableRipple
        style={{ paddingLeft: 12 }}
        value={RESOLVE_ERRORS}
      >
        <div className="flex text-type-error">
          <Error style={{ paddingRight: 12, height: 30, width: 30 }} />
          <div className="truncate">{newError}</div>
          <button
            className="type-underline type-body2"
            onClick={() => {
              setSelectors({
                ...selectors,
                references: checkValidMarkupReferences(markups, references),
              });
              setNewErrorState('');
            }}
          >
            Delete
          </button>
        </div>
      </MenuItem>
    );

  // References for markups
  if (!isFixedMarkup && hasMarkups && !isOwnerCost)
    menuOptions.push(
      ...markups.map((markup) => {
        const { lineNumber, id } = markup;
        const isChecked = isMarkupChecked(id, references);
        const value = getReferenceValue(markup.id, isChecked);
        return getReferencesSubMenuItem({
          classes,
          id: lineNumber + CELL_REFERENCE_SELECTION,
          key: lineNumber + id,
          text: markupText(markup),
          title: markupTitle(references, markup),
          isChecked,
          value,
          onClick,
        });
      })
    );

  if (isOwnerCost) {
    menuOptions.push(
      getReferencesMenuSubtotal({
        classes,
        key: COST_OF_CONSTRUCTION_REF,
        text: 'Cost of construction',
        isChecked: isReferencingCostOfConstruction,
        value: getReferenceValue(COST_OF_CONSTRUCTION_REF, isReferencingCostOfConstruction),
        onClick,
      })
    );
  }

  if (!isFixedMarkup && hasMarkups) {
    menuOptions.push(
      getReferencesMenuSubtotal({
        classes,
        key: TOTAL_REF,
        text: `${isItem ? 'Item' : 'Project'} total`,
        isChecked: totalChecked,
        value: getReferenceValue(TOTAL_REF, totalChecked),
        onClick,
      })
    );
  }

  return menuOptions;
};
