import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { InsightsEvent, insightsEvent } from '../../../../analytics/analyticsEventProperties';
import { ALL_MILESTONES } from '../../../../constants';
import {
  ItemsListQuery,
  ItemsSortKey,
  SortDirection,
  Status,
  Visibility,
  VisibilityView,
} from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../../routes/paths';
import { formatCost } from '../../../../utilities/currency';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { isCostRange } from '../../../CostReport/CostReportUtils';
import { ItemDueState } from '../../../dragon-scales/TimelineCharts/InsightsItems/types';
import { useItemsListQuery } from '../../../ItemsList/useItemsListQuery';
import { SortManager, Table } from '../../../scales';
import TableChartPlaceholder from '../../Charts/TableChartPlaceholder';
import Section from '../../InsightsProject/Section';
import SectionTitle from '../../Tables/SectionTitle';
import { InsightsProject, InsightsTabId } from '../../types';
import { getDueDateState } from '../../utils';
import { TOTAL_HEIGHT } from '../utils';

enum SortKey {
  Number = 'number',
  Date = 'date',
  Cost = 'cost',
}

export default function ProjectItemsTable(props: { project: InsightsProject; today: Date }) {
  const sendAnalytics = useSendAnalytics();
  const itemsListQueryResult = useItemsListQuery(
    null, // milestoneID
    null, // activityID
    props.project.id, // projectID
    true, // showOptions
    {}, // viewFilter
    VisibilityView.PUBLIC_VIEW, // visibilityView
    [], // integrations
    true // loadItemCosts
  );

  const [lateSortState, setLateSortState] = useState<SortBy>({
    sortDirection: SortDirection.SORT_ASCENDING,
    sortKey: SortKey.Date,
  });
  const [upcomingSortState, setUpcomingSortState] = useState<SortBy>({
    sortDirection: SortDirection.SORT_ASCENDING,
    sortKey: SortKey.Date,
  });
  const [missingDueDateSortState, setMissingDueDateSortState] = useState<SortBy>({
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: SortKey.Cost,
  });
  type Item = ItemsListQuery['itemsList']['items'][number];

  const [lateItems, upcomingItems, missingDueDateItems, minDate, maxDate] = useMemo<
    [Item[], Item[], Item[], Date | null, Date | null]
  >(() => {
    const lateItems: Item[] = [];
    const upcomingItems: Item[] = [];
    const missingDueDateItems: Item[] = [];
    let minDate: Date | null = null;
    let maxDate: Date | null = null;
    const compareFn = (sortState: SortBy) => (a: Item, b: Item) => {
      const { sortKey, sortDirection } = sortState;
      if (sortKey === SortKey.Number) {
        if (sortDirection === SortDirection.SORT_ASCENDING) {
          return a.number < b.number ? -1 : 1;
        }
        return a.number > b.number ? -1 : 1;
      }
      if (sortKey === SortKey.Date) {
        const aValue = new Date(a?.dueDate ?? 0).valueOf();
        const bValue = new Date(b?.dueDate ?? 0).valueOf();
        if (sortDirection === SortDirection.SORT_ASCENDING) {
          return aValue - bValue;
        }
        return bValue - aValue;
      }
      if (sortKey === SortKey.Cost) {
        const aValue = isCostRange(a.cost) ? a.cost.max : a.cost.value;
        const bValue = isCostRange(b.cost) ? b.cost.max : b.cost.value;
        if (sortDirection === SortDirection.SORT_ASCENDING) {
          return aValue - bValue;
        }
        return bValue - aValue;
      }
      return 0;
    };
    (itemsListQueryResult.data?.itemsList.items ?? []).forEach((it) => {
      if (it.status === Status.NOTCHOSEN) return;

      if (!it.dueDate) {
        missingDueDateItems.push(it);
        missingDueDateItems.sort(compareFn(missingDueDateSortState));
        return;
      }
      const date = new Date(it.dueDate);
      if (minDate === null || date < minDate) minDate = date;
      if (maxDate === null || date > maxDate) maxDate = date;

      const state = getDueDateState(it, props.today);
      if (state === ItemDueState.PastDue) {
        lateItems.push(it);
      } else if (state === ItemDueState.Upcoming) {
        upcomingItems.push(it);
      }
    });

    lateItems.sort(compareFn(lateSortState));
    upcomingItems.sort(compareFn(upcomingSortState));

    return [lateItems, upcomingItems, missingDueDateItems, minDate, maxDate];
  }, [
    itemsListQueryResult.data?.itemsList.items,
    props.today,
    lateSortState,
    upcomingSortState,
    missingDueDateSortState,
  ]);

  if (itemsListQueryResult.loading) return <TableChartPlaceholder height={TOTAL_HEIGHT} />;

  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const emptyStateText = (title: string) => {
    switch (title) {
      case 'Past due items':
        return 'No past due items';
      case 'Upcoming items':
        return 'No upcoming items';
      case 'Missing due dates':
        return 'No missing due dates';
      default:
        return '';
    }
  };

  const makeEntry = (it: Item) => {
    const key = it.id;
    const numberAndName = (
      <div className="line-clamp-3 type-table-text">
        #{it.number}: {it.name}
      </div>
    );
    return [
      {
        key,
        component: props.project.hasAccess ? (
          <Link
            className="w-full"
            to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
              projectId: props.project.id,
              itemId: it.id,
            })}
          >
            {numberAndName}
          </Link>
        ) : (
          <div className="w-full">{numberAndName}</div>
        ),
      },
      {
        key,
        component: (
          <div className="type-table-number">
            {it.dueDate
              ? new Date(it.dueDate).toLocaleDateString(undefined, dateFormatOptions)
              : ''}
          </div>
        ),
      },
      {
        key,
        component: (
          <div className="type-table-number">
            {isCostRange(it.cost)
              ? `${formatCost(it.cost.min, { short: true })} to ${formatCost(it.cost.max, {
                  short: true,
                })}`
              : formatCost(it.cost.value, { short: true })}
          </div>
        ),
      },
    ];
  };

  const makeTableSection = (params: {
    title: string;
    entityColor: string;
    count: number;
    dateFilterText: string | undefined;
    sortManager: SortManager | undefined;
    items: Item[];
  }) => {
    const { title, entityColor, count, dateFilterText, sortManager, items } = params;
    return (
      <Section
        id={InsightsTabId.Items}
        title={
          <SectionTitle
            count={count}
            entityColor={entityColor}
            isLinkVisible={props.project.hasAccess}
            linkText="View all"
            onLink={() =>
              sendAnalytics(
                insightsEvent(InsightsEvent.DETAILS_ITEMS_LINK_CLICK, { linkType: title })
              )
            }
            title={title}
            to={{
              pathname: generateSharedPath(RouteKeys.PROJECT_ITEMS, {
                projectId: props.project.id,
              }),
              search: queryString.stringify(
                {
                  currentMilestone: [ALL_MILESTONES],
                  due: dateFilterText,
                  filterText: dateFilterText ? `due: ${dateFilterText}` : undefined,
                  sort: ItemsSortKey.SORT_DUE_DATE,
                  status: [Status.PENDING],
                  visibility: [Visibility.PUBLISHED],
                },
                { arrayFormat: 'index' }
              ),
            }}
          />
        }
      >
        {items.length > 0 ? (
          <Table
            columnWidths={['minmax(128px, 1fr)', '112px', '128px']}
            entries={items.map((it) => makeEntry(it))}
            headerContent={[
              {
                component: <div className="-m-1 h-9" />,
                copy: `Items (#)`,
                headerSortKey: SortKey.Number,
                key: SortKey.Number,
              },
              {
                copy: 'Due Date',
                headerSortKey: SortKey.Date,
                key: SortKey.Date,
              },
              {
                copy: 'Cost Impact',
                headerSortKey: SortKey.Cost,
                key: SortKey.Cost,
                isRightAligned: true,
              },
            ]}
            rowHeight="minmax(40px, auto)"
            sortManager={sortManager}
          />
        ) : (
          <div className="p-4 text-type-muted">{emptyStateText(title)}</div>
        )}
      </Section>
    );
  };

  const lateItemEntries = lateItems.slice(0, 4);
  const upcomingItemEntries = upcomingItems.slice(0, 4);
  const numNonMissingEntries = lateItemEntries.length + upcomingItemEntries.length;
  const missingItemEntries =
    numNonMissingEntries < 8
      ? missingDueDateItems.slice(0, Math.min(8 - numNonMissingEntries, 4))
      : [];

  return (
    <div className="flex flex-auto flex-col gap-6">
      {makeTableSection({
        title: 'Past due items',
        entityColor: 'bg-entities-item-pastdue',
        count: props.project.pendingItemsPastDue,
        dateFilterText: minDate
          ? `${minDate.toLocaleDateString(
              'en-US',
              dateFormatOptions
            )} to ${props.today.toLocaleDateString('en-US', dateFormatOptions)}`
          : undefined,
        sortManager: {
          sortState: lateSortState,
          setSort: (value) => setLateSortState(value),
        },
        items: lateItemEntries,
      })}
      {makeTableSection({
        title: 'Upcoming items',
        entityColor: 'bg-entities-item-upcoming',
        count: props.project.pendingItemsDueInFuture,
        dateFilterText: maxDate
          ? `${props.today.toLocaleDateString(
              'en-US',
              dateFormatOptions
            )} to ${maxDate.toLocaleDateString('en-US', dateFormatOptions)}`
          : undefined,
        sortManager: {
          sortState: upcomingSortState,
          setSort: (value) => setUpcomingSortState(value),
        },
        items: upcomingItemEntries,
      })}
      {makeTableSection({
        title: 'Missing due dates',
        entityColor: 'bg-item-status-pending-tint',
        count: props.project.pendingItemsMissingDueDates,
        dateFilterText: 'none',
        sortManager: {
          sortState: missingDueDateSortState,
          setSort: (value) => setMissingDueDateSortState(value),
        },
        items: missingItemEntries,
      })}
    </div>
  );
}
