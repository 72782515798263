import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Dialog, DialogActions, DialogContent, Divider, Typography } from '@material-ui/core';

import { UserReportType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useEditProjectComparisonReportMutation } from '../../ForecastingRoute/ForecastingReports/hooks/useEditProjectComparisonReportMutation';
import { Button, Icon, IconButton } from '../../scales';
import DialogsReportsContent from '../DialogsReports/DialogsReportsContent/DialogsReportsContent';
import styles from '../DialogsStyles';

type DialogsReportEditProps = {
  classes: Classes<typeof styles>;
  onClose: () => void;
  onSuccess: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  report?: ForecastingReport;
};

// NOTE: this component is a ripoff of DialogsReport, but specialized for editing Forecasting Reports
// it would be best long term to revisit the reusability of DialogsReport/DialogsReportContent components
const DialogsReportEdit: FC<DialogsReportEditProps> = ({
  classes,
  onClose,
  onSuccess,
  open,
  report,
}) => {
  const { projectId } = useParams();

  const [reportInput, setReportInput] = useState<UserReportInput>({
    name: '',
    description: '',
    reportType: UserReportType.NONE,
    settings: '',
    shared: false,
  });
  useEffect(
    () =>
      setReportInput((prevReport) => ({
        ...prevReport,
        id: report?.id,
        name: report?.name ?? '',
        description: report?.description ?? '',
      })),
    [report]
  );

  const [onEditForecastingReport] = useEditProjectComparisonReportMutation(onSuccess);

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaperSmall,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">Edit report</Typography>
        </div>
        <IconButton
          aria-label="Close dialog"
          icon={<Icon name="close" />}
          onClick={onClose}
          type="secondary"
        />
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <DialogsReportsContent
          allowableReportTypes={[]}
          // DialogsReportsEdit is only used only for forecasting, which are not able to be shared
          cannotShare
          onSubmit={() => {}}
          projectId={projectId}
          setUserReport={setReportInput}
          userReport={reportInput}
          variant="reports-manager"
        />
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button label="Cancel" onClick={onClose} type="secondary" />
        <Button
          data-cy="confirm-dialog--ok-button"
          isDisabled={
            reportInput.name === report?.name && reportInput.description === report?.description
          }
          label="Update"
          onClick={() => {
            onEditForecastingReport(report?.id ?? '', reportInput.name, reportInput.description);
            onClose();
          }}
          type="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogsReportEdit);
