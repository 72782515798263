import { FC, useMemo } from 'react';

import { ProjectCostBreakdownReportFieldsFragment } from '../../generated/graphql';
import { isNonNullable } from '../../utilities/types';
import ChartsPieGraph from '../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';

import ProjectCostBreakdownReportTable, {
  ReportTableHeader,
} from './ProjectCostBreakdownReportTable';
import { breakdownSorting, projectBreakdownTableOrder } from './utils';

type Props = {
  costBreakdownTypeToColorMap: Map<string, string>; // used for fill styling
  headers: ReportTableHeader[];
  label: string;
  orderingMap: Map<string, number>;
  shouldTableChartData?: boolean;
  sublabel?: string;
  projectCostBreakdownReport: ProjectCostBreakdownReportFieldsFragment;
};

const ProjectCostBreakdownReportChart: FC<Props> = ({
  costBreakdownTypeToColorMap,
  headers,
  label,
  orderingMap,
  shouldTableChartData = false,
  sublabel,
  projectCostBreakdownReport,
}) => {
  const { breakdown } = projectCostBreakdownReport;

  // filter out zero cost values from the pie chart,
  // and sort + format the data for the chart
  // note: this table has a different sort order from the table
  const sortedBreakdown = useMemo(() => {
    return [...breakdown]
      .filter((b) => b.cost.toString() !== '0')
      .sort(breakdownSorting(orderingMap));
  }, [breakdown, orderingMap]);

  const data = useMemo(() => {
    return sortedBreakdown.map(({ name, cost }) => {
      return { name, share: Number(cost) };
    });
  }, [sortedBreakdown]);

  // sort the table data
  // note: this table might have a different sort order from the chart
  // depending on if it's a program cost breakdown report or not
  const tableBreakdown = useMemo(() => {
    const data = shouldTableChartData ? sortedBreakdown : breakdown;
    return [...data].sort(breakdownSorting(projectBreakdownTableOrder));
  }, [breakdown, sortedBreakdown, shouldTableChartData]);

  return (
    <div className="mt-10 flex w-full items-center justify-center">
      <div className="flex w-full max-w-[1000px] gap-20">
        <ChartsPieGraph
          centerLabel={{ label, sublabel }}
          chartSize={{
            diameter: 300,
            insideDiameter: 216,
          }}
          colors={data.map((b) => costBreakdownTypeToColorMap.get(b.name)).filter(isNonNullable)}
          data={data}
          hasSectionTooltip
          isCurrency
        />
        <ProjectCostBreakdownReportTable
          costBreakdownTypeToColorMap={costBreakdownTypeToColorMap}
          headers={headers}
          projectCostBreakdown={tableBreakdown}
        />
      </div>
    </div>
  );
};

export default ProjectCostBreakdownReportChart;
