import { useEffect, useState } from 'react';

import { getTodayUtcNoon } from '../../../../utilities/dates';
import InsightsItemsChart from '../../../dragon-scales/TimelineCharts/InsightsItems/InsightsItemsChart';
import { filterItemsRange } from '../../../dragon-scales/TimelineCharts/InsightsItems/utils';
import useMemoWrapper from '../../../useMemoWrapper';
import TableChartPlaceholder from '../../Charts/TableChartPlaceholder';
import XAxis from '../../Charts/XAxis';
import { useItems } from '../../ProjectBabyCharts/hooks/useItems';
import { seriesToTimeline } from '../../ProjectBabyCharts/ProjectItems';
import { InsightsProject } from '../../types';
import { CHART_HEIGHT, LEFT_MARGIN, PADDING, TOTAL_HEIGHT } from '../utils';

const AXIS_HEIGHT = 32;

export default function ProjectItems(props: {
  project: InsightsProject;
  startStr: string;
  endStr: string;
  today?: Date;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const milestones = props.project?.milestones || [];
  const activeMilestoneID = milestones.find((m) => m.active)?.id;

  const { items: realItems, loading } = useItems(props.project.id, activeMilestoneID || '');
  const items = seriesToTimeline(props.project?.itemsDueDateSeriesMockData) || realItems;

  // Min and Max date range values for zoom domain and pickers
  const [startStr, setStartStr] = useState<string>(props.startStr);
  const [endStr, setEndStr] = useState<string>(props.endStr);

  useEffect(() => {
    if (startStr !== props.startStr) setStartStr(props.startStr);
    if (endStr !== props.endStr) setEndStr(props.endStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startStr, props.endStr]);

  const chartItems = useMemoWrapper(filterItemsRange, items, [
    new Date(startStr),
    new Date(endStr),
  ]);
  if (loading) return <TableChartPlaceholder height={CHART_HEIGHT - AXIS_HEIGHT + PADDING * 2} />;

  const chart = (
    <div className="relative block w-full">
      <InsightsItemsChart
        hasLinkAccess={props.project.hasAccess}
        height={CHART_HEIGHT}
        items={chartItems}
        projectID={props.project.id}
        today={today}
        totalRange={[new Date(startStr), new Date(endStr)]}
      />
      <XAxis totalRange={[startStr, endStr]} />
    </div>
  );

  return (
    <div
      className={`h-[${TOTAL_HEIGHT - AXIS_HEIGHT}px] min-w-full bg-background-primary`}
      style={{ paddingLeft: LEFT_MARGIN, paddingTop: PADDING, paddingBottom: PADDING }}
    >
      {chart}
    </div>
  );
}
