import queryString from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { getEmptyProjectRangeFilterKeys } from '../components/ProjectsList/ProjectsListUtils';
import { MANUAL, TYPE } from '../constants';

import { isNonNullable } from './types';

export const pathnameContainsPath: (pathname: string, path: string) => boolean = (
  pathname,
  path
) => {
  const paths = pathname.split('/');
  return paths.includes(path);
};

export type SetSettingsFunctionType = (
  settings: Record<string, string | string[] | number[] | null>
) => void;

// Serialize the current selection into the URL
export const serializeToURL = (
  navigate: NavigateFunction,
  location: Location,
  page: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  state: any,
  hash?: string
) => {
  const { pathname, search: oldSearch } = location;
  if (!pathnameContainsPath(pathname, page)) return;
  // we sometimes have search terms that need decoding...
  const oldState = queryString.parse(decodeURIComponent(oldSearch), { arrayFormat: 'index' });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const stateToPreserve: any = {};
  // Our List is derived for Draft Estimate Import and UTM context links from email (see invite emails)
  [
    TYPE,
    MANUAL,
    'email',
    'emailVerify',
    'initialScreen', // leaving for backwards compatability
    'screen_hint',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
  ].forEach((key) => {
    stateToPreserve[key] = oldState[key];
  });
  const newState = { ...state, ...stateToPreserve }; // Keeps whatever state we have already
  const search = queryString.stringify(newState, { arrayFormat: 'index' });
  // the url is a one-way journey - we replace, not edit, it
  if (hash) {
    navigate({ pathname, search, hash }, { replace: true });
  } else {
    navigate({ search }, { replace: true });
  }
};

export const getURLState = (location: Location, page: string) =>
  pathnameContainsPath(location.pathname, page)
    ? queryString.parse(location.search, { arrayFormat: 'index' })
    : {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const getNullateKeys = (settings: any) => {
  const keys = Object.keys(settings);
  return (keys || []).filter((key) => {
    const val = settings[key];
    if (
      !val ||
      // an empty object
      val === '{}' ||
      (Object.keys(val).length === 0 && val.constructor === Object) ||
      // or an empty array
      val === '[]' ||
      (Array.isArray(val) && val.length === 0)
    )
      return true;
    return false;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const removeNullKeys = (settings: any) => {
  const nonNullSettings = { ...settings };
  const nullKeys = getNullateKeys(nonNullSettings);
  (nullKeys || []).forEach((nullKey) => delete nonNullSettings[nullKey]);
  const emptyKeys = getEmptyProjectRangeFilterKeys(nonNullSettings);
  (emptyKeys || []).forEach((emptyKey) => delete nonNullSettings[emptyKey]);
  return nonNullSettings;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const getHasUrlState = (urlState: any) => {
  if (!urlState) {
    return false;
  }
  const urlStateKeysLength = Object.keys(urlState).length;
  const isImportEstimate = !!(urlState[TYPE] && urlState[MANUAL]) && urlStateKeysLength === 2; // Temporary
  if (isImportEstimate) return false;
  return !!urlStateKeysLength;
};

export const getNewState = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  defaults: any,
  getLocalStorage: (setting: string) => string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  urlState: any
) => {
  // Get state from URL, localStorage, default
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const newState: any = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const urlCompare: any = {};

  Object.keys(defaults).forEach((k: string) => {
    const localValue = getLocalStorage(k);
    const defaultValue = defaults[k];
    // if there are URL values, we use the URL. Else, we use local.
    if (getHasUrlState(urlState)) {
      const urlValue = urlState[k];
      const empty = Array.isArray(defaultValue) ? [] : defaultValue;
      newState[k] = urlValue || empty;
      urlCompare[k] = urlValue;
    } else if (localValue || localValue === '') {
      newState[k] = localValue;
    } else {
      newState[k] = defaults[k];
    }
  });
  return { newState, urlCompare };
};

// LOCAL ONLY HELPERS
const generateStorageLocation = (param: string, prefix: string) => `${prefix}${param}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const setLocalStorage = (settings: any, prefix: string) => {
  Object.entries(settings).forEach(([param, value]) => {
    const location = generateStorageLocation(param, prefix);
    if (value !== undefined) localStorage.setItem(location, JSON.stringify(value));
    else localStorage.removeItem(location);
  });
};

const getLocalStorage = (param: string, prefix: string) => {
  let value;
  try {
    value = localStorage.getItem(generateStorageLocation(param, prefix)); // either array or string
    if (value) {
      const parsedValue = JSON.parse(value);
      if (isNonNullable(parsedValue)) return parsedValue;
    }
    return null;
  } catch (_) {
    // its not JSON, its just a string, return the string
    return value;
  }
};

// made this for purely localStorage state
// realized we might want to check if on the right page, then write URL or not
type StringByString = Record<string, string | boolean>;

function getStoredSettings<T extends StringByString>(defaults: T, prefix: string) {
  let settings = { ...defaults };
  Object.keys(settings).forEach((key) => {
    const storedValue = getLocalStorage(key, prefix);
    const isNotEmpty = ![null, undefined].includes(storedValue);
    if (isNotEmpty) {
      settings = { ...settings, [key]: storedValue };
    }
  });
  return settings;
}

/** @deprecated use our custom useLocalStorage() hook instead */
export function useLocalStorageParams<T extends StringByString>(
  defaults: T, // default settings
  prefix: string // prefix for localStorage
) {
  const [settings, setSettingsInternal] = useState<T>(getStoredSettings<T>(defaults, prefix));
  const setSettings = useCallback(
    (newSettings: T) => {
      if (newSettings !== settings) {
        setLocalStorage(newSettings, prefix);
        setSettingsInternal(newSettings);
      }
    },
    [settings, prefix]
  );
  const tuple: [T, (newSettings: T) => void] = [settings, setSettings];
  return tuple;
}

// THE BIG HOOK
// This generic function looks for a key state in URL and localStorage,
// returning state and updating the URL
export function usePersistentStates<Settings, TranformSettings>(
  location: Location,
  page: string,
  defaultSettings: TranformSettings,
  prefix: string,
  transformSettings?: (settings: TranformSettings) => Settings,
  hash?: string
) {
  const navigate = useNavigate();
  // HELPER FUNCTIONS
  const locationHref = (location || {}).href;
  const urlState = getURLState(location, page) as unknown;
  const [counter, setCounter] = useState(0);
  // HOOKS
  // when the url changes or local updates, we recalc the state and comparable existing URL
  const { newState, urlCompare } = useMemo(() => {
    const getLocalStorageValue = (param: string) => getLocalStorage(param, prefix);
    const { newState, urlCompare } = getNewState(defaultSettings, getLocalStorageValue, urlState);
    if (transformSettings) {
      return { newState: transformSettings(newState), urlCompare };
    }
    return { newState, urlCompare };
    // eslint-disable-next-line
  }, [defaultSettings, locationHref, counter]);

  // Once after load: we will update the url if the state differs from the url
  useEffectOnce(() => {
    const urlCompareState = queryString.stringify(urlCompare, { arrayFormat: 'index' });
    const displayState = queryString.stringify(newState, { arrayFormat: 'index' });
    if (urlCompareState !== displayState) {
      const nonNullNewState = removeNullKeys(newState);
      setLocalStorage(nonNullNewState, prefix); // for your next visit...
      setCounter(counter + 1);
      serializeToURL(navigate, location, page, nonNullNewState, hash);
    }
  });

  // RETURN FUNCTION
  // We present a setSettings function to update a single setting
  const setSettings: SetSettingsFunctionType = (settings) => {
    // save settings to state, to local storage, and update URL
    setLocalStorage(settings, prefix); // for your next visit...
    setCounter(counter + 1);
    const newSettings = removeNullKeys({ ...newState, ...settings });
    // this should kick off a state update
    if (pathnameContainsPath(location.pathname, page)) {
      serializeToURL(navigate, location, page, newSettings);
    }
  };

  return [newState, setSettings];
}

// COLLAPSE STATE HELPER

export type CollapseSettings = {
  collapse: string[];
  expand: string[];
  zeroVariance?: string;
};

export const useSetCollapse = (
  settings: CollapseSettings,
  setSettings: SetSettingsFunctionType,
  setCollapseAnalytics: (bool: boolean, nodes: string[], node: string) => void
) =>
  useCallback(
    (bool: boolean, nodes: string[], node: string) => {
      const expand = settings.expand
        .filter((c: string) => !nodes.includes(c))
        .concat(bool ? [] : nodes); // remove all matching collapses
      const collapse = settings.collapse
        .filter((c) => !nodes.includes(c))
        .concat(bool ? nodes : []); // remove all matching collapses
      setSettings({ collapse, expand });
      setCollapseAnalytics?.(bool, nodes, node);
    },
    [settings.collapse, settings.expand, setSettings, setCollapseAnalytics]
  );
