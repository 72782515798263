import { ChangeEvent, FC, KeyboardEvent, useContext, useState } from 'react';

import { ListItem, TextField, Typography } from '@material-ui/core';
import { Restore } from '@material-ui/icons';

import { withStyles } from '../../theme/komodo-mui-theme';
import { Button } from '../scales';

import { TerminologyDefaults, TerminologyDescriptions } from './constants';
import { useResetProjectTerminology, useUpdateProjectTerminology } from './hooks';
import styles from './TerminologyListItemStyles';
import { ProjectTermStore } from './TerminologyProvider';

type TerminologyListItemProps = {
  projectID: UUID;
  canEdit: boolean;
  term: Terminology;
  classes: Classes<typeof styles>;
};

const TerminologyListItem: FC<TerminologyListItemProps> = ({
  projectID,
  canEdit,
  term,
  classes,
}) => {
  const t = useContext(ProjectTermStore);
  const [updateTerm] = useUpdateProjectTerminology(projectID, term);
  const [resetTerm] = useResetProjectTerminology(projectID, term);

  const { override, overrideValue } = term;
  const defaultValue = TerminologyDefaults[term.key as TermKey];
  const description = TerminologyDescriptions(t)(term.key as TermKey);
  // local state for text field
  const [value, setValue] = useState(override && overrideValue ? overrideValue : defaultValue);

  const onKeyDown = (evt: KeyboardEvent) => {
    if (evt && evt.key === 'Enter' && !evt.shiftKey) {
      const { target } = evt;
      (target as HTMLElement).blur();
    }
  };

  const submitTerm = () => {
    // somehow value can be null
    if (value && value !== defaultValue && value.length < term.maxLength) {
      updateTerm(value, (result: Terminology[]) => {
        const newTerm = result.find((t) => t.key === term.key);
        // in some cases the user may set an invalid value
        // for example, and empty string or a string consisting of just a space
        // in this case the backend will return a different result than what currently have
        if (newTerm?.overrideValue && newTerm.overrideValue !== value && !!value.trim()) {
          setValue(newTerm.overrideValue);
        }
      });
      // if value is empty then revert to the state before the user started editing
    } else if (!value || !value.trim()) {
      setValue(override && overrideValue ? overrideValue : defaultValue);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const submitResetTerm = () => {
    if (term.override) {
      resetTerm();
      setValue(defaultValue);
    }
  };

  return (
    <ListItem className={classes.listItem}>
      <TextField
        className={classes.term}
        data-cy={`textField-projectTerminologyKey-${term.key}`}
        disabled={!canEdit}
        inputProps={{ maxLength: term.maxLength - 1 }}
        // eslint-disable-next-line
        InputProps={{ disableUnderline: true }}
        onBlur={submitTerm}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
      />
      <Typography className={classes.description}>{description}</Typography>
      {term.override && canEdit && (
        <Button
          label={`Reset to "${defaultValue}"`}
          onClick={submitResetTerm}
          // I'm using data-cy on startIcon as a workaround to test the icon.
          // This should be done by checking the button's text instead of using
          // a templated data-cy tag.
          startIcon={<Restore data-cy={`resetToDefaultTerm-projectTerminologyKey-${term.key}`} />}
          type="secondary"
        />
      )}
    </ListItem>
  );
};
export default withStyles(styles)(TerminologyListItem);
