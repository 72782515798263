import { ApolloCache } from '@apollo/client';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { getOperationName } from '@apollo/client/utilities';

import { newMutationHook, newQueryHook } from '../../api/apollo/utilities';
import { REFETCH_ALERTS, REFETCH_COMPANY_STATS } from '../../api/refetchSets';
import {
  AddCompanyEmployeeMutation,
  AddCompanyEmployeeMutationVariables,
  CompaniesQuery,
  CompaniesQueryVariables,
  CompanyCollaboratorDetailsQuery,
  CompanyCollaboratorDetailsQueryVariables,
  CompanyEmployeeInput,
  CompanyProjectRolesQuery,
  CompanyProjectRolesQueryVariables,
  CompanyProjectStatsQuery,
  CompanyProjectStatsQueryVariables,
  CompanyRolesQuery,
  CompanyRolesQueryVariables,
  CompanyUserDetailsQuery,
  CompanyUserDetailsQueryVariables,
  CompanyUserSortKeys,
  CreateAlertMutation,
  CreateAlertMutationVariables,
  CurrentCompanyProjectRoleQuery,
  CurrentCompanyProjectRoleQueryVariables,
  DeleteProjectTypeMutation,
  DeleteProjectTypeMutationVariables,
  DeprecateAlertMutation,
  DeprecateAlertMutationVariables,
  GetAlertsQuery,
  GetAlertsQueryVariables,
  ProjectsSortKey,
  SortDirection,
  ToggleAlertMutation,
  ToggleAlertMutationVariables,
  ToggleProjectTypeVisibilityMutation,
  ToggleProjectTypeVisibilityMutationVariables,
  UpdateBulkCompanyProjectTypeMutation,
  UpdateBulkCompanyProjectTypeMutationVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdateCompanyProjectTypeMutation,
  UpdateCompanyProjectTypeMutationVariables,
  UpdateCompanyUserCompanyRoleMutation,
  UpdateCompanyUserCompanyRoleMutationVariables,
  UpdateCompanyUserJobTitleMutation,
  UpdateCompanyUserJobTitleMutationVariables,
  UpdateCompanyUserNameMutation,
  UpdateCompanyUserNameMutationVariables,
  UpdateCompanyUserPictureMutation,
  UpdateCompanyUserPictureMutationVariables,
  UpdateCompanyUserStatusMutation,
  UpdateCompanyUserStatusMutationVariables,
  UpdateProjectTypeMutation,
  UpdateProjectTypeMutationOptions,
  UpdateProjectTypeMutationVariables,
  UpdateTriggerMutation,
  UpdateTriggerMutationOptions,
  UpdateTriggerMutationVariables,
} from '../../generated/graphql';
import { companyProjectTypes } from '../../hooks/queries';
import { QueryHookOptions, useQuery } from '../../hooks/useMountAwareQuery';
import { useTypesIdsToNames } from '../../hooks/useTypesIdsToNames';

import {
  addCompanyEmployeeMutation,
  companiesQuery,
  companyCollaboratorDetailsQuery,
  companyProjectRolesQuery,
  companyRolesQuery,
  companyUserDetailsQuery,
  createAlertMutation,
  currentCompanyProjectRoleQuery,
  deleteProjectTypeMutation,
  deprecateAlertMutation,
  getAlertsQuery,
  getCompanyProjectStatsQuery,
  getCompanyUsersQuery,
  toggleAlertMutation,
  toggleProjectTypeVisibilityMutation,
  updateBulkCompanyProjectTypeMutation,
  updateCompanyMutation,
  updateCompanyProjectTypeMutation,
  updateCompanyUserCompanyRoleMutation,
  updateCompanyUserJobTitleMutation,
  updateCompanyUserNameMutation,
  updateCompanyUserPictureMutation,
  updateCompanyUserStatusMutation,
  updateProjectTypeMutation,
  updateTriggerMutation,
} from './queries';

const reorderCachedCompanyUsers = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  cache: ApolloCache<any>,
  companyID: UUID | undefined,
  sortKey: CompanyUserSortKeys,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  getSortField: (readField: (field: string, object: any) => any, object: any) => any
) => {
  cache.modify({
    fields: {
      companyUsers(existing = [], { storeFieldName, readField }) {
        if (!(companyID && storeFieldName.includes(companyID)) || !storeFieldName.includes(sortKey))
          return existing;
        const comparison = storeFieldName.includes(SortDirection.SORT_ASCENDING) ? 1 : -1;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        return [...existing].sort((objA: any, objB: any) => {
          const a = getSortField(readField, objA);
          const b = getSortField(readField, objB);
          if (a < b) return -comparison;
          if (a > b) return comparison;
          return 0;
        });
      },
    },
  });
};

const reorderCachedCompanyProjects = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  cache: ApolloCache<any>,
  companyID: UUID | undefined,
  sortKey: ProjectsSortKey,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  getSortField: (readField: (field: string, object: any) => any, object: any) => any
) => {
  cache.modify({
    fields: {
      companyProjectStats(existing = [], { storeFieldName, readField }) {
        if (!(companyID && storeFieldName.includes(companyID)) || !storeFieldName.includes(sortKey))
          return existing;
        const comparison = storeFieldName.includes(SortDirection.SORT_ASCENDING) ? 1 : -1;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        return [...existing].sort((objA: any, objB: any) => {
          const a = getSortField(readField, objA);
          const b = getSortField(readField, objB);
          if (a < b) return -comparison;
          if (a > b) return comparison;
          return 0;
        });
      },
    },
  });
};

export const useCompaniesQuery = () =>
  useQuery<CompaniesQuery, CompaniesQueryVariables>(companiesQuery, { errorPolicy: 'all' });

export const useUpdateCompany = () => {
  const [updateCompanyFunc] = useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    updateCompanyMutation
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const submitFunc = (company: CompanyInput, onSuccess?: any, onFailure?: any) =>
    updateCompanyFunc({
      variables: {
        ...company,
      },
    })
      .then((result) => {
        const updateCompany = result.data?.updateCompany;
        if (onSuccess) onSuccess(updateCompany);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return submitFunc;
};

export const useUpdateCompanyUserCompanyRole = (companyID: UUID | undefined) => {
  const [updateCompanyUserCompanyRoleFunc] = useMutation<
    UpdateCompanyUserCompanyRoleMutation,
    UpdateCompanyUserCompanyRoleMutationVariables
  >(updateCompanyUserCompanyRoleMutation);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const submitFunc = (userID: UUID, roleID: UUID, onSuccess?: any, onFailure?: any) => {
    const companyUserDetailsQueryOperationName = getOperationName(companyUserDetailsQuery);
    if (!companyUserDetailsQueryOperationName)
      throw new Error('failed to find query operation name');

    return updateCompanyUserCompanyRoleFunc({
      variables: {
        userID,
        roleID,
      },
      refetchQueries: [companyUserDetailsQueryOperationName],
      update: (cache) => {
        reorderCachedCompanyUsers(
          cache,
          companyID,
          CompanyUserSortKeys.SORT_COMPANY_USER_COMPANY_ROLE,
          (readField, object) => readField('name', readField('role', object))
        );
      },
    })
      .then((result) => {
        const updateCompanyUserCompanyRole = result.data?.updateCompanyUserCompanyRole;
        if (onSuccess) onSuccess(updateCompanyUserCompanyRole);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };

  return submitFunc;
};

export const useCompanyUserDetailsQuery = (userID: UUID, sortBy?: UserProjectSortBy) =>
  useQuery<CompanyUserDetailsQuery, CompanyUserDetailsQueryVariables>(companyUserDetailsQuery, {
    variables: { userID, sortBy },
    skip: !userID,
  });

export const useCompanyCollaboratorDetailsQuery = (
  userID: UUID,
  companyID?: UUID,
  sortBy?: UserProjectSortBy
) =>
  useQuery<CompanyCollaboratorDetailsQuery, CompanyCollaboratorDetailsQueryVariables>(
    companyCollaboratorDetailsQuery,
    {
      variables: { userID, companyID, sortBy },
      skip: !userID || !companyID,
    }
  );

export const useCompanyProjectStatsQuery = (
  companyID: UUID | undefined,
  filtersOuter: ProjectsFilterBy,
  sortBy: ProjectsSortBy
) => {
  const filters = useTypesIdsToNames(filtersOuter, 'types');

  return useQuery<CompanyProjectStatsQuery, CompanyProjectStatsQueryVariables>(
    getCompanyProjectStatsQuery,
    {
      variables: { companyID, filters, sortBy },
      skip: !companyID,
    }
  );
};

export const useCompanyRolesQuery = (companyID?: UUID) =>
  useQuery<CompanyRolesQuery, CompanyRolesQueryVariables>(companyRolesQuery, {
    variables: { companyID },
    skip: !companyID,
  });

export const useUpdateCompanyUserNameMutation = (companyID: UUID) => {
  const [updateCompanyUserNameFunc] = useMutation<
    UpdateCompanyUserNameMutation,
    UpdateCompanyUserNameMutationVariables
  >(updateCompanyUserNameMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (userID: UUID, name: string, onSuccess?: any, onFailure?: any) =>
    updateCompanyUserNameFunc({
      variables: { userID, name },
      update: (cache) => {
        reorderCachedCompanyUsers(
          cache,
          companyID,
          CompanyUserSortKeys.SORT_COMPANY_USER_USER_NAME,
          (readField, object) => readField('name', readField('user', object))
        );
      },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { updateCompanyUserName: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
};

export const useUpdateCompanyUserJobTitleMutation = (companyID: UUID) => {
  const [updateCompanyUserJobTitleFunc] = useMutation<
    UpdateCompanyUserJobTitleMutation,
    UpdateCompanyUserJobTitleMutationVariables
  >(updateCompanyUserJobTitleMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (userID: UUID, jobTitle: string, onSuccess?: any, onFailure?: any) =>
    updateCompanyUserJobTitleFunc({
      variables: { userID, jobTitle },
      update: (cache) => {
        reorderCachedCompanyUsers(
          cache,
          companyID,
          CompanyUserSortKeys.SORT_COMPANY_USER_JOB_TITLE,
          (readField, object) => readField('jobTitle', readField('user', object))
        );
      },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { updateCompanyUserJobTitle: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
};

export const useUpdateCompanyUserPictureMutation = () => {
  const [updateCompanyUserPictureFunc] = useMutation<
    UpdateCompanyUserPictureMutation,
    UpdateCompanyUserPictureMutationVariables
  >(updateCompanyUserPictureMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (userID: UUID, picture: string, onSuccess?: any, onFailure?: any) =>
    updateCompanyUserPictureFunc({
      variables: { userID, picture },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { updateCompanyUserPicture: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
};

export const useUpdateCompanyUserStatusMutation = () => {
  const [updateCompanyUserStatusFunc] = useMutation<
    UpdateCompanyUserStatusMutation,
    UpdateCompanyUserStatusMutationVariables
  >(updateCompanyUserStatusMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (userID: UUID, status: UserStatus, onSuccess?: any, onFailure?: any) =>
    updateCompanyUserStatusFunc({
      variables: { userID, status },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { updateCompanyUserStatus: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
};

export const useAddCompanyEmployeeMutation = () => {
  const [addCompanyEmployeeFunc] = useMutation<
    AddCompanyEmployeeMutation,
    AddCompanyEmployeeMutationVariables
  >(addCompanyEmployeeMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (input: CompanyEmployeeInput, onSuccess?: any, onFailure?: any) => {
    const getCompanyUsersQueryOperationName = getOperationName(getCompanyUsersQuery);
    if (!getCompanyUsersQueryOperationName) throw new Error('failed to find query operation name');
    addCompanyEmployeeFunc({
      variables: { input },
      refetchQueries: [getCompanyUsersQueryOperationName],
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { addCompanyEmployee: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
};

export const useCompanyProjectRolesQuery = (companyID?: UUID) =>
  useQuery<CompanyProjectRolesQuery, CompanyProjectRolesQueryVariables>(companyProjectRolesQuery, {
    variables: { companyID },
    skip: !companyID,
  });

export const useCurrentCompanyProjectRoleQuery = (
  options?: QueryHookOptions<
    CurrentCompanyProjectRoleQuery,
    CurrentCompanyProjectRoleQueryVariables
  >
) =>
  useQuery<CurrentCompanyProjectRoleQuery, CurrentCompanyProjectRoleQueryVariables>(
    currentCompanyProjectRoleQuery,
    options
  );

export const useUpdateCompanyProjectTypeMutation = (companyID: UUID) => {
  const [setProjectType] = useMutation<
    UpdateCompanyProjectTypeMutation,
    UpdateCompanyProjectTypeMutationVariables
  >(updateCompanyProjectTypeMutation);

  return (projectID: UUID, typeID: UUID) =>
    setProjectType({
      variables: { projectID, typeID },
      refetchQueries: REFETCH_COMPANY_STATS,
      update: (cache) => {
        reorderCachedCompanyProjects(
          cache,
          companyID,
          ProjectsSortKey.SORT_PROJECT_TYPE,
          (readField, object) => readField('name', readField('projectType', object))
        );
      },
    });
};

export const useUpdateBulkCompanyProjectTypeMutation = (companyID: UUID) => {
  const [setBulkProjectType] = useMutation<
    UpdateBulkCompanyProjectTypeMutation,
    UpdateBulkCompanyProjectTypeMutationVariables
  >(updateBulkCompanyProjectTypeMutation);

  return (projectIDs: UUID[], typeID: UUID) =>
    setBulkProjectType({
      variables: { projectIDs, typeID },
      refetchQueries: REFETCH_COMPANY_STATS,
      update: (cache) => {
        reorderCachedCompanyProjects(
          cache,
          companyID,
          ProjectsSortKey.SORT_PROJECT_TYPE,
          (readField, object) => readField('name', readField('projectType', object))
        );
      },
    });
};

export const useUpdateProjectTypeMutation = (options?: UpdateProjectTypeMutationOptions) => {
  const [mutationFunc, mutationResult] = useMutation<
    UpdateProjectTypeMutation,
    UpdateProjectTypeMutationVariables
  >(updateProjectTypeMutation, {
    ...options,
    onError: (error) => {
      options?.onError?.(error);
    },
  });
  return [
    (typeID: UUID, name: string, parentID: UUID | null | undefined) =>
      mutationFunc({
        variables: { typeID, name, parentID },
      }),
    mutationResult,
  ] as const;
};

export const useToggleProjectTypeVisibilityMutation = () => {
  const [mutationFunc] = useMutation<
    ToggleProjectTypeVisibilityMutation,
    ToggleProjectTypeVisibilityMutationVariables
  >(toggleProjectTypeVisibilityMutation);
  return (typeID: UUID, hide: boolean) =>
    mutationFunc({
      variables: { typeID, hide },
    });
};

export const useDeleteProjectTypeMutation = () => {
  const [mutationFunc] = useMutation<DeleteProjectTypeMutation, DeleteProjectTypeMutationVariables>(
    deleteProjectTypeMutation
  );
  return (typeID: UUID) =>
    mutationFunc({
      variables: { typeID },
      refetchQueries: [getOperationName(companyProjectTypes)!],
    });
};

export const useGetAlerts = newQueryHook<GetAlertsQuery, GetAlertsQueryVariables>(getAlertsQuery);
export const useCreateAlert = newMutationHook<CreateAlertMutation, CreateAlertMutationVariables>(
  createAlertMutation
);
export const useDeprecateAlert = newMutationHook<
  DeprecateAlertMutation,
  DeprecateAlertMutationVariables
>(deprecateAlertMutation);
export const useToggleAlert = newMutationHook<ToggleAlertMutation, ToggleAlertMutationVariables>(
  toggleAlertMutation
);

export function useUpdateTrigger(options?: UpdateTriggerMutationOptions) {
  return useMutation<UpdateTriggerMutation, UpdateTriggerMutationVariables>(updateTriggerMutation, {
    ...options,
    refetchQueries: REFETCH_ALERTS,
  });
}
