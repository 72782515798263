import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { IconButton, Typography } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import { reportVariance, varianceEventTypes } from '../../../../analytics/analyticsEventProperties';
import { VARIANCE } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { usePersistentStates } from '../../../../utilities/urlState';
import { transformVarianceSettings } from '../../../CostReport/CostReportList/CostReportList/CostReportListUtils';
import { useGetProjectUnitsQuery } from '../../../Milestone/hooks/UnitHooks';
import { useDetailedMilestonesQuery } from '../../../Milestones/hooks';
import { generateVarianceStorageParam } from '../../useVarianceReportParams';
import { reverseSetting, transformVarianceDefaults } from '../../VarianceReportUtils';

import VarianceModalMilestonesSelects from './VarianceModalMilestonesSelects/VarianceModalMilestonesSelects';
import styles from './VarianceModalMilestonesStyles';

type VarianceModalMilestonesProps = {
  classes: Classes<typeof styles>;
  defaultGroupBy: string[];
};

const VarianceModalMilestones: FC<VarianceModalMilestonesProps> = ({ classes, defaultGroupBy }) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const { data: { milestones = [] } = {} } = useDetailedMilestonesQuery(projectId, false);
  const sendAnalytics = useSendAnalytics();

  // load project enabled units
  const { data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] } } =
    useGetProjectUnitsQuery(projectId, true);

  const transformSettings = transformVarianceSettings(enabledUnits);
  const [settings, setSettings] = usePersistentStates<
    VarianceSettings,
    TransformVarianceReportSettings
  >(
    window.location,
    VARIANCE,
    { ...transformVarianceDefaults, groupBy: defaultGroupBy },
    generateVarianceStorageParam(projectId),
    transformSettings,
    ''
  );

  const { milestoneIDs = [], types = [], dates = [] } = settings as VarianceSettings;

  // default the milestones to something interesting
  useEffect(() => {
    if (milestoneIDs && milestoneIDs.length === 0 && milestones) {
      if (milestones.length > 1)
        setSettings({ milestoneIDs: [milestones[0].id, milestones[1].id] });
      else if (milestones.length === 1)
        setSettings({ milestoneIDs: [milestones[0].id, milestones[0].id] });
    }
  }, [milestoneIDs, milestones, setSettings]);

  if (!milestoneIDs || milestoneIDs.length === 0) return null;

  const columnHeadersText = ['Column A', 'Column B'];
  const columnHeaders = (
    <div className={classes.columnLabelContainer}>
      {columnHeadersText.map((headerText) => (
        <div key={headerText} className={classes.columnLabels}>
          <Typography className={classes.bold} variant="body1">
            {headerText}
          </Typography>
        </div>
      ))}
    </div>
  );

  const footerText = 'Variance will display as the delta from Column A to Column B.';
  const footer = (
    <div className={classes.footerContainer}>
      <Typography className={classes.bold} variant="body1">
        {footerText}
      </Typography>
    </div>
  );

  const onChangeColumnOrder = () => {
    const newMilestoneIDs = reverseSetting(milestoneIDs);
    const newTypes = reverseSetting(types);
    const newDates = reverseSetting(dates);
    setSettings({ milestoneIDs: newMilestoneIDs, types: newTypes, dates: newDates });
    sendAnalytics(
      reportVariance(varianceEventTypes.VARIANCE_SWAP_COLUMNS, {
        milestoneIDs: newMilestoneIDs,
        types: newTypes,
        dates: newDates,
      })
    );
  };

  const swapButton = (
    <div key="swapButton" className={classes.swapButtonContainer}>
      <IconButton className={classes.swapButton} disableRipple onClick={onChangeColumnOrder}>
        <SwapHorizIcon />
      </IconButton>
    </div>
  );

  return milestones.length < 2 ? (
    <Typography>Project should contain at least two milestones</Typography>
  ) : (
    <>
      {columnHeaders}
      <div className={classes.innerContainer}>
        {milestoneIDs.map((id: string, i: number) => {
          const key = id + i;
          return (
            <>
              <VarianceModalMilestonesSelects
                key={key}
                i={i}
                milestones={milestones}
                setSettings={setSettings}
                settings={settings}
              />
              {i === 0 && <>{swapButton}</>}
            </>
          );
        })}
      </div>
      {footer}
    </>
  );
};

export default withStyles(styles)(VarianceModalMilestones);
