import * as d3 from 'd3';
import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import useMemoWrapper from '../../useMemoWrapper';

type TimeDate = {
  date: Date;
};

type Value = {
  // The key string of your choice
  // Preferably API driven
  [key in string]: number;
};

// Default key fields:
const VALUE_KEY = 'value';

export default function TimelinePath<V extends Value>(props: {
  data: (V & TimeDate)[];
  field?: string;
  shouldFilterNulls?: boolean;
  hasSteps?: boolean;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  className?: string;
  strokeDasharray?: string;
  strokeWidth?: number;
  /** @deprecated do not use */
  stroke?: string;
}) {
  const line =
    useMemoWrapper(
      getLineCoords,
      props.data,
      props.x,
      props.y,
      props.field ?? VALUE_KEY,
      props.hasSteps,
      props.shouldFilterNulls
    ) ?? undefined;
  if (!props.data?.length) return null;
  return (
    <path
      className={props.stroke ? undefined : (props.className ?? 'stroke-type-primary')} // using props.stroke until pieChartDefaultColors are figma tokens
      d={line}
      data-cy="path-line"
      fill="none"
      stroke={props.stroke}
      strokeDasharray={props.strokeDasharray ?? 'none'}
      strokeWidth={props.strokeWidth ?? 1}
    />
  );
}

function getLineCoords(
  data: (TimeDate & Value)[],
  x: ScaleTime<number, number> | ScalePoint<Date>,
  y: ScaleLinear<number, number>,
  field: string,
  hasSteps: boolean | undefined,
  shouldFilterNulls: boolean | undefined
) {
  const line = d3.line<TimeDate & Value>();
  if (hasSteps) line.curve(d3.curveStepAfter);
  line.x((d) => x(d.date) ?? 0).y((d) => y(d[field]));
  if (shouldFilterNulls)
    // There is a difference in USCents type in BE and FE scalar definition
    line.defined((d) => !!d && d[field] !== 0 && (d[field] as unknown as string) !== '0');
  return line(data);
}
