import * as d3 from 'd3';
import React from 'react';

import { projectSettingsVar } from '../../api/apollo/reactiveVars';
import { formatCost } from '../../utilities/currency';

type Props = {
  yScale: d3.ScaleLinear<number, number>;
  xWidth: number;
};

export default function EstimateChartYAxis({ yScale, xWidth }: Props) {
  const settings = projectSettingsVar();
  const ticks = yScale.ticks(8);

  return (
    <g transform="translate(0, 0)">
      {/* Map over yScale ticks to create tick lines and labels */}
      {ticks.map((tickValue, _) => {
        const y = yScale(tickValue);
        return (
          <g key={tickValue} className="tick" transform={`translate(0, ${y})`}>
            {/* Tick line */}
            <line stroke="#e6e6e9" x1={0} x2={xWidth} y1={0} y2={0} />

            {/* Tick label */}
            <text
              className="type-body2"
              dy=".32em" // shift text down slightly for vertical centering
              textAnchor="end"
              x={-10} // Offset to the left of the y-axis
              y={0}
            >
              {formatCost(Number(tickValue), { short: true })}
            </text>
          </g>
        );
      })}

      {/* Y-axis line */}
      <line
        stroke="#e6e6e9"
        strokeWidth={2}
        x1={0}
        x2={0}
        y1={yScale.range()[0]}
        y2={yScale.range()[1]}
      />

      {/* Y-axis label */}
      <text
        className="y-axis-label type-body1"
        style={{ fill: 'fill-entities-estimate' }}
        textAnchor="middle" // Center text anchor
        transform="rotate(-90)" // Rotate the text to be parallel with the y-axis
        x={((yScale.range()[0] + yScale.range()[1]) / 2) * -1} // Center the label vertically based on the y-axis range
        y={-60} // Position to the left of the axis
      >
        {`Estimate in ${settings.CURRENCY}`}
      </text>
    </g>
  );
}
