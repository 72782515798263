import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    button: {
      marginLeft: 8,
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '8px',
    },
  });
