import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../../constants';
import { formatCost } from '../../../../utilities/currency';
import { isNonNullable } from '../../../../utilities/types';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import { NARROW_WIDTH } from '../../../ExecutiveDashboardV2/Charts/XAxis';
import ContingenciesTooltipContent from '../../../ExecutiveDashboardV2/ProjectDetailsCharts/ProjectContingencies/ContingenciesTooltipContent';
import { DETAILS_MARGIN } from '../../../ExecutiveDashboardV2/ProjectDetailsCharts/utils';
import useMemoWrapper from '../../../useMemoWrapper';
import CostLabelBlur from '../ChartsCostTrendline/CostLabelBlur';
import CostYAxis from '../ChartsCostTrendline/CostYAxis';
import InsightsEmptyChart from '../InsightsCost/InsightsEmptyChart';
import { getYDomainPadded, isNonNullUSCents } from '../InsightsCost/utils';
import TimelineArea from '../TimelineArea';
import TimelineChartTooltip from '../TimelineChartTooltip';
import TimelineCircle from '../TimelineCircle';
import TimelineHoverSections from '../TimelineHoverSections';
import TimelineMultiTooltipContent from '../TimelineMultiTooltip/TimelineMultiTooltipContent';
import TimelinePath from '../TimelinePath';
import TimelineXAxis from '../TimelineXAxis';

import ContingenciesFillPattern from './ContingenciesFillPattern';
import { ContingenciesTimeSeries } from './types';
import { filterCaRange, getAreaDataAllow, getAreaDataCont, getYDomain } from './utils';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  data: ContingenciesTimeSeries[];
  height: number;
  hoverDate?: Date | undefined;
  hasAllowance: boolean;
  hasContingency: boolean;
  isMiniChart?: boolean;
  isPrint?: boolean;
  setHoverDate: (date: Date | undefined) => void;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsContingencies(props: Props) {
  const {
    height,
    hoverDate,
    hasAllowance,
    hasContingency,
    isPrint,
    setHoverDate,
    today,
    totalRange,
  } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = props.isMiniChart ? DEFAULT_MARGIN : DETAILS_MARGIN;
  const data = useMemoWrapper(filterCaRange, props.data, props.totalRange);

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, data, hasAllowance, hasContingency);
  // Add vertical padding
  const { yDomain, yMin, yMax } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  // Get area data separated
  const pendingContingencies = useMemoWrapper(getAreaDataCont, data);
  const pendingAllowances = useMemoWrapper(getAreaDataAllow, data);
  // Last data index, the first point for horizontal lines after today
  const lastIndex = data.length - 1;

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  // Hover index for tooltips
  const hoverIndex = data.findIndex(({ date }) => date.getTime() === hoverDate?.getTime());
  const hoverCA = data[hoverIndex];
  const hasContingencyValue = isNonNullUSCents(hoverCA?.contingencyRemaining);
  const hasAllowanceValue = isNonNullUSCents(hoverCA?.allowanceRemaining);

  if (!data.length || !data[0]) {
    return (
      <InsightsEmptyChart height={height} margin={margin} today={today} totalRange={totalRange} />
    );
  }

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {!props.isMiniChart && (
        <>
          <TimelineXAxis
            range={xRange}
            tickTotal={width < NARROW_WIDTH ? 10 : 18}
            x={x}
            y={y}
            yPosition={yMin}
          />
          <CostYAxis
            range={yRange}
            tickFormat={(v: number) => formatCost(v, costFormat)}
            tickTotal={4}
            x={x}
            xPosition={xMin}
            y={y}
          />
        </>
      )}
      {/* Hover Line */}
      {hoverDate && (
        <TimelinePath<{ value: number }>
          // current hover date
          className="stroke-chart-axis"
          data={[
            { date: hoverDate, value: yMin },
            { date: hoverDate, value: yMax },
          ]}
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {hasContingency && (
        <>
          {/* Pending Area Contingencies */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingContingencies}
            fill="url(#pattern-contingencies)"
            hasSteps
            shouldFilterNulls
            x={x}
            y={y}
          />
          {/* Total number Contingencies */}
          <TimelinePath<{ contingencyRemaining: number }>
            // Before today
            className="stroke-entities-contingencies"
            data={data}
            field="contingencyRemaining"
            hasSteps
            shouldFilterNulls
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Contingencies
            className="stroke-entities-contingencies-future"
            data={[
              { date: data[lastIndex].date, value: data[lastIndex].contingencyRemaining },
              { date: xMax, value: data[lastIndex].contingencyRemaining },
            ]}
            shouldFilterNulls
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          {!isPrint && hoverDate && (
            <TimelineCircle<{ value: number }>
              data={{ date: hoverCA.date, value: hoverCA.contingencyRemaining }}
              fill="fill-entities-contingencies"
              x={x}
              y={y}
            />
          )}
        </>
      )}
      {hasAllowance && (
        <>
          {/* Pending Area Allowances */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingAllowances}
            fill="url(#pattern-allowances)"
            hasSteps
            shouldFilterNulls
            x={x}
            y={y}
          />
          {/* Total number Allowances */}
          <TimelinePath<{ allowanceRemaining: number }>
            // Before today
            className="stroke-entities-allowances"
            data={data}
            field="allowanceRemaining"
            hasSteps
            shouldFilterNulls
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Allowances
            className="stroke-entities-allowances-future"
            data={[
              { date: data[lastIndex].date, value: data[lastIndex].allowanceRemaining },
              { date: xMax, value: data[lastIndex].allowanceRemaining },
            ]}
            shouldFilterNulls
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          {!isPrint && hoverDate && (
            <TimelineCircle<{ value: number }>
              data={{ date: hoverCA.date, value: hoverCA.allowanceRemaining }}
              fill="fill-entities-allowances"
              x={x}
              y={y}
            />
          )}
        </>
      )}
      {/* Today */}
      <TimelinePath<{ value: number }>
        className="stroke-selection-focus-fill"
        data={todayData}
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Tooltip */}
      {!isPrint && hoverDate && (
        <TimelineChartTooltip
          key={`tooltip-${hoverIndex}-${hoverDate}`}
          content={
            props.isMiniChart ? (
              <TimelineMultiTooltipContent
                backgroundColor={[
                  // Tooltip background colors
                  hasContingency && hasContingencyValue
                    ? 'var(--colors-entities-contingencies-label)'
                    : null,
                  hasAllowance && hasAllowanceValue
                    ? 'var(--colors-entities-allowances-label)'
                    : null,
                ].filter(isNonNullable)}
                content={[
                  hasContingency && hasContingencyValue
                    ? formatCost(hoverCA.contingencyRemaining, costFormat)
                    : null,
                  hasAllowance && hasAllowanceValue
                    ? formatCost(hoverCA.allowanceRemaining, costFormat)
                    : null,
                ].filter(isNonNullable)}
                data={[
                  hasContingency && hasContingencyValue
                    ? {
                        value: hoverCA.contingencyRemaining,
                        date: hoverCA.date,
                      }
                    : null,
                  hasAllowance && hasAllowanceValue
                    ? {
                        value: hoverCA.allowanceRemaining,
                        date: hoverCA.date,
                      }
                    : null,
                ].filter(isNonNullable)}
                yDomain={yDomain}
              />
            ) : (
              <ContingenciesTooltipContent
                data={hoverCA}
                hasAllowance={hasAllowance}
                hasContingency={hasContingency}
              />
            )
          }
          data={{
            value: (yMin + yMax) / 2,
            date: hoverDate,
          }}
          isMiniChart={!!props.isMiniChart}
          isOpen
          offsetY={height / 2 - 10}
          x={x}
          y={y}
        />
      )}
      <TimelineHoverSections
        bounds={bounds}
        data={data}
        onHoverIndex={(index) =>
          index === -1 ? setHoverDate(undefined) : setHoverDate(data[index]?.date)
        }
        x={x}
      />
      <ContingenciesFillPattern
        fillBgn="fill-entities-contingencies-drawn"
        id="pattern-contingencies"
        stroke="stroke-entities-contingencies"
      />
      <ContingenciesFillPattern
        fillBgn="fill-entities-allowances-drawn"
        id="pattern-allowances"
        stroke="stroke-entities-allowances"
      />
      <CostLabelBlur />
    </SVGWithDimensions>
  );
}
