import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import { FILTER_PROJECT_LEAD, FILTER_PROJECT_TYPE } from '../../../constants';
import { InsightsProjectCounts, ProjectType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
} from '../../ProjectsList/ProjectsListUtils';
import useMemoWrapper from '../../useMemoWrapper';

import { HeaderDisplayBy } from './InsightsListHeaderPieBar';

interface Props {
  filterManager: ProjectFilterManager;
  projectCounts: InsightsProjectCounts;
  selectedDisplayBy: HeaderDisplayBy;
  setSettings: ProjectFilterSetSettings;
}

export default function HeaderDonutCharts(props: Props) {
  // Hooks
  const sendAnalytics = useSendAnalytics();

  // Filters
  const { setFilter } = props.filterManager;
  // Generate date for donut charts input
  const pieChartDataTypes = useMemoWrapper(
    generatePieChartDataTypes,
    props.projectCounts,
    props.selectedDisplayBy
  );
  const pieChartDataLeads = useMemoWrapper(
    generatePieChartDataLeads,
    props.projectCounts,
    props.selectedDisplayBy
  );

  return (
    <div className="flex gap-8">
      {/* Leads Donut */}
      {pieChartDataLeads.topEntries.length > 0 && (
        <div>
          <ChartsPieGraph
            centerLabel={{
              label: 'Leads',
              sublabel: `(${String(pieChartDataLeads.topEntries.length)})`,
            }}
            chartSize={{
              diameter: 100,
              insideDiameter: 70,
            }}
            data={pieChartDataLeads.topEntries}
            dataOther={pieChartDataLeads.otherEntries}
            hasLegendTooltip
            hasSectionTooltip
            headerTitle="Project Leads"
            isCurrency={Boolean(props.selectedDisplayBy === HeaderDisplayBy.VOLUME)}
            labelStyle="type-label"
            onSliceClick={(name: string) => {
              const matchedLead = props.filterManager.filterOptions?.projectLeads?.find(
                (lead) => lead.name === name
              );
              if (matchedLead) {
                setFilter({ type: FILTER_PROJECT_LEAD, value: matchedLead.id }, props.setSettings);
              }
            }}
            onSliceTooltip={() =>
              sendAnalytics(
                insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                  area: 'Leads',
                  level: 'detail',
                })
              )
            }
            onSummaryTooltip={() =>
              sendAnalytics(
                insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                  area: 'Leads',
                  level: 'summary',
                })
              )
            }
            title="Leads"
          />
        </div>
      )}

      {/* Types Donut */}
      {pieChartDataTypes.topEntries.length > 0 && (
        <div>
          <ChartsPieGraph
            centerLabel={{
              label: 'Type',
              sublabel: `(${String(pieChartDataTypes.topEntries.length)})`,
            }}
            chartSize={{
              diameter: 100,
              insideDiameter: 70,
            }}
            data={pieChartDataTypes.topEntries}
            dataOther={pieChartDataTypes.otherEntries}
            hasLegendTooltip
            hasSectionTooltip
            headerTitle="Project Types"
            isCurrency={Boolean(props.selectedDisplayBy === HeaderDisplayBy.VOLUME)}
            labelStyle="type-label"
            onSliceClick={(name: string) => {
              const types = props.filterManager.filterOptions?.types;
              // Check if types is defined and is of type ProjectType[]
              const matchedType = Array.isArray(types)
                ? (types as ProjectType[]).find((type) => type.name === name)
                : undefined;
              if (matchedType) {
                setFilter({ type: FILTER_PROJECT_TYPE, value: matchedType.id }, props.setSettings);
              }
            }}
            onSliceTooltip={() =>
              sendAnalytics(
                insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                  area: 'Types',
                  level: 'detail',
                })
              )
            }
            onSummaryTooltip={() =>
              sendAnalytics(
                insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                  area: 'Types',
                  level: 'summary',
                })
              )
            }
            title="Types"
          />
        </div>
      )}
    </div>
  );
}

//
// Project Types Util
//
const generatePieChartDataTypes = (
  projectCounts: InsightsProjectCounts,
  selectedDisplayBy: HeaderDisplayBy
) => {
  // Map and sort the result
  const pieChartDataTypes = projectCounts.projectTypesBreakdown
    .map((breakdownItem) => ({
      name: breakdownItem.label,
      share:
        selectedDisplayBy === HeaderDisplayBy.COUNT
          ? Number(breakdownItem.count)
          : Number(breakdownItem.volume),
    }))
    .sort((a, b) => b.share - a.share); // Sort by share value

  let topEntries = pieChartDataTypes;
  let otherEntries: { name: string; share: number }[] = [];

  // If more than 19 entries, combine the rest into "Other"
  if (pieChartDataTypes.length > 19) {
    topEntries = pieChartDataTypes.slice(0, 19);
    otherEntries = pieChartDataTypes.slice(19);

    const otherShare = otherEntries.reduce((acc, { share }) => acc + Number(share), 0);

    topEntries.push({ name: 'Other Project Types', share: otherShare });
  }

  return {
    topEntries, // The top 19 entries + "Other"
    otherEntries, // The entries that were combined into "Other"
  };
};

//
// Project Leads Util
//
const generatePieChartDataLeads = (
  projectCounts: InsightsProjectCounts,
  selectedDisplayBy: HeaderDisplayBy
) => {
  // Step 2: Map the results
  let pieChartDataLeads = projectCounts.projectLeadsBreakdown
    .map((breakdownItem) => ({
      name: breakdownItem.label,
      share:
        selectedDisplayBy === HeaderDisplayBy.COUNT
          ? Number(breakdownItem.count)
          : Number(breakdownItem.volume),
    }))
    .sort((a, b) => b.share - a.share); // Sort by share value

  // Step 3: Sort by share value, but ensure "Unassigned" is always last
  pieChartDataLeads = pieChartDataLeads.sort((a, b) => {
    if (a.name === 'Unassigned') return 1; // "Unassigned" should come last
    if (b.name === 'Unassigned') return -1; // Ensure other entries come before "Unassigned"
    return b.share - a.share; // Sort by share value for all other entries
  });

  let topEntries = pieChartDataLeads;
  let otherEntries: { name: string; share: number }[] = [];

  // If more than 19 entries, combine the rest into "Other"
  if (pieChartDataLeads.length > 19) {
    topEntries = pieChartDataLeads.slice(0, 19);
    otherEntries = pieChartDataLeads.slice(19);

    const otherShare = otherEntries.reduce((acc, { share }) => acc + Number(share), 0);

    topEntries.push({ name: 'Other Project Leads', share: Number(otherShare) });
  }

  return {
    topEntries, // The top 19 entries + "Other"
    otherEntries, // The entries that were combined into "Other"
  };
};
