import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardHeader, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { GetApp, Print } from '@material-ui/icons';

import {
  currentUserReportVar,
  isDownloadingContingencyReportToExcelVar,
  setReactiveLocal,
} from '../../../api/apollo/reactiveVars';
import { USER_REPORT_VAR } from '../../../constants';
import { ContingencyInfo } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isEnumValue } from '../../../utilities/types';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { Export } from '../../Icons/Export';
import ReportsManagerMenu from '../../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import JoinSelect from '../../Select/JoinSelect/JoinSelect';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';

import ContingencyReportSelect from './ContingencyReportSelect';
import { styles } from './ContingencyReportStyles';
import {
  ContingencyReportType,
  ContingencyReportView,
  isContingencyReportType,
  isStringArray,
} from './ContingencyReportUtils';

type ContingencyReportHeaderProps = {
  classes: Classes<typeof styles>;
  milestoneID: string;
  projectID: string;
  milestoneName?: string;
  reportView: ContingencyReportView;
  setReportView: (value: ContingencyReportView) => void;
  selectedReportType: ContingencyReportType;
  setSelectedReportType: (value: ContingencyReportType) => void;
  availableContingencies: Pick<ContingencyInfo, 'type' | 'name'>[];
  selectedContingencies: string[];
  setSelectedContingencies: (value: string[]) => void;
  selectedAllowances: string[];
  setSelectedAllowances: (value: string[]) => void;
  print: () => void;
  exportReport: () => void;
};

const ContingencyReportHeader: FC<ContingencyReportHeaderProps> = ({
  classes,
  milestoneID,
  milestoneName,
  projectID,
  reportView,
  setReportView,
  selectedReportType,
  setSelectedReportType,
  availableContingencies,
  selectedContingencies,
  setSelectedContingencies,
  selectedAllowances,
  setSelectedAllowances,
  print,
  exportReport,
}) => {
  const navigate = useNavigate();
  const isActiveMilestoneReportView = reportView === ContingencyReportView.ACTIVE_MILESTONE;

  const setSettings: SetSettingsFunctionType = (settings) => {
    const { selectedReportType, selectedContingencies, selectedAllowances } = settings;
    if (typeof selectedReportType === 'string' && isContingencyReportType(selectedReportType)) {
      setSelectedReportType(selectedReportType);
    }
    if (Array.isArray(selectedContingencies) && isStringArray(selectedContingencies)) {
      setSelectedContingencies(selectedContingencies);
    }
    if (Array.isArray(selectedAllowances) && isStringArray(selectedAllowances)) {
      setSelectedAllowances(selectedAllowances);
    }
  };

  const { isViewOnly } = usePermissions();

  const options: MenuOption[] = [
    {
      name: 'Print',
      icon: <Print />,
      callback: print,
    },
    {
      callback: () => exportReport(),
      icon: <GetApp />,
      name: 'Contingency Draw Report.xlsx',
      loadingVar: isDownloadingContingencyReportToExcelVar,
    },
  ];

  return (
    <div className={classes.reportHeader}>
      <CardHeader
        title={
          <div className={classes.cardHeader}>
            <div className={classes.cardTitle}>
              <Breadcrumbs
                links={[
                  {
                    display: 'Milestones',
                    destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES, {
                      projectId: projectID,
                    }),
                    tooltip: 'Back to milestones list',
                  },
                  reportView === ContingencyReportView.ACTIVE_MILESTONE
                    ? {
                        display: milestoneName || 'Loading...',
                        destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
                          projectId: projectID,
                          milestoneId: milestoneID,
                        }),
                        tooltip: 'Back to milestone details',
                      }
                    : { display: 'All Milestones', tooltip: 'Showing all milestones in report' },
                ]}
              />
              <Typography data-cy="CostReport-title" variant="headline">
                Contingency &amp; Allowance Draw Report
              </Typography>
            </div>
          </div>
        }
      />
      <div className={classes.reportMenuContainer}>
        <div>
          <RadioGroup
            onChange={(_, value: string) => {
              if (isEnumValue(ContingencyReportView, value)) {
                const route =
                  value === ContingencyReportView.ACTIVE_MILESTONE
                    ? RouteKeys.PROJECT_CONTINGENCY_ALLOWANCE_REPORT
                    : RouteKeys.PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT;
                if (value !== reportView) {
                  // if we are in a saved report then clear it out
                  setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);

                  setReportView(value);
                  navigate({
                    pathname: generateSharedPath(route, {
                      projectId: projectID,
                      milestoneId: milestoneID,
                    }),
                  });
                }
              } else {
                throw Error('onChange triggered without valid value.');
              }
            }}
            row
            style={{ paddingTop: 14 }}
            value={reportView}
          >
            <FormControlLabel
              control={<Radio />}
              label="Active Milestone"
              value={ContingencyReportView.ACTIVE_MILESTONE}
            />
            <FormControlLabel
              control={<Radio />}
              label="All Milestones"
              value={ContingencyReportView.ALL_MILESTONES}
            />
          </RadioGroup>
        </div>
        {isActiveMilestoneReportView && (
          <div className={classes.reportTypeSelect}>
            <Typography className={classes.reportTypeSelectLabel}>Status</Typography>
            <JoinSelect
              entries={[
                {
                  name: 'Both Pending and Accepted Items',
                  id: ContingencyReportType.ACCEPTED_AND_PENDING,
                },
                { name: 'Pending Items only', id: ContingencyReportType.PENDING },
                { name: 'Accepted Items only', id: ContingencyReportType.ACCEPTED },
              ]}
              onChange={(value) => setSelectedReportType(value)}
              value={selectedReportType}
            />
          </div>
        )}
        <div className={classes.reportTypeSelect}>
          <Typography className={classes.reportTypeSelectLabel}>Show</Typography>
          <ContingencyReportSelect
            availableContingencies={availableContingencies}
            selectedAllowances={selectedAllowances}
            selectedContingencies={selectedContingencies}
            setSelectedAllowances={setSelectedAllowances}
            setSelectedContingencies={setSelectedContingencies}
          />
        </div>
        <div className={classes.exportButtton}>
          <ReportsManagerMenu
            isViewOnly={isViewOnly}
            setSettings={setSettings}
            settings={{ selectedReportType, selectedContingencies, selectedAllowances }}
            variant={isActiveMilestoneReportView ? 'contingencyDraw' : 'contingencyAllMilestones'}
          />
          <IconMenu icon={<Export />} isBottomOriented options={options} />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ContingencyReportHeader);
