// Constants of known feature flags. These should directly reflect those in scalar/featureflag.go

export const PROJECT_COMPS = 'PROJECT_COMPS';
export const FORECASTED_COST_REPORT = 'FORECASTED_COST_REPORT';
export const YC_TIMELINE_DEFAULT_ZOOM = 'YC_TIMELINE_DEFAULT_ZOOM';
export const YC_INSIGHTS_V2 = 'YC_INSIGHTS_V2';
export const YC_DEMO_MOCKS_INSIGHTS_V2 = 'YC_DEMO_MOCKS_INSIGHTS_V2';
export const CT_ITEM_ASSIST_EXPERIMENT = 'CT_ITEM_ASSIST_EXPERIMENT';
export const CT_BRAND_ASSIST_EXPERIMENT = 'CT_BRAND_ASSIST_EXPERIMENT';
export const YC_ITEM_ESTIMATE_DELETION = 'YC_ITEM_ESTIMATE_DELETION';
export const NS_PROGRAM_REPORTING = 'NS_PROGRAM_REPORTING';
export const DD_PROJECT_IDEAS = 'DD_PROJECT_IDEAS';
export const DD_BENCHMARKING = 'DD_BENCHMARKING';
export const DD_TIMELINE_V2 = 'DD_TIMELINE_V2';
export const NS_OWNER_COSTS_V2 = 'NS_OWNER_COSTS_V2';
