import { HTMLAttributes, ReactNode, forwardRef, useRef } from 'react';
import { mergeProps, useFocusRing, useTextField } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import { StrictUnion } from '../../../utilities/types';
import ClearButton from '../ClearButton/ClearButton';
import ClearIconButton from '../ClearIconButton/ClearIconButton';
import useHasClearButton from '../hooks/useHasClearButton';
import { validateDataCy } from '../utils/data-cy';

type BaseTextInputProps = {
  autoFocus?: boolean;
  'data-cy'?: string;
  defaultValue?: string;
  endAdornment?: ReactNode;
  errorMessage?: string;
  id?: string;
  isDisabled?: boolean;
  maxLength?: number;
  name?: string;
  /** Will also be triggered by onKeyDown if key is Enter */
  onBlur?: HTMLAttributes<HTMLInputElement>['onBlur'];
  onChange?: Parameters<typeof useTextField>[0]['onChange'];
  onClear?: () => void;
  onFocus?: Parameters<typeof useTextField>[0]['onFocus'];
  /** Note: onBlur will also be called onKeyDown if key is Enter */
  onKeyDown?: Parameters<typeof useTextField>[0]['onKeyDown'];
  placeholder?: string;
  startAdornment?: ReactNode;
  type?: Parameters<typeof useTextField>[0]['type'];
  value?: string;
};

type LabelledTextInputProps = BaseTextInputProps & {
  'aria-label'?: string;
  label: string;
};

type UnlabelledTextInputProps = BaseTextInputProps & {
  'aria-label': string;
  label?: string;
};

type TextInputProps = StrictUnion<LabelledTextInputProps | UnlabelledTextInputProps>;

export default forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props, forwardedRef) {
    const { errorMessage } = props;
    const ref = useRef<HTMLInputElement>(null);
    const { labelProps, inputProps, errorMessageProps } = useTextField(
      {
        ...props,
        // @ts-expect-error because we've forced the type of onBlur here to ensure
        // we get e.currentTarget being an instanceof HTMLInputElement. This is so
        // that we can acces the `value` a la onBlur={e => onUpdateFoo(e.currentTarget.value)}
        onBlur: props.onBlur,
        onKeyDown: (e) => {
          props.onKeyDown?.(e);

          if (e.key === 'Enter') {
            ref.current?.blur();
          }
        },
        isDisabled: Boolean(props.isDisabled),
        ...(errorMessage ? { validationState: 'invalid', 'aria-errormessage': errorMessage } : {}),
      },
      ref
    );

    const { hasTextClearButton, hasInlineClearButton } = useHasClearButton({
      label: props.label,
      isClearable: Boolean(props.onClear && inputProps.value),
      isDisabled: props.isDisabled,
    });
    const handleClear = () => {
      props.onClear?.();
      ref.current?.focus();
    };

    const { focusProps, isFocused } = useFocusRing();

    validateDataCy(props['data-cy'], ['text-input', 'cost-input', 'number-input', 'search-input']);

    return (
      <div className="w-full text-type-primary">
        <div className="flex flex-col gap-0.5">
          {props.label && (
            <div className="flex">
              <label {...labelProps} className="mr-auto text-type-primary type-label">
                {props.label}
              </label>
              {hasTextClearButton && <ClearButton onClick={handleClear} />}
            </div>
          )}
          <div
            className={[
              'flex h-10 items-center gap-1 rounded-md border px-2 type-body1 placeholder:text-type-inactive',
              inputProps.disabled ? 'bg-button-inactive' : 'bg-background-primary',
              errorMessage ? 'border-type-error' : '',
              isFocused ? 'outline' : 'outline-none',
            ].join(' ')}
          >
            {props.startAdornment}
            <input
              {...mergeProps(inputProps, focusProps)}
              ref={composeRefs(ref, forwardedRef)}
              className="h-full min-w-0 flex-grow bg-background-primary outline-none type-body1 placeholder:text-type-inactive disabled:bg-button-inactive disabled:text-type-inactive"
              data-cy={props['data-cy']}
            />
            {hasInlineClearButton && <ClearIconButton onClick={handleClear} />}
            {props.endAdornment}
          </div>
          {errorMessage && (
            <div {...errorMessageProps} className="cursor-default text-type-error type-label">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
);
