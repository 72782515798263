import {
  GetProgramMarkupModeQuery,
  GetProgramMarkupModeQueryVariables,
} from '../../../../generated/graphql';
import { getProgramMarkupModeQuery } from '../../../../hooks/queries';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

export function useProgramMarkupModeQuery(programID?: UUID) {
  return useQuery<GetProgramMarkupModeQuery, GetProgramMarkupModeQueryVariables>(
    getProgramMarkupModeQuery,
    { variables: { programID }, skip: !programID }
  );
}
