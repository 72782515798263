import { ReactNode } from 'react';

import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useProjectID } from '../../../utilities/routes/params';
import { getTeamGUID } from '../../assets/utils';
import FullLogo from '../../logo/FullLogo.svg';

import TeamImage from './TeamImage';

type Props = {
  children: ReactNode;
};

/**
 * SignupContainer can be reached when you haven't verified your email, which
 * will cause 403 errors for projectPropsQuery if your URL includes a projectID
 */
export default function SignupContainer(props: Props) {
  const projectId = useProjectID();
  const {
    data: { project },
    loading,
  } = useProjectPropsQuery(projectId, MountPolicy.SKIP_ON_MOUNT);
  const { designTeamGUID, generalContractorGUID, ownerGUID } = getTeamGUID(project?.team);

  // the order here dictates the left to right appearance
  const logos = [designTeamGUID, generalContractorGUID, ownerGUID];
  // Make teamGUIDs array
  const orgLogoIDs = logos.map((logoGUID: string | null) => String(logoGUID));

  const teamImages =
    !loading && orgLogoIDs.map((assetID: UUID) => <TeamImage key={assetID} assetID={assetID} />);

  return (
    <div
      className="flex h-screen w-screen items-center justify-center bg-cover"
      style={{ backgroundImage: 'url(/img/signup/onboardingBG.svg)' }}
    >
      <img alt="joinlogo" className="fixed left-0 top-10 w-40" src={FullLogo} />

      <div className="m-12 w-full min-w-[300px] sm:w-1/2 lg:max-w-[600px]">
        <div className="mb-2 hidden type-heading2 md:block">
          {project?.name ? `Welcome to the ${project.name} team.` : 'Welcome to Join!'}
        </div>
        {props.children}
        {project?.team && (
          <div className="hidden h-auto w-full justify-around gap-4 md:flex">{teamImages}</div>
        )}
      </div>
    </div>
  );
}
