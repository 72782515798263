import { FC, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  CustomSVGSeries,
  FlexibleXYPlot,
  Hint,
  HorizontalGridLines,
  LabelSeries,
  VerticalBarSeries,
  XAxis,
  YAxis,
  // @ts-ignore because the library is not typed
} from 'react-vis/dist';

import { GetProjectBudgetGapsQuery } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { generateSharedPath } from '../../../utilities/routes/links';
import { noPermissionTooltip, percentFormatterSimple } from '../../../utilities/string';
import ChartsHintWrapper from '../../Charts/ChartsHintWrapper/ChartsHintWrapper';
import ChartsLegend from '../../Charts/ChartsLegend/ChartsLegend';
import { getLegendElementColorFunction } from '../../Charts/ChartsLegend/ChartsLegendUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import {
  ProjectMap,
  VERTICAL_BAR_CHART_BAR_WIDTH,
  VERTICAL_BAR_CHART_BOTTOM_PADDING,
  VERTICAL_BAR_CHART_HEIGHT,
  VERTICAL_BAR_CHART_LABEL_OFFSET,
  VERTICAL_BAR_CHART_LEFT_MARGIN,
  VERTICAL_BAR_CHART_RANGE,
  VERTICAL_BAR_CHART_RIGHT_PADDING,
  formatBudgetGapsLegendElementsData,
  getProjectDisplayName,
  projectBudgetGapsColorMap,
  simpleCost,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardBudgetGapsHint from './ExecutiveDashboardBudgetGapsHint';
import styles from './ExecutiveDashboardBudgetGapsStyles';

const lineBottomPosition = (y: number) => 0.9999 * y;

// Short name.
type BudgetGapData = GetProjectBudgetGapsQuery['projectBudgetGaps'][number];

type ExecutiveDashboardBudgetGapsProps = {
  classes: Classes<typeof styles>;
  displayCount?: number;
  projectMap?: ProjectMap;
  projectBudgetGaps: BudgetGapData[];
};

const ExecutiveDashboardBudgetGaps: FC<ExecutiveDashboardBudgetGapsProps> = ({
  classes,
  displayCount = 10,
  projectMap,
  projectBudgetGaps = [],
}) => {
  const t = useContext(ProjectTermStore);

  // Limit display count and ensure that fields have numeric values.
  const displayed: BudgetGapData[] = projectBudgetGaps.slice(0, displayCount).map((gap) => ({
    ...gap,
    accepted: Number(gap.accepted),
    budget: Number(gap.budget),
    estimate: Number(gap.estimate),
    runningTotal: Number(gap.runningTotal),
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const [value, setValue] = useState<any>(null);

  const hint = useMemo(() => {
    if (!value) return null;
    return (
      <Hint
        align={{
          horizontal: Hint.ALIGN.AUTO,
          vertical: Hint.ALIGN.AUTO,
        }}
        value={value}
      >
        <ChartsHintWrapper content={<ExecutiveDashboardBudgetGapsHint value={value} />} />
      </Hint>
    );
  }, [value]);

  const barValue = (data: BudgetGapData, y: number) => {
    const { projectID } = data;
    return {
      x: projectID,
      y,
      name: projectMap?.get(projectID)?.name,
      ...data,
    };
  };

  const xAxis = (
    <XAxis
      className={classes.xAxis}
      tick
      tickFormat={(projectID: string) => {
        const {
          code,
          hasAccess = false,
          milestoneName = '',
          name = '',
        } = projectMap?.get(projectID) || {};
        return hasAccess ? (
          <tspan x="0" y="0">
            <NormalTooltip
              title={
                <>
                  <div>{name}</div>
                  <div>{milestoneName}</div>
                </>
              }
            >
              <tspan>
                <Link to={generateSharedPath(RouteKeys.PROJECT, { projectId: projectID })}>
                  {getProjectDisplayName(code, name)}
                </Link>
              </tspan>
            </NormalTooltip>
          </tspan>
        ) : (
          <tspan className={classes.noPermission}>
            <NormalTooltip title={noPermissionTooltip(name)}>
              <tspan>{getProjectDisplayName(code, name)}</tspan>
            </NormalTooltip>
          </tspan>
        );
      }}
      tickValues={displayed.map((gap: BudgetGapData) => gap.projectID)}
    />
  );
  Array.from(projectBudgetGapsColorMap(t).keys());

  const yAxis = (
    <YAxis
      className={classes.yAxis}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      tickFormat={(v: any) => formatCost(v * 100, { short: true })}
      tickTotal={5}
    />
  );

  const colorMap = projectBudgetGapsColorMap(t);
  const data = Array.from(colorMap.keys());

  const getLegendElementColor = getLegendElementColorFunction(colorMap);

  return (
    <div className={classes.rowContainer}>
      <ChartsLegend
        getLegendElementColor={getLegendElementColor}
        legendElements={formatBudgetGapsLegendElementsData(data, t)}
      />
      <FlexibleXYPlot
        className={classes.graph}
        dontCheckIfEmpty
        height={VERTICAL_BAR_CHART_HEIGHT}
        margin={{
          bottom: VERTICAL_BAR_CHART_BOTTOM_PADDING,
          left: VERTICAL_BAR_CHART_LEFT_MARGIN,
          right: VERTICAL_BAR_CHART_RIGHT_PADDING,
        }}
        onMouseLeave={() => setValue(null)}
        stackBy="y"
        xDomain={displayed.map((data: BudgetGapData) => data.projectID)}
        xType="ordinal"
        yBaseValue={0}
        yDomain={displayed.length === 0 && [0, 100000000]}
        yRange={VERTICAL_BAR_CHART_RANGE}
      >
        <HorizontalGridLines className={classes.gridLines} />
        {yAxis}

        {/* Estimate/running total portion of bar */}
        <VerticalBarSeries
          barWidth={VERTICAL_BAR_CHART_BAR_WIDTH}
          className={classes.remaining}
          data={displayed
            .map((gap: BudgetGapData) => {
              const { runningTotal, estimate } = gap;
              if (estimate !== 0) {
                const y = runningTotal >= estimate ? estimate : runningTotal;
                const type = runningTotal >= estimate ? 'estimate' : 'running';
                return {
                  ...barValue(gap, simpleCost(y)),
                  type,
                };
              }
              return null;
            })
            .filter((c) => !!c)}
          onValueMouseOut={() => setValue(null)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onValueMouseOver={(v: any) => setValue(v)}
          stack
        />
        {/* Accepted portion of bar for accepted < 0 */}
        <VerticalBarSeries
          barWidth={VERTICAL_BAR_CHART_BAR_WIDTH}
          className={classes.remaining}
          colorType="literal"
          data={displayed
            .map((gap: BudgetGapData) => {
              const { estimate, runningTotal } = gap;
              if (runningTotal < estimate) {
                const y = estimate - runningTotal;
                return {
                  ...barValue(gap, simpleCost(y)),
                  type: 'accepted',
                };
              }
              return null;
            })
            .filter((c) => !!c)}
          onValueMouseOut={() => setValue(null)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onValueMouseOver={(v: any) => setValue(v)}
          stack={false}
        />
        {/* Accepted portion of bar for accepted > 0 */}
        <VerticalBarSeries
          barWidth={VERTICAL_BAR_CHART_BAR_WIDTH}
          className={classes.change}
          colorType="literal"
          data={displayed
            .map((gap: BudgetGapData) => {
              const { estimate, runningTotal } = gap;
              if (runningTotal >= estimate) {
                const y = runningTotal - estimate;
                return {
                  ...barValue(gap, simpleCost(y)),
                  type: 'accepted',
                };
              }
              return null;
            })
            .filter((c) => !!c)}
          onValueMouseOut={() => setValue(null)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onValueMouseOver={(v: any) => setValue(v)}
          stack={false}
        />
        {/* Display x-axis in front of bars */}
        {xAxis}
        {/* Estimate line */}
        <VerticalBarSeries
          barWidth={VERTICAL_BAR_CHART_BAR_WIDTH}
          className={classes.estimate}
          data={displayed
            .map((data: BudgetGapData) => {
              const { estimate, projectID } = data;
              const y = simpleCost(estimate);
              if (Number.isNaN(y)) return null;
              return {
                x: projectID,
                y,
                y0: lineBottomPosition(y),
                style: { ...theme.typography.number },
              };
            })
            .filter((c) => !!c)}
          stack={false}
        />
        {/* Running total line */}
        <VerticalBarSeries
          barWidth={VERTICAL_BAR_CHART_BAR_WIDTH}
          className={classes.running}
          data={displayed
            .map((data: BudgetGapData) => {
              const { estimate, projectID, runningTotal } = data;
              if (estimate !== runningTotal) {
                const v = simpleCost(runningTotal);
                if (Number.isNaN(v)) return null;
                return {
                  x: projectID,
                  y: v,
                  y0: lineBottomPosition(v),
                };
              }
              return null;
            })
            .filter((c) => !!c)}
          stack={false}
        />
        {/* Budget line */}
        <VerticalBarSeries
          className={classes.budget}
          data={displayed
            .map((gap: BudgetGapData) => {
              const { budget } = gap;
              if (budget > 0) {
                const y = simpleCost(budget);
                if (Number.isNaN(y)) return null;
                return {
                  ...barValue(gap, y),
                  type: 'budget',
                  y0: lineBottomPosition(y),
                };
              }
              return null;
            })
            .filter((c) => !!c)}
          onValueMouseOut={() => setValue(null)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onValueMouseOver={(v: any) => setValue(v)}
          stack={false}
        />
        {/* Accepted arrows */}
        <CustomSVGSeries
          data={displayed
            .map((data: BudgetGapData) => {
              const { estimate, projectID, runningTotal } = data;
              const isIncrease = runningTotal > estimate;
              if (runningTotal !== estimate) {
                return {
                  x: projectID,
                  y: simpleCost((runningTotal + estimate) / 2),
                  customComponent: () => (
                    <g className={classes.arrow}>
                      <line x1="0" x2="0" y1={isIncrease ? 7 : -7} y2={isIncrease ? -6 : 6} />
                      <polyline points={isIncrease ? '-4,-3 0,-7, 4,-3' : '-4,3 0,7, 4,3'} />
                    </g>
                  ),
                };
              }
              return null;
            })
            .filter((c) => !!c)}
          labelAnchorX="middle"
          labelAnchorY="middle"
        />
        {/* Budget gap % label */}
        <LabelSeries
          className={classes.dataLabel}
          data={displayed
            .map((data: BudgetGapData) => {
              const { budget, estimate, projectID, relativeGap, runningTotal } = data;
              if (budget !== 0) {
                return {
                  label: percentFormatterSimple.format(relativeGap),
                  x: projectID,
                  y: simpleCost(Math.max(budget, estimate, runningTotal)),
                  yOffset: VERTICAL_BAR_CHART_LABEL_OFFSET,
                };
              }
              return null;
            })
            .filter((c) => !!c)}
          labelAnchorX="middle"
          labelAnchorY="bottom"
          stack={false}
        />
        {hint}
      </FlexibleXYPlot>
    </div>
  );
};

export default withStyles(styles)(ExecutiveDashboardBudgetGaps);
