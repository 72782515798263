import { useEffect } from 'react';
import { isUUID } from 'validator';

import { useMutation } from '@apollo/client';

import {
  SETTINGS_RESET_PROJECT_TERMINOLOGY,
  SETTINGS_UPDATE_PROJECT_TERMINOLOGY,
} from '../../../actions/actionTypes';
import {
  analyticsEvent,
  updateProjectSettingsAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { projectSettingsVar } from '../../../api/apollo/reactiveVars';
import {
  ProjectSettingsQuery,
  ProjectSettingsQueryVariables,
  ProjectTerminologyQuery,
  ProjectTerminologyQueryVariables,
  ResetProjectTerminologyMutation,
  ResetProjectTerminologyMutationVariables,
  UpdateProjectSettingMutation,
  UpdateProjectSettingMutationVariables,
  UpdateProjectTerminologyMutation,
  UpdateProjectTerminologyMutationVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import {
  projectSettingsQuery,
  projectTerminologyQuery,
  resetProjectTerminologyMutation,
  updateProjectSettingMutation,
  updateProjectTerminologyMutation,
} from './queries';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const useUpdateProjectTerminology: any = (projectID: UUID, term: Terminology) => {
  const sendAnalytics = useSendAnalytics();
  const [updateTermFunc, mutationResult] = useMutation<
    UpdateProjectTerminologyMutation,
    UpdateProjectTerminologyMutationVariables
  >(updateProjectTerminologyMutation, {
    refetchQueries: [
      {
        query: projectTerminologyQuery,
        variables: { projectID },
      },
    ],
  });

  const submitFunc = (overrideValue: string, onSuccess?: (result: Terminology[]) => void) =>
    updateTermFunc({
      variables: {
        projectID,
        key: term.key,
        overrideValue,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    }).then((result: any) => {
      const { data = { updateProjectTerminology: [] } } = result;
      if (onSuccess) onSuccess(data.updateProjectTerminology as Terminology[]);
      sendAnalytics(analyticsEvent(SETTINGS_UPDATE_PROJECT_TERMINOLOGY));
    });

  return [submitFunc, mutationResult];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const useResetProjectTerminology: any = (projectID: UUID, term: Terminology) => {
  const sendAnalytics = useSendAnalytics();
  const [resetTermFunc, mutationResult] = useMutation<
    ResetProjectTerminologyMutation,
    ResetProjectTerminologyMutationVariables
  >(resetProjectTerminologyMutation, {
    refetchQueries: [
      {
        query: projectTerminologyQuery,
        variables: { projectID },
      },
    ],
  });

  const submitFunc = () =>
    resetTermFunc({
      variables: {
        projectID,
        key: term.key,
      },
    }).then(() => {
      sendAnalytics(analyticsEvent(SETTINGS_RESET_PROJECT_TERMINOLOGY));
    });

  return [submitFunc, mutationResult];
};

export function useProjectTerminology(projectID?: UUID): Terminology[] | null {
  const { loading, error, data } = useQuery<
    ProjectTerminologyQuery,
    ProjectTerminologyQueryVariables
  >(projectTerminologyQuery, {
    variables: { projectID },
    skip: !projectID || !isUUID(projectID),
  });

  if (!data || loading || error) {
    return null;
  }
  const { projectTerminology } = data;
  return projectTerminology;
}

export const defaultProjectSettings: ProjectSettingStore = {
  ROUNDING_PRECISION: 3,
  CURRENCY: 'USD',
  SCHEDULE_IMPACT_DISPLAY: 'WORK_DAYS',
  PREV_SCHEDULE_IMPACT_DISPLAY: 'WORK_DAYS',
};
export const buildProjectSettingStore = (settings: ProjectSetting[]): ProjectSettingStore => {
  if (!settings || settings.length === 0) {
    return defaultProjectSettings;
  }
  const newSettings = {
    ...defaultProjectSettings,
  };
  settings.forEach((setting) => {
    if (setting.key === 'ROUNDING_PRECISION') {
      newSettings.ROUNDING_PRECISION = parseInt(setting.value, 10);
    } else if (setting.key === 'CURRENCY') {
      newSettings[setting.key] = setting.value;
    } else if (setting.key === 'SCHEDULE_IMPACT_DISPLAY') {
      newSettings[setting.key] = setting.value;
    } else if (setting.key === 'PREV_SCHEDULE_IMPACT_DISPLAY') {
      newSettings[setting.key] = setting.value;
    }
  });
  return newSettings;
};

const useSetProjectSettings = (settings: ProjectSettingStore) => {
  const dependencies = Object.values(settings);
  useEffect(() => {
    projectSettingsVar(settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, dependencies);
};

export const useProjectSettings = (projectID?: UUID): ProjectSettingStore => {
  const { loading, error, data } = useQuery<ProjectSettingsQuery, ProjectSettingsQueryVariables>(
    projectSettingsQuery,
    {
      variables: { projectID },
      skip: !projectID || !isUUID(projectID),
    }
  );
  const hasData = !loading && !error && data;
  const projectSettings = data?.projectSettings;
  const store =
    hasData && projectSettings ? buildProjectSettingStore(projectSettings) : defaultProjectSettings;
  useSetProjectSettings(store);
  return store;
};

export const useUpdateProjectSetting = (projectID: UUID, key: string) => {
  const sendAnalytics = useSendAnalytics();
  const [updateSettingFunc] = useMutation<
    UpdateProjectSettingMutation,
    UpdateProjectSettingMutationVariables
  >(updateProjectSettingMutation, {
    update(cache, { data }) {
      const updateProjectSetting = data?.updateProjectSetting;
      if (!updateProjectSetting) return;
      projectSettingsVar(buildProjectSettingStore(updateProjectSetting));
      cache.modify({
        fields: {
          projectSettings() {
            return updateProjectSetting;
          },
        },
      });
    },
  });
  const submitFunc = (value: string) => {
    return updateSettingFunc({
      variables: {
        projectID,
        key,
        value,
      },
    }).then(() => {
      sendAnalytics(updateProjectSettingsAnalytics(key, value));
    });
  };

  return submitFunc;
};
