import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CostReportColumnType, GetMilestoneItemCountQuery } from '../../../../generated/graphql';
import { getItemStatusFromString, getItemStatusLabel } from '../../../../utilities/item-status';
import { pluralizeCountString } from '../../../../utilities/string';
import { cellContent } from '../../../CostReport/CostReportList/CostReportListRow/CostReportListRowUtils';
import { getCostReportByType } from '../../../CostReport/CostReportUtils';
import { ItemStatusIcon } from '../../../dragon-scales';
import { FILTERED_STATUSES } from '../../../ItemsList/ItemsListUtils';
import { useMilestoneCostReportsQuery } from '../../../Milestone/hooks';
import { useMilestoneItemCountQuery } from '../../../Milestone/hooks/useMilestoneQuery';
import { Table } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import CostSummary from '../../common/CostSummary';
import { ScenarioMilestone } from '../../types';

import PresentationColumn from './PresentationColumn';

type Props = {
  milestone: ScenarioMilestone;
  milestoneName: string;
  onLoad?: () => void;
  shouldGrow: boolean;
};

enum MilestoneTableColumns {
  COUNT = 'Item Count',
  COST = 'Cost',
  STATUS = 'Items Status',
}

export default function MilestonePresentationColumn(props: Props) {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');
  const activeMilestoneId = props.milestone.milestoneID;
  const milestoneCostReports = useMilestoneCostReportsQuery(
    activeMilestoneId,
    projectId,
    {},
    STATUSES.map((type) => ({ type }))
  ).data?.milestoneCostReports;
  const costReport = (milestoneCostReports || [])[0];

  // Load the data for Milestone Items
  const { data: milestoneItemCountData, loading } = useMilestoneItemCountQuery(activeMilestoneId);
  const itemCounts = milestoneItemCountData?.itemCounts;
  const statusData = useMemoWrapper(computeStatusData, costReport, itemCounts);

  // Prep the table
  const headerContent = [
    {
      key: MilestoneTableColumns.STATUS,
      copy: MilestoneTableColumns.STATUS,
    },
    {
      key: MilestoneTableColumns.COUNT,
      copy: MilestoneTableColumns.COUNT,
    },
    {
      key: MilestoneTableColumns.COST,
      copy: MilestoneTableColumns.COST,
      rightAligned: true,
    },
  ];

  const entries = useMemo(
    () =>
      statusData.map((d) =>
        d
          ? [
              {
                component: (
                  <div className="flex h-8 items-center gap-1 type-table-text">
                    <ItemStatusIcon value={getItemStatusFromString(d.status)} />
                    {getItemStatusLabel(d.status)}
                  </div>
                ),
                key: MilestoneTableColumns.STATUS,
              },
              {
                component: (
                  <div className="flex h-8 flex-grow items-center type-table-text">
                    {pluralizeCountString('item', d.itemCount)}
                  </div>
                ),
                key: MilestoneTableColumns.COUNT,
              },
              {
                component: (
                  <div className="flex h-8 items-center type-table-number">
                    {cellContent(undefined, undefined, d.segmented).text}
                  </div>
                ),
                key: MilestoneTableColumns.COST,
              },
            ]
          : []
      ),
    [statusData]
  );

  // Rendering, onComplete
  const { onLoad } = props;
  useEffect(() => {
    if (onLoad && entries.length > 0) {
      onLoad();
    }
  }, [onLoad, entries]);

  return (
    <PresentationColumn shouldGrow={props.shouldGrow}>
      <div className="flex h-16 flex-shrink-0 items-center rounded bg-background-2 p-2 text-type-primary type-heading2">
        {props.milestoneName}
      </div>
      <div className="flex flex-grow flex-col gap-2 py-3">
        <div className="type-heading3">Items Summary</div>
        <Table
          columnWidths={['minmax(200px,6fr)', 'max-content', 'max-content']}
          entries={entries}
          headerContent={headerContent}
          isCompact
          isLoading={loading}
        />
      </div>
      <div className="grow-0">
        <CostSummary costReports={props.milestone.costReports} />
      </div>
    </PresentationColumn>
  );
}

const STATUSES = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.REMAINING_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.TARGET_REPORT,
];

type ItemCounts = GetMilestoneItemCountQuery['itemCounts'];

const combineReportWithItemByStatus = (
  type: CostReportColumnType,
  costReports: MilestoneCostReport,
  itemCountByStatus: ItemCounts | undefined | null
) => {
  // we combine the cost report with
  // item counts by status
  const report = getCostReportByType(costReports, type);
  const status = FILTERED_STATUSES.find((s) => type.includes(s));
  if (report && status && itemCountByStatus) {
    const key = status.toLowerCase() as keyof ItemCounts;
    const { segmented } = report;
    const { adds, deducts } = segmented;
    const itemCount = itemCountByStatus[key] || 0;
    return { status, segmented, adds, deducts, itemCount: Number(itemCount) };
  }
  return null;
};

const computeStatusData = (
  costReports: MilestoneCostReport,
  itemCountByStatus: ItemCounts | undefined | null
) =>
  costReports && costReports.costReportColumns.length > 0
    ? STATUSES.map((status) =>
        combineReportWithItemByStatus(status, costReports, itemCountByStatus)
      )
    : [];
