import { ProjectCostBreakdown } from '../../generated/graphql';
import DashboardAnchor from '../dashboard/DashboardAnchor';
import ProjectCostBreakdownReportChart from '../ProjectCostBreakdownReport/ProjectCostBreakdownReportChart';
import { ReportTableHeader } from '../ProjectCostBreakdownReport/ProjectCostBreakdownReportTable';
import ShadedHelpTip from '../shared-widgets/ShadedHelpTip/ShadedHelpTip';

type Props = {
  breakdown: Omit<ProjectCostBreakdown, 'type'>[];
  colorMap: Map<string, string>;
  headers: ReportTableHeader[];
  label: string;
  name: string;
  orderingMap: Map<string, number>;
  shouldTableChartData?: boolean;
  sublabel?: string;
  tip?: string;
  total: number;
};

export default function ProgramDashboardPieChart({
  breakdown,
  colorMap,
  headers,
  label,
  name,
  orderingMap,
  shouldTableChartData = false,
  sublabel,
  tip,
  total,
}: Props) {
  return (
    <div>
      <div className="flex">
        <div className="flex-grow">
          <DashboardAnchor anchor={name} />
        </div>
        {tip && <ShadedHelpTip tip={tip} />}
      </div>
      <div className="flex w-full justify-center">
        <div className="w-[1000px]">
          <ProjectCostBreakdownReportChart
            costBreakdownTypeToColorMap={colorMap}
            headers={headers}
            label={label}
            orderingMap={orderingMap}
            projectCostBreakdownReport={{ breakdown, total }}
            shouldTableChartData={shouldTableChartData}
            sublabel={sublabel}
          />
        </div>
      </div>
    </div>
  );
}
