import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';

import { setItemAssetsExpanded } from '../../../analytics/analyticsEventProperties';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getItemIdFromUrl } from '../../../utilities/url';
import AssetsWrapperItemData from '../../assets/AssetsWrapper/AssetsWrapperItemData';

import AssetsCollapseStyles from './AssetsCollapseStyles';
import ItemDetailsCollapse from './ItemDetailsCollapse';

type AssetsCollapseProps = {
  canView: boolean;
  classes: Classes<typeof AssetsCollapseStyles>;
  numItemAttachments: number;
  projectID: UUID;
};

const AssetsCollapse: FC<AssetsCollapseProps> = ({
  canView = false,
  classes,
  numItemAttachments,
  projectID,
}) => {
  const itemID = getItemIdFromUrl();

  const [isExpanded, setIsExpanded] = useState(true);

  const headerContent = (
    <Typography className={classes.collapseTitle} data-cy="item-details-attachment-panel">
      {`Attachments (${numItemAttachments})`}
    </Typography>
  );

  return (
    <div className="pb-2">
      <ItemDetailsCollapse
        analyticsEvent={setItemAssetsExpanded(isExpanded)}
        canExpand={canView}
        headerContent={headerContent}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      >
        <AssetsWrapperItemData itemID={itemID} projectID={projectID} />
      </ItemDetailsCollapse>
    </div>
  );
};

export default withStyles(AssetsCollapseStyles)(AssetsCollapse);
