import { fromNow } from '../../../../utilities/dates';

type Props = {
  report: ForecastingReport;
};

// TODO: Could merge with Project Cell
export default function LastUpdatedCell(props: Props) {
  return (
    <div className="type-label">{`${fromNow(props.report.updatedAt)} by ${props.report.updatedBy.name}`}</div>
  );
}
