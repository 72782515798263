import { Search } from '@material-ui/icons';

import {
  ForecastingReportShareEvent,
  getForecastingReportShareEvent,
} from '../../../analytics/analyticsEventProperties';
import { DD_BENCHMARKING } from '../../../features';
import { AccessLevel, ResourceType } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { pluralizeString } from '../../../utilities/string';
import { ShareDialog } from '../../dragon-scales';
import CompanyUserTable from '../../dragon-scales/Share/CompanyUserTable/CompanyUserTable';
import { getSelectedText } from '../../dragon-scales/Share/utils';
import { useShareResources } from '../../ItemsList/ItemsSharing/hooks/useShareResourceHook';
import { DialogContent, TextInput } from '../../scales';

import { useSearchForecastingUsers } from './useSearchForecastingUsers';
import { useSelectedRemovedAddedUserIDs } from './useSelectedRemovedAddedUserIDs';

export type Props = {
  reportName: string;
  reportID: UUID;
  onClose: () => void;
};

const EMPLOYEE = 'employee';

export default function ShareForecastingReportDialog(props: Props) {
  const hasBenchmarkingFeature = useHasFeature(DD_BENCHMARKING);
  const shareResources = useShareResources();
  const sendAnalytics = useSendAnalytics();

  const { displayCompanyUsers, forecastingUsersCount, searchTerm, setSearchTerm, usersLoading } =
    useSearchForecastingUsers();
  const searchText = `${displayCompanyUsers.length} of ${forecastingUsersCount} ${pluralizeString(EMPLOYEE, forecastingUsersCount)} shown`;

  const {
    newUserIDs,
    refetch,
    removedUserIDs,
    resourceLoading,
    selectedUserIDs,
    setSelectedUserIDs,
    sharedUserIDs,
  } = useSelectedRemovedAddedUserIDs(props.reportID);
  const { selectedText, summaryText } = getSelectedText(
    sharedUserIDs || [],
    newUserIDs,
    removedUserIDs,
    EMPLOYEE
  );
  const onShare = () => {
    const userIDs = [...newUserIDs, ...removedUserIDs];
    const accessLevels = [
      ...newUserIDs.map(() => AccessLevel.DEFAULT),
      ...removedUserIDs.map(() => AccessLevel.NONE),
    ];
    shareResources(ResourceType.FORECASTING_REPORT, [props.reportID], userIDs, accessLevels, () => {
      refetch();
    });
    sendAnalytics(
      getForecastingReportShareEvent(ForecastingReportShareEvent.SHARE_CLICK, {
        newUserCount: newUserIDs.length,
        removedUserCount: removedUserIDs.length,
        sharedUsersCount: sharedUserIDs?.length || 0,
      })
    );
    props.onClose();
  };

  // Disable to prevent last un-sharing or prevent removal of author
  const disableShareButton = newUserIDs.length === 0 && removedUserIDs.length === 0;
  const shareLabel = newUserIDs.length === 0 ? 'Update' : 'Share';
  const dialogDescription = `Project Comparison reports can be shared with other users in your company who have access to the ${hasBenchmarkingFeature ? 'Benchmarking' : 'Forecasting'} module.`;

  return (
    <ShareDialog
      description={dialogDescription}
      isOpen
      isShareDisabled={disableShareButton}
      onClose={props.onClose}
      onShare={onShare}
      selectedText={selectedText}
      shareLabel={shareLabel}
      summaryText={summaryText}
      title={`Share ${props.reportName}`}
    >
      <DialogContent className="flex h-full flex-col gap-2 pb-0">
        <div className="flex w-full items-center gap-4">
          <TextInput
            aria-label="search users"
            data-cy="users-search-input"
            onChange={setSearchTerm}
            placeholder="Find people to share with"
            startAdornment={<Search />}
            value={searchTerm}
          />
          <div className="flex-shrink-0">{searchText}</div>
        </div>
        {!!selectedUserIDs && (
          <CompanyUserTable
            companyUsers={displayCompanyUsers}
            isLoading={usersLoading || resourceLoading}
            searchTerm={searchTerm}
            selectedUserIDs={selectedUserIDs}
            setSelectedUserIDs={setSelectedUserIDs}
          />
        )}
      </DialogContent>
    </ShareDialog>
  );
}
