import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { noPermissionTooltip } from '../../utilities/string';
import { Tooltip } from '../scales';

type Props = {
  children: ReactNode;
  hasAccess: boolean;
  isProgram?: boolean;
  to: LinkProps['to'];
  projectName: string;
};

export default function ProjectLink(props: Props) {
  if (props.hasAccess) {
    return (
      <Link className="type-link" to={props.to}>
        {props.children}
      </Link>
    );
  }
  return (
    <Tooltip content={noPermissionTooltip(props.projectName, props.isProgram)}>
      <div className="cursor-not-allowed">{props.children}</div>
    </Tooltip>
  );
}

ProjectLink.defaultProps = {
  isProgram: false,
};
