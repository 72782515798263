import { formatRelative } from 'date-fns';

import en from 'date-fns/locale/en-US';

import {
  ITEM_ACTIVITY_AUTODESK_POTENTIAL_CHANGE_ORDER_LINK_CTA,
  ITEM_ACTIVITY_CREATE_AUTODESK_POTENTIAL_CHANGE_ORDER,
  ITEM_ACTIVITY_CREATE_PROCORE_CHANGE_EVENT,
  ITEM_ACTIVITY_PROCORE_CHANGE_EVENT_LINK_CTA,
  ITEM_ACTIVITY_UNLINK_AUTODESK_POTENTIAL_CHANGE_ORDER,
  ITEM_ACTIVITY_UNLINK_PROCORE_CHANGE_EVENT,
} from '../../../../actions/actionTypes';
import { isProduction } from '../../../../api/joinAPI';
import {
  AutodeskPotentialChangeOrder,
  CreateItemIntegrationObjectInput,
  ItemIntegrationObjectsQuery,
  ProcoreChangeEvent,
  SourceSystem,
  UserSourcesQuery,
} from '../../../../generated/graphql';
import { RouteKeys } from '../../../../routes/paths';
import { LocaleRelative } from '../../../../utilities/dates';
import { generateSharedPath } from '../../../../utilities/routes/links';
import {
  SourceInfo,
  sourceSystemInfo,
} from '../../../DocumentMagic/Files/FilesDialog/FilesDialogUtils';
import { SelectEntry } from '../../../scales';

export type ItemInfo = Pick<
  Item,
  'id' | 'name' | 'description' | 'estimateCost' | 'assetCount' | 'contingencyDrawCost'
>;

export type UserSource = UserSourcesQuery['userSources']['sources'][number];
export const SUPPORTED_SOURCE_SYSTEMS = [SourceSystem.AUTODESK, SourceSystem.PROCORE];

// fake data for a procore change event
export const fakeProcoreChangeEvents: ProcoreChangeEvent[] = [
  {
    integrationObjectID: '1',
    number: 1,
    alphanumericNumber: '1',
    title: 'Fire Sprinkler Updates',
    description: 'Adding fire sprinklers to the building',
    status: 'Open',
    createdAt: '2021-01-01T00:00:00.000Z',
    updatedAt: '2021-01-01T00:00:00.000Z',
    eventType: 'Owner Change',
    changeReason: 'Client Request',
    eventScope: 'tbd',
    cost: 100000,
    isDeleted: false,
  },
  {
    integrationObjectID: '2',
    number: 2,
    alphanumericNumber: '2',
    title: 'Upgrade to LED Lighting',
    description: 'Upgrading all lighting to LED',
    status: 'Open',
    createdAt: '2021-01-01T00:00:00.000Z',
    updatedAt: '2021-01-01T00:00:00.000Z',
    eventType: 'Allowance',
    changeReason: 'Client Request',
    eventScope: 'tbd',
    cost: 50000,
    isDeleted: false,
  },
];

// fake data for an autodesk potential change order
export const fakeAutodeskPotentialChangeOrders: AutodeskPotentialChangeOrder[] = [
  {
    integrationObjectID: 'autodesk-pco-id',
    number: 1,
    title: 'Fire Sprinkler Updates',
    description: 'Adding fire sprinklers to the building',
    createdAt: '2021-01-01T00:00:00.000Z',
    updatedAt: '2021-01-01T00:00:00.000Z',
    eventType: 'Owner Change',
    cost: 100000,
  },
];

export const EventStatuses = {
  OPEN: 'open',
  CLOSED: 'closed',
  PENDING: 'pending',
  VOID: 'void',
};

export const changeEventStatuses: SelectEntry[] = [
  { label: 'Open', id: EventStatuses.OPEN },
  { label: 'Closed', id: EventStatuses.CLOSED },
  { label: 'Pending', id: EventStatuses.PENDING },
  { label: 'Void', id: EventStatuses.VOID },
];

export const ChangeEventTypes = {
  ALLOWANCE: 'allowance',
  CONTINGENCY: 'contingency',
  OWNER_CHANGE: 'owner_change',
  TBD: 'tbd',
  TRANSFER: 'transfer',
};

export const changeEventTypes: SelectEntry[] = [
  { label: 'Allowance', id: ChangeEventTypes.ALLOWANCE },
  { label: 'Contingency', id: ChangeEventTypes.CONTINGENCY },
  { label: 'Owner Change', id: ChangeEventTypes.OWNER_CHANGE },
  { label: 'TBD', id: ChangeEventTypes.TBD },
  { label: 'Transfer', id: ChangeEventTypes.TRANSFER },
];

export const PotentialChangeOrderTypes = {
  BUDGET_TRANSFER: 'Budget Transfer',
  OWNER_DIRECT_PURCHASE: 'Owner Direct Purchase',
  OWNER_DIRECTIVE: 'Owner Directive',
  INTERNAL_CHANGE: 'Internal Change',
  CONTINGENCY: 'Contingency',
  BACK_CHARGE: 'Back Charge',
  SUBCONTRACT_QUOTATION: 'Subcontract Quotation',
  PURCHASE_ORDER_QUOTATION: 'Purchase Order Quotation',
  SUBCONTRACT_CHANGE_ORDER: 'Subcontract Change Order',
  PURCHASE_ORDER_CHANGE_ORDER: 'Purchase Order Change Order',
};

export const potentialChangeOrderTypes: SelectEntry[] = [
  { label: 'Budget Transfer', id: PotentialChangeOrderTypes.BUDGET_TRANSFER },
  { label: 'Owner Direct Purchase', id: PotentialChangeOrderTypes.OWNER_DIRECT_PURCHASE },
  { label: 'Owner Directive', id: PotentialChangeOrderTypes.OWNER_DIRECTIVE },
  { label: 'Internal Change', id: PotentialChangeOrderTypes.INTERNAL_CHANGE },
  { label: 'Contingency', id: PotentialChangeOrderTypes.CONTINGENCY },
  { label: 'Back Charge', id: PotentialChangeOrderTypes.BACK_CHARGE },
  { label: 'Subcontract Quotation', id: PotentialChangeOrderTypes.SUBCONTRACT_QUOTATION },
  { label: 'Purchase Order Quotation', id: PotentialChangeOrderTypes.PURCHASE_ORDER_QUOTATION },
  { label: 'Subcontract Change Order', id: PotentialChangeOrderTypes.SUBCONTRACT_CHANGE_ORDER },
  {
    label: 'Purchase Order Change Order',
    id: PotentialChangeOrderTypes.PURCHASE_ORDER_CHANGE_ORDER,
  },
];

export const ChangeReasons = {
  ALLOWANCE: 'Allowance',
  BACKCHARGE: 'Backcharge',
  CLIENT_REQUEST: 'Client Request',
  DESIGN_DEVELOPMENT: 'Design Development',
  EXISTING_CONDITION: 'Existing Condition',
};

export const changeEventChangeReasons: SelectEntry[] = [
  { label: 'Allowance', id: ChangeReasons.ALLOWANCE },
  { label: 'Backcharge', id: ChangeReasons.BACKCHARGE },
  { label: 'Client Request', id: ChangeReasons.CLIENT_REQUEST },
  { label: 'Design Development', id: ChangeReasons.DESIGN_DEVELOPMENT },
  { label: 'Existing Condition', id: ChangeReasons.EXISTING_CONDITION },
];

export const loadTimeDateString = (date: Date) => {
  const formatRelativeLocale: LocaleRelative = {
    today: `'today at' h:mm aa`,
    other: `MM/dd/yyyy h:mm aa`,
  };

  const locale: Locale = {
    ...en,
    formatRelative: (token: keyof LocaleRelative) =>
      formatRelativeLocale[token] || formatRelativeLocale.other,
  };

  const dateString = formatRelative(date, new Date(), { locale });
  return dateString;
};

export const getProcoreChangeEventBaseURL = (procoreZone?: string, projectID?: string) => {
  const domain = isProduction ? `${procoreZone}.procore` : `sandbox.procore`;
  return `https://${domain}.com/${projectID}/project/change_events/events`;
};

export const getProcoreChangeEventLink = (
  sourceEntityID: string,
  procoreZone: string,
  linkedProjectID?: string
) => {
  if (!linkedProjectID) return '#';
  return `${getProcoreChangeEventBaseURL(procoreZone, linkedProjectID)}/${sourceEntityID}`;
};

export const getAutodeskChangeOrdersBaseURL = (projectID?: string) => {
  if (!projectID) return '';
  const projectIDParts = projectID.split('.');
  const urlProjectID = projectIDParts[projectIDParts.length - 1];
  return `https://acc.autodesk.com/cost/changeorder/projects/${urlProjectID}/changeorders/pco`;
};

export const getAutodeskPotentialChangeOrderLink = (
  sourceEntityID: string,
  linkedProjectID?: string
) => {
  if (!linkedProjectID) return '#';
  return `${getAutodeskChangeOrdersBaseURL(linkedProjectID)}?preview=${sourceEntityID}`;
};

export const getItemIntegrationObjectTypeBySourceSystem = (sourceSystem?: SourceSystem) => {
  switch (sourceSystem) {
    case SourceSystem.PROCORE:
      return 'Change Event';
    case SourceSystem.AUTODESK:
      return 'Potential Change Order';
    default:
      throw new Error('Unsupported Source System');
  }
};

export const getItemIntegrationObjectCostType = (itemIntegrationObjectType: string) => {
  switch (itemIntegrationObjectType) {
    case 'Change Event':
      return 'ROM';
    case 'Potential Change Order':
      return 'Cost Item';
    default:
      throw new Error('Unsupported Item Integration Object');
  }
};

export const getItemIntegrationObjectsBySourceSystem = (
  itemIntegrationObjects?: ItemIntegrationObjectsQuery['itemIntegrationObjects'],
  sourceSystem?: SourceSystem
) => {
  switch (sourceSystem) {
    case SourceSystem.PROCORE:
      return itemIntegrationObjects?.procoreChangeEvents;
    case SourceSystem.AUTODESK:
      return itemIntegrationObjects?.autodeskPotentialChangeOrders;
    default:
      throw new Error('Unsupported Source System');
  }
};

export const getSourceSystemString = (sourceSystem?: SourceSystem) => {
  switch (sourceSystem) {
    case SourceSystem.PROCORE:
      return 'Procore';
    case SourceSystem.AUTODESK:
      return 'Autodesk';
    default:
      throw new Error('Unsupported Source System');
  }
};

export const getSourceSystemIcon = (sourceSystem?: SourceSystem) => (
  <img
    alt={sourceSystem}
    className="h-4 w-4"
    src={sourceSystem ? sourceSystemInfo[sourceSystem].img : ''}
  />
);

export const getSourceSystemInfo = (sourceSystem: SourceSystem, clientID?: UUID) => {
  const sourceInfo: SourceInfo = {
    sourceSystem,
    clientID: clientID || '',
    url: sourceSystemInfo[sourceSystem].url,
    img: sourceSystemInfo[sourceSystem].img,
  };
  if (sourceSystem === SourceSystem.AUTODESK) sourceInfo.scope = 'data:read data:write';

  return sourceInfo;
};

export const getItemIntegrationObjectAnalyticsTypeBySourceSystem = (sourceSystem: SourceSystem) => {
  switch (sourceSystem) {
    case SourceSystem.PROCORE:
      return {
        create: ITEM_ACTIVITY_CREATE_PROCORE_CHANGE_EVENT,
        unlink: ITEM_ACTIVITY_UNLINK_PROCORE_CHANGE_EVENT,
        linkCTA: ITEM_ACTIVITY_PROCORE_CHANGE_EVENT_LINK_CTA,
      };
    case SourceSystem.AUTODESK:
      return {
        create: ITEM_ACTIVITY_CREATE_AUTODESK_POTENTIAL_CHANGE_ORDER,
        unlink: ITEM_ACTIVITY_UNLINK_AUTODESK_POTENTIAL_CHANGE_ORDER,
        linkCTA: ITEM_ACTIVITY_AUTODESK_POTENTIAL_CHANGE_ORDER_LINK_CTA,
      };
    default:
      throw new Error('Unsupported Source System');
  }
};

export const getItemIntegrationBaseURL = (
  sourceSystem: SourceSystem,
  procoreZone?: string,
  linkedProjectID?: string
) => {
  switch (sourceSystem) {
    case SourceSystem.PROCORE:
      return getProcoreChangeEventBaseURL(procoreZone, linkedProjectID);
    case SourceSystem.AUTODESK:
      return getAutodeskChangeOrdersBaseURL(linkedProjectID);
    default:
      throw new Error('Unsupported Source System');
  }
};

export const getItemIntegrationObjectLink = (
  sourceSystem: SourceSystem,
  integrationObjectID: string,
  linkedProjectID?: string,
  procoreZone?: string
) => {
  switch (sourceSystem) {
    case SourceSystem.PROCORE:
      return getProcoreChangeEventLink(integrationObjectID, procoreZone || '', linkedProjectID);
    case SourceSystem.AUTODESK:
      return getAutodeskPotentialChangeOrderLink(integrationObjectID, linkedProjectID);
    default:
      throw new Error('Unsupported Source System');
  }
};

export const getInitialItemIntegrationObjectInput = (
  sourceSystem: SourceSystem,
  itemInfo: ItemInfo,
  projectID?: UUID
) => {
  const itemURL = generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
    projectId: projectID,
    itemId: itemInfo.id,
  });
  const initialItemIntegrationObjectInput: CreateItemIntegrationObjectInput = {
    title: itemInfo.name,
    description: itemInfo.description,
    cost: 0,
    itemURL,
  };
  if (sourceSystem === SourceSystem.PROCORE) {
    initialItemIntegrationObjectInput.status = EventStatuses.OPEN;
    initialItemIntegrationObjectInput.eventType = ChangeEventTypes.ALLOWANCE;
    initialItemIntegrationObjectInput.changeReason = ChangeReasons.ALLOWANCE;
    initialItemIntegrationObjectInput.shouldIncludeAttachments = false;
  }
  return initialItemIntegrationObjectInput;
};
