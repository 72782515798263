import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import { reportVariance, varianceEventTypes } from '../../../../analytics/analyticsEventProperties';
import { DETAILED_ITEM_COSTS, GROUP_BY, VARIANCE, ZERO_VARIANCE } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import {
  makeDefaultLevelNames,
  makeOptionsFromCategorizations,
} from '../../../../utilities/categorization';
import { returnGroupByOptions } from '../../../../utilities/grouping';
import { usePersistentStates } from '../../../../utilities/urlState';
import CostReportGroupBySelect from '../../../CostReport/CostReportGroupBySelect';
import { transformVarianceSettings } from '../../../CostReport/CostReportList/CostReportList/CostReportListUtils';
import CostReportSelect from '../../../CostReport/CostReportSelect/CostReportSelect';
import { getGroupByString } from '../../../CostReport/CostReportSettingsPanel/CostReportSettingsPanel';
import { useGetProjectUnitsQuery } from '../../../Milestone/hooks/UnitHooks';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import SelectValue from '../../../Select/SelectValue/SelectValue';
import { generateVarianceStorageParam } from '../../useVarianceReportParams';
import {
  displaySettings,
  makeSettingDescription,
  transformVarianceDefaults,
} from '../../VarianceReportUtils';

import styles from './VarianceSettingsSelectionStyles';

type VarianceSettingsSelectionProps = {
  classes: Classes<typeof styles>;
  categorizations: Categorization[];
  defaultGroupBy: string[];
};

const VarianceSettingsSelection: FC<VarianceSettingsSelectionProps> = ({
  classes,
  categorizations,
  defaultGroupBy,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const sendAnalytics = useSendAnalytics();

  // load project enabled units
  const { data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] } } =
    useGetProjectUnitsQuery(projectId, true);

  const transformSettings = transformVarianceSettings(enabledUnits);

  const [settings, setSettings] = usePersistentStates(
    window.location,
    VARIANCE,
    { ...transformVarianceDefaults, groupBy: defaultGroupBy },
    generateVarianceStorageParam(projectId),
    transformSettings
  );

  const { groupBy, zeroVariance, viewMode, estimateLines } = settings as VarianceSettings;
  const groupByOptions: Categorization[] = makeOptionsFromCategorizations(categorizations);

  const groupBySelections = useMemo(() => {
    if (groupBy) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      return returnGroupByOptions(groupBy, groupByOptions).filter((gb: any) => gb.id);
    }
    if (categorizations.length) {
      const defaultGroupBy: string[] = makeDefaultLevelNames(categorizations);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      return returnGroupByOptions(defaultGroupBy, groupByOptions).filter((gb: any) => gb.id);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorizations, groupBy]);

  const onChangeViewMode = (newViewMode: string) => {
    setSettings({ viewMode: newViewMode });
    sendAnalytics(
      reportVariance(varianceEventTypes.VARIANCE_SELECT_SETTINGS, { viewMode: newViewMode })
    );
  };

  const onChangeZeroVariance = (v: string) => {
    const idx = displaySettings
      .map((setting) => makeSettingDescription(setting, ZERO_VARIANCE))
      .findIndex((d) => d === v);
    const newSetting = displaySettings[idx];
    if (newSetting) {
      setSettings({ zeroVariance: newSetting });
    }
    sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_SELECT_SETTINGS, { zeroVariance }));
  };

  const onChangeEstimateLines = (v: string) => {
    const idx = displaySettings
      .map((setting) => makeSettingDescription(setting, DETAILED_ITEM_COSTS))
      .findIndex((d) => d === v);
    const newSetting = displaySettings[idx];
    if (newSetting) {
      setSettings({ estimateLines: newSetting });
    }
    sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_ESTIMATE_LINES, { estimateLines }));
  };

  const onChangeGroupBy = (newGroupBy: { name?: string }[]) => {
    setSettings({ groupBy: getGroupByString(newGroupBy) });
    sendAnalytics(
      reportVariance(varianceEventTypes.VARIANCE_SELECT_SETTINGS, {
        groupBy: getGroupByString(newGroupBy),
      })
    );
  };

  const renderHeader = (headLeft: string, headRight: string, toolTipCopy?: string) => (
    <div className={classes.header}>
      <Typography className={classes.headLeftText}>{headLeft}</Typography>
      <div className={classes.headRight}>
        <Typography className={classes.headRightText}>{headRight}</Typography>
        {toolTipCopy && (
          <div className={classes.info}>
            <NormalTooltip title={toolTipCopy}>
              <Help />
            </NormalTooltip>
          </div>
        )}
      </div>
    </div>
  );

  const renderTopContent = (
    <div className={`${classes.selectContainer} ${classes.horizontal}`}>
      <div className={`${classes.selectComponent} ${classes.paddingRight}`}>
        <CostReportSelect
          hideHeader
          onChange={(mode: string) => onChangeViewMode(mode)}
          selected={viewMode}
        />
      </div>
      <div className={classes.selectComponent}>
        <SelectValue
          cySelect="select-zeroVarianceSelect"
          hidePrint={false}
          onChange={(v: string) => onChangeZeroVariance(v)}
          value={makeSettingDescription(zeroVariance, ZERO_VARIANCE)}
          values={displaySettings.map((setting) => makeSettingDescription(setting, ZERO_VARIANCE))}
        />
      </div>
    </div>
  );

  const renderBottomContent = (
    <div className={`${classes.selectContainer} ${classes.horizontal}`}>
      {categorizations && groupBy && (
        <div className={`${classes.selectComponent} ${classes.paddingRight}`}>
          <CostReportGroupBySelect
            displayChips={2}
            hideHeader
            onClose={onChangeGroupBy}
            options={groupByOptions}
            selected={groupBySelections}
          />
        </div>
      )}
      <div className={classes.selectComponent}>
        <SelectValue
          cySelect="select-itemDetailedCosts"
          onChange={(v: string) => onChangeEstimateLines(v)}
          value={makeSettingDescription(estimateLines, DETAILED_ITEM_COSTS)}
          values={displaySettings.map((setting) =>
            makeSettingDescription(setting, DETAILED_ITEM_COSTS)
          )}
        />
      </div>
    </div>
  );
  const zeroVarianceToolTip =
    'Choose whether to include categories and items where costs are the same';
  const estimateLinesToolTip =
    'Choose whether to display estimate line information for item contributions';
  const estimateLinesHeader = 'Detailed Item Costs';

  return (
    <div className={classes.innerContainer}>
      {renderHeader('View', 'Zero Variance Rows', zeroVarianceToolTip)}
      {renderTopContent}
      {renderHeader(GROUP_BY, estimateLinesHeader, estimateLinesToolTip)}
      {renderBottomContent}
    </div>
  );
};

export const StyledVarianceSettingsSelection = withStyles(styles)(VarianceSettingsSelection);

export default StyledVarianceSettingsSelection;
