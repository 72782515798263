import { FC } from 'react';

import { InsertChart, Print, TrendingUp } from '@material-ui/icons';

import {
  PROJECT_DASHBOARD_PRINT,
  PROJECT_DASHBOARD_PRINT_WITH_COST_SUMMARY,
  PROJECT_DASHBOARD_RUN_CATEGORY_TRENDS,
  PROJECT_DASHBOARD_RUN_ESTIMATE_CHART,
} from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Export } from '../../Icons/Export';
import { IconMenuButton } from '../../scales';

type DashboardExportPanelProps = {
  onEstimateTableClick: () => void;
  printOnClick: (route: RouteKeys) => void;
  projectId: UUID;
};

const DASHBOARD_EXPORT = 'dashboard-export';

const DashboardExportPanel: FC<DashboardExportPanelProps> = ({
  onEstimateTableClick,
  printOnClick,
  projectId,
}) => {
  const onBreakdownClick = () => {
    window.open(generateSharedPath(RouteKeys.PRINT_PROJECT_BREAKDOWNS, { projectId }), '_blank');
  };

  const sendAnalytics = useSendAnalytics();

  const options = [
    {
      id: 'category-trends-breakdown',
      onClick: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_RUN_CATEGORY_TRENDS));
        onBreakdownClick();
      },
      startAdornment: <TrendingUp />,
      label: 'Category Trends Breakdown',
    },
    {
      id: 'estimate-chart-with-details',
      onClick: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_RUN_ESTIMATE_CHART));
        onEstimateTableClick(); // RENAME???
      },
      startAdornment: <InsertChart />,
      label: 'Estimate Chart with Details',
    },
    {
      id: 'print-dashboard-with-cost-summary',
      onClick: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_PRINT_WITH_COST_SUMMARY));
        printOnClick(RouteKeys.PRINT_PROJECT_DASHBOARD_SUMMARY);
      },
      startAdornment: <Print />,
      label: 'Print Dashboard With Cost Summary',
    },
    {
      id: 'print-dashboard',
      onClick: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_PRINT));
        printOnClick(RouteKeys.PRINT_PROJECT_DASHBOARD);
      },
      startAdornment: <Print />,
      label: 'Print Dashboard',
    },
  ];

  return (
    <div>
      <IconMenuButton
        aria-label="Dashboard export menu"
        data-cy={DASHBOARD_EXPORT}
        entries={options}
        icon={<Export />}
        type="secondary"
      />
    </div>
  );
};

export default DashboardExportPanel;
