import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Help as Info } from '@material-ui/icons';
import IconError from '@material-ui/icons/ErrorOutline';

import {
  ADD_CATEGORY_FROM_ERROR_CTA,
  HEADER_ERROR_SELECTED_CTA,
  OPEN_ITEM_ESTIMATE_COLUMN_MENU,
  OPEN_MILESTONE_ESTIMATE_COLUMN_MENU,
} from '../../../actions/actionTypes';
import {
  addAllNewCategoriesFromErrorAnalytics,
  analyticsEvent,
  replaceCategoryFromErrorAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { CategorizationDialogType } from '../../../api/gqlEnums';
import { FieldType } from '../../../api/gqlEnumsBe';
import { DESCRIPTION, QTY, UNIT_PRICE, U_M } from '../../../constants';
import { EstimateTotalType, PermissionResource } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { removeYear } from '../../../utilities/string';
// eslint-disable-next-line import/no-cycle
import CategorizationsListDialogs from '../../Categorizations/CategorizationsListDialogs/CategorizationsListDialogs';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import DialogsConfirmDelete from '../../Dialogs/DialogsMilestoneConfirmDelete/DialogsMilestoneConfirmDelete';
import NewBadge from '../../ImportEstimate/Modals/NewBadge/NewBadge';
import ErrorTooltip from '../../JoinGrid/ErrorTooltip';
import {
  acceptImportEstimateError,
  createCategories,
} from '../../JoinGrid/hooks/categorizationMutation';
import useUpdate from '../../JoinGrid/hooks/useUpdate';
import { CELL_TEXT_PADDING } from '../../JoinGrid/style/styleConstants';
import { Column, ColumnSettings, GridType, GridVariant } from '../../JoinGrid/types';
import { isCurrencyField } from '../../JoinGrid/utilities/cell';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';
import { getEstimateTypeFromGridVariant, getMaxWidth } from '../tableUtils/EstimateHeaderUtils';

import EstimateHeaderError from './EstimateHeaderError';
import EstimateHeaderIconMenu from './EstimateHeaderIconMenu';
import EstimateHeaderStyles from './EstimateHeaderStyles';
import { getTotalTypeDialogCopy } from './EstimateHeaderUtils';
import EstimateSortArrowButton from './EstimateSortArrowButton';

export const alignments = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const getVariantString = (isItem: boolean) => (isItem ? 'item estimate' : 'milestone estimate');

type ReplaceWith = {
  open: boolean;
  newValue?: {
    search: string;
    id: UUID;
  };
  count?: number;
  oldValue?: string;
};

type CategoryFromError = {
  open: boolean;
  category?: string;
};

type EstimateHeaderProps = {
  addColumn: (n: number) => void;
  align?: 'LEFT' | 'RIGHT';
  canEditColumns?: boolean;
  categorization?: CategorizationMetadata;
  classes: Classes<typeof EstimateHeaderStyles>;
  closeErrorHeaders: () => void;
  column: Column;
  columnSettings: ColumnSettings;
  deleteColumn: (id: UUID) => void;
  displayName: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  enableColumnMenu?: boolean;
  estimateID: UUID;
  estimateTotalType?: EstimateTotalType;
  fieldId: UUID;
  gridType?: GridType;
  hasGroups?: boolean;
  index: number;
  isItem: boolean;
  moveColumn: (c: number, f: number) => void;
  refetch: () => void;
  replaceCategory: (field: UUID, oldValue?: string, newValue?: UUID) => void;
  scrollToCell: (error: number, col: number) => void;
  selectCell: (row: number, col: number) => void;
  setEditedCellType?: () => void;
  setEditing?: (b: boolean) => void;
  setTotalType: (type: EstimateTotalType) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  sortEnabled: boolean;
  variant?: GridVariant;
  width?: number;
};

const EstimateHeader: FC<EstimateHeaderProps> = ({
  addColumn,
  align = 'LEFT',
  canEditColumns = false,
  categorization,
  classes,
  closeErrorHeaders,
  column,
  columnSettings,
  deleteColumn,
  displayName,
  enableColumnMenu = false,
  estimateID,
  estimateTotalType,
  fieldId,
  gridType,
  hasGroups = false,
  index,
  isItem,
  moveColumn,
  refetch,
  replaceCategory,
  scrollToCell,
  selectCell,
  setEditedCellType = () => {},
  setEditing = () => {},
  setTotalType,
  sortEnabled,
  variant = GridVariant.ITEM_FULLSCREEN,
  width = 0,
}) => {
  const { projectId } = useParams();

  const [errorIndex, setErrorIndex] = useState(0);
  const [deleteId, setDeleteId] = useState('');
  const [pendingTotalType, setPendingTotalType] = useState<EstimateTotalType | null>(null);
  const [categorizationDialogType, setCategorizationDialogType] =
    useState<CategorizationDialogType>(CategorizationDialogType.NONE);

  const isReadOnlyVariant = variant === GridVariant.READ_ONLY;

  const sendAnalytics = useSendAnalytics();

  const { canEdit } = usePermissions();
  const canEditCategory = canEdit(PermissionResource.CATEGORIES_AND_TAGS);

  const { errors, isErrorsMode, headerToolTip, helpTip } = column;
  const [
    {
      errorsIndices = [],
      errorsMap = new Map<string, number[]>(),
      errorsResolutionId = null,
      isNewCategorization = false,
    } = {},
  ] = errors || [];
  const totalErrors = (errorsIndices || []).length;

  const {
    isLastAddableColumn,
    isFirstCategoryColumn,
    isLastCategoryColumn,
    isTotalCell,
    isTotalCellShadow,
    isSourceCell,
  } = columnSettings;
  const [categoryFromError, setCategoryFromError] = useState<CategoryFromError>({ open: false });
  const createCategoryFromError = () => {
    if (categorization && categoryFromError) {
      setCategoryFromError({ ...categoryFromError, open: false });
      const toCreate = [{ number: categoryFromError.category || '', name: '' }];
      createCategories(categorization.id, toCreate, sendAnalytics, projectId, refetch);
      sendAnalytics(analyticsEvent(ADD_CATEGORY_FROM_ERROR_CTA));
    }
  };
  const estimateType = getEstimateTypeFromGridVariant(variant);

  const [categoriesFromErrorDialog, setCategoriesFromErrorDialog] = useState<boolean>(false);
  const createCategoriesFromError = () => {
    if (categorization) {
      // hide dialog
      setCategoriesFromErrorDialog(false);
      // resolve side pannel error
      if (errorsResolutionId)
        acceptImportEstimateError(projectId, estimateID, errorsResolutionId, () => {});
      // create categories
      const keys = Array.from(errorsMap.keys());
      const toCreate = (keys || []).map((key) => ({ number: key, name: '' }));
      if (toCreate.length > 0) {
        createCategories(categorization.id, toCreate, sendAnalytics, projectId, refetch);
        if (estimateType) sendAnalytics(addAllNewCategoriesFromErrorAnalytics(estimateType));
      }
    }
  };

  const [replaceWith, setReplaceWith] = useState<ReplaceWith>({ open: false });
  const replaceColumnCells = () => {
    if (estimateType) sendAnalytics(replaceCategoryFromErrorAnalytics(estimateType));
    setReplaceWith({ ...replaceWith, open: false });
    replaceCategory(column.id, replaceWith.oldValue, replaceWith.newValue?.id);
  };

  const menuOptionsData = {
    addColumn,
    categorization,
    canEditCategory,
    canEditColumns,
    column,
    deleteColumn,
    errorsMap,
    estimateTotalType,
    hasGroups,
    id: fieldId,
    index,
    isErrorsMode: true,
    isFirstCategoryColumn,
    isItem,
    isLastAddableColumn,
    isLastCategoryColumn,
    isQuantity: variant === GridVariant.QUANTITY,
    isTotalColumn: isTotalCell,
    isTotalColumnShadow: isTotalCellShadow,
    isSourceColumn: isSourceCell,
    moveColumn,
    onAddAllErrors: () => setCategoriesFromErrorDialog(true),
    onCategoryError: (category: string) => setCategoryFromError({ open: true, category }),
    openCategorizationDialog: (type: CategorizationDialogType) => setCategorizationDialogType(type),
    sendAnalytics,
    setDeleteId,
    setEditedCellType,
    setPendingTotalType,
    totalErrors,
    updateColumnCells: (
      value: { category: Category | null; search: string },
      indices: number[],
      oldValue: string
    ) => {
      const search = value.category ? value.category.number || value.category.name : '';
      const newValue = value.category ? { id: value.category.id, search } : null;
      if (newValue) setReplaceWith({ open: true, newValue, count: indices.length, oldValue });
    },
  };
  const columnName =
    displayName ||
    (categorization &&
      removeYear(categorization.name, (categorization as CategorizationFeDefined).builtin));

  const isSingleColumn =
    isSourceCell ||
    isTotalCell ||
    (column.name === DESCRIPTION && column.type === FieldType.STRING) ||
    (column.name === QTY && column.type === FieldType.NUMBER) ||
    (column.name === U_M && column.type === FieldType.STRING) ||
    (column.name === UNIT_PRICE && isCurrencyField(column.type) && column.group === '');

  const reportMenuOpenedAnalytics = () =>
    sendAnalytics(
      analyticsEvent(isItem ? OPEN_ITEM_ESTIMATE_COLUMN_MENU : OPEN_MILESTONE_ESTIMATE_COLUMN_MENU)
    );

  const isLeft = align === alignments.LEFT;
  const isRight = align === alignments.RIGHT;

  const setIsErrorsMode = (isErrors: boolean) => {
    if (isErrors) {
      closeErrorHeaders();
      sendAnalytics(analyticsEvent(HEADER_ERROR_SELECTED_CTA));
    }
    // eslint-disable-next-line no-param-reassign
    column.isErrorsMode = isErrors;
    if (column.update) column.update();
  };

  const hasErrors = totalErrors !== 0;
  useEffect(() => {
    if (isErrorsMode) {
      scrollToCell(errorsIndices[errorIndex], index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorsMode]);

  const updateErrorIndex = () => {
    const [{ errorsIndices: eIndices = [] } = {}] = errors || [];
    if (isErrorsMode) {
      if (errorIndex >= eIndices.length) {
        // case when user resolves last error in array, but there are more errors left
        setErrorIndex(errorIndex - 1);
        scrollToCell(eIndices[errorIndex - 1], index);
      } else {
        scrollToCell(eIndices[errorIndex], index);
      }
    }
  };

  const update = useUpdate();
  // eslint-disable-next-line no-param-reassign
  column.update = () => {
    update();
    updateErrorIndex();
  };

  const headerContent = () => {
    if (isErrorsMode) {
      return (
        <EstimateHeaderError
          align={align}
          column={column}
          enableColumnMenu={enableColumnMenu}
          errorIndex={errorIndex}
          index={index}
          menuOptionsData={menuOptionsData}
          onClose={() => setIsErrorsMode(false)}
          reportMenuOpenedAnalytics={reportMenuOpenedAnalytics}
          scrollToCell={scrollToCell}
          selectCell={selectCell}
          setEditing={setEditing}
          setErrorIndex={(eIndex: number) => setErrorIndex(eIndex)}
          width={width}
        />
      );
    }

    if (
      !hasGroups &&
      estimateTotalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES &&
      gridType === GridType.ESTIMATE_GRID &&
      isSingleColumn
    ) {
      return null;
    }

    return (
      <>
        {isRight && enableColumnMenu && (
          <EstimateHeaderIconMenu
            onClick={() => {
              selectCell(-1, -1);
              reportMenuOpenedAnalytics();
            }}
            optionsData={menuOptionsData}
            variant={SelectVariants.GRID}
          />
        )}
        <div
          className={`${isRight ? classes.right : ''} ${classes.crop}`}
          style={{
            maxWidth: getMaxWidth(width, enableColumnMenu),
            marginLeft: width && isLeft ? CELL_TEXT_PADDING : undefined,
            marginRight: width && isRight ? CELL_TEXT_PADDING : undefined,
            display: helpTip ? 'flex' : '',
          }}
        >
          <NormalTooltip title={headerToolTip || columnName || ''}>
            <div className={classes.columnName} data-cy="estimateHeader-columnName">
              {columnName}
            </div>
          </NormalTooltip>
          {helpTip && (
            <NormalTooltip title={helpTip}>
              <Info className={classes.info} />
            </NormalTooltip>
          )}
        </div>
        {!hasGroups && isNewCategorization && (
          <NormalTooltip title="This new categorization was automatically created for you from your estimate file">
            <div className={classes.newBadgeContainer}>
              <NewBadge />
            </div>
          </NormalTooltip>
        )}
        {!hasGroups && hasErrors && (
          <ErrorTooltip
            title={`${totalErrors} error${totalErrors === 1 ? '' : 's'} in ${columnName} column`}
          >
            <div
              className="join-grid-error-head"
              onClick={() => setIsErrorsMode(true)}
              onKeyDown={() => setIsErrorsMode(true)}
              role="button"
              tabIndex={-1}
            >
              <IconError className="join-grid-error-icon" />
              <span className="join-grid-error-count">{totalErrors}</span>
            </div>
          </ErrorTooltip>
        )}
        {(!hasGroups || (hasGroups && isSingleColumn)) &&
          sortEnabled &&
          align === 'LEFT' &&
          !isReadOnlyVariant &&
          !isErrorsMode && (
            <EstimateSortArrowButton align="RIGHT" columnId={column.id} disabled={false} />
          )}
        {isLeft && enableColumnMenu ? (
          <EstimateHeaderIconMenu
            id="menu-columnOptions-EstimateHeader"
            onClick={() => {
              selectCell(-1, -1);
              reportMenuOpenedAnalytics();
            }}
            optionsData={menuOptionsData}
            variant={SelectVariants.GRID}
          />
        ) : (
          <div className="join-grid-no-menu-padding" />
        )}
      </>
    );
  };

  // Borders
  const errorHeader = isErrorsMode ? 'join-grid-error-nav-header-cell' : '';
  const endCellBorder = isTotalCell ? 'root-end-cell-border' : 'row-cell-border';

  return (
    <div className={`${classes.root} ${errorHeader} ${endCellBorder}`}>
      {headerContent()}
      {!isReadOnlyVariant && sortEnabled && align === 'RIGHT' && !isErrorsMode && (
        <EstimateSortArrowButton align={align} columnId={column.id} disabled={false} />
      )}
      {categorization && categorizationDialogType !== CategorizationDialogType.NONE && (
        <CategorizationsListDialogs
          categorization={categorization}
          onClose={() => {
            refetch();
          }}
          projectId={projectId}
          setType={setCategorizationDialogType}
          type={categorizationDialogType}
        />
      )}
      <DialogsConfirm
        body={`Category ${categoryFromError.category} will be added to ${
          (categorization || {}).name
        }`}
        onClose={() => setCategoryFromError({ ...categoryFromError, open: false })}
        onConfirm={() => createCategoryFromError()}
        open={categoryFromError.open}
        title={`Category ${categoryFromError.category}`}
      />
      <DialogsConfirm
        body={`All new categories will be added to ${(categorization || {}).name}`}
        onClose={() => setCategoriesFromErrorDialog(false)}
        onConfirm={() => createCategoriesFromError()}
        open={categoriesFromErrorDialog}
        title="All new categories"
      />
      <DialogsConfirm
        body={`Replace ${replaceWith.oldValue} category (${replaceWith.count || 0} matches) with ${
          (replaceWith.newValue || {}).search
        }`}
        onClose={() => setReplaceWith({ ...replaceWith, open: false })}
        onConfirm={() => replaceColumnCells()}
        open={replaceWith.open}
        title={`Replace ${replaceWith.oldValue} category`}
      />
      {pendingTotalType !== null && (
        <DialogsConfirm
          body={getTotalTypeDialogCopy(estimateTotalType, pendingTotalType).body}
          onClose={() => {
            setPendingTotalType(null);
          }}
          onConfirm={() => {
            setTotalType(pendingTotalType);
            setPendingTotalType(null);
          }}
          open
          title={getTotalTypeDialogCopy(estimateTotalType, pendingTotalType).title}
        />
      )}
      {categorization && (
        <DialogsConfirmDelete
          dialogContent={`Deleting this column will remove all ${removeYear(
            categorization.name,
            (categorization as CategorizationFeDefined).builtin
          )} categories from lines in this ${getVariantString(isItem)}.`}
          dialogTitle="Warning: Delete Column"
          displayDialogsConfirmDelete={!!deleteId}
          onClose={() => {
            setDeleteId('');
          }}
          onDeleteSubmit={() => {
            deleteColumn(deleteId);
            setDeleteId('');
          }}
        />
      )}
    </div>
  );
};

export default withStyles(EstimateHeaderStyles)(EstimateHeader);
