import { FC } from 'react';

import { GetApp, Print } from '@material-ui/icons';

import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { Export } from '../Icons/Export';
import { IconMenuButton } from '../scales';

type ItemsListExportPanelProps = {
  exportList: () => void;
  printList: () => void;
  printDetails: (withHistory: boolean) => void;
};

const ItemsListExportPanel: FC<ItemsListExportPanelProps> = ({
  printList,
  exportList,
  printDetails,
}) => {
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const options = [
    ...(shouldDisplayCosts
      ? [
          {
            id: 'export-xlsx',
            label: 'Download .xlsx',
            onClick: () => exportList(),
            startAdornment: <GetApp />,
          },
        ]
      : []),
    {
      id: 'print-list',
      label: 'Print list only',
      onClick: () => printList(),
      startAdornment: <Print />,
    },
    {
      id: 'print-details',
      label: 'Print list and details without change history',
      onClick: () => printDetails(false),
      startAdornment: <Print />,
    },
    {
      id: 'print-details-with-history',
      label: 'Print list and details with change history',
      onClick: () => printDetails(true),
      startAdornment: <Print />,
    },
  ];

  return (
    <IconMenuButton
      aria-label="export items menu"
      entries={options}
      icon={<Export data-cy="exportItemsButtonContainer" />}
      type="secondary"
    />
  );
};

export default ItemsListExportPanel;
