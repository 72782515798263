import { select } from 'd3-selection';

import { renderToString } from 'react-dom/server';

import {
  SVGGDataBaseSelection,
  SVGGSelection,
  SVGPathDataSelection,
  TimelineData,
  TimelineItemGroup,
  TimelineOptions,
} from './timeline.types';

export const END_DATE_ALERT = 'End Date required';
export const ITEMS_TOOLTIP_TOP = 136;
export const TOOLTIP_WIDTH = 340;
export const TOOLTIP_HALF_WIDTH = TOOLTIP_WIDTH / 2;

export const createTooltipHost = (): void => {
  // Create tooltip host
  const tooltip = select('body')
    .append('div')
    .attr('id', 'timeline-tooltip-container')
    .style('width', '340px')
    .style('position', 'absolute')
    .style('display', 'flex')
    .style('justify-content', 'center')
    .style('visibility', 'hidden')
    .style('top', 0)
    .style('left', 0);

  const content = tooltip
    .append('div')
    .attr('id', 'timeline-tooltip')
    .attr(
      'class',
      'bg-background-1 text-type-primary border p-2 max-w-[340px] w-fit text-left rounded shadow z-[1000]'
    );

  content.on('mouseout', (event) => {
    if (event.relatedTarget?.id === 'timeline-tooltip') return;
    tooltip.style('visibility', 'hidden');
  });
};

export const updateTooltips = (
  svgGroups: SVGGDataBaseSelection | SVGGSelection | SVGPathDataSelection,
  options: TimelineOptions,
  text?: string,
  dataOuter?: TimelineData
): void => {
  if (!options.showTooltips) return;

  const tooltipContainer = select('#timeline-tooltip-container');
  const tooltip = select('#timeline-tooltip');

  svgGroups.on('mouseover', (event, data: TimelineData | Date | TimelineItemGroup) => {
    event.preventDefault();
    event.stopPropagation();
    const d = dataOuter || data;
    const x = event.pageX - TOOLTIP_HALF_WIDTH;
    let y = event.pageY - 88;

    // Today line
    if (!('type' in d)) {
      y += 20;
    }
    // Items chart tooltip params
    if ('counter' in d) {
      y = event.pageY - ITEMS_TOOLTIP_TOP;
    }

    const body = options.tooltipElement(d as TimelineData, text);

    if (!body) return;

    tooltipContainer
      //
      .style('top', `${y}px`)
      .style('left', `${x}px`)
      .style('visibility', 'visible');
    tooltip
      //
      .html(renderToString(body));
  });

  svgGroups.on('mouseout', (event) => {
    if (event.relatedTarget?.id === 'timeline-tooltip') return;
    tooltipContainer.style('visibility', 'hidden');
  });
};
