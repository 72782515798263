import * as React from 'react';

import { Dialog, DialogActions, Divider, Typography } from '@material-ui/core';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { CompanyCategorization } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useDeleteCompanyCategorization } from '../../CompanyTab/Standards/CompanyCategorizations/hooks';
import { Button, Icon, IconButton } from '../../scales';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import DialogsStyles from '../DialogsStyles';

type DialogsDeleteCompanyCategorizationProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onClose: () => void;
  companyCategorization: CompanyCategorization;
};

const DialogsDeleteCompanyCategorization: React.FC<DialogsDeleteCompanyCategorizationProps> = ({
  classes,
  open,
  onClose,
  companyCategorization,
}) => {
  const deleteCompanyCategorization = useDeleteCompanyCategorization();
  const sendAnalytics = useSendAnalytics();
  const header = (
    <div className={`${classes.titleContainer}`}>
      <Typography variant="title">Delete {companyCategorization.categorization.name}?</Typography>
      <IconButton
        aria-label="Close dialog"
        icon={<Icon name="close" />}
        onClick={() => {
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_DELETE_CLOSE)
          );
          onClose();
        }}
        type="secondary"
      />
    </div>
  );

  const content = (
    <>
      <div className={`${classes.horizontalContainer} ${classes.content}`}>
        <Typography>
          Deleting this categorization is a permanent action and cannot be undone. Once deleted,
          this categorization will no longer be available to your company&apos;s projects. Existing
          projects will not be affected.
        </Typography>
      </div>
      <div className={` ${classes.content} ${classes.paddingBottom}`}>
        <ShadedHelpTip
          shiftLeft
          tip="You can download the categorization details as a backup to easily restore later, if needed."
        />
      </div>
    </>
  );

  const footer = (
    <DialogActions className={classes.action}>
      <Button
        label="Cancel"
        onClick={() => {
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_DELETE_CANCEL)
          );
          onClose();
        }}
        type="secondary"
      />
      <Button
        label="Delete"
        onClick={() => {
          deleteCompanyCategorization(companyCategorization.categorization.id);
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_DELETE)
          );
          onClose();
        }}
        startIcon={<Icon name="delete" />}
        type="destructive"
      />
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: `${classes.dialogPaper} ${classes.dialogPaperSmall}`,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      {header}
      <Divider />
      {content}
      {footer}
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsDeleteCompanyCategorization);
