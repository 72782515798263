import { ProgramBreakdownQuery, ProgramBreakdownQueryVariables } from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { programBreakdownQuery } from '../queries';

export const useProgramBreakdownQuery = (
  programID: UUID | undefined,
  projectIDs: UUID[],
  options?: QueryHookOptions<ProgramBreakdownQuery, ProgramBreakdownQueryVariables>
) =>
  useQuery<ProgramBreakdownQuery, ProgramBreakdownQueryVariables>(
    programBreakdownQuery,
    {
      variables: {
        programID,
        projectIDs,
      },
      skip: !programID || !projectIDs.length,
      ...options,
    },
    MountPolicy.FETCH_ON_VARS
  );
