import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { ProjectTermProvider } from '../ProjectDisplaySettings/TerminologyProvider';

import { useDefaultProjectRoute } from './hooks/useDefaultProjectRoute';
import { useHasProjectAccess } from './hooks/useHasProjectAccess';

export default function ProjectRoute() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  useNavigateToDefaultRoute(projectId);

  const { loading, hasAccess } = useHasProjectAccess(projectId);
  if (loading) return null;
  if (!hasAccess) return <Navigate replace to="/404" />;

  return (
    <ProjectTermProvider projectID={projectId}>
      <Outlet />
    </ProjectTermProvider>
  );
}

function useNavigateToDefaultRoute(projectId: string) {
  const navigate = useNavigate();
  const { route, loading } = useDefaultProjectRoute();
  const pathnameWithoutTrailingSlash = useLocation().pathname.replace(/\/$/, '');

  useEffect(() => {
    const rootProjectPath = generateSharedPath(RouteKeys.PROJECT, { projectId });
    if (pathnameWithoutTrailingSlash === rootProjectPath && !loading) {
      // 'replace: true' makes it so that this temporary redirect doesn't become part of the browser history
      if (projectId && isUUID(projectId)) {
        navigate(generateSharedPath(route, { projectId }), { replace: true });
      }
    }
  }, [loading, navigate, pathnameWithoutTrailingSlash, projectId, route]);
}
