export const LEFT_MARGIN = 64;
export const PADDING = 16;
export const CHART_HEIGHT = 182;
export const TOTAL_HEIGHT = PADDING + CHART_HEIGHT + PADDING;
export const CHIPS_HEIGHT = 28;

export const DETAILS_MARGIN = {
  left: LEFT_MARGIN,
  right: 0,
  bottom: 32,
  top: 8,
};
