import { Navigate, useLocation } from 'react-router-dom';

import { DD_BENCHMARKING, PROJECT_COMPS } from '../../features';
import { PermissionResource } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useHasFeature';
import { setToast } from '../../hooks/useToastParametersLocalQuery';
import { FORECASTING } from '../../moduleEntitlementFlagsList';
import HasModuleEntitlement from '../HasModuleEntitlement';
import HasPermission from '../HasPermission';

import ForecastingTab from './ForecastingTab';

export default function ForecastingRoute() {
  const override = useHasFeature(PROJECT_COMPS);

  const redirect = useRedirect();
  if (redirect) return redirect;

  if (override) return <ForecastingTab />;

  return (
    <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={FORECASTING}>
      <HasPermission
        fallback={<Navigate replace to="/404" />}
        permission={PermissionResource.PROJECT_COMPS_ACCESS}
      >
        <ForecastingTab />
      </HasPermission>
    </HasModuleEntitlement>
  );
}

// TODO DD-1476 clean all this cruft up after a few weeks of running the benchmarking feature flag
function useRedirect() {
  const hasBenchmarkingFeatureFlag = useHasFeature(DD_BENCHMARKING);
  const pathname = useLocation().pathname;

  if (hasBenchmarkingFeatureFlag && pathname.includes('forecasting')) {
    setToast({
      message:
        'Forecasting is now called Benchmarking - update your bookmarks with the new location.',
    });

    return <Navigate to={pathname.replace('/forecasting', '/benchmarking')} />;
  }

  // out of convenience, all our links in the app use the benchmarking path
  // without regard for the feature flag. this pushes them to the correct URL for now.
  if (!hasBenchmarkingFeatureFlag && pathname.includes('benchmarking')) {
    return <Navigate to={pathname.replace('/benchmarking', '/forecasting')} />;
  }

  return false;
}
