import {
  ProgramEstimateChartOptionsQuery,
  ProgramEstimateChartOptionsQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { programEstimateChartOptionsQuery } from '../queries';

export function useProgramEstimateChartOptionsQuery(
  programID: UUID | undefined,
  includedProjectIDs: UUID[],
  options?: QueryHookOptions<
    ProgramEstimateChartOptionsQuery,
    ProgramEstimateChartOptionsQueryVariables
  >
) {
  return useQuery<ProgramEstimateChartOptionsQuery, ProgramEstimateChartOptionsQueryVariables>(
    programEstimateChartOptionsQuery,
    {
      variables: { includedProjectIDs, programID },
      skip: !programID || !includedProjectIDs.length,
      ...options,
    },
    MountPolicy.FETCH_ON_VARS
  );
}
