import { FC } from 'react';

import { withStyles } from '@material-ui/core';
import { GetApp, Print } from '@material-ui/icons';

import { isDownloadingProjectCompsToExcelVar } from '../../../api/apollo/reactiveVars';
import { Export } from '../../Icons/Export';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';

import styles from './ProjectCompsInputsExportPanelStyles';

type ProjectCompsExportPanelProps = {
  printOnClick: () => void;
  exportProjectCompsReport: () => void;
};

const ProjectCompsExportPanel: FC<ProjectCompsExportPanelProps> = ({
  printOnClick,
  exportProjectCompsReport,
}) => {
  const options: MenuOption[] = [
    {
      callback: () => printOnClick(),
      icon: <Print />,
      name: 'Print Project Comparison',
    },
    {
      callback: () => exportProjectCompsReport(),
      icon: <GetApp />,
      name: 'Download .xlsx',
      loadingVar: isDownloadingProjectCompsToExcelVar,
    },
  ];

  return <IconMenu icon={<Export />} isBottomOriented options={options} />;
};

export default withStyles(styles)(ProjectCompsExportPanel);
