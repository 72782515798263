import { FC, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { currentUserReportVar } from '../../../api/apollo/reactiveVars';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useProjectID } from '../../../utilities/routes/params';
import ProjectBreakdownReportData from '../../ProjectCostBreakdownReport/ProjectCostBreakdownReportData';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import { LandscapeOrientation } from '../PrintUtils';

const PrintProjectCostBreakdown: FC = () => {
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');
  const project = useProjectPropsQuery(projectId)?.data?.project;

  const currentReport = useReactiveVar(currentUserReportVar);
  const defaultName = 'Project Cost Breakdown Report';
  const name = currentReport?.name ?? defaultName;
  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - ${name}`;
  }, [name, project]);

  if (!project?.activeMilestone) {
    return null;
  }
  const milestoneName = project?.activeMilestone?.name ?? '';

  return (
    <>
      <LandscapeOrientation />
      <div>
        <PrintPageHeaderWrapper
          milestoneName={milestoneName}
          projectId={projectId}
          reportName={name}
        />
        <ProjectBreakdownReportData isPrint />
      </div>
    </>
  );
};

export default PrintProjectCostBreakdown;
