import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { JoinCompanyRoutes } from '../../../../api/gqlEnums';
import { Org } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { pluralizeCountString } from '../../../../utilities/string';
import { useDeleteOrg } from '../../../Organizations/hooks/hooks';
import { Button, Dialog, DialogContent, Icon } from '../../../scales';
import { getProjectStatsLink } from '../../CompanyTabUtils';
import { useGetProjectsUsingOrgQuery } from '../hooks/useGetProjectsUsingOrgQuery';

export default function OrgDeleteBtn(props: { organization: Org }) {
  const sendAnalytics = useSendAnalytics();
  const [deleteOrg] = useDeleteOrg();
  const navigate = useNavigate();
  const { data, loading } = useGetProjectsUsingOrgQuery(props.organization.id);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const projectsUsingOrg = data?.getProjectsUsingOrg;

  const onDelete = () => {
    const id = props.organization.id;
    deleteOrg({
      variables: {
        input: { organizationID: id },
      },
      update: (cache) => {
        cache.modify({
          fields: {
            organizations(organizations, { readField }) {
              return organizations.filter((org: Org) => id !== readField('id', org));
            },
          },
        });
      },
      onCompleted: () => {
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_DELETE, {
            organization: props.organization.name,
          })
        );
        const route = generateSharedPath(JoinCompanyRoutes.COMPANY_ORGANIZATIONS);
        navigate(route, { replace: true });
      },
    });
  };

  const onProjectStats = () => {
    const nodeIDUsedInProjects = [...new Set(projectsUsingOrg?.map((n) => n.nodeID))]; // Ensure duplicate nodeIDs are removed
    navigate(
      getProjectStatsLink({
        orgNodeIDs: nodeIDUsedInProjects || [],
      })
    );
    setIsDialogOpen(false);
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_PROJECT_STATS, {
        organization: props.organization.name,
      })
    );
  };

  if (loading) return null;

  const dialogToDisplay =
    projectsUsingOrg && projectsUsingOrg.length ? (
      <Dialog
        footerLeft={
          <Button label="Go to Project Stats" onClick={onProjectStats} type="secondary" />
        }
        footerRight={
          <Button
            label="Proceed with Delete"
            onClick={onDelete}
            startIcon={<Icon name="delete" />}
            type="destructive"
          />
        }
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        title={`Delete Organization: ${props.organization.name}`}
      >
        <DialogContent className="flex flex-col gap-2">
          <div className="flex flex-col gap-3">
            <div className="flex type-body1">
              {`There ${projectsUsingOrg.length > 1 ? 'are' : 'is'} still  ${pluralizeCountString(
                'project',
                projectsUsingOrg.length
              )} assigned to this organization. Deleting the organization will remove the tagging and leave projects unassigned.`}
            </div>
            <div>
              You can use the Project Stats button below to reassign projects or proceed with
              deleting the organization.
            </div>
          </div>
        </DialogContent>
      </Dialog>
    ) : (
      <Dialog
        footerRight={
          <Button
            label="Delete"
            onClick={() => {
              onDelete();
              setIsDialogOpen(false);
              sendAnalytics(
                companyAdminAnalyticsEvent(
                  CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_DELETE,
                  {
                    organization: props.organization.name,
                    published: !props.organization.isDraft,
                  }
                )
              );
            }}
            startIcon={<Icon name="delete" />}
            type="destructive"
          />
        }
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_CLOSE, {
              organization: props.organization.name,
              published: !props.organization.isDraft,
            })
          );
        }}
        title={`Delete Organization: ${props.organization.name}`}
      >
        <DialogContent>
          <div>
            {props.organization.isDraft
              ? 'Are you sure? You will lose your progress on this organization.'
              : 'Are you sure? By deleting this organization, teammates will no longer be able to select this as an option for organization.'}
          </div>
        </DialogContent>
      </Dialog>
    );

  return (
    <>
      <Button
        label="Delete"
        onClick={() => {
          setIsDialogOpen(true);
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_ORG, {
              organization: props.organization.name,
              published: !props.organization.isDraft,
            })
          );
        }}
        startIcon={<Icon name="delete" />}
        type="destructive"
      />
      {dialogToDisplay}
    </>
  );
}
