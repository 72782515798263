import { FC, useMemo, useState } from 'react';

import { LeadEvent, LeadKey, leadEvent } from '../../../analytics/analyticsEventProperties';
import {
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
  PROJECTS,
} from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import ClearFiltersPanelHeader from '../../FilterPanel/ClearFilters/ClearFiltersPanelHeader';
import FilterIcon from '../../Icons/FilterIcon';
import InputsSettingsPanel from '../../Inputs/InputsSettingsPanel/InputsSettingsPanel';
import OrgsFilterLevelsSelect from '../../ProjectsList/ProjectsListFilter/ProjectsListFilterSelect/OrgsFilterLevelsSelect';
import ProjectsListFilterSelect from '../../ProjectsList/ProjectsListFilter/ProjectsListFilterSelect/ProjectsListFilterSelect';
import ProjectTypeFilter from '../../ProjectsList/ProjectsListFilter/ProjectTypeFilter';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  projectFilterStateHasFilters,
} from '../../ProjectsList/ProjectsListUtils';
import { ProjectMap } from '../ExecutiveDashboardUtils';

import FilterSelect from './FilterSelect';

type FilterPanelProps = {
  clearProjectFilter: () => void;
  filterManager: ProjectFilterManager;
  onProjectSelect: (value: string) => void;
  projectMap: ProjectMap;
  projectOptions: string[];
  selectAllProjects: () => void;
  selectedProjects: string[];
  setSettings: ProjectFilterSetSettings;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showAsButton?: boolean;
};

const FilterPanel: FC<FilterPanelProps> = ({
  clearProjectFilter,
  filterManager,
  onProjectSelect,
  projectMap,
  projectOptions,
  selectAllProjects,
  selectedProjects,
  setSettings,
  showAsButton,
}) => {
  const sendAnalytics = useSendAnalytics();

  const [show, setShow] = useState(false);
  const numFilters = useMemo(() => {
    let result = 0;
    if (selectedProjects.length < projectOptions.length) result += 1;
    return result;
  }, [projectOptions.length, selectedProjects.length]);
  const hasFilters = projectFilterStateHasFilters(filterManager.filterState) || numFilters > 0;
  return (
    <InputsSettingsPanel
      data-cy="CompanyDashboard-FilterPanel"
      header={
        <ClearFiltersPanelHeader
          additionalFilters={{
            canClear: numFilters > 0,
            clearFilters: clearProjectFilter,
            clearFiltersCTA: '',
            filters: <></>,
            numFilters,
          }}
          filterManager={filterManager}
          setSettings={setSettings}
          variant={PROJECTS}
        />
      }
      icon={<FilterIcon isFilled={hasFilters} />}
      setShow={(show) => {
        setShow(show);
        if (show) {
          sendAnalytics(leadEvent(LeadKey.EXEC_DASH, LeadEvent.FilterClick));
        }
      }}
      show={show}
      showAsButton={showAsButton}
    >
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_STATUS}
        setSettings={setSettings}
      />
      <ProjectTypeFilter
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_TYPE}
        setSettings={setSettings}
      />
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_LOCATION}
        setSettings={setSettings}
      />
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_COMPANIES}
        setSettings={setSettings}
      />
      <ProjectsListFilterSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_LEAD}
        setSettings={setSettings}
      />
      <OrgsFilterLevelsSelect
        clearAllText="clear"
        filterManager={filterManager}
        filterType={FILTER_PROJECT_ORGANIZATION}
        setSettings={setSettings}
      />
      <FilterSelect
        allSelectedText="All projects"
        clearFilter={clearProjectFilter}
        cy="CompanyDashboard-FilterSelect-ProjectName"
        formatValueForDisplay={(value: string) => projectMap.get(value)?.name || ''}
        onSelect={onProjectSelect}
        options={projectOptions}
        selectAll={selectAllProjects}
        selected={selectedProjects}
        title="Project name"
        tooltip={[`${selectedProjects.length} of ${projectOptions.length} projects`]}
      />
    </InputsSettingsPanel>
  );
};

export default FilterPanel;
