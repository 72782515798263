import { QueryHookOptions } from '@apollo/client';

import { NS_PROGRAM_REPORTING } from '../../../../../features';
import { ProgramsQuery, ProgramsQueryVariables } from '../../../../../generated/graphql';
import { useHasFeature } from '../../../../../hooks/useHasFeature';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import { useTypesIdsToNames } from '../../../../../hooks/useTypesIdsToNames';

import { programs } from './queries';

export const useProgramsQuery = (
  variablesOuter: ProgramsQueryVariables,
  options?: QueryHookOptions<ProgramsQuery, ProgramsQueryVariables>
) => {
  const hasProgramReporting = useHasFeature(NS_PROGRAM_REPORTING);
  const variables = useTypesIdsToNames(variablesOuter, 'filters.types');

  return useQuery<ProgramsQuery, ProgramsQueryVariables>(programs, {
    ...options,
    variables,
    skip: !hasProgramReporting,
  });
};
