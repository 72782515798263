import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Divider, Typography } from '@material-ui/core';
import { Check, Edit, FileCopy, NotInterested } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { DRAFT } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../../routes/paths';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../../utilities/routes/links';
import DialogsDeleteTemplate from '../../../Dialogs/DialogsTemplates/DialogsDeleteTemplate';
import DialogsDuplicateTemplate from '../../../Dialogs/DialogsTemplates/DialogsDuplicateTemplate';
import { Thumbnail } from '../../../dragon-scales';
import { Icon } from '../../../scales';
import IconMenu from '../../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../../Select/SelectMenu/SelectOption';
import CompanyTabStyles from '../../CompanyTabStyles';

import { useSetProjectTemplatePublicationMutation } from './hooks';

type ProjectTemplatesListEntryProps = {
  classes: Classes<typeof CompanyTabStyles>;
  projectTemplate: ProjectTemplate;
};

const ProjectTemplatesListEntry: FC<ProjectTemplatesListEntryProps> = ({
  classes,
  projectTemplate,
}) => {
  const navigate = useNavigate();
  const path = generateSharedPath(RouteKeys.PROJECT_SETTINGS, { projectId: projectTemplate.id });
  const sendAnalytics = useSendAnalytics();

  const [showDuplicateTemplateModal, setShowDuplicateTemplateModal] = useState(false);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);

  const setProjectTemplatePublication = useSetProjectTemplatePublicationMutation(
    projectTemplate.id
  );

  const basicMenuOptions: MenuOption[] = [
    {
      callback: () => {
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_EDIT_CTA, {
            projectID: projectTemplate.id,
          })
        );
        navigate(path);
      },
      name: 'Edit',
      icon: <Edit />,
    },
    {
      callback: () => {
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DUPLICATE_CTA, {
            projectID: projectTemplate.id,
          })
        );
        setShowDuplicateTemplateModal(true);
      },
      name: 'Duplicate',
      icon: <FileCopy />,
    },
    {
      callback: () => {
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_DELETE_CTA, {
            projectID: projectTemplate.id,
          })
        );
        setShowDeleteTemplateModal(true);
      },
      name: 'Delete',
      icon: <Icon name="delete" />,
      color: 'red',
    },
  ];

  const publishMenuOption: MenuOption = {
    callback: () => {
      setProjectTemplatePublication(true);
      sendAnalytics(
        companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_PUBLISH, {
          projectID: projectTemplate.id,
        })
      );
    },
    name: 'Publish',
    tooltip: 'Publish makes this template available to your employees.',
    icon: <Check />,
  };

  const unpublishMenuOption: MenuOption = {
    callback: () => {
      sendAnalytics(
        companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_UNPUBLISH, {
          projectID: projectTemplate.id,
        })
      );
      setProjectTemplatePublication(false);
    },
    name: 'Unpublish',
    tooltip: `Unpublish makes this template no longer available to your employees.`,
    icon: <NotInterested />,
  };

  const { isDraft } = projectTemplate;
  if (isDraft) {
    basicMenuOptions.unshift(publishMenuOption);
  } else {
    basicMenuOptions.unshift(unpublishMenuOption);
  }

  return (
    <>
      <div className={classes.listEntry} data-cy="ProjectTemplatesListEntry">
        <div className={`${classes.alignLeft} ${classes.alignItemsCenter}`}>
          <div className="p-2">
            <Thumbnail size={50} thumbnail={projectTemplate.project.thumbnail} />
          </div>
          <Typography className={classes.projectTemplateTitle}>
            <Link
              className={classes.linkBlack}
              data-cy="ProjectTemplatesListEntry-link"
              onClick={() => {
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_CLICK)
                );
              }}
              to={path}
            >
              {projectTemplate.name}
            </Link>
            {isDraft && <span className={classes.draftIcon}>{DRAFT}</span>}
          </Typography>
        </div>
        <div className={classes.iconMenu}>
          <IconMenu cy="ProjectTemplatesListEntry-menu" options={basicMenuOptions} />
        </div>
        <DialogsDuplicateTemplate
          onClose={() => {
            setShowDuplicateTemplateModal(false);
          }}
          open={showDuplicateTemplateModal}
          templateID={projectTemplate.id}
        />
        <DialogsDeleteTemplate
          onClose={() => {
            setShowDeleteTemplateModal(false);
          }}
          open={showDeleteTemplateModal}
          projectTemplate={projectTemplate}
        />
      </div>
      <Divider />
    </>
  );
};

export default withStyles(CompanyTabStyles)(ProjectTemplatesListEntry);
