import { useFocusRing } from 'react-aria';
import { Link } from 'react-router-dom';

import useLocalStorage from '../../../hooks/useLocalStorage';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import { Chip, Icon } from '../../scales';
import Star from '../common/Star';
import { ProjectIdea } from '../types';
import { getRelevanceText } from '../utils/getRelevanceText';

type Props = {
  idea: ProjectIdea;
  isStarred: boolean;
  onClick: () => void;
  onStar: (isChecked: boolean) => void;
  projectID: UUID;
};

export default function IdeaTile(props: Props) {
  const { focusProps, isFocusVisible } = useFocusRing();

  const [hiddenAlternatesByIdea] = useLocalStorage<Record<UUID, UUID[]>>(
    `IDEA_TO_HIDDEN_ALTERNATES`,
    {}
  );
  const hiddenAlternates = hiddenAlternatesByIdea[props.idea.id];
  const numAlternates = props.idea.alternates.filter(
    ({ id }) => !hiddenAlternates?.includes(id)
  ).length;

  const relevanceText = getRelevanceText(props.idea);
  const uniformatText =
    props.idea.root.uniFormat?.number && `UniFormat: ${props.idea.root.uniFormat?.number}`;
  const masterformatText =
    props.idea.root.masterFormat?.number && `MasterFormat: ${props.idea.root.masterFormat?.number}`;

  return (
    <div className="relative h-64 w-full">
      <Link
        {...focusProps}
        className="flex h-full w-full rounded bg-cover bg-no-repeat outline-none"
        onClick={props.onClick}
        style={{ backgroundImage: `url(${props.idea.root.imageURL})` }}
        to={generateSharedPath(RouteKeys.PROJECT_IDEAS_IDEA, {
          projectId: props.projectID,
          ideaID: props.idea.id,
        })}
      >
        <div
          className={`flex w-full flex-col justify-between rounded hover:bg-[#F6F7F929] ${isFocusVisible ? 'outline' : ''}`}
        >
          <div className="flex min-h-[56px] rounded-t bg-gradient-to-t from-transparent from-0% to-[#000000B3] to-80% p-2 pb-6">
            {numAlternates > 1 && (
              <Chip
                backgroundColor="#E7EAEF33"
                text={pluralizeCountString('Alternate', numAlternates)}
                textColor="var(--colors-type-secondary)"
              />
            )}
          </div>
          <div className="flex flex-col gap-2 rounded-b bg-gradient-to-t from-[#000000B3] from-75% to-transparent to-100% p-2 pt-6 text-type-secondary">
            <div className="type-heading3">{props.idea.name}</div>
            {props.idea.relevance && (
              <div className="flex items-center gap-2 type-label">
                <Icon name="bulb_ray" />
                {relevanceText}
              </div>
            )}
            {(uniformatText || masterformatText) && (
              <div className="flex gap-2">
                {uniformatText && (
                  <Chip
                    backgroundColor="#E7EAEF33"
                    text={uniformatText}
                    textColor="var(--colors-type-secondary)"
                  />
                )}
                {masterformatText && (
                  <Chip
                    backgroundColor="#E7EAEF33"
                    text={masterformatText}
                    textColor="var(--colors-type-secondary)"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Link>
      <div className="absolute right-2 top-2">
        <Star
          aria-label="Star Idea"
          isSelected={props.isStarred}
          onChange={props.onStar}
          variant="light"
        />
      </div>
    </div>
  );
}
