import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import useMemoWrapper from '../../../useMemoWrapper';
import { TIMELINE_BOTTOM_TOOLTIP_ID } from '../TimelineBottomPortal';

import { TimelineItemGroup } from './types';
import { BAR_GAP, getItemsBarParams } from './utils';

type Props = {
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  yMax: number;
  item: TimelineItemGroup;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export default function ItemsBar(props: Props) {
  const { positionX, positionY, width, yDecidedHeight, yHeight, yPastDueHeight, yUpcomingHeight } =
    useMemoWrapper(getItemsBarParams, props.x, props.y, props.yMax, props.item);
  return (
    <g transform={`translate(${positionX}, ${positionY})`}>
      <rect
        // Decided
        className="fill-entities-item-decided"
        height={yDecidedHeight}
        width={width}
        y={0}
      />
      <rect
        // Pending
        className="fill-entities-item-upcoming"
        height={yUpcomingHeight}
        width={width}
        y={yDecidedHeight}
      />
      <rect
        // Past Due
        className="fill-entities-item-pastdue"
        height={yPastDueHeight}
        width={width}
        y={yDecidedHeight + yUpcomingHeight}
      />
      {/* Gray out overlay  */}
      <rect
        className="fill-background-primary"
        fillOpacity={props.isHovered ? 0.5 : 0}
        height={yHeight}
        width={width}
      />
      {/* Hover overlay  */}
      <rect
        className="fill-transparent"
        height={yHeight}
        onMouseEnter={() => props.onMouseEnter()}
        onMouseLeave={(e) => {
          // We use id to keep tooltip on the screen
          if (
            e.relatedTarget instanceof Element &&
            e.relatedTarget?.id === TIMELINE_BOTTOM_TOOLTIP_ID
          )
            return;
          props.onMouseLeave();
        }}
        width={width + BAR_GAP}
      />
    </g>
  );
}
