import { NEW_COLUMN_FROM_AVERAGES, NULL_ID } from '../../../../constants';
import {
  CostTableColumnInput,
  CostTableColumnInputKey,
  ProjectCompsSetQuery,
} from '../../../../generated/graphql';
import { colorWheel, pickerColors } from '../../../../theme/colors';
import { isEnumValue } from '../../../../utilities/types';
import { getCostValue } from '../../../CostReport/CostReportUtils';
import { categoryDisplayName } from '../../../Select/SelectCategory/SelectCategoryUtils';
import { getColumnInputDisplayString } from '../../ProjectCompsSetUtils';

// Types
export enum TypeToggle {
  TOTAL = 'Total',
  CATEGORIZED = 'Categorized',
}

// Constants
export const COLUMN_X_PADDING_PERCENT = 0.2; // 20% padding on either side of column width for bar
export const CHART_TOP_PADDING_PERCENT = 0.1; // The padding from the top of chart to the maxiumum bar
export const TICK_COUNT = 6; // Target number of ticks on the y-axis, which is sometimes adjusted for round label values

// Helper Functions

/** 
  marshallColumnInputParam and unmarshallColumnInput format the CostTableColumnInput struct into a string form
  that we can store in our local storage parameters
  example format: "TOTAL", or "TOTAL_PER_METRIC--17c46793-7493-4df3-9d41-4cedd0dfd05c"
*/
const splitter = '--';
export const marshallColumnInputParam = (key: CostTableColumnInputKey, unitID?: UUID) =>
  `${key}${unitID ? `${splitter}${unitID}` : ''}`;
export const unmarshallColumnInput = (selectedColumnInputParam: string): CostTableColumnInput => {
  const [keyString, unitID] = selectedColumnInputParam.split(splitter);
  let key = CostTableColumnInputKey.TOTAL;
  if (isEnumValue(CostTableColumnInputKey, keyString)) {
    key = keyString;
  }
  return { key, unitID };
};

/** 
  getColor is a helper function that takes in an index and returns a color from a palette. 
*/
const getColor = (idx: number, palette: string[]) => palette[idx % palette.length];
export const getStackedBarColor = (idx: number) => getColor(idx, colorWheel);
export const getProjectColor = (idx: number) => getColor(idx, Object.values(pickerColors));

/**
 * getTitle is a helper function that takes in a type, unit, and unitName and returns a string that is used as the title of the chart.
 */
export const getTitle = (
  type: TypeToggle,
  selectedColumnInputKey: CostTableColumnInputKey,
  unitName: string
) => `${type} ${getDisplayStringFromColumn(selectedColumnInputKey, unitName)}`;

// Primary totalData calculations
type Totals =
  ProjectCompsSetQuery['projectCompsSet']['projectComps'][number]['projectCompsCostTable']['summaryLines']['totalLine']['pinnedUnitValues'];

/** 
  getValueByUnit is a helper function that takes in a total object and a selectedUnit and returns the value of the total object based on the selectedUnit. 
  There is some relationship between this and isCost, but this handles type well...
*/
const getValueByUnit = (total: Totals, selectedColumnInput: CostTableColumnInput) => {
  switch (selectedColumnInput.key) {
    case CostTableColumnInputKey.TOTAL_PER_METRIC:
      return getCostValue(total.quantityValue);
    case CostTableColumnInputKey.PERCENT:
      return total.percentValue;
    case CostTableColumnInputKey.METRIC:
      return total.quantityMagnitude;
    default:
      return getCostValue(total.totalValue);
  }
};

/**
 * getDisplayStringFromColumn is a helper that takes in the column key value, and determines the display text based on the corresponding enum.
 */
export const getDisplayStringFromColumn = (
  selectedColumnInputKey: CostTableColumnInputKey,
  unitName?: string
) => {
  switch (selectedColumnInputKey) {
    case CostTableColumnInputKey.TOTAL:
      return 'Cost';
    case CostTableColumnInputKey.TOTAL_PER_METRIC:
      return unitName ? `Cost Per ${unitName}` : `Cost Per Metric`;
    case CostTableColumnInputKey.PERCENT:
      return 'Percent';
    case CostTableColumnInputKey.METRIC:
      return unitName ?? 'Metric';
    default:
      return '';
  }
};

/**
 * getToggleOptionFromColumnInput formats the information we need to display toggle options in the actual buttons,
 * by transforming the column input key and optional unit into the string we display.
 */
const getToggleOptionFromColumnInput = (key: CostTableColumnInputKey, unit?: Unit) => {
  return {
    label: getColumnInputDisplayString(key, unit?.abbreviationSingular, false, true),
    value: marshallColumnInputParam(key, unit?.id),
  };
};

/**
 * getColumnToggleOptionsForCharts is a helper shared by ProjectCompsChartData and PrintProjectCompsChartData to collect
 * the toggle options based on column inputs (if categorized), or default total, $/unit, unit if not categorized
 */
export const getColumnToggleOptionsForCharts = (
  projectCompsSet: ProjectCompsSet,
  isCategorized: boolean,
  pinnedUnit?: Unit
) => {
  const columnToggleOptions = [];
  if (isCategorized) {
    projectCompsSet.input.costTableColumnInputs?.forEach((costTableColumnInput) => {
      const unit = projectCompsSet.units?.find((u) => u.id === costTableColumnInput.unitID);
      columnToggleOptions.push(getToggleOptionFromColumnInput(costTableColumnInput.key, unit));
    });
  } else {
    columnToggleOptions.push(getToggleOptionFromColumnInput(CostTableColumnInputKey.TOTAL));
    columnToggleOptions.push(
      getToggleOptionFromColumnInput(CostTableColumnInputKey.TOTAL_PER_METRIC, pinnedUnit)
    );
    columnToggleOptions.push(
      getToggleOptionFromColumnInput(CostTableColumnInputKey.METRIC, pinnedUnit)
    );
  }
  return columnToggleOptions;
};

// Type - Input requirements
type SetAverageComp = ProjectCompsSetQuery['projectCompsSet']['averageComp'];
type AverageCompInputCosts = Pick<NonNullable<SetAverageComp>, 'input' | 'projectCompsCostTable'>;
type ProjectCompInputCosts = Pick<
  ProjectCompsSetQuery['projectCompsSet']['projectComps'][number],
  'project' | 'projectCompsCostTable' | 'input'
>;
type ProjectCompsSetInputCosts = {
  averageComp?: AverageCompInputCosts | null;
  projectComps: ProjectCompInputCosts[];
};

/**
  generateProjectCompsChartData is a function that takes in a projectCompsSet and returns a totalData object that can be used to render a bar chart.
  It also returns a boolean isCost that is used to determine if the chart is a cost chart or not.
*/
export const generateProjectCompsChartData = ({
  projectCompsSet,
  selectedColumnInput,
}: {
  projectCompsSet: ProjectCompsSetInputCosts;
  selectedColumnInput: CostTableColumnInput;
}) => {
  const isCost =
    selectedColumnInput.key === CostTableColumnInputKey.TOTAL_PER_METRIC ||
    selectedColumnInput.key === CostTableColumnInputKey.TOTAL;

  const { averageComp, projectComps } = projectCompsSet;

  // Do Project Comps First
  const totalData = projectComps
    .filter((comp) => !comp.input.isExcluded)
    .map((comp, i) => {
      const { categoryLines, summaryLines } = comp.projectCompsCostTable;
      const { totalLine } = summaryLines;

      const id = comp.input.id || comp.project.id || '';
      const name = comp.input.name || comp.project.name || '';
      const color = getProjectColor(i);

      return {
        id,
        name,
        color,
        total: totalLine.pinnedUnitValues,
        categoryLines,
        value: getValueByUnit(totalLine.pinnedUnitValues, selectedColumnInput), // This total value is directly consumed by both charts
      };
    });

  // Then Average Comp
  if (averageComp) {
    const { summaryLines, categoryLines } = averageComp.projectCompsCostTable;
    const { totalLine } = summaryLines;

    const averageData = {
      id: NULL_ID,
      name: averageComp?.input?.name || NEW_COLUMN_FROM_AVERAGES,
      color: averageComp?.input?.color || '',
      total: totalLine.pinnedUnitValues,
      categoryLines,
      value: getValueByUnit(totalLine.pinnedUnitValues, selectedColumnInput),
    };
    totalData.unshift(averageData);
  }
  return { totalData, isCost };
};
export type TotalBar = ReturnType<typeof generateProjectCompsChartData>['totalData'][number];

/**
   generateCategoryNameMap is a helper function that takes in totalData and 
   returns a map of category numbers to category names.
*/
export const generateCategoryNameMap = (totalData: TotalBar[]) => {
  const mappedCategoryNames = new Map<string, string>();
  if (totalData.length) {
    totalData[0].categoryLines.forEach(({ category }) => {
      const name = categoryDisplayName(category);
      mappedCategoryNames.set(category.number, name);
    });
  }
  return mappedCategoryNames;
};

export type CategorizedValues = Record<string, number>;

/** 
  getNumericValueForColumnValue is a helper function to properly round values based on it 
  being a cost or number vs. a percent value
 */
export const getNumericValueForColumnValue = (key: CostTableColumnInputKey, rawValue?: number) => {
  const divisor = key === CostTableColumnInputKey.PERCENT ? 100 : 1;
  return (rawValue ?? 0) / divisor;
};

/** 
  getCategoryValues is a helper function that takes in a categoryLines object and 
  returns the sum of the average comp category values and a map of category numbers to category values.
 */
const getCategoryValues = (
  categoryLines: ProjectCompInputCosts['projectCompsCostTable']['categoryLines'],
  filterKeys: string[],
  selectedColumnInput: CostTableColumnInput
) => {
  const categoryValues: CategorizedValues = {};
  categoryLines.forEach(({ category, columnValues }) => {
    const isFiltered = !filterKeys.length || filterKeys.includes(category.number);

    const matchingColumnValue = columnValues.find(
      (v) =>
        v.costTableColumnInput.key === selectedColumnInput.key &&
        (!selectedColumnInput.unitID ||
          v.costTableColumnInput.unitID === selectedColumnInput.unitID)
    );

    const value = getNumericValueForColumnValue(
      selectedColumnInput.key,
      matchingColumnValue?.valueNumeric
    );
    categoryValues[category.number] = isFiltered ? value : 0;
  });

  return categoryValues;
};

type GenerateCategorizedDataProps = {
  totalData: TotalBar[];
  filterKeys: string[];
  selectedColumnInput: CostTableColumnInput;
};

/** 
  generateCategorizedData is a function for the stacked chart that takes in totalData, filterKeys, and selectedUnit
  and returns a categorizedData object, a mappedCategoryNames map, and the sum of the average comp category values.
*/
export const generateCategorizedData = ({
  totalData,
  filterKeys,
  selectedColumnInput,
}: GenerateCategorizedDataProps) =>
  totalData.map((d) => getCategoryValues(d.categoryLines, filterKeys, selectedColumnInput));

/** 
  sumOfCategorizedValues is a helper function that takes in a totalData object and returns the sum of the values.
*/
export const sumOfCategorizedValues = (categorizedValues: CategorizedValues) =>
  Object.values(categorizedValues).reduce((acc, val) => acc + val, 0);
