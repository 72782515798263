type Props = {
  allChips: JSX.Element[];
  lastUpdated: JSX.Element | undefined;
};

const ProjectTableChipsTooltip = (props: Props) => {
  return (
    <div className="flex w-80 flex-col gap-2">
      <div className="flex flex-wrap gap-2 type-table-text">
        {props.allChips.map((chip) => (
          <div key={chip.key}>{chip}</div>
        ))}
      </div>
      {props.lastUpdated && <div className="type-table-text">{props.lastUpdated}</div>}
    </div>
  );
};

export default ProjectTableChipsTooltip;
