import { HasDisplayCosts } from '../conditionals/HasDisplayCosts';
import ProjectRoute from '../ProjectRoute/ProjectRoute';

export default function ExportRoute() {
  return (
    <HasDisplayCosts>
      <ProjectRoute />
    </HasDisplayCosts>
  );
}
