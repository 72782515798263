import { FC, MouseEvent } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { RouteKeys } from '../../routes/paths';
import { withStyles } from '../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../utilities/clickHandler';
import { generateSharedPath } from '../../utilities/routes/links';

import { styles } from './MilestonesStyles';

type MilestoneReportInlineLinkProps = {
  classes: Classes<typeof styles>;
  milestoneId: UUID;
  milestones: Milestone[];
  search?: string;
};

const MilestoneReportInlineLink: FC<MilestoneReportInlineLinkProps> = ({
  classes,
  milestoneId,
  milestones,
  search = '',
}) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');
  const path = generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE_MSR, {
    projectId,
    milestoneId,
  });
  const currentMilestone = milestones.filter((m) => m.id === milestoneId);
  let milestoneName = '';
  if (currentMilestone.length === 1) {
    milestoneName = currentMilestone[0].name;
  }
  return (
    <div className={classes.itemsListHeaderMSRLink}>
      <Typography className={classes.itemsListMilestoneLinkText}>
        Want to see more subtotals? Go to
      </Typography>
      <div
        className={`${classes.containerMilestoneReport}`}
        onClick={(e: MouseEvent) => {
          clickLinkKeyHandler(navigate, e, path, search);
          e.stopPropagation();
        }}
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>): void => {
          clickLinkKeyHandler(navigate, e, path, search);
          e.stopPropagation();
        }}
        role="button"
        tabIndex={0}
      >
        <Typography className={classes.milestoneInlineLink} title="Go to Milestone Summary Report">
          {` ${milestoneName} Milestone Summary Report`}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(MilestoneReportInlineLink);
