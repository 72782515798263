import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { refetchPermissions } from '../../../../api/refetchSets';
import {
  PermissionLevel,
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables,
  UpdateRoleNameMutation,
  UpdateRoleNameMutationVariables,
  UpdateRoleTradeMutation,
  UpdateRoleTradeMutationVariables,
} from '../../../../generated/graphql';
import { useRefetch } from '../../../../hooks/useRefetch';

import {
  updatePermissionMutation,
  updateRoleNameMutation,
  updateRoleTradeMutation,
} from './queries';

export type PermissionUpdate = {
  id: UUID;
  level: PermissionLevel;
};

export type PermissionChanges = {
  roleID: UUID;
  name?: string;
  hasTrade?: boolean;
  permissionUpdates: PermissionUpdate[];
};

export const isDefined = (name?: string | boolean) => name !== undefined;

const useSyncPermissionsWithBackend = () => {
  const [updateRoleNameMutationFunc] = useMutation<
    UpdateRoleNameMutation,
    UpdateRoleNameMutationVariables
  >(updateRoleNameMutation);
  const [updateRoleTradeMutationFunc] = useMutation<
    UpdateRoleTradeMutation,
    UpdateRoleTradeMutationVariables
  >(updateRoleTradeMutation);
  const [updatePermissionMutationFunc] = useMutation<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >(updatePermissionMutation);
  const { projectId, roleId } = useParams();
  if (!projectId) throw new Error('Project ID is required');
  if (!roleId) throw new Error('Role ID is required');

  const refetch = useRefetch(refetchPermissions(projectId, roleId));

  return async ({ roleID, name, hasTrade = false, permissionUpdates }: PermissionChanges) => {
    const mutations: Promise<unknown>[] = [];
    // if new role name, then do it!
    if (roleID) {
      if (name && isDefined(name))
        mutations.push(
          updateRoleNameMutationFunc({ variables: { projectID: projectId, roleID, name } })
        );
      if (isDefined(hasTrade))
        mutations.push(
          updateRoleTradeMutationFunc({ variables: { projectID: projectId, roleID, hasTrade } })
        );
      permissionUpdates.forEach((permissionUpdate: PermissionUpdate) => {
        mutations.push(
          updatePermissionMutationFunc({ variables: { projectID: projectId, ...permissionUpdate } })
        );
      });
      if (mutations.length > 0) {
        // wait til these all complete then...
        await Promise.all(mutations).then(() => {
          // refetch role
          refetch();
        });
      }
    }
  };
};

export default useSyncPermissionsWithBackend;
