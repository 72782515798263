import { FC } from 'react';

import { Avatar } from '@material-ui/core';
import {
  ChevronRight,
  Edit,
  ErrorOutline,
  Link as LinkIcon,
  MoreVert,
  Print,
} from '@material-ui/icons';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { ExcelIcon } from '../../../Icons';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import { Button, Icon, IconButton, IconMenuButton, MenuEntry, Tooltip } from '../../../scales';

import styles from './ReportsListEntrySettingsStyles';

const tipExcel = 'Download as .xlsx';
const tipPrint = 'Print or export to PDF';
const tipGo = 'View in Join';
const tipLink = 'Copy link to clipboard';

const verticalDividerIcon = (
  <svg height="26" id="line" style={{ flexShrink: 0, marginRight: 4 }} width="1">
    <line stroke="grey" strokeWidth="1" x1="1" x2="1" y1="0" y2="26" />
  </svg>
);

export type ReportsListEntrySettingsType = {
  error?: string | undefined;
  cy?: string;
  onDelete?: (() => void) | undefined;
  onEdit?: (() => void) | undefined;
  onExcel?: (() => void) | undefined;
  onGo: (() => void) | undefined;
  onLink: (() => void) | undefined;
  onPrint?: (() => void) | undefined;
  onDistribute?: (() => void) | undefined;
  hasDistributionScheduled?: boolean;
};

type ClassesType = {
  classes: Classes<typeof styles>;
};

interface ReportsListEntrySettingsProps extends ClassesType, ReportsListEntrySettingsType {}

const ReportsListEntrySettings: FC<ReportsListEntrySettingsProps> = ({
  classes,
  error,
  cy = '',
  onDelete,
  onEdit,
  onExcel,
  onGo,
  onLink,
  onPrint,
  onDistribute,
  hasDistributionScheduled,
}) => {
  const menuOptions: MenuEntry[] = [];
  if (onEdit) {
    menuOptions.push({
      id: 'edit',
      label: 'Edit',
      onClick: () => onEdit(),
      startAdornment: <Edit />,
    });
  }
  if (onDistribute) {
    menuOptions.push({
      id: 'distribute',
      label: `${
        hasDistributionScheduled ? 'Edit Scheduled Distribution' : 'Send/Schedule Distribution'
      }`,
      onClick: () => onDistribute(),
      startAdornment: hasDistributionScheduled ? (
        <img
          alt="Has Distribution Scheduled"
          src="/img/ReportDistribution/Icon_ScheduleDistribution.svg"
          width={24}
        />
      ) : (
        <img
          alt="Has Distribution Scheduled"
          src="/img/ReportDistribution/Icon_SendDistribution.svg"
          width={24}
        />
      ),
    });
  }
  if (onDelete) {
    menuOptions.push({
      id: 'delete',
      label: 'Delete',
      onClick: () => onDelete(),
      startAdornment: <Icon name="delete" />,
      type: 'destructive',
    });
  }

  const optionButton = (
    <IconMenuButton
      aria-label="dropdown menu"
      data-cy={cy}
      entries={menuOptions}
      icon={<MoreVert />}
      isDisabled={!menuOptions.length}
      type="secondary"
    />
  );

  const errorSign = (
    <NormalTooltip title={error}>
      <Avatar className={classes.avatar}>
        <ErrorOutline className={classes.iconError} />
      </Avatar>
    </NormalTooltip>
  );

  if (error)
    return (
      <div className={classes.root}>
        {errorSign}
        {optionButton}
      </div>
    );

  return (
    <div className={classes.root}>
      <Tooltip content={tipExcel} isDisabled={!onExcel}>
        <IconButton
          aria-label="export"
          icon={<ExcelIcon />}
          isDisabled={!onExcel}
          onClick={onExcel}
          type="secondary"
        />
      </Tooltip>
      <Tooltip content={tipPrint} isDisabled={!onPrint}>
        <IconButton
          aria-label="print"
          icon={<Print />}
          isDisabled={!onPrint}
          onClick={onPrint}
          type="secondary"
        />
      </Tooltip>
      {verticalDividerIcon}
      <Tooltip content={tipGo} isDisabled={!onGo}>
        <Button
          data-cy="go-to-report-button"
          endIcon={<ChevronRight />}
          isDisabled={!onGo}
          label="Go"
          onClick={onGo}
          type="secondary"
        />
      </Tooltip>
      <Tooltip content={tipLink} isDisabled={!onLink}>
        <IconButton
          aria-label="link"
          icon={<LinkIcon />}
          isDisabled={!onLink}
          onClick={onLink}
          type="secondary"
        />
      </Tooltip>
      {verticalDividerIcon}
      {optionButton}
    </div>
  );
};

export default withStyles(styles)(ReportsListEntrySettings);
