import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import {
  CreateItemFromAlternateMutation,
  CreateItemFromAlternateMutationVariables,
} from '../../../../generated/graphql';
import { RouteKeys } from '../../../../routes/paths';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { useProjectID } from '../../../../utilities/routes/params';

import { createItemFromAlternateMutation } from './queries';

export default function useCreateItemFromAlternateMutation(ideaID: UUID) {
  const projectID = useProjectID() || '';
  const navigate = useNavigate();

  const [createItem] = useMutation<
    CreateItemFromAlternateMutation,
    CreateItemFromAlternateMutationVariables
  >(createItemFromAlternateMutation);

  const submitFunc = useCallback(
    async (alternateID: UUID) => {
      const mutationResult = await createItem({ variables: { projectID, ideaID, alternateID } });

      const itemID = mutationResult.data?.createItemFromAlternate;
      if (itemID)
        navigate(
          generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, { projectId: projectID, itemId: itemID })
        );
    },
    [createItem, ideaID, navigate, projectID]
  );

  return [submitFunc];
}
