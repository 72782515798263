import { ProjectType } from '../../../../generated/graphql';
import { pluralizeCountString } from '../../../../utilities/string';
import { Button, Dialog, DialogContent, Icon } from '../../../scales';
import { useDeleteProjectTypeMutation } from '../../CompanyHooks';

export type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onProjectStats: () => void;
  subType: ProjectType;
  type: ProjectType;
  usedInProjectsCount?: number;
  templateCount?: number;
};

export default function DeleteProjectSubtypeDialog(props: Props) {
  const deleteProjectType = useDeleteProjectTypeMutation();
  const onDelete = () => {
    deleteProjectType(props.subType.id);
    props.onClose?.();
  };
  const onProjectStats = () => {
    props.onClose?.();
    props.onProjectStats();
  };
  const disabled = Boolean(props.usedInProjectsCount) || Boolean(props.templateCount);

  const projectCountText = () => {
    let text = '';
    if (props.usedInProjectsCount) {
      text += pluralizeCountString(`project`, props.usedInProjectsCount ?? 0);
    }
    if (props.templateCount) {
      if (text !== '') text += ' and ';
      text += pluralizeCountString(`template`, props.templateCount ?? 0);
    }
    return text;
  };

  return (
    <Dialog
      {...props}
      footerLeft={
        disabled && (
          <Button
            data-cy="go-to-project-stats-button"
            label="Go to Project Stats"
            onClick={onProjectStats}
            type="secondary"
          />
        )
      }
      footerRight={
        disabled ? (
          <Button label="OK" onClick={props.onClose} type="primary" />
        ) : (
          <Button
            isDisabled={disabled}
            label="Delete"
            onClick={onDelete}
            startIcon={<Icon name="delete" />}
            type="destructive"
          />
        )
      }
      title={`Delete Project Subtype: ${props.subType.name} (${props.type.name})`}
    >
      <DialogContent className="flex flex-col gap-2">
        {disabled ? (
          <div className="inline-block type-body1" data-cy="text-Delete Project Type">
            <div>
              {`We cannot delete this project subtype, because there ${
                (props.usedInProjectsCount ?? 0) === 1 ? 'is' : 'are'
              } still `}
            </div>
            <strong className="inline">{projectCountText()}</strong>
            <div className="inline">{` assigned to this subtype.`}</div>
            <br />
            <br />
            <div>
              You can use the Project Stats button below to reassign projects, then try again.
            </div>
          </div>
        ) : (
          <div className="type-body1">
            Are you sure? By deleting this project subtype, your teammates will no longer have this
            option available to select for Project Type.
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
