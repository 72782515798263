import { FC } from 'react';
import { useParams } from 'react-router-dom';

import {
  DETACH_ITEM_ASSET,
  DETACH_MILESTONE_ASSET,
  DOWNLOAD_ITEM_ASSET,
  DOWNLOAD_MILESTONE_ASSET,
} from '../../../actions/actionTypes';
import {
  getItemAssetAnalytics,
  getMilestoneAssetAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { assetViewerOpenVar } from '../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useDownloadAsset } from '../hooks/AssetsMutationHook';
import { useDetachFromItem } from '../hooks/mutations/useDetachFromItem';
import { useDetachFromMilestone } from '../hooks/mutations/useDetachFromMilestone';

import AssetsCards from './AssetsCards';

type AssetsCardsWrapperProps = {
  assets: EntityAttachment[];
  customProjectID?: UUID | undefined;
  hasDeletePermission: boolean;
  itemID?: UUID;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  readOnly?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMenu: boolean;
  onItemMutated?: () => void;
};

const AssetsCardsWrapper: FC<AssetsCardsWrapperProps> = ({
  assets,
  customProjectID,
  hasDeletePermission,
  itemID,
  readOnly,
  showMenu,
  onItemMutated,
}) => {
  const params = useParams();
  const milestoneId = params.milestoneId;
  const projectId = customProjectID ?? params.projectId;
  if (!projectId) throw new Error('Project ID not found');

  const sendAnalytics = useSendAnalytics();

  const [detachFromItemFunc] = useDetachFromItem();
  const [detachFromMilestoneFunc] = useDetachFromMilestone();
  const detachAttachment = (assetID: string, sourceSystem?: SourceSystem) => {
    if (itemID) {
      detachFromItemFunc(projectId, itemID, assetID, sourceSystem, onItemMutated);
      sendAnalytics(getItemAssetAnalytics(DETACH_ITEM_ASSET, itemID, assetID));
      assetViewerOpenVar(false);
    } else if (milestoneId) {
      detachFromMilestoneFunc(projectId, milestoneId, assetID, sourceSystem);
      sendAnalytics(getMilestoneAssetAnalytics(DETACH_MILESTONE_ASSET, milestoneId, assetID));
      assetViewerOpenVar(false);
    }
  };

  const [downloadAssetFunc] = useDownloadAsset();
  const downloadAsset = (location: string, name: string, id: string) => {
    downloadAssetFunc(location, name);
    if (itemID) {
      sendAnalytics(getItemAssetAnalytics(DOWNLOAD_ITEM_ASSET, itemID, id));
    } else if (milestoneId) {
      sendAnalytics(getMilestoneAssetAnalytics(DOWNLOAD_MILESTONE_ASSET, milestoneId, id));
    }
  };

  return (
    <AssetsCards
      assets={assets}
      detachAttachment={detachAttachment}
      downloadAsset={downloadAsset}
      hasDeletePermission={hasDeletePermission}
      readOnly={readOnly}
      showMenu={showMenu}
    />
  );
};

export default AssetsCardsWrapper;
