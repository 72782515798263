import * as d3 from 'd3';

import { useReactiveVar } from '@apollo/client';

import { projectsSummaryVar } from '../../../api/apollo/reactiveVars';
import SVGWithDimensions from '../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../Charts/ChartsD3/useChartDimensions';
import TimelinePath from '../../dragon-scales/TimelineCharts/TimelinePath';

import { createXScale } from './utils';

const BAR_HEIGHT = 4;

type Props = {
  height: number;
  width: number;
  projects: { id: UUID }[];
  totalRange: [string, string];
};

export default function ProjectsSummary(props: Props) {
  const summary = useReactiveVar(projectsSummaryVar);
  const isMoreThanSix = props.projects.length > 6;
  const barHeight = isMoreThanSix ? BAR_HEIGHT / 2 : BAR_HEIGHT;

  const { ref, dimensions } = useChartDimensions({
    height: props.height,
    width: props.width,
    marginTop: isMoreThanSix ? 4 : 8,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { height } = props;

  const yMax = height;
  const yMin = 0;
  const y = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);
  const x = createXScale(props.totalRange, props.width);

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      {props.projects.map(({ id }, i) => {
        const bar = summary.get(id);
        if (!bar) return null;
        const value = height - i * barHeight * 2 + barHeight;
        const path = [
          { date: bar[0], value },
          { date: bar[1], value },
        ];
        return (
          <TimelinePath<{ value: number }>
            key={value}
            className="stroke-radio-disabled"
            data={path}
            strokeWidth={barHeight}
            x={x}
            y={y}
          />
        );
      })}
    </SVGWithDimensions>
  );
}
