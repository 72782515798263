import {
  ForecastingReportShareEvent,
  getForecastingReportShareEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ResourceType } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { toSharedResourceUsers } from '../../../../utilities/utilities';
import SharedUserState from '../../../dragon-scales/Share/SharedResourceIcon/SharedUserState';
import { useGetSharedResource } from '../../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { Tooltip } from '../../../scales';

type Props = {
  report: ForecastingReport;
};

export default function SharedScenarioBadge(props: Props) {
  const sendAnalytics = useSendAnalytics();

  const { data } = useGetSharedResource(props.report.id, ResourceType.FORECASTING_REPORT);
  const sharedUsers = toSharedResourceUsers(data);

  // if not shared,
  if (!sharedUsers || sharedUsers.length < 2) {
    return (
      <Tooltip
        content={
          <div className="type-table-text">
            <p className="type-table-header">Shared with 0 teammates.</p>
            <p>Click on the menu or report</p>
            <p>to share within your company.</p>
          </div>
        }
      >
        <div>Private to me</div>
      </Tooltip>
    );
  }

  return (
    <SharedUserState
      onTooltipOpen={() => {
        sendAnalytics(
          getForecastingReportShareEvent(ForecastingReportShareEvent.TOOLTIP, {
            location: 'ForecastingReportsList',
          })
        );
      }}
      sharedUsers={sharedUsers}
    />
  );
}
