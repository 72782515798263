import { generatePath } from 'react-router-dom';

import paths, { RouteKeys } from '../../routes/paths';
import { AllRoutes, SharedRoute } from '../../routes/routes';
import { isEnumValue } from '../types';

type SharedPathParams = {
  assetID?: string;
  estimateId?: string;
  ideaID?: string;
  itemId?: string;
  milestoneId?: string;
  organizationId?: string;
  programID?: string;
  projectId?: string;
  reportID?: string;
  roleId?: string;
  search?: string;
  setId?: string;
  userId?: string;
};

/**
 * @deprecated in our new routing model.
 *
 * Use `generateSharedPath` if requesting a path. If you want to see whether a
 * route is accessible before constructing the path, do the appropriate
 * permissions check via usePermissions().
 */
const generateSharedRoute = (
  routeKey: keyof typeof AllRoutes,
  params?: SharedPathParams
): SharedRoute => {
  const route = AllRoutes[routeKey];
  if (!route) throw new Error(`Route "${routeKey}" does not exist.`);

  if (typeof route !== 'object' || 'path' in route === false) {
    throw new Error(
      'Calling this function for modern routes is not supported. It only supports legacy routes.'
    );
  }

  const path = generatePath(route.path, params);
  return { ...route, path };
};

export const generateSharedPath = (routeKey: string, params?: SharedPathParams) => {
  let path: string | undefined;

  // first, try new routes
  if (isEnumValue(RouteKeys, routeKey)) {
    path = paths[routeKey];
    if (path) {
      path = generatePath(path, params);
    }
  }

  // if didn't find one, fall back to legacy routes
  if (!path) {
    // this may throw but that's good at this point.
    path = generateSharedRoute(routeKey as keyof typeof AllRoutes, params).path;
  }

  if (!path) throw new Error(`Path for "${routeKey}" does not exist.`);

  return path + (params?.search ?? '');
};
