import * as React from 'react';

import { Dialog, DialogActions, DialogContent, Divider, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateTitle } from '../../Items/ItemsListItem/ItemsListItemUtils';
import { Button, Icon, IconButton } from '../../scales';
import DialogsStyles from '../DialogsStyles';

type Props = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  option: Option;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClose?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onConfirm?: (event: any) => void;
};

const DialogsDetachOption: React.FC<Props> = ({
  classes,
  onClose,
  onConfirm,
  open,
  option,
  // status
}) => {
  const convertText = (
    <Typography>
      {`Your option `}
      <b>{generateTitle(option)}</b>
      {` will be detached from this item and will become a standalone item in this milestone.`}
    </Typography>
  );
  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">Detach option</Typography>
        </div>
        <IconButton
          aria-label="Close dialog"
          icon={<Icon name="close" />}
          onClick={onClose}
          type="secondary"
        />
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        {convertText}
        <Typography>You can undo by re-adding this item as an option later.</Typography>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button label="Cancel" onClick={onClose} type="secondary" />
        <Button
          data-cy="confirm-dialog--ok-button"
          label="Detach"
          onClick={onConfirm}
          type="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsDetachOption);
