import { RefObject, useLayoutEffect } from 'react';

let preventScrollCount = 0;

// Modified from https://github.com/adobe/react-spectrum/blob/main/packages/@react-aria/overlays/src/usePreventScroll.ts
export function usePreventMainScroll(ref: RefObject<Element>) {
  useLayoutEffect(() => {
    const el: HTMLElement | null | undefined = ref.current?.closest('main');
    if (!el) return undefined;

    const overflow = el.style.overflow;
    const paddingRight = el.style.paddingRight;
    preventScrollCount += 1;
    if (preventScrollCount === 1) {
      el.style.overflow = 'hidden';
      el.style.paddingRight = `${el.clientWidth - el.offsetWidth}px`;
    }

    return () => {
      preventScrollCount -= 1;
      if (preventScrollCount === 0) {
        el.style.overflow = overflow;
        el.style.paddingRight = paddingRight;
      }
    };
  }, [ref]);
}
