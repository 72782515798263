import { useParams } from 'react-router-dom';

import { useEstimateQuery } from '../../../hooks/useEstimateQuery';
import { isNonNullable } from '../../../utilities/types';
import useMilestoneQuantitiesQuery from '../../Milestone/hooks/useMilestoneQuantitiesQuery';
import { QuantityListItem } from '../../Milestone/MilestoneDetails/MilestoneDetailsQuantities/MilestoneDetailsQuantitiesUtils';

export type GroupByOption = {
  name?: string;
  id: UUID;
  levelNames?: string[];
  hints?: string[];
  label?: string;
  title?: string;
};

export type NonCategorizationGroupBy = {
  name: string;
  variant: string;
};

export type MultiGroupOrderOption = DisplayGroupBy | NonCategorizationGroupBy | GroupByOption;

export const filterDisplayGroupBys = (options: MultiGroupOrderOption[]) =>
  options.filter((x): x is DisplayGroupBy => 'id' in x && !!x.id && 'categorization' in x);

export const mapLevelNamesToOptions = (id: string) => (level: string, i: number) => ({
  id,
  name: level,
  level: i + 1,
});

export const useMilestoneQuantities = () => {
  const { milestoneId } = useParams();
  const quantities = useMilestoneQuantitiesQuery(milestoneId).data?.quantities ?? [];
  return quantities.filter((quantity) => quantity.unit.isEnabled);
};

export const useCategorizationsForQuantity = (quantity: Quantity | QuantityListItem) => {
  const { estimateID } = quantity;
  const { data, loading } = useEstimateQuery(estimateID);
  const fields = data?.estimate?.fields ?? [];
  const categorizations: Categorization[] =
    (!loading &&
      estimateID &&
      fields.map((field: Field) => field.categorization).filter(isNonNullable)) ||
    [];
  return { categorizations, loading };
};

const getTopLevelCategorizationOption = (option: GroupByOption) => {
  const groupBy = option as GroupByOption;
  const { levelNames } = groupBy;
  if (levelNames) {
    const name = levelNames[0];
    return { ...groupBy, name };
  }
  return groupBy;
};

export const getTopLevelOptionsforCategorizations = (
  categorizations: Categorization[],
  options: MultiGroupOrderOption[]
) => {
  const groupBys: GroupByOption[] = [];
  categorizations.forEach(({ id }) => {
    const option = options.find((o) => (o as GroupByOption).id === id);
    if (option) {
      groupBys.push(getTopLevelCategorizationOption(option as GroupByOption));
    }
  });
  return groupBys;
};
