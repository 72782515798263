import { FC } from 'react';

import { START_REPORT_BUTTON } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { Button } from '../../../scales';

import styles from './ReportsListEntryStartReportStyles';

export type ReportsListEntryStartReportType = {
  onStart: () => void;
};

type ClassesType = {
  classes: Classes<typeof styles>;
  isViewOnly: boolean;
};

interface ReportsListEntryStartReportProps extends ClassesType, ReportsListEntryStartReportType {}

const ReportsListEntryStartReport: FC<ReportsListEntryStartReportProps> = ({
  classes,
  onStart,
  isViewOnly,
}) => (
  <div className={classes.root}>
    <Button
      data-cy={START_REPORT_BUTTON}
      isDisabled={isViewOnly}
      label="Start Report"
      onClick={onStart}
      type="secondary"
    />
  </div>
);

export const StyledReportsListEntryStartReport = withStyles(styles)(ReportsListEntryStartReport);

export default StyledReportsListEntryStartReport;
