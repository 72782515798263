import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { OpenInNew } from '@material-ui/icons';

import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  ProgramCollaboratorFieldsFragment,
  ProgramFieldsFragment,
  ResourceType,
  SharedResource,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { ShareDialog } from '../../dragon-scales';
import { getSelectedText } from '../../dragon-scales/Share/utils';
import { useShareResources } from '../../ItemsList/ItemsSharing/hooks/useShareResourceHook';
import { DialogContent } from '../../scales';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';

import ProgamUsersTableFilter from './ProgramUsersTableFilter';
import { getSharedResourceInput } from './utils';

type Props = {
  isOpen: boolean;
  collaborators: ProgramCollaboratorFieldsFragment[];
  resource: SharedResource;
  program: ProgramFieldsFragment;
  setIsOpen: (value: boolean) => void;
};

export default function ProgramUsersDialog({
  isOpen,
  program,
  resource,
  collaborators,
  setIsOpen,
}: Props) {
  const sendAnalytics = useSendAnalytics();
  const originalCollaboratorIDs = useMemo(
    () =>
      collaborators
        .filter((c) => resource?.users.find((u) => u.id === c.user.id))
        .map((c) => {
          return c.user.id;
        }) ?? [],
    [collaborators, resource?.users]
  );

  const [selectedUserIDs, setSelectedUserIDs] = useState<UUID[]>(originalCollaboratorIDs);

  const shareResources = useShareResources();

  const { selectedText, summaryText } = getSelectedText(
    selectedUserIDs,
    selectedUserIDs?.filter((c) => !originalCollaboratorIDs.includes(c)),
    originalCollaboratorIDs?.filter((c) => !selectedUserIDs.includes(c))
  );

  return (
    <ShareDialog
      isOpen={isOpen}
      isShareDisabled={false}
      onClose={() => setIsOpen(false)}
      onShare={() => {
        const { userIDs, accessLevels } = getSharedResourceInput(resource, selectedUserIDs);
        shareResources(ResourceType.PROGRAM, [program.id], userIDs, accessLevels);
        setIsOpen(false);
        sendAnalytics(
          ProgramReportingAnalyticsEvent(ProgramReportingTypes.SHARE_PROGRAM, {
            programName: program?.name,
            numSharedWith: userIDs.length,
            sharedRoles: [
              ...new Set(collaborators.map((c) => c.roles.map((r) => r.roleName)).flat()),
            ],
          })
        );
      }}
      selectedText={selectedText}
      shareLabel="Share"
      summaryText={summaryText}
      title={`Share ${program?.name}`}
    >
      <DialogContent className="flex h-full flex-col gap-2 pb-0">
        <div className="flex">
          <div className="flex-grow self-center">
            Select who you want to have access to this Program
          </div>
          <ShadedHelpTip
            shiftLeft
            tip={
              <div className="type-small flex gap-1 type-label">
                Tip: Recipients will view this Program with their most restrictive
                <Link
                  className="relative top-[1px] flex type-small-link"
                  target="_blank"
                  to="https://success.join.build/en/knowledge/overview-how-join-calculates-project-costs"
                >
                  <div className="m-auto">Cost Mode</div>
                  <div className="inline icon-sm">
                    <OpenInNew fontSize="inherit" />
                  </div>
                </Link>
              </div>
            }
          />
        </div>
        <ProgamUsersTableFilter
          loading={false}
          programCollaborators={collaborators}
          projectCount={program.projects.length}
          selectedUserIDs={selectedUserIDs}
          setSelectedUserIDs={setSelectedUserIDs}
        />
      </DialogContent>
    </ShareDialog>
  );
}
