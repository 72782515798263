import { TermKey } from '../../api/gqlEnums';
import {
  ProjectCostBreakdownFieldsFragment,
  ProjectCostBreakdownType,
} from '../../generated/graphql';

export const generateProjectCostBreakdownReportStorageParam = (milestoneID: string) =>
  `Milestone ${milestoneID} Project Cost Breakdown Report `;

export const costBreakdownTypeToColorMap = new Map<string, string>([
  [ProjectCostBreakdownType.ALLOWANCES, 'fill-entities-allowances'],
  [ProjectCostBreakdownType.CONTINGENCIES, 'fill-entities-contingencies'],
  [ProjectCostBreakdownType.DIRECTCOSTS, 'fill-background-2'], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS, 'fill-background-2'],
  [ProjectCostBreakdownType.COSTOFCONSTRUCTION, 'fill-background-2'], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.MARKUPS, 'fill-entities-markups'],
  [ProjectCostBreakdownType.OWNERCOSTS, 'fill-entities-owner-cost'],
]);

export const projectBreakdownTableOrder = new Map<string, number>([
  [ProjectCostBreakdownType.DIRECTCOSTS, 0], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS, 1],
  [ProjectCostBreakdownType.COSTOFCONSTRUCTION, 2], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.MARKUPS, 3],
  [ProjectCostBreakdownType.CONTINGENCIES, 4],
  [ProjectCostBreakdownType.ALLOWANCES, 5],
  [ProjectCostBreakdownType.OWNERCOSTS, 6],
]);

export const projectBreakdownChartOrder = new Map<string, number>([
  [ProjectCostBreakdownType.MARKUPS, 0],
  [ProjectCostBreakdownType.CONTINGENCIES, 1],
  [ProjectCostBreakdownType.ALLOWANCES, 2],
  [ProjectCostBreakdownType.OWNERCOSTS, 3],
  [ProjectCostBreakdownType.DIRECTCOSTS, 4], // Either Direct costs or Cost of construction should be visible, not both
  [ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS, 5],
  [ProjectCostBreakdownType.COSTOFCONSTRUCTION, 6], // Either Direct costs or Cost of construction should be visible, not both
]);

export const breakdownSorting = (order: Map<string, number>) => {
  return (a: ProjectCostBreakdownFieldsFragment, b: ProjectCostBreakdownFieldsFragment) => {
    const i = order.get(a.name ?? '') ?? 0;
    const j = order.get(b.name ?? '') ?? 0;
    return i - j;
  };
};

export const getProgramCostBreakdownHeaders = (isRunningTotal = false) => [
  { copy: 'Costs', key: 'header-costs' },
  { copy: 'Percent', key: 'header-percent', isRightAligned: true },
  { copy: isRunningTotal ? 'Running Total' : 'Total', key: 'header-total', isRightAligned: true },
];

export const projectCostBreakdownHeaders = [
  { copy: 'Project', key: 'header-costs' },
  { copy: 'Percent', key: 'header-percent', isRightAligned: true },
  { copy: 'Running Total', key: 'header-total', isRightAligned: true },
];

export const getTermName = (name: string, terms: TermStore): string => {
  const termName = name?.replace(/([A-Z])/g, ' $1').trim();
  switch (name) {
    case ProjectCostBreakdownType.MARKUPS:
      return terms.titleCase(TermKey.MARKUP);
    case ProjectCostBreakdownType.DIRECTCOSTS:
      return terms.titleCase(TermKey.DIRECT_COST);
    case ProjectCostBreakdownType.DIRECTCOSTSANDALLOCATEDMARKUPS:
      return `${terms.titleCase(TermKey.DIRECT_COST)} and Allocated ${terms.titleCase(TermKey.MARKUP)}`;
    default:
      return termName;
  }
};
