import { ReactNode } from 'react';

import { ItemDueState, TimelineItemGroup } from './types';
import { getItemsGroupHref } from './utils';

type Props = {
  data: TimelineItemGroup;
  hasLinkAccess: boolean;
  isMiniChart: boolean;
  projectID: UUID;
  today: Date;
};

export default function ItemsBarTooltipContent(props: Props) {
  const data = props.data;
  const pastDue = data.counter[ItemDueState.PastDue];
  const upcoming = data.counter[ItemDueState.Upcoming];
  const decided = data.counter[ItemDueState.Decided];
  return (
    <div className="flex flex-col gap-2">
      <div className="-mt-1">
        <b className="type-body2">Items</b>
        <div className="type-body2">
          {new Date(props.data.start).toLocaleDateString()}
          {props.data.start !== props.data.end
            ? ` ‒ ${new Date(props.data.end).toLocaleDateString()}`
            : ''}
        </div>
      </div>
      <div className="flex max-w-max flex-col gap-2 text-left type-table-text">
        {pastDue !== 0 && (
          <ContentRow
            data={data}
            icon={<div className="h-2 w-2 self-center bg-entities-item-pastdue" />}
            isLink={props.hasLinkAccess && !props.isMiniChart}
            label="Past due"
            projectID={props.projectID}
            state={ItemDueState.PastDue}
            today={props.today}
            value={pastDue}
          />
        )}
        {upcoming !== 0 && (
          <ContentRow
            data={data}
            icon={<div className="h-2 w-2 self-center bg-entities-item-upcoming" />}
            isLink={props.hasLinkAccess && !props.isMiniChart}
            label="Upcoming"
            projectID={props.projectID}
            state={ItemDueState.Upcoming}
            today={props.today}
            value={upcoming}
          />
        )}
        {decided !== 0 && (
          <ContentRow
            data={data}
            icon={<div className="h-2 w-2 self-center bg-entities-item-decided" />}
            isLink={false} // There is no filter for it
            label="Decided"
            projectID={props.projectID}
            state={ItemDueState.Upcoming}
            today={props.today}
            value={decided}
          />
        )}
      </div>
    </div>
  );
}

type GroupRowProps = {
  icon: ReactNode;
  label: string;
  value: string | number;
};

export function GroupRow(props: GroupRowProps) {
  return (
    <div className="flex items-center justify-between gap-1 type-table-text">
      <div className="flex gap-2">
        <div className="flex items-center text-left">{props.icon}</div>
        <div className="line-clamp-1 w-[100px]">{props.label}</div>
        <div className="w-12 text-right">{props.value}</div>
      </div>
    </div>
  );
}

type GroupLinkProps = {
  children: ReactNode;
  data: TimelineItemGroup;
  projectID: UUID;
  state: ItemDueState;
  today: Date;
};

export function GroupLink(props: GroupLinkProps) {
  return (
    <a
      className="cursor-pointer type-link type-body2"
      href={getItemsGroupHref(props.projectID, props.data, props.today, props.state)}
      id="timeline-tooltip"
      rel="noreferrer"
      target="_blank"
    >
      {props.children}
    </a>
  );
}

type ContentRowProps = {
  data: TimelineItemGroup;
  isLink: boolean;
  icon: ReactNode;
  label: string;
  projectID: UUID;
  state: ItemDueState;
  today: Date;
  value: string | number;
};

export function ContentRow(props: ContentRowProps) {
  if (props.isLink)
    return (
      <GroupLink {...props}>
        <GroupRow {...props} />
      </GroupLink>
    );
  return <GroupRow {...props} />;
}
