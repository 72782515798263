import { FC } from 'react';

import { Checkbox, IconButton } from '@material-ui/core';

import {
  ItemEvent,
  itemEvent,
  itemSidebarEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ScheduleImpactType } from '../../../../api/gqlEnumsBe';
import { SCHEDULE_IMPACT_TYPE_NAMES } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getScheduleImpactUnits } from '../../../ProjectProperties/ProjectScheduleImpact/utils';
import JoinSelect, { getEntry } from '../../../Select/JoinSelect/JoinSelect';

import ScheduleImpactInput from './ScheduleImpactInput';
import ScheduleImpactWrapperStyles from './ScheduleImpactWrapperStyles';

type ScheduleImpactWrapperProps = {
  classes: Classes<typeof ScheduleImpactWrapperStyles>;
  isSidebar?: boolean;
  hasScheduleImpactEditPermission: boolean;
  hasScheduleImpactViewPermission: boolean;
  localImpact: ScheduleImpact;
  scheduleImpact: ScheduleImpact;
  setLocalImpact: React.Dispatch<React.SetStateAction<ScheduleImpact>>;
  setScheduleImpact: (value: ScheduleImpact, onSuccess?: () => void) => void;
  onItemMutated?: () => void;
};

const ScheduleImpactWrapper: FC<ScheduleImpactWrapperProps> = ({
  classes,
  isSidebar = false,
  hasScheduleImpactEditPermission,
  hasScheduleImpactViewPermission,
  localImpact: scheduleImpact,
  scheduleImpact: scheduleImpactOuter,
  setLocalImpact: setScheduleImpact,
  setScheduleImpact: setScheduleImpactOuter,
  onItemMutated,
}) => {
  const sendAnalytics = useSendAnalytics();
  const analyticsEvent = isSidebar ? itemSidebarEvent : itemEvent;
  if (!hasScheduleImpactViewPermission) return null;

  const isTBD = scheduleImpact.type === ScheduleImpactType.TBD;
  const isNA = scheduleImpact.type === ScheduleImpactType.NA;

  const showConfigs = !isNA && !isTBD;

  const entries = Object.entries(SCHEDULE_IMPACT_TYPE_NAMES).map(([type, name]) =>
    getEntry(type, name)
  );

  const scheduleImpactUnits = getScheduleImpactUnits();

  return (
    <div className={`${classes.container} ${isSidebar ? classes.containerSidebar : ''}`}>
      <div className={`${classes.select} ${isSidebar ? classes.selectSidebar : ''}`}>
        <JoinSelect
          cySelect="ScheduleImpact-Select"
          disabled={!hasScheduleImpactEditPermission}
          entries={entries}
          hidePrint={false}
          onChange={(entryID: string) => {
            const type: ScheduleImpactType = Number(entryID);
            if (type !== scheduleImpactOuter.type) {
              let impact = { ...scheduleImpactOuter, type };
              if (type === ScheduleImpactType.DAYS) {
                impact = { ...impact, criticalPath: true };
              }
              setScheduleImpact((prev) => ({ ...prev, type, criticalPath: impact.criticalPath }));
              setScheduleImpactOuter(impact, onItemMutated);
              sendAnalytics(
                analyticsEvent(ItemEvent.SCHEDULE_IMPACT_EDIT_TYPE, {
                  value: SCHEDULE_IMPACT_TYPE_NAMES[type],
                })
              );
            }
          }}
          value={scheduleImpact.type.toString()}
        />
      </div>
      {showConfigs && (
        <div
          className={`${classes.inline} ${classes.marginLeft} ${
            isSidebar ? classes.marginTop : ''
          }`}
        >
          <IconButton
            className={classes.checkbox}
            onClick={() => {
              const criticalPath = !scheduleImpact.criticalPath;
              setScheduleImpact((prev) => ({ ...prev, criticalPath }));
              if (criticalPath !== scheduleImpactOuter.criticalPath) {
                setScheduleImpactOuter({
                  ...scheduleImpact,
                  criticalPath,
                });
              }
              sendAnalytics(
                analyticsEvent(ItemEvent.SCHEDULE_IMPACT_EDIT_CRITICAL_PATH, {
                  value: String(criticalPath),
                })
              );
            }}
          >
            <Checkbox
              checked={scheduleImpact.criticalPath}
              data-cy="ScheduleImpact-CriticalPath-Checkbox"
              disabled={!hasScheduleImpactEditPermission}
            />
          </IconButton>
          <p className={classes.description}>Critical Path</p>
        </div>
      )}
      {showConfigs && (
        <div
          className={`${classes.inline} ${classes.alignRight} ${
            isSidebar ? classes.marginTop : ''
          }`}
        >
          <ScheduleImpactInput
            disabled={!hasScheduleImpactEditPermission}
            onChange={(value) => {
              const days = Math.round(Number(value));
              setScheduleImpact((prev) => ({ ...prev, days }));
              if (days !== scheduleImpactOuter.days) {
                setScheduleImpactOuter({ ...scheduleImpactOuter, days });
              }
              sendAnalytics(
                analyticsEvent(ItemEvent.SCHEDULE_IMPACT_EDIT_DAYS, {
                  value: String(days),
                })
              );
            }}
            value={scheduleImpact.days.toFixed(0)}
          />
          <p className={`${classes.description} ${classes.marginLeft}`}>{scheduleImpactUnits}</p>
        </div>
      )}
    </div>
  );
};

export default withStyles(ScheduleImpactWrapperStyles)(ScheduleImpactWrapper);
