import { ProgramSeparatedCostTimeSeries } from '../../../../generated/graphql';
import { renderCostString } from '../../../CostReport/CostReportUtils';

type Props = {
  projectName: string;
  activeMilestoneName: string;
  costData: ProgramSeparatedCostTimeSeries;
};

export default function SeparatedHoverContent(props: Props) {
  return (
    <div className="flex w-72 flex-col gap-2">
      <div className="flex !font-bold type-body2">
        {props.projectName} - {props.activeMilestoneName}
      </div>
      <div className="flex justify-between gap-1 type-body2">
        <div>Amount</div>
        <div>{renderCostString({ cost: props.costData.runningTotal, short: true })}</div>
      </div>
      <div className="flex justify-between gap-1 text-chart-axis-label type-body2">
        <div>Gap</div>
        <div>{renderCostString({ cost: props.costData.gap, short: true })}</div>
      </div>
    </div>
  );
}
