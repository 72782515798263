import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
} from '../../../analytics/analyticsEventProperties';
import { ProgramCreationInput, ProgramFieldsFragment } from '../../../generated/graphql';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useCompanyRoutesData } from '../../CompanyTab/CompanyTabUtils';
import { DialogFlow } from '../../scales';

import { useCreateProgramMutation } from './hooks/useCreateProgramMutation';
import { useEditProgramMutation } from './hooks/useEditProgramMutation';
import { useProgramEligibleProjectsQuery } from './hooks/useProgramEligibleProjectsQuery';
import getProgramDetailsStep from './steps/ProgramDetailsStep/getProgramDetailsStep';
import getProjectSelectionStep from './steps/ProjectSelectionStep/getProjectSelectionStep';
import { defaultProgramInput, updateProgramInput } from './utils';

type Props = {
  onCompleted: (programID: UUID) => void;
  onClose: () => void;
  isCreate: boolean;
  program?: ProgramFieldsFragment;
};

export default function ProgramDialog(props: Props) {
  const sendAnalytics = useSendAnalytics();
  useEffectOnce(() => {
    if (props.isCreate) {
      sendAnalytics(ProgramReportingAnalyticsEvent(ProgramReportingTypes.CREATE_PROGRAM_VIEW));
    } else {
      sendAnalytics(
        ProgramReportingAnalyticsEvent(ProgramReportingTypes.EDIT_PROGRAM_VIEW, {
          programName: props.program?.name,
        })
      );
    }
  });

  // State
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState(!!props.program);
  const [input, setInput] = useState<ProgramCreationInput>(
    props.program
      ? {
          projectIDs: props.program.projects.map((p) => p.id),
          name: props.program.name,
          typeID: props.program.typeID,
          imageProjectID: props.program.imageProjectID,
          locationDetails: props.program.locationDetails
            ? { ...props.program.locationDetails }
            : { ...defaultProgramInput.locationDetails },
        }
      : {
          ...defaultProgramInput,
        }
  );

  // Hooks
  const createProgram = useCreateProgramMutation({
    onCompleted: (result) => {
      props.onCompleted(result.createProgram);
      sendAnalytics(
        ProgramReportingAnalyticsEvent(ProgramReportingTypes.CREATE_PROGRAM, {
          numberOfProjects: input.projectIDs.length,
        })
      );
    },
  });
  const editProgram = useEditProgramMutation({
    onCompleted: (result) => {
      props.onClose();
      sendAnalytics(
        ProgramReportingAnalyticsEvent(ProgramReportingTypes.EDIT_PROGRAM, {
          numberOfProjects: result.editProgram.projects.length,
          programName: result.editProgram.name,
          projectNames: result.editProgram.projects.map((p) => p.name),
        })
      );
    },
  });

  const { companyID } = useCompanyRoutesData();
  const projectTypes =
    useCompanyProjectTypesQuery(false, companyID).data?.companyProjectTypes ?? [];

  const projects = useProgramEligibleProjectsQuery().data?.programEligibleProjects ?? [];
  const selectedProjects = projects.filter((p) => input.projectIDs.includes(p.id));

  const projectSelectionStep = getProjectSelectionStep({
    onChange: (newProjectIDs) =>
      updateProgramInput(
        projects,
        newProjectIDs,
        input,
        isEditingDetails,
        selectedProjects,
        setInput
      ),
    onSubmit: () => setIsEditingDetails(true),
    selectedProjects,
    isCreate: props.isCreate,
  });

  const canSubmit =
    !isSubmitting &&
    (input.projectIDs?.length ?? 0) >= 2 &&
    !!input.name?.trim() &&
    !!input.typeID &&
    !!input.imageProjectID &&
    !!input.locationDetails?.name &&
    input.projectIDs.includes(input.imageProjectID);

  const programDetailsStep = getProgramDetailsStep({
    canSubmit,
    input,
    isSubmitting,
    isCreate: props.isCreate,
    selectedProjects,
    projectTypes,
    setInput,
  });

  const steps = [projectSelectionStep, programDetailsStep];
  const title = props.isCreate ? 'Create Program' : 'Edit Program';

  return (
    <DialogFlow
      isFullHeight
      isOpen
      onClose={props.onClose}
      onComplete={() => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        if (props.isCreate) {
          createProgram(input);
        } else {
          editProgram(props.program?.id || '', input);
        }
      }}
      size="lg"
      steps={steps}
      title={title}
    />
  );
}
