import { FC } from 'react';

import { UPLOAD_ATTACH_MILESTONE_ASSET } from '../../../../actions/actionTypes';
import { getMilestoneAssetAnalytics } from '../../../../analytics/analyticsEventProperties';
import { UserSource } from '../../../../generated/graphql';
import { useMilestoneUploadAttachAssets } from '../../../assets/hooks/AssetsMutationHook';
import useOptimisticProjectUploadAssets from '../../../assets/hooks/useOptimisticProjectUploadAssets';

import FilesDialogSidebar from './FilesDialogSidebar';

type FilesDialogSidebarMilestoneWrapperProps = {
  milestoneID: UUID;
  projectID: UUID;
  userSources?: UserSource[];
};

const FilesDialogSidebarMilestoneWrapper: FC<FilesDialogSidebarMilestoneWrapperProps> = (props) => {
  const analyticsEvent = getMilestoneAssetAnalytics(
    UPLOAD_ATTACH_MILESTONE_ASSET,
    props.milestoneID
  );
  const { onDropFile, uploadingAssets } = useMilestoneUploadAttachAssets(
    props.milestoneID,
    analyticsEvent
  );

  useOptimisticProjectUploadAssets(props.projectID, uploadingAssets);

  return <FilesDialogSidebar onDropFile={onDropFile} userSources={props.userSources} />;
};

export default FilesDialogSidebarMilestoneWrapper;
