import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Edit, MoreVert, Print } from '@material-ui/icons';

import { AccessLevel, ProgramFieldsFragment, ResourceType } from '../../generated/graphql';
import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { useCurrentUser } from '../contexts/current-user';
import SharedBadge from '../dragon-scales/Share/SharedResourceIcon/SharedUserState';
import { Export } from '../Icons/Export';
import { useGetSharedResource } from '../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { Button, Icon, IconMenuButton, MenuEntry } from '../scales';

import ProgramDeleteDialog from './ProgramDeleteDialog';
import ProgramDialog from './ProgramDialog/ProgramDialog';
import ProgramUsersDialogData from './ProgramUsersDialog/ProgramUsersDialogData';

type Props = {
  isPrinting: boolean;
  program?: ProgramFieldsFragment;
};

export default function ProgramDashboardMenu({ isPrinting, program }: Props) {
  const navigate = useNavigate();
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isProgramOwner, setIsProgramOwner] = useState<boolean | undefined>(undefined);

  const user = useCurrentUser();

  // Hooks
  const { data: sharedResourceData } = useGetSharedResource(program?.id, ResourceType.PROGRAM, {
    onCompleted: () => {
      const userIsOwner = sharedResourceData?.getSharedResource?.resource?.users.find((u, i) => {
        return (
          u.id === user.id &&
          sharedResourceData?.getSharedResource?.resource?.accessLevels[i] === AccessLevel.OWNER
        );
      });
      setIsProgramOwner(!!userIsOwner);
    },
  });

  const menuEntries: MenuEntry[] = [];
  menuEntries.push(
    {
      disabled: false,
      id: 'edit-program',
      label: 'Edit Program',
      onClick: () => {
        setIsOpenEdit(true);
      },
      startAdornment: <Edit />,
      type: 'default',
    },
    {
      disabled: false,
      id: 'delete-program',
      label: 'Delete Program',
      onClick: () => {
        setIsOpenDelete(true);
      },
      startAdornment: <Icon name="delete" />,
      type: 'destructive',
    }
  );

  const exportEntries = [
    {
      id: 'print-dashboard',
      label: 'Print Dashboard',
      startAdornment: <Print />,
      onClick: () =>
        window.open(
          generateSharedPath(RouteKeys.PROGRAM_DASHBOARD_PRINT, { programID: program?.id }),
          '_blank'
        ),
    },
  ];

  return (
    <>
      <div className="flex h-20 items-center gap-2 bg-background-primary p-6">
        {program && (
          <ProgramUsersDialogData
            isOpen={isOpenShare}
            program={program}
            setIsOpen={setIsOpenShare}
          />
        )}
        {program && isOpenEdit && (
          <ProgramDialog
            isCreate={false}
            onClose={() => setIsOpenEdit(false)}
            onCompleted={(programID) => {
              navigate(generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, { programID }));
            }}
            program={program}
          />
        )}
        <div className="flex-grow type-heading1">{program?.name}</div>
        {!isPrinting && (
          <>
            <SharedBadge sharedUsers={sharedResourceData?.getSharedResource?.resource?.users} />
            {isProgramOwner && (
              <Button
                label="Share"
                onClick={() => setIsOpenShare(true)}
                startIcon={<Icon name="shareGroupAdd" />}
                type="primary"
              />
            )}
            <IconMenuButton
              aria-label="Open Print View"
              entries={exportEntries}
              icon={<Export />}
              type="secondary"
            />
            {isProgramOwner && (
              <IconMenuButton
                aria-label="export-menu"
                entries={menuEntries}
                icon={<MoreVert />}
                type="secondary"
              />
            )}
          </>
        )}
      </div>
      {program && (
        <ProgramDeleteDialog isOpen={isOpenDelete} program={program} setIsOpen={setIsOpenDelete} />
      )}
    </>
  );
}
