import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { OpenInNew } from '@material-ui/icons';

import { ShareDialog, UsersTableFilter } from '../../dragon-scales';
import { Button, DialogContent, Icon } from '../../scales';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';

import DistributionFrequency from './DistributionFrequency';
import {
  DistributionFrequencyInputs,
  SEND_NOW_CRON_EXPRESSION,
  getDistributionFrequencySummaryText,
  getDistributionSummaryText,
} from './DistributionUtils';

type Props = {
  projectID: UUID;
  isOpen: boolean;
  onClose: () => void;
  distributionInput: DistributionFrequencyInputs;
  cronExpression: string;
  handleShareClose: () => void;
  isEditDialog: boolean;
  shareLabel: string;
  shareLabelIcon: ReactNode;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  shareDisabled: boolean;
  onDelete: () => void;
  onEdit: (input: Partial<DistributionFrequencyInputs>) => void;
  reportName: string;
};

export default function DistributionDialog(props: Props) {
  const { summaryText, selectedText } = getDistributionSummaryText(
    props.distributionInput,
    props.cronExpression
  );
  return (
    <ShareDialog
      footerLeft={
        props.isEditDialog ? (
          <Button
            label="Delete"
            onClick={props.onDelete}
            startIcon={<Icon name="delete" />}
            type="destructive"
          />
        ) : null
      }
      footerRight={
        <Button
          data-cy="share-button"
          isDisabled={props.shareDisabled}
          label={props.shareLabel}
          onClick={() => {
            props.handleShareClose();
          }}
          startIcon={props.shareLabelIcon}
          type="primary"
        />
      }
      isOpen={props.isOpen}
      isShareDisabled={props.shareDisabled}
      onClose={props.onClose}
      onShare={() => {
        props.handleShareClose();
      }}
      selectedText={selectedText}
      shareLabel={props.shareLabel}
      summaryText={summaryText}
      title={
        props.isEditDialog
          ? `Edit Scheduled Distribution for ${props.reportName}`
          : `Send / Schedule Distribution for ${props.reportName}`
      }
    >
      <DialogContent className="flex h-full flex-col gap-2 pb-0 pl-0 pr-0">
        <div className="flex h-full flex-col p-0">
          <div className="h-full overflow-auto p-6 pt-0">
            <div className="mb-6 flex">
              <div className="my-auto type-body1">
                Select who you want to email this report as a .PDF to, and how often
              </div>
              <ShadedHelpTip
                shiftLeft
                tip={
                  <div className="type-label">
                    What will the recipients see?
                    <Link
                      className="ml-2 type-small-link"
                      rel="noreferrer"
                      target="_blank"
                      to="https://success.join.build/en/knowledge/overview-how-join-calculates-project-costs"
                    >
                      Display Cost
                      <div className="inline icon-sm">
                        <OpenInNew fontSize="inherit" />
                      </div>
                    </Link>
                  </div>
                }
              />
            </div>
            <UsersTableFilter
              collaboratorID=""
              defaultSelectedCollaborators={props.distributionInput.selectedCollaboratorIDs}
              onCollaboratorNewIDs={(collaboratorIDs) =>
                props.onEdit({
                  ...props.distributionInput,
                  selectedCollaboratorIDs: collaboratorIDs,
                })
              }
              onCollaboratorRemovedIDs={() => {}}
              onCollaboratorSharedIDs={() => {}}
              projectID={props.projectID}
              sharedResources={[]}
            />
          </div>
          <div className="mt-auto flex w-full flex-col justify-end bg-background-1">
            <DistributionFrequency
              distribution={props.distributionInput}
              isEditDialog={props.isEditDialog}
              onEdit={props.onEdit}
            />
            <div className="text-body1 pb-4 pl-4">
              {getDistributionFrequencySummaryText(
                props.distributionInput.selectedCollaboratorIDs.length ?? 0,
                props.distributionInput,
                props.cronExpression === SEND_NOW_CRON_EXPRESSION
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </ShareDialog>
  );
}
