import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { ALL_MILESTONES } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { parseDate } from '../../../../utilities/dates';
import { pluralizeCountString } from '../../../../utilities/string';
import { AddCategoriesToFilter } from '../../../FilterPanel/filterUtils';
import MilestoneMeetingChip from '../../../Milestone/MilestoneMeetingChip/MilestoneMeetingChip';
import ItemsCategories from '../../ItemsCategories/ItemsCategories';
import ItemsOptionChip from '../../ItemsOptionChipSquareListArea/ItemsOptionChip';
import ItemsOptionChipSquareListArea from '../../ItemsOptionChipSquareListArea/ItemsOptionChipSquareListArea';
import { getTotalItemAttachmentCount } from '../../ItemsUtils';
import ItemsListItemLinkData from '../ItemsListItemLinkData';

import FilteredMilestoneInfo from './FilteredMilestoneInfo';
import ItemsListItemAssetsChip from './ItemsListItemAssetsChip';
import ItemsListItemInfoStyles from './ItemsListItemInfoStyles';

type ItemsListItemInfoProps = {
  addCategoriesToFilter?: AddCategoriesToFilter;
  classes: Classes<typeof ItemsListItemInfoStyles>;
  canViewAttachments: boolean;
  filteredMilestoneId?: UUID;
  item: ItemsListItem;
  link: string;
  options: ItemsTreeOption[] | undefined;
  sharedUsers: Pick<User, 'id' | 'name'>[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCategories?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showParent: boolean;
  hasDoubleClick: boolean;
};

const ItemsListItemInfo: FC<ItemsListItemInfoProps> = ({
  addCategoriesToFilter,
  classes,
  canViewAttachments = false,
  filteredMilestoneId = '',
  item,
  link,
  sharedUsers,
  showCategories = true,
  showParent,
  hasDoubleClick,
}) => {
  // VARIABLES
  const {
    activityIDs,
    categories,
    createdAt,
    creator,
    currentMilestone,
    dueDate,
    milestone,
    name,
    options,
    parent,
    updateTime,
  } = item;

  // AREAS
  const attachmentCount = getTotalItemAttachmentCount(item);
  const assetsIcon = attachmentCount !== 0 && canViewAttachments && (
    <div className={classes.noPrint} data-cy={`item-${name}-attachment`}>
      <ItemsListItemAssetsChip attachmentCount={attachmentCount} />
    </div>
  );

  const createdAtTime = createdAt ? `(${parseDate(createdAt)})` : '';
  const createdBy = creator && (
    <div className={`${classes.inlineRow}`}>
      <Typography variant="caption">Creator: </Typography>
      <Typography className={classes.captionText} data-cy="itemsListInfo-creator" variant="body2">
        {`${creator.name} ${createdAtTime}`}
      </Typography>
    </div>
  );

  const itemDueDate = dueDate && (
    <div className={`${classes.inlineRow}`}>
      <Typography variant="caption">Due: </Typography>
      <Typography className={classes.captionText} data-cy="itemsListInfo-Due" variant="body2">
        {parseDate(dueDate)}
      </Typography>
    </div>
  );

  const dateModified = updateTime && (
    <div className={`${classes.inlineRow}`}>
      <Typography variant="caption">Last updated: </Typography>
      <Typography className={classes.captionText} variant="body2">
        {parseDate(updateTime)}
      </Typography>
    </div>
  );

  const isNotAllMilestonesFilter = filteredMilestoneId !== ALL_MILESTONES;
  const filteredMilestoneInfo = filteredMilestoneId &&
    isNotAllMilestonesFilter &&
    currentMilestone.id !== milestone.id && <FilteredMilestoneInfo item={item} />;

  const eventsInfo = activityIDs && (
    <div className={`${classes.inlineRow}`}>
      <Typography variant="caption">{pluralizeCountString('Event', activityIDs.length)}</Typography>
    </div>
  );

  const milestoneInfo = !isNotAllMilestonesFilter && (
    <div className={classes.inlineRow} data-cy="itemInfoCurrentMilestone">
      <Typography variant="caption">Milestone:</Typography>
      {milestone && <MilestoneMeetingChip {...milestone} hideDate inline />}
    </div>
  );

  const isDisplayOptions = (options || []).length;
  const itemOptions = isDisplayOptions ? (
    <ItemsOptionChipSquareListArea options={options} parentStatus={item.status} />
  ) : null;

  const itemParent = !isDisplayOptions
    ? showParent && parent && <ItemsOptionChip item={parent} />
    : null;

  return (
    <div className="flex flex-col justify-center">
      <ItemsListItemLinkData
        hasDoubleClick={hasDoubleClick}
        item={item}
        link={link}
        sharedUsers={sharedUsers}
      />
      <ItemsCategories
        addCategoriesToFilter={addCategoriesToFilter}
        // @ts-ignore TODO CT-545
        categories={categories}
        showCategories={showCategories}
      />
      <div className={classes.itemChips}>
        {createdBy}
        {itemDueDate}
        {dateModified}
        <div className={classes.inlineRow}>
          {milestoneInfo}
          {filteredMilestoneInfo}
        </div>
        {eventsInfo}
        {assetsIcon}
        {itemOptions}
        {itemParent}
      </div>
    </div>
  );
};

export default withStyles(ItemsListItemInfoStyles)(ItemsListItemInfo);
