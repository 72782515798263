import { MutationHookOptions, useMutation } from '@apollo/client';

import { REFETCH_CHANGE_MILESTONE_EVENTS_LIST } from '../../../api/refetchSets';
import {
  CreateItemCommentMutation,
  CreateItemCommentMutationVariables,
  MentionableTextInput,
} from '../../../generated/graphql';
import { useCostMode } from '../../../utilities/costMode';
import { useProjectID } from '../../../utilities/routes/params';
import { useCurrentUser } from '../../contexts/current-user';
import { itemQuery } from '../../Items/hooks/itemQuery';

import { createItemCommentMutation } from './queries';

export const useCreateComment = (
  item: Pick<ItemDataQueryItem, 'id' | 'comments'>,
  options: MutationHookOptions<CreateItemCommentMutation, CreateItemCommentMutationVariables> = {}
) => {
  const { id } = item;
  const costMode = useCostMode();
  const user = useCurrentUser();
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');
  const [createItemCommentFunc] = useMutation<
    CreateItemCommentMutation,
    CreateItemCommentMutationVariables
  >(createItemCommentMutation, options);
  const submitFunc = (text: MentionableTextInput) => {
    const mentions = text.mentions?.map(({ position, text, userID }, id) => ({
      __typename: 'Mention',
      id,
      position,
      text,
      userID,
      user: { id: userID },
    }));
    const itemReferences = text.itemReferences?.map(({ position, text, referencedItemID }, id) => ({
      __typename: 'ItemReference',
      id,
      position,
      text,
      referencedItemID,
      referencedItem: { id: referencedItemID },
    }));
    return createItemCommentFunc({
      variables: {
        projectID: projectId,
        comment: {
          itemID: item.id,
          text,
        },
        costMode,
      },
      optimisticResponse: {
        commentOnItem: [
          ...item.comments,
          {
            __typename: 'Comment',
            id: new Date().getTime().toString() as UUID,
            author: user,
            created: new Date().toISOString(),
            text: {
              __typename: 'MentionableText',
              ...text,
              mentions,
              itemReferences,
            },
          } as unknown as CreateItemCommentMutation['commentOnItem'][number],
        ],
      },
      update: (cache, { data }) => {
        const comments = data?.commentOnItem || item.comments;
        cache.writeQuery({
          query: itemQuery,
          data: { item: { ...item, comments } },
          variables: {
            id,
            costMode,
          },
        });
      },
      refetchQueries: REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
    });
  };
  return [submitFunc];
};
