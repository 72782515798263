import { FC, ReactNode } from 'react';

import { List } from '@material-ui/core';

import { RouteKeys } from '../../../routes/paths';
import { getItemCost } from '../../../utilities/items';
import { PermissionFunc } from '../../../utilities/permissions/types';
import { generateSharedPath } from '../../../utilities/routes/links';
import ItemsListOption from '../ItemsListItem/ItemsListOption/ItemsListOption';

type ItemsOptionsListProps = {
  canAdd: PermissionFunc;
  canEdit: PermissionFunc;
  canView: PermissionFunc;
  filteredMilestoneID?: UUID;
  getCtaButton?: (o: Option) => ReactNode;
  getOption?: (
    o: Option | ItemsTreeOption | DetailedOption
  ) => Option | ItemsListItem | DetailedOption | undefined;
  isNested?: boolean;
  isNewItemsList?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  inPreviewMode: boolean;
  item: ItemsListItem | ItemDataQueryItem;
  itemsLikeSelected: UUID[];
  nodeKey?: string;
  options: DetailedOption[] | ItemsTreeOption[];
  projectId: UUID;
  selectItemLike?: (id: UUID, isSelected: boolean, isItem: boolean, milestoneID: UUID) => void;
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCategories?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCheckbox?: boolean;
  view: string;
};

const ItemsOptionsList: FC<ItemsOptionsListProps> = ({
  canAdd,
  canEdit,
  canView,
  filteredMilestoneID,
  getCtaButton = () => null,
  getOption = (o) => o,
  inPreviewMode,
  isNested = false,
  isNewItemsList = true,
  item: parentItem,
  itemsLikeSelected,
  nodeKey,
  options, // this the list of display options, will be treated like an Item(like)
  projectId,
  selectItemLike,
  sharedUsersMap,
  showCategories = true,
  showCheckbox = true,
  view,
}) => (
  // FUNCTIONS

  <List key={`${nodeKey} list`} data-cy="itemsCollapse-optionsList">
    {options &&
      options.map((o) => {
        // If these are provided, they are passed to the item
        const option = getOption(o);

        if (!option) return null;
        const optionID = 'id' in option ? option.id : '';
        const isSelected = itemsLikeSelected.some((i) => i === optionID);
        const costImpact = getItemCost(option as Option);

        const itemsTreeOptionCost = (o as ItemsTreeOption).cost;
        const itemsListOptionCost = (option as ItemsListItem).cost;
        const costsMatch =
          JSON.stringify(itemsTreeOptionCost) === JSON.stringify(itemsListOptionCost);

        const mainCostImpact = isNewItemsList
          ? itemsTreeOptionCost || itemsListOptionCost
          : undefined;
        const totalCostImpact =
          isNewItemsList && itemsTreeOptionCost && !costsMatch ? itemsListOptionCost : undefined;

        if (!option) return null;

        return (
          <ItemsListOption
            key={`${nodeKey} ${optionID}`}
            canAdd={canAdd}
            canEdit={canEdit}
            canView={canView}
            costImpact={costImpact as CostRange | CostScalar}
            ctaButton={getCtaButton(option as Option)}
            filteredMilestoneID={filteredMilestoneID}
            inPreviewMode={inPreviewMode}
            isNested={isNested}
            isSelected={isSelected}
            item={parentItem}
            link={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
              projectId,
              itemId: optionID,
            })}
            mainCostImpact={mainCostImpact}
            option={option as ItemsListItem}
            selectItemLike={selectItemLike}
            sharedUsers={sharedUsersMap.get(optionID) ?? []}
            showCategories={showCategories}
            showCheckbox={showCheckbox}
            totalCostImpact={totalCostImpact}
            view={view}
          />
        );
      })}
  </List>
);
export default ItemsOptionsList;
