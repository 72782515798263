import { FC, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import { AssistantPhoto, MoreVert } from '@material-ui/icons';

import { EstimateType, TermKey } from '../../api/gqlEnums';
import { CURRENT_MILESTONE } from '../../constants';
import { CostReportColumnType, PermissionResource } from '../../generated/graphql';
import { milestoneColumns } from '../../hooks/constants';
import { RouteKeys } from '../../routes/paths';
import { MILESTONES_PAGE_SMALL_ROWS, TEXT_FIELD_MILESTONE_NAME } from '../../tagConstants';
import theme from '../../theme/design-system-mui-theme';
import { withStyles } from '../../theme/komodo-mui-theme';
import { formatCommas } from '../../utilities/currency';
import { getDateString } from '../../utilities/dates';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { EMPTY_COST } from '../../utilities/string';
import { isNonNullable } from '../../utilities/types';
import { useCurrentUser } from '../contexts/current-user';
import { getCostReportByType, renderCostString } from '../CostReport/CostReportUtils';
import { getItemListLink } from '../ItemsList/ItemsListUtils';
import {
  useDeleteMilestone,
  useMilestoneCostReportsQuery,
  useSetMilestoneStatus,
} from '../Milestone/hooks';
import { getIconColorLabel } from '../Milestone/MilestoneDetails/MilestoneDetailsNav/MilestoneDetailsNavUtils';
import { MilestoneDetailsTabs } from '../Milestone/utils';
import { getTextDot } from '../Navigation/NavigationTabs';
import NormalTooltip from '../NormalTooltip/NormalTooltip';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import { Chip, Icon, IconMenuButton, MenuEntry, Tooltip } from '../scales';

import DeleteMilestoneDataDialog from './Dialogs/DeleteMilestoneDataDialog';
import MilestoneReportLink from './MilestoneReportLink';
import { styles } from './MilestonesStyles';

type MilestoneProps = {
  activeMilestoneId: UUID;
  shouldDisplayCosts: boolean;
  classes: Classes<typeof styles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displayDesignPhaseColumn: boolean;
  milestone: Milestone;
  projectId: UUID;
  selectedUnits: Unit[];
};

const getTermText = (textCost: string, term: string, hasColor: boolean) =>
  textCost === '$0' && hasColor ? `No ${term}` : textCost;

const Milestone: FC<MilestoneProps> = ({
  activeMilestoneId,
  shouldDisplayCosts,
  classes,
  displayDesignPhaseColumn,
  milestone,
  projectId,
  selectedUnits,
}) => {
  const t = useContext(ProjectTermStore);
  const navigate = useNavigate();
  const deleteMilestone = useDeleteMilestone();
  const [setMilestoneStatus] = useSetMilestoneStatus();
  const [deleteMilestoneDialog, setDeleteMilestoneDialog] = useState(false);

  // Permissions
  const userID = useCurrentUser().id;
  const { canDelete, canEdit } = usePermissions();
  const canEditMilestones = canEdit(PermissionResource.MILESTONES);
  const didCreateMilestone = milestone.createdBy?.id === userID;
  const canDeleteDraftMilestones =
    canDelete(PermissionResource.DRAFT_MILESTONE) || didCreateMilestone;
  const isDeletable = milestone.isDraft ? canDeleteDraftMilestones : true;

  const { data: milestoneCostReportData, loading: isLoadingCostReport } =
    useMilestoneCostReportsQuery(milestone.id, projectId, {}, milestoneColumns);
  const milestoneCostReports = milestoneCostReportData?.milestoneCostReports ?? [];
  const costReport = milestoneCostReports[0];

  const { id, name, date, itemsCount } = milestone;
  const quantities: Quantity[] = milestone.quantities?.filter(isNonNullable) || [];
  const isActiveMilestone = activeMilestoneId === id;

  const targetReport = getCostReportByType(costReport, CostReportColumnType.TARGET_REPORT);
  const estimateReport = getCostReportByType(costReport, CostReportColumnType.ESTIMATE_REPORT);
  const targetTotal = targetReport?.range || { value: '0' };
  const estimateTotal = estimateReport?.range || { value: '0' };

  const estimateCost = renderCostString({ cost: estimateTotal, isWide: true });
  const targetCost = renderCostString({ cost: targetTotal, isWide: true });
  const estimateTerm = t.lowerCase(TermKey.ESTIMATE);
  const targetTerm = t.lowerCase(TermKey.TARGET);
  const estimateColor = getIconColorLabel(EstimateType.ACTIVE_ESTIMATE, milestone, estimateTerm);
  const budgetColor = getIconColorLabel(EstimateType.BUDGET, milestone, targetTerm);
  const estimateText = getTextDot(
    getTermText(estimateCost, estimateTerm, !!estimateColor.color),
    estimateColor
  );
  const budgetText = getTextDot(
    getTermText(targetCost, targetTerm, !!budgetColor.color),
    budgetColor
  );

  const designPhaseName = milestone.designPhase?.abbreviation
    ? `${milestone.designPhase?.name} (${milestone.designPhase?.abbreviation})`
    : milestone.designPhase?.name;

  const magnitudeStrings = selectedUnits.map((unit: Unit) => {
    const quantity = (quantities || []).find((q: Quantity) => q.unit.id === unit.id);
    return quantity && quantity.magnitude ? formatCommas(quantity.magnitude) : '-';
  });

  const getEstimateLink = (estimateType: EstimateType) => {
    const isEstimate = estimateType === EstimateType.ACTIVE_ESTIMATE;
    const text = isEstimate ? estimateText : budgetText;
    const cy = isEstimate ? 'milestone-estimate' : 'milestone-budget';
    const tab = isEstimate ? MilestoneDetailsTabs.ESTIMATE : MilestoneDetailsTabs.TARGET;
    const linkContent = isLoadingCostReport ? (
      <div
        className={classes.loadingCost}
        style={{
          maxWidth: isEstimate ? '80%' : undefined, // the estimate column is slightly wider than the budget column, we need this so the background of the two columns is the same width
        }}
      >
        <CircularProgress size={18} style={{ margin: 'auto' }} />
      </div>
    ) : (
      <span className={classes.numberCells}>{text}</span>
    );

    return (
      <Link
        className={`${classes.linkTableCell} ${classes.cell} ${classes.limitCell} ${classes.cellLink} ${classes.alignRight}`}
        data-cy={cy}
        tabIndex={-1}
        to={`${milestoneDetailsLink}?view=${tab}`}
      >
        {linkContent}
      </Link>
    );
  };

  // LINKS
  const milestoneDetailsLink = generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
    projectId,
    milestoneId: id,
  });
  const itemsListLink = getItemListLink(projectId, { [CURRENT_MILESTONE]: [milestone.id] });

  // Menu options
  const isMenuVisible = milestone.isDraft ? canDeleteDraftMilestones : canEditMilestones;

  const menuEntries: MenuEntry[] = [];
  if (!isActiveMilestone && !milestone.isDraft) {
    menuEntries.push({
      disabled: isActiveMilestone,
      id: 'set-active-milestone',
      label: 'Set as the Active Milestone',
      onClick: () => {
        setMilestoneStatus(projectId, milestone.id);
      },
      startAdornment: <AssistantPhoto nativeColor={theme.palette.ds.entities.milestone} />,
    });
  }

  menuEntries.push({
    id: 'delete-milestone',
    label: 'Delete Milestone',
    onClick: () => setDeleteMilestoneDialog(true),
    startAdornment: <Icon name="delete" />,
    type: 'destructive',
  });

  return (
    <div
      className={`${classes.tableRow} ${classes.row} ${MILESTONES_PAGE_SMALL_ROWS} ${classes.paddingLeft}`}
    >
      <Link
        className={`${classes.linkTableCell} ${classes.wrapContent} ${classes.cellLink} ${classes.button} ${TEXT_FIELD_MILESTONE_NAME} ${classes.paddingLeft}`}
        data-cy="tableRow-MilestoneName"
        tabIndex={-1}
        to={milestoneDetailsLink}
      >
        <div className={`${classes.containerMilestoneReport}`}>
          <Typography className={`${classes.link} !mr-1`} tabIndex={0}>
            {name}
          </Typography>
          {isActiveMilestone && (
            <Tooltip content="Active milestone">
              <div>
                <Chip
                  data-cy="active-milestone"
                  icon={<AssistantPhoto className="text-entities-milestone" />}
                  text="Active"
                />
              </div>
            </Tooltip>
          )}
          {milestone.isDraft && (
            <Tooltip content="Draft milestone">
              <div>
                <Chip data-cy="tableRow-DraftMilestoneBadge" text="Draft" />
              </div>
            </Tooltip>
          )}
        </div>
      </Link>

      {displayDesignPhaseColumn && (
        <div className={`${classes.linkTableCell} ${classes.cell} `}>
          <span className={classes.numberCells}>{designPhaseName || EMPTY_COST}</span>
        </div>
      )}
      <Link
        className={`${classes.linkTableCell} ${classes.cell} ${classes.smallCell} ${classes.cellLink}`}
        tabIndex={-1}
        to={milestoneDetailsLink}
      >
        <div data-cy="milestone-date-text">{date ? getDateString(new Date(date)) : ''}</div>
      </Link>
      {shouldDisplayCosts && (
        <div
          className={`${classes.tableCell} ${classes.cell} ${classes.limitCell} ${classes.alignRight}`}
        >
          <div className={`${classes.wrapContent}`}>
            <MilestoneReportLink milestoneId={id} />
          </div>
        </div>
      )}
      <div
        className={`${classes.tableCell} ${classes.cell} ${classes.limitCell} ${classes.alignRight}`}
      >
        {!milestone.isDraft && (
          <Link
            className={`${classes.linkTableCell} ${classes.wrapContent} ${classes.cellLink} ${classes.button}`}
            data-cy="Milestone-itemsCountMilestonesPage"
            tabIndex={-1}
            to={itemsListLink}
          >
            <NormalTooltip title="Go to Items List">
              <Typography className={classes.link} tabIndex={0}>
                Items ({itemsCount})
              </Typography>
            </NormalTooltip>
          </Link>
        )}
      </div>
      {shouldDisplayCosts && getEstimateLink(EstimateType.ACTIVE_ESTIMATE)}
      {shouldDisplayCosts && getEstimateLink(EstimateType.BUDGET)}
      {magnitudeStrings.map((magnitude: string) => (
        <Link
          key={magnitude}
          className={`${classes.linkTableCell} ${classes.cell} ${classes.limitCell} ${classes.cellLink} ${classes.alignRight}`}
          data-cy="Milestone-quantity-magnitude"
          tabIndex={-1}
          to={`${milestoneDetailsLink}?view=${MilestoneDetailsTabs.METRICS}`}
        >
          <span className={`${classes.numberCells}`}>{magnitude}</span>
        </Link>
      ))}
      <div
        className={`align-top ${classes.cell} ${classes.linkTableCell} ${classes.noPadding} ${classes.alignContent}`}
      >
        {isMenuVisible && (
          <IconMenuButton
            aria-label="export-menu"
            entries={menuEntries}
            icon={<MoreVert />}
            type="secondary"
          />
        )}
        <DeleteMilestoneDataDialog
          isActiveMilestone={isActiveMilestone}
          isOpen={deleteMilestoneDialog}
          milestone={milestone}
          onClose={() => setDeleteMilestoneDialog(false)}
          onDelete={() => {
            if (!isDeletable) return;
            deleteMilestone(projectId, id, true, () => {
              navigate(generateSharedPath(RouteKeys.PROJECT_MILESTONES, { projectId }));
            });
          }}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(Milestone);
