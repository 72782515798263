import { FC } from 'react';

import { Chip, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import DropDownArrow from '../../Select/DropDownArrow/DropDownArrow';

import MultiGroupStyles from './MultiGroupStyles';

type MultiGroupAnchorProps = {
  classes: Classes<typeof MultiGroupStyles>;
  displayChips: number;
  emptyMessage: string;
  onClick: () => void;
  selectedNames: string[];
};

const MultiGroupAnchor: FC<MultiGroupAnchorProps> = ({
  classes,
  displayChips,
  emptyMessage,
  onClick,
  selectedNames,
}) => (
  <div
    className={classes.groupButton}
    data-cy="multi-group-anchor"
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
  >
    {selectedNames.length === 0 && (
      <Typography className={classes.empty}>{emptyMessage}</Typography>
    )}
    {selectedNames.map((selectedName, index) =>
      index < displayChips ? (
        <Chip
          key={selectedName}
          classes={{
            label: classes.chipLabel,
          }}
          className={classes.chip}
          data-cy={`chip-${selectedName}`}
          label={selectedName}
          title={selectedName}
        />
      ) : null
    )}
    {selectedNames.length > displayChips && (
      <Typography className={classes.counter}>
        {`+${selectedNames.length - displayChips}`}
      </Typography>
    )}
    <DropDownArrow isRelative onClick={() => {}} />
  </div>
);

export default withStyles(MultiGroupStyles)(MultiGroupAnchor);
