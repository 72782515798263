import { createRef, useEffect, useLayoutEffect, useState } from 'react';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';

import { TimelineEvent } from '../../../analytics/analyticsEventProperties';
import { NULL_ID } from '../../../constants';
import InsightsItemsChart from '../../dragon-scales/TimelineCharts/InsightsItems/InsightsItemsChart';
import useMemoWrapper from '../../useMemoWrapper';

import { unload } from './timeline/timeline';
import {
  Timeline,
  TimelineData,
  TimelineExpandedMap,
  TimelineItemData,
  TimelineSettings,
  TimelineType,
} from './timeline/timeline.types';
import { isSettingsItems, isSettingsTimeline } from './timeline/utils';
import { computeItemsLegend, getFinalHeight, initTimeline } from './TimelineChartUtils';
import TimelineItemsExpandLegend from './TimelineItemsLegend/TimelineItemsExpandLegend';

const ITEMS_HEIGHT = 92;
const TOP_WITH_TIMELINE = 38;
const TOP_NO_TIMELINE = 74;

type TimelineChartProps = {
  activities?: TimelineActivity[];
  data: TimelineData[];
  items?: TimelineItemData[];
  endDate?: string;
  expandedMap: TimelineExpandedMap;
  isPrint?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  maxHeight?: number;
  onAnalytics?: (event: TimelineEvent) => void;
  onExpand: (id: UUID) => void;
  onZoom?: (domain: string[]) => void;
  settings: TimelineSettings;
  startDate?: string;
  today?: string;
  width?: number;
  zoomIn?: TimelineData;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  zoomLineCompressed?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  zoomLineDisabled?: boolean;
};

const TimelineChart = (props: TimelineChartProps) => {
  const {
    activities = [],
    data: dataOuter = [],
    items = [],
    endDate,
    expandedMap,
    isPrint,
    onAnalytics = () => {},
    onExpand: onExpandOuter,
    onZoom: onZoomOuter = () => {},
    settings,
    startDate,
    today,
    width: widthOuter,
    zoomIn,
    zoomLineCompressed = false,
    zoomLineDisabled = false,
  } = props;
  const timelineRef = createRef<HTMLDivElement>();

  const [data, setData] = useState<TimelineData[]>(dataOuter);

  const [timelineChart, setTimelineChart] = useState<Timeline<TimelineData>>();

  const [width, setWidth] = useState<number | undefined>(widthOuter);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (widthOuter) return;
      const newWidth = timelineRef.current?.offsetWidth;
      if (!newWidth) return;
      if (width === newWidth) return;
      setWidth(newWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef, timelineRef.current]);

  useDeepCompareEffect(() => {
    setData(dataOuter);
  }, [dataOuter]);

  const onExpand = ({ id }: TimelineData) => onExpandOuter(id);

  const onZoom = (domain: Date[]) => {
    const newMin = domain[0].toISOString();
    const newMax = domain[1].toISOString();
    onZoomOuter([newMin, newMax]);
  };

  const [range, setRange] = useState<[Date, Date]>([
    new Date(startDate ?? ''),
    new Date(endDate ?? ''),
  ]);
  const onZoomInstant = (domain: Date[]) => {
    setRange(domain as [Date, Date]);
  };

  useEffect(() => {
    if (!timelineRef || !timelineRef.current) return;
    if (!width) return;

    const chartProps = {
      width,
      height: getFinalHeight(props),
      data,
      items,
      today,
      expandedMap,
      zoomLineCompressed,
      zoomLineDisabled,
      onExpand,
      onZoom,
      onZoomInstant,
      onAnalytics,
      isPrint,
      settings,
    };
    setTimelineChart(initTimeline(timelineRef.current, chartProps, activities));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [timelineRef?.current, width, data]);

  useEffect(() => {
    if (!timelineRef.current) return;
    timelineChart
      ?.expandedMap(expandedMap)
      .height(getFinalHeight(props))
      .render(timelineRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [expandedMap]);

  useEffect(() => {
    if (zoomIn) timelineChart?.onZoomIn(zoomIn);
    setRange([new Date(zoomIn?.start ?? ''), new Date(zoomIn?.end ?? '')]);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [zoomIn]);

  useEffect(() => {
    if (startDate && endDate) {
      const d = { end: endDate, id: NULL_ID, name: '', start: startDate, type: TimelineType.PHASE };
      timelineChart?.onZoomInChart(d);
      setRange([new Date(startDate ?? ''), new Date(endDate ?? '')]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [startDate, endDate]);

  useDeepCompareEffect(() => {
    timelineChart?.settings(settings).height(getFinalHeight(props));
  }, [settings]);

  useEffectOnce(() => () => unload());

  const itemsLegend = useMemoWrapper(computeItemsLegend, items);
  const itemsLegendEl = !!items.length && (
    <TimelineItemsExpandLegend
      data={itemsLegend}
      isTimeline={isSettingsTimeline(settings)}
      timelineHeight={getFinalHeight(props).timelineHeight}
      total={items.length}
    />
  );

  const itemsChart = !!items.length && (
    <InsightsItemsChart
      hasLinkAccess
      height={ITEMS_HEIGHT}
      items={items}
      projectID={settings.projectID ?? ''}
      today={new Date(today ?? '')}
      totalRange={range}
    />
  );

  const isItems = isSettingsItems(settings);
  const isTimeline = isSettingsTimeline(settings);
  const timelineHeight = getFinalHeight(props).timelineHeight;
  const top = isTimeline ? timelineHeight + TOP_WITH_TIMELINE : TOP_NO_TIMELINE;

  return (
    <div className="relative block">
      <div ref={timelineRef} className="overflow-hidden" id="timeline-chart" />
      {itemsLegendEl}
      {isItems && (
        <div className="absolute w-full px-6" style={{ top }}>
          {itemsChart}
        </div>
      )}
    </div>
  );
};

export default TimelineChart;
