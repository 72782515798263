import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { filterEvent } from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { withStyles } from '../../theme/komodo-mui-theme';
import FilterIcon from '../Icons/FilterIcon';
import InputsSettingsPanel from '../Inputs/InputsSettingsPanel/InputsSettingsPanel';
import useMemoWrapper from '../useMemoWrapper';

import ClearFiltersPanelHeader from './ClearFilters/ClearFiltersPanelHeader';
import FilterPanelCategorizationItem from './FilterPanelCategorizationItem';
import styles from './FilterPanelStyles';
import {
  AdditionalFilterManager,
  CategorizationCategoryMap,
  FilterManager,
  filterAnalyticsCategoryList,
  formatFilterSummary,
  getCategorizationsNameMap,
  getHeaderFilterString,
  useProjectCategorizationsFilterCount,
} from './filterUtils';

interface FilterPanelComponentProps {
  additionalFilters?: AdditionalFilterManager;
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  filterManager: FilterManager;
  page: string;
  projectID: UUID;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showFilterSummary?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  show: boolean;
  setShow: (show: boolean) => void;
}

const FilterPanelComponent: FC<FilterPanelComponentProps> = ({
  additionalFilters,
  categorizations,
  classes,
  filterManager,
  page,
  projectID,
  showFilterSummary = true,
  show = false,
  setShow,
}) => {
  // HOOKS
  const sendAnalytics = useSendAnalytics();

  const categorizationsNameMap = useMemoWrapper(getCategorizationsNameMap, categorizations);

  const filterSummaryLines = useMemoWrapper(
    formatFilterSummary,
    filterManager.filters,
    categorizationsNameMap,
    50,
    1
  ).map(getHeaderFilterString);

  const filterSummaryTitle = filterSummaryLines.join('\n');

  // VARIABLES
  const { filters, addCategories, removeCategories } = filterManager;
  const enabledProjectCategorizationFilters = useProjectCategorizationsFilterCount(
    filters,
    projectID
  );
  const numFilters =
    enabledProjectCategorizationFilters + (additionalFilters ? additionalFilters.numFilters : 0);
  const hasFilters = numFilters > 0;
  const hasCategorizations = categorizations && categorizations.length > 0;
  const hasNoFilterOptions = !additionalFilters && !hasCategorizations;

  // HANDLERS
  const handleAnalytics = (cMap: CategorizationCategoryMap) => {
    const categoryList = filterAnalyticsCategoryList(cMap, categorizationsNameMap);
    if (categoryList.length > 0) sendAnalytics(filterEvent(page, categoryList));
  };

  if (hasNoFilterOptions) return null;

  return (
    <div className={classes.containerOuter}>
      {showFilterSummary && hasFilters ? (
        <div className={classes.filterSummary}>
          {filterSummaryLines.map((filterSummaryLine: string) => (
            <Typography key={filterSummaryLine} className={classes.filterSummaryLine}>
              {filterSummaryLine}
            </Typography>
          ))}
        </div>
      ) : null}
      <div className={classes.containerOuter} title={filterSummaryTitle}>
        <InputsSettingsPanel
          header={
            <ClearFiltersPanelHeader
              additionalFilters={additionalFilters}
              filterManager={filterManager}
              projectID={projectID}
              text={additionalFilters ? additionalFilters.clearFiltersCTA : undefined}
            />
          }
          icon={<FilterIcon isFilled={hasFilters} />}
          setShow={setShow}
          show={show}
        >
          {additionalFilters && additionalFilters.filters}
          {categorizations.map((categorization, i) => (
            <FilterPanelCategorizationItem
              key={`${categorization.id}-${i + 1}`}
              addCategories={(newCategories: Category[]) =>
                addCategories(categorization.id, newCategories, handleAnalytics)
              }
              categories={filters[categorization.id] || []}
              categorization={categorization}
              removeCategories={(oldCategories: Category[]) =>
                removeCategories(categorization.id, oldCategories)
              }
            />
          ))}
        </InputsSettingsPanel>
      </div>
    </div>
  );
};

export const FilterPanel = withStyles(styles)(FilterPanelComponent);
