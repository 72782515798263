import { useState } from 'react';

import { ForecastingProjectsSortKey, ProgramFieldsFragment } from '../../../../generated/graphql';
import { formatNumber } from '../../../../utilities/string';
import ProgramCell from '../../../dragon-scales/ProjectCell/ProgramCell';
import ProjectCell from '../../../dragon-scales/ProjectCell/ProjectCell';
import { SortManager, Table, TableCell } from '../../../scales';

import ActiveMilestoneCosts from './cells/ActiveMilestoneCosts';
import LastUpdatedCell from './cells/LastUpdatedCell';
import ProgramCosts from './cells/ProgramCosts';

type Props = {
  counts: { current: number; total: number };
  isLoading: boolean;
  programs: ProgramFieldsFragment[];
  searchText: string;
  sortManager: SortManager;
};

export default function SearchPrograms(props: Props) {
  const [expandedPrograms, setExpandedPrograms] = useState<UUID[]>([]);

  const expandProgram = (programID: UUID) => {
    if (expandedPrograms?.includes(programID))
      setExpandedPrograms([...(expandedPrograms ?? []).filter((p) => p !== programID)]);
    else setExpandedPrograms([...(expandedPrograms ?? []), programID]);
  };

  const entries: TableCell[][] = [];
  props.programs.forEach((program) => {
    entries.push(getEntry(program, expandedPrograms, expandProgram, props.searchText));
    // if this program is expanded then add it's child projects as entries
    if (expandedPrograms.includes(program.id))
      program.projects.forEach((project) =>
        entries.push(getEntry(program, expandedPrograms, expandProgram, props.searchText, project))
      );
  });

  return (
    <Table
      columnWidths={['minmax(300px, 6fr)', 'minmax(200px, 1fr)', 'minmax(225px, 1fr)']}
      entries={entries}
      headerContent={[
        {
          copy: `Programs (${formatNumber(props.counts.current)} of ${formatNumber(
            props.counts.total
          )})`,
          key: 'programs',
          headerSortKey: ForecastingProjectsSortKey.NAME,
        },
        {
          copy: 'Last updated',
          key: 'last-updated',
          headerSortKey: ForecastingProjectsSortKey.UPDATED_AT,
        },
        { copy: 'Program costs', key: 'active-milestone-costs' },
      ]}
      isLoading={props.isLoading}
      sortManager={props.sortManager}
    />
  );
}

const getEntry = (
  program: ProgramFieldsFragment,
  expandedPrograms: UUID[],
  onClick: (id: UUID) => void,
  searchText: string,
  project?: ProgramFieldsFragment['projects'][number]
) => {
  const key = program.id;

  return [
    {
      key,
      component: project ? (
        <ProjectCell programName={program.name} project={project} searchText={searchText} />
      ) : (
        <ProgramCell
          isCollapsed={!expandedPrograms?.includes(program.id)}
          onClick={() => onClick(program.id)}
          program={program}
          searchText={searchText}
        />
      ),
    },
    {
      key,
      component: (
        <LastUpdatedCell
          updatedAt={program.updatedAt ?? undefined}
          updatedBy={program.updatedBy ?? undefined}
        />
      ),
    },
    {
      key,
      centered: true,
      component: project ? (
        <ActiveMilestoneCosts project={project} />
      ) : (
        <ProgramCosts programID={program.id} projectIDs={program.projects.map((p) => p.id)} />
      ),
    },
  ];
};
