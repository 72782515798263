import {
  ProgramCategorizedTotalsQuery,
  ProgramCategorizedTotalsQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { programCategorizedTotalsQuery } from '../queries';

export const useProgramCategorizedTotalsQuery = (
  programID: UUID | undefined,
  includedProjectIDs: UUID[],
  categorizationName?: string,
  unitAbbreviationPlural?: UUID,
  options?: QueryHookOptions<ProgramCategorizedTotalsQuery, ProgramCategorizedTotalsQueryVariables>
) => {
  return useQuery<ProgramCategorizedTotalsQuery, ProgramCategorizedTotalsQueryVariables>(
    programCategorizedTotalsQuery,
    {
      variables: { programID, includedProjectIDs, categorizationName, unitAbbreviationPlural },
      skip: !programID || !includedProjectIDs.length,
      ...options,
    }
  );
};

export type CategoryTotals = ProgramCategorizedTotalsQuery['programCategorizedTotals'][number];
