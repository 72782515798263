import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useJoinAuth } from '../../components/JoinAuthProvider';

import apolloClient from './index';

// Store these header values outside of the React lifecycle so that they are accessible
// to the single ApolloLink we'll create at the start, yet still updated upon route changes.
const JOIN_HEADERS: Record<string, string> = {
  'Join-Uri': '',
  'Join-ProjectId': '',
  'Join-MilestoneId': '',
};

/**
 * Update the request headers for the apollo client to include the current path,
 * projectId, and milestoneId.
 */
function useApolloHeaders() {
  const { pathname } = useLocation();
  const { projectId = '', milestoneId = '' } = useParams();

  useEffect(() => {
    JOIN_HEADERS['Join-Uri'] = pathname;
    JOIN_HEADERS['Join-ProjectId'] = projectId;
    JOIN_HEADERS['Join-MilestoneId'] = milestoneId;
  }, [pathname, projectId, milestoneId]);

  return JOIN_HEADERS;
}

/** Create a new Apollo Client for use in our app. This must be called within a
 * RouterProvider so that useParams is available. */
export default function useApolloClient() {
  const auth = useJoinAuth();
  const headers = useApolloHeaders();
  return useMemo(() => apolloClient(auth, headers), [auth, headers]);
}
