import { Link } from 'react-router-dom';

import { BusinessOutlined, Flag, LocationOnOutlined, Restore } from '@material-ui/icons';

import { UNTITLED_PROJECT } from '../../../constants';
import { RouteKeys } from '../../../routes/paths';
import { fromNow, parseDate } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import { Thumbnail } from '../../dragon-scales';
import ActiveMilestoneCosts from '../../HomeTab/Search/projects/cells/ActiveMilestoneCosts';
import { Chip, Icon, Tooltip } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';

type Project = {
  budget?: USCents | null;
  estimate?: USCents | null;
  id: UUID;
  location: string;
  milestoneGSF?: number | null;
  milestoneName: string;
  name: string;
  runningTotal?: USCents | null;
  status: string;
  thumbnail: string;
  type: string;
  updatedAt?: Time | null;
  updatedBy?: string | null;
};

type Props = {
  isLink?: boolean;
  project: Project;
  searchTerm: string;
  shouldShowTooltip: boolean;
};

export default function ProjectSelectionListEntry({
  isLink = false,
  project,
  searchTerm,
  shouldShowTooltip,
}: Props) {
  // When the user doesn't have access to a project we show a tooltip explaining
  // why.  Having another nested tooltip here interferes with the parent tooltip
  const lastUpdatedText = `Last updated ${fromNow(project.updatedAt)}${
    project.updatedBy ? ` by ${project.updatedBy}` : ''
  }`;
  return (
    <div className="flex items-center p-1 text-type-primary">
      <div className="p-2">
        <Thumbnail size={92} thumbnail={project.thumbnail} />
      </div>
      <div className="flex flex-grow flex-col gap-1 p-1">
        <div className="type-heading2" data-cy="ProjectsListItem-Name" id={project.id}>
          {isLink ? (
            <Link
              className="!type-heading2 type-link"
              to={generateSharedPath(RouteKeys.PROJECT, {
                projectId: project.id,
              })}
            >
              <SearchText searchTerm={searchTerm} text={project.name ?? UNTITLED_PROJECT} />
            </Link>
          ) : (
            <SearchText searchTerm={searchTerm} text={project.name ?? UNTITLED_PROJECT} />
          )}
        </div>
        <div className="flex flex-wrap gap-1">
          <Chip
            icon={<LocationOnOutlined />}
            text={<SearchText searchTerm={searchTerm} text={project.location} />}
          />
          <Chip
            icon={<BusinessOutlined />}
            text={<SearchText searchTerm={searchTerm} text={project.type} />}
          />
          <Chip text={<SearchText searchTerm={searchTerm} text={project.status} />} />
          <Chip icon={<Flag />} text={project.milestoneName} />
          {project.milestoneGSF ? (
            <Chip
              icon={<Icon name="straighten" />}
              text={`${formatNumber(project.milestoneGSF)} GSF`}
            />
          ) : null}
        </div>
        {project.updatedAt &&
          (shouldShowTooltip ? (
            <Tooltip content={parseDate(project.updatedAt)}>
              <Chip icon={<Restore />} text={lastUpdatedText} />
            </Tooltip>
          ) : (
            <Chip icon={<Restore />} text={lastUpdatedText} />
          ))}
      </div>
      <ActiveMilestoneCosts project={project} />
    </div>
  );
}
