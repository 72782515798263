import { FC, KeyboardEvent, ReactElement, useRef, useState } from 'react';
import {
  DataFunc,
  Mention,
  MentionItem,
  MentionsInput,
  OnChangeHandlerFunc,
  SuggestionDataItem,
} from 'react-mentions';

import { useApolloClient } from '@apollo/client';
// eslint-disable-next-line import/no-unresolved
import { Mention as ReactMention } from 'react-mentions/lib/utils/getMentions';

import { MentionableTextInput } from '../../../generated/graphql';
import useCollaboratorsQuery, { readCollaborators } from '../../../hooks/useCollaboratorsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useProjectID } from '../../../utilities/routes/params';
import useItemLinksQuery, { readItems } from '../../Items/ItemReferences/hooks/useItemLinksQuery';

import styles, { mentionInputClassName } from './MentionableTextAreaStyles';
import {
  displayEmojiTransform,
  displayItemTransform,
  displayTransform,
  getFilteredActiveUsers,
  getFilteredItemLinks,
  getFormattedContent,
  getItemReferenceInputs,
  getPrivateVisible,
  getUserMentionInputs,
  isItemReference,
  isUserMention,
  queryEmojis,
  renderEmojiSuggestion,
  renderItemSuggestions,
  renderSuggestions,
} from './MentionableTextAreaUtils';

const neverMatchingRegex = /($a)/;

export const defaultComment: MentionableTextInput = {
  contents: '',
  mentions: [],
  itemReferences: [],
};

export const DEFAULT_PLACEHOLDER_TEXT = 'Leave a comment. Use @ to mention teammates';

export type MentionableTextAreaProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  autoFocus?: boolean;
  classes: Classes<typeof styles>;
  comment: MentionableTextInput;
  setComment: (result: MentionableTextInput) => void;
  setFocused: (focused: boolean) => void;
  submitComment: () => void;
  placeholderText?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  resetCommentAfterSubmitting?: boolean;
  isReportComment?: boolean;
  isPrivate?: boolean;
  sharedUsersIDs?: UUID[];
};

const MentionableTextArea: FC<MentionableTextAreaProps> = ({
  autoFocus = false,
  classes,
  comment,
  setComment,
  setFocused,
  submitComment,
  placeholderText = DEFAULT_PLACEHOLDER_TEXT,
  resetCommentAfterSubmitting = true,
  isReportComment = false,
  isPrivate,
  sharedUsersIDs,
}) => {
  const projectId = useProjectID();
  if (!projectId) throw new Error('Project ID not found');

  const client = useApolloClient();

  const { refetch: loadCollaborators } = useCollaboratorsQuery();
  const { refetch: loadItems } = useItemLinksQuery();

  const loadSuggestedUsers: DataFunc = (
    query: string,
    callback: (data: SuggestionDataItem[]) => void
  ) => {
    const applyUsers = (collaborators: Collaborator[]) =>
      callback(
        getFilteredActiveUsers(query, getPrivateVisible(collaborators, isPrivate, sharedUsersIDs))
      );
    const collaborators = readCollaborators(client, projectId);
    if (collaborators.length && query) {
      applyUsers(collaborators);
    } else {
      loadCollaborators({ projectId }).then(({ data }) => applyUsers(data?.collaborators ?? []));
    }
  };

  const loadSuggestedItems: DataFunc = (
    query: string,
    callback: (data: SuggestionDataItem[]) => void
  ) => {
    const applyItems = (itemLinks: ItemLink[]) => callback(getFilteredItemLinks(query, itemLinks));
    const itemLinks = readItems(client, projectId);
    if (itemLinks.length && query) {
      applyItems(itemLinks);
    } else {
      loadItems({ projectID: projectId }).then(({ data }) => applyItems(data?.itemLinks ?? []));
    }
  };

  const isItemReferences = !isReportComment;
  const itemReferencesPlaceholder = isItemReferences ? '. Use # to reference items' : '';
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // plain-text markup
  const [value, setValue] = useState(getFormattedContent(comment, isItemReferences)); // local text state that react-mention understands

  const onChange: OnChangeHandlerFunc = (
    _event,
    newValue,
    newPlainTextValue,
    mentionItems: MentionItem[]
  ) => {
    const typedReactMentions = mentionItems as unknown as ReactMention[]; // this type does not match the returned value, typing mismatch
    const userReactMentions: ReactMention[] = [];
    const itemReactMentions: ReactMention[] = [];

    // seperate react mentions into user mentions / item references
    typedReactMentions.forEach((typedMention) => {
      if (isUserMention(typedMention)) {
        userReactMentions.push(typedMention);
      } else if (isItemReferences && isItemReference(typedMention)) {
        itemReactMentions.push(typedMention);
      }
    });

    const userMentions = getUserMentionInputs(userReactMentions);
    const itemReferences = isItemReferences ? getItemReferenceInputs(itemReactMentions) : [];

    // should we include a new space?
    const hasNewMention = userMentions.length > (comment.mentions?.length ?? 0);
    const hasNewItemReference = itemReferences.length > (comment.itemReferences?.length ?? 0);

    const addSpace = hasNewMention || hasNewItemReference ? ' ' : '';
    const localNewValue = `${newValue}${addSpace}`;
    const contents = `${newPlainTextValue}${addSpace}`;

    setValue(localNewValue);
    setComment({ contents, mentions: userMentions, itemReferences });
    if (hasNewMention || hasNewItemReference) {
      // after its done, move the cursor to the end of the input
      setTimeout(() => {
        const input = inputRef.current;
        if (input) {
          const end = input.value.length;
          input.setSelectionRange(end, end);
        }
      }, 0);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key, shiftKey } = event;
    if (key === 'Enter') {
      if (!shiftKey) {
        event.stopPropagation(); // don't trigger key down events for parent elements
        submitComment();
        if (resetCommentAfterSubmitting) {
          setValue('');
          setComment(defaultComment);
        }
        event.preventDefault(); // in user report notes this will expand / collapse rows without this.
      }
    }
  };

  return (
    <div className={classes.container} onKeyDown={handleKeyDown} role="menu" tabIndex={0}>
      <MentionsInput
        a11ySuggestionsListLabel="Suggested mentions"
        allowSpaceInQuery
        autoFocus={autoFocus}
        className={mentionInputClassName}
        data-cy="input-mention"
        forceSuggestionsAboveCursor
        inputRef={inputRef}
        onBlur={() => setFocused(false)}
        onChange={onChange}
        onFocus={() => {
          setFocused(true);
        }}
        placeholder={`${placeholderText}${itemReferencesPlaceholder}`}
        value={value}
      >
        <Mention // default markup for react-mention: '@[__display__](__id__)'
          className={classes.mention}
          data={loadSuggestedUsers}
          displayTransform={displayTransform(client, projectId)}
          renderSuggestion={renderSuggestions(client, projectId, classes)}
          trigger="@"
        />
        <Mention
          data={queryEmojis}
          displayTransform={displayEmojiTransform} // important for comment text
          markup="__id__"
          regex={neverMatchingRegex}
          renderSuggestion={renderEmojiSuggestion(classes)}
          trigger=":"
        />
        {isItemReferences ? (
          <Mention
            className={classes.itemReference}
            data={loadSuggestedItems}
            displayTransform={displayItemTransform(client, projectId)}
            markup="#[__display__](__id__)" // special markup for item references
            renderSuggestion={renderItemSuggestions(client, projectId, classes)}
            trigger="#"
          />
        ) : (
          (null as unknown as ReactElement)
        )}
      </MentionsInput>
    </div>
  );
};

export default withStyles(styles)(MentionableTextArea);
