import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  SettingsEvent,
  setEditRolePermissionsAnalytics,
  settingsEvent,
} from '../../../analytics/analyticsEventProperties';
import { ADMINISTRATOR_ROLE } from '../../../constants';
import { Permission, PermissionLevel, PermissionResource, Role } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import { Button, Icon, ScrollContainer, Select, TextInput, Tooltip } from '../../scales';

import DeleteRoleDialog from './DeleteRoleDialog';
import Header from './Header';
import useSyncPermissionsWithBackend from './hooks/updateRoleHooks';
import PermissionsTable from './PermissionsTable';

type Props = {
  projectID: UUID;
  roles: Role[];
  roleID: UUID;
};

const RoleEdit = (props: Props) => {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();
  const syncPermissionsWithBackend = useSyncPermissionsWithBackend();

  const role = props.roles.find((role) => role.id === props.roleID);

  const { canEdit, canDelete } = usePermissions();
  const [showEditConfirmDialog, setShowEditConfirmDialog] = useState(false);
  const [roleName, setRoleName] = useState<string | undefined>(role?.name ?? undefined);
  const [hasTrade, setHasTrade] = useState<boolean | undefined>(role?.hasTrade ?? undefined);
  const [permissionsMap, setPermissionsMap] = useState(new Map<UUID, PermissionLevel>());
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const canEditPermissionTemplates = canEdit(PermissionResource.PERMISSION_TEMPLATES);
  // TODO DD-843: Create role type to avoid need for special names
  const isSaveDisabled =
    ((!roleName || roleName === role?.name) &&
      (role?.hasTrade === hasTrade || hasTrade === undefined) &&
      permissionsMap.size === Number(0)) ||
    roleName?.trim() === ADMINISTRATOR_ROLE;

  const setMap = (permissionsMap: Map<UUID, PermissionLevel>) =>
    setPermissionsMap(new Map(permissionsMap));

  const localPermissionGroups =
    role?.permissionGroups?.map((group) => {
      const permissions = group?.permissions.map((permission: Permission) => {
        const level = permissionsMap.get(permission.id);
        if (level) return { ...permission, level };
        return permission;
      });
      return { ...group, permissions };
    }) || [];

  // supports new scales permissions table
  const onChange = (id: UUID, level: PermissionLevel, permissionResource?: string) => {
    const permissionMatch = role?.permissionGroups
      .flatMap((group) => group.permissions)
      .find((permission: Permission) => permission.id === id);
    if (permissionMatch?.level === level) {
      permissionsMap.delete(id);
      setMap(permissionsMap);
    } else {
      setMap(permissionsMap.set(id, level));
    }
    sendAnalytics(
      settingsEvent(SettingsEvent.PERMISSION_CHANGE, {
        projectId: props.projectID,
        role: role?.name,
        permission: permissionResource,
        level,
      })
    );
  };

  const onCancel = () => {
    sendAnalytics(setEditRolePermissionsAnalytics(false, role?.name));
    if (roleName !== role?.name || hasTrade !== role?.hasTrade || permissionsMap.size > 0) {
      setShowEditConfirmDialog(true);
    } else {
      setShowEditConfirmDialog(false);
      navigate(
        generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW, {
          projectId: props.projectID,
          roleId: props.roleID,
        })
      );
    }
  };

  const onReset = () => {
    setRoleName(undefined);
    setHasTrade(undefined);
    permissionsMap.clear();
    setMap(permissionsMap);
    navigate(
      generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW, {
        projectId: props.projectID,
        roleId: props.roleID,
      })
    );
  };

  const onSave = (
    name?: string,
    hasTrade?: boolean,
    permissionsMap?: Map<string, PermissionLevel> | undefined
  ) => {
    const permissionUpdates =
      permissionsMap && permissionsMap.size > 0
        ? Array.from(permissionsMap).map(([id, level]) => ({ id, level }))
        : [];
    syncPermissionsWithBackend({
      roleID: role?.id || props.roleID,
      name: name !== role?.name ? name : undefined,
      hasTrade: hasTrade !== role?.hasTrade ? hasTrade : undefined,
      permissionUpdates,
    });
    sendAnalytics(setEditRolePermissionsAnalytics(false, roleName));
    setRoleName(undefined);
    setHasTrade(undefined);
    setMap(new Map<string, PermissionLevel>());
    navigate(
      generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW, {
        projectId: props.projectID,
        roleId: props.roleID,
      })
    );
  };

  const isAdminRole = role?.name.trim() === ADMINISTRATOR_ROLE;
  const deleteButtonDisabled = isAdminRole || !canDelete(PermissionResource.PERMISSION_TEMPLATES);

  // Trades selector
  const selectEntries = [
    { id: 'NO', label: 'No' },
    { id: 'YES', label: 'Yes' },
  ];

  return (
    <div className="flex grow flex-col overflow-auto">
      <Header roleName={role?.name ?? ''}>
        {canEditPermissionTemplates && (
          <div className="flex gap-2">
            <Tooltip
              content="Administrator role cannot be deleted"
              isDisabled={!deleteButtonDisabled}
              placement="bottom"
            >
              <Button
                data-cy="delete-role-button"
                isDisabled={deleteButtonDisabled}
                label="Delete"
                onClick={() => setShowDeleteDialog(true)}
                startIcon={<Icon name="delete" />}
                type="destructive"
              />
            </Tooltip>
            <Button
              data-cy="edit-role-cancel-button"
              label="Cancel"
              onClick={onCancel}
              type="secondary"
            />
            <Button
              data-cy="save-role-button"
              isDisabled={isSaveDisabled}
              label="Save"
              onClick={() => onSave(roleName, hasTrade, permissionsMap)}
              type="secondary"
            />
          </div>
        )}
      </Header>
      <ScrollContainer direction="vertical">
        <div className="flex flex-col gap-6 p-6 pt-0">
          <div className="flex w-1/2 flex-row gap-2">
            <div className="w-1/2">
              <TextInput
                errorMessage={isAdminRole ? `Role cannot be named '${roleName?.trim()}'` : ''}
                isDisabled={isAdminRole}
                label="Role Name"
                onChange={(newName: string) => setRoleName(newName)}
                placeholder="Add role name"
                value={roleName}
              />
            </div>
            <div className="w-1/2">
              <Select
                entries={selectEntries}
                label="Limit Access by Category"
                onChange={(value) => setHasTrade(value === 'YES')}
                value={hasTrade ? selectEntries[1].id : selectEntries[0].id}
              />
            </div>
          </div>
          <PermissionsTable
            editable
            hasTrade={hasTrade ?? role?.hasTrade ?? true}
            onChange={onChange}
            permissionGroups={localPermissionGroups}
          />
        </div>
      </ScrollContainer>
      <DialogsConfirm
        acceptCtaCopy="Leave"
        body={`You have unsaved permission changes for the ${
          roleName ?? ''
        } role. Are you sure you want to leave?`}
        cancelCtaCopy="Stay"
        onClose={() => setShowEditConfirmDialog(false)}
        onConfirm={onReset}
        open={showEditConfirmDialog}
        title="Confirm navigation"
      />
      {showDeleteDialog && (
        <DeleteRoleDialog
          isOpen
          onClose={() => setShowDeleteDialog(false)}
          projectID={props.projectID}
          role={role}
          roles={props.roles}
        />
      )}
    </div>
  );
};

export default RoleEdit;
