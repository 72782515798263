import { ScalePoint, ScaleTime } from 'd3-scale';

import { formatCost } from '../../../../utilities/currency';
import useMemoWrapper from '../../../useMemoWrapper';

type TimeDate = {
  date: Date;
};

type Value = Record<string, number>;

// Default key fields:
const VALUE_KEY = 'value';

export default function TimelineLabel<V extends Value>(props: {
  data: V & TimeDate;
  field?: string;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  yPosition: number;
  textColor?: string;
}) {
  const label = useMemoWrapper(
    getLabelCoords,
    props.data,
    props.x,
    props.yPosition,
    props.field ?? VALUE_KEY
  );
  if (!props.data) return null;
  return label ? (
    <foreignObject height="30" width="50" x={label.x - 40} y={label.y - 16}>
      <div>
        <g>
          <text
            key={`label-${label.value}`}
            className={`bg-background-primary type-body3 ${props.textColor ?? 'text-black'}`}
            textAnchor="middle"
          >
            {formatCost(label.value, { short: true })}
          </text>
        </g>
      </div>
    </foreignObject>
  ) : null;
}

export function getLabelCoords<V extends Value>(
  data: V & TimeDate,
  x: ScaleTime<number, number> | ScalePoint<Date>,
  yPosition: number,
  field: string
) {
  return {
    x: x(data.date) ?? 0,
    y: yPosition,
    value: data[field],
  };
}
