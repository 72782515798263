import { ReactNode } from 'react';

import { MenuItem, MenuList } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import Clock from '../../Icons/Clock';
import ClockMultiple from '../../Icons/ClockMultiple';
import Location from '../../Icons/Location';
import LocationMultiple from '../../Icons/LocationMultiple';
import { Icon } from '../../scales';

import styles from './EscalationInputDropdownMenuListStyles';

const EscalationInputMenuList = (props: {
  isAutoEscalated: boolean;
  onAutoEscalate: () => void;
  onAutoEscalateAll: () => void;
  onManualEscalate: () => void;
  onRemoveEscalation: () => void;
  type: 'date' | 'location';
}) => {
  const dropdownMenu = [
    <StyledMenuItem key="auto" data-cy="auto" onClick={props.onAutoEscalate}>
      {icons[props.type].auto}
      <div>{text[props.type].auto}</div>
    </StyledMenuItem>,
    <StyledMenuItem key="all" data-cy="all" onClick={props.onAutoEscalateAll}>
      {icons[props.type].all}
      <div>{text[props.type].all}</div>
    </StyledMenuItem>,
    <StyledMenuItem key="manual" data-cy="manual" onClick={props.onManualEscalate}>
      {icons[props.type].manual}
      <div>{text[props.type].manual}</div>
    </StyledMenuItem>,
  ];

  if (props.isAutoEscalated) {
    dropdownMenu.push(
      <StyledMenuItem key="remove" data-cy="remove" onClick={props.onRemoveEscalation}>
        {icons[props.type].remove}
        <div>{text[props.type].remove}</div>
      </StyledMenuItem>
    );
  }

  return <MenuList data-cy="escalation-input-menu">{dropdownMenu}</MenuList>;
};

const icons = {
  date: {
    auto: <Clock />,
    all: <ClockMultiple />,
    manual: <Edit />,
    remove: <Icon className="text-button-destructive" name="delete" />,
  },
  location: {
    auto: <Location />,
    all: <LocationMultiple />,
    manual: <Edit />,
    remove: <Icon className="text-button-destructive" name="delete" />,
  },
} as const;

const text = {
  date: {
    auto: 'Update this time factor with RSMeans Index',
    all: 'Update all time factors with RSMeans Index',
    manual: 'Update manually',
    remove: 'Remove RSMeans Index escalation',
  },
  location: {
    auto: 'Update this location factor with RSMeans Index',
    all: 'Update all location factors with RSMeans Index',
    manual: 'Update manually',
    remove: 'Remove RSMeans Index escalation',
  },
} as const;

const StyledMenuItem = withStyles(styles)(
  (props: {
    classes: Classes<typeof styles>;
    children: ReactNode;
    'data-cy'?: string;
    onClick: () => void;
  }) => (
    <MenuItem className={props.classes.menuitem} data-cy={props['data-cy']} onClick={props.onClick}>
      {props.children}
    </MenuItem>
  )
);

export default EscalationInputMenuList;
