import { FC, useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { CardHeader, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { reportVariance, varianceEventTypes } from '../../../analytics/analyticsEventProperties';
import { EDIT, VARIANCE } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import {
  VarianceColumnDescription,
  getColumnTermFromColumnKey,
} from '../../CostReport/CostReportColumns/CostReportColumns';
import FilterPanelWrapper from '../../FilterPanel/FilterPanelWrapper';
import { FilterManager } from '../../FilterPanel/filterUtils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import ReportsManagerMenu from '../../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import { IconButton } from '../../scales';
import VarianceManager, {
  openVarianceOnboarding,
} from '../VarianceModals/VarianceManager/VarianceManager';
import VarianceReportExportPanel from '../VarianceReportExportPanel/VarianceReportExportPanel';
import { getDate } from '../VarianceReportUtils';

import styles from './VarianceReportHeaderStyles';

type VarianceReportHeaderProps = {
  classes: Classes<typeof styles>;
  exportCostReport: () => void;
  filterManager: FilterManager;
  projectId: UUID;
  printOnClick: () => void;
  settings: VarianceSettings;
  setSettings: SetSettingsFunctionType;
  varianceDescriptions: VarianceColumnDescription[];
};

const VarianceReportHeader: FC<VarianceReportHeaderProps> = ({
  classes,
  exportCostReport,
  filterManager,
  printOnClick,
  projectId,
  settings,
  setSettings,
  varianceDescriptions,
}) => {
  const t = useContext(ProjectTermStore);
  const hash = window.location.hash.slice(1);
  useEffectOnce(() => {
    if (hash.includes(EDIT)) {
      openVarianceOnboarding(true);
    }
  });
  const sendAnalytics = useSendAnalytics();
  const onClick = () => {
    openVarianceOnboarding();
    sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_EDIT_MENU));
  };

  const [showFilters, setShowFilters] = useState(false);

  const { isViewOnly } = usePermissions();

  const controlHeader = (
    <div className={classes.controlHeader}>
      <FilterPanelWrapper
        filterManager={filterManager}
        page={VARIANCE}
        setShow={setShowFilters}
        show={showFilters}
      />
      <ReportsManagerMenu
        isViewOnly={isViewOnly}
        setSettings={setSettings}
        settings={settings}
        variant="variance"
      />
      <IconButton
        aria-label="Edit variance report"
        data-cy="button-editVarianceReport"
        icon={<Edit />}
        onClick={onClick}
        type="secondary"
      />
      <VarianceReportExportPanel exportCostReport={exportCostReport} printOnClick={printOnClick} />
    </div>
  );
  const descriptions = varianceDescriptions
    .slice(0, 2)
    .map(
      (d) => `${d.milestoneName} ${getColumnTermFromColumnKey(t, d.columnKey)} (${getDate(d.date)})`
    )
    .join(' vs ');

  return (
    <>
      <CardHeader
        title={
          <div className={classes.header}>
            <div className={classes.title}>
              <Breadcrumbs
                links={[
                  {
                    display: 'Milestones',
                    destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES, { projectId }),
                    tooltip: 'Back to milestones list',
                  },
                  {
                    display: 'Variance Report',
                    destination: '',
                    tooltip: '',
                  },
                ]}
              />
              <Typography variant="headline">Variance Report</Typography>
              <Typography>{descriptions}</Typography>
            </div>
            {controlHeader}
          </div>
        }
      />
      <VarianceManager />
    </>
  );
};

export default withStyles(styles)(VarianceReportHeader);
