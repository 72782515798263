import { FC, useState } from 'react';

import { Dialog, DialogActions, Divider, Typography } from '@material-ui/core';

import { PROJECT_CURRENCY } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { constructCurrencyEntries } from '../../../utilities/currency';
import { useProjectSettings, useUpdateProjectSetting } from '../../ProjectDisplaySettings/hooks';
import { Button, Select } from '../../scales';

import styles from './ChangeProjectCurrencyModalStyles';

type ChangeProjectCurrencyModalProps = {
  canEditCurrency: boolean;
  classes: Classes<typeof styles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onEscape?: (e: any, closeDialog: () => void) => void;
  projectID: UUID;
};

const ChangeProjectCurrencyModal: FC<ChangeProjectCurrencyModalProps> = ({
  canEditCurrency,
  classes,
  open = false,
  onCancel,
  onClose,
  onEscape,
  projectID,
}) => {
  const settings = useProjectSettings(projectID);
  const updateCurrency = useUpdateProjectSetting(projectID, 'CURRENCY');
  const [newCurrency, setNewCurrency] = useState(settings.CURRENCY);
  const onCurrencySubmit = (newCurrency: string) => {
    updateCurrency(newCurrency);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth={false}
      onClose={onClose}
      onKeyDown={onEscape ? (e) => onEscape(e, onClose) : () => {}}
      open={open}
    >
      <div className={classes.root}>
        <div className={classes.modalHeader}>
          <Typography variant="title">Change Project Currency</Typography>
        </div>
        <Divider />
        <div className={classes.modalBody}>
          <div className="type-label">{PROJECT_CURRENCY}</div>
          <Select
            data-cy="currency-select"
            entries={constructCurrencyEntries()}
            isDisabled={!canEditCurrency}
            onChange={setNewCurrency}
            placeholder="$ USD US Dollar"
            value={newCurrency}
          />
          <Typography className={classes.promptText}>
            Join will not convert values based on exchange rates. <br />
            (i.e. $ 5 will become € 5).
          </Typography>
        </div>
      </div>
      <Divider />
      <div>
        <DialogActions>
          <Button label="Cancel" onClick={onCancel} type="secondary" />
          <Button
            data-cy="change-project-currency-change-button"
            label="Change"
            onClick={() => {
              onCurrencySubmit(newCurrency);
              onClose();
            }}
            type="primary"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ChangeProjectCurrencyModal);
