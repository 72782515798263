import { AssistantPhoto, AssistantPhotoOutlined } from '@material-ui/icons';

import { InsightsMilestone } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import { isGmpInsightsMilestone } from '../utils';

export type DisplayType = 'budget' | 'estimate' | 'gap';

type Props = {
  displayType: DisplayType;
  milestones: InsightsMilestone[];
};

export default function MilestoneCostTooltip(props: Props) {
  const getDisplayValue = (milestone: InsightsMilestone) => {
    switch (props.displayType) {
      case 'budget':
        return milestone.budget && milestone.budget > 0
          ? formatCost(milestone.budget, { short: true })
          : 'No budget';
      case 'estimate':
        return milestone.estimate && milestone.estimate > 0
          ? formatCost(milestone.estimate, { short: true })
          : 'No estimate';
      case 'gap':
        return milestone.budget && milestone.budget > 0
          ? formatCost((milestone.budget || 0) - (milestone.estimate || 0), { short: true })
          : '-';
      default:
        return '-';
    }
  };

  const getTitleValue = () => {
    switch (props.displayType) {
      case 'budget':
        return 'Budget History';
      case 'estimate':
        return 'Running Total History';
      case 'gap':
        return 'Gap History';
      default:
        return 'Milestones';
    }
  };

  // Reverse milestones for display (from most recent first)
  const reversedMilestones = [...props.milestones].reverse();
  const firstMilestones = reversedMilestones.slice(0, 8); // First 8 milestones
  const hasMoreThan9 = reversedMilestones.length > 9;
  const oldestMilestone = reversedMilestones[reversedMilestones.length - 1]; // Last milestone in reversed list

  return (
    <div className="flex flex-col gap-1">
      <strong className="type-body2">{getTitleValue()}</strong>
      <div className="flex min-w-[300px] flex-col gap-2">
        {/* Display first 8 milestones */}
        {firstMilestones.map((milestone) => (
          <MilestoneItem
            key={milestone.id}
            displayValue={getDisplayValue(milestone)}
            milestone={milestone}
          />
        ))}
        {/* Display ... if more than 9 milestones */}
        {hasMoreThan9 && <div className="text-center type-body2">...</div>}
        {/* Display the oldest milestone */}
        {((hasMoreThan9 && oldestMilestone) || firstMilestones.length === 8) && (
          <MilestoneItem
            key={oldestMilestone.id}
            displayValue={getDisplayValue(oldestMilestone)}
            milestone={oldestMilestone}
          />
        )}
      </div>
    </div>
  );
}

type MilestoneItemProps = {
  displayValue: string;
  milestone: InsightsMilestone;
};

function MilestoneItem(props: MilestoneItemProps) {
  const isGMPPhase = isGmpInsightsMilestone(props.milestone);
  const Icon = props.milestone.active ? AssistantPhoto : AssistantPhotoOutlined;
  // GMP active = orange filled in
  // GMP not active = orange not filled in
  // All others active = blue filled in
  // All others not active = blue not filled in
  const iconClass = isGMPPhase ? '' : 'text-entities-milestone';
  const iconColor = isGMPPhase ? 'var(--colors-theme-bauhaus-laszlo)' : '';

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Icon className={iconClass} nativeColor={iconColor} />
        <div className="max-w-[180px]">
          <div className="line-clamp-1 type-body2">{props.milestone.name}</div>
        </div>
      </div>
      <div className="text-right type-body2">{props.displayValue}</div>
    </div>
  );
}
