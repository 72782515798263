import { FC, useContext } from 'react';

import { TermKey } from '../../../api/gqlEnums';
import { S1, S2 } from '../../../constants';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Switch } from '../../scales';

import ReferencesMenu from './ReferencesMenu';

type Props = {
  selectedReferences: string[];
  setSelectedReferences: (refs: string[]) => void;
};

const InputsSelectReferenceInheritedOptions: FC<Props> = ({
  selectedReferences,
  setSelectedReferences,
}) => {
  const termStore = useContext(ProjectTermStore);

  const getTitle = (ref: string, termKey: TermKey) =>
    `${ref}: Subtotal of ${termStore.titleCase(termKey)}`;

  const checked = selectedReferences.includes(S2);
  return (
    <>
      <ReferencesMenu
        subMenuItems={undefined}
        subTitle=""
        title={getTitle(S1, TermKey.DIRECT_COST)}
      />
      <ReferencesMenu
        subMenuItems={undefined}
        subTitle=""
        title={
          <div className="flex max-h-7">
            <div className="flex-grow">{getTitle(S2, TermKey.MARKUP)}</div>
            <div className="relative bottom-1.5">
              <Switch
                isChecked={checked}
                label=""
                onChange={() => setSelectedReferences(checked ? [S1] : [S1, S2])}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default InputsSelectReferenceInheritedOptions;
