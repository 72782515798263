import * as d3 from 'd3';
import { useState } from 'react';

import { DEFAULT_MARGIN } from '../../../../constants';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import useMemoWrapper from '../../../useMemoWrapper';
import InsightsEmptyChart from '../InsightsCost/InsightsEmptyChart';
import TimelinePath from '../TimelinePath';

import ItemsBar from './ItemsBar';
import ItemsBarTooltip from './ItemsBarTooltip';
import ItemsBarTooltipContent from './ItemsBarTooltipContent';
import ItemsMaxLine from './ItemsMaxLine';
import { TimelineItemData } from './types';
import {
  findGroupByValue,
  getHoverDate,
  getItemsGroupBy,
  getItemsGroups,
  getYDomain,
  getYDomainPadded,
} from './utils';

type Props = {
  hasLinkAccess: boolean;
  height: number;
  items: TimelineItemData[];
  isMiniChart?: boolean;
  isPrint?: boolean;
  projectID?: UUID;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsItemsChart(props: Props) {
  const { height, isPrint, today, totalRange } = props;
  const [hoverIndex, setHoverIndex] = useState(-1);
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = DEFAULT_MARGIN;

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  const itemsGroupBy = useMemoWrapper(getItemsGroupBy, x.domain());
  const itemGroups = useMemoWrapper(getItemsGroups, props.items, itemsGroupBy);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, itemGroups);
  // Add vertical padding
  const { yDomain, yMin, yMax } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  const yRange: [number, number] = [margin.top, height - margin.bottom];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const maxGroup = useMemoWrapper(findGroupByValue, itemGroups, yDataMax);
  const hoverItem = itemGroups[hoverIndex];
  const hoverDate = useMemoWrapper(getHoverDate, hoverItem);

  if (!props.items.length) {
    return <InsightsEmptyChart height={height} today={today} totalRange={totalRange} />;
  }

  return (
    <SVGWithDimensions ref={ref} data-cy="chart-items" dimensions={dimensions}>
      {/* Bars */}
      {itemGroups.map((item, i) => (
        <ItemsBar
          key={item.start}
          isHovered={hoverIndex !== -1 && hoverIndex !== i}
          item={item}
          onMouseEnter={() => setHoverIndex(i)}
          onMouseLeave={() => setHoverIndex(-1)}
          x={x}
          y={y}
          yMax={yMax}
        />
      ))}
      {/* Today */}
      <TimelinePath<{ value: number }>
        className="pointer-events-none stroke-selection-focus-fill"
        data={todayData}
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Points */}
      {!isPrint && hoverItem && hoverDate && (
        <ItemsBarTooltip
          key={`tooltip-${hoverIndex}`}
          content={
            <ItemsBarTooltipContent
              data={hoverItem}
              hasLinkAccess={props.hasLinkAccess}
              isMiniChart={!!props.isMiniChart}
              projectID={props.projectID ?? ''}
              today={props.today}
            />
          }
          data={{
            value: props.isMiniChart ? (yMax + yMin) / 2 : yMax,
            date: hoverDate,
          }}
          isMiniChart={!!props.isMiniChart}
          offsetY={props.isMiniChart ? height / 2 + 12 : 10}
          onMouseLeave={() => setHoverIndex(-1)}
          x={x}
          y={y}
        />
      )}
      {/* Max line with label */}
      {maxGroup && (
        <ItemsMaxLine
          isMiniChart={props.isMiniChart}
          maxGroup={maxGroup}
          totalRange={props.totalRange}
          x={x}
          y={y}
          yDataMax={yDataMax}
          yMax={yMax}
        />
      )}
    </SVGWithDimensions>
  );
}
