import { ReactNode } from 'react';

import { LinearProgress } from '@material-ui/core';

import { ItemSidebarLocation } from '../../../analytics/analyticsEventProperties';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { ItemTypes } from '../../CostReport/CostReportList/CostReportListRow/CostReportListRowUtils';
import { StatusBanner } from '../../scales';

import ItemSidebarBody from './ItemSidebarBody';
import ItemSidebarHeader from './ItemSidebarHeader';
import styles from './ItemSidebarStyles';
import { ItemPermissions } from './ItemSidebarUtils';

type Props = Omit<ContentProps, 'item'> & {
  item?: ItemLike | null;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
};

const ItemSidebar = (props: Props) => {
  const content: ReactNode[] = [];
  if (props.loading) {
    content.push(
      <div key="loader" className="h-0">
        <LinearProgress />
      </div>
    );
  }

  if (props.item) {
    content.push(<Contents {...props} key="contents" item={props.item} />);
  } else if (!props.loading) {
    content.push(
      <div key="error-banner" className="flex h-full w-full items-center justify-center">
        <StatusBanner type="error">Failed to fetch item details.</StatusBanner>
      </div>
    );
  }

  return <div className={props.classes.root}>{content}</div>;
};

type ContentProps = {
  classes: Classes<typeof styles>;
  closeSidebar: () => void;
  costMode: CostMode;
  expanded: string[];
  footer: ReactNode;
  item: ItemLike;
  items?: ItemTypes[] | null;
  milestones: Milestone[];
  openSidebar: (id?: string) => void;
  permissions: ItemPermissions;
  projectId: UUID;
  onItemMutated: (isCostMutated?: boolean) => void;
  setExpanded: (section: string[]) => void;
  setOpenDialogAddOption: (b: boolean) => void;
  sharedUsers?: Pick<User, 'id' | 'name'>[];
  sidebarLocation?: ItemSidebarLocation;
};

function Contents(props: ContentProps) {
  const isProjectItemSidebar =
    props.sidebarLocation !== ItemSidebarLocation.BENCHMARKING &&
    props.sidebarLocation !== ItemSidebarLocation.HOME;

  return (
    <>
      <ItemSidebarHeader
        closeSidebar={props.closeSidebar}
        isProjectItemSidebar={isProjectItemSidebar}
        item={props.item}
        items={props.items}
        onItemMutated={props.onItemMutated}
        projectId={props.projectId}
        sharedUsers={props.sharedUsers ?? []}
      />
      <div className={props.classes.bodyContainer}>
        <ItemSidebarBody
          costMode={props.costMode}
          expanded={props.expanded}
          isProjectItemSidebar={isProjectItemSidebar}
          item={props.item}
          milestones={props.milestones}
          onItemMutated={props.onItemMutated}
          openSidebar={props.openSidebar}
          permissions={props.permissions}
          projectId={props.projectId}
          setExpanded={props.setExpanded}
          setOpenDialogAddOption={props.setOpenDialogAddOption}
        />
      </div>
      {props.footer && (
        <div className="flex justify-between border-t bg-background-primary px-4 py-2">
          {props.footer}
        </div>
      )}
    </>
  );
}

export default withStyles(styles)(ItemSidebar);
