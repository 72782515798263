import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useEstimateQuery } from '../../../hooks/useEstimateQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useCostMode } from '../../../utilities/costMode';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { usePrintItemHistory } from '../../Events/EventsItemHistoryList/EvenItemsHistoryListUtils';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import usePrintWindow from '../PrintHooks/usePrintWindow';

import { PrintItemDetails } from './PrintItemDetails';

const PrintViewItemDetails: FC = () => {
  const { projectId, itemId } = useParams();
  if (!projectId || !itemId) throw new Error('Project ID or Item ID not found');

  const withHistory = usePrintItemHistory();

  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const itemLike = useItemQuery(itemId)?.data?.item;

  // PERMISSIONS
  const permissions = usePermissions({ trades: itemLike?.categories || [] });
  const costMode = useCostMode();

  const estimateID = itemLike?.activeEstimate?.id ?? '';
  const { data: { estimate } = {} } = useEstimateQuery(estimateID);

  const itemIsReady = Boolean(itemLike?.id);
  const estimateIsReady = Boolean(estimate?.id);
  const [renderComplete, setRenderComplete] = useState(false);
  const triggerOnComplete = () => setRenderComplete(true);

  usePrintWindow(itemIsReady, estimateIsReady, renderComplete);

  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - Item Details`;
  }, [project]);

  return itemLike ? (
    <div>
      <PrintItemDetails
        costMode={costMode}
        estimate={estimate ?? undefined}
        itemLike={itemLike}
        permissions={permissions}
        projectId={projectId}
        triggerOnComplete={triggerOnComplete}
        withHistory={withHistory}
      />
    </div>
  ) : null;
};

export default PrintViewItemDetails;
