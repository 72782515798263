import { FC, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Card, CardHeader, Divider, IconButton } from '@material-ui/core';
import { FullscreenExit } from '@material-ui/icons';

import { ItemEvents } from '../../../analytics/analyticsEventProperties';
import { TermKey } from '../../../api/gqlEnums';
import { ESC } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getItemIdFromUrl } from '../../../utilities/url';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import ItemsTemplateLink from '../../Items/ItemsTemplatesLinks/ItemsTemplateLink';
import { isScenarioVisibility } from '../../Items/ItemsUtils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

import EstimateAccordionItemContent from './EstimateAccordionItemContent';
import styles from './EstimateAccordionStyles';

export const CLOSE_ITEM_ESTIMATE = 'close-item-estimate';

type EstimateAccordionItemProps = {
  classes: Classes<typeof styles>;
};

const EstimateAccordionItem: FC<EstimateAccordionItemProps> = ({ classes }) => {
  const navigate = useNavigate();
  const t = useContext(ProjectTermStore);
  const itemId = getItemIdFromUrl();
  const { data: { item } = {} } = useItemQuery(itemId);

  // DERIVED ITEM CONSTS
  const { name } = item || {};

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const estimateHandle = `Item ${t.titleCase(TermKey.ESTIMATE)}`;

  const sendAnalytics = useSendAnalytics();

  const isScenarioItem = isScenarioVisibility(item?.visibility);
  const onReturn = useCallback(() => {
    if (isScenarioItem) {
      navigate(-1);
    } else {
      navigate(
        generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
          projectId,
          itemId,
        })
      );
    }
    sendAnalytics({
      type: ItemEvents.ITEM_ESTIMATE_FULLSCREEN_CLOSE,
      eventProperties: { open: false },
    });
  }, [isScenarioItem, sendAnalytics, navigate, projectId, itemId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const handleKey = (e: any) => {
      if (e.key === ESC) onReturn();
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [onReturn]);

  const exitFullscreenButton = (
    <IconButton
      classes={{ root: classes.editButton }}
      data-cy={CLOSE_ITEM_ESTIMATE}
      disableRipple
      onClick={onReturn}
    >
      <FullscreenExit />
    </IconButton>
  );

  // PERMISSIONS
  const { canView } = usePermissions();
  const canViewItemTemplate = canView(PermissionResource.ITEM_TEMPLATE);

  // LOCAL PERSISTENT "STATE" SETUP from URL and localStorage per milestone

  const title = (
    <div className={classes.header}>
      <div className={classes.title}>
        {!isScenarioItem && (
          <Breadcrumbs
            links={[
              {
                display: 'Items',
                destination: generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId }),
                tooltip: 'Back to items list',
              },
              {
                display: name || 'Loading...',
                destination: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                  projectId,
                  itemId,
                }),
                tooltip: 'Back to item details',
              },
              {
                display: estimateHandle,
                destination: '',
                tooltip: `${name} ${estimateHandle}`,
              },
            ]}
          />
        )}
        {exitFullscreenButton}
      </div>
      <div className={classes.controlHeader}>
        <div className={classes.headerOptions}>{canViewItemTemplate && <ItemsTemplateLink />}</div>
      </div>
    </div>
  );

  return (
    <div onDragOver={(e) => e?.preventDefault()}>
      <Card className={classes.card} elevation={0} square>
        <div className={classes.background}>
          <CardHeader title={title} />
        </div>
        <Divider />
        {item && (
          <EstimateAccordionItemContent
            estimateHandle={estimateHandle}
            item={item}
            projectID={projectId}
          />
        )}
      </Card>
    </div>
  );
};

export default withStyles(styles)(EstimateAccordionItem);
