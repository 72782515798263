import { FC, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { ApolloError } from '@apollo/client';
import { Card, Divider, Typography } from '@material-ui/core';

import { GO_TO_COLUMN_CTA } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import { EstimateType, TermKey } from '../../../api/gqlEnums';
import { CATEGORIZATION_ID, EDIT_ESTIMATE, PREVIEW_MSR } from '../../../constants';
import {
  EstimateArmatureQuery,
  GetMilestoneQuery,
  MilestoneCostReportsQueryResult,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { computeDraftEstimateTotal } from '../../../utilities/permissions/itemsPermissions';
import { isNonNullable } from '../../../utilities/types';
import { getURLState, serializeToURL } from '../../../utilities/urlState';
import useCostReportParams from '../../CostReport/CostReport/useCostReportParams';
import CostReportSettingsPanel from '../../CostReport/CostReportSettingsPanel/CostReportSettingsPanel';
import MilestoneCostReport from '../../CostReport/MilestoneCostReport';
import CostEstimate from '../../estimate/CostEstimate';
import { getGridVariantFromEstimateType } from '../../estimate/EstimateAccordion/EstimateAccordionUtils';
import EstimateTotal from '../../estimate/EstimateAccordion/EstimateTotal';
import EstimateTotalHeader from '../../estimate/EstimateAccordion/EstimateTotalHeader';
import IssuesSelect from '../../ImportEstimate/IssuesSelect/IssuesSelect';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import useMemoWrapper from '../../useMemoWrapper';
import ImportEstimateHeader from '../ImportEstimateHeader/ImportEstimateHeader';

import styles from './EstimateImportPageStyles';

const computeCategorizations = (fields: Field[]) =>
  (fields || [])
    .map(({ categorization }: Field) => categorization)
    .filter((c: Categorization | undefined | null) => !!c);

type EstimateImportProps = {
  draftEstimate: NonNullable<EstimateArmatureQuery['estimate']>;
  estimateType: EstimateType;
  canPublishDraftEstimate: boolean;
  canViewProjectCategories: boolean;
  classes: Classes<typeof styles>;
  error: ApolloError | undefined;
  estimateAssetID: UUID;
  enabledUnits: Unit[];
  importEstimateErrors: ImportEstimateError[];
  milestone: GetMilestoneQuery['milestone'];
  milestoneCostReports: MilestoneCostReports;
  refetch: () => void;
  refetchMilestone: () => void;
  refetchMilestoneCostReports: MilestoneCostReportsQueryResult['refetch'];
};

const EstimateImport: FC<EstimateImportProps> = ({
  draftEstimate,
  estimateType,
  canPublishDraftEstimate,
  canViewProjectCategories,
  classes,
  error,
  estimateAssetID,
  enabledUnits,
  importEstimateErrors,
  milestone,
  milestoneCostReports,
  refetch,
  refetchMilestone,
  refetchMilestoneCostReports,
}) => {
  const navigate = useNavigate();
  // Read our project and milestone ids from the URL to populate queries
  const t = useContext(ProjectTermStore);
  const { milestoneId, projectId } = useParams();
  if (!projectId || !milestoneId) {
    throw new Error('Project ID or Milestone ID not found');
  }

  const isEstimateImport = estimateType === EstimateType.ACTIVE_ESTIMATE;
  const estimateHandle = isEstimateImport
    ? t.titleCase(TermKey.ESTIMATE)
    : t.titleCase(TermKey.TARGET);
  const editTitle = `Edit ${estimateHandle}`;
  const [filterKey, setFilterKey] = useState(editTitle);

  const sendAnalytics = useSendAnalytics();

  const fields = draftEstimate?.fields ?? [];
  const categorizations = useMemoWrapper(computeCategorizations, fields);

  const projectTotal = useMemoWrapper(
    computeDraftEstimateTotal,
    milestoneCostReports,
    true,
    estimateType
  );
  const costOfConstruction = useMemoWrapper(
    computeDraftEstimateTotal,
    milestoneCostReports,
    false,
    estimateType
  );
  const header = <EstimateTotalHeader />;

  const nonNullableCategorizations = categorizations.filter(isNonNullable);

  // LOCAL "STATE" SETUP from URL and localStorage per milestone
  const milestoneName = (milestone || {}).name || '';
  const page = 'import';
  const {
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    headerDescriptions,
    filterManager,
    setSetting,
    setSettings,
    settings,
  } = useCostReportParams(
    nonNullableCategorizations,
    nonNullableCategorizations,
    milestoneName,
    page,
    enabledUnits,
    `Milestone ${milestoneId} Import `
  );
  const { status, viewMode } = settings;
  const { filterQueryInput: viewFilter, clearFilters } = filterManager;

  useDeepCompareEffect(() => {
    refetchMilestoneCostReports({ viewFilter });
  }, [viewFilter]);

  const isEdit = filterKey !== PREVIEW_MSR;
  const gridVariant = getGridVariantFromEstimateType(estimateType);
  const innerContentEdit = gridVariant ? (
    <div className={classes.editContainer} style={{ display: !isEdit ? 'none' : undefined }}>
      <CostEstimate
        activeEstimateID={draftEstimate.id}
        canViewDetails
        clearFilters={clearFilters}
        costOfConstruction={costOfConstruction}
        errors={importEstimateErrors}
        projectID={projectId}
        refetchOuter={() => {
          refetch();
          refetchMilestoneCostReports();
        }}
        sendAnalytics={sendAnalytics}
        subtotals={draftEstimate}
        variant={gridVariant}
        viewFilter={viewFilter}
      />
      <EstimateTotal
        boldTopBorder
        color={isEstimateImport ? 'Grey' : 'Blue'}
        cost={{ value: projectTotal }}
        header={header}
        topBorder
      />
    </div>
  ) : null;
  const innerContentView = !isEdit && ( // reload to get fresh
    <MilestoneCostReport
      categorizations={nonNullableCategorizations}
      displayGroupBy={displayGroupBy}
      error={error}
      filterManager={filterManager}
      headerDescriptions={headerDescriptions}
      milestoneDescriptions={displayColumnDescriptions}
      scroll={false}
      setIsLoading={() => {}}
      setSettings={setSettings}
      settings={settings}
    />
  );

  const issuesComponent = (
    <IssuesSelect
      issues={importEstimateErrors}
      onToColumn={(id: UUID) => {
        const isEdit = filterKey === EDIT_ESTIMATE;
        if (!isEdit) setFilterKey(EDIT_ESTIMATE);
        const urlState = getURLState(window.location, 'import');
        serializeToURL(navigate, window.location, 'import', {
          ...urlState,
          [CATEGORIZATION_ID]: id,
        });
        sendAnalytics(analyticsEvent(GO_TO_COLUMN_CTA));
      }}
    />
  );

  const settingsComponent = (
    <CostReportSettingsPanel
      canViewProjectCategories={canViewProjectCategories}
      categorizations={nonNullableCategorizations}
      columnDescriptions={columnDescriptions}
      displayColumnDescriptions={displayColumnDescriptions}
      displayGroupBy={displayGroupBy}
      filterManager={filterManager}
      milestoneID={milestoneId}
      page={page}
      setSetting={setSetting}
      settings={settings}
      status={status}
      statusDisabled
      viewGroupByColumnsDisabled={filterKey !== PREVIEW_MSR}
      viewMode={viewMode}
    />
  );

  const importEstimateHeader = (
    <ImportEstimateHeader
      canPublishDraftEstimate={canPublishDraftEstimate}
      draftEstimate={draftEstimate}
      editTitle={editTitle}
      estimateAssetID={estimateAssetID}
      estimateHandle={estimateHandle}
      filterKey={filterKey}
      issuesComponent={issuesComponent}
      milestone={milestone}
      refetchMilestone={refetchMilestone}
      setFilterKey={setFilterKey}
      settingsComponent={settingsComponent}
    />
  );

  if (milestoneId === null || error) {
    return (
      <div>
        <Typography className={classes.empty}>Something went wrong. Please reload.</Typography>
      </div>
    );
  }

  const content = (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0} square>
        {importEstimateHeader}
        <Divider />
        {innerContentView}
        {innerContentEdit}
      </Card>
    </div>
  );

  return <div>{content}</div>;
};

export default withStyles(styles)(EstimateImport);
