import { FC, useEffect, useState } from 'react';

import { LinearProgress, Typography } from '@material-ui/core';
import { Launch } from '@material-ui/icons';

import { PermissionResource } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { pluralizeCountString } from '../../../../utilities/string';
import DialogUnits from '../../../Dialogs/DialogsUnits/DialogsUnits';
import { Button } from '../../../scales';
import CTALink from '../../../shared-widgets/CTALink';
import { formatUnitText } from '../../../VarianceReport/VarianceModals/VarianceModalUnits/VarianceModalUnitsUtils';
import useCreateQuantity from '../../hooks/CreateQuantityHook';
import { useSortedUnits } from '../../hooks/UnitHooks';
import useMilestoneQuantitiesQuery from '../../hooks/useMilestoneQuantitiesQuery';

import MilestoneDetailsQuantitiesCollapse from './MilestoneDetailsQuantitiesCollapse/MilestoneDetailsQuantitiesCollapse';
import MilestoneDetailsQuantitiesStyles from './MilestoneDetailsQuantitiesStyles';
import { QuantityListItem } from './MilestoneDetailsQuantitiesUtils';

type MilestoneDetailsQuantitiesProps = {
  classes: Classes<typeof MilestoneDetailsQuantitiesStyles>;
  milestoneID: UUID;
  projectID: UUID;
};

export const getMagnitudeCount = (quantities?: Quantity[]) =>
  (quantities && quantities.filter((quantity) => !!quantity.magnitude).length) || 0;

const MilestoneDetailsQuantities: FC<MilestoneDetailsQuantitiesProps> = ({
  classes,
  milestoneID,
  projectID,
}) => {
  // Operational state
  const createQuantity = useCreateQuantity();

  const { canView, canEdit } = usePermissions();
  const canEditProjectUnits = canEdit(PermissionResource.PROJECT_UNITS);
  const canViewMetrics = canView(PermissionResource.METRICS);
  const canEditMetrics = canEdit(PermissionResource.METRICS);

  const [quantitiesLocal, setQuantitiesLocal] = useState<QuantityListItem[]>([]);
  const [manageUnitsDialogOpen, setUnitsDialogOpen] = useState<boolean>(false);
  const [openUnit, setOpenUnit] = useState<UUID | null>(null);

  // UNITS
  const { units, unitsLoading } = useSortedUnits(true);
  // QUANTITY
  const { data: { quantities = [] } = {}, loading: quantitiesLoading } =
    useMilestoneQuantitiesQuery(milestoneID);

  const unitsCount = units.length;
  const quantitiesCount = quantitiesLocal.length;
  const magnitudeCount = getMagnitudeCount(quantities);

  useEffect(() => {
    // We are going to use this on start, and when units are added or removed...
    // otherwise, the field values will be managed locally (uncontrolled)
    if (!quantitiesLoading) {
      setQuantitiesLocal(
        units.map((unit: Unit) => {
          const matchedQuantity = quantities.find(
            (quantity: Quantity) => quantity.unit.id === unit.id
          );

          const { abbreviationPlural, abbreviationSingular, id: unitID, name: unitName } = unit;
          const placeholder = canEditProjectUnits
            ? `Set milestone ${abbreviationPlural}`
            : `${unit.name} not yet set`;

          return {
            abbreviationPlural,
            abbreviationSingular,
            cy: abbreviationPlural,
            estimateID: matchedQuantity?.estimateID,
            hasBreakdown: !!matchedQuantity?.hasBreakdown,
            magnitude: matchedQuantity?.magnitude,
            milestoneID,
            name: formatUnitText(unit.name, unit.abbreviationPlural),
            placeholder,
            quantityID: matchedQuantity?.id,
            unitID,
            unitName,
          };
        })
      );
    }
  }, [
    unitsCount,
    quantitiesCount,
    quantities,
    canEditProjectUnits,
    quantitiesLoading,
    units,
    milestoneID,
  ]);

  const getSetMagnitude = (unitID: UUID) => (magnitude: number) => {
    const idx = quantitiesLocal.findIndex((quantity) => quantity.unitID === unitID);
    if (idx >= 0) {
      const stateCopy = quantitiesLocal.slice();
      stateCopy[idx] = { ...quantitiesLocal[idx], magnitude };
      setQuantitiesLocal(stateCopy);
    }
  };

  // We need this info to load our editting UI
  if (!projectID) return null;

  const nullContent = () => {
    if (unitsLoading || quantitiesLoading) return <LinearProgress />;
    if (!canEditProjectUnits) {
      return (
        <Typography className={classes.message}>
          Contact a project administrator to enable units of measure
        </Typography>
      );
    }
    return (
      <div className={classes.message}>
        <CTALink
          linkText="Enable units of measure to add them to the milestone."
          onClick={() => setUnitsDialogOpen(true)}
        />
      </div>
    );
  };

  const getSubmitFunc = (unitID: UUID) => () => {
    const entry = quantitiesLocal.find((quantity) => quantity.unitID === unitID);
    if (entry?.magnitude !== undefined) {
      createQuantity(projectID, milestoneID, entry.magnitude, unitID);
    }
  };

  const header = (
    <div className={classes.unitHeader}>
      <div className={classes.unitHeaderTitle}>
        <Typography className={classes.unitHeaderText}>Metric Name</Typography>
      </div>
      <div className={classes.unitHeaderRight}>
        <div className={classes.unitHeaderReport}>
          <Typography className={classes.unitHeaderText}>Report(s)</Typography>
        </div>
        <div className={classes.unitHeaderCategorizations}>
          <Typography className={classes.unitHeaderText}>Categorization</Typography>
        </div>
        <div className={classes.unitHeaderQuantity}>
          <Typography className={classes.unitHeaderText}>Total Quantity</Typography>
        </div>
        <div className={classes.unitHeaderUnit}>
          <Typography className={classes.unitHeaderText}>Unit</Typography>
        </div>
      </div>
    </div>
  );

  const projectMetrics =
    !unitsLoading &&
    quantitiesLocal.map((quantityListItem: QuantityListItem) => {
      return (
        <MilestoneDetailsQuantitiesCollapse
          key={`${quantityListItem.unitID}:${quantityListItem.quantityID}`}
          canEditMetrics={canEditMetrics}
          canViewMetrics={canViewMetrics}
          getSetMagnitude={getSetMagnitude}
          getSubmitFunc={getSubmitFunc}
          openUnit={openUnit}
          quantityListItem={quantityListItem}
          setOpenUnit={setOpenUnit}
        />
      );
    });

  return (
    <div className={classes.container}>
      {manageUnitsDialogOpen && (
        <DialogUnits onClose={() => setUnitsDialogOpen(false)} open={manageUnitsDialogOpen} />
      )}
      <div className={classes.header}>
        <Typography data-cy="text-trackingMetrics">
          {!unitsLoading &&
            `Tracking ${magnitudeCount} of ${pluralizeCountString('metric', quantitiesCount)}`}
        </Typography>
        <a
          className={classes.link}
          href="https://success.join.build/en/knowledge/units-of-measure"
          rel="noreferrer"
          target="_blank"
        >
          <Typography>What are Metrics used for?</Typography>
          <Launch className={classes.linkIcon} />
        </a>
        <div className={classes.spacer} />
        {canEditProjectUnits && (
          <Button
            data-cy="manage-units-of-measure-button"
            label="Manage Units of Measure"
            onClick={() => setUnitsDialogOpen(true)}
            type="primary"
          />
        )}
      </div>
      {!unitsCount ? (
        <div className={classes.nullEstimateContainer}>{nullContent()}</div>
      ) : (
        <div>
          {header}
          {projectMetrics}
        </div>
      )}
    </div>
  );
};

export default withStyles(MilestoneDetailsQuantitiesStyles)(MilestoneDetailsQuantities);
