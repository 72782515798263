import { useParams } from 'react-router-dom';

import { RouteKeys } from '../routes/paths';
import { generateSharedPath } from '../utilities/routes/links';

import { TextLink } from './scales';

export default function PageHasMoved() {
  const { projectId } = useParams();

  return (
    <div className="flex items-center justify-center text-type-primary">
      <img
        alt=""
        // negative margin because the logos have a bunch of whitespace on the
        // left side of them
        className="-ml-40 h-100 w-100 object-cover"
        src={`/img/logos/${Math.floor(Math.random() * (9 - 1 + 1)) + 1}.png`}
      />
      <div className="flex flex-col gap-2">
        <div>
          <p className="type-heading1">Oops!</p>
          <p className="type-heading2">
            We&apos;ve recently changed some of our URLs - please update your links and bookmarks.
          </p>
        </div>
        {projectId ? (
          <TextLink
            label="Go to project"
            to={generateSharedPath(RouteKeys.PROJECT, { projectId })}
          />
        ) : (
          <TextLink
            label="Go to projects list"
            to={generateSharedPath(RouteKeys.SEARCH_PROJECTS)}
          />
        )}
      </div>
    </div>
  );
}
