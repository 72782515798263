import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserStatus } from '../api/gqlEnumsBe';
import { SelectEntry } from '../components/scales';
import { ALL_COLLABORATORS, INVITED_COLLABORATOR } from '../constants';
import { Role } from '../generated/graphql';

import useRolesQuery from './useRolesQuery';

export const useFilterCollaborators = (
  collaborators: Collaborator[]
): {
  entries: SelectEntry[];
  filteredCollaborators: Collaborator[];
  setFilterKey: (filterKey: string) => void;
  filterKey: string;
} => {
  const [filterKey, setFilterKey] = useState(ALL_COLLABORATORS);

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const roles = useRolesQuery(projectId).data?.projectRoles ?? [];
  const roleNames = roles.map((r: Role) => r.name);
  const entries = getEntries(collaborators.length, roleNames);

  const filteredCollaborators = useMemo(() => {
    if (filterKey === ALL_COLLABORATORS) {
      return collaborators;
    }
    if (filterKey === INVITED_COLLABORATOR) {
      return collaborators.filter((c: Collaborator) => c.user.status === UserStatus.PENDING);
    }
    return collaborators.filter((c: Collaborator) => c.role.name === filterKey);
  }, [collaborators, filterKey]);

  return {
    entries,
    filteredCollaborators,
    setFilterKey,
    filterKey,
  };
};

export const getEntries = (collaboratorsLength: number, roles: string[]): SelectEntry[] => {
  const allData = {
    id: ALL_COLLABORATORS,
    label: ALL_COLLABORATORS,
  };

  const roleData = roles.map((role: string) => ({
    id: role,
    label: role,
  }));

  const pendingData = {
    id: INVITED_COLLABORATOR,
    label: INVITED_COLLABORATOR,
  };

  return collaboratorsLength > 0 ? [allData, pendingData, ...roleData] : [];
};
