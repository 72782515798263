import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';
import { Divider } from '@material-ui/core';

import { currentUserReportVar } from '../../../../api/apollo/reactiveVars';
import { HIDE, SORT_ITEM_NUMBERS, UNGROUPED } from '../../../../constants';
import {
  MarkupMode,
  MilestoneEstimateInfo,
  PermissionResource,
} from '../../../../generated/graphql';
import { MILESTONE_REPORT_TREE } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { useCostMode } from '../../../../utilities/costMode';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { sortItemsBy } from '../../../../utilities/sorting';
import { SetSettingsFunctionType, useSetCollapse } from '../../../../utilities/urlState';
import { useGetProjectUnitsQuery } from '../../../Milestone/hooks/UnitHooks';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { VarianceReportComments } from '../../../ReportsTab/ReportHooks';
import { isDefaultReport } from '../../../ReportsTab/ReportsManagerMenu/utils';
import useMemoWrapper from '../../../useMemoWrapper';
import { getIsTotalIncluded } from '../../../VarianceReport/VarianceReportUtils';
import ZeroState from '../../../ZeroState/ZeroState';
import {
  ColumnDescription,
  VarianceColumnDescription,
  getColumnTermFromColumnKey,
  getFnFromColumnKey,
} from '../../CostReportColumns/CostReportColumns';
import CostReportMarkupsSection from '../../CostReportMarkup/CostReportMarkupsSection';
import {
  useMarkupTrees,
  useMilestonesMarkupsQuery,
} from '../../CostReportMarkup/CostReportMarkupsSectionUtils';
import { getListHeaderHeight } from '../../CostReportUtils';
import CostReportCategoriesTree, {
  CostReportCategoriesTreeNode,
  NodeData,
} from '../CostReportCategoriesTree';
import CostReportListHeader from '../CostReportListHeader/CostReportListHeader';
import { getCostReportListHeaderHeight } from '../CostReportListHeader/CostReportListHeaderStyles';
import CostReportListRow from '../CostReportListRow/CostReportListRow';
import { RowVariants } from '../CostReportListRow/CostReportListRowUtils';

import { styles } from './CostReportListStyles';
import {
  CalcSubtotalsData,
  calcSubtotals,
  currentNode,
  getDisplayedNodes,
  hasUserReportComments,
} from './CostReportListUtils';

type CostReportListProps = {
  breakpoints?: number[];
  classes: Classes<typeof styles>;
  displayColumnDescriptions: ColumnDescription[] | VarianceColumnDescription[];
  groupBys: DisplayGroupBy[];
  header?: JSX.Element;
  headerDescriptions: ColumnDescription[] | VarianceColumnDescription[];
  isVariance?: boolean;
  itemStatuses: string[];
  costReports: MilestoneCostReports | VarianceReports;
  milestoneID?: UUID;
  reportAnalyticsExpandCollapse?: (collapse: boolean) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  scroll?: boolean;
  setIsLoading?: (loading: boolean) => void;
  setNavigationData?: (itemsNodes: NodeData[]) => void;
  setSettings?: SetSettingsFunctionType;
  settings: CostReportSettings;
  viewFilter: ViewFilterInput;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  reportComments?: VarianceReportComments;
  milestoneEstimates: MilestoneEstimateInfo[];
};

const CostReportList: FC<CostReportListProps> = ({
  breakpoints = [],
  classes,
  costReports,
  displayColumnDescriptions: inputDisplayColumnDescriptions,
  groupBys,
  header,
  headerDescriptions: inputHeaderDescriptions,
  isVariance = false,
  itemStatuses,
  loading = false,
  reportAnalyticsExpandCollapse = null,
  scroll,
  setIsLoading = () => {},
  setNavigationData = () => {},
  setSettings = () => {},
  settings,
  viewFilter,
  milestoneID,
  reportComments,
  milestoneEstimates,
}) => {
  const t = useContext(ProjectTermStore);
  const [loadedRows, setLoadedRows] = useState(0);
  const incrementRows = () => {
    setLoadedRows(loadedRows + 1);
  };

  const hideEstimateLines = settings.estimateLines === HIDE;
  const zeroVarianceSetting = 'zeroVariance' in settings ? settings.zeroVariance : undefined;
  const hideZeroVariance = zeroVarianceSetting === HIDE;

  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const costMode = useCostMode();

  const milestoneMarkups = useMilestonesMarkupsQuery(projectId)?.data?.milestonesMarkups ?? [];

  const { canDelete, canView } = usePermissions();
  const canViewDirectCosts = canView(PermissionResource.NO_MARKUPS_VIEW);
  const canViewMarkups = canView(PermissionResource.MARKUPS);
  const canDeleteUserReportComment = canDelete(PermissionResource.USER_REPORT_NOTES);

  const isPrint = !reportAnalyticsExpandCollapse; // When we don't have an active expand / collapse

  const { data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] } } =
    useGetProjectUnitsQuery(projectId, true);
  const isTotalIncluded = isVariance ? getIsTotalIncluded(settings, enabledUnits) : false;

  const currentReport = useReactiveVar(currentUserReportVar);
  // if we're printing a saved report, we can only get the report ID from the URL
  const { reportID } = useParams();
  const currentReportID = currentReport?.id || reportID;
  const canExpandVarianceReportNotesColumn = !!currentReportID && !isDefaultReport(currentReportID);
  const groupByIDs: UUID[] = groupBys.map((g) => g.id);

  const [isNotesColumnExpanded, setNotesColumnExpanded] = useState(isPrint);
  const [isAddingNoteInReport, setAddingNoteInReport] = useState(false);
  const notesHeaderRef = useRef<HTMLDivElement>(null);
  const expandNotesColumn = (isExpanded: boolean) => {
    setNotesColumnExpanded(isExpanded);
    if (isExpanded) {
      // need to wait a little bit before scrolling so the column width can update
      setTimeout(() => {
        notesHeaderRef.current?.scrollIntoView({ block: 'nearest', inline: 'end' });
      }, 5);
    }
  };

  const {
    acceptedSubtotalData,
    afterMarkupsSubtotalData,
    afterOwnerCostsSubtotalData,
    categorizedSubtotalsTree,
    columnKeyQuantityMap,
    directCostSubtotalData,
    markupSubtotals,
    markupSummaryData,
    contingencySummaryData,
    allowanceSummaryData,
    ownerCostSummaryData,
    runningTotalData,
    includesIncorporatedMarkups,
  }: CalcSubtotalsData = useMemoWrapper(
    calcSubtotals,
    t,
    costReports,
    viewFilter,
    isVariance,
    hideZeroVariance,
    isTotalIncluded,
    hideEstimateLines,
    itemStatuses,
    reportComments
  );
  const hasComments = hasUserReportComments(reportComments);

  const { markupsTree, contingencyTree, allowanceTree, ownerCostTree } = useMemoWrapper(
    useMarkupTrees,
    markupSummaryData,
    contingencySummaryData,
    allowanceSummaryData,
    ownerCostSummaryData,
    markupSubtotals,
    t,
    canViewMarkups,
    hideZeroVariance,
    includesIncorporatedMarkups,
    milestoneMarkups,
    // we don't want to limit markups to the current milestone in the Variance Report (only the MSR)
    isVariance ? undefined : milestoneID,
    reportComments?.markupComments
  );

  const categorizedSubtotals =
    categorizedSubtotalsTree &&
    !!categorizedSubtotalsTree.root.branches.length &&
    sortItemsBy[SORT_ITEM_NUMBERS](categorizedSubtotalsTree.root.branches);

  const showDirectCostSubtotalRow =
    canViewDirectCosts && costMode.markupMode !== MarkupMode.ALLOCATED_MARKUPS;
  const showSubtotalRow = costMode.markupMode !== MarkupMode.NO_MARKUPS;

  const showMarkupSubtotalRows = canViewMarkups && !!markupsTree.nodeCount;
  const showContingencySubtotalRows = canViewMarkups && !!contingencyTree.nodeCount;
  const showAllowanceSubtotalRows = canViewMarkups && !!allowanceTree.nodeCount;
  const showOwnerCostSubtotalRows = canViewMarkups && !!ownerCostTree.nodeCount;

  const directCostSubtotalTreeNode = {
    data: directCostSubtotalData,
  } as CostReportCategoriesTreeNode;
  const afterMarkupsSubtotalTreeNode = {
    data: afterMarkupsSubtotalData,
  } as CostReportCategoriesTreeNode;
  const afterOwnerCostsTreeNode = {
    data: afterOwnerCostsSubtotalData,
  } as CostReportCategoriesTreeNode;
  const acceptedTreeNode = { data: acceptedSubtotalData } as CostReportCategoriesTreeNode;
  const runningTotalTreeNode = { data: runningTotalData } as CostReportCategoriesTreeNode;

  const rangeColumnKeys = directCostSubtotalData.columns.flatMap(({ columnKey, subtotal }) => {
    const cost = getFnFromColumnKey(columnKey)(subtotal, true);
    return typeof cost === 'object' &&
      (('min' in cost && 'max' in cost) ||
        ('adds' in cost &&
          'deducts' in cost &&
          cost.adds !== undefined &&
          cost.deducts !== undefined))
      ? [columnKey]
      : [];
  });
  const isColumnWide = (
    key: CostReportColumnKey | undefined,
    isDescriptionWide: boolean | undefined
  ) =>
    key &&
    (isVariance
      ? !!isDescriptionWide
      : (!!isDescriptionWide && rangeColumnKeys.includes(key)) ||
        (getColumnTermFromColumnKey(t, key)?.length ?? 0) > 20);

  const displayColumnDescriptions = inputDisplayColumnDescriptions.map((description) => ({
    ...description,
    isWide: isColumnWide(description?.columnKey, description?.isWide),
  }));
  const headerDescriptions = inputHeaderDescriptions.map((description) => ({
    ...description,
    isWide: isColumnWide(description?.parentColumnKey, description?.isWide),
  }));

  useEffect(() => {
    // if there are no groupBys then the number of rows will be zero
    const hasLoadedRowsOrNoGroupBys = loadedRows > 0 || !groupByIDs.length;
    if (hasLoadedRowsOrNoGroupBys && !loading) {
      setIsLoading(false);
      setNavigationData(currentNode(categorizedSubtotals, []));
    } else {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorizedSubtotalsTree.nodeCount, loadedRows]);

  const setCollapse = useSetCollapse(
    settings,
    setSettings,
    reportAnalyticsExpandCollapse ?? (() => {})
  );
  const anyCollapsed = !!settings?.collapse?.length;

  // Header transformations
  const [, zoomLevel] = breakpoints;
  const transformListHeader = (zoomLevel && `scaleY(${zoomLevel})`) || '';
  // scale dividers back up to fit full-screen after resize...
  const transform = (zoomLevel && `scaleX(${1 / zoomLevel})`) || '';
  const transformOrigin = 'left';
  // Each page can have the header, and the descriptions - TODO: his also happens at Print level...
  const headerHeight = getCostReportListHeaderHeight(isVariance, settings);
  const listHeaderHeight = getListHeaderHeight(zoomLevel, headerHeight);
  const listHeaderOrigin = 'top';

  const costReportListHeader = (
    <CostReportListHeader
      anyCollapsed={anyCollapsed}
      canExpandVarianceReportNotesColumn={canExpandVarianceReportNotesColumn}
      displayColumnDescriptions={displayColumnDescriptions}
      hasComments={hasComments}
      headerDescriptions={headerDescriptions}
      isNotesColumnExpanded={isNotesColumnExpanded}
      isPrint={isPrint}
      isVariance={isVariance}
      notesHeaderRef={notesHeaderRef}
      scroll={scroll}
      setAllExpanded={(bool: boolean) => {
        reportAnalyticsExpandCollapse?.(bool);
        const expand: string[] = [];
        setSettings({ collapse: bool ? [] : [UNGROUPED], expand });
      }}
      setNotesColumnExpanded={expandNotesColumn}
      settings={settings}
    />
  );
  const costReportListPrintHeader = (
    <div
      style={{
        height: listHeaderHeight,
        transform: transformListHeader,
        transformOrigin: listHeaderOrigin,
      }}
    >
      {costReportListHeader}
    </div>
  );
  const firstPageHeader = isPrint ? costReportListPrintHeader : costReportListHeader;
  const printPageHeaders = (
    <>
      <div style={{ transformOrigin: 'top left', transform }}>
        {groupByIDs.length > 0 && <div className={classes.pageBreakLine} />}
        {header}
      </div>
      {costReportListPrintHeader}
    </>
  );

  const displayNodes = isPrint ? getDisplayedNodes(categorizedSubtotals, settings) : undefined;

  const getMarkupSection = (tree: CostReportCategoriesTree) => (
    <>
      <Divider className={classes.divider} style={{ transformOrigin, transform }} />
      <CostReportMarkupsSection
        breakpoints={breakpoints}
        canDeleteUserReportComment={canDeleteUserReportComment}
        canViewMarkups={showMarkupSubtotalRows}
        columnKeyQuantityMap={columnKeyQuantityMap}
        currentReportID={currentReportID}
        displayColumnDescriptions={displayColumnDescriptions}
        groupByIDs={groupByIDs}
        hasComments={hasComments}
        headerDescriptions={headerDescriptions}
        isAddingNoteInReport={isAddingNoteInReport}
        isCurrentReportShared={currentReport?.shared}
        isNotesColumnExpanded={isNotesColumnExpanded}
        isPrint={isPrint}
        isTotalIncluded={isTotalIncluded}
        isVariance={isVariance}
        markupsTree={tree}
        milestoneEstimates={milestoneEstimates}
        reportAnalyticsExpandCollapse={reportAnalyticsExpandCollapse || (() => {})}
        scroll={scroll}
        setAddingNoteInReport={setAddingNoteInReport}
        setCollapse={setCollapse}
        setNotesColumnExpanded={expandNotesColumn}
        settings={settings}
      />
    </>
  );

  const subtotals = (
    <div>
      {showDirectCostSubtotalRow && (
        <>
          {groupBys.length > 0 && (
            <div>
              <Divider className={classes.divider} style={{ transformOrigin, transform }} />{' '}
            </div>
          )}
          <CostReportListRow
            breakpoints={breakpoints}
            canDeleteUserReportComment={canDeleteUserReportComment}
            columnKeyQuantityMap={columnKeyQuantityMap}
            currentReportID={currentReportID}
            displayColumnDescriptions={displayColumnDescriptions}
            groupByIDs={groupByIDs}
            hasComments={hasComments}
            headerDescriptions={headerDescriptions}
            headers={printPageHeaders}
            incrementRows={incrementRows}
            isAddingNoteInReport={isAddingNoteInReport}
            isCurrentReportShared={currentReport?.shared}
            isNotesColumnExpanded={isNotesColumnExpanded}
            isPrint={isPrint}
            isTotalIncluded={isTotalIncluded}
            isVariance={isVariance}
            milestoneEstimates={milestoneEstimates}
            scroll={scroll}
            setAddingNoteInReport={setAddingNoteInReport}
            setCollapse={setCollapse}
            setNotesColumnExpanded={expandNotesColumn}
            settings={settings}
            treeNode={directCostSubtotalTreeNode}
            useDirectCost
            variant={RowVariants.SUBTOTAL}
          />
        </>
      )}
      {showMarkupSubtotalRows && getMarkupSection(markupsTree)}
      {showContingencySubtotalRows && getMarkupSection(contingencyTree)}
      {showAllowanceSubtotalRows && getMarkupSection(allowanceTree)}

      {showSubtotalRow && (
        <>
          <Divider className={classes.divider} style={{ transformOrigin, transform }} />
          <CostReportListRow
            breakpoints={breakpoints}
            canDeleteUserReportComment={canDeleteUserReportComment}
            columnKeyQuantityMap={columnKeyQuantityMap}
            currentReportID={currentReportID}
            displayColumnDescriptions={displayColumnDescriptions}
            groupByIDs={groupByIDs}
            hasComments={hasComments}
            headerDescriptions={headerDescriptions}
            headers={printPageHeaders}
            isAddingNoteInReport={isAddingNoteInReport}
            isCurrentReportShared={currentReport?.shared}
            isNotesColumnExpanded={isNotesColumnExpanded}
            isPrint={isPrint}
            isTotalIncluded={isTotalIncluded}
            isVariance={isVariance}
            milestoneEstimates={milestoneEstimates}
            scroll={scroll}
            setAddingNoteInReport={setAddingNoteInReport}
            setNotesColumnExpanded={expandNotesColumn}
            settings={settings}
            treeNode={afterMarkupsSubtotalTreeNode}
            variant={RowVariants.SUBTOTAL}
          />
        </>
      )}

      {showOwnerCostSubtotalRows && getMarkupSection(ownerCostTree)}
      {showOwnerCostSubtotalRows && showSubtotalRow && (
        <>
          <Divider className={classes.divider} style={{ transformOrigin, transform }} />
          <CostReportListRow
            breakpoints={breakpoints}
            canDeleteUserReportComment={canDeleteUserReportComment}
            columnKeyQuantityMap={columnKeyQuantityMap}
            currentReportID={currentReportID}
            displayColumnDescriptions={displayColumnDescriptions}
            groupByIDs={groupByIDs}
            hasComments={hasComments}
            headerDescriptions={headerDescriptions}
            headers={printPageHeaders}
            isAddingNoteInReport={isAddingNoteInReport}
            isCurrentReportShared={currentReport?.shared}
            isNotesColumnExpanded={isNotesColumnExpanded}
            isPrint={isPrint}
            isTotalIncluded={isTotalIncluded}
            isVariance={isVariance}
            milestoneEstimates={milestoneEstimates}
            scroll={scroll}
            setAddingNoteInReport={setAddingNoteInReport}
            setNotesColumnExpanded={expandNotesColumn}
            settings={settings}
            treeNode={afterOwnerCostsTreeNode}
            variant={RowVariants.SUBTOTAL}
          />
        </>
      )}

      {!isVariance && (
        <>
          <CostReportListRow
            breakpoints={breakpoints}
            canDeleteUserReportComment={canDeleteUserReportComment}
            columnKeyQuantityMap={columnKeyQuantityMap}
            displayColumnDescriptions={displayColumnDescriptions}
            hasComments={hasComments}
            headerDescriptions={headerDescriptions}
            headers={printPageHeaders}
            isPrint={isPrint}
            isTotalIncluded={isTotalIncluded}
            milestoneEstimates={milestoneEstimates}
            scroll={scroll}
            settings={settings}
            treeNode={acceptedTreeNode}
            variant={RowVariants.ACCEPTED}
          />
          <Divider className={classes.divider} style={{ transformOrigin, transform }} />
          <CostReportListRow
            breakpoints={breakpoints}
            canDeleteUserReportComment={canDeleteUserReportComment}
            columnKeyQuantityMap={columnKeyQuantityMap}
            displayColumnDescriptions={displayColumnDescriptions}
            hasComments={hasComments}
            headerDescriptions={headerDescriptions}
            headers={printPageHeaders}
            isPrint={isPrint}
            isTotalIncluded={isTotalIncluded}
            milestoneEstimates={milestoneEstimates}
            scroll={scroll}
            settings={settings}
            treeNode={runningTotalTreeNode}
            variant={RowVariants.RUNNINGTOTAL}
          />
        </>
      )}
    </div>
  );

  const costReportBody = (
    <>
      {categorizedSubtotals && (
        <div data-cy={MILESTONE_REPORT_TREE}>
          {categorizedSubtotals.map((categorizedSubtotalTreeNode: CostReportCategoriesTreeNode) => (
            <CostReportListRow
              key={JSON.stringify(categorizedSubtotalTreeNode.data)}
              breakpoints={breakpoints}
              canDeleteUserReportComment={canDeleteUserReportComment}
              columnKeyQuantityMap={columnKeyQuantityMap}
              currentReportID={currentReportID}
              displayColumnDescriptions={displayColumnDescriptions}
              displayNodes={displayNodes}
              endFlags={[]}
              groupByIDs={groupByIDs}
              hasComments={hasComments}
              headerDescriptions={headerDescriptions}
              headers={printPageHeaders}
              incrementRows={incrementRows}
              isAddingNoteInReport={isAddingNoteInReport}
              isCurrentReportShared={currentReport?.shared}
              isNotesColumnExpanded={isNotesColumnExpanded}
              isPrint={isPrint}
              isTotalIncluded={isTotalIncluded}
              isVariance={isVariance}
              milestoneEstimates={milestoneEstimates}
              scroll={scroll}
              setAddingNoteInReport={setAddingNoteInReport}
              setCollapse={setCollapse}
              setNotesColumnExpanded={expandNotesColumn}
              settings={settings}
              treeNode={categorizedSubtotalTreeNode}
            />
          ))}
        </div>
      )}
      {isPrint && printPageHeaders}
      {subtotals}
    </>
  );

  return (
    <div className={classes.root}>
      {groupByIDs.length > 0 && <div>{firstPageHeader}</div>}
      {loading ? <ZeroState loading /> : costReportBody}
    </div>
  );
};

export default withStyles(styles)(CostReportList);
