import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    containerOuter: {
      position: 'relative',
      zIndex: 10,
    },
    header: {
      background: theme.palette.backgroundGrey,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    popper: {
      '& *': {
        boxSizing: 'border-box',
      },
      background: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      boxShadow:
        '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      marginTop: 16, // this defines how far offset from the button,
      maxHeight: theme.portal.maxHeight,
      zIndex: 1300,
      width: '400px',
    },
  });
