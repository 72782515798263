import { OrgNode } from '../../../generated/graphql';
import { TreeEntry, collectNodeIDs } from '../../../utilities/utilities';
import useMemoWrapper from '../../useMemoWrapper';

import FilterGroupLevels from './FilterGroupLevels';

export type Props = {
  name: string;
  nodesCurrentOrg: TreeEntry<OrgNode>[];
  onChange: (value: string[]) => void;
  orgID: string;
  selectedEntriesAllOrgs: string[];
};

export default function FilterGroupOrgs(props: Props) {
  const { selectedEntriesIDsCurrentOrg, selectedEntriesIDsOtherOrgs } = useMemoWrapper(
    filterSelectedEntries,
    props.selectedEntriesAllOrgs,
    props.nodesCurrentOrg
  );

  return (
    <FilterGroupLevels
      key={props.orgID}
      data-cy={`organization-FilterGroup-${props.orgID}`}
      entries={props.nodesCurrentOrg}
      isAllSelectable
      isSearchable
      onChange={(values) => {
        props.onChange([...values, ...selectedEntriesIDsOtherOrgs]);
      }}
      searchPlaceholder={`Search ${props.name}`}
      selectedEntries={selectedEntriesIDsCurrentOrg}
      title={props.name}
    />
  );
}

function filterSelectedEntries(
  selectedEntries: string[],
  nodesFromCurrentOrg: TreeEntry<OrgNode>[]
) {
  // Collect all node IDs that belong to the current Organization
  const nodeIDs = collectNodeIDs(nodesFromCurrentOrg);

  // Separate out entries from current Org and from other Orgs
  const selectedEntriesIDsCurrentOrg: string[] = [];
  const selectedEntriesIDsOtherOrgs: string[] = [];

  selectedEntries.forEach((nodeID) => {
    if (nodeIDs.has(nodeID)) {
      selectedEntriesIDsCurrentOrg.push(nodeID);
    } else {
      selectedEntriesIDsOtherOrgs.push(nodeID);
    }
  });

  return { selectedEntriesIDsCurrentOrg, selectedEntriesIDsOtherOrgs };
}
