import * as d3 from 'd3';
import { useMemo } from 'react';

import {
  ACCEPTED,
  PENDING,
  REJECTED,
  TRENDLINE_EVENT_DEFAULT,
  TRENDLINE_EVENT_ESTIMATE,
} from '../../../../constants';
import { formatCost } from '../../../../utilities/currency';
import {
  CHART_RIGHT_PADDING,
  CHART_TOP_PADDING,
} from '../../../Charts/ChartsAllMilestones/ChartsAllMilestonesUtils';
import ChartsCostTrendlineTooltip from '../../../Charts/ChartsCostTrendline/ChartsCostTrendlineTooltip/ChartsCostTrendlineTooltip';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import {
  CHART_HEIGHT,
  calculateTickTotalForDomain,
  getChartLabelWithCurrency,
} from '../../../Charts/ChartsUtils';
import {
  CostTrendlineEventExtended,
  Label,
  LegendLabel,
} from '../../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';
import ChartLegend from '../ChartLegend';
import CostArea from '../TimelineArea';
import TimelineCircle from '../TimelineCircle';
import TimelinePath from '../TimelinePath';

import ChartLabelX from './ChartLabelX';
import ChartLabelY from './ChartLabelY';
import CostFillPattern from './CostFillPattern';
import CostLabel from './CostLabel';
import CostLabelBlur from './CostLabelBlur';
import CostTooltip from './CostTooltip';
import CostXAxis from './CostXAxis';
import CostYAxis from './CostYAxis';
import YMeshLines from './CostYMeshLines';

const LABEL_Y = getChartLabelWithCurrency('Cost');

export type Props = {
  estimate?: number;
  height?: number;
  isPrint?: boolean;
  labels?: Label[];
  legendLabels: LegendLabel[];
  milestoneID: UUID;
  onClick: (v: CostTrendlineEventExtended) => void;
  onHoverEvent: (v: CostTrendlineEventExtended) => void;
  onMouseLeave: () => void;
  target?: number;
  trend?: CostTrendlineEventExtended[];
  xDomain?: Date[];
  yDomain?: number[];
};

export default function ChartsCostTrendline({
  estimate = 0,
  height = CHART_HEIGHT,
  isPrint,
  labels,
  legendLabels,
  milestoneID,
  onClick,
  onHoverEvent,
  onMouseLeave,
  target = 0,
  trend,
  xDomain,
  yDomain,
}: Props) {
  // get the list of visible trendlin events
  const visibleTrends = useMemo(
    () => (trend && trend.length > 0 && trend.filter((t) => t.visible)) || [],
    [trend]
  );
  const hoverMarks = useMemo(
    () =>
      visibleTrends.filter(
        (d) =>
          d.item?.status === ACCEPTED || d.item?.status === PENDING || d.item?.status === REJECTED
      ),
    [visibleTrends]
  );

  const hasVisibleTrendData = visibleTrends && visibleTrends.length > 0;
  const hasVisibleTrendDataAndLabels = hasVisibleTrendData && labels;

  const costFormat = { short: true, showCurrencySymbol: false };

  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = {
    left: labels ? 90 : 32,
    right: labels ? CHART_RIGHT_PADDING : 32,
    bottom: labels ? 60 : 32,
    top: labels ? CHART_TOP_PADDING : 32,
  };

  // x domain
  const xMin = (xDomain ?? [])[0];
  const xMax = (xDomain ?? [])[(xDomain?.length ?? 0) - 1];
  const xMedian = (xDomain ?? [])[Math.round((xDomain?.length ?? 0) / 2)];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scalePoint(xDomain ?? [], xRange);

  // y domain
  const yMin = (yDomain ?? [])[0];
  const yMax = (yDomain ?? [])[1];
  const yAverage = (yMin + yMax) / 2;
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain([yMin, yMax]).range(yRange);

  const data = visibleTrends.map((d) => ({
    date: d.x,
    value: d.cost,
  }));

  return (
    <>
      {labels && <ChartLegend legendLabels={legendLabels} />}
      <div className="mt-[20px]" />
      <SVGWithDimensions ref={ref} data-cy="charts-cost-trendline" dimensions={dimensions}>
        {hasVisibleTrendData && (
          <YMeshLines
            tickTotal={calculateTickTotalForDomain(yDomain, (value) =>
              formatCost(value, costFormat)
            )}
            x={x}
            xDomain={[xMin, xMax]}
            y={y}
          />
        )}
        {hasVisibleTrendDataAndLabels && (
          <CostXAxis
            domain={xDomain as Date[]}
            range={xRange}
            tickTotal={6}
            x={x}
            y={y}
            yPosition={yMin}
          />
        )}
        {hasVisibleTrendDataAndLabels && (
          <CostYAxis
            range={yRange}
            tickFormat={(v: number) => formatCost(v, costFormat)}
            tickTotal={calculateTickTotalForDomain(yDomain, (value) =>
              formatCost(value, costFormat)
            )}
            x={x}
            xPosition={xMin}
            y={y}
          />
        )}
        {target !== 0 && (
          <TimelinePath<{ value: number }>
            className="stroke-entities-milestone"
            data={[
              { date: xMin, value: target },
              { date: xMax, value: target },
            ]}
            x={x}
            y={y}
          />
        )}
        {estimate !== 0 && (
          <TimelinePath<{ value: number }>
            className="stroke-entities-estimate"
            data={[
              { date: xMin, value: estimate },
              { date: xMax, value: estimate },
            ]}
            strokeDasharray="1, 2"
            x={x}
            y={y}
          />
        )}
        {hasVisibleTrendData && (
          <CostArea<{ y0: number; y: number }, { x: Date }>
            data={visibleTrends}
            fill="url(#stripes)"
            stroke="var(--colors-chart-pending-cost-area)"
            strokeWidth={1}
            x={x}
            y={y}
          />
        )}
        {hasVisibleTrendData && (
          <TimelinePath<{ value: number }> data={data} strokeWidth={2} x={x} y={y} />
        )}
        {/* Render the dot for the first and last CostTrendlineEvent */}
        {hasVisibleTrendData &&
          [visibleTrends[0], visibleTrends[visibleTrends.length - 1]].map((d, i) => (
            <TimelineCircle<{ value: number }>
              key={`${+i}-${d.cost}`}
              data={{ date: d.x, value: d.cost }}
              fill="var(--colors-entities-estimate)"
              size={TRENDLINE_EVENT_ESTIMATE + 1}
              x={x}
              y={y}
            />
          ))}
        {hasVisibleTrendData &&
          hoverMarks.map((d, i) => (
            <TimelineCircle<{ value: number }>
              key={`${+i}-${d.yPoint}`}
              data={{ date: d.x, value: d.yPoint }}
              fill={d.color}
              size={TRENDLINE_EVENT_DEFAULT + 1}
              x={x}
              y={y}
            />
          ))}
        {labels && labels.map((l) => <CostLabel key={`${l.x}-${l.label}`} data={l} x={x} y={y} />)}
        {hasVisibleTrendData &&
          !isPrint &&
          hoverMarks.map((d, i) => (
            <CostTooltip
              key={`${+i}-${d.yPoint}`}
              content={
                <ChartsCostTrendlineTooltip
                  costTrendlineEvents={trend}
                  milestoneID={milestoneID}
                  selectedEvent={d}
                />
              }
              data={{ date: d.x, value: d.yPoint }}
              fill={d.color}
              hoverSize={20}
              offset={-8}
              onClick={() => onClick(d)}
              onHover={() => onHoverEvent(d)}
              onLeave={onMouseLeave}
              pointSize={4}
              x={x}
              y={y}
            />
          ))}
        {labels && <ChartLabelX label="Date" x={x} xPosition={xMedian} y={y} yPosition={yMin} />}
        {labels && (
          <ChartLabelY label={LABEL_Y} x={x} xPosition={xMin} y={y} yPosition={yAverage} />
        )}
        <CostFillPattern />
        <CostLabelBlur />
      </SVGWithDimensions>
    </>
  );
}
