import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { UserStatus } from '../../../api/gqlEnumsBe';
import { UserProjectSortKey } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { SortManager, Table } from '../../scales';
import CompanyTabStyles from '../CompanyTabStyles';
import { gridRowHeight } from '../CompanyTabUtils';

const headerContent = [
  {
    copy: 'Name',
    headerSortKey: UserProjectSortKey.SORT_USER_PROJECT_NAME,
    key: 'project name',
  },
  {
    copy: 'Status',
    headerSortKey: UserProjectSortKey.SORT_USER_PROJECT_STATUS,
    key: 'project status',
  },
  {
    copy: 'Type',
    headerSortKey: UserProjectSortKey.SORT_USER_PROJECT_TYPE,
    key: 'project type',
  },
  {
    copy: 'Location',
    headerSortKey: UserProjectSortKey.SORT_USER_PROJECT_LOCATION,
    key: 'project location',
  },
  {
    copy: 'Role',
    headerSortKey: UserProjectSortKey.SORT_USER_PROJECT_ROLE,
    key: 'project role',
  },
];

type ProjectTableProps = {
  classes: Classes<typeof CompanyTabStyles>;
  companyUserDetails: CompanyUserDetails;
  sortManager: SortManager;
  isCollaborator?: boolean;
  page?: CompanyAdminView;
};

const ProjectTable: FC<ProjectTableProps> = ({
  classes,
  companyUserDetails,
  sortManager,
  isCollaborator,
  page,
}) => {
  const sendAnalytics = useSendAnalytics();
  const { companyProjects = [], outsideProjects = [] } = companyUserDetails;

  const getCompanyAnalyticsEventType = (page?: CompanyAdminView) => {
    if (page === CompanyAdminView.MEMBER_DETAILS) {
      return CompanyAdminEventType.MEMBER_PROJECT_LINK;
    }
    if (page === CompanyAdminView.COLLABORATORS_DETAILS) {
      return CompanyAdminEventType.COLLABORATORS_PROJECT_LINK;
    }
    return undefined;
  };

  const entries = useMemo(
    () =>
      [...companyProjects, ...outsideProjects].map((userProject, index) => {
        const { id: key, name, status, type, location, roles, roleID } = userProject;

        if (!isCollaborator && index >= companyProjects.length) {
          return [
            { text: name || ' ', key, disabled: true },
            { text: 'Non-company administrated project', key, disabled: true, span: 4 },
          ];
        }
        const roleName = roles.find((r) => r.id === roleID)?.name ?? '';
        const disabled = companyUserDetails.user.status === UserStatus.DEACTIVATED;

        const link = (
          <Link
            className={`${classes.linkTitle} ${classes.textOverflow}`}
            onClick={() => {
              const analyticsEventType = getCompanyAnalyticsEventType(page);
              if (analyticsEventType)
                sendAnalytics(companyAdminAnalyticsEvent(analyticsEventType, { view: page }));
            }}
            to={generateSharedPath(RouteKeys.PROJECT, { projectId: userProject.id })}
          >
            {name || 'Untitled Project'}
          </Link>
        );

        return [
          { component: link, key: userProject.id },
          { text: status, key, disabled },
          { text: type, key, disabled },
          { text: location, key, disabled },
          { text: roleName, key, disabled },
        ];
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [companyUserDetails]
  );

  const projectCount = companyProjects.length + outsideProjects.length;
  if (projectCount === 0) return null;
  return (
    <div>
      <div>
        <Typography className={classes.titleBold} data-cy="project-count">
          Project permissions ({projectCount})
        </Typography>
      </div>
      <div className={classes.projectTable}>
        <Table
          columnWidths={['2fr', 'repeat(4, 1fr)']}
          entries={entries}
          headerContent={headerContent}
          rowHeight={gridRowHeight}
          sortManager={sortManager}
        />
      </div>
    </div>
  );
};

export default withStyles(CompanyTabStyles)(ProjectTable);
