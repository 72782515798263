import { InsightsSortKey } from '../../generated/graphql';
import { Select, SelectEntry } from '../scales';
import useMemoWrapper from '../useMemoWrapper';

type SortByInsights = {
  [key in InsightsSortKey]?: string;
};
const sortByEntries: SortByInsights[] = [
  { [InsightsSortKey.ALERTS]: 'Alerts' },
  { [InsightsSortKey.COST]: 'Cost' },
  { [InsightsSortKey.DELIVERY_TYPE]: 'Delivery Method' },
  { [InsightsSortKey.DESIGN_PHASE]: 'Design Phase' },
  { [InsightsSortKey.LAST_UPDATED]: 'Last Updated' },
  { [InsightsSortKey.NAME]: 'Name' },
  { [InsightsSortKey.SIZE]: 'Size (GSF)' },
  { [InsightsSortKey.STATUS]: 'Status' },
  { [InsightsSortKey.PROJECT_LEAD]: 'Project Lead' },
];

const getSortBySelectors = (sortByEntries: SortByInsights[]) =>
  sortByEntries.map((item: SortByInsights) => {
    const [key, value] = Object.entries(item)[0];
    return { id: key as keyof typeof InsightsSortKey, label: value! };
  });

const findSelectEntry = (selectedSortBy: InsightsSortKey): SelectEntry | undefined => {
  const foundItem = sortByEntries.find((item) => Object.keys(item)[0] === selectedSortBy);
  if (foundItem) {
    const [key, value] = Object.entries(foundItem)[0];
    return { id: key as keyof typeof InsightsSortKey, label: value! };
  }
  return undefined;
};

type Props = {
  onSortByChange: (sortKey: InsightsSortKey) => void;
  selectedSortBy: InsightsSortKey;
};

export default function InsightsSortSelect(props: Props) {
  // Sorting Selector
  const sortBySelectors: SelectEntry[] = useMemoWrapper(getSortBySelectors, sortByEntries);
  const selectedEntry = useMemoWrapper(findSelectEntry, props.selectedSortBy);

  return (
    <div className="flex w-44">
      <Select
        aria-label="Insights Sort"
        entries={sortBySelectors}
        label="Sort By"
        onChange={(value: string) => {
          const selectedSortKey: InsightsSortKey = value as InsightsSortKey;
          props.onSortByChange(selectedSortKey);
        }}
        value={selectedEntry?.id}
      />
    </div>
  );
}
