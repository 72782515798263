import { FC } from 'react';

import { PermissionResource } from '../../../../generated/graphql';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';
import { compareCost } from '../../../../utilities/compare';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { AddCategoriesToFilter } from '../../../FilterPanel/filterUtils';
import { getItemSettingsFromURL, getItemTotalValue } from '../../ItemsUtils';
import ItemsListItem from '../ItemsListItem';

type ItemFlag = {
  parentIsChosen?: boolean;
};

type CategorizedItem = ItemsListItem & CategorizedLine & ItemFlag;

type ItemsListItemDataCtaProps = {
  addCategoriesToFilter?: AddCategoriesToFilter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  children?: any;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  filteredMilestoneID?: UUID; // TODO: might be in the milestone state now
  hasCtaButton?: boolean;
  isListWithOptions?: boolean;
  isCollapseHeader?: boolean;
  isNested?: boolean;
  isSelected?: boolean;
  item: CategorizedItem;
  link: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseEnter?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onMouseLeave?: (event: any) => void;
  options?: Option[]; // if the display options differ than the listed Items due to filtering...
  sharedUsers: Pick<User, 'id' | 'name'>[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCategories?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCheckbox?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showParent?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showPartialCost?: boolean;
  mainCostImpact?: Cost;
  totalCostImpact?: Cost;
};

const ItemsListItemDataCta: FC<ItemsListItemDataCtaProps> = ({
  addCategoriesToFilter,
  children, // this is the third column content
  disabled = false,
  filteredMilestoneID,
  hasCtaButton = false,
  isCollapseHeader = false,
  isListWithOptions = false,
  isNested = false, // this is a nested option in the items list
  isSelected,
  item,
  link = '',
  onMouseEnter,
  onMouseLeave,
  options,
  sharedUsers,
  showCategories = true,
  showCheckbox = true,
  showParent = false,
  showPartialCost = true,
  mainCostImpact,
  totalCostImpact,
}) => {
  const { categories, cost: costImpact, filteredMilestoneState } = item;

  // PERMISSIONS
  const { canEdit, canView } = usePermissions({
    trades: categories,
    mountPolicy: MountPolicy.SKIP_ON_MOUNT,
  });
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const canViewAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const canViewComments = canView(PermissionResource.ITEM_COMMENTS);
  const canBulkEdit =
    canEdit(PermissionResource.ITEM_STATUS) ||
    canEdit(PermissionResource.ITEM_ASSIGNEES) ||
    canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS) ||
    canEdit(PermissionResource.ITEM_CATEGORIES);
  const canViewEstimateCostSubtotals = canView(PermissionResource.ESTIMATE_COST_SUBTOTALS);

  const { groupBy, show, hasGroupings } = getItemSettingsFromURL();

  const totalValue = getItemTotalValue(costImpact, mainCostImpact, showPartialCost);
  const shouldShowAcrossAllCategories =
    mainCostImpact && totalCostImpact && !compareCost(mainCostImpact, totalCostImpact);

  return (
    <ItemsListItem
      // eslint-disable-next-line react/no-children-prop
      children={children}
      addCategoriesToFilter={addCategoriesToFilter}
      canEdit={canEdit}
      canViewAttachments={canViewAttachments}
      canViewCheckbox={canBulkEdit}
      canViewComments={canViewComments}
      canViewEstimateCostSubtotals={canViewEstimateCostSubtotals}
      costImpact={mainCostImpact}
      disabled={disabled}
      filteredMilestoneID={filteredMilestoneID}
      filteredMilestoneState={filteredMilestoneState}
      groupBy={groupBy}
      hasCtaButton={hasCtaButton}
      hasGroupings={hasGroupings}
      isCollapseHeader={isCollapseHeader}
      isListWithOptions={isListWithOptions}
      isNested={isNested}
      isSelected={isSelected}
      item={item}
      link={link}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      options={options}
      sharedUsers={sharedUsers}
      shouldDisplayCosts={shouldDisplayCosts}
      show={show}
      showCategories={showCategories}
      showCheckbox={showCheckbox}
      showParent={showParent}
      totalValue={shouldShowAcrossAllCategories && (totalCostImpact || totalValue)}
    />
  );
};

export default ItemsListItemDataCta;
