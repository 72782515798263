import { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { AssistantPhoto } from '@material-ui/icons';

import { TermKey } from '../../../../api/gqlEnums';
import { CostReportColumnType } from '../../../../generated/graphql';
import { RouteKeys } from '../../../../routes/paths';
import { MILESTONE_OPTIONS_SET_ACTIVE_MILESTONE } from '../../../../tagConstants';
import { useShouldDisplayCosts } from '../../../../utilities/permissions/useShouldDisplayCosts';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { capitalizeString } from '../../../../utilities/string';
import Breadcrumbs from '../../../Breadcrumbs/Breadcrumbs';
import { getCostReportByType, renderCostString } from '../../../CostReport/CostReportUtils';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { Button, Chip, Tab, TabList, Tabs, Tooltip } from '../../../scales';
import useSetMilestoneStatus from '../../hooks/SetMilestoneStatusHook';
import useMilestoneQuantitiesQuery from '../../hooks/useMilestoneQuantitiesQuery';
import { MilestoneDetailsTabs } from '../../utils';
import { getMagnitudeCount } from '../MilestoneDetailsQuantities/MilestoneDetailsQuantities';

import MilestoneDetailsNavSettings from './MilestoneDetailsNavSettings';
import { CostParams, getIconColorLabel } from './MilestoneDetailsNavUtils';

type Props = {
  canDeleteDraftMilestones: boolean;
  canEditDraftMilestones: boolean;
  canEditMilestones: boolean;
  canViewAttachments: boolean;
  canViewMilestoneCosts: boolean;
  costParams: CostParams;
  hasEstimate: boolean;
  isActive: boolean;
  milestone: Milestone;
  milestoneCostReports: MilestoneCostReports;
  projectName: string;
  numMilestoneAttachments: number;
  view: string;
  setView: (view: string) => void;
};

const MilestoneDetailsNav: FC<Props> = ({
  canDeleteDraftMilestones,
  canEditDraftMilestones,
  canEditMilestones,
  canViewAttachments,
  canViewMilestoneCosts,
  costParams,
  hasEstimate,
  isActive,
  milestone,
  milestoneCostReports,
  projectName,
  numMilestoneAttachments,
  view,
  setView,
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const { description, id, isDraft, name } = milestone;

  const t = useContext(ProjectTermStore);

  // PERMISSIONS
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  // FILTER INFO
  const { data: { quantities } = {} } = useMilestoneQuantitiesQuery(id);
  const magnitudeCount = getMagnitudeCount(quantities);

  const estimateReport = getCostReportByType(
    milestoneCostReports[0],
    CostReportColumnType.ESTIMATE_REPORT
  );
  const targetReport = getCostReportByType(
    milestoneCostReports[0],
    CostReportColumnType.TARGET_REPORT
  );
  const estimateCost = estimateReport && estimateReport.range;
  const targetCost = targetReport && targetReport.range;

  const [setMilestoneStatus] = useSetMilestoneStatus();
  const onSetActive = () => {
    setMilestoneStatus(projectId, milestone.id);
  };

  const getCount = (key: MilestoneDetailsTabs) => {
    switch (key) {
      case MilestoneDetailsTabs.METRICS:
        return magnitudeCount;
      case MilestoneDetailsTabs.ESTIMATE:
        return estimateCost && renderCostString({ cost: estimateCost, isWide: true });
      case MilestoneDetailsTabs.TARGET:
        return targetCost && renderCostString({ cost: targetCost, isWide: true });
      case MilestoneDetailsTabs.ATTACHMENTS:
        return numMilestoneAttachments;
      default:
        return null;
    }
  };
  // TABS
  const blocked: MilestoneDetailsTabs[] = [];
  if (!canViewAttachments) blocked.push(MilestoneDetailsTabs.ATTACHMENTS);
  if (!shouldDisplayCosts) {
    blocked.push(MilestoneDetailsTabs.ESTIMATE);
    blocked.push(MilestoneDetailsTabs.TARGET);
  }

  const options = Object.values(MilestoneDetailsTabs)
    .filter((o) => !blocked.includes(o))
    .map((key: MilestoneDetailsTabs) => {
      let label = Object.values(TermKey).includes(key as unknown as TermKey)
        ? t.titleCase(key as unknown as TermKey)
        : capitalizeString(key.toString().toLowerCase());
      const count = getCount(key);
      const { title, color } = getIconColorLabel(key, milestone, label);
      if (count != null) label = `${label} (${count})`;
      return {
        key,
        label,
        color,
        title,
      };
    });

  return (
    <div className="bg-background-primary" style={{ width: '100%' }}>
      <div className="flex max-h-16 items-center justify-between px-6">
        <div className="mb-2.5 mt-3.5 flex items-center gap-2" data-cy="MilestoneDetailsNav">
          <Breadcrumbs
            links={[
              {
                display: 'Milestones',
                destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES, { projectId }),
                tooltip: 'Back to Milestones',
              },
              {
                display: name,
                destination: '',
                tooltip: description,
              },
            ]}
          />
          {isActive && (
            <Tooltip content="Active milestone">
              <div>
                <Chip icon={<AssistantPhoto className="text-entities-milestone" />} text="Active" />
              </div>
            </Tooltip>
          )}
          {isDraft && (
            <Tooltip content="Draft milestone">
              <div>
                <Chip data-cy="badge-DraftMilestone" text="Draft" />
              </div>
            </Tooltip>
          )}
          {!isActive && !isDraft && canEditMilestones && (
            <Button
              data-cy={MILESTONE_OPTIONS_SET_ACTIVE_MILESTONE}
              label="Set as active"
              onClick={onSetActive}
              startIcon={<AssistantPhoto />}
              type="secondary"
            />
          )}
        </div>
        <MilestoneDetailsNavSettings
          canDeleteDraftMilestones={canDeleteDraftMilestones}
          canEditDraftMilestones={canEditDraftMilestones}
          canEditMilestones={canEditMilestones}
          canViewMilestoneCosts={canViewMilestoneCosts}
          costParams={costParams}
          hasEstimate={hasEstimate}
          isActive={isActive}
          milestone={milestone}
          projectName={projectName}
          view={view}
        />
      </div>
      <div className="border-b border-border-default">
        <Tabs onChange={(view) => setView(view)} value={view}>
          <TabList>
            {options.map(({ key, label }) => (
              <Tab key={key} data-cy={`navigationTab-${key}`} id={key}>
                {label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </div>
    </div>
  );
};

export default MilestoneDetailsNav;
