import {
  UserSourceCompaniesQuery,
  UserSourceCompaniesQueryVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import { userSourceCompaniesQuery } from '../../../../DocumentMagic/hooks/queries';

export const useGetUserSourceCompaniesQuery = (sourceID: UUID) =>
  useQuery<UserSourceCompaniesQuery, UserSourceCompaniesQueryVariables>(userSourceCompaniesQuery, {
    variables: { sourceID },
    skip: !sourceID,
  });
