import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { VIEW_FILTER } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { useFilterManager } from '../../../FilterPanel/filterUtils';
import { useItemsListSettings } from '../../../ItemsList/ItemsListSettingsUtils';
import { useItemCurrentCostQuery } from '../../../ItemsList/useItemCurrentCostQuery';
import { generateCostImpactInfo } from '../../ItemsUtils';

import FilteredMilestoneInfoStyles from './FilteredMilestoneInfoStyles';

type FilteredMilestoneInfoProps = {
  classes: Classes<typeof FilteredMilestoneInfoStyles>;
  item: ItemsListItem;
};

const FilteredMilestoneCost: FC<FilteredMilestoneInfoProps> = ({ classes, item }) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');
  const { currentCost, id } = item;

  // STATE
  const settingsManager = useItemsListSettings();
  const { settings, persistSettingsChange } = settingsManager;
  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    persistSettingsChange(VIEW_FILTER, newValue)
  );
  const { filterQueryInput: viewFilter } = filterManager;
  const { data, loading } = useItemCurrentCostQuery(id, projectId, viewFilter, settings);
  const itemCost = data?.itemCurrentCost?.currentCost ?? currentCost;

  if (loading) return null;

  return (
    <Typography
      className={classes.costImpact}
      data-cy="filteredMilestoneCostImpact"
      variant="caption"
    >
      {generateCostImpactInfo(itemCost)}
    </Typography>
  );
};

export default withStyles(FilteredMilestoneInfoStyles)(FilteredMilestoneCost);
