import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { AssistantPhoto } from '@material-ui/icons';

import { COSTS } from '../../../constants';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { FilterManager } from '../../FilterPanel/filterUtils';
import { Chip } from '../../scales';
import { ColumnDescription } from '../CostReportColumns/CostReportColumns';
import CostReportExportPanel from '../CostReportExportPanel/CostReportExportPanel';
import CostReportSettingsPanel from '../CostReportSettingsPanel/CostReportSettingsPanel';

type CostReportHeaderProps = {
  categorizations: Categorization[];
  columnDescriptions: ColumnDescription[];
  currentReport?: Omit<UserReport, 'createdBy' | 'updatedAt'> | undefined;
  displayColumnDescriptions: ColumnDescription[];
  displayGroupBy: DisplayGroupBy[];
  exportCostReport: () => void;
  filterManager: FilterManager;
  milestone: Milestone;
  printOnClick: (route: RouteKeys) => void;
  projectId: UUID;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSettings?: (settings: Record<string, any>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSetting: (param: string, value: any) => void;
  status: string[];
  viewMode: string;
  setShowReportDistributionDialog?: (value: boolean) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  reportModalOpenOverride?: boolean;
  setReportModalOpenOverride?: (value: boolean) => void;
};

const CostReportHeader: FC<CostReportHeaderProps> = ({
  categorizations,
  columnDescriptions,
  currentReport,
  displayColumnDescriptions,
  displayGroupBy,
  exportCostReport,
  filterManager,
  milestone,
  printOnClick,
  projectId,
  settings,
  setSettings,
  setSetting,
  status,
  viewMode,
  setShowReportDistributionDialog,
  reportModalOpenOverride,
  setReportModalOpenOverride,
}) => {
  const controlHeader = (
    <div className="flex items-center">
      <CostReportSettingsPanel
        categorizations={categorizations}
        columnDescriptions={columnDescriptions}
        currentReport={currentReport}
        displayColumnDescriptions={displayColumnDescriptions}
        displayGroupBy={displayGroupBy}
        filterManager={filterManager}
        milestoneID={milestone.id}
        page={COSTS}
        reportModalOpenOverride={reportModalOpenOverride}
        setReportModalOpenOverride={setReportModalOpenOverride}
        setSetting={setSetting}
        setSettings={setSettings}
        setShowReportDistributionDialog={setShowReportDistributionDialog}
        settings={settings}
        status={status}
        viewMode={viewMode}
      />
      <CostReportExportPanel exportCostReport={exportCostReport} printOnClick={printOnClick} />
    </div>
  );

  const { id: milestoneId, name } = milestone;

  return (
    <div className="p-5">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <Breadcrumbs
            links={[
              {
                display: 'Milestones',
                destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES, { projectId }),
                tooltip: 'Back to milestones list',
              },
              {
                display: name || 'Loading...',
                destination: generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE, {
                  projectId,
                  milestoneId,
                }),
                tooltip: 'Back to milestone details',
              },
            ]}
          />
          <div className="flex items-center gap-3">
            <Typography data-cy="CostReport-title" variant="headline">
              Milestone Summary Report
            </Typography>
            {currentReport && currentReport.isFollowingActiveMilestone && (
              <Chip
                icon={<AssistantPhoto style={{ marginRight: 'auto' }} />}
                text="Following Active"
              />
            )}
          </div>
        </div>
        {controlHeader}
      </div>
    </div>
  );
};

export default CostReportHeader;
