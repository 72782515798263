import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import {
  AssistantPhoto,
  AssistantPhotoOutlined,
  ErrorOutline,
  InfoRounded,
} from '@material-ui/icons';

import {
  ItemsListImportEvent,
  itemsListImportEvent,
} from '../../../analytics/analyticsEventProperties';
import { PermissionResource, Visibility } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import ItemVisibilityRadio from '../../Items/ItemVisibilityToggle/ItemVisibilityRadio';
import { Button, Callout, Chip, Dialog, DialogContent, Select } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import { useCreateItemsFromImportMutation } from '../hooks/useCreateItemsFromImport';

import ImportItemsAreaData, { ImportState } from './ImportItemsAreaData';

const getMilestoneIcon = (id: UUID, activeId: UUID) =>
  id === activeId ? (
    <div className="text-entities-milestone">
      <AssistantPhoto />
    </div>
  ) : (
    <div className="text-entities-milestone">
      <AssistantPhotoOutlined />
    </div>
  );
const getMilestones = (milestones: Milestone[], activeId: UUID) =>
  milestones.map(({ id, name }) => ({
    id,
    label: name,
    startAdornment: getMilestoneIcon(id, activeId),
  }));

export type Props = {
  milestones: Milestone[];
  activeMilestoneID: UUID;
  file?: File;
  fileSource?: FileSource;
  isOpen?: boolean;
  itemsCount: number;
  onClose?: () => void;
  projectName: string;
};

export default function ImportItemsDialog(props: Props) {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID not found');

  const milestoneEntries = useMemoWrapper(getMilestones, props.milestones, props.activeMilestoneID);
  const [importState, setImportState] = useState<ImportState>(ImportState.none);
  const [importedItemsAssetID, setImportedItemsAssetID] = useState('');
  const [importedItemsTotal, setImportedItemsTotal] = useState(0);
  const [importedItemsStatus, setImportedItemStatus] = useState<string[]>([]);
  const [milestone, setMilestone] = useState<UUID>(props.activeMilestoneID);
  const [visibility, setVisibility] = useState<Visibility>(Visibility.PUBLISHED);
  const sendAnalytics = useSendAnalytics();

  const { canAdd, inTrade } = usePermissions();
  const canAddItemLines = canAdd(getItemLinesPermissionResource(inTrade));
  const canAddItemCategories = canAdd(PermissionResource.ITEM_CATEGORIES);
  const feedbackText = (() => {
    if (!canAddItemLines && !canAddItemCategories) return 'Cost and categorization';
    if (!canAddItemLines) return 'Cost';
    if (!canAddItemCategories) return 'Categorization';
    return '';
  })();

  const onClose = () => {
    props.onClose?.();
    sendAnalytics(itemsListImportEvent(ItemsListImportEvent.IMPORT_CANCEL));
  };
  const [createItemsFromImport, mutationResult] = useCreateItemsFromImportMutation({
    onCompleted: onClose,
  });

  const loading = importState === ImportState.loading;
  const disabled = Boolean(!importedItemsAssetID || loading || mutationResult.loading);
  const onUpdate = () => {
    createItemsFromImport(projectId, milestone, importedItemsAssetID, visibility);
    sendAnalytics(
      itemsListImportEvent(ItemsListImportEvent.IMPORT_IMPORT, {
        milestone,
        itemsCount: props.itemsCount,
        itemsUploadCount: importedItemsTotal,
        collaboration: visibility,
      })
    );
  };

  const loadingBar = (
    <div className="h-1">
      <LinearProgress hidden={!mutationResult.loading} />
    </div>
  );

  return (
    <Dialog
      {...props}
      footerRight={
        <Button
          data-cy="import-items-button"
          isDisabled={disabled}
          label="Import Items"
          onClick={onUpdate}
          type="primary"
        />
      }
      isNotDismissable={loading || mutationResult.loading}
      onClose={onClose}
      title="Import Items"
    >
      {loadingBar}
      <DialogContent className="flex flex-col gap-2">
        {importedItemsTotal > 0 && feedbackText && (
          <Callout>
            <div className="flex gap-2 type-body2">
              <ErrorOutline className="!˝fill-type-warning icon-md" />
              {`${feedbackText} data excluded due to permissions. 
           `}
              <br />
              Contact admin to update permissions.
            </div>
          </Callout>
        )}
        <ImportItemsAreaData
          file={props.file}
          fileSource={props.fileSource}
          handleImportState={setImportState}
          itemsCount={props.itemsCount}
          projectID={projectId}
          projectName={props.projectName}
          setImportAssetID={setImportedItemsAssetID}
          setImportedItemStatus={setImportedItemStatus}
          setImportedItemsTotal={setImportedItemsTotal}
        />
        <Select
          entries={milestoneEntries}
          label="Milestone"
          onChange={setMilestone}
          value={milestone}
        />
        <ItemVisibilityRadio
          isDisabled={false}
          onChange={() => {}}
          setVisibilitySetting={(visibility) => {
            setVisibility(visibility);
          }}
          visibilitySetting={visibility}
        />
        {visibility === Visibility.PRIVATE_DRAFT &&
          importedItemsStatus.some((status) => status !== 'PENDING') && (
            <Chip
              data-cy="import-private-feedback"
              icon={<InfoRounded />}
              text="Draft Items are imported and set to Pending status."
            />
          )}
      </DialogContent>
    </Dialog>
  );
}
