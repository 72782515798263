import { ComponentProps, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isNonNullable } from '../../../utilities/types';
import { LastViewedProject } from '../../contexts/recently-viewed-entries';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import { ProjectsIcon } from '../icons';

import { useProjectOrProgramQuery } from './hooks/useProjectOrProgramQuery';
import NavItem from './NavItem';
import ProgramTitle from './Title/ProgramTitle';
import ProjectTitle from './Title/ProjectTitle';

type Props = {
  isExpanded?: boolean;
  lastViewedEntries: LastViewedProject[];
};

export default function ProjectsNavItem(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();

  let tooltip: ReactNode | undefined;

  const ids = props.lastViewedEntries.map((e) =>
    e.type === 'project'
      ? { projectID: e.id, programID: undefined }
      : { projectID: undefined, programID: e.id }
  );

  const data = [
    useProjectOrProgramQuery(ids[1]?.projectID, ids[1]?.programID),
    useProjectOrProgramQuery(ids[2]?.projectID, ids[2]?.programID),
    useProjectOrProgramQuery(ids[3]?.projectID, ids[3]?.programID),
  ];

  // TODO: NS-1461: do not rely on checking for deprecated programs
  const entries = data
    .map((d) => {
      if (d.data && 'project' in d.data) return d.data.project;
      if (d.data && 'program' in d.data && !d.data.program.deprecated) return d.data.program;

      return undefined;
    })
    .filter(isNonNullable);

  if (entries.length) {
    tooltip = (
      <div className="flex w-60 flex-col gap-4 p-2" data-cy="project-switcher">
        <div className="type-heading3">Recent Activity</div>
        {entries.map((e, i) => {
          if ('activeMilestone' in e)
            return (
              <NavProjectEntry
                key={e.id}
                onClick={() => sendNavAnalytics(navEventTypes.RECENT_PROJECT_CTA, { i })}
                project={e}
              />
            );

          return (
            <NavProgramEntry
              key={e.id}
              onClick={() => sendNavAnalytics(navEventTypes.RECENT_PROGRAM_CTA, { i })}
              program={e}
            />
          );
        })}
      </div>
    );
  } else if (!props.isExpanded) {
    tooltip = 'Projects';
  }

  return (
    <NavItem
      icon={<ProjectsIcon />}
      label={props.isExpanded ? 'Projects' : undefined}
      path={generateSharedPath(RouteKeys.SEARCH_PROJECTS)}
      tooltip={tooltip}
      tooltipPlacement={props.lastViewedEntries.length ? 'right top' : undefined}
    />
  );
}

function NavProjectEntry({
  onClick,
  project,
}: {
  onClick: () => void;
  project: ComponentProps<typeof ProjectTitle>['project'];
}) {
  return (
    <Link
      key={project?.id}
      className="cursor-pointer"
      onClick={onClick}
      to={generateSharedPath(RouteKeys.PROJECT, {
        projectId: project?.id,
      })}
    >
      <ProjectTitle isThumbnailVisible project={project} />
    </Link>
  );
}
function NavProgramEntry({
  onClick,
  program,
}: {
  onClick: () => void;
  program: ComponentProps<typeof ProgramTitle>['program'];
}) {
  return (
    <Link
      key={program?.id}
      className="cursor-pointer"
      onClick={onClick}
      to={generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, {
        programID: program?.id,
      })}
    >
      <ProgramTitle isThumbnailVisible program={program} />
    </Link>
  );
}
