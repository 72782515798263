import { forwardRef, useRef } from 'react';
import { mergeProps, useCheckbox, useFocusRing, useToggleButton } from 'react-aria';
import { useToggleState } from 'react-stately';

import composeRefs from '@seznam/compose-react-refs';

type Props = {
  'aria-label': string;
  isSelected?: boolean;
  onChange?: Parameters<typeof useCheckbox>[0]['onChange'];
  variant?: 'light' | 'default';
};

export default forwardRef<HTMLButtonElement, Props>(function Star(props, forwardedRef) {
  const ref = useRef<HTMLButtonElement>(null);
  const state = useToggleState({
    isSelected: props.isSelected,
    onChange: props.onChange,
  });
  const { buttonProps } = useToggleButton(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = state.isSelected;
  const isLight = props.variant === 'light';

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={composeRefs(ref, forwardedRef)}
      className={[
        'relative flex w-max shrink-0 items-center rounded-full p-1 transition icon-md',
        isFocusVisible ? 'outline' : 'outline-none',
        isLight
          ? 'text-type-secondary hover:enabled:bg-background-backdrop'
          : 'text-type-primary hover:enabled:bg-button-icon-hover',
        isSelected ? '!text-[#F6B901]' : '',
      ].join(' ')}
    >
      {isSelected ? <StarFilled /> : <StarOutlined />}
    </button>
  );
});

export const StarFilled = () => (
  <svg fill="none" height="1em" viewBox="0 0 24 24" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
      fill="currentColor"
    />
    <path
      d="M22 9.24L14.81 8.62L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.55 13.97L22 9.24ZM12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L12 6.1L13.71 10.14L18.09 10.52L14.77 13.4L15.77 17.68L12 15.4Z"
      fill="currentColor"
    />
  </svg>
);

export const StarOutlined = () => (
  <svg fill="none" height="1em" viewBox="0 0 24 24" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 9.24L14.81 8.62L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.55 13.97L22 9.24ZM12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L12 6.1L13.71 10.14L18.09 10.52L14.77 13.4L15.77 17.68L12 15.4Z"
      fill="currentColor"
    />
  </svg>
);
