import { FC } from 'react';

import { withStyles } from '@material-ui/core';
import { GetApp, Print } from '@material-ui/icons';

import { RouteKeys } from '../../../routes/paths';
import { COST_REPORT_EXPORT } from '../../../tagConstants';
import { Export } from '../../Icons/Export';
import { IconMenuButton, MenuEntry } from '../../scales';

import styles from './CostReportExportPanelStyles';

type CostReportExportPanelProps = {
  classes: Classes<typeof styles>;
  printOnClick: (route: RouteKeys) => void;
  exportCostReport: () => void;
};

const CostReportExportPanel: FC<CostReportExportPanelProps> = ({
  classes,
  printOnClick,
  exportCostReport,
}) => {
  const options: MenuEntry[] = [
    {
      id: 'print-report-with-cost-summary',
      onClick: () => printOnClick(RouteKeys.PRINT_PROJECT_MSR_SUMMARY),
      startAdornment: <Print />,
      label: 'Print Report with Cost Summary',
    },
    {
      id: 'print-report-only',
      onClick: () => printOnClick(RouteKeys.PRINT_PROJECT_MSR),
      startAdornment: <Print />,
      label: 'Print Report Only',
    },
    {
      id: 'download-report',
      onClick: () => exportCostReport(),
      startAdornment: <GetApp />,
      label: 'Download .xlsx',
    },
  ];

  return (
    <div className={classes.padding}>
      <IconMenuButton
        aria-label="Cost report export menu"
        data-cy={COST_REPORT_EXPORT}
        entries={options}
        icon={<Export />}
        type="secondary"
      />
    </div>
  );
};

export default withStyles(styles)(CostReportExportPanel);
