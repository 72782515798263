import queryString from 'query-string';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';

import { LinearProgress } from '@material-ui/core';

import { EstimateType } from '../../../api/gqlEnums';
import { COSTS, TYPE, VIEW_OPTIONS } from '../../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useThumbnailURL } from '../../../hooks/useThumbnailURL';
import { RouteKeys } from '../../../routes/paths';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { makeOptionsFromCategorizations } from '../../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../../utilities/categorization/categorizationUtils';
import { returnGroupByOptions } from '../../../utilities/grouping';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isNonNullable } from '../../../utilities/types';
import ChartsReportCost from '../../Charts/ChartsReport/ChartsReportMilestone';
import { HasDisplayCosts } from '../../conditionals/HasDisplayCosts';
import useCostReportParams from '../../CostReport/CostReport/useCostReportParams';
import {
  ColumnDescription,
  buildCostReportisplayColumnDescriptions,
  getColumnDescriptionsT,
  getCostReportColumns,
  getExpressionsFromUnits,
} from '../../CostReport/CostReportColumns/CostReportColumns';
import { HEADER_HEIGHT } from '../../CostReport/CostReportHeader/CostReportHeaderStyles';
import CostReportList from '../../CostReport/CostReportList/CostReportList/CostReportList';
import { hasUserReportComments } from '../../CostReport/CostReportList/CostReportList/CostReportListUtils';
import { getCostReportListHeaderHeight } from '../../CostReport/CostReportList/CostReportListHeader/CostReportListHeaderStyles';
import {
  DESCRIPTION_WIDTH,
  LIST_PADDING,
  NOTES_COL_WIDTH_EXPANDED,
  RANGE_COL_WIDTH,
  REPORT_COL_WIDTH,
} from '../../CostReport/CostReportList/CostReportListRow/CostReportListRowStyles';
import { getHeight, getListHeaderHeight } from '../../CostReport/CostReportUtils';
import useMilestoneCostReportQuery from '../../CostReport/hooks/useMilestoneCostReportQuery';
import { getNonZeroQuantities } from '../../Inputs/UnitToggle/UnitToggleUtils';
import { useGetProjectUnitsQuery } from '../../Milestone/hooks/UnitHooks';
import useMilestoneQuantitiesQuery, {
  emptyQuantities,
} from '../../Milestone/hooks/useMilestoneQuantitiesQuery';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { useLoadTimer } from '../../PerfMonitor/utils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { useLoadUserReportComments } from '../../ReportsTab/ReportHooks';
import { filterDisplayGroupBys } from '../../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';
import useMemoWrapper from '../../useMemoWrapper';
import { useCurrentUserReportID } from '../../VarianceReport/VarianceReportUtils';
import usePrintWindow from '../PrintHooks/usePrintWindow';
import PrintPageBreak from '../PrintSharedComponents/PrintPageBreak';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import {
  LANDSCAPE_WIDTH,
  LandscapeOrientation,
  PORTRAIT_WIDTH,
  PortraitOrientation,
} from '../PrintUtils';

import PrintCostReportStyles from './PrintCostReportStyles';
import PrintSubheader from './PrintSubheader';

type PrintCostReportProps = {
  classes: Classes<typeof PrintCostReportStyles>;
};

const PrintCostReport: FC<PrintCostReportProps> = ({ classes }) => {
  const t = useContext(ProjectTermStore);

  const { projectId, milestoneId } = useParams();
  if (!projectId || !milestoneId) throw new Error('Project ID or Milestone ID not found');

  // Import Estimate
  const estimateType = queryString.parse(window.location.search, { arrayFormat: 'index' })[TYPE];

  // DATA
  // Fetch relevant project data: categorizations list, milestone names and estimate ids
  const {
    data: { project },
    loading: projectLoading,
  } = useProjectPropsQuery(projectId);
  const { data: milestonesData, loading: milestonesLoading } = useMilestonesQuery(projectId, false);
  const { data: catData, loading: catLoading } = useProjectCategorizationsQuery(projectId);

  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const defaultCategorizations = useMemoWrapper(getDefaultReportCategorizations, categorizations);
  const milestone = milestonesData?.milestones.find((m) => m.id === milestoneId);
  const milestoneName = milestone?.name ?? '';

  // We group by the estimate's categorizations as a default setting, but allow the user to override.

  const { data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] } } =
    useGetProjectUnitsQuery(projectId, true);
  const { settings, filterManager } = useCostReportParams(
    categorizations,
    defaultCategorizations,
    milestoneName,
    COSTS,
    enabledUnits
  );

  const { columns = [], viewMode, groupBy = [], status = [], metrics = [] } = settings;
  const viewFilter = filterManager.filterQueryInput;

  // Quanitites - get'em
  const { data: { quantities = emptyQuantities } = {} } = useMilestoneQuantitiesQuery(milestoneId);

  // Columns for Report Query
  const nonZeroQuantities = getNonZeroQuantities(quantities);

  const costReportColumns = useMemo(
    () => getCostReportColumns(nonZeroQuantities, settings),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [nonZeroQuantities]
  );

  const expressions = getExpressionsFromUnits(settings, enabledUnits);

  const columnDescriptions: ColumnDescription[] = getColumnDescriptionsT(t)(
    milestoneName,
    false,
    expressions
  );
  const columnsArray = Array.isArray(columns) ? columns.filter(isNonNullable) : [];
  const { displayColumnDescriptions, headerDescriptions } = buildCostReportisplayColumnDescriptions(
    columnsArray,
    columnDescriptions,
    expressions,
    settings
  );

  // GROUPBY translation and defaults
  // We split up categorization levels as options
  const options = makeOptionsFromCategorizations(categorizations);
  // We match the simple string to a complex option with level explanation for the tree
  // we also need to scrub for valid option ids
  const groupByOptions = Array.isArray(groupBy)
    ? returnGroupByOptions(
        groupBy.filter((x): x is string => x !== null),
        options
      )
    : [];
  const displayGroupBy = filterDisplayGroupBys(groupByOptions);

  // Report Notes
  const reportID = useCurrentUserReportID();
  const { data: commentData, loading: loadingReportComments } = useLoadUserReportComments(
    reportID,
    {
      groupBys: displayGroupBy.map((gb) => gb.id),
      milestoneEstimates: [],
    }
  );
  const reportComments = commentData?.varianceReportComments || undefined;

  // Sizing
  const numUnits = metrics?.length || 0;
  const wideCount = displayColumnDescriptions.filter((c) => c && c.isWide).length;
  const thinCount = displayColumnDescriptions.length - wideCount;
  const tableWidth =
    wideCount * RANGE_COL_WIDTH +
    thinCount * REPORT_COL_WIDTH +
    numUnits * REPORT_COL_WIDTH +
    DESCRIPTION_WIDTH +
    (hasUserReportComments(reportComments) ? NOTES_COL_WIDTH_EXPANDED : 0);
  const isLandscape = tableWidth > PORTRAIT_WIDTH;
  const orientationStyle = isLandscape ? <LandscapeOrientation /> : <PortraitOrientation />;
  const width = isLandscape ? LANDSCAPE_WIDTH : PORTRAIT_WIDTH;
  const [listIsLoading, setListIsLoading] = useState(true);
  const zoomLevel =
    (listIsLoading || width > tableWidth) && groupBy?.length !== 0 ? 1 : width / tableWidth;
  const transform = `scaleX(${zoomLevel})`;

  // Import Estimate
  const useDraftEstimate = estimateType === EstimateType.ACTIVE_ESTIMATE;
  const useDraftBudget = estimateType === EstimateType.BUDGET;

  const { data: { milestoneCostReports = null } = {}, loading: costReportsLoading } =
    useMilestoneCostReportQuery(
      milestoneId,
      displayGroupBy,
      costReportColumns,
      projectId,
      viewFilter,
      useDraftEstimate,
      useDraftBudget,
      true,
      false
    );
  const milestoneCostReport = milestoneCostReports && milestoneCostReports[0];

  // THUMBNAIL
  const thumbnail = project?.thumbnail;
  const routerLocation = useLocation();
  const hasSummary =
    routerLocation.pathname ===
    generateSharedPath(RouteKeys.PRINT_PROJECT_MSR_SUMMARY, { projectId, milestoneId });

  // CALCULATE CONSTS FOR PAGE BREAK IN ROW
  const paperHeight = isLandscape ? PORTRAIT_WIDTH : LANDSCAPE_WIDTH;
  const headerRef = useRef<HTMLDivElement>(null);
  const { current } = headerRef;
  const headerHeight = useMemoWrapper(getHeight, current, HEADER_HEIGHT);

  const listHeaderHeight = getCostReportListHeaderHeight(false, settings);

  const pageHeight =
    paperHeight -
    headerHeight -
    LIST_PADDING - // We will leave a little space in case they modify the print settings
    getListHeaderHeight(zoomLevel, listHeaderHeight);
  const breakpoints = [pageHeight, zoomLevel];

  const { thumbnailURL, thumbnailLoading } = useThumbnailURL(thumbnail, !hasSummary);

  // FIRE PRINT ON LOADING
  const [imagesAreReady, setImagesAreReady] = useState(false);
  const triggerOnComplete = () => setImagesAreReady(true);
  const hasList = viewMode !== VIEW_OPTIONS.CHART;
  const hooksLoading =
    costReportsLoading ||
    projectLoading ||
    milestonesLoading ||
    catLoading ||
    !imagesAreReady ||
    thumbnailLoading ||
    loadingReportComments ||
    (hasList && listIsLoading);

  usePrintWindow(!hooksLoading);

  // LOAD EFFECTS
  useLoadTimer('PrintCostReport', hooksLoading);
  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - Milestone Summary Report`;
  }, [project]);

  // COMPONENTS
  // With Summary, chart will cause a page break. Without, we standardize it with CSS.
  const chart = () =>
    costReportsLoading ? (
      <div className={classes.costReportChart} />
    ) : (
      projectId &&
      milestoneCostReport && (
        <ChartsReportCost
          categorizations={categorizations}
          classes={{ root: classes.costReportChart }}
          costReports={milestoneCostReports}
          groupBy={displayGroupBy}
          milestoneId={milestoneId}
          printWidth={width}
          projectId={projectId}
        />
      )
    );

  // TODO: Most of this is shared with PrintVarianceReport. Consider shared helpers.
  const hasChart = viewMode !== VIEW_OPTIONS.LIST && displayGroupBy.length;
  const header = (
    <div ref={headerRef}>
      <PrintPageHeaderWrapper
        milestoneName={!hasSummary ? milestoneName : undefined}
        projectId={projectId}
        reportName="Milestone Summary Report"
        triggerOnComplete={triggerOnComplete}
      />
      <PrintSubheader
        categorizations={categorizations}
        hasSummary={hasSummary}
        milestone={milestone}
        project={project}
        settings={settings}
        thumbnailURL={thumbnailURL ?? undefined}
      />
    </div>
  );
  const chartMargin = 64; // protection for wide margins on page 1
  const page1 = () => (
    <div style={{ height: paperHeight - chartMargin }}>
      {header}
      {chart()}
    </div>
  );

  const list = () => (
    <>
      {groupBy && groupBy.length > 0 && header}
      {milestoneId && milestoneCostReport && (
        <div style={{ transformOrigin: 'top left', transform }}>
          <CostReportList
            breakpoints={breakpoints}
            costReports={[milestoneCostReport]}
            displayColumnDescriptions={displayColumnDescriptions}
            groupBys={displayGroupBy}
            header={header}
            headerDescriptions={headerDescriptions}
            itemStatuses={status}
            milestoneEstimates={[]}
            milestoneID={milestoneId}
            reportComments={reportComments}
            setIsLoading={setListIsLoading}
            settings={settings}
            viewFilter={viewFilter}
          />
        </div>
      )}
    </>
  );

  const chartPage = hasChart ? page1() : null;
  const firstBreak = (hasChart && hasList && <PrintPageBreak />) || null;
  const listPages = hasList ? list() : null;

  return (
    <div
      className={classes.root}
      // add a test ID so automated report distribution PDF generation (pdf/lib.go) knows the report has loaded
      data-testid={!hooksLoading ? 'report-distribution-print-hooks-loaded' : undefined}
    >
      {orientationStyle}
      <div className={classes.page} style={{ width }}>
        {chartPage}
        {firstBreak}
        {(costReportsLoading || !imagesAreReady) && <LinearProgress />}
        {listPages}
      </div>
      <div className={classes.footer} />
    </div>
  );
};

const Styled = withStyles(PrintCostReportStyles)(PrintCostReport);

export default () => (
  <HasDisplayCosts>
    <Styled />
  </HasDisplayCosts>
);
