import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  forecastingEventTypes,
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  projectCompsSetInputVar,
  projectCompsSettingsInputDefault,
} from '../../../api/apollo/reactiveVars';
import { FORECASTING_TAB_NAME } from '../../../constants';
import { DD_BENCHMARKING } from '../../../features';
import {
  ForecastingReportsSortBy,
  ForecastingReportsSortKey,
  SortDirection,
} from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import DialogsReportsAddToProject from '../../Dialogs/DialogsReportsAddToProject/DialogsReportsAddToProject';
import DialogsReportsDelete from '../../Dialogs/DialogsReportsDelete/DialogsReportsDelete';
import DialogsReportsEdit from '../../Dialogs/DialogsReportsEdit/DialogsReportsEdit';
import DialogsSelectionProjects from '../../Dialogs/DialogsSelectionProjects/DialogsSelectionProjects';
import { navigateToProjectComps } from '../../ProjectComps/ProjectCompsSetUtils';
import useSendForecastingAnalytics from '../hooks/useSendForecastingAnalytics';

import ForecastingReportsHeader from './ForecastingReportsHeader';
import ForecastingReportsList from './ForecastingReportsList';
import { getSortManager } from './ForecastingReportsUtils';
import { useAddProjectComparisonReportToProjectMutation } from './hooks/useAddProjectComparisonReportToProjectMutation';
import { useDeleteForecastingReportMutation } from './hooks/useDeleteForecastingReportMutation';
import { useProjectComparisonReportsQuery } from './hooks/useProjectComparisonReportsQuery';

const ForecastingReports = () => {
  const hasBenchmarkingFeature = useHasFeature(DD_BENCHMARKING);
  useEffect(() => {
    document.title = `${hasBenchmarkingFeature ? 'Benchmarking' : FORECASTING_TAB_NAME} - Reports`;
  }, [hasBenchmarkingFeature]);

  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();
  const sendForecastingAnalytics = useSendForecastingAnalytics();

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState({
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingReportsSortKey.UPDATED_AT,
  });
  const [openCreateReportDialog, setOpenCreateReportDialog] = useState<boolean>(false);
  const onCreateReport = useCallback(() => {
    sendForecastingAnalytics(forecastingEventTypes.CREATE_REPORT_CTA, {
      location: 'reports',
    });
    projectCompsSetInputVar(projectCompsSettingsInputDefault);
    setOpenCreateReportDialog(true);
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_SELECT_PROJECTS_MODAL_VIEW, {
        location: 'forecasting reports',
      })
    );
  }, [sendAnalytics, sendForecastingAnalytics]);
  const onChangeSearch = useCallback(
    (search: string) => {
      setSearch(search);
      sendForecastingAnalytics(forecastingEventTypes.SEARCH, {
        search,
        searchType: 'reports',
      });
    },
    [sendForecastingAnalytics]
  );
  const onChangeSortBy = useCallback(
    (sortBy: ForecastingReportsSortBy) => {
      setSortBy(sortBy);
      sendForecastingAnalytics(forecastingEventTypes.SORT, {
        sortDirection: sortBy.sortDirection,
        sortKey: sortBy.sortKey,
        sortType: 'reports',
      });
    },
    [sendForecastingAnalytics]
  );

  const sortManager = getSortManager(sortBy, onChangeSortBy);

  const { data, loading, previousData, refetch } = useProjectComparisonReportsQuery({
    search,
    sortBy,
  });
  const [onDeleteForecastingReport] = useDeleteForecastingReportMutation();
  const [onAddToProject] = useAddProjectComparisonReportToProjectMutation();

  const onLink = useCallback(
    (report: ForecastingReport) => {
      projectCompsSetInputVar(projectCompsSettingsInputDefault);
      navigate(
        generateSharedPath(RouteKeys.BENCHMARKING_PROJECT_COMPS_SAVED, {
          reportID: report.id,
        })
      );
    },
    [navigate]
  );

  enum DialogType {
    None = 'None',
    AddToProject = 'AddToProject',
    Edit = 'Edit',
    Delete = 'Delete',
  }
  const [openDialog, setOpenDialog] = useState<DialogType>(DialogType.None);
  const [selectedReport, setSelectedReport] = useState<ForecastingReport | undefined>(undefined);
  const onCloseDialog = () => {
    setOpenDialog(DialogType.None);
    setSelectedReport(undefined);
  };
  const onOpenDialog = (report: ForecastingReport, type: DialogType) => {
    setOpenDialog(type);
    setSelectedReport(report);
    if (type === DialogType.AddToProject)
      sendForecastingAnalytics(forecastingEventTypes.ADD_REPORT_TO_PROJECT_DIALOG_VIEW);
    else if (type === DialogType.Delete)
      sendForecastingAnalytics(forecastingEventTypes.DELETE_REPORT_DIALOG_VIEW);
    else if (type === DialogType.Edit)
      sendForecastingAnalytics(forecastingEventTypes.EDIT_REPORT_DIALOG_VIEW);
  };
  const onConfirmAddToProject = (projectID: string) => {
    sendForecastingAnalytics(forecastingEventTypes.ADD_REPORT_TO_PROJECT_DIALOG_CTA, {
      projectId: projectID,
      reportID: selectedReport?.id,
      reportName: selectedReport?.name,
    });
    if (selectedReport)
      onAddToProject(selectedReport.id, projectID, () => {
        refetch();
      });
    onCloseDialog();
  };
  const onConfirmDelete = (reportID: string, onSuccess?: () => void, onFailure?: () => void) => {
    sendForecastingAnalytics(forecastingEventTypes.DELETE_REPORT_DIALOG_CTA, {
      reportID: selectedReport?.id,
      reportName: selectedReport?.name,
    });
    onDeleteForecastingReport(
      reportID,
      () => {
        refetch();
        if (onSuccess) onSuccess();
      },
      onFailure
    );
  };
  const onConfirmEdit = () => {
    sendForecastingAnalytics(forecastingEventTypes.EDIT_REPORT_DIALOG_CTA, {
      reportID: selectedReport?.id,
      reportName: selectedReport?.name,
    });
    refetch();
  };

  return (
    <>
      <ForecastingReportsHeader
        onCreateReport={onCreateReport}
        search={search}
        setSearch={onChangeSearch}
      />
      <ForecastingReportsList
        isLoading={loading}
        onAddToProject={(report: ForecastingReport) =>
          onOpenDialog(report, DialogType.AddToProject)
        }
        onDelete={(report: ForecastingReport) => onOpenDialog(report, DialogType.Delete)}
        onEdit={(report: ForecastingReport) => onOpenDialog(report, DialogType.Edit)}
        onLink={onLink}
        reports={data?.projectComparisonReports ?? previousData?.projectComparisonReports ?? []}
        searchText={search}
        sortManager={sortManager}
      />
      {selectedReport && (
        <DialogsReportsEdit
          onClose={onCloseDialog}
          onSuccess={onConfirmEdit}
          open={openDialog === DialogType.Edit}
          report={selectedReport}
        />
      )}
      {selectedReport && (
        <DialogsReportsDelete
          onClose={onCloseDialog}
          onDelete={onConfirmDelete}
          open={openDialog === DialogType.Delete}
          report={selectedReport}
        />
      )}
      {selectedReport && (
        <DialogsReportsAddToProject
          onAddToProject={onConfirmAddToProject}
          onClose={onCloseDialog}
          open={openDialog === DialogType.AddToProject}
        />
      )}
      {openCreateReportDialog && (
        <DialogsSelectionProjects
          canViewCompanyProjects
          confirmButtonLabel={(count) =>
            `Create report with ${pluralizeCountString('project', count)}`
          }
          isOpen
          onClose={() => {
            setOpenCreateReportDialog(false);
          }}
          onDone={(selectedProjectIDs) => {
            setOpenCreateReportDialog(false);
            navigateToProjectComps(navigate, selectedProjectIDs);
          }}
          selectionMode="multiple"
          title="Create report"
        />
      )}
    </>
  );
};

export default ForecastingReports;
