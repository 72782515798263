import { upperCase } from 'lodash';
import { isUUID } from 'validator';

import {
  AUTHORIZE,
  COMPANY_COLLABORATORS_PATH,
  COMPANY_MEMBERS_PATH,
  COMPANY_TAB_PATH,
  IMPORT,
  ITEMS,
  MILESTONES,
  PROJECTS,
} from '../constants';
import { SourceSystem } from '../generated/graphql';

import { logErrorToSentry } from './sentry';
import { getUUID } from './uuid';

/*
- splitPathIntoUUID
- getItemIdFromUrl
- getMilestoneIdFromUrl
- getImportEstimateIdFromUrl
 */

export const getDomain = (url: string) => {
  const regex = /.*\/\/[^/]*/;
  return (url ?? '').match(regex)?.[0];
};

// remove the domain ie localhost, app.join.build etc
// from the path if it exists
const stripDomain = (input: string): string => {
  const reg = /.*\/\/[^/]*/;
  return input.replace(reg, '');
};

const splitPathIntoUUID = (split: string, path = window.location.pathname): string => {
  const parts = stripDomain(path).split(split);
  return parts && parts.length > 1 && parts[1] !== PROJECTS ? getUUID(parts[1]) : '';
};

/** @deprecated use useParams() instead */
export const getItemIdFromUrl = (path?: string): string => splitPathIntoUUID(`/${ITEMS}/`, path);
/** @deprecated use useParams() instead */
export const getMemberUserIdFromUrl = (path?: string): string =>
  splitPathIntoUUID(`/${COMPANY_TAB_PATH}/${COMPANY_MEMBERS_PATH}/`, path);

/** @deprecated use useParams() instead */
export const getCollaboratorUserIdFromUrl = (path?: string): string =>
  splitPathIntoUUID(`/${COMPANY_TAB_PATH}/${COMPANY_COLLABORATORS_PATH}/`, path);

/** @deprecated use useParams() instead */
export const getMilestoneIdFromUrl = (path?: string): string => {
  try {
    // find the thing that's after the word 'milestones' and before the next '/'
    return splitPathIntoUUID(`/${MILESTONES}/`, path);
  } catch (e) {
    logErrorToSentry('Attempting to parse a milestone ID from a bad path.');
    return '';
  }
};

// These relates to routes.ts
/** @deprecated use useParams() instead */
export const getImportEstimateIdFromUrl = (path?: string): string =>
  splitPathIntoUUID(`/${IMPORT}/`, path);

/** @deprecated use useParams() instead. */
export const getProjectIdFromUrl = (path = window.location.pathname): string => {
  const segment = path?.split('/')[1];
  return isUUID(segment) ? segment : '';
};

/** @deprecated use useParams() instead */
export const getDerivationIdFromUrl = (path?: string): string =>
  splitPathIntoUUID(`/derivations/`, path);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const matchStringWithEnum = (enumeration: any, input: string) => {
  const enumValues = Object.values(enumeration);
  const match = enumValues.find((value) => value === input);
  return match && (match as typeof enumeration);
};

export const matchStringWithSourceEnum = (input: string) =>
  matchStringWithEnum(SourceSystem, input);

/** @deprecated use useParams() instead */
export const getSourceFromURL: () => SourceSystem | undefined = () => {
  const { pathname } = window.location;
  const [, auth, source] = pathname.split('/');
  if (auth === AUTHORIZE) {
    return matchStringWithSourceEnum(upperCase(source));
  }
  return undefined;
};

export const isPrintFromUrl = (path = window.location.pathname): boolean => {
  return path.indexOf('/print/') >= 0;
};
