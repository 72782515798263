import { ErrorOutline } from '@material-ui/icons';

import { InsightsSortKey } from '../../generated/graphql';
import { Switch } from '../scales';

import InsightsSortSelect from './InsightsSortSelect';
import InsightsTabs from './InsightsTabs';
import { InsightsTabId } from './types';

type Props = {
  isAlertsDisabled: boolean;
  isAlertsOnly: boolean;
  onSortByChange: (sortKey: InsightsSortKey) => void;
  onTabChange?: (tabId: InsightsTabId) => void;
  selectedSortBy: InsightsSortKey;
  selectedTabID: InsightsTabId;
  setAlertsOnly: (checked: boolean) => void;
};

export default function InsightsViewHeader(props: Props) {
  return (
    <div className="flex items-center justify-between gap-4">
      <div className="flex flex-row items-center gap-3">
        <InsightsTabs {...props} />
        <div className="flex items-center pt-4">
          <ErrorOutline color="error" />
          <Switch
            data-cy="alerts-only"
            isChecked={props.isAlertsOnly}
            isDisabled={props.isAlertsDisabled && !props.isAlertsOnly}
            label="Alerts only"
            onChange={props.setAlertsOnly}
          />
        </div>
      </div>
      <InsightsSortSelect {...props} />
    </div>
  );
}
