import { MutationHookOptions, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import {
  RemoveAccessToIdeasBetaMutation,
  RemoveAccessToIdeasBetaMutationVariables,
} from '../../../../generated/graphql';
import { featureFlagQuery } from '../../../contexts/queries';

import { removeAccessToIdeasBetaMutation } from './queries';

export default function useRemoveAccessToIdeasBetaMutation(
  options?: MutationHookOptions<
    RemoveAccessToIdeasBetaMutation,
    RemoveAccessToIdeasBetaMutationVariables
  >
) {
  const [optOut] = useMutation<
    RemoveAccessToIdeasBetaMutation,
    RemoveAccessToIdeasBetaMutationVariables
  >(removeAccessToIdeasBetaMutation, options);
  return () =>
    optOut({
      refetchQueries: [getOperationName(featureFlagQuery)!],
    });
}
