import { MarkupMode } from '../../../../generated/graphql';
import { MarkupModeSelect } from '../../../dragon-scales';
import { useProgramMarkupModeQuery } from '../hooks/useProgramMarkupModeQuery';

type Props = {
  programID: UUID;
};

export default function ProgramSettings(props: Props) {
  const { data, loading } = useProgramMarkupModeQuery(props.programID);
  const markupMode = data?.programMarkupMode ?? MarkupMode.NO_MARKUPS;

  const availableMarkupModes = [markupMode];

  if (loading) return null;

  return (
    <div className="flex h-full flex-col gap-2" data-cy="settings">
      <MarkupModeSelect
        availableMarkupModes={availableMarkupModes}
        isDisabled
        label="Markups Display"
        onChange={() => {}} // Cannot currenty change this for programs
        value={markupMode}
      />
      {/* )} */}
    </div>
  );
}
