import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { getWeek, startOfWeek } from 'date-fns';

import {
  ALL_MILESTONES,
  CURRENT_MILESTONE,
  DUE_DATE,
  DUE_STATE,
  FILTER_TEXT,
  FROM,
  SORT,
  STATUS,
} from '../../../../constants';
import { ItemsSortKey, Status } from '../../../../generated/graphql';
import { RouteKeys } from '../../../../routes/paths';
import { getDatePrimitives, getUtcPrimitives, setAllDayRange } from '../../../../utilities/dates';
import { getItemListLink } from '../../../ItemsList/ItemsListUtils';

import { GroupByType, ItemDueState, TimelineItemData, TimelineItemGroup } from './types';

export const getYDomain = (data: TimelineItemGroup[]): [number, number] => {
  const max = Math.max(...data.map((g) => g.data.length));
  return [0, max ?? 0];
};

export const getYDomainPadded = (yDataMin: number, yDataMax: number) => {
  const yMin = yDataMin;
  const yMax = yDataMax + yDataMax * 0.3;
  const yDomain: [number, number] = [yMin, yMax];
  return { yDomain, yMin, yMax };
};

export const DAY_MILLISECONDS = 8.64e7;
export const MONTH_MS = DAY_MILLISECONDS * 30;
export const QUARTER_MS = 3 * MONTH_MS;
export const YEAR_MS = 365 * DAY_MILLISECONDS;
export const TWO_YEARS_MS = 2 * YEAR_MS;

export const createGroup = (
  start: string,
  end: string,
  itemData: TimelineItemData
): TimelineItemGroup => ({
  counter: { 0: 0, 1: 0, 2: 0, 3: 0 },
  data: [itemData],
  start,
  end,
});

export const updateDueStateCount = (group: TimelineItemGroup, { dueState }: TimelineItemData) => {
  const { counter } = group;
  counter[dueState] += 1;
};

export const byMonthKey = (date: string): string => `${date.split('-')[0]}-${date.split('-')[1]}`;
export const byWeekKey = (date: string): string =>
  `${date.split('-')[0]}-${getWeek(new Date(date))}`;
export const byDayKey = (date: string): string => date.split('T')[0];

export const byMonthLimits = (date: Date) => {
  const [year, month] = getUtcPrimitives(date);
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  return [startDate, endDate];
};
export const byWeekLimits = (date: Date) => {
  const startDate = startOfWeek(date);
  const [year, month, day] = getUtcPrimitives(startDate);
  const endDate = new Date(year, month, day + 6);
  return [startDate, endDate];
};
export const byDayLimits = (date: Date) => {
  const startDate = new Date(date);
  const endDate = new Date(date);
  return [startDate, endDate];
};

export const keyFunc = {
  [GroupByType.Month]: byMonthKey,
  [GroupByType.Week]: byWeekKey,
  [GroupByType.Day]: byDayKey,
};

export const limitsFunc = {
  [GroupByType.Month]: byMonthLimits,
  [GroupByType.Week]: byWeekLimits,
  [GroupByType.Day]: byDayLimits,
};

export const getItemsGroups = (
  items: TimelineItemData[],
  type: GroupByType
): TimelineItemGroup[] => {
  const groups = items.reduce((groups: { [key in string]: TimelineItemGroup }, item) => {
    const { dueDate } = item;
    const groupKey = keyFunc[type](dueDate);
    if (groups[groupKey]) {
      groups[groupKey].data.push(item);
    } else {
      const date = new Date(item.dueDate);
      const [startDate, endDate] = setAllDayRange(limitsFunc[type](date));
      const start = startDate.toISOString();
      const end = endDate.toISOString();
      // eslint-disable-next-line no-param-reassign
      groups[groupKey] = createGroup(start, end, item);
    }
    updateDueStateCount(groups[groupKey], item);
    return groups;
  }, {});

  const result = Object.keys(groups).map((k) => groups[k]);
  result.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  return result;
};

export const getItemsGroupBy = ([start, end]: Date[]): GroupByType => {
  const deltaMs = end.getTime() - start.getTime();
  let groupBy = GroupByType.Month;
  if (deltaMs < QUARTER_MS) {
    groupBy = GroupByType.Day;
  } else if (deltaMs < TWO_YEARS_MS) {
    groupBy = GroupByType.Week;
  }
  return groupBy;
};

export const BAR_GAP = 4;
export const BAR_MIN_HEIGHT = 5;

export const getItemsBarParams = (
  x: ScaleTime<number, number> | ScalePoint<Date>,
  y: ScaleLinear<number, number>,
  yMax: number,
  item: TimelineItemGroup
) => {
  const width = (x(new Date(item.end)) ?? 0) - (x(new Date(item.start)) ?? 0) - BAR_GAP;
  const height = item.data.length;
  const decidedHeight = item.counter[ItemDueState.Decided];
  const pastDueHeight = item.counter[ItemDueState.PastDue];
  const upcomingHeight = item.counter[ItemDueState.Upcoming];
  const positionX = x(new Date(item.start));
  const positionY = y(yMax - height);
  let yHeight = y(height);
  let yDecidedHeight = y(decidedHeight);
  let yPastDueHeight = y(pastDueHeight);
  let yUpcomingHeight = y(upcomingHeight);
  if (yHeight < BAR_MIN_HEIGHT) {
    const fraction = BAR_MIN_HEIGHT / height;
    yDecidedHeight *= fraction;
    yPastDueHeight *= fraction;
    yUpcomingHeight *= fraction;
    yHeight *= fraction;
  }
  return { positionX, positionY, width, yDecidedHeight, yHeight, yPastDueHeight, yUpcomingHeight };
};

export const getHoverDate = (hoverItem: TimelineItemGroup) =>
  hoverItem
    ? new Date((new Date(hoverItem.start).getTime() + new Date(hoverItem.end).getTime()) * 0.5)
    : undefined;

export const findGroupByValue = (itemGroups: TimelineItemGroup[], value: number) =>
  itemGroups.find((group) => group.data.length === value);

export const getStatusFromDueState = (d: TimelineItemGroup, today: Date, state: ItemDueState) => {
  const todayDate = new Date(today);
  todayDate.setDate(todayDate.getDate() - 1);
  let { start, end } = d;
  let status: Status[] = [];
  if (state === ItemDueState.Upcoming) {
    if (new Date(start) < todayDate && new Date(end) > todayDate) {
      start = todayDate.toISOString();
    }
    status = [Status.PENDING];
  } else if (state === ItemDueState.PastDue) {
    if (new Date(end) > todayDate && new Date(start) < todayDate) {
      end = todayDate.toISOString();
    }
    status = [Status.PENDING];
  } else if (state === ItemDueState.Decided) {
    if (new Date(start) < todayDate && new Date(end) > todayDate) {
      start = todayDate.toISOString();
    }
    status = [Status.ACCEPTED, Status.REJECTED, Status.INCORPORATED, Status.NOTAPPLICABLE];
  }
  return { start, end, status };
};

export const getItemsGroupHref = (
  projectID: UUID | undefined,
  d: TimelineItemGroup,
  today: Date,
  state: ItemDueState | undefined
) => {
  if (!projectID) return '';
  const { end, start, status } =
    state === undefined ? { ...d, status: [] } : getStatusFromDueState(d, today, state);
  const [sYear, sMonth, sDay] = getDatePrimitives(start);
  const [eYear, eMonth, eDay] = getDatePrimitives(end);
  const startStr = `${sMonth}/${sDay}/${sYear}`;
  const endStr = `${eMonth}/${eDay}/${eYear}`;
  const due = `due=${startStr} to ${endStr}`;
  const filterText = `due: ${startStr} to ${endStr}`;
  const dueState = state ? { [DUE_STATE]: ItemDueState[state] } : {};
  const itemsListLink = getItemListLink(projectID, {
    [DUE_DATE]: due,
    [FILTER_TEXT]: filterText,
    [STATUS]: status,
    [SORT]: ItemsSortKey.SORT_DUE_DATE,
    [CURRENT_MILESTONE]: [ALL_MILESTONES],
    [FROM]: RouteKeys.PROJECT_TIMELINE,
    ...dueState,
  });
  return itemsListLink;
};

export const filterItemsRange = (items: TimelineItemData[], range: [Date, Date]) => {
  const [min, max] = range;
  return items.filter(({ dueDate }) => {
    const date = new Date(dueDate);
    return min <= date && date <= max;
  });
};
