import TimelineBottomTooltip, {
  Props as TimelineBottomTooltipProps,
} from './TimelineBottomTooltip';
import TimelineMultiTooltip, {
  Props as TimelineMultiTooltipProps,
} from './TimelineMultiTooltip/TimelineMultiTooltip';

type Props = TimelineMultiTooltipProps & TimelineBottomTooltipProps & { isMiniChart: boolean };

export default function TimelineChartTooltip(props: Props) {
  return (
    <>
      {props.isMiniChart ? (
        <TimelineMultiTooltip {...props} />
      ) : (
        <TimelineBottomTooltip {...props} />
      )}
    </>
  );
}
