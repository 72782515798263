import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import BarChartIcon from '@material-ui/icons/BarChart';

import { RouteKeys } from '../../routes/paths';
import { withStyles } from '../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../utilities/routes/links';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import { styles } from './MilestonesStyles';

type MilestoneReportLinkProps = {
  classes: Classes<typeof styles>;
  linkCopy?: string;
  milestoneId: UUID;
  search?: string;
};

const MilestoneReportLink: FC<MilestoneReportLinkProps> = ({
  classes,
  linkCopy = 'Milestone Report',
  milestoneId,
  search = '',
}) => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Project ID is required');

  const path = generateSharedPath(RouteKeys.PROJECT_MILESTONES_MILESTONE_MSR, {
    projectId,
    milestoneId,
    search,
  });

  return (
    <Link data-cy="milestone-report-link" tabIndex={-1} to={path}>
      <div
        className={`!inline-block min-w-[92px] ${classes.containerMilestoneReport} ${classes.alignRight} ${classes.link}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <BarChartIcon className={classes.iconBarChart} />
        <NormalTooltip title="Go to Milestone Summary Report">
          <div className="inline min-w-fit">{linkCopy}</div>
        </NormalTooltip>
      </div>
    </Link>
  );
};

export default withStyles(styles)(MilestoneReportLink);
