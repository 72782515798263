import { useParams } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import {
  Selected,
  itemSidebarOpenVar,
  selectedAssetsVar,
} from '../../../../api/apollo/reactiveVars';
import {
  useItemAttachments,
  useMilestoneAttachments,
} from '../../../assets/AssetsWrapper/AssetsWrapperUtils';

export const useEntityID = () => {
  const displayItemID = useReactiveVar(itemSidebarOpenVar);
  const { itemId, milestoneId } = useParams();

  return { itemID: itemId || displayItemID || '', milestoneID: milestoneId };
};

export const returnAssets = (
  itemAssets: EntityAttachment[] = [],
  milestoneAssets: EntityAttachment[] = []
) => {
  if (itemAssets.length > 0) {
    return itemAssets;
  }
  if (milestoneAssets.length > 0) {
    return milestoneAssets;
  }
  return [];
};

export const useEntityAttachments = () => {
  const { itemID, milestoneID } = useEntityID();
  const { itemAssets } = useItemAttachments(itemID);
  const { milestoneAssets } = useMilestoneAttachments(milestoneID);
  return returnAssets(itemAssets, milestoneAssets);
};

export const updateSelectedMap = (selectedMapUpdate: Selected) => {
  const selectedAssets = selectedAssetsVar();
  selectedAssetsVar({
    ...selectedAssets,
    selectedMap: { ...selectedAssets.selectedMap, ...selectedMapUpdate },
  });
};

export const getAssetIDsforDisabling = (assets: EntityAttachment[]) =>
  assets.map((asset) => ('sourceAssetID' in asset ? asset.sourceAssetID : asset.id));
