import {
  ExecDashboardV2EventTypes,
  execDashboardEvent,
} from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { MarketingBanner, useBannerVisibility } from '../scales';

const INSIGHTS_V2_KEY = 'INSIGHTS_V2_KEY';

export function InsightsV2Banner() {
  const sendAnalytics = useSendAnalytics();
  const { isHidden, onDismiss, onRemindMeLater } = useBannerVisibility({
    localStorageKey: INSIGHTS_V2_KEY,
  });

  if (isHidden) return null;

  return (
    <div className="py-2">
      <MarketingBanner
        buttonLabel="Learn more"
        description="The new Insights has been upgraded to make tracking preconstruction project health across projects and regions easier than ever."
        header="A new version of Insights is now available."
        image={
          <div className="flex">
            <img alt="placeholder" className="h-40 w-full" src="/img/insightsV2Banner.gif" />
          </div>
        }
        onCTA={() => {
          window.open('https://join.build/insights/', '_blank');
          sendAnalytics(
            execDashboardEvent(ExecDashboardV2EventTypes.EXEC_DASHBOARD_INSIGHTS_V2_BANNER_CTA, {})
          );
        }}
        onDismiss={() => {
          onDismiss();
          sendAnalytics(
            execDashboardEvent(
              ExecDashboardV2EventTypes.EXEC_DASHBOARD_INSIGHTS_V2_BANNER_DISMISS,
              {}
            )
          );
        }}
        onRemindMeLater={() => {
          onRemindMeLater();
          sendAnalytics(
            execDashboardEvent(
              ExecDashboardV2EventTypes.EXEC_DASHBOARD_INSIGHTS_V2_BANNER_REMIND_LATER,
              {}
            )
          );
        }}
      />
    </div>
  );
}
