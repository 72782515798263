import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import { CostTimeSeries } from '../InsightsCost/types';

import LineLabel from './LineLabel';

export default function LineLabelManager({
  data,
  x,
  y,
}: {
  data: CostTimeSeries[];
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
}) {
  // get the last data point
  const lastDataPoint = data[data.length - 1];
  // get the y positions for each label
  const yPositions = {
    runningTotal: y(data[data.length - 1].runningTotal),
    estimate: y(data[data.length - 1].estimate),
    budget: y(data[data.length - 1].budget),
    pendingMin: y(data[data.length - 1].pendingMin),
    pendingMax: y(data[data.length - 1].pendingMax),
  };

  // check if the y positions are too close to each other, and then adjust the positions that are too close
  const sortedYPositions = Object.entries(yPositions).sort((a, b) => a[1] - b[1]);

  // sort the positions to only compare the yPositions that are next to each other
  const adjustedYPositions = Object.fromEntries(
    sortedYPositions.map(([key, yPos], i, arr) => {
      if (i > 0 && Math.abs(yPos - arr[i - 1][1]) < 15) {
        return [key, yPos + (5 * i) / 2];
      }
      return [key, yPos];
    })
  );

  return (
    <>
      <LineLabel<{ runningTotal: number }>
        data={lastDataPoint}
        field="runningTotal"
        textColor="text-entities-estimate"
        x={x}
        yPosition={adjustedYPositions.runningTotal}
      />
      <LineLabel<{ estimate: number }>
        data={lastDataPoint}
        field="estimate"
        textColor="text-entities-estimate"
        x={x}
        yPosition={adjustedYPositions.estimate}
      />
      <LineLabel<{ budget: number }>
        data={lastDataPoint}
        field="budget"
        textColor="text-entities-budget"
        x={x}
        yPosition={adjustedYPositions.budget}
      />
      <LineLabel<{ pendingMin: number }>
        data={lastDataPoint}
        field="pendingMin"
        textColor="text-item-status-pending"
        x={x}
        yPosition={adjustedYPositions.pendingMin}
      />
      <LineLabel<{ pendingMax: number }>
        data={lastDataPoint}
        field="pendingMax"
        textColor="text-item-status-pending"
        x={x}
        yPosition={adjustedYPositions.pendingMax}
      />
    </>
  );
}
