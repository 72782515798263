import { isUUID } from 'validator';

import { UserStatus } from '../../api/gqlEnumsBe';
import { PROJECTS } from '../../constants';
import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';

// ROUTING
// Here's where we standardize Signup Routing
// We need to include search params to track path of arrival
// If there's no ProjectId, we will go to /projects/${route}
export const generateSignupPath = (route: RouteKeys, projectID?: UUID) => {
  const search = decodeURIComponent(window.location.search);
  return generateSharedPath(route, {
    projectId: projectID || PROJECTS,
    search,
  });
};

export const useSignupURL = (companyUser?: CompanyUser, projectId?: UUID): string | null => {
  if (!companyUser) return null;

  const { company, isIndependent, matchedCompany, user } = companyUser;
  const needsCompany = (!company || user?.status === UserStatus.PENDING) && !isIndependent;

  // First roadblock: Company
  const isCompanyPages = window.location.href.includes('/sign-up/company/');
  const isUserPages = window.location.href.includes('/sign-up/user/');
  if (needsCompany && !isCompanyPages) {
    const companyRoute = matchedCompany
      ? RouteKeys.PROJECT_SIGNUP_CONFIRM_COMPANY
      : RouteKeys.PROJECT_SIGNUP_CREATE_COMPANY;
    return generateSignupPath(companyRoute, projectId);
  }
  // Second roadblock: User
  if (!needsCompany && !isUserPages && !user?.didSetup) {
    return generateSignupPath(RouteKeys.PROJECT_SIGNUP_SETUP_USER, projectId);
  }

  // Or, no need to be in signup pages
  if (!needsCompany && (isUserPages || isCompanyPages) && user?.didSetup) {
    return projectId && isUUID(projectId)
      ? generateSharedPath(RouteKeys.PROJECT, { projectId })
      : generateSharedPath(RouteKeys.SEARCH_PROJECTS, {});
  }
  return null;
};

export const useWaitForSignupRedirect = (
  companyUser: CompanyUser | undefined,
  skipSignupFlow: boolean,
  projectId: UUID | undefined
) => {
  const url = useSignupURL(companyUser, projectId);

  if (skipSignupFlow) return { wait: false };
  if (!companyUser) return { wait: true };

  return { wait: !!url, url };
};
