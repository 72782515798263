import { Outlet, RouteObject } from 'react-router-dom';

import ApolloSandbox from '../components/ApolloSandbox';
import CompanyRoute from '../components/CompanyTab/CompanyRoute';
import CompanyTabSettingsData from '../components/CompanyTab/CompanyTabSettings.tsx/CompanyTabSettingsData';
import ContingencyReportData from '../components/ContingencyReport/ContingencyReportData/ContingencyReportData';
import CostReportData from '../components/CostReport/CostReportData/CostReportData';
import { DashboardRoute } from '../components/dashboard/DashboardRoute';
import DeactivatedRoute from '../components/DeactivatedRoute';
import AuthorizeRedirectPage from '../components/DocumentMagic/AuthorizeRedirectPage/AuthorizeRedirectPage';
import EstimateAccordionItem from '../components/estimate/EstimateAccordion/EstimateAccordionItem';
import ExecutiveDashboardRoute from '../components/ExecutiveDashboard/ExecutiveDashboardRoute';
import InsightsClassicRoute from '../components/ExecutiveDashboard/InsightsClassicRoute';
import InsightsProjectRoute from '../components/ExecutiveDashboardV2/InsightsProject/InsightsProjectRoute';
import ExportRoute from '../components/ExportRoute/ExportRoute';
import ForecastingExplore from '../components/ForecastingRoute/ForecastingExplore/ForecastingExplore';
import ForecastingReports from '../components/ForecastingRoute/ForecastingReports/ForecastingReports';
import ForecastingRoute from '../components/ForecastingRoute/ForecastingRoute';
import FourOhFour from '../components/FourOhFour';
import Search from '../components/HomeTab/Search/Search';
import IdeaRoute from '../components/IdeasRoute/IdeaRoute/IdeaRoute';
import IdeasRoute from '../components/IdeasRoute/IdeasRoute';
import IdeasIndexRoute from '../components/IdeasRoute/IndexRoute/IdeasIndexRoute';
import ImportEstimateData from '../components/ImportEstimate/ImportEstimateData/ImportEstimateData';
import ItemActivityFeedData from '../components/Items/ItemActivityFeed/ItemActivityFeedData';
import ItemsListData from '../components/ItemsList/ItemsListData';
import ConfirmCompanyData from '../components/login/Signup/SignupPages/ConfirmCompanyData';
import CreateCompanyData from '../components/login/Signup/SignupPages/CreateCompanyData';
import SetupUserData from '../components/login/Signup/SignupPages/SetupUserData';
import MilestoneRoute from '../components/Milestone/MilestoneRoute';
import Milestones from '../components/Milestones/Milestones';
import MilestonesRoute from '../components/MilestonesRoute/MilestonesRoute';
import Nav from '../components/Nav/Nav';
import Router from '../components/Nav/Router';
import PageHasMoved from '../components/PageHasMoved';
import ExportActiveMilestoneContingency from '../components/Print/ExportContingency/ExportActiveMilestoneContingency';
import ExportAllMilestonesContingency from '../components/Print/ExportContingency/ExportAllMilestonesContingency';
import ExportItemsList from '../components/Print/ExportItemsList/ExportItemsList';
import ExportMilestone from '../components/Print/ExportMilestone/ExportMilestone';
import ExportMilestoneSummaryReport from '../components/Print/ExportMilestoneSummaryReport/ExportMilestoneSummaryReport';
import ExportVariance from '../components/Print/ExportVariance/ExportVariance';
import PrintBreakdownsData from '../components/Print/PrintBreakdowns/PrintBreakdownsData';
import PrintContingencyReport from '../components/Print/PrintContingencyReport/PrintContingencyReport';
import PrintCostReport from '../components/Print/PrintCostReport/PrintCostReport';
import PrintDashboardData from '../components/Print/PrintDashboard/PrintDashboardData';
import PrintViewItemDetails from '../components/Print/PrintItemDetails/PrintViewItemDetails';
import PrintViewItemsList from '../components/Print/PrintItemsListNew/PrintViewItemsList';
import PrintViewItemsListAndItemDetailsList from '../components/Print/PrintItemsListNew/PrintViewItemsListAndItemDetailsList';
import PrintProjectComps from '../components/Print/PrintProjectComps/PrintProjectComps';
import PrintProjectCostBreakdown from '../components/Print/PrintProjectCostBreakdown/PrintProjectCostBreakdown';
import PrintScenariosSandbox from '../components/Print/PrintScenariosSandbox/PrintScenariosSandbox';
import PrintVarianceReport from '../components/Print/PrintVarianceReport/PrintVarianceReport';
import PrintProgramDashboardRoute from '../components/ProgramReporting/PrintProgramDashboardRoute';
import ProgramDashboardRoute from '../components/ProgramReporting/ProgramDashboardRoute';
import ProgramReportingRoute from '../components/ProgramReporting/ProgramReportingRoute';
import ProjectCompsSetData from '../components/ProjectComps/ProjectCompsSetData';
import ExportProjectCostBreakdownReportData from '../components/ProjectCostBreakdownReport/ExportProjectCostBreakdownReportData';
import ProjectCostBreakdownReportData from '../components/ProjectCostBreakdownReport/ProjectCostBreakdownReportData';
import ProjectItemRoute from '../components/ProjectItemsRoute/ProjectItemRoute';
import ProjectItemsRoute from '../components/ProjectItemsRoute/ProjectItemsRoute';
import ProjectPropertiesData from '../components/ProjectProperties/ProjectPropertiesData';
import ProjectRoute from '../components/ProjectRoute/ProjectRoute';
import ReportDistributionUnsubscribe from '../components/ReportDistributionUnsubscribe/ReportDistributionUnsubscribe';
import ReportDistributionView from '../components/ReportDistributionView/ReportDistributionView';
import ReportsTabData from '../components/ReportsTab/ReportsTab/ReportsTabData';
import RouteReport, { RouteReportBackCompat } from '../components/ReportsTab/RouteReport';
import RootRoute from '../components/RootRoute';
import { ScenariosRoute } from '../components/Scenarios/ScenariosRoute';
import CompaniesRoute from '../components/TeamRoute/CompaniesRoute';
import RoleEditRoute from '../components/TeamRoute/RolesRoute/RoleEditRoute';
import RoleRoute from '../components/TeamRoute/RolesRoute/RoleRoute';
import RolesRoute from '../components/TeamRoute/RolesRoute/RolesRoute';
import TeammatesRoute from '../components/TeamRoute/TeammatesRoute';
import TeamRoute from '../components/TeamRoute/TeamRoute';
import TimelineEditView from '../components/TimelineRoute/Timeline/TimelineEditView';
import TimelineRoute from '../components/TimelineRoute/TimelineRoute';
import TrendingCostReportData from '../components/TrendingCostReport/TrendingCostReportData/TrendingCostReportData';
import VarianceReportData from '../components/VarianceReport/VarianceReportData/VarianceReportData';
import ItemDetailsData from '../components/ve-item-details/ItemDetailsData';

import paths from './paths';

/**
 * https://reactrouter.com/en/main/upgrading/v6-data#add-routerprovider-with-a-root-splat-route
 *
 * We're upgrading the router onto RR 6.4 incrementally. All new routes should
 * ideally be added here instead of the routes.tsx file that's in this directory
 * unless there's a good reason for it not to be the case. See comments
 * throughout this file to see how to add routes incrementally.
 *
 * To do so:
 *   1. `paths.ts`: Add a new entry to the `RouteKeys` enum.
 *   2. `paths.ts`: Add the path into `paths`. Note that paths are relative and
 *      do not include the full path.
 *   3. `index.tsx`: Add a route into the `routes` array. Note that:
 *      - You can define index routes if desired:
 *          https://reactrouter.com/en/main/start/concepts#index-routes
 *
 */

// RouteObject: https://reactrouter.com/en/main/route/route
const routes: RouteObject[] = [
  {
    /**
     * We define all our top-level routes that rely on auth'd users here.
     *
     * In this transition period between routers, we don't need to fully
     * define all routes in this array because we use the splat route
     * at the end to render the <Router /> as a fallback method of routing.
     *
     * As we bring in more routes, the children will get more filled-out and
     * eventually we'll be able to render <Navigate to="/404" /> instead of
     * <Router /> as the fallback.
     */
    path: paths.ROOT,
    element: <RootRoute />,
    children: [
      /** These routes do not render the Nav. */
      { path: paths.APOLLO, element: <ApolloSandbox /> },
      { path: paths.AUTHORIZE, element: <AuthorizeRedirectPage /> },
      { path: paths.DEACTIVATED, element: <DeactivatedRoute /> },
      { path: paths.NOT_FOUND, element: <FourOhFour /> },
      {
        path: paths.EXPORT,
        children: [
          {
            path: paths.EXPORT_PROJECT,
            element: <ExportRoute />,
            children: [
              {
                path: paths.EXPORT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY,
                element: <ExportActiveMilestoneContingency />,
              },
              {
                path: paths.EXPORT_PROJECT_ALL_MILESTONES_CONTINGENCY,
                element: <ExportAllMilestonesContingency />,
              },
              { path: paths.EXPORT_PROJECT_ITEMS_LIST, element: <ExportItemsList /> },
              { path: paths.EXPORT_PROJECT_MILESTONE_BUDGET, element: <ExportMilestone /> },
              { path: paths.EXPORT_PROJECT_MILESTONE_ESTIMATE, element: <ExportMilestone /> },
              { path: paths.EXPORT_PROJECT_MSR, element: <ExportMilestoneSummaryReport /> },
              {
                path: paths.EXPORT_PROJECT_COST_BREAKDOWN_REPORT,
                element: <ExportProjectCostBreakdownReportData />,
              },
              { path: paths.EXPORT_PROJECT_VARIANCE, element: <ExportVariance /> },
            ],
          },
        ],
      },
      {
        path: paths.PRINT,
        children: [
          {
            path: paths.PRINT_PROJECT,
            element: <ProjectRoute />,
            children: [
              {
                path: paths.PRINT_PROJECT_BREAKDOWNS,
                element: <PrintBreakdownsData />,
              },
              {
                path: paths.PRINT_PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT,
                element: <PrintContingencyReport />,
              },
              {
                path: paths.PRINT_PROJECT_CONTINGENCY_ALLOWANCE_REPORT,
                element: <PrintContingencyReport />,
              },
              { path: paths.PRINT_PROJECT_COST_BREAKDOWN, element: <PrintProjectCostBreakdown /> },
              { path: paths.PRINT_PROJECT_DASHBOARD_SUMMARY, element: <PrintDashboardData /> },
              { path: paths.PRINT_PROJECT_DASHBOARD, element: <PrintDashboardData /> },
              { path: paths.PRINT_PROJECT_ITEM_DETAILS, element: <PrintViewItemDetails /> },
              { path: paths.PRINT_PROJECT_ITEMS_LIST, element: <PrintViewItemsList /> },
              {
                path: paths.PRINT_PROJECT_ITEMS_LIST_DETAILS,
                element: <PrintViewItemsListAndItemDetailsList />,
              },
              { path: paths.PRINT_PROJECT_MSR_SUMMARY, element: <PrintCostReport /> },
              { path: paths.PRINT_PROJECT_MSR, element: <PrintCostReport /> },
              { path: paths.PRINT_PROJECT_REPORT, element: <RouteReport isPrint /> },
              { path: paths.PRINT_PROJECT_SCENARIOS, element: <PrintScenariosSandbox /> },
              { path: paths.PRINT_PROJECT_VARIANCE, element: <PrintVarianceReport /> },
            ],
          },
        ],
      },

      /** Most of our routes should render the Nav sidebar but some do not. We
       * use a Layout Route [1] to provide this functionality for those
       * routes.
       *
       * [1](https://reactrouter.com/en/main/start/concepts#layout-routes)
       */
      {
        element: <Nav />,
        children: [
          {
            path: paths.COMPANY,
            element: <CompanyRoute />,
            children: [{ path: paths.COMPANY_SETTINGS, element: <CompanyTabSettingsData /> }],
          },
          {
            path: paths.BENCHMARKING,
            element: <ForecastingRoute />,
            children: [
              { path: paths.BENCHMARKING_EXPLORE, element: <ForecastingExplore /> },
              { path: paths.BENCHMARKING_REPORTS, element: <ForecastingReports /> },
              { path: paths.BENCHMARKING_PROJECT_COMPS, element: <ProjectCompsSetData /> },
              { path: paths.BENCHMARKING_PROJECT_COMPS_SAVED, element: <ProjectCompsSetData /> },
              { path: paths.BENCHMARKING_PROJECT_COMPS_PRINT, element: <PrintProjectComps /> },
              {
                path: paths.BENCHMARKING_PROJECT_COMPS_SAVED_PRINT,
                element: <PrintProjectComps />,
              },
            ],
          },
          // TODO DD-1476 delete me after a few weeks of running the benchmarking feature flag
          {
            path: paths.FORECASTING,
            element: <ForecastingRoute />,
            children: [
              { path: paths.FORECASTING_EXPLORE, element: <ForecastingExplore /> },
              { path: paths.FORECASTING_REPORTS, element: <ForecastingReports /> },
              { path: paths.FORECASTING_PROJECT_COMPS, element: <ProjectCompsSetData /> },
              { path: paths.FORECASTING_PROJECT_COMPS_SAVED, element: <ProjectCompsSetData /> },
              { path: paths.FORECASTING_PROJECT_COMPS_PRINT, element: <PrintProjectComps /> },
              {
                path: paths.FORECASTING_PROJECT_COMPS_SAVED_PRINT,
                element: <PrintProjectComps />,
              },
            ],
          },
          {
            path: paths.INSIGHTS,
            element: <ExecutiveDashboardRoute />,
            children: [{ path: paths.INSIGHTS_PROJECT, element: <InsightsProjectRoute /> }],
          },
          {
            path: paths.INSIGHTS_CLASSIC,
            element: <InsightsClassicRoute />,
            children: [],
          },
          {
            path: paths.PROGRAM,
            element: <ProgramReportingRoute />,
            children: [
              {
                path: paths.PROGRAM_DASHBOARD_PRINT,
                element: <PrintProgramDashboardRoute />,
              },
              {
                path: paths.PROGRAM_DASHBOARD,
                element: <ProgramDashboardRoute />,
              },
            ],
          },
          {
            path: paths.PROJECT,
            element: <ProjectRoute />,
            children: [
              {
                path: paths.PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT,
                element: <ContingencyReportData />,
              },
              {
                path: paths.PROJECT_CONTINGENCY_ALLOWANCE_REPORT,
                element: <ContingencyReportData />,
              },
              { path: paths.PROJECT_DASHBOARD, element: <DashboardRoute /> },
              { path: paths.PROJECT_EXPORT, element: <PageHasMoved /> },
              {
                path: paths.PROJECT_IDEAS,
                element: <IdeasRoute />,
                children: [
                  { index: true, element: <IdeasIndexRoute /> },
                  { path: paths.PROJECT_IDEAS_IDEA, element: <IdeaRoute /> },
                ],
              },
              {
                path: paths.PROJECT_ITEMS,
                element: <ProjectItemsRoute />,
                children: [
                  { index: true, element: <ItemsListData /> },
                  { path: paths.PROJECT_ITEMS_ACTIVITY, element: <ItemActivityFeedData /> },
                  {
                    path: paths.PROJECT_ITEMS_ITEM,
                    element: <ProjectItemRoute />,
                    children: [
                      { index: true, element: <ItemDetailsData /> },
                      {
                        path: paths.PROJECT_ITEMS_ITEM_ESTIMATE,
                        element: <EstimateAccordionItem />,
                      },
                    ],
                  },
                ],
              },
              {
                path: paths.PROJECT_MILESTONES,
                element: <MilestonesRoute />,
                children: [
                  { element: <Milestones />, index: true },
                  {
                    path: paths.PROJECT_MILESTONES_MILESTONE,
                    element: <MilestoneRoute />,
                    children: [
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_COST_BREAKDOWN_REPORT,
                        element: <ProjectCostBreakdownReportData />,
                      },
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_IMPORT_ESTIMATE,
                        element: <ImportEstimateData />,
                      },
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_MSR,
                        element: <CostReportData />,
                      },
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_TRENDING_COST_REPORT,
                        element: <TrendingCostReportData />,
                      },
                    ],
                  },
                ],
              },
              { path: paths.PROJECT_REPORT_BACKCOMPAT, element: <RouteReportBackCompat /> },
              { path: paths.PROJECT_REPORT_DISTRIBUTION_FILE, element: <ReportDistributionView /> },
              {
                path: paths.PROJECT_REPORT_DISTRIBUTION_UNSUBSCRIBE,
                element: <ReportDistributionUnsubscribe />,
              },
              {
                path: paths.PROJECT_REPORTS,
                element: <Outlet />, // kind of lazy, we wouldn't do this if writing from scratch
                children: [
                  { index: true, element: <ReportsTabData /> },
                  { path: paths.PROJECT_REPORTS_REPORT, element: <RouteReport /> },
                ],
              },
              { path: paths.PROJECT_SCENARIOS, element: <ScenariosRoute /> },
              { path: paths.PROJECT_SETTINGS, element: <ProjectPropertiesData /> },
              {
                path: paths.PROJECT_SIGNUP,
                element: <Outlet />, // kind of lazy, we wouldn't do this if writing from scratch
                children: [
                  { path: paths.PROJECT_SIGNUP_CONFIRM_COMPANY, element: <ConfirmCompanyData /> },
                  { path: paths.PROJECT_SIGNUP_CREATE_COMPANY, element: <CreateCompanyData /> },
                  { path: paths.PROJECT_SIGNUP_SETUP_USER, element: <SetupUserData /> },
                ],
              },
              {
                path: paths.PROJECT_TEAM,
                element: <TeamRoute />,
                children: [
                  { path: paths.PROJECT_TEAM_TEAMMATES, element: <TeammatesRoute /> },
                  { path: paths.PROJECT_TEAM_COMPANIES, element: <CompaniesRoute /> },
                  {
                    path: paths.PROJECT_TEAM_ROLES,
                    element: <RolesRoute />,
                    children: [
                      { path: paths.PROJECT_TEAM_ROLES_ROLE_VIEW, element: <RoleRoute /> },
                      { path: paths.PROJECT_TEAM_ROLES_ROLE_EDIT, element: <RoleEditRoute /> },
                    ],
                  },
                ],
              },
              {
                path: paths.PROJECT_TIMELINE,
                element: <TimelineRoute />,
                children: [{ index: true, element: <TimelineEditView /> }],
              },
              { path: paths.PROJECT_VARIANCE, element: <VarianceReportData /> },
            ],
          },
          { path: paths.SEARCH_ITEMS, element: <Search /> },
          { path: paths.SEARCH_PROJECTS, element: <Search /> },
          {
            // See above comments. This is our fallback to the legacy style of routing.
            path: paths.SPLAT,
            element: <Router />,
          },
        ],
      },
    ],
  },
];

export default routes;
