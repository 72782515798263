import { CREATE_CATEGORY } from '../../../actions/actionTypes';
import { EventProperties, analyticsEvent } from '../../../analytics/analyticsEventProperties';
import joinAPI from '../../../api/joinAPI';
import {
  AcceptImportEstimateErrorMutation,
  AcceptImportEstimateErrorMutationVariables,
  BulkUpdateCategoriesMutationResult,
  BulkUpdateCategoriesMutationVariables,
  CreateCategoriesMutation,
  CreateCategoriesMutationVariables,
} from '../../../generated/graphql';

import {
  acceptImportEstimateErrorMutation,
  bulkUpdateCategoriesMutation,
  createCategoriesMutation,
} from './queries';

type CreateCategoriesMutationResult = {
  data?: CreateCategoriesMutation;
};

export const bulkUpdateCategories = (
  categorizationID: UUID,
  projectID: UUID | undefined,
  toCreate: CategoryContentInput[],
  toEdit: EditCategoryInput[],
  toDelete: UUID[],
  sendAnalytics?: (analyticsEvent: { type: string; eventProperties: EventProperties }) => void,
  onSuccess?: (result: BulkUpdateCategoriesMutationResult) => void
) => {
  if (toCreate.length === 0 && toEdit.length === 0 && toDelete.length === 0) return;
  const variables: BulkUpdateCategoriesMutationVariables = {
    categorizationID,
    projectID,
    toCreate,
    toEdit,
    toDelete,
  };
  joinAPI
    .requestGraphQL(bulkUpdateCategoriesMutation, variables)
    .then((r: BulkUpdateCategoriesMutationResult) => {
      if (toCreate.length > 0) sendAnalytics?.(analyticsEvent(CREATE_CATEGORY));
      onSuccess?.(r);
    });
};

export const createCategories = (
  categorizationID: UUID,
  categories: CategoryContentInput[],
  sendAnalytics?: (analyticsEvent: { type: string; eventProperties: EventProperties }) => void,
  projectID?: UUID,
  onSuccess?: (result: CreateCategoriesMutationResult) => void
) => {
  if (!(categories.length > 0)) return;
  const variables: CreateCategoriesMutationVariables = {
    projectID,
    categorizationID,
    categories,
  };
  joinAPI
    .requestGraphQL(createCategoriesMutation, variables)
    .then((r: CreateCategoriesMutationResult) => {
      sendAnalytics?.(analyticsEvent(CREATE_CATEGORY));
      if (onSuccess) onSuccess(r);
    });
};

type AcceptImportEstimateErrorResult = {
  data?: AcceptImportEstimateErrorMutation;
};

export const acceptImportEstimateError = (
  projectID: UUID | undefined,
  estimateID: UUID | undefined,
  errorID: UUID,
  onSuccess?: (result: AcceptImportEstimateErrorResult) => void
) => {
  if (!projectID || !estimateID) return;
  const variables: AcceptImportEstimateErrorMutationVariables = {
    projectID,
    estimateID,
    errorID,
  };
  joinAPI
    .requestGraphQL(acceptImportEstimateErrorMutation, variables)
    .then((r: AcceptImportEstimateErrorResult) => onSuccess && onSuccess(r));
};
