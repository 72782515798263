import { useState } from 'react';
import * as React from 'react';

import { Dialog, DialogActions, Divider, TextField, Typography } from '@material-ui/core';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useCreateProjectTemplateMutation } from '../../CompanyTab/Standards/ProjectTemplates/hooks';
import { Button, Icon, IconButton } from '../../scales';
import DialogsStyles from '../DialogsStyles';

type DialogsNewTemplateProps = {
  classes: Classes<typeof DialogsStyles>;
  setDialogOpen: (open: boolean) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
};

const DialogsNewTemplate: React.FC<DialogsNewTemplateProps> = ({
  classes,
  open,
  setDialogOpen,
}) => {
  const [newTemplateName, setNewTemplateName] = useState('');
  const createProjectTemplate = useCreateProjectTemplateMutation();
  const sendAnalytics = useSendAnalytics();

  const onClose = () => {
    setDialogOpen(false);
    setNewTemplateName('');
  };

  const header = (
    <div className={classes.titleContainer}>
      <Typography variant="title">New Template</Typography>
      <IconButton
        aria-label="Close dialog"
        icon={<Icon name="close" />}
        onClick={() => {
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_CREATE_CLOSE)
          );
          onClose();
        }}
        type="secondary"
      />
    </div>
  );

  const content = (
    <div className={classes.content}>
      <TextField
        autoFocus
        data-cy="DialogsNewTemplate-name"
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{ disableUnderline: true }}
        label="Template Name"
        margin="normal"
        onChange={(evt) => setNewTemplateName(evt.target.value)}
        placeholder="Template Name"
        required
        value={newTemplateName}
      />
    </div>
  );

  const footer = (
    <DialogActions className={classes.action}>
      <Button
        label="Cancel"
        onClick={() => {
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_CREATE_CANCEL)
          );
          onClose();
        }}
        type="secondary"
      />
      <Button
        data-cy="create-new-template-as-draft-button"
        isDisabled={!newTemplateName.trim()}
        label="Create as Draft"
        onClick={() => {
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_CREATE_DRAFT)
          );
          createProjectTemplate(newTemplateName);
          onClose();
        }}
        type="primary"
      />
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: `${classes.dialogPaper} ${classes.dialogPaperSmall}`,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      {header}
      <Divider />
      {content}
      {footer}
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsNewTemplate);
