import {
  CurrentCollaboratorQuery,
  PermissionGroupType,
  PermissionResource,
} from '../../generated/graphql';

import { PartialCategory } from './types';

const initializeAccessMaps = () => ({
  default: new Map<PermissionResource, boolean>(),
  inTrade: new Map<PermissionResource, boolean>(),
  outOfTrade: new Map<PermissionResource, boolean>(),
});

/** getPermissions extracts the permissions information from both the
 * currentCompanyRole (company) and currentCollaborator (project) data into
 * easier to understand functions that determine whether you can view, edit,
 * add, or delete any given permission resource.
 *
 * Most of the nitty gritty with getPermissions stems from how we handle in
 * trade vs out of trade item permissions. For the most part we can provide
 * itemCategories to usePermissions (or choose not to) and get our canView,
 * canEdit, canAdd, canDelete permission functions. However there is also a need
 * to support checking multiple distinct items in the same place, so we also
 * support allowing you to pass trades into these functions to correctly get the
 * in trade or out of trade permission based on that.
 * */
export const getPermissions = (
  input: {
    permissionGroups?: CurrentCollaboratorQuery['currentCollaborator']['role']['permissionGroups'];
    allTrades?: CurrentCollaboratorQuery['currentCollaborator']['allTrades'];
    hasTrade?: CurrentCollaboratorQuery['currentCollaborator']['role']['hasTrade'];
    trades?: CurrentCollaboratorQuery['currentCollaborator']['trades'];
  },
  itemCategories?: PartialCategory[]
) => {
  const { permissionGroups, allTrades, hasTrade, trades } = input;
  const inTrade = getInTrade(allTrades, hasTrade, trades, itemCategories);
  const viewAccess = initializeAccessMaps();
  const editAccess = initializeAccessMaps();
  const addAccess = initializeAccessMaps();
  const deleteAccess = initializeAccessMaps();

  permissionGroups?.forEach((group) => {
    if (group.type === PermissionGroupType.ITEM_IN_TRADE) {
      // We keep track of the ITEM_IN_TRADE permissions seperately to support
      // the trades arg for our returned functions
      group.permissions.forEach((permission) => {
        viewAccess.inTrade.set(permission.resource, permission.actions.view);
        editAccess.inTrade.set(permission.resource, permission.actions.edit);
        addAccess.inTrade.set(permission.resource, permission.actions.add);
        deleteAccess.inTrade.set(permission.resource, permission.actions.delete);
      });
      // If this is out of trade based on the trades provided to usePermissions,
      // then we ignore the in trade item permissions for the default access
      // maps
      if (!inTrade) return;
    }
    if (group.type === PermissionGroupType.ITEM_OUT_OF_TRADE) {
      // We keep track of the ITEM_OUT_OF_TRADE permissions seperately to
      // support the trades arg for our returned functions
      group.permissions.forEach((permission) => {
        viewAccess.outOfTrade.set(permission.resource, permission.actions.view);
        editAccess.outOfTrade.set(permission.resource, permission.actions.edit);
        addAccess.outOfTrade.set(permission.resource, permission.actions.add);
        deleteAccess.outOfTrade.set(permission.resource, permission.actions.delete);
      });
      // If this is in trade based on the trades provided to usePermissions,
      // then we ignore the out of trade item permissions for the default access
      // maps
      if (inTrade) return;
    }
    group.permissions.forEach((permission) => {
      viewAccess.default.set(permission.resource, permission.actions.view);
      editAccess.default.set(permission.resource, permission.actions.edit);
      addAccess.default.set(permission.resource, permission.actions.add);
      deleteAccess.default.set(permission.resource, permission.actions.delete);
    });
  });

  const checkInTrade = (categories?: PartialCategory[]) =>
    getInTrade(allTrades, hasTrade, trades, categories);
  const getPermissionResource = (
    resource: PermissionResource,
    access: ReturnType<typeof initializeAccessMaps>,
    args?: { trades: PartialCategory[] }
  ) => {
    // if no trades are specified, we will use the default permissions that have
    // already accounted for in or out of trade permissions based on your role
    // and the trades provided to usePermissions
    if (!args?.trades) return !!access.default.get(resource);
    // otherwise we determine if the custom trades provided are in or out of trade
    return checkInTrade(args.trades)
      ? (access.inTrade.get(resource) ?? !!access.default.get(resource))
      : (access.outOfTrade.get(resource) ?? !!access.default.get(resource));
  };

  const canView = (resource: PermissionResource, args?: { trades: PartialCategory[] }) =>
    getPermissionResource(resource, viewAccess, args);
  const canEdit = (resource: PermissionResource, args?: { trades: PartialCategory[] }) =>
    getPermissionResource(resource, editAccess, args);
  const canAdd = (resource: PermissionResource, args?: { trades: PartialCategory[] }) =>
    getPermissionResource(resource, addAccess, args);
  const canDelete = (resource: PermissionResource, args?: { trades: PartialCategory[] }) =>
    getPermissionResource(resource, deleteAccess, args);

  return { canView, canEdit, canAdd, canDelete, inTrade, checkInTrade };
};

const getInTrade = (
  allTrades?: CurrentCollaboratorQuery['currentCollaborator']['allTrades'],
  hasTrade?: CurrentCollaboratorQuery['currentCollaborator']['role']['hasTrade'],
  trades?: CurrentCollaboratorQuery['currentCollaborator']['trades'],
  itemCategories?: PartialCategory[]
) => {
  if (allTrades || !hasTrade) return true;
  // when there is a trade restriction, if any one category matches then it is in trade
  return Boolean(
    trades?.some((tradeCategory) =>
      itemCategories?.some((c1) => {
        if (tradeCategory.id === c1.id) return true;
        return c1.levels?.some((c2) => tradeCategory.id === c2.id);
      })
    )
  );
};

/** combinePermissions returns permissions based on the intersection between
 * your current permissions and the permissions you are previewing. This
 * includes combining of both the access functions as well as the trades-related
 * boolean and function. For company-specific resource access, the boolean
 * return is entirely based on what you the current user have access to. For
 * all project-specific resource access, the boolean return is based on what
 * both you the current user and the previewed user/role have access to
 * */
export const combineCurrentAndPreviewPermissions = (
  currentPermissions: ReturnType<typeof getPermissions>,
  previewPermissions: ReturnType<typeof getPermissions>
) => {
  const combineAccess = (
    currentAccess: (resource: PermissionResource, args?: { trades: PartialCategory[] }) => boolean,
    previewAccess: (resource: PermissionResource, args?: { trades: PartialCategory[] }) => boolean
  ) => {
    return (resource: PermissionResource, args?: { trades: PartialCategory[] }) => {
      if (isCompanySpecificResource(resource)) return currentAccess(resource, args);
      return currentAccess(resource, args) && previewAccess(resource, args);
    };
  };
  const canView = combineAccess(currentPermissions.canView, previewPermissions.canView);
  const canEdit = combineAccess(currentPermissions.canEdit, previewPermissions.canEdit);
  const canAdd = combineAccess(currentPermissions.canAdd, previewPermissions.canAdd);
  const canDelete = combineAccess(currentPermissions.canDelete, previewPermissions.canDelete);
  const inTrade = currentPermissions.inTrade && previewPermissions.inTrade;
  const checkInTrade = (trades: PartialCategory[]) =>
    currentPermissions.checkInTrade(trades) && previewPermissions.checkInTrade(trades);
  return { canView, canEdit, canAdd, canDelete, inTrade, checkInTrade };
};

const isCompanySpecificResource = (resource: PermissionResource) =>
  [
    PermissionResource.USER_DETAILS,
    PermissionResource.COMPANY_DETAILS,
    PermissionResource.PROJECT_COMPS_ACCESS,
    PermissionResource.INSIGHTS_ACCESS,
    PermissionResource.ALL_PROJECTS_ACCESS,
    PermissionResource.PROJECT_TEMPLATE_ACCESS,
    PermissionResource.ALL_COMPANY_SEARCH_ACCESS,
  ].includes(resource);
